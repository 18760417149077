import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Header4, Loader } from 'fiducius-ui';

import { getUserId, authLoadPermissions } from '../../../auth';
import { withPermissions } from '../../../routing';
import { Redirect } from 'react-router-dom';

import { questionnaireBtsUpdateResource } from '../redux/operations';
import { todosLoadCollection } from '../../../todos/redux/operations';
import BtsBorrowingCompare from '../../../benefits/forgiveness/components/bts-borrowing-comparison';

const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

const ButtonRight = styled(Button)`
  float: right;
  margin-top: 10px;
  margin-right: 5px;
`;

class TabResults extends React.Component {
  static propTypes = {
    getResults: t.func.isRequired,
    id: t.string,
    isLoading: t.bool.isRequired,
    reloadPermissions: t.func.isRequired,
    cache: t.object,
    questionnaireSave: t.func,
    requests: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { RedirectUri: '', BtsYear: 0 };
  }

  async componentDidMount() {
    const { id } = this.props;
    window.scrollTo(0, 0);
    await this.props.questionnaireSave(id);
  }

  async componentDidUpdate() {
    const { cache } = this.props;
    if (cache !== undefined && cache.btsYearId && this.state.BtsYear === 0) {
      this.setState({ ...this.state, BtsYear: cache.btsYearId });
    }
  }

  setRedirect = () => {
    this.setState({ RedirectUri: '/benefits/forgiveness/bts-review' });
  };

  render() {
    const { isLoading } = this.props;

    if (isLoading) {
      return (
        <Flex>
          <Loader variant="atom" size={3} />
        </Flex>
      );
    } else if (this.state.RedirectUri && this.state.RedirectUri.length > 0) {
      return <Redirect to={this.state.RedirectUri} />;
    } else {
      return (
        <>
          <div className="row">
            <div className="col-12">
              <Flex>
                <Header4>
                  <b>Thank you for completing your Snapshot Questionnaire.</b>
                </Header4>
                <br />
                <p>
                  The graph below is a comparison of your initial loan balance,
                  {this.state.BtsYear === 1 && ' current Snapshot'}
                  {this.state.BtsYear > 1 && ' previous and current Snapshots'}, and financial
                  wellness plan. For an in depth review of your results, our recommendations and
                  other helpful information, please select "Go to Full Results."
                </p>
                <BtsBorrowingCompare requestData={true} showPreviousYears={false} />
              </Flex>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ButtonRight brand="primary" onClick={() => this.setRedirect()}>
                Go to Full Results
              </ButtonRight>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { questionnaireBts } = state;
  const { cache } = questionnaireBts;
  const id = getUserId(state);
  return {
    id: id,
    cache: cache[id],
    isLoading: questionnaireBts.requests.updateResource.isLoading,
    requests: questionnaireBts.requests.updateResource,
    saveRequest: questionnaireBts.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  questionnaireSave: async (id) => {
    await dispatch(questionnaireBtsUpdateResource(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabResults));
