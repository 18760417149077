import React from 'react';
import t from 'prop-types';
import {
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputRadio,
  InputText,
  InputWrapper,
  InputCheckbox,
} from 'fiducius-ui';

import { Fade } from '../../routing';

const LearnMore = ({ id, handleChange, data, errors }) => {
  return (
    <Form id={id} handleChange={handleChange}>
      <InputWrapper>
        <InputLabel>
          Would you like to learn more about loan forgiveness, refinance or credit repair options?
        </InputLabel>
        <InputRadio
          name="learnMore"
          value="Y"
          label="Yes"
          defaultChecked={data.learnMore === 'Y'}
        />
        <InputRadio name="learnMore" value="N" label="No" defaultChecked={data.learnMore === 'N'} />
      </InputWrapper>
      <Fade id="learnMoreGate" show={data.learnMore === 'Y'}>
        <InputWrapper>
          <InputLabel>
            Select the individuals below who have student loans in excess of $5,000
          </InputLabel>
          <InputCheckbox name="loansMyself" label="Myself" defaultChecked={data.loansMyself} />
          <InputCheckbox name="loansSpouse" label="Spouse" defaultChecked={data.loansSpouse} />
          <InputCheckbox
            name="loansChildren"
            label="Children"
            defaultChecked={data.loansChildren}
          />
          <InputCheckbox name="loansParents" label="Parents" defaultChecked={data.loansParents} />
        </InputWrapper>
        <InputWrapper error={!!errors.email}>
          <InputLabel>What is your email?</InputLabel>
          <InputError>{errors.email}</InputError>
          <InputGroup affordance="medium">
            <InputText name="email" defaultValue={data.email} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper>
          <InputLabel>May we communicate with you via SMS/text messages?</InputLabel>
          <InputRadio name="sendSMS" value="Y" label="Yes" defaultChecked={data.sendSMS === 'Y'} />
          <InputRadio name="sendSMS" value="N" label="No" defaultChecked={data.sendSMS === 'N'} />
        </InputWrapper>
      </Fade>
    </Form>
  );
};

LearnMore.propTypes = {
  handleChange: t.func.isRequired,
  id: t.string,
  data: t.object,
  errors: t.object,
};

export default LearnMore;
