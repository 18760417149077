import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Form,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputAddon,
  InputHelp,
  InputText,
  Header2,
  Modal,
} from 'fiducius-ui';

import { FlexEnd } from '../../../root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as faXIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';

import { formatAsMoney } from '../../../utils/format';

import { MessageDiv } from '../../../messages';

const StyledDiv = styled.div``;

const StyledDivCentered = styled.div`
  text-align: center;
`;

const StyledDivLeft = styled.div`
  text-align: left;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%
  text-align: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const InputLabelCentered = styled(InputLabel)`
  text-align: center;
`;

const StyledCard = styled(Card)`
  margin: 5px;

  @media only screen and (min-width: 0px) {
    width: 95%;
  }

  @media only screen and (min-width: 1024px) {
    width: 45%;
  }
`;

const StyledCardLoanBalance = styled(Card)`
  margin: 5px;
  max-height: 550px;
  width: 100%;
`;

const StyledCardFinal = styled(Card)`
  margin: 5px;
  max-height: 900px;
`;

const StyledCardStacked = styled(Card)`
  margin: 5px;
  min-height: 350px;
  max-width: 370px;
`;

const StyledCardText = styled(CardText)``;

const StyledCardTextTopMargin = styled(CardText)`
  margin-top: 10px;
`;

const StyledHeader2NoBottom = styled(Header2)`
  margin-bottom: 0px;
`;

const StyledDd = styled.dd`
  margin-left: 0;
  margin-bottom: 0;
  text-align: center;
`;

const StyledLinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: rgb(0, 123, 255);
  display: inline;
  margin: 0;
  padding: 0;
`;

const MessagePaddedDiv = styled.div`
  padding: 5px;
  margin: 5px;
  color: ${(p) => p.theme.textPrimary};
`;

const StyledInputTextDisabled = styled(InputText)`
  background-color: ${(p) => (p.theme.themeName === 'dark' ? '#2b3035' : '#d0d1d2')};
`;

const TaxEstimatorForm = ({ data = {}, onChange, messageContent, dataLoaded }) => {
  const [showFinalPage, setShowFinalPage] = useState(false);
  const [showAGIModal, setShowAGIModal] = useState(false);
  const [showHouseholdInformationModal, setShowHouseholdInformationModal] = useState(false);
  const [showAssistantModal, setShowAssistantModal] = useState(false);

  const [income, setIncome] = useState(0);
  const [clientAndSpouseIncome, setClientAndSpouseIncome] = useState(0);
  const [clientAndSpouseHouseholdSizeState, setClientAndSpouseHouseholdSize] = useState(0);
  const [householdSizeState, setHouseholdSize] = useState(0);
  const [repaymentPlan, setRepaymentPlan] = useState('');
  //const [clientAndSpouseRepaymentPlan, setClientAndSpouseRepaymentPlan] = useState('');
  const [clientMonthlyPayment, setClientMonthlyPayment] = useState(0);
  const [clientAndSpouseMonthlyPayment, setClientAndSpouseMonthlyPayment] = useState(0);
  const [spouseMonthlyPayment, setSpouseMonthlyPayment] = useState(0);
  const [clientAnnualLoanPayment, setClientAnnualLoanPayment] = useState(0);
  const [clientAndSpouseAnnualLoanPayment, setClientAndSpouseAnnualLoanPayment] = useState(0);
  const [repaymentSavings, setRepaymentSavings] = useState(0);
  const [clientLoanBalance, setClientLoanBalance] = useState(0);
  const [spouseLoanBalance, setSpouseLoanBalance] = useState(0);
  const [resultText, setResultText] = useState('');

  useEffect(() => {
    if (Object.keys(data).length > 0 && dataLoaded) {
      //setIncome(data.agi);
      //setClientAndSpouseIncome(data.agi);
      let initialRepaymentPlan = data.repaymentPlan !== 'ICR' ? 'SAVE' : 'ICR';
      setIncome(null);
      setClientAndSpouseIncome(null);
      setClientAndSpouseHouseholdSize(data.householdSize);
      const clientHouseholdSize =
        data.householdSize > 1 && data.maritalStatus === 'MARRY' && initialRepaymentPlan === 'SAVE'
          ? data.householdSize - 1
          : data.householdSize;
      setHouseholdSize(clientHouseholdSize);
      setRepaymentPlan(initialRepaymentPlan);
      setClientLoanBalance(data.totalPrincipal);
      //setSpouseLoanBalance(data.totalPrincipal);
      //setClientMonthlyAndAnnualPayment(data.agi, data.repaymentPlan, clientHouseholdSize);
      //setClientAndSpouseMonthlyAndAnnualPayment(data.agi, data.repaymentPlan, data.householdSize, data.totalPrincipal, data.totalPrincipal);
      setClientMonthlyAndAnnualPayment(
        0,
        initialRepaymentPlan,
        clientHouseholdSize,
        spouseMonthlyPayment
      );
      setClientAndSpouseMonthlyAndAnnualPayment(
        0,
        initialRepaymentPlan,
        data.householdSize,
        data.totalPrincipal,
        data.totalPrincipal,
        spouseMonthlyPayment
      );
    }
  }, [dataLoaded]);

  useEffect(() => {
    calculateRepaymentSavingsAndResultText();
  }, [clientAnnualLoanPayment, clientAndSpouseAnnualLoanPayment]);

  const calculateRepaymentSavingsAndResultText = () => {
    //const repaymentSavingsValue = clientAndSpouseAnnualLoanPayment - clientAnnualLoanPayment < 0 ? 0 : clientAndSpouseAnnualLoanPayment - clientAnnualLoanPayment;
    const repaymentSavingsValue = clientAndSpouseAnnualLoanPayment - clientAnnualLoanPayment;
    setRepaymentSavings(Math.abs(repaymentSavingsValue.toFixed(2)));
    setResultText(
      getResultText(
        clientAnnualLoanPayment,
        clientAndSpouseAnnualLoanPayment,
        repaymentSavingsValue
      )
    );
  };

  const setClientMonthlyAndAnnualPayment = (
    agi,
    repaymentPlan,
    householdSize,
    spMonthlyPayment
  ) => {
    let monthlyPayment = getMonthlyPaymentByPaymentType(agi, repaymentPlan, householdSize).toFixed(
      2
    );
    if (monthlyPayment < 0) {
      monthlyPayment = 0;
    }
    setClientMonthlyPayment(monthlyPayment);
    setSpouseMonthlyPayment(spMonthlyPayment || 0);
    let annualLoanPayment = (
      (parseFloat(monthlyPayment) + parseFloat(spMonthlyPayment || 0)) *
      12
    ).toFixed(2);
    setClientAnnualLoanPayment(annualLoanPayment);
  };

  const setClientAndSpouseMonthlyAndAnnualPayment = (
    agi,
    repaymentPlan,
    householdSize,
    spLoanBalance,
    clLoanBalance,
    spMonthlyPayment
  ) => {
    let balanceRatio = 1;
    if (parseFloat(clLoanBalance) + parseFloat(spLoanBalance) > 0) {
      balanceRatio =
        parseFloat(clLoanBalance) / (parseFloat(clLoanBalance) + parseFloat(spLoanBalance));
    }

    let monthlyPayment = (
      getMonthlyPaymentByPaymentType(agi, repaymentPlan, householdSize) * balanceRatio
    ).toFixed(2);

    if (monthlyPayment < 0) {
      monthlyPayment = 0;
    }
    setClientAndSpouseMonthlyPayment(monthlyPayment);

    let annualLoanPayment = (
      (parseFloat(monthlyPayment) + parseFloat(spMonthlyPayment || 0)) *
      12
    ).toFixed(2);
    setClientAndSpouseAnnualLoanPayment(annualLoanPayment);
  };

  const getMonthlyPayment = (agi, povertyTablePercentage, multiplier, householdSize) => {
    const povertyGuidelinesDB = data.povertyTable;
    let hhSizeFromDB = 1;

    if (householdSize > 10) {
      hhSizeFromDB =
        (povertyGuidelinesDB[9].lower48Value - povertyGuidelinesDB[8].lower48Value) *
          (householdSize - 10) +
        povertyGuidelinesDB[9].lower48Value;
    } else {
      hhSizeFromDB =
        povertyGuidelinesDB === undefined
          ? 1
          : householdSize > 0 && Object.keys(povertyGuidelinesDB).length > 0
          ? povertyGuidelinesDB[householdSize - 1].lower48Value
          : 1;
    }

    let retVal = ((agi - (povertyTablePercentage / 100) * hhSizeFromDB) * multiplier) / 12;
    return retVal;
  };

  const getMonthlyPaymentByPaymentType = (agi, paymentType, householdSize) => {
    let paymentAmount = 0;

    // multiplier is either a percentage, or could be a weighted amount for the SAVE plan type
    let multiplier = 1;

    switch (paymentType.toLowerCase()) {
      case 'repaye':
        multiplier = 10 / 100;
        paymentAmount = getMonthlyPayment(agi, 150, multiplier, householdSize);
        break;
      case 'paye':
        multiplier = 10 / 100;
        paymentAmount = getMonthlyPayment(agi, 150, multiplier, householdSize);
        break;
      case 'ibr':
        multiplier = 15 / 100;
        paymentAmount = getMonthlyPayment(agi, 150, multiplier, householdSize);
        break;
      case 'icr':
        multiplier = 20 / 100;
        paymentAmount = getMonthlyPayment(agi, 100, multiplier, householdSize);
        break;
      case '5%repaye':
        multiplier = 5 / 100;
        paymentAmount = getMonthlyPayment(agi, 225, multiplier, householdSize);
        break;
      case 'save':
        multiplier = data.saveMultiplier;
        paymentAmount = getMonthlyPayment(agi, 225, multiplier, householdSize);
        break;
      default:
        break;
    }

    return paymentAmount;
  };

  const getResultText = (
    clientAnnualLoanPayment,
    clientAndSpouseAnnualLoanPayment,
    repaymentSavings
  ) => {
    let finalResult = '';
    if (
      parseFloat(clientAnnualLoanPayment) + parseFloat(clientAndSpouseAnnualLoanPayment) === 0 &&
      (parseFloat(income) || 0) + (parseFloat(clientAndSpouseIncome) || 0) === 0
    ) {
      finalResult = 'Annual Loan Payment total is zero.';
    } else if (parseFloat(clientAnnualLoanPayment) < parseFloat(clientAndSpouseAnnualLoanPayment)) {
      finalResult =
        'If your tax cost is less than ' +
        formatAsMoney(Math.abs(repaymentSavings)) +
        ' you should file separately to create the most savings.';
    } else {
      finalResult =
        'If your tax cost is more than ' +
        formatAsMoney(Math.abs(repaymentSavings)) +
        ' you should file jointly to create the most savings.';
    }

    return finalResult;
  };

  const scrollToTop = () => {
    document.getElementById('taxtooltop').scrollIntoView({ behavior: 'smooth' });
  };

  const handleClientIncomeChange = (e) => {
    setIncome(e.target.value);
    setClientMonthlyAndAnnualPayment(
      e.target.value,
      clientRepaymentPlan,
      householdSizeState,
      spouseMonthlyPayment
    );
  };

  const handleClientAndSpouseIncomeChange = (e) => {
    setClientAndSpouseIncome(e.target.value);
    setClientAndSpouseMonthlyAndAnnualPayment(
      e.target.value,
      clientAndSpouseRepaymentPlan,
      clientAndSpouseHouseholdSizeState,
      spouseLoanBalance,
      clientLoanBalance,
      spouseMonthlyPayment
    );
  };

  const handleHouseholdSizeChange = (e) => {
    setHouseholdSize(e.target.value);
    setClientMonthlyAndAnnualPayment(
      clientAgiIncome,
      clientRepaymentPlan,
      e.target.value,
      spouseMonthlyPayment
    );
  };

  const handleClientAndSpouseHouseholdSizeChange = (e) => {
    setClientAndSpouseHouseholdSize(e.target.value);
    setClientAndSpouseMonthlyAndAnnualPayment(
      clientAndSpouseAgiIncome,
      clientAndSpouseRepaymentPlan,
      e.target.value,
      spouseLoanBalance,
      clientLoanBalance,
      spouseMonthlyPayment
    );
  };

  const handleClientRepaymentPlanChange = (e) => {
    setRepaymentPlan(e.target.value);
    setClientMonthlyAndAnnualPayment(
      clientAgiIncome,
      e.target.value,
      householdSizeState,
      spouseMonthlyPayment
    );
    setClientAndSpouseMonthlyAndAnnualPayment(
      clientAndSpouseAgiIncome,
      e.target.value,
      clientAndSpouseHouseholdSizeState,
      spouseLoanBalance,
      clientLoanBalance,
      spouseMonthlyPayment
    );
    //calculateRepaymentSavingsAndResultText();
  };

  // const handleClientAndSpouseRepaymentPlanChange = (e) => {
  //   setClientAndSpouseRepaymentPlan(e.target.value);
  // }

  const handleClientMonthlyPaymentChange = (e) => {
    setClientMonthlyPayment(e.target.value);
    let annualLoanPayment = (e.target.value * 12).toFixed(2);
    setClientAnnualLoanPayment(annualLoanPayment);
  };

  const handleClientAndSpouseMonthlyPaymentChange = (e) => {
    setClientAndSpouseMonthlyPayment(e.target.value);
    let annualLoanPayment = (e.target.value * 12).toFixed(2);
    setClientAndSpouseAnnualLoanPayment(annualLoanPayment);
  };

  const handleClientAnnualLoanPaymentChange = (e) => {
    setClientAnnualLoanPayment(e.target.value);
  };

  const handleClientAndSpouseAnnualLoanPaymentChange = (e) => {
    setClientAndSpouseAnnualLoanPayment(e.target.value);
  };

  const handleRepaymentSavingsChange = (e) => {
    setRepaymentSavings(e.target.value);
  };

  const handleClientLoanBalanceChange = (e) => {
    setClientLoanBalance(e.target.value);

    setClientAndSpouseMonthlyAndAnnualPayment(
      clientAndSpouseAgiIncome,
      clientAndSpouseRepaymentPlan,
      clientAndSpouseHouseholdSizeState,
      spouseLoanBalance,
      e.target.value,
      spouseMonthlyPayment
    );
  };

  const handleSpouseLoanBalanceChange = (e) => {
    setSpouseLoanBalance(e.target.value);

    setClientAndSpouseMonthlyAndAnnualPayment(
      clientAndSpouseAgiIncome,
      clientAndSpouseRepaymentPlan,
      clientAndSpouseHouseholdSizeState,
      e.target.value,
      clientLoanBalance,
      spouseMonthlyPayment
    );
  };

  const handleSpouseMonthlyPaymentChange = (e) => {
    setSpouseMonthlyPayment(e.target.value);
    setClientMonthlyAndAnnualPayment(
      clientAgiIncome,
      clientAndSpouseRepaymentPlan,
      householdSizeState,
      e.target.value
    );
    setClientAndSpouseMonthlyAndAnnualPayment(
      clientAndSpouseAgiIncome,
      clientAndSpouseRepaymentPlan,
      clientAndSpouseHouseholdSizeState,
      spouseLoanBalance,
      clientLoanBalance,
      e.target.value
    );
  };

  const clientAgiIncome = income; // || data.agi;
  const clientAndSpouseAgiIncome = clientAndSpouseIncome; // || data.agi;
  const clientAndSpouseHouseholdSize = clientAndSpouseHouseholdSizeState || data.householdSize;
  //const clientHouseholdSize = clientAndSpouseHouseholdSize > 1 ? clientAndSpouseHouseholdSize - 1 : clientAndSpouseHouseholdSize;
  //const clientHouseholdSize = data.householdSize > 1 && data.maritalStatus === 'MARRY' ? data.householdSize - 1 : data.householdSize;
  const clientRepaymentPlan = repaymentPlan || (data.repaymentPlan !== 'ICR' ? 'SAVE' : 'ICR');
  const clientAndSpouseRepaymentPlan =
    repaymentPlan || (data.repaymentPlan !== 'ICR' ? 'SAVE' : 'ICR');
  const clientFederalLoanBalance = clientLoanBalance || data.totalPrincipal;
  const spouseFederalLoanBalance = spouseLoanBalance; // || data.totalPrincipal;

  return (
    <>
      <Form id="taxtoolcalculator" defaults={data} handleChange={onChange}>
        <div className="row">
          <StyledCard brand="secondary">
            <CardBody>
              <StyledDiv>
                <StyledRow className="row">
                  <StyledColumn className="col-4 col-sm-4">
                    <InputLabelCentered htmlFor="income">You Only</InputLabelCentered>
                  </StyledColumn>
                  <StyledColumn className="col-4 col-sm-4"></StyledColumn>
                  <StyledColumn className="col-4 col-sm-4">
                    <InputLabelCentered htmlFor="income">You + Spouse</InputLabelCentered>
                  </StyledColumn>
                </StyledRow>
                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <InputText
                          id="clientAgiIncome"
                          name="clientAgiIncome"
                          defaultValue={clientAgiIncome}
                          onChange={handleClientIncomeChange}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn>
                    <StyledCardText>
                      <dl>
                        <StyledDd>Adjusted Gross Income</StyledDd>
                        <StyledDd>
                          <InputHelp>
                            <StyledLinkButton
                              onClick={(e) => {
                                e.preventDefault();
                                setShowAGIModal(true);
                              }}
                            >
                              How Do I Determine?
                            </StyledLinkButton>
                          </InputHelp>
                        </StyledDd>
                      </dl>
                    </StyledCardText>
                  </StyledColumn>
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <InputText
                          id="clientAndSpouseAgiIncome"
                          name="clientAndSpouseAgiIncome"
                          defaultValue={clientAndSpouseAgiIncome}
                          onChange={handleClientAndSpouseIncomeChange}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                </StyledRow>
                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <StyledInputTextDisabled
                          id="clientHouseHoldSize"
                          key="clientHouseHoldSize"
                          name="clientHouseHoldSize"
                          //defaultValue={householdSizeState}
                          value={householdSizeState}
                          onChange={handleHouseholdSizeChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn>
                    <StyledCardText>
                      <dl>
                        <StyledDd>Household Size</StyledDd>
                        <StyledDd>
                          <InputHelp>
                            <StyledLinkButton
                              onClick={(e) => {
                                e.preventDefault();
                                setShowHouseholdInformationModal(true);
                              }}
                            >
                              Household Information
                            </StyledLinkButton>
                          </InputHelp>
                        </StyledDd>
                      </dl>
                    </StyledCardText>
                  </StyledColumn>
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <StyledInputTextDisabled
                          id="clientAndSpouseHouseholdSize"
                          name="clientAndSpouseHouseholdSize"
                          defaultValue={clientAndSpouseHouseholdSize}
                          onChange={handleClientAndSpouseHouseholdSizeChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                </StyledRow>
                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        {/* <InputSelect
                              id="clientRepaymentPlan"
                              name="clientRepaymentPlan"
                              options={{
                                '': '',
                                PAYE: 'PAYE',
                                IBR: 'IBR',
                                ICR: 'ICR',
                                SAVE: 'SAVE',
                              }}
                              value={clientRepaymentPlan}
                              onChange={handleClientRepaymentPlanChange}
                              disabled={true}
                            /> */}
                        <StyledInputTextDisabled
                          id="clientRepaymentPlan"
                          name="clientRepaymentPlan"
                          value={clientRepaymentPlan}
                          onChange={handleClientRepaymentPlanChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn>
                    <StyledCardText>
                      <dl>
                        <StyledDd>Repayment Plan Type</StyledDd>
                      </dl>
                    </StyledCardText>
                  </StyledColumn>
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        {/* <InputSelect
                              id="clientAndSpouseRepaymentPlan"
                              name="clientAndSpouseRepaymentPlan"
                              options={{
                                '': '',
                                PAYE: 'PAYE',
                                IBR: 'IBR',
                                ICR: 'ICR',
                                SAVE: 'SAVE',
                              }}
                              value={clientAndSpouseRepaymentPlan}
                              onChange={handleClientRepaymentPlanChange}
                              disabled={true}
                            /> */}
                        <StyledInputTextDisabled
                          id="clientAndSpouseRepaymentPlan"
                          name="clientAndSpouseRepaymentPlan"
                          value={clientAndSpouseRepaymentPlan}
                          onChange={handleClientRepaymentPlanChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                </StyledRow>
                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="clientMonthlyPayment"
                          name="clientMonthlyPayment"
                          //defaultValue={clientMonthlyPayment}
                          value={clientMonthlyPayment}
                          onChange={handleClientMonthlyPaymentChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn>
                    <StyledCardText>
                      <dl>
                        <StyledDd>Monthly Payment</StyledDd>
                      </dl>
                    </StyledCardText>
                  </StyledColumn>
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="clientAndSpouseMonthlyPayment"
                          name="clientAndSpouseMonthlyPayment"
                          //defaultValue={clientAndSpouseMonthlyPayment}
                          value={clientAndSpouseMonthlyPayment}
                          onChange={handleClientAndSpouseMonthlyPaymentChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                </StyledRow>

                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="spouseMonthlyPayment"
                          name="spouseMonthlyPayment"
                          //defaultValue={clientMonthlyPayment}
                          value={spouseMonthlyPayment}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn>
                    <StyledCardText>
                      <dl>
                        <StyledDd>Spouse Monthly Payment</StyledDd>
                      </dl>
                    </StyledCardText>
                  </StyledColumn>
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="spouseMonthlyPaymentBoth"
                          name="spouseMonthlyPaymentBoth"
                          //defaultValue={clientAndSpouseMonthlyPayment}
                          value={spouseMonthlyPayment}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                </StyledRow>

                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="clientAnnualLoanPayment"
                          name="clientAnnualLoanPayment"
                          //defaultValue={clientAnnualLoanPayment}
                          value={clientAnnualLoanPayment}
                          onChange={handleClientAnnualLoanPaymentChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn>
                    <StyledCardText>
                      <dl>
                        <StyledDd>Annual Loan Payment</StyledDd>
                      </dl>
                    </StyledCardText>
                  </StyledColumn>
                  <StyledColumn className="col-6 col-sm-6">
                    <InputWrapper>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="clientAndSpouseAnnualLoanPayment"
                          name="clientAndSpouseAnnualLoanPayment"
                          //defaultValue={clientAndSpouseAnnualLoanPayment}
                          value={clientAndSpouseAnnualLoanPayment}
                          onChange={handleClientAndSpouseAnnualLoanPaymentChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                </StyledRow>
                <StyledRow className="row">
                  <StyledColumn className="col-6 col-sm-6"></StyledColumn>
                  <StyledColumn>
                    <InputWrapper>
                      <InputLabelCentered htmlFor="income">Repayment Savings</InputLabelCentered>
                      <InputGroup>
                        <InputAddon>$</InputAddon>
                        <StyledInputTextDisabled
                          id="repaymentSavings"
                          name="repaymentSavings"
                          //defaultValue={repaymentSavings}
                          value={repaymentSavings}
                          onChange={handleRepaymentSavingsChange}
                          disabled={true}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </StyledColumn>
                  <StyledColumn className="col-4 col-sm-4"></StyledColumn>
                </StyledRow>
                <StyledRow className="row">
                  <StyledColumn className="col-2 col-sm-2"></StyledColumn>
                  <StyledColumn>
                    <Card brand="primary">
                      <CardBody>
                        <dl>
                          <StyledDd>
                            <StyledHeader2NoBottom>Results</StyledHeader2NoBottom>
                          </StyledDd>
                        </dl>
                        <StyledCardText>
                          <dl>
                            <StyledDd>{resultText}</StyledDd>
                          </dl>
                        </StyledCardText>
                      </CardBody>
                    </Card>
                  </StyledColumn>
                  <StyledColumn className="col-2 col-sm-2"></StyledColumn>
                </StyledRow>
              </StyledDiv>
            </CardBody>
          </StyledCard>
          {showFinalPage && (
            <>
              <StyledColumn>
                <StyledRow>
                  <StyledCardFinal brand="secondary">
                    <CardBody>
                      <StyledDivCentered>
                        <StyledHeader2NoBottom>Helpful Information</StyledHeader2NoBottom>
                        <StyledRow>
                          <StyledCardTextTopMargin>
                            <StyledDivLeft>
                              <ul>
                                <li>
                                  Your household size may differ from the number you claim on your
                                  taxes and is generated from what you provided to Fiducius. When
                                  filing taxes, either spouse may claim dependents. (Please complete
                                  a Contact Us request if this needs to be updated.)
                                </li>
                                <br />
                                <li>
                                  If your repayment is SAVE, your spouse will not be included in the
                                  household size under your column due to rules regarding that
                                  repayment plan. However, they can be included as a household
                                  member when their income is included and filing joint.
                                </li>
                                <br />
                                <li>
                                  Want to lower your spouse's student loan payment too?
                                  <ul>
                                    <li>
                                      <a href="https://myfiducius.com/register" target="_blank">
                                        Register an account
                                      </a>{' '}
                                      and let us help them today.
                                    </li>
                                  </ul>
                                </li>
                                <br />
                                <li>
                                  The results will provide your annual student loan repayment
                                  savings between filing your taxes jointly or separately. This
                                  should be used when comparing your tax filing difference.
                                </li>
                                <br />
                                <li>
                                  Please consult a tax professional for specific tax related needs
                                  and share your results with them by printing a copy.
                                </li>
                                <br />
                                <li>
                                  If you live in a community property state, See{' '}
                                  <a
                                    href="https://www.irs.gov/pub/irs-pdf/p555.pdf"
                                    target="_blank"
                                  >
                                    IRS Publication 555
                                  </a>{' '}
                                  page 10 for more information regarding filing separate returns.
                                </li>
                              </ul>
                            </StyledDivLeft>
                            <Button
                              brand="primary"
                              onClick={() => {
                                window.print();
                              }}
                            >
                              {' '}
                              {'Print'}{' '}
                            </Button>
                          </StyledCardTextTopMargin>
                        </StyledRow>
                      </StyledDivCentered>
                    </CardBody>
                  </StyledCardFinal>
                </StyledRow>
                <StyledRow>
                  <StyledColumn>
                    <div className="row">
                      <div className="col">
                        <MessagePaddedDiv>
                          <StyledDivCentered>
                            <img src="https://cdn2.getfiducius.com/crm/_images/calcqrcode1.png" />
                          </StyledDivCentered>
                        </MessagePaddedDiv>
                      </div>
                    </div>
                  </StyledColumn>
                </StyledRow>
              </StyledColumn>
              <StyledRow>
                <StyledColumn></StyledColumn>
                <StyledColumn>
                  <div className="row">
                    <div className="col-2"></div>
                    <div id="backbutton" className="col-6">
                      <InputWrapper>
                        <Button
                          brand="primary"
                          onClick={() => {
                            setShowFinalPage(false);
                            scrollToTop();
                          }}
                        >
                          {' '}
                          {'Back'}{' '}
                        </Button>
                      </InputWrapper>
                    </div>
                    <div className="col-2"></div>
                  </div>
                </StyledColumn>
              </StyledRow>
            </>
          )}
          {!showFinalPage && (
            <>
              <StyledColumn>
                <StyledRow>
                  <StyledCardLoanBalance brand="secondary">
                    <CardBody>
                      <StyledDivCentered>
                        <StyledRow>
                          <StyledRow className="row">
                            <StyledColumn>
                              <InputLabelCentered htmlFor="income">
                                Your Spouse's Loan Balance
                              </InputLabelCentered>
                            </StyledColumn>
                          </StyledRow>
                          <StyledRow className="row">
                            <StyledColumn>
                              <InputWrapper>
                                <InputGroup>
                                  <InputAddon>$</InputAddon>
                                  <InputText
                                    id="spouseLoanBalance"
                                    name="spouseLoanBalance"
                                    defaultValue={spouseFederalLoanBalance}
                                    onChange={handleSpouseLoanBalanceChange}
                                  />
                                </InputGroup>
                              </InputWrapper>
                            </StyledColumn>
                          </StyledRow>

                          <StyledRow className="row">
                            <StyledColumn>
                              <InputLabelCentered htmlFor="income">
                                Your Spouse's Monthly Payment
                              </InputLabelCentered>
                            </StyledColumn>
                          </StyledRow>
                          <StyledRow className="row">
                            <StyledColumn>
                              <InputWrapper>
                                <InputGroup>
                                  <InputAddon>$</InputAddon>
                                  <InputText
                                    id="spouseLoanBalance"
                                    name="spouseLoanBalance"
                                    defaultValue={spouseMonthlyPayment}
                                    onChange={handleSpouseMonthlyPaymentChange}
                                  />
                                </InputGroup>
                              </InputWrapper>
                            </StyledColumn>
                          </StyledRow>

                          <StyledRow className="row">
                            <StyledColumn>
                              <InputLabelCentered htmlFor="income">
                                Your Loan Balance
                              </InputLabelCentered>
                            </StyledColumn>
                          </StyledRow>
                          <StyledRow className="row">
                            <StyledColumn>
                              <InputWrapper>
                                <InputGroup>
                                  <InputAddon>$</InputAddon>
                                  <InputText
                                    id="clientLoanBalance"
                                    name="clientLoanBalance"
                                    defaultValue={clientFederalLoanBalance}
                                    onChange={handleClientLoanBalanceChange}
                                  />
                                </InputGroup>
                              </InputWrapper>
                            </StyledColumn>
                          </StyledRow>
                        </StyledRow>
                      </StyledDivCentered>
                      <StyledRow>
                        <StyledDivLeft>
                          <InputHelp>
                            *Include only federal student loans not in school deferment or default.
                          </InputHelp>
                          <br />
                          <InputHelp>
                            *If Your Loan Balance is showing a significantly different balance,
                            please complete a Contact Us request to let us know.
                          </InputHelp>
                        </StyledDivLeft>
                      </StyledRow>
                    </CardBody>
                  </StyledCardLoanBalance>
                </StyledRow>
              </StyledColumn>
              <StyledColumn>
                {messageContent.length > 0 && (
                  <div id="informationbox">
                    <StyledRow>
                      <StyledCardStacked brand="secondary">
                        <CardBody>
                          <StyledHeader2NoBottom>Information</StyledHeader2NoBottom>
                          <StyledRow>
                            <StyledCardTextTopMargin>
                              <div dangerouslySetInnerHTML={{ __html: messageContent }}></div>
                            </StyledCardTextTopMargin>
                          </StyledRow>
                        </CardBody>
                      </StyledCardStacked>
                    </StyledRow>
                  </div>
                )}
                <StyledRow>
                  <StyledCardStacked brand="secondary">
                    <CardBody>
                      <StyledDiv>
                        <StyledHeader2NoBottom>Tax Preparer Assistant</StyledHeader2NoBottom>
                        {/* <InputHelp>
                          <StyledLinkButton
                            onClick={(e) => {
                              e.preventDefault();
                              setShowAssistantModal(true);
                            }}
                          >
                            What is this?
                          </StyledLinkButton>
                        </InputHelp> */}
                        <StyledRow>
                          <StyledCardTextTopMargin>
                            <StyledDivLeft>
                              <p>
                                Click <b>Download</b> to print a copy of your results.
                              </p>
                              <p>
                                Use it when preparing your taxes or provide it to your tax preparer
                                for reference.
                              </p>
                              <p>They can view the video above when scanning the QR code.</p>
                            </StyledDivLeft>
                          </StyledCardTextTopMargin>
                        </StyledRow>
                      </StyledDiv>
                    </CardBody>
                  </StyledCardStacked>
                </StyledRow>
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col">
                    <InputWrapper>
                      <Button
                        brand="primary"
                        onClick={() => {
                          setShowFinalPage(true);
                          scrollToTop();
                        }}
                      >
                        {' '}
                        {'Download'}{' '}
                      </Button>
                    </InputWrapper>
                  </div>
                  <div className="col-2"></div>
                </div>
              </StyledColumn>
            </>
          )}
        </div>
      </Form>

      <Modal isOpen={showAGIModal} theme={'position:fixed;'}>
        <Card>
          <FlexEnd>
            <InputWrapper>
              <Button
                brand="lowContrast"
                onClick={() => {
                  setShowAGIModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXIcon} />
              </Button>
            </InputWrapper>
          </FlexEnd>

          <MessagePaddedDiv id="messageSection">
            <Header2>Adjusted Gross Income</Header2>
            <StyledDivLeft>
              <p>
                There are three ways that you can input your income:
                <br />
                <br />
                <ul>
                  <li>
                    Enter the Wages reported in Box 1 on your <b>W2</b>.
                  </li>
                  <li>
                    Enter the Year-to-Date taxable income from your last paycheck of the previous
                    year.
                  </li>
                  <li>
                    Enter Gross Income minus your total pre-tax deductions from your last{' '}
                    <b>paycheck</b> of the previous year.
                  </li>
                </ul>
              </p>
              <p>
                <InputHelp>*Enter all income for both you and your spouse in each field.</InputHelp>
                <br />
                <InputHelp>
                  *If you live in a community property state, divide your and your spouse's income
                  by two and put it under your income. Then, include both incomes in the next
                  column.
                </InputHelp>
                <br />
                <InputHelp>
                  *See{' '}
                  <a href="https://www.irs.gov/pub/irs-pdf/p555.pdf" target="_blank">
                    IRS Publication 555
                  </a>{' '}
                  page 10 for more information regarding filing separate returns in a community
                  property state.
                </InputHelp>
              </p>
            </StyledDivLeft>
          </MessagePaddedDiv>
          <CardFooter>
            <Button
              brand="lowContrast"
              onClick={() => {
                setShowAGIModal(false);
              }}
            >
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
      <Modal isOpen={showHouseholdInformationModal} theme={'position:fixed;'}>
        <Card>
          <FlexEnd>
            <InputWrapper>
              <Button
                brand="lowContrast"
                onClick={() => {
                  setShowHouseholdInformationModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXIcon} />
              </Button>
            </InputWrapper>
          </FlexEnd>

          <MessagePaddedDiv id="messageSection">
            <Header2>Household Size</Header2>
            <StyledDivLeft>
              <p>
                Your household size may differ from the number you claim on your taxes and is
                generated from what you provided to Fiducius.
              </p>
              <p>
                The household size includes you and your children, including unborn children who
                will be born during the year, if the children will receive more than half their
                support from you.
              </p>
              <p>
                <i>Please complete a Contact Us request if this needs to be updated.</i>
              </p>
              <p>
                If your repayment is SAVE, your spouse will not be included in the household size
                under your column due to rules regarding that repayment plan. However, can be
                included as a household member when their income is included and filing joint.
              </p>
            </StyledDivLeft>
          </MessagePaddedDiv>
          <CardFooter>
            <Button
              brand="lowContrast"
              onClick={() => {
                setShowHouseholdInformationModal(false);
              }}
            >
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
      <Modal isOpen={showAssistantModal} theme={'position:fixed;'}>
        <Card>
          <FlexEnd>
            <InputWrapper>
              <Button
                brand="lowContrast"
                onClick={() => {
                  setShowAssistantModal(false);
                }}
              >
                <FontAwesomeIcon icon={faXIcon} />
              </Button>
            </InputWrapper>
          </FlexEnd>

          <MessagePaddedDiv id="messageSection">
            <p>Assistant Modal Text Here!</p>
          </MessagePaddedDiv>
          <CardFooter>
            <Button
              brand="lowContrast"
              onClick={() => {
                setShowAssistantModal(false);
              }}
            >
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
    </>
  );
};

TaxEstimatorForm.propTypes = {
  action: t.string,
  data: t.object,
  errors: t.object,
  isLoading: t.bool,
  isOpen: t.bool,
  onChange: t.func.isRequired,
  agi: t.number,
};

export default TaxEstimatorForm;
