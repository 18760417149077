import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import QpPie from '../components/qualified-payment-pie';
const StyledCardText = styled(CardText)`
  text-align: left;
`;

class AboutAssure extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <DashboardSection>
        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <CardTitle>
                  Fiducius will help you navigate the new Limited Waiver Opportunity
                </CardTitle>
                <CardText>
                  <p>
                    The new LWO from the Department of Education is an unprecedented opportunity for
                    you to get more forgiveness through PSLF. This program has{' '}
                    <u>
                      <strong>temporarily waived</strong>
                    </u>{' '}
                    PSLF requirements until October 31, 2022:
                    <StyledCardText>
                      <ol>
                        <li>Past payments made under any repayment plan type may qualify</li>
                        <li>Past ineligible loan payments due to improper loan type may qualify</li>
                      </ol>
                    </StyledCardText>
                  </p>
                  <p>
                    As a result of these waivers, some borrowers will receive more qualifying
                    payments and move closer to forgiveness. Some will have all their loans forgiven
                    immediately. By working with Fiducius, Jenna Webb from Pennsylvania just
                    received her approval letter confirming $71,521 in loan forgiveness and reducing
                    her repayment period by six years.
                  </p>
                  <p>
                    If you have federal loans and meet all program requirements, this could be you.
                    However, the Department of Education estimates that only 1.5% of federal
                    borrowers are eligible for LWO. Because of this, it's important that you take
                    steps to confirm whether or not you are among the fortunate few and exactly how
                    many additional qualifying payments you should receive.
                  </p>
                  <p>
                    As you might expect with a federal program, it's not straightforward and easy.
                    To begin with, very few borrowers have done everything correctly to be qualified
                    for forgiveness. That means you have work to do. Like a forensic accountant,
                    you'll need to dig into your loan payment history and provide the correct
                    supporting documentation. Even if you get it right, many borrowers tell us they
                    don't fully trust the Department of Education or their lender to deliver as they
                    should.
                  </p>
                </CardText>

                <CardTitle>
                  Assure&#8480;… get all you deserve from the Limited Waiver Opportunity
                </CardTitle>
                <CardText>
                  <p>
                    Put all your doubts and frustrations aside. For more than 10 years, Fiducius has
                    helped thousands of borrowers get the most out of PSLF. We're at it again, now
                    providing the expertise and advocacy you need to tackle the LWO.
                  </p>
                  <p>
                    Introducing Assure&#8480;. It's the only solution of its kind, guaranteeing that
                    borrowers like you receive all the additional qualifying payments and loan
                    forgiveness you deserve:
                  </p>

                  <ul>
                    <li>Step-by-step instructions ensure you meet all program requirements</li>
                    <li>Accurate documentation to provide all mandatory information </li>
                    <li>Dashboard to monitor and manage your progress</li>
                    <li>Experienced support team you can trust if you have questions</li>
                  </ul>
                  <p>
                    If you're not eligible for LWO and can't benefit from Assure&#8480;, our free
                    Student Loan Financial Planning service will quickly help you understand other
                    options to take control of your student loans, including getting the most out of
                    PSLF.
                  </p>
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(AboutAssure));
