import React from 'react';
import t from 'prop-types';
import { Card, CardBody, CardTitle, ChartLine } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils/format';

const TotalRepayment = ({ totalStd = 0, totalIdr = 0, years = 0 }) => (
  <Card>
    <CardBody>
      <CardTitle>Total Repayment - IDR vs Standard</CardTitle>
      <ChartLine
        lines={[
          {
            brand: 'secondary',
            dataType: 'currency',
            fill: true,
            title: 'Total IDR',
            label: 'IDR',
            data: [0.0, totalIdr],
            xDataType: 'category',
            yDataType: 'linear',
          },
          {
            brand: 'primary',
            dataType: 'currency',
            fill: true,
            title: 'Total Standard',
            label: 'Std',
            data: [0.0, totalStd],
            xDataType: 'category',
            yDataType: 'linear',
          },
        ]}
        config={{
          multiAxis: false,
          labels: ['0', years.toString()],
          xScaleLabelDisplay: true,
          xScaleLabelLabelString: 'Years',
          yScaleLabelDisplay: false,
          yScaleLabelLabelString: '',
          formatter: formatAsMoney,
        }}
      />
    </CardBody>
  </Card>
);

TotalRepayment.propTypes = {
  totalIdr: t.number,
  totalStd: t.number,
  years: t.number,
};

export default TotalRepayment;
