import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { safeAccess } from '../../utils';
import { getUserId } from '../../auth';
import { Alert } from 'fiducius-ui';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

import {
  idrCopyCompleteToForm,
  idrLoadComplete,
  idrHandleFormChange,
} from '../../workflows/idr/redux/operations';

const RepaymentComplete = withRouter((props) => {
  return (
    <TodoComplete
      {...props}
      onComplete={() => props.history.push('/documents')}
      text={
        <>
          <strong>Step 1 – Sign your application</strong>
          <ul>
            <li>
              Click on “Income-Driven Repayment Application” in the My Documents section of your
              portal.
            </li>
            <li>Complete the short questionnaire.</li>
            <li>Electronically sign the application.</li>
            <li>
              Please verify your Social Security Number is present on the six four pages of your
              application prior to submitting it.
            </li>
            {props.isMarried && (
              <li>
                Please verify your spouse's Social Security Number is present on Question 9 (page
                2).
              </li>
            )}
          </ul>
          <strong>Step 2 – Submit your application to MOHELA</strong>
          <ul>
            <li>
              Upload the signed form to{' '}
              <a href="https://www.mohela.com" target="_blank" rel="noopener noreferrer">
                MOHELA
              </a>{' '}
              if you have an account.
              <ul>
                <li>Select "File Upload" on the top right</li>
                <li>Select "Repayment"</li>
                <li>Select "Income-Driven Repayment"</li>
              </ul>
            </li>
            Or
            <li>Fax your application to MOHELA at 866.222.7060 if you do not have an account.</li>
          </ul>
          {props.form.incomeDocToInclude != null && (
            <Alert brand="warning">{props.form.incomeDocToInclude}</Alert>
          )}
        </>
      }
      title="Submit your Repayment Application"
    />
  );
});

const mapStateToProps = (state) => {
  const userId = getUserId(state);
  let form = safeAccess(state, `idr.cache[${userId}]`, {});
  return {
    form: form,
    request: state.todos.requests.updateResource,
    isMarried: safeAccess(state, 'auth.cache.permissions.married', false),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateForm: (state, attributes) => dispatch(idrHandleFormChange(state, attributes)),
  onLoad: async () => {
    await dispatch(idrLoadComplete(ownProps.id));
    dispatch(idrCopyCompleteToForm());
  },
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RepaymentComplete);
