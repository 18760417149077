import { FormValidator } from '../../../utils';

export class TuitionValidator extends FormValidator {
  institutionId(validator, state) {
    validator.isRequired();
  }

  termId(validator, state) {
    if (state.institutionId !== undefined) {
      validator.isRequired();
    }
  }

  className(validator) {
    validator.isRequired();
    validator.hasMaxLength(150);
  }

  hours(validator) {
    if (validator.value) {
      validator.validNumber();
      validator.isLessThan(100);
    }
  }
}

export default TuitionValidator;
