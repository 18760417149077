import React from 'react';
import { connect } from 'react-redux';
import { CardText } from 'fiducius-ui';

const TuitionApprovalInvalid = () => {
  return (
    <>
      <CardText>
        <p>You've used an invalid link to come to this page.</p>
      </CardText>
    </>
  );
};

TuitionApprovalInvalid.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionApprovalInvalid);
