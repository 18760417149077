import React from 'react';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import { Card, CardBody, CardTitle } from 'fiducius-ui';

class ComponentStepDisplay extends React.Component {
  static propTypes = {
    title: t.string.isRequired,
    show: t.bool,
  };
  constructor(props) {
    super(props);
    this.state = {
      showBody: false,
      showProp: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate() {
    let props = this.props;
    if (this.state.showProp !== props.show) {
      this.setState({
        showProp: props.show,
      });
    }
    if (
      this.props.show !== undefined &&
      this.state.showBody === false &&
      this.state.showBody !== this.state.showProp
    ) {
      this.handleShowProp();
    }
  }

  handleClick = () => {
    this.setState({
      showBody: !this.state.showBody,
    });
  };

  handleShowProp = () => {
    this.setState({
      showBody: this.props.show,
    });
  };

  render() {
    let props = this.props;

    return (
      <>
        <Card>
          <CardBody>
            <CardTitle onClick={this.handleClick}>
              <FontAwesomeIcon icon={this.state.showBody ? faAngleDown : faAngleRight} fixedWidth />
              {props.title}
            </CardTitle>
            {this.state.showBody && props.children}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default ComponentStepDisplay;
