import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { CardTitle, ChartBar } from 'fiducius-ui';

import { safeAccess, formatAsMoney, flatten } from '../../../utils';

import { withPermissions } from '../../../routing';

import { questionnaireBtsLoadCollection } from '../../../workflows/questionnaire-bts/redux/operations';

class BtsBorrowingCompare extends React.Component {
  static propTypes = {
    data: t.object,
    loadData: t.func,
    requestData: t.bool,
    request: t.object.isRequired,
    showPreviousYears: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { requestData } = this.props;
    if (requestData) {
      this.props.loadData();
    }
  }

  componentDidUpdate() {}

  getChartValues = (initialBalance, newBalance, recommendedBorrowing) => {
    const { showPreviousYears } = this.props;
    let retArray = [];

    for (let i = 0; i <= newBalance.length; i++) {
      if (i === 0) {
        retArray.push(initialBalance[i]);
      } else if (!showPreviousYears) {
        if (i === newBalance.length) {
          retArray.push(newBalance[i - 1]);
        }
      } else {
        retArray.push(newBalance[i - 1]);
      }
    }
    retArray.push(initialBalance[0] / 1 + recommendedBorrowing);

    return retArray;
  };

  removeEmptyEntries = (array) => {
    if (array.length > 0) {
      var retArray = [];
      for (let i = 0; i <= array.length; i++) {
        if (array[i] !== undefined) {
          array[i].forEach((a) => {
            if (a !== undefined && a !== '') retArray.push(a);
          });
        }
      }
    }
    return retArray;
  };

  getChartLabels = (numRows) => {
    const { showPreviousYears } = this.props;

    let labels = [];
    for (let i = 0; i <= numRows; i++) {
      if (i === 0) {
        labels.push('Initial Balance');
      } else if (i === numRows) {
        labels.push('Current Snapshot');
      } else if (showPreviousYears) {
        labels.push('Year ' + i);
      }
    }
    labels.push('Presented Plan');
    return labels;
  };

  getBackColors = (numRows) => {
    const { showPreviousYears } = this.props;
    let colors = [];

    for (let i = 0; i <= numRows; i++) {
      if (i === 0) {
        //initial balance - blue
        colors.push('#2aa9be');
      } else if (!showPreviousYears) {
        if (i === numRows - 1) {
          //orange
          colors.push('#ff774d');
        } else if (numRows === i) {
          //green
          colors.push('#39ae54');
        }
      } else {
        colors.push('#ff774d');
      }
    }
    //presented strategy total
    colors.push('#39ae54');
    return colors;
  };

  render() {
    const { data } = this.props;

    const variables = [
      'id',
      'initialLoanBalance',
      'newLoanAmtBorrowed',
      'estimatedLoanBalance',
      'recommendedBorrowing',
      'recommendedBorrowingTuition',
      'recommendedBorrowingLivingExp',
      'estimatedIdr',
      'graduationIncome',
      'monthlyStandardPayment',
    ];
    let loanLabels = [];
    let backgroundArray = [];
    let loanBalance = [];

    let initLoanBalance = Object.values(data).map((a) =>
      variables.map((h) => (h === 'initialLoanBalance' ? a[h] : undefined))
    );

    let newLoanBalance = Object.values(data).map((a) =>
      variables.map((h) => (h === 'estimatedLoanBalance' ? a[h] : undefined))
    );

    initLoanBalance = this.removeEmptyEntries(initLoanBalance);
    newLoanBalance = this.removeEmptyEntries(newLoanBalance);

    if (
      initLoanBalance !== undefined &&
      newLoanBalance !== undefined &&
      initLoanBalance.length > 0
    ) {
      loanBalance = this.getChartValues(
        initLoanBalance,
        newLoanBalance,
        flatten(data).recommendedBorrowing
      );
      loanLabels = this.getChartLabels(newLoanBalance.length);
      backgroundArray = this.getBackColors(newLoanBalance.length);
    }

    return (
      <>
        <CardTitle>
          <span>Borrowing Comparison</span>
        </CardTitle>
        <ChartBar
          bars={[
            {
              data: loanBalance,
              title: 'Loan Balance',
              allSameColor: true,
              backgroundColor: backgroundArray,
            },
          ]}
          config={{
            labels: loanLabels,
            formatter: (value) => formatAsMoney(value),
            min: 0,
            showXAxisLabel: true,
            xAxisLabel: 'Snapshot Review',
            showYAxisLabel: true,
            yAxisLabel: 'Loan Balance',
            axisLabelFontSize: 16,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.questionnaireBts;
  const formData = safeAccess(state.questionnaireBts, 'cache');

  return {
    data: formData,
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(questionnaireBtsLoadCollection(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(BtsBorrowingCompare));
