import React from 'react';
import t from 'prop-types';
import {
  Card,
  ChartLine,
  CardTitle,
  CardBody,
  CardText,
  Form,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputNumeric,
} from 'fiducius-ui';

import { formatAsMoney } from '../../../utils/format';
import { MessageDiv } from '../../../messages';

class InterestComparisonTool extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentBalance: 0,
      currentRepaymentTerm: 240,
      currentInterest: 0,

      newRepaymentTerm: 120,
      newInterest: 0,
    };

    this.handleCurrentBalanceChange = this.handleCurrentBalanceChange.bind(this);
    this.handleCurrentInterest = this.handleCurrentInterest.bind(this);
    this.handleCurrentTerm = this.handleCurrentTerm.bind(this);

    this.handleNewInterest = this.handleNewInterest.bind(this);
    this.handleNewTerm = this.handleNewTerm.bind(this);
  }

  handleCurrentBalanceChange(event) {
    this.setState({ currentBalance: event.target.value });
  }
  handleCurrentInterest(event) {
    this.setState({ currentInterest: event.target.value });
  }
  handleCurrentTerm(event) {
    this.setState({ currentRepaymentTerm: event.target.value });
  }
  handleNewInterest(event) {
    this.setState({ newInterest: event.target.value });
  }
  handleNewTerm(event) {
    this.setState({ newRepaymentTerm: event.target.value });
  }

  render() {
    const { data = {}, onChange } = this.props;
    const currentBalance = this.state.currentBalance || data.totalPrincipal;
    const currentRepaymentTerm = this.state.currentRepaymentTerm;
    const currentInterest = this.state.currentInterest || data.avgInterestRate;
    const cInterest = currentInterest / 100;

    const newRepaymentTerm = this.state.newRepaymentTerm;
    const newInterest = this.state.newInterest || data.avgInterestRate;
    const nInterest = newInterest / 100;

    let currentPayment = (
      (currentBalance * (cInterest / 12.0)) /
      (1 - Math.pow(1.0 + cInterest / 12.0, -currentRepaymentTerm))
    ).toFixed(2);
    let newPayment = (
      (currentBalance * (nInterest / 12.0)) /
      (1 - Math.pow(1.0 + nInterest / 12.0, -newRepaymentTerm))
    ).toFixed(2);

    let currentSchedule = [];
    let ticks = [];
    let longestPaymentTerm = this.state.currentRepaymentTerm;
    if (this.state.currentRepaymentTerm / 1 < this.state.newRepaymentTerm / 1) {
      longestPaymentTerm = this.state.newRepaymentTerm;
    }

    let loopPrincipal = currentBalance;
    let interestMonthlyPayment = 0;
    let principalMonthlyPayment = 0;

    for (let i = longestPaymentTerm - 1; i >= 0; i--) {
      interestMonthlyPayment = (cInterest / 12) * loopPrincipal;
      principalMonthlyPayment = currentPayment - interestMonthlyPayment;
      loopPrincipal = loopPrincipal - principalMonthlyPayment;

      if ((i % 12 === 0 && i !== 0 && loopPrincipal > 0) || i === longestPaymentTerm - 1) {
        if (i === longestPaymentTerm - 1) {
          ticks.push('0');
          currentSchedule.push(currentBalance);
        } else {
          ticks.push((longestPaymentTerm / 12 - i / 12).toFixed(2).toString());
          currentSchedule.push(loopPrincipal);
        }
      } else if (i % 12 === 0 && i !== 0 && loopPrincipal < 0) {
        ticks.push((longestPaymentTerm / 12 - i / 12).toFixed(2).toString());
        currentSchedule.push(0);
      } else if (i === 0) {
        ticks.push((longestPaymentTerm / 12 - i / 12).toFixed(2).toString());
        currentSchedule.push(0);
      }
      //loopPrincipal = loopPrincipal - principalMonthlyPayment
    }

    let newSchedule = [];
    let newLoopPrincipal = currentBalance;
    let newinterestMonthlyPayment = 0;
    let newprincipalMonthlyPayment = 0;

    for (let i = longestPaymentTerm - 1; i >= 0; i--) {
      newinterestMonthlyPayment = (nInterest / 12) * newLoopPrincipal;
      newprincipalMonthlyPayment = newPayment - newinterestMonthlyPayment;

      newLoopPrincipal = newLoopPrincipal - newprincipalMonthlyPayment;
      if ((i % 12 === 0 && i !== 0 && newLoopPrincipal > 0) || i === longestPaymentTerm - 1) {
        if (i === longestPaymentTerm - 1) {
          newSchedule.push(currentBalance);
        } else {
          newSchedule.push(newLoopPrincipal);
        }
      } else if (i % 12 === 0 && i !== 0 && newLoopPrincipal < 0) {
        newSchedule.push(0);
      } else if (i === 0) {
        newSchedule.push(0);
      }
      //newLoopPrincipal = newLoopPrincipal - newprincipalMonthlyPayment
    }

    return (
      <Form id="interestComparison" defaults={data} handleChange={onChange}>
        <CardTitle>Interest Comparison Tool</CardTitle>
        <p>
          <MessageDiv messageType={'Interest_Comparison'} />
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <p></p>
            <p></p>
            <div className="row">
              <div className="col-12 col-md-12 col-sm-10 col-lg-6">
                <p></p>
                <p></p>
                <InputWrapper>
                  <InputLabel htmlFor="LoanBalance">Total Balance</InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="ClientTotalLoanBalance"
                      name="ClientTotalLoanBalance"
                      defaultValue={data.totalPrincipal}
                      onChange={this.handleCurrentBalanceChange}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 col-sm-10 col-lg-6">
                <InputWrapper>
                  <InputLabel htmlFor="state">Interest Rate</InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="CurrentInterestRate"
                      name="CurrentInterestRate"
                      defaultValue={currentInterest}
                      onChange={this.handleCurrentInterest}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-12 col-md-12 col-sm-10 col-lg-6">
                <InputWrapper>
                  <InputLabel htmlFor="state">Repayment Term (Months)</InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="CurrentTermMonths"
                      name="CurrentTermMonths"
                      defaultValue={240}
                      onChange={this.handleCurrentTerm}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 col-sm-10 col-lg-6">
                <InputWrapper>
                  <InputLabel htmlFor="state">New Interest Rate</InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="CurrentInterestRate"
                      name="CurrentInterestRate"
                      defaultValue={data.avgInterestRate}
                      onChange={this.handleNewInterest}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-12 col-md-12 col-sm-10 col-lg-6">
                <InputWrapper>
                  <InputLabel htmlFor="state">New Repayment Term (Months)</InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="CurrentTermMonths"
                      name="CurrentTermMonths"
                      defaultValue={this.state.newRepaymentTerm}
                      onChange={this.handleNewTerm}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
          </div>

          <div className="col-10 col-md-10 col-sm-10 col-lg-6">
            <Card brand="primary">
              <CardBody>
                <div className="row">
                  <div className="col-10 col-md-10 col-sm-10 col-lg-6">
                    <CardTitle>Current Terms</CardTitle>
                    <CardText>
                      <dl>
                        <dt>Monthly Payment</dt>
                        <dd>{formatAsMoney(currentPayment) || '—'}</dd>
                        <dt>Total Principal + Interest</dt>
                        <dd>{formatAsMoney(currentPayment * currentRepaymentTerm) || '—'}</dd>
                        <dt>Principal Paid</dt>
                        <dd>{formatAsMoney(currentBalance) || '—'}</dd>
                        <dt>Interest Paid</dt>
                        <dd>
                          {formatAsMoney(currentPayment * currentRepaymentTerm - currentBalance) ||
                            '—'}
                        </dd>
                      </dl>
                    </CardText>
                  </div>
                  <div className="col-8 col-md-6">
                    <CardTitle>New Terms</CardTitle>
                    <CardText>
                      <dl>
                        <dt>Monthly Payment</dt>
                        <dd>{formatAsMoney(newPayment)}</dd>
                        <dt>Total Principal + Interest</dt>
                        <dd>{formatAsMoney(newPayment * newRepaymentTerm) || '—'}</dd>
                        <dt>Principal Paid</dt>
                        <dd>{formatAsMoney(currentBalance) || '—'}</dd>
                        <dt>Interest Paid</dt>
                        <dd>
                          {formatAsMoney(newPayment * newRepaymentTerm - currentBalance) || '—'}
                        </dd>
                      </dl>
                    </CardText>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <Card brand="secondary">
          <CardBody>
            <CardTitle>
              <span>Yearly Balance Comparison</span>
            </CardTitle>
            <ChartLine
              lines={[
                {
                  brand: 'secondary',
                  dataType: 'currency',
                  fill: true,
                  title: 'New Terms',
                  data: newSchedule,
                  xDataType: 'category',
                  yDataType: 'linear',
                },
                {
                  brand: 'primary',
                  dataType: 'currency',
                  fill: true,
                  title: 'Current Terms',
                  data: currentSchedule,
                  xDataType: 'category',
                  yDataType: 'linear',
                },
              ]}
              config={{
                labels: ticks,
                formatter: (value) => formatAsMoney(parseFloat(value)),
                titleFormatter: (value) => `Year ${value}`,
                yMin: 0,
                multiAxis: false,
              }}
            />
          </CardBody>
        </Card>
      </Form>
    );
  }
}

export default InterestComparisonTool;
