import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';

import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Header2,
  Link,
  Modal,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';

import NSLDSFile from './nslds-file';

const TextBlock = styled.div`
  text-align: left;
`;

const PaddedDiv = styled.div`
  margin-left: 1em;
`;

const VerticalCentered = styled.div`
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
  margin-bottom: auto;
`;

const SpacedDiv = styled.div`
  margin-top: 1em;
`;

class TabUpload extends React.Component {
  static propTypes = {
    files: t.object,
    request: t.object,
    errors: t.object,
    updateNsldsUploaded: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      errors: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  openCloseModal = (value) => {
    this.setState({ openModal: value });
  };

  getNSLDSFile = () => {
    const { files } = this.props;
    const matches = Object.keys(files).filter((k) => files[k].fileTypeCd === 'LNDT');
    return matches[0];
  };

  addError = (error) => {
    const { errors } = this.state;
    errors.push(error);
    this.setState({ ...this.state, errors: errors });
  };

  getErrorMessage = () => {
    const { errors } = this.state;
    let message;

    if (Object.keys(errors).length > 0) {
      message = errors[0].detail;
    } else {
      message = 'An error occurred during upload';
    }
    return message;
  };

  getModalText = () => {
    return (
      <Card brand={'success'}>
        <CardHeader>How to obtain your NSLDS:</CardHeader>
        <CardBody>
          <CardText>
            <TextBlock>
              <ol>
                <li>
                  {/* TODO: once UI is updated, convert this and other anchors to Links */}
                  To access, visit{' '}
                  <Link to="https://studentaid.gov/" external>
                    https://studentaid.gov/
                  </Link>
                </li>
                <li>
                  Click <strong>Log In</strong> if you have an FSA ID or{' '}
                  <strong>Create Account</strong> to create one
                </li>
                <li>
                  Click <strong>Accept</strong>
                </li>
                <li>
                  Select <strong>VIEW DETAILS</strong> in the My Aid section of your dashboard in
                  the middle of the page
                </li>
                <li>
                  On the Aid Summary page, click on <strong>Download My Aid Data</strong> on the
                  right side of the page
                </li>
                <li>
                  Click <strong>Continue</strong>
                </li>
                <li>Save file as "Text Document" to your computer</li>
                <li>Upload the saved file to your MyFiducius account</li>
              </ol>
              <Button brand="lowContrast" onClick={() => this.openCloseModal(false)}>
                Close
              </Button>
            </TextBlock>
          </CardText>
        </CardBody>
      </Card>
    );
  };

  render() {
    return (
      <>
        <Header2>Upload</Header2>

        <AsyncLoader request={this.props.request}>
          <div className="row">
            <div className="col-12">
              Please select the "Loan Detail (NSLDS / Statements)" box below to upload your NSLDS.
            </div>
          </div>
          <SpacedDiv className="row">
            <div className="col-6">
              <PaddedDiv className="row">
                {this.props.request.hasFinished && this.props.files && (
                  <NSLDSFile
                    key={this.getNSLDSFile()}
                    file={this.props.files[this.getNSLDSFile()]}
                    uploadSuccessful={() => {
                      this.props.updateNsldsUploaded(true);
                    }}
                    requiredUpload
                    addError={this.addError}
                  />
                )}
              </PaddedDiv>
              {Object.keys(this.state.errors).length > 0 && (
                <>
                  <CardHeader>Upload Error</CardHeader>
                  <CardText>{this.getErrorMessage()}</CardText>
                </>
              )}
            </div>
            <div className="col-6">
              <VerticalCentered className="row">
                <StyledButton brand="primary" onClick={() => this.openCloseModal(true)}>
                  How to obtain your NSLDS
                </StyledButton>
              </VerticalCentered>
            </div>
          </SpacedDiv>
        </AsyncLoader>

        <Modal isOpen={this.state.openModal} fullWidth={false}>
          {this.getModalText()}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.documents.cache,
    errors: state.documents.errors,
    request: mergeRequestStatuses([
      state.documents.requests.loadCollection,
      state.documents.requests.loadResource,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabUpload));
