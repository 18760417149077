import { FormValidator } from '../../../utils';

export class TuitionValidator extends FormValidator {
  // Account tab validators
  approverEmail(validator, data) {
    validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidEmail();
    validator.valueMatchesList(data.approverEmail, data.clientEmails);
  }
}
