import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { Alert, AsyncLoader, Button, Loader, Workflow } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { fulfillmentUpdateResource } from '../../../fulfillment';
import { debounce, mergeRequestStatuses, safeAccess } from '../../../utils';
import { authLoadPermissions } from '../../../auth';

import TabAgreement, { AGREEMENT_DOC_ID } from './tab-agreement';
import TabEmployment from './tab-employment';
import TabPlan from './tab-plan';
import {
  lwoAgreementCopyResourceToForm,
  lwoAgreementHandleFormChange,
  lwoAgreementLoadResource,
  lwoAgreementValidateAttributes,
  lwoAgreementClearForm,
  lwoAgreementUpdateResource,
} from '../redux/operations';
import { employmentHistoryLoadCollection } from '../../../employment-history';

import { paymentSetTypeLWO } from '../../../payment';

class AgreementWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    fulfillData: t.object,
    errors: t.object,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
    signContract: t.func.isRequired,
    signRequest: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
    employers: t.object,
    invalidEmployers: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      current: props.current || 0,
      redirect: false,
      redirectUrl: null,
    };
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  handleTabChange = (index) => {
    this.props.validateTab(Object.keys(tabs)[this.state.current]);
    this.setState({ current: index });
  };

  sectionValidationStatus(section) {
    const { errors, data } = this.props;

    let exists = false;
    for (let i = 0; i < tabs[section].length; i++) {
      let attribute = tabs[section][i];
      if (safeAccess(errors, `[${attribute}]`)) {
        return 'danger';
      } else if (
        (safeAccess(errors, `[${attribute}]`) === false) & (attribute !== 'employersCorrect') ||
        data.employersCorrect === 'true'
      ) {
        exists = true;
      }
    }
    return exists ? 'success' : null;
  }

  employmentIncorrect = () => {
    const { data, updateForm, invalidEmployers, id } = this.props;
    const newData = { ...data, employersCorrect: false };
    updateForm(newData, ['employersCorrect']);
    invalidEmployers(id).then(() => {
      this.setState({ redirectUrl: '/benefits/limited-waiver-opportunity/questionnaire' });
    });
  };

  getTabs = (data, errors, agreementStatus, employers) => {
    let tabs = [
      <TabPlan
        key="1"
        id="plan"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('plan')}
        name="Your Assure&trade;"
        handleChange={this.handleChange}
      />,
      <TabEmployment
        key="2"
        id="employment"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('employment')}
        name="Review Employment"
        handleChange={this.handleChange}
        employers={employers}
        employmentIncorrect={this.employmentIncorrect}
        showEmployerIncorrect={data.employersCorrect === 'false' ? true : false}
      />,
      <TabAgreement
        key="3"
        id="agreement"
        data={data}
        errors={errors}
        brand={agreementStatus}
        name="Sign Agreement"
      />,
    ];

    return tabs;
  };

  reviewValidationStatus = () => {
    const plan = this.sectionValidationStatus('plan');
    const employment = this.sectionValidationStatus('employment');

    if (plan === 'success' && employment === 'success') {
      return 'success';
    } else {
      return null;
    }
  };

  submit = async (e) => {
    e.preventDefault();
    if (!this.props.signRequest.isLoading) {
      await this.props.signContract();
    }
  };

  render() {
    const { data, errors, request, signRequest, employers } = this.props;

    if (this.state.redirectUrl !== null) {
      return <Redirect to={this.state.redirectUrl} />;
    }

    let agreementStatus = this.sectionValidationStatus('agreement');
    if (agreementStatus !== 'danger' && signRequest.hasFinished) {
      agreementStatus = signRequest.hasFailed ? 'danger' : 'success';
    }

    return (
      <>
        <AsyncLoader request={request}>
          <>
            {safeAccess(errors, 'generalErrors')
              ? errors.generalErrors.map((e, i) => (
                  <Alert key={i} brand="danger">
                    {e.detail}
                  </Alert>
                ))
              : null}
            {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
            {data && (
              <Workflow
                tabs={this.getTabs(data, errors, agreementStatus, employers)}
                finalButton={
                  <Button brand="primary" onClick={(e) => this.submit(e)}>
                    {signRequest.isLoading ? <Loader variant="push" size={1.5} /> : 'Sign contract'}
                  </Button>
                }
                handleTabChange={this.handleTabChange}
                next
                prev
                finalPageLocksFlow
                finalPageCheck={this.reviewValidationStatus}
              />
            )}
          </>
        </AsyncLoader>
      </>
    );
  }
}

const tabs = {
  plan: ['planType'],
  employment: ['employersCorrect'],
  agreement: [],
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.lwoAgreement;
  return {
    id: getUserId(state),
    data: form,
    fulfillData: state.fulfillment.cache[AGREEMENT_DOC_ID],
    errors: errors,
    request: mergeRequestStatuses([
      requests.loadResource,
      requests.copyResourceToForm,
      state.employmentHistory.requests.loadCollection,
    ]),
    employers: state.employmentHistory.cache,
    signRequest: state.fulfillment.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(lwoAgreementClearForm(id));
    await dispatch(lwoAgreementLoadResource(id));
    await dispatch(lwoAgreementCopyResourceToForm(id));
    await dispatch(employmentHistoryLoadCollection());
  },
  updateForm: (data, attributes) => {
    dispatch(lwoAgreementHandleFormChange(data, attributes));
  },
  signContract: async () => {
    await dispatch(fulfillmentUpdateResource(AGREEMENT_DOC_ID));
    dispatch(paymentSetTypeLWO());
    await dispatch(authLoadPermissions());
  },
  validateTab: async (tab) => {
    await dispatch(lwoAgreementValidateAttributes(tabs[tab]));
  },
  invalidEmployers: async (id) => {
    await dispatch(lwoAgreementUpdateResource(AGREEMENT_DOC_ID));
    await dispatch(lwoAgreementClearForm(id));
    await dispatch(authLoadPermissions());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementWorkflow);
