import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { getAffiliateLinkByName } from '../redux/operations';
import { getUserId } from '../../../auth';

const GetAffiliateLinkByName = async (affiliateName, affiliateByName) => {
  let data = await affiliateByName(affiliateName);
  return data;
};

const AffiliateLink = ({ affiliateName, affiliateByName, linkText, targetText, relText }) => {
  const [affiliateData, setAffiliateData] = useState('');

  useEffect(() => {
    let isSubscribed = true;
    GetAffiliateLinkByName(affiliateName, affiliateByName).then((data) => {
      if (isSubscribed) {
        if (!data && affiliateName === 'sofi') {
          data = 'https://www.sofi.com';
        }
        setAffiliateData(data);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <>
      <div>
        <a href={affiliateData} target={targetText} rel={relText}>
          {linkText}
        </a>
      </div>
    </>
  );
};

AffiliateLink.propTypes = {
  id: t.string.isRequired,
  affiliate: t.func.isRequired,
};

const mapStateToProps = (state) => ({
  id: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  affiliateByName: async (affiliateName) => await dispatch(getAffiliateLinkByName(affiliateName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateLink);
