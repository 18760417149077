import actions from './actions';
import { normalize, patchApi, postApi, resourceOperationsGenerator, safeAccess } from '../../utils';
import { getToken } from '../../auth';
import { TuitionInstitutionTermValidator } from './validators';

const tuitionInstitutionTermValidator = new TuitionInstitutionTermValidator();

const endpointHandler = {
  endpoint: '/tuition-reimbursement-term',
  customResponseHandler: null,
  resourceType: 'tuition-reimbursement-term',
};

const operations = resourceOperationsGenerator(
  'tuitionInstitutionTerm',
  actions,
  null,
  tuitionInstitutionTermValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  tuitionInstitutionTermClearForm,
  tuitionInstitutionTermCopyResourceToForm,
  tuitionInstitutionTermHandleFormChange,
  tuitionInstitutionTermLoadResource,
  tuitionInstitutionTermLoadCollection,
  tuitionInstitutionTermDeleteResource,
} = operations;

const termCreateResource = () => async (dispatch, getState) => {
  const state = getState();

  try {
    dispatch(actions.tuitionInstitutionTermCreateResourceRequest());
    if (state.tuitionInstitutionTerm.form.costPerCreditHour === '') {
      state.tuitionInstitutionTerm.form.costPerCreditHour = null;
    }
    const postData = normalize('tuition-reimbursement-term', state.tuitionInstitutionTerm.form);
    let data = await postApi(getToken(state), '/tuition-reimbursement-term/', postData);

    dispatch(actions.tuitionInstitutionTermCreateResourceResponse());
    dispatch(actions.tuitionInstitutionTermCreateResourceSuccess());

    const addedId = safeAccess(data[0], 'id') || -1;
    return addedId;
  } catch (error) {
    dispatch(operations.tuitionInstitutionTermHandleError(error));
    dispatch(actions.tuitionInstitutionTermCreateResourceFailure(error));
  }
};

const termUpdateResource = (id) => async (dispatch, getState) => {
  const state = getState();
  let retVal = false;
  try {
    dispatch(actions.tuitionInstitutionTermUpdateResourceRequest());
    if (state.tuitionInstitutionTerm.form.costPerCreditHour === '') {
      state.tuitionInstitutionTerm.form.costPerCreditHour = null;
    }
    const patchData = normalize('tuition-reimbursement-term', state.tuitionInstitutionTerm.form);
    await patchApi(getToken(state), `/tuition-reimbursement-term/${id}`, patchData);
    dispatch(actions.tuitionInstitutionTermUpdateResourceResponse());

    dispatch(actions.tuitionInstitutionTermUpdateResourceSuccess());
    const errors = safeAccess(state.tuitionInstitutionTerm, 'errors');
    if (!!errors || !!errors.totalCost) {
      retVal = true;
    }
  } catch (error) {
    dispatch(operations.tuitionInstitutionTermHandleError(error));
    dispatch(actions.tuitionInstitutionTermUpdateResourceFailure(error));
  }

  return retVal;
};

const termDeleteResource = (id) => async (dispatch, getState) => {
  await dispatch(tuitionInstitutionTermDeleteResource(id));
};

export {
  tuitionInstitutionTermClearForm,
  tuitionInstitutionTermCopyResourceToForm,
  tuitionInstitutionTermHandleFormChange,
  tuitionInstitutionTermLoadResource,
  tuitionInstitutionTermLoadCollection,
  termCreateResource,
  termDeleteResource,
  termUpdateResource,
};

export default operations;
