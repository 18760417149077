import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <strong>Step 1 – Login to your current loan servicer(s) account(s)</strong>
    <ul>
      <li>
        Request <strong>general</strong> forbearance on any loan(s) that have a payment due.{' '}
      </li>
      <li>
        <strong>Please note:</strong> You will want to remove any direct debit that is currently
        placed on these loans. If a payment is due within the next week, you may want to contact
        your bank to ensure the payment is not drawn from your account.
      </li>
    </ul>

    <strong>Step 2 – Confirm you have requested forbearance.</strong>
    <ul>
      <li>
        I confirm I have submitted a forbearance request at my current loan servicer(s) to place my
        current loan payment(s) on hold. We recommend you request general forbearance for
        approximately <strong>four</strong> months to allow enough time to complete the remaining
        steps.
      </li>
      <li>
        Forbearance requests typically take up to two weeks to process and any payment made while in
        forbearance may not qualify.
      </li>
      <li>
        <strong>Please note:</strong> If you have used more than 36 months of general forbearance,
        your request may be denied by the loan servicer.
      </li>
    </ul>
  </>
);

const ForbearancePhaseOneComplete = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/dashboard')}
    text={<TodoText />}
    title="Request General Forbearance - Phase 1"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForbearancePhaseOneComplete);
