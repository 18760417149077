import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { CardBody, CardHeader, CardText, Loader, Header1 } from 'fiducius-ui';

import { ShadowAddCard } from '../../routing';
import { safeAccess } from '../../utils';

import LoaderWrapper from '../styles/loader-wrapper';
import { authPasswordReset } from '../redux/operations';
import PasswordResetForm from '../components/password-reset-form';

const PasswordReset = ({ request, requestPasswordReset }) => {
  const [email, setEmail] = useState('');
  return (
    <>
      <Header1>Request Password Reset</Header1>
      <ShadowAddCard
        brand={(request.hasFailed && 'danger') || (request.hasFinished && 'success') || 'primary'}
      >
        {request.hasFailed && <CardHeader>Password Change Failed</CardHeader>}
        {!request.hasFailed && request.hasFinished && (
          <CardHeader>Password Request Sent</CardHeader>
        )}
        <CardBody>
          {request.notStarted ? (
            <PasswordResetForm
              email={email}
              onSubmit={(e) => {
                e.preventDefault();
                requestPasswordReset(email);
              }}
              handleChange={(e) => setEmail(e.target.value)}
            />
          ) : request.isLoading ? (
            <LoaderWrapper>
              <Loader variant="atom" size={3} />
            </LoaderWrapper>
          ) : (
            <CardText>
              <p>
                Check your email to finish changing your password. The email could take up to a few
                minutes to arrive and is active for 45 minutes. This e-mail could appear in your
                spam folder.
              </p>
            </CardText>
          )}
        </CardBody>
      </ShadowAddCard>
    </>
  );
};

PasswordReset.propTypes = {
  request: t.object,
  requestPasswordReset: t.func.isRequired,
};

const mapStateToProps = (state) => ({
  request: safeAccess(state, 'auth.requests.passwordReset', {}),
});

const mapDispatchToProps = (dispatch) => ({
  requestPasswordReset: (email) => dispatch(authPasswordReset(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
