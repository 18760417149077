export const defaultState = {
  General: {
    'Plan Type': {
      type: 'plantype',
      value: 'NFP',
      multiplier: 1.0,
      help: 'Select Plan Type.',
    },
    'Household Size': {
      type: 'number',
      value: 1,
      help:
        'How many total people are a part of your household? This includes adults and dependents.',
    },
    'Marital Status': {
      type: 'marital',
      value: 'Single',
      help: 'Are you married?',
    },
    'Client Gross Income': {
      type: 'number',
      value: 0,
      help:
        'This number is your total income per year, before taxes and any other pre-tax deductions are subtracted.',
    },
    'Spouse Gross Income': {
      type: 'number',
      value: 0,
      hide: (s) => s['General']['Marital Status'].value === 'Single',
      help:
        "This number is your spouse's total income per year, before taxes and any other pre-tax deductions are subtracted.",
    },
    'IDR Type': {
      type: 'idrtype',
      value: 'IBR',
      help: 'Select IDR Type.',
    },
    'Client Loan Balance': {
      type: 'number',
      value: 0,
      help: 'This number is your current total student loan balance.',
    },
    'Spouse Loan Balance': {
      type: 'number',
      value: 0,
      hide: (s) => s['General']['Marital Status'].value === 'Single',
      help: "This number is your spouse's current total student loan balance.",
    },
    'Current Loan Payment': {
      type: 'number',
      value: 0,
      help: 'This number is your monthly student loan payment.',
    },
    'Qualifying Payments': {
      type: 'number',
      value: 0,
      help:
        "This is how many monthly student loan payments you've made that count towards public student loan forgiveness. If you don't know whether you've made any payments that qualify, leave this as 0.",
    },
  },
  Taxes: {
    'Filing Status': {
      type: 'filing',
      value: 'Filing Separately',
      hide: (s) => s['General']['Marital Status'].value === 'Single',
      help: 'Will you be filing your taxes this year jointly with your spouse or separately?',
    },
    'Tax Cost': {
      type: 'number',
      value: 0,
      min: -99999,
      help:
        'Enter the amount of taxes you will owe this year. This is the total annual number that the government expects you to pay when you file your taxes.',
    },
  },
  'Monthly Retirement Contribution': {
    projectedRetirementDate: {
      type: 'date',
      value: '',
      label: 'Projected Retirement Date',
      width: 'full',
      help: 'Enter your projected retirement date.',
    },
    'Retirement Percentage': {
      type: 'number',
      value: 1,
      help:
        'Enter the montly percentage of your income before taxes and other deductions that you would like to save for retirement. For example, if you make $50,000 and you enter 1% here, that means you are saving $500 each month for retirement. Increasing this number will lower your taxable income, increase your monthly retirement savings, and possibly lower your monthly payment on a student loan income-driven payment plan.',
    },
    'Monthly Retirement Amount': {
      type: 'number',
      value: 0,
      help:
        'If you would prefer to enter an absolute dollar value instead of a percentage, enter it here. Any change to either of these fields will update the other. They are not additive and the value in this box is what will be used when calculating your taxable income.',
    },
    'Modified Retirement Percentage': {
      type: 'number',
      value: 0,
      help:
        'Enter the montly percentage of your income before taxes and other deductions that you would like to save for retirement. For example, if you make $50,000 and you enter 1% here, that means you are saving $500 each month for retirement. Increasing this number will lower your taxable income, increase your monthly retirement savings, and possibly lower your monthly payment on a student loan income-driven payment plan.',
    },
    'Modified Retirement Amount': {
      type: 'number',
      value: 0,
      help:
        'If you would prefer to enter an absolute dollar value instead of a percentage, enter it here. Any change to either of these fields will update the other. They are not additive and the value in this box is what will be used when calculating your taxable income.',
    },
  },
  'Health Insurance': {
    'Insurance Type': {
      type: 'insurance',
      value: 'PPO',
      help: 'Select the type of health insurance you have.',
    },
    'Insurance Coverage': {
      type: 'coverage',
      value: 'Employee Only',
      help: 'Select the level of coverage you have on your health insurance.',
    },
    'Pre-Tax Insurance Cost': {
      type: 'number',
      value: 0,
      help:
        'Enter any monthly pre-tax deductions made from your pay towards health insurance coverage. Pre-tax contributions like these reduce your taxable income and could possibly lower your monthly payment on a student loan income-driven payment plan. ',
    },
    'Annual HSA Contribution': {
      type: 'number',
      value: 0,
      hide: (s) => s['Health Insurance']['Insurance Type'].value !== 'HSA',
      help:
        'Enter the total annual dollar amount you will contribute to your HSA this year. Using an HSA plan and contributing pre-tax deductions from your pay can help lower your taxable income, save for medical expenses, and possibly lower your monthly payment on a student loan income-driven payment plan.',
    },
  },
  'Other Pre-Tax Costs / Contributions': {
    'Deduction 1 Value': {
      type: 'number',
      customInput: 'deduction',
      value: 0,
      help: 'Enter the monthly value of any other pre-tax deductions here.',
      showDeleteButton: false,
    },
  },
  'Modified Other Pre-Tax Costs / Contributions': {
    'Modified Deduction 1 Value': {
      type: 'number',
      customInput: 'deduction',
      value: 0,
      help: 'Enter the monthly value of any other pre-tax deductions here.',
      showDeleteButton: false,
    },
  },
  UIData: {
    ChartData: {
      value: '',
    },
    ComparisonChartData: {
      value: '',
    },
    GraphData: {
      value: '',
    },
  },
};

const getMonthlyPaymentByPaymentType = (state, exp, modified) => {
  let paymentAmount = 0;
  let paymentType = state['General']['IDR Type'].value;

  // multiplier is either a percentage, or could be a weighted amount for the SAVE plan type
  let multiplier = 1;

  switch (paymentType.toLowerCase()) {
    case 'repaye':
      multiplier = 10 / 100;
      paymentAmount = getMonthlyPayment(state, exp, 150, multiplier, modified);
      break;
    case 'paye':
      multiplier = 10 / 100;
      paymentAmount = getMonthlyPayment(state, exp, 150, multiplier, modified);
      break;
    case 'ibr':
      multiplier = 15 / 100;
      paymentAmount = getMonthlyPayment(state, exp, 150, multiplier, modified);
      break;
    case 'icr':
      multiplier = 20 / 100;
      paymentAmount = getMonthlyPayment(state, exp, 100, multiplier, modified);
      break;
    case '5%repaye':
      multiplier = 5 / 100;
      paymentAmount = getMonthlyPayment(state, exp, 225, multiplier, modified);
      break;
    case 'save':
      multiplier = state['General']['Plan Type'].multiplier;
      paymentAmount = getMonthlyPayment(state, exp, 225, multiplier, modified);
      break;
    default:
      break;
  }

  return paymentAmount;
};

const getNumberOfMonths = (state) => {
  let numOfMonths = 0;
  let planType = state['General']['Plan Type'].value;
  switch (planType) {
    case 'NFP':
      //120 months
      numOfMonths = 120;
      break;
    case 'FP20YR':
      //240 months - qualified payments
      numOfMonths = 240;
      break;
    case 'FP25YR':
      //300 months - qualified payments
      numOfMonths = 300;
      break;
    default:
      numOfMonths = 120;
      break;
  }

  return numOfMonths;
};

const getMonthlyPayment = (state, exp, povertyTablePercentage, multiplier, modified = false) => {
  let houseHoldSize = state['General']['Household Size'].value;
  const povertyGuidelinesDB = state['PovertyGuidelines'];
  let hhSizeFromDB = 1;

  if (houseHoldSize > 10) {
    hhSizeFromDB =
      (povertyGuidelinesDB[9].lower48Value - povertyGuidelinesDB[8].lower48Value) *
        (houseHoldSize - 10) +
      povertyGuidelinesDB[9].lower48Value;
  } else {
    hhSizeFromDB =
      povertyGuidelinesDB === undefined
        ? 1
        : houseHoldSize > 0 && Object.keys(povertyGuidelinesDB).length > 0
        ? povertyGuidelinesDB[houseHoldSize - 1].lower48Value
        : 1;
  }

  let retVal = 0;
  if (modified) {
    retVal =
      ((modifiedAgi(state, exp) - (povertyTablePercentage / 100) * hhSizeFromDB) * multiplier) / 12;
  } else {
    retVal = ((agi(state, exp) - (povertyTablePercentage / 100) * hhSizeFromDB) * multiplier) / 12;
  }

  return retVal;
};

const agi = (state, exp) => {
  const gi = +state['General']['Client Gross Income'].value * 1.02 ** exp;

  let otherDeductionsTotal = 0;
  Object.keys(state['Other Pre-Tax Costs / Contributions']).forEach((e) => {
    otherDeductionsTotal += state['Other Pre-Tax Costs / Contributions'][e].value * 12;
  });

  const deductions =
    +state['Monthly Retirement Contribution']['Monthly Retirement Amount'].value * 12 +
    +state['Health Insurance']['Pre-Tax Insurance Cost'].value * 12 +
    +state['Health Insurance']['Annual HSA Contribution'].value +
    otherDeductionsTotal;

  return gi - deductions;
};

const modifiedAgi = (state, exp) => {
  const gi = +state['General']['Client Gross Income'].value * 1.02 ** exp;

  let otherDeductionsTotal = 0;
  Object.keys(state['Modified Other Pre-Tax Costs / Contributions']).forEach((e) => {
    otherDeductionsTotal += state['Modified Other Pre-Tax Costs / Contributions'][e].value * 12;
  });

  const deductions =
    +state['Monthly Retirement Contribution']['Modified Retirement Amount'].value * 12 +
    +state['Health Insurance']['Pre-Tax Insurance Cost'].value * 12 +
    +state['Health Insurance']['Annual HSA Contribution'].value +
    otherDeductionsTotal;

  return gi - deductions;
};

export const usd = (n) => n.toLocaleString('en-US', { style: 'currency', currency: 'usd' });

export const forgiveness = (state, modified) => {
  let years = [];

  const pmts = state.General['Qualifying Payments'].value;
  const numMonths = getNumberOfMonths(state);
  const fullYears = numMonths / 12 - Math.floor(pmts / 12);
  const partialYear = (numMonths - pmts) % 12;
  const lastYearMonths = partialYear === 0 ? 12 : partialYear;

  for (let i = 0; i < fullYears; i++) {
    const ibp = getMonthlyPaymentByPaymentType(state, i, modified);
    const std = +state['General']['Current Loan Payment'].value;
    const payment = Math.round((ibp <= std ? ibp : std) * 100) / 100;
    const months = i + 1 === fullYears ? lastYearMonths : 12;
    const savings = Math.round((std - payment) * months);
    const accSavings = i - 1 >= 0 ? years[i - 1].accSavings + savings : savings;

    // Amortization of 8% on retirement account
    const ret = modified
      ? +state['Monthly Retirement Contribution']['Modified Retirement Amount'].value * 12
      : +state['Monthly Retirement Contribution']['Monthly Retirement Amount'].value * 12;
    const accRetirement = i - 1 >= 0 ? (years[i - 1].accRetirement + ret) * 1.08 : ret * 1.08;

    const hsa = +state['Health Insurance']['Annual HSA Contribution'].value;
    const accHsa = i - 1 >= 0 ? (years[i - 1].accHsa + hsa) * 1.06 : hsa * 1.06;

    years.push({
      id: i + 1,
      payment: payment < 0 ? 0 : payment, // payment can never be negative
      savings,
      accSavings,
      accRetirement,
      accHsa,
    });
  }
  return years;
};

const reOrderDeductionNames = (state) => {
  // Re-order the deduction names
  let i = 1;
  Object.keys(state['Other Pre-Tax Costs / Contributions']).forEach((e) => {
    let newObj = state['Other Pre-Tax Costs / Contributions'][e];
    delete state['Other Pre-Tax Costs / Contributions'][e];
    state['Other Pre-Tax Costs / Contributions']['Deduction ' + i + ' Value'] = newObj;
    i++;
  });

  i = 1;
  Object.keys(state['Modified Other Pre-Tax Costs / Contributions']).forEach((e) => {
    let newObj = state['Modified Other Pre-Tax Costs / Contributions'][e];
    delete state['Modified Other Pre-Tax Costs / Contributions'][e];
    state['Modified Other Pre-Tax Costs / Contributions'][
      'Modified Deduction ' + i + ' Value'
    ] = newObj;
    i++;
  });
};

export const getNewState = (state, page, name, value) => {
  let newState = { ...state };

  if (name === 'Add Deduction') {
    reOrderDeductionNames(state);

    let nextDeductionObject = Object.keys(state['Other Pre-Tax Costs / Contributions']).length + 1;
    let newDeductionObjectName = 'Deduction ' + nextDeductionObject + ' Value';

    let newDeductionObject = {
      type: 'number',
      customInput: 'deduction',
      value: 0,
      help: 'Enter the monthly value of any other pre-tax deductions here.',
      showDeleteButton: true,
    };

    newState[page][newDeductionObjectName] = newDeductionObject;
  } else if (name === 'Add Modified Deduction') {
    reOrderDeductionNames(state);

    let nextDeductionObject =
      Object.keys(state['Modified Other Pre-Tax Costs / Contributions']).length + 1;
    let newDeductionObjectName = 'Modified Deduction ' + nextDeductionObject + ' Value';

    let newDeductionObject = {
      type: 'number',
      customInput: 'deduction',
      value: 0,
      help: 'Enter the monthly value of any other pre-tax deductions here.',
      showDeleteButton: true,
    };

    newState['Modified Other Pre-Tax Costs / Contributions'][
      newDeductionObjectName
    ] = newDeductionObject;
  } else if (name === 'Delete Deduction') {
    // For Delete Deduction, the id of the button matches the name in the state
    delete newState[page][value];
    reOrderDeductionNames(state);
  } else if (name === 'Delete Modified Deduction') {
    // For Delete Deduction, the id of the button matches the name in the state
    delete newState['Modified Other Pre-Tax Costs / Contributions'][value];
    reOrderDeductionNames(state);
  } else if (defaultState[page][name].type === 'number') {
    newState[page][name].value = +value;
  } else {
    newState[page][name].value = value;
  }

  // reset spouse values if changing marital status back to single
  if (name === 'Marital Status' && value === 'Single') {
    newState['General']['Spouse Gross Income'].value = 0;
    newState['General']['Spouse Loan Balance'].value = 0;
    newState['Taxes']['Filing Status'].value = 'Filing Separate';
    if (state['Health Insurance']['Insurance Coverage'].value === 'Employee + Spouse') {
      newState['Health Insurance']['Insurance Coverage'].value = 'Employee Only';
    }
  }

  // reset hsa contribution if changing plan type
  if (name === 'Insurance Type' && value !== 'HSA') {
    newState['Health Insurance']['Annual HSA Contribution'].value = 0;
  }

  // retirement contributions should update each other
  if (name === 'Retirement Percentage') {
    newState['Monthly Retirement Contribution']['Monthly Retirement Amount'].value = Math.round(
      (newState['General']['Client Gross Income'].value * (value / 100)) / 12
    );
  } else if (name === 'Monthly Retirement Amount') {
    newState['Monthly Retirement Contribution']['Retirement Percentage'].value = Math.round(
      (value / (newState['General']['Client Gross Income'].value / 12)) * 100
    );
  }

  // retirement contributions should update each other
  if (name === 'Modified Retirement Percentage') {
    newState['Monthly Retirement Contribution']['Modified Retirement Amount'].value = Math.round(
      (newState['General']['Client Gross Income'].value * (value / 100)) / 12
    );
  } else if (name === 'Modified Retirement Amount') {
    newState['Monthly Retirement Contribution'][
      'Modified Retirement Percentage'
    ].value = Math.round((value / (newState['General']['Client Gross Income'].value / 12)) * 100);
  }

  // Update retirement if client gross income is changed
  if (name === 'Client Gross Income') {
    newState['Monthly Retirement Contribution']['Monthly Retirement Amount'].value = Math.round(
      (value * (newState['Monthly Retirement Contribution']['Retirement Percentage'].value / 100)) /
        12
    );
  }

  //console.log(state, newState);
  return newState;
};
