import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Card, CardBody, Loader, Modal } from 'fiducius-ui';

import { safeAccess, debounce, mergeRequestStatuses } from '../../utils';

import LoaderWrapper from '../../auth/styles/loader-wrapper';
import EmpcertApprovalForm from '../components/empcert-approval-form';
import EmpcertApprovalSuccess from '../components/empcert-approval-success';
import EmpcertApprovalInvalid from '../components/empcert-approval-invalid';

import {
  empcertApprovalLoadResource,
  empcertApprovalCopyResourceToForm,
  empcertApprovalHandleFormChange,
  empcertApprovalSaveApprovalInfo,
} from '../redux/operations';

import { ContrastDisplay, FullscreenContainer } from '../../routing';
import EmpcertSectionSix from '../components/empcert-section-six';

const EmpcertApproval = ({
  id,
  load,
  update,
  updateForm,
  updateRequest,
  location,
  data = {},
  errors = {},
  request = {},
  form = {},
  handleForm,
}) => {
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [openSectionSixModal, setOpenSectionSixModal] = useState(false);

  useEffect(() => {
    const params = queryString.parse(location.search);
    load(params.token);
  }, []);

  useEffect(() => {
    if (updateRequest.hasFinished) {
      if (!submitSuccessful && hasSubmitted) {
        if (!updateRequest.hasFailed && !hasServerErrors(errors)) {
          setSubmitSuccessful(true);
        } else {
          setHasSubmitted(false);
        }
      }
    }
  });

  const hasServerErrors = (errors) => {
    let retVal = false;

    if (safeAccess(errors, 'na')) {
      retVal = true;
    }

    return retVal;
  };

  const handleChange = debounce((formState, id, attribute, props) => {
    const newData = { ...form, ...formState, signaturePadApprover: form.signaturePadApprover };
    handleForm(newData, Object.keys(newData));
  }, 250);

  const handleApproverSignature = (data) => {
    updateForm({ ...form, signaturePadApprover: data }, ['signaturePadApprover']);
  };

  const handleSectionSixModal = () => {
    !openSectionSixModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');

    document.getElementsByClassName('modal-wrapper')[0].style['position'] = 'fixed';

    setOpenSectionSixModal(!openSectionSixModal);
  };

  const onSubmit = () => {
    delete errors.na;
    update(form.id);
    delete form.signaturePadApprover;
    setHasSubmitted(true);
  };

  return (
    <>
      <FullscreenContainer className="container">
        <div className="row">
          <div className="col-12">
            <ContrastDisplay>Employer Information</ContrastDisplay>
            <Card brand={(request.hasFailed && 'danger') || 'primary'}>
              <CardBody>
                {request.isLoading ? (
                  <LoaderWrapper>
                    <Loader variant="atom" size={3} />
                  </LoaderWrapper>
                ) : !submitSuccessful ? (
                  form !== undefined && Object.keys(form).length > 0 ? (
                    <EmpcertApprovalForm
                      data={form}
                      cache={data}
                      errors={errors}
                      onSubmit={onSubmit}
                      onChange={handleChange}
                      handleApproverSignature={handleApproverSignature}
                      handleSectionSixModal={handleSectionSixModal}
                    />
                  ) : (
                    <EmpcertApprovalInvalid />
                  )
                ) : (
                  <EmpcertApprovalSuccess />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </FullscreenContainer>
      <Modal isOpen={openSectionSixModal} fullWidth theme={'position:fixed;'}>
        <EmpcertSectionSix closeModal={handleSectionSixModal} />
      </Modal>
    </>
  );
};

EmpcertApproval.propTypes = {
  errors: t.object,
  request: t.object,
  updateRequest: t.object,
  form: t.object,
  data: t.object,
  location: t.shape({
    search: t.string,
  }),
  load: t.func.isRequired,
  handleForm: t.func.isRequired,
  update: t.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: safeAccess(state, 'empcertApproval.errors') || {},
  request: mergeRequestStatuses([
    safeAccess(state, 'empcertApproval.requests.loadResource'),
    safeAccess(state, 'empcertApproval.requests.updateResource'),
  ]),
  updateRequest: safeAccess(state, 'empcertApproval.requests.updateResource'),
  data: state.empcertApproval.cache,
  form: safeAccess(state, 'empcertApproval.form'),
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(empcertApprovalLoadResource(id));
    dispatch(empcertApprovalCopyResourceToForm(id));
  },
  handleForm: (empcertApproval, attributes) =>
    dispatch(empcertApprovalHandleFormChange(empcertApproval, attributes)),
  update: async (id) => {
    await dispatch(empcertApprovalSaveApprovalInfo(id));
  },
  updateForm: (data, attributes) => dispatch(empcertApprovalHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmpcertApproval);
