import actions from './actions';
import { ReferenceValidator } from './validators';
import { resourceOperationsGenerator } from '../../utils';

const referenceValidator = new ReferenceValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-reference',
  resourceType: 'customer-reference',
};

const operations = resourceOperationsGenerator(
  'references',
  actions,
  null,
  referenceValidator,
  endpointHandler,
  null,
  endpointHandler,
  endpointHandler,
  null
);

const {
  referencesClearForm,
  referencesCopyResourceToForm,
  referencesCreateResource,
  referencesHandleFormChange,
  referencesLoadCollection,
  referencesUpdateResource,
} = operations;

export {
  referencesClearForm,
  referencesCopyResourceToForm,
  referencesCreateResource,
  referencesHandleFormChange,
  referencesLoadCollection,
  referencesUpdateResource,
};

export default operations;
