import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { InputHelp, InputWrapper, Table } from 'fiducius-ui';

import { formatAsMoney } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

class LoanDataTableTwo extends React.Component {
  static propTypes = {
    id: t.string,
    isLoading: t.bool.isRequired,
    data: t.object,
    clearData: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { arraySize: null };
  }

  async componentDidMount() {}

  componentDidUpdate() {}

  calculateTableValues = (data) => {
    let retArray = [];
    let numRows = data.newLoanAmtBorrowed > 0 ? 2 : 1;

    let tuitionPercent =
      data.recommendedBorrowingTuition /
      (data.recommendedBorrowingTuition + data.recommendedBorrowingLivingExp);
    let livingExpPercent =
      data.recommendedBorrowingLivingExp /
      (data.recommendedBorrowingTuition + data.recommendedBorrowingLivingExp);

    for (let i = 0; i < numRows; i++) {
      let tmpArray = [];
      if (i === 0) {
        tmpArray.push(
          'Presented Plan',
          formatAsMoney(data.initialLoanBalance),
          formatAsMoney(data.recommendedBorrowingTuition) + '*',
          formatAsMoney(data.recommendedBorrowingLivingExp) + '*'
        );
      } else {
        tmpArray.push(
          'Previous Snapshot Totals',
          formatAsMoney(data.newLoanAmtBorrowed + data.initialLoanBalance),
          formatAsMoney(data.newLoanAmtBorrowed * tuitionPercent),
          formatAsMoney(data.newLoanAmtBorrowed * livingExpPercent)
        );
      }
      retArray.push(tmpArray);
    }
    return retArray;
  };

  render() {
    const { data } = this.props;

    let body = [];

    if (data.initialLoanBalance !== undefined) {
      body = this.calculateTableValues(data);
    } else {
      body = [];
    }
    let tableHeaders = [
      '',
      'Loan Balance',
      'Borrowing for Tuition',
      'Borrowing for Living Expenses',
    ];

    return (
      <div>
        {body !== undefined && body.length > 0 && (
          <InputWrapper>
            <Table header={tableHeaders} body={body} />
            <InputHelp>* - Recommended Amounts</InputHelp>
          </InputWrapper>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { questionnaireBts } = state;
  const { requests } = questionnaireBts;
  const id = getUserId(state);

  return {
    id: id,
    isLoading: requests.loadResource,
    parentLoaded: requests.loadResource,
    saveRequest: questionnaireBts.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LoanDataTableTwo));
