import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';

import { Redirect } from 'react-router-dom';

import { AsyncLoader, Button, Card, CardBody, DashboardSection, Lede } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import { getTuitionInstitutionData } from '../redux/selectors';

import {
  tuitionInstitutionLoadCollection,
  TuititionInstitutionRow,
} from '../../../tuition-institution';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

class MyInstitutions extends React.Component {
  static propTypes = {
    data: t.object,
    loadInstitution: t.func.isRequired,
    permissions: t.object,
    request: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '' };
  }

  componentDidMount() {
    this.props.loadInstitution();
  }

  addClass() {
    this.setState({ ...this.state, redirectURI: '/tuition/new-class' });
  }

  render() {
    const { data = {}, request } = this.props;
    const institutions = [];
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <DashboardSection
          title={
            <div className="row">
              <div className="col-12">My Institutions</div>
              <div className="col-12">
                <Button
                  brand="primary"
                  onClick={() => {
                    this.addClass();
                  }}
                >
                  <PaddedIcon icon={faPlusCircle} fixedWidth />
                  Add New Institution
                </Button>
              </div>
            </div>
          }
        >
          <AsyncLoader
            request={request}
            empty={
              <Card>
                <CardBody>
                  <FlexRow>
                    <Lede>An institution has not been added.</Lede>
                  </FlexRow>
                </CardBody>
              </Card>
            }
          >
            {Object.keys(data).length > 0 &&
              Object.keys(data).forEach((institution) => {
                institutions.push(
                  <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <TuititionInstitutionRow institutionId={institution} />
                  </div>
                );
              })}
            <div className="row">{institutions}</div>
          </AsyncLoader>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyInstitutions));
