import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Alert,
  Animation,
  Button,
  Card,
  CardTitle,
  fade,
  Form,
  InputCheckbox,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputRadio,
  InputText,
  InputWrapper,
  Header4,
} from 'fiducius-ui';

import { safeAccess, convertIsoToSlash } from '../../utils';

import { Signature } from '../../fulfillment';

const StyledInputError = styled(InputError)`
  display: flex;
  flex-direction: column;
`;

const RowFade = styled.div`
  ${fade};
`;

const EmpcertApprovalForm = ({
  data,
  cache,
  errors = {},
  onSubmit,
  onChange,
  handleApproverSignature,
  handleSectionSixModal,
}) => {
  const allowedToSubmit = (data, errors) => {
    let retVal = true;
    if (
      !data.email ||
      !data.employerName ||
      !data.ein ||
      !data.address1 ||
      !data.city ||
      !data.state ||
      !data.zipCode ||
      !data.employmentBeginDate ||
      !data.employmentHoursPerWeek ||
      !data.approverName ||
      !data.approverPhone ||
      !data.approverTitle ||
      !data.employmentStatus ||
      !data.signaturePadApprover
    ) {
      retVal = false;
    }

    if (
      safeAccess(errors, 'email') ||
      safeAccess(errors, 'employerName') ||
      safeAccess(errors, 'ein') ||
      safeAccess(errors, 'address1') ||
      safeAccess(errors, 'city') ||
      safeAccess(errors, 'state') ||
      safeAccess(errors, 'employmentBeginDate') ||
      safeAccess(errors, 'employmentHoursPerWeek') ||
      safeAccess(errors, 'approverName') ||
      safeAccess(errors, 'approverPhone') ||
      safeAccess(errors, 'approverTitle') ||
      safeAccess(errors, 'employmentStatus') ||
      safeAccess(errors, 'signaturePadApprover')
    ) {
      retVal = false;
    }

    return retVal;
  };

  if (data && data.message && data.message.length > 0) {
    return <Card>{data.message}</Card>;
  } else {
    return (
      <>
        {safeAccess(errors, 'na') && (
          <Alert key={'invalid-info'} brand="danger">
            {errors.na.detail}
          </Alert>
        )}
        <CardTitle>{data.customerName}'s Employment Information</CardTitle>
        <Form defaults={data} handleChange={onChange} id={'empcertApproval'} onSubmit={onSubmit}>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.employerName}>
                <InputLabel required>Employer Name</InputLabel>
                <InputError>{safeAccess(errors, 'employerName.detail')}</InputError>
                <InputGroup>
                  <InputText name="employerName" defaultValue={data.employerName} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.ein}>
                <InputLabel required>Federal Employer Identification Number (FEIN)</InputLabel>
                <InputError>{safeAccess(errors, 'ein.detail')}</InputError>
                <InputGroup>
                  <InputText name="ein" defaultValue={data.ein} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.address1}>
                <InputLabel required>Address Line 1</InputLabel>
                <InputError>{safeAccess(errors, 'address1.detail')}</InputError>
                <InputGroup>
                  <InputText name="address1" defaultValue={data.address1} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.employerWebsite}>
                <InputLabel>Employer Website</InputLabel>
                <InputError>{safeAccess(errors, 'employerWebsite.detail')}</InputError>
                <InputGroup>
                  <InputText name="employerWebsite" defaultValue={data.employerWebsite} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.address2}>
                <InputLabel>Address Line 2</InputLabel>
                <InputError>{safeAccess(errors, 'address2.detail')}</InputError>
                <InputGroup>
                  <InputText name="address2" defaultValue={data.address2} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.employmentBeginDate}>
                <InputLabel required>Employment Begin Date</InputLabel>
                <InputError>{safeAccess(errors, 'employmentBeginDate.detail')}</InputError>
                <InputGroup>
                  <InputMask
                    defaultValue={convertIsoToSlash(data.employmentBeginDate)}
                    mask="##/##/####"
                    name="employmentBeginDate"
                    placeholder="MM/DD/YYYY"
                  />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.city}>
                <InputLabel required>City</InputLabel>
                <InputError>{safeAccess(errors, 'city.detail')}</InputError>
                <InputGroup>
                  <InputText name="city" defaultValue={data.city} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.employmentEndDate}>
                <InputLabel>Employment End Date</InputLabel>
                <InputError>{safeAccess(errors, 'employmentEndDate.detail')}</InputError>
                <InputGroup>
                  <InputMask
                    defaultValue={convertIsoToSlash(data.employmentEndDate)}
                    mask="##/##/####"
                    name="employmentEndDate"
                    placeholder="MM/DD/YYYY"
                  />
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
          <div className="row">
            <div className="col-3 col-md-3">
              <InputWrapper error={!!errors.state}>
                <InputLabel required>State</InputLabel>
                <InputError>{safeAccess(errors, 'state.detail')}</InputError>
                <InputGroup>
                  <InputText name="state" defaultValue={data.state} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-3 col-md-3">
              <InputWrapper error={!!errors.zipCode}>
                <InputLabel required>Zip Code</InputLabel>
                <InputError>{safeAccess(errors, 'zipCode.detail')}</InputError>
                <InputGroup>
                  <InputText name="zipCode" defaultValue={data.zipCode} />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-6 col-md-6">
              <InputWrapper error={!!errors.employmentHoursPerWeek}>
                <InputLabel required>Hours Per Week (Average)</InputLabel>
                <InputError>{safeAccess(errors, 'employmentHoursPerWeek.detail')}</InputError>
                <InputGroup>
                  <InputText
                    name="employmentHoursPerWeek"
                    defaultValue={data.employmentHoursPerWeek}
                  />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-6 col-md-6">
              <InputWrapper error={!!errors.employmentStatus}>
                <InputLabel htmlfor="employmentStatus" required>
                  Employment Status
                </InputLabel>
                <StyledInputError
                  dangerouslySetInnerHTML={{
                    __html: safeAccess(errors, 'employmentStatus.detail'),
                  }}
                />
                <InputRadio
                  name="employmentStatus"
                  value="FULL"
                  checked={data.employmentStatus === 'FULL'}
                  onChange={() => {}}
                  label="Full-Time"
                />
                <InputRadio
                  name="employmentStatus"
                  value="PART"
                  checked={data.employmentStatus === 'PART'}
                  onChange={() => {}}
                  label="Part-Time"
                />
              </InputWrapper>
            </div>
          </div>
          <Animation key="showFinalSection" animation="fade" appear={true} in={true} unmountOnExit>
            <RowFade>
              <Header4>Employer Certification</Header4>
              <div className="col-12 col-md-12" id="signingCertify">
                <p>
                  By providing an acceptable signature below, I certify (1) that the information in
                  Section 4 is true, complete, and correct to the best of my knowledge and belief,
                  (2) that I am an authorized official of the organization named in Section 4, and
                  (3) that the borrower named is or was a direct employee of the organization named;
                  or is or was employed under a contract or by a contracted organization in a
                  position or providing services that, under applicable state law, cannot be filled
                  or provided by a direct employee of the organization named.
                </p>
              </div>
              <Signature
                handleChange={handleApproverSignature}
                label="Authorized Official's Signature:"
                name="approverSignature"
              />
              <div className="row">
                <div className="col-6 col-md-6">
                  <InputWrapper error={!!errors.approverName}>
                    <InputLabel required>Official's Name</InputLabel>
                    <InputError>{safeAccess(errors, 'approverName.detail')}</InputError>
                    <InputGroup>
                      <InputText name="approverName" defaultValue={data.approverName} />
                    </InputGroup>
                  </InputWrapper>
                </div>
                <div className="col-3 col-md-3">
                  <InputWrapper error={!!errors.approverPhone}>
                    <InputLabel required>Official's Phone</InputLabel>
                    <InputError>{safeAccess(errors, 'approverPhone.detail')}</InputError>
                    <InputGroup>
                      <InputMask
                        defaultValue={data.approverPhone}
                        mask="(###) ###-####"
                        name="approverPhone"
                        placeholder="(___) ___-____"
                        type="tel"
                      />
                    </InputGroup>
                  </InputWrapper>
                </div>
                <div className="col-3 col-md-3">
                  <InputWrapper error={!!errors.phoneExtension}>
                    <InputLabel htmlFor="phoneExtension">Ext.</InputLabel>
                    <InputError>{safeAccess(errors, 'phoneExtension.detail')}</InputError>
                    <InputGroup>
                      <InputText name="phoneExtension" defaultValue={data.phoneExtension} />
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputWrapper error={!!errors.approverTitle}>
                    <InputLabel required>Official's Title</InputLabel>
                    <InputError>{safeAccess(errors, 'approverTitle.detail')}</InputError>
                    <InputGroup>
                      <InputText name="approverTitle" defaultValue={data.approverTitle} />
                    </InputGroup>
                  </InputWrapper>
                </div>
                <div className="col-12 col-md-6">
                  <InputWrapper error={!!errors.email}>
                    <InputLabel required>Official's Email</InputLabel>
                    <StyledInputError
                      dangerouslySetInnerHTML={{ __html: safeAccess(errors, 'email.detail') }}
                    />
                    <InputGroup>
                      <InputText
                        name="email"
                        autoComplete="email"
                        placeholder="Email"
                        defaultValue={data.email}
                      />
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
              {allowedToSubmit(data, errors) ? (
                <Button className="col-4" brand="primary" onClick={onSubmit}>
                  Submit Response(s)
                </Button>
              ) : (
                <>To submit, please finish filling out the above information correctly.</>
              )}
            </RowFade>
          </Animation>
        </Form>
      </>
    );
  }
};

export default EmpcertApprovalForm;
