import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Workflow } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { debounce, safeAccess } from '../../../utils';

import TabEducation from './tab-education';
import TabResults from './tab-results';

import {
  questionnaireBtsClearForm,
  questionnaireBtsHandleFormChange,
  questionnaireBtsValidateAttributes,
} from '../redux/operations';

class QuestionnaireBTSWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    errors: t.object,
    hasAdvisorResults: t.bool.isRequired,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
    clearForm: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { current: props.current || 0, farthest: props.current || 0, redirectURI: '' };
  }

  componentDidMount() {}

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  handleTabChange = (index) => {
    this.props.validateTab(Object.keys(tabs)[this.state.current]);
    this.setState({
      current: index,
      farthest: index > this.state.farthest ? index : this.state.farthest,
    });
  };

  sectionValidationStatus(section, index) {
    const { data = {}, errors } = this.props;
    let exists = true;

    if (index <= this.state.farthest) {
      for (let i = 0; i < tabs[section].length; i++) {
        let attribute = tabs[section][i];
        if (safeAccess(errors, `[${attribute}]`)) {
          return 'danger';
        } else if (
          attribute === 'loanAmtBorrow' &&
          data.borrowedAddFedLoans === 'true' &&
          !!safeAccess(data, `[${attribute}]`) === false
        ) {
          exists = false;
        } else if (
          attribute !== 'loanAmtBorrow' &&
          !!safeAccess(data, `[${attribute}]`) === false
        ) {
          exists = false;
        }
      }
    }
    return exists ? 'success' : null;
  }

  reviewValidationStatus = () => {
    const education = this.sectionValidationStatus('education', 0);
    if (education === 'success' && (this.state.current === 0 || this.state.current === 1)) {
      return 'success';
    } else {
      return null;
    }
  };

  resultValidationStatus = () => {
    let reviewStatus = this.sectionValidationStatus('education', 0);

    if (reviewStatus === 'success' && this.state.current === 1) {
      return 'success';
    } else {
      return null;
    }
  };

  render() {
    const { data = {}, errors } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <>
          <Workflow
            tabs={[
              <TabEducation
                key="1"
                id="education"
                form={data}
                errors={errors}
                brand={this.sectionValidationStatus('education', 0)}
                name="Education"
                handleChange={this.handleChange}
              />,
              <TabResults key="2" name="Results" brand={this.resultValidationStatus()} />,
            ]}
            handleTabChange={this.handleTabChange}
            next
            prev
            finalPageLocksFlow
            finalPageCheck={this.reviewValidationStatus}
            penUltimateButton={'Next'}
          />
        </>
      </>
    );
  }
}

const tabs = {
  education: [
    'continuedEducation',
    'borrowedAddFedLoans',
    'graduateDate',
    'graduationIncome',
    'loanAmtBorrow',
  ],
  results: [],
};

const mapStateToProps = (state) => {
  const { errors, form } = state.questionnaireBts;
  return {
    id: getUserId(state),
    data: form,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateForm: (data, attributes) => {
    dispatch(questionnaireBtsHandleFormChange(data, attributes));
  },
  clearForm: () => {
    dispatch(questionnaireBtsClearForm(tabs));
  },
  validateTab: async (tab) => {
    await dispatch(questionnaireBtsValidateAttributes(tabs[tab]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireBTSWorkflow);
