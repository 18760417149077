import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';
import { EducationalReengagementValidator } from './validators';

const educationalReengagementValidator = new EducationalReengagementValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/educational-reengagement',
  resourceType: 'educational-reengagement',
};

const operations = resourceOperationsGenerator(
  'educationalReengagement',
  actions,
  null,
  educationalReengagementValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  educationalReengagementLoadResource,
  educationalReengagementUpdateResource,
  educationalReengagementHandleFormChange,
} = operations;

export {
  educationalReengagementLoadResource,
  educationalReengagementUpdateResource,
  educationalReengagementHandleFormChange,
};

export default operations;
