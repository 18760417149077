import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  endpoint: '/maintenance',
  customResponseHandler: null,
  resourceType: 'maintenance',
};

const operations = resourceOperationsGenerator(
  'maintenance',
  actions,
  null,
  null,
  endpointHandler,
  null,
  null,
  null,
  null
);

const { maintenanceLoadResource } = operations;

export { maintenanceLoadResource };
