import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  AsyncLoader,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { convertIsoToSlash, safeAccess, stringIsNullOrEmpty } from '../../utils';
import { Fade } from '../../routing';

const TextLeft = styled.div`
  text-align: left;
`;

class TuitionInstitutionTermForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    getResource: t.object,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    institutionList: t.object.isRequired,
    defaultInstitutionId: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }
    /*if (!prevProps.errors.generalErrors && this.props.errors.generalErrors) {
      this.modalRef.current.scrollTo(0, 0);
    }*/
  }

  getForm() {
    const {
      action,
      data,
      errors,
      onChange,
      onSubmit,
      institutionList,
      defaultInstitutionId,
    } = this.props;

    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <InputWrapper error={!!errors.institutionId}>
          <InputLabel htmlFor="institutionId" required>
            Institution
          </InputLabel>
          <InputError>{safeAccess(errors, 'institutionId.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="institutionId"
              defaultValue={defaultInstitutionId || data.institutionId || ''}
              options={institutionList}
              disabled={action === 'Delete' || action === 'Add'}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.termName}>
          <InputLabel htmlFor="termName" required>
            Term / Semester Name
          </InputLabel>
          <InputError>{safeAccess(errors, 'termName.detail')}</InputError>
          <InputGroup>
            <InputText
              name="termName"
              defaultValue={data.termName}
              disabled={action === 'Delete'}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.startDate}>
          <InputLabel htmlFor="startDate" required>
            Term / Semester Start Date
          </InputLabel>
          <InputError>{safeAccess(errors, 'startDate.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={convertIsoToSlash(data.startDate)}
              mask="##/##/####"
              name={'startDate'}
              placeholder="MM/DD/YYYY"
              disabled={action === 'Delete'}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.endDate}>
          <InputLabel htmlFor="endDate" required>
            Term / Semester End Date
          </InputLabel>
          <InputError>{safeAccess(errors, 'endDate.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={convertIsoToSlash(data.endDate)}
              mask="##/##/####"
              name={'endDate'}
              placeholder="MM/DD/YYYY"
              disabled={action === 'Delete'}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.totalCost}>
          <InputLabel htmlFor="totalCost" required>
            Term / Semester Total Cost
          </InputLabel>
          <InputError>{safeAccess(errors, 'totalCost.detail')}</InputError>
          <InputGroup>
            <InputAddon>$</InputAddon>
            <InputText
              name="totalCost"
              defaultValue={data.totalCost}
              disabled={action === 'Delete'}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.costPerCreditHour}>
          <InputLabel htmlFor="costPerCreditHour">Term / Semester Cost Per Credit Hour</InputLabel>
          <InputError>{safeAccess(errors, 'costPerCreditHour.detail')}</InputError>
          <InputGroup>
            <InputAddon>$</InputAddon>
            <InputText
              name="costPerCreditHour"
              defaultValue={data.costPerCreditHour}
              disabled={action === 'Delete'}
            />
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  }

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {}, defaultInstitutionId } = this.props;
    return (
      (data.institutionId !== undefined || defaultInstitutionId !== undefined) &&
      !stringIsNullOrEmpty(data.termName) &&
      !stringIsNullOrEmpty(data.startDate) &&
      !stringIsNullOrEmpty(data.endDate) &&
      !stringIsNullOrEmpty(data.totalCost)
    );
  };

  checkNoErrors = () => {
    const { data = {}, errors = {} } = this.props;
    return (
      this.validDate(data.startDate) &&
      this.validDate(data.endDate) &&
      !isNaN(data.totalCost) &&
      !errors.institutionId &&
      !errors.termName &&
      !errors.startDate &&
      !errors.endDate &&
      !errors.totalCost &&
      !errors.costPerCreditHour
    );
  };

  validDate = (theDate) => {
    let retVal = true;
    if (
      theDate === undefined ||
      theDate === '' ||
      new Date(theDate) === 'Invalid date' ||
      !(new Date(theDate) > new Date('01/01/1900'))
    ) {
      retVal = false;
    }
    return retVal;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { getResource, action, closeForm, errors = {}, isLoading, isOpen } = this.props;

    return (
      <Modal ref={this.modalRef} isOpen={isOpen}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Term</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            {action === 'Add' && <TextLeft>{this.getForm()}</TextLeft>}
            {(action === 'Update' || action === 'Delete') && (
              <AsyncLoader request={getResource}>
                <TextLeft>{this.getForm()}</TextLeft>
              </AsyncLoader>
            )}
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" type="submit" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' term'}
                </Button>
              </Fade>
              <Fade show={!getResource.isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade
                show={!getResource.isLoading && this.checkNoMissingData() && !this.checkNoErrors()}
              >
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default TuitionInstitutionTermForm;
