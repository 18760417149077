import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';

import FreshStartProgress from '../components/fresh-start-progress';
import { mergeRequestStatuses } from '../../../utils';
import { authLoadPermissions } from '../../../auth';
import { withPermissions } from '../../../routing';
import { todosLoadCollection, todosSendUpdate } from '../../../todos/redux/operations';

const FreshStartComplete = ({ request, permissions }) => {
  const [redirectUri, setRedirectUri] = useState('');

  const hasCompletedStep = () => {
    const completedContact = permissions.freshStartSteps.filter(
      (a) => a.id === 'CompleteFreshStart' && a.stepComplete
    );

    if (completedContact.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <div className="row">
        <div className="col-12 col-lg-12">
          <AsyncLoader request={request}>
            <FreshStartProgress />
            <DashboardSection title="Complete">
              <Card>
                <CardBody>
                  <AsyncLoader request={request} loading={<></>}>
                    {!hasCompletedStep() && <p>You haven't reached this step yet.</p>}
                    {hasCompletedStep() && (
                      <p>
                        Congratulations, you have completed all of the steps for Fresh Start! If you
                        qualified for additional benefits, those are now available to you. Please
                        review your To-do list to determine if you have new steps to complete.
                      </p>
                    )}
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </AsyncLoader>
        </div>
      </div>
    );
  }
};

FreshStartComplete.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    dispatch(todosSendUpdate(920)).then(() => {
      dispatch(authLoadPermissions());
      dispatch(todosLoadCollection());
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FreshStartComplete));
