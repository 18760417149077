import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection, Header5 } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';
import DocsNeededForRSUpload from '../components/docs-upload-needed';

class AccountReviewRS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <ForgivenessProgress />
        <DashboardSection title="Account Review">
          <Card>
            <Card>
              <CardBody>
                <CardTitle>Account Review</CardTitle>
                <CardText>
                  <Header5>
                    <p>
                      We are reviewing your documentation to determine the optimal approach to
                      submitting your recertification request.
                    </p>
                    <p>
                      <DocsNeededForRSUpload documentButton={true} />
                    </p>
                  </Header5>
                </CardText>
              </CardBody>
            </Card>
          </Card>
        </DashboardSection>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(AccountReviewRS));
