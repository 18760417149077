import React from 'react';
import { Card, CardBody, CardText, Header1 } from 'fiducius-ui';

import { FullscreenContainer } from '../../../routing';

import ScreeningWorkflow from '../components/screening-workflow';

const Screening = () => (
  <FullscreenContainer className="container">
    <div className="row">
      <div className="col-12">
        <Card>
          <CardBody>
            <Header1>Screening Questionnaire</Header1>
            <CardText>
              <ScreeningWorkflow />
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  </FullscreenContainer>
);

export default Screening;
