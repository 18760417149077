import React from 'react';
import t from 'prop-types';
import {
  Button,
  Form,
  InputHelp,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputAddon,
  InputText,
  Lede,
} from 'fiducius-ui';

class PretaxItems extends React.Component {
  static propTypes = {
    data: t.object,
    onChange: t.func.isRequired,
    handleChange: t.func,
  };

  constructor(props) {
    super(props);
    this.retirementVal = React.createRef();

    this.state = {
      deductions: [],
      deductionAmount: 0,
      deductionName: '',
    };

    this.handleAddDeduction = this.handleAddDeduction.bind(this);
    this.handleDeductionAmount = this.handleDeductionAmount.bind(this);
    this.handleDeductionName = this.handleDeductionName.bind(this);
  }

  handleAddDeduction() {
    this.setState({ deductions: [...this.state.deductions, ''] });
    this.setState({ deductionName: '' });
  }

  handleDeductionName(event) {
    this.setState({ deductionName: event.target.value });
  }

  handleDeductionAmount(e, index) {
    let newArray = this.state.deductions; //we don't want to mutate state directly.
    newArray.splice(index, 1, e.target.value);
    this.setState({ deductions: newArray });
  }

  render() {
    const { handleChange } = this.props;
    /*for(let [key, value] of Object.entries(this.state.deductions)) {
      alert(`${key}: ${value}`);
    }*/
    return (
      <Form handleChange={handleChange}>
        {this.state.deductions.map((deduction, index) => {
          return (
            <div key={index}>
              <div className="col-6 col-lg-4">
                <InputWrapper>
                  <InputLabel htmlFor="income">Deduction {(index + 1).toString()}</InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputText
                      id="deduction"
                      defaultValue={deduction}
                      onChange={(e) => this.handleDeductionAmount(e, index)}
                      name={'income' + (index + 1).toString()}
                      type="number"
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
          );
        })}
        {}
        <Lede>
          <Button brand="primary" onClick={this.handleAddDeduction}>
            Add Deduction
          </Button>
        </Lede>
        <InputHelp>Please only input deductions that are considered Pre-Tax Deductions.</InputHelp>
      </Form>
    );
  }
}

export default PretaxItems;
