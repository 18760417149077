import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import styled from 'styled-components';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Form,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputNumeric,
  InputSelect,
  InputWrapper,
  Loader,
  Modal,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { withPermissions, Fade, PaddedDD } from '../../routing';
import { safeAccess, stringIsNullOrEmpty, formatAsMoney, formatAsPercent } from '../../utils';

import { getContributionSetupData } from '../../contribution-setup';

const TextLeft = styled.div`
  text-align: left;
`;

class CustomerContributionForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    setup: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    permissions: t.object,
    accountsOnFile: t.object,
    contributionEligibleAccounts: t.object,
    token: t.any,
    contributionData: t.object,
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.props.data.error = '';
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }

    if (
      !prevProps.errors.generalErrors &&
      this.props.errors.generalErrors &&
      this.modalRef.current !== null
    ) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  onAccountChange() {
    //Do things
  }

  showEmployeeContribution() {
    const { setup, permissions } = this.props;
    let retVal = true;

    if (
      !permissions.showForgiveness &&
      (setup.nonPslfAllowCustomEmployeeContribution === 'NO' ||
        setup.nonPslfAllowCustomEmployeeContribution === 'PMTNOPD' ||
        setup.nonPslfAllowCustomEmployeeContribution === 'PMTAMT')
    ) {
      retVal = false;
    }

    return retVal;
  }

  getSelectedAccountInfo() {
    const { data, accountsOnFile } = this.props;

    let account = {};
    if (!stringIsNullOrEmpty(data.loanId)) {
      account = Object.values(accountsOnFile).filter(
        (a) => a.id.toString() === data.loanId.toString()
      )[0];
    }
    return (
      <CardText>
        <div className="row">
          <div className="col-auto">
            <dl>
              <dt>Account Balance</dt>
              <PaddedDD>
                {account !== undefined ? formatAsMoney(account.currentLoanBalance) : null}
              </PaddedDD>
            </dl>
          </div>
          <div className="col-auto">
            <dl>
              <dt>Monthly Payment</dt>
              <PaddedDD>
                {account !== undefined ? formatAsMoney(account.monthlyPayment) : null}
              </PaddedDD>
            </dl>
          </div>
          <div className="col-auto">
            <dl>
              <dt>Interest Rate</dt>
              <PaddedDD>
                {account !== undefined && !isNaN(account.interestRate) ? (
                  formatAsPercent(account.interestRate)
                ) : (
                  <>&nbsp;</>
                )}
              </PaddedDD>
            </dl>
          </div>
        </div>
      </CardText>
    );
  }

  getForm() {
    const {
      action,
      data,
      errors,
      onChange,
      onSubmit,
      permissions,
      contributionEligibleAccounts = {},
      contribution,
    } = this.props;

    if (permissions.showForgiveness && action === 'Create') {
      data.contributionType = contribution.contributionType;
      data.employerContribution = contribution.employerAmount;
      data.employeeContribution = contribution.employeeAmount;
    }

    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <InputWrapper error={!!errors.loanId}>
          <InputLabel htmlFor="loanId" required>
            Account
          </InputLabel>
          <InputError>{safeAccess(errors, 'loanId.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="loanId"
              options={contributionEligibleAccounts}
              value={data.loanId || ''}
              disabled={action === 'Update'}
              onChange={this.onAccountChange}
            />
          </InputGroup>
        </InputWrapper>
        <div className="row">
          <div className="col">{this.getSelectedAccountInfo()}</div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <InputWrapper error={!!errors.contributionType}>
              <InputLabel htmlFor="contributionType" required>
                Employer Allocation Type
              </InputLabel>
              <InputError>{safeAccess(errors, 'contributionType.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  name="contributionType"
                  options={{ '': '', D: 'Dollar Amount', P: 'Percentage' }}
                  value={data.contributionType || ''}
                  disabled={permissions.showForgiveness}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-lg-6">
            <InputWrapper error={!!errors.employerContribution}>
              <InputLabel htmlFor="employerContribution" required>
                Employer Allocation Amount
              </InputLabel>
              <InputError>{safeAccess(errors, 'employerContribution.detail')}</InputError>
              <InputGroup>
                {data.contributionType === 'D' && <InputAddon>$</InputAddon>}
                <InputNumeric
                  name="employerContribution"
                  defaultValue={data.employerContribution}
                  disabled={permissions.showForgiveness}
                />
                {data.contributionType === 'P' && <InputAddon>%</InputAddon>}
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        {this.showEmployeeContribution() && (
          <div className="row">
            <div className="col-12 col-lg-6">
              <InputWrapper error={!!errors.employeeContribution}>
                <InputLabel htmlFor="employeeContribution" required>
                  Employee Allocation Amount
                </InputLabel>
                <InputError>{safeAccess(errors, 'employeeContribution.detail')}</InputError>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    name="employeeContribution"
                    defaultValue={data.employeeContribution}
                    disabled={permissions.showForgiveness}
                  />
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
        )}
        <InputWrapper error={!!errors.active}>
          <InputLabel htmlFor="active" required>
            Contribution Active
          </InputLabel>
          <InputError>{safeAccess(errors, 'active.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="active"
              options={{ true: 'Active', false: 'Inactive' }}
              disabled={action === 'Create'}
              value={data.active !== undefined ? data.active.toString() : 'true'}
            />
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  }

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    return true;
  };

  checkNoErrors = () => {
    return true;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { action, closeForm, errors = {}, isLoading, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={false}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Contribution</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            <TextLeft>{this.getForm()}</TextLeft>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' contribution'}
                </Button>
              </Fade>
              <Fade show={!isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade show={!isLoading && this.checkNoMissingData() && !this.checkNoErrors()}>
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountsOnFile: state.accounts.cache,
    setup: getContributionSetupData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(CustomerContributionForm));
