import React from 'react';
import t from 'prop-types';
import { Button, Table } from 'fiducius-ui';
import { formatAsMoney, formatAsPercent } from '../../../utils';

const QuotesTable = ({ sendApply, quotes = [] }) => {
  let body = [];
  for (let i = 0; i < quotes.length; i++) {
    body.push([
      quotes[i].interestRate,
      quotes[i].rateType,
      quotes[i].loanAmount,
      quotes[i].monthlyPayment,
      quotes[i].termLength,
      <Button key={quotes[i].id} brand="secondary" onClick={() => sendApply(quotes[i].id)}>
        Apply now
      </Button>,
    ]);
  }

  if (body.length > 0) {
    return (
      <Table
        columns={[
          { sortable: true, renderer: formatAsPercent },
          { sortable: true },
          { sortable: true, renderer: formatAsMoney },
          { sortable: true, renderer: formatAsMoney },
          { sortable: true, renderer: (termLength) => termLength.toString() + ' mo.' },
          { sortable: false },
        ]}
        header={[
          'Interest Rate',
          'Rate Type',
          'Loan Amount',
          'Monthly Payment',
          'Term Length',
          'Apply',
        ]}
        body={body}
      />
    );
  } else {
    return null;
  }
};

QuotesTable.propTypes = {
  quotes: t.array,
  sendApply: t.func.isRequired,
};

export default QuotesTable;
