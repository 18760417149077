import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardText, DashboardSection, Header2 } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { getBenefitData } from '../redux/selectors';

import { debounce, mergeRequestStatuses, flatten } from '../../../utils';

import {
  modelerLoadResource,
  modelerCopyResourceToForm,
  modelerHandleFormChange,
} from '../redux/operations';

import PretaxForm from '../components/pretax-form';

//I need this too
class modeler extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    copyRequest: t.object.isRequired,
    data: t.object,
    errors: t.object,
    form: t.object,
    hasData: t.bool,
    handleForm: t.func.isRequired,
    id: t.string,
    loadData: t.func.isRequired,
    reload: t.func.isRequired,
    request: t.object,
    modeler: t.object,
    updateFormState: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {}; // must be initialized for data handling
  }

  componentDidMount() {
    const { id, loadData } = this.props;
    loadData(id);
  }

  componentDidUpdate(prevProps) {
    const wasLoading = prevProps.actionRequest.isLoading;
    const currently = this.props.actionRequest;
    if (wasLoading && !currently.isLoading && !currently.hasFailed) {
      this.setState({ ...this.state, showForm: true }, () => this.props.reload());
    }
  }

  //I need this - but there is more too it
  //make this call to a function that does the calculations
  onChange = debounce((modeler, attributes, formState) => {
    const { data, updateFormState } = this.props;
    const newData = { ...flatten(data), ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 250);

  render() {
    const { request, data } = this.props;

    return (
      <DashboardSection title="Benefits for your financial well-being">
        <Card>
          <CardBody>
            <Header2>Lower your payments and better benefit your financial wellness</Header2>
            <CardText>
              <AsyncLoader request={request}>
                <PretaxForm data={data} isLoading={request.isLoading} onChange={this.onChange} />
              </AsyncLoader>
            </CardText>
          </CardBody>
        </Card>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.modeler;
  return {
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    data: getBenefitData(state),
    form: form,
    errors: errors,
    id: getUserId(state),
    request: state.modeler.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(modelerLoadResource(id));
    dispatch(modelerCopyResourceToForm(id));
  },
  updateFormState: (data, attributes) => dispatch(modelerHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(modeler);
