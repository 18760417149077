import { JsonApiError } from './error';
import { camelCase, snakeCase, upperFirst } from './helpers';

const requestStates = ['REQUEST', 'RESPONSE', 'FAILURE', 'SUCCESS'];

/**
 * Takes multiple request status objects and merges them into one final request
 * status object. The merging is true biased. If any of the requests contains
 * a true value for one of the statuses, it will be true in the final object.
 * Likewise, the most current lastUpdated value is the final value. Note that
 * this implementation could cause a result that is both notStarted and
 * hasFinished. There's not a more sensible way to merge these requests though
 * and our implementation of AsyncLoader should handle this by priority.
 *
 * @param {Array} requests - Array of request objects to merge together
 *
 * @returns {Object} - Final merged request status object
 */
export const mergeRequestStatuses = (requests) => {
  return {
    notStarted: requests.filter((r) => r.notStarted).length > 0,
    isLoading: requests.filter((r) => r.isLoading).length > 0,
    hasFailed: requests.filter((r) => r.hasFailed).length > 0,
    hasFinished: requests.filter((r) => r.hasFinished).length > 0,
    isEmpty: requests.filter((r) => r.isEmpty).length > 0,
    lastUpdated: requests.reduce((latest, r) => {
      const current = r.lastUpdated ? r.lastUpdated : 0;
      return current > latest ? current : latest;
    }, 0),
  };
};

/**
 * Generates status tracking types for asynchronous requests
 *
 * @param {string} feature     - Feature name request falls under.
 * @param {string} requestName - Name of request
 * @param {string} namespace   - (Optional) Namespace for types. Defaults to 'app'.
 *
 * @returns {Object} - Object of generated types
 */
export const requestTypesGenerator = (feature, requestName, namespace = 'app') => {
  let types = {};
  const SSC_FEATURE = snakeCase(feature).toUpperCase();
  const SSC_NAME = snakeCase(requestName).toUpperCase();

  requestStates.forEach((state) => {
    types[`${SSC_FEATURE}_${SSC_NAME}_${state}`] = `${namespace}/${feature}/${SSC_NAME}_${state}`;
  });

  return types;
};

/**
 * Generates status tracking actions for asynchronous requests
 *
 * @param {string} feature     - Feature name request falls under.
 * @param {string} requestName - Name of request
 * @param {Object} types       - Action types to create actions for
 *
 * @returns {Object} - Object of generated actions
 */
export const requestActionsGenerator = (feature, requestName, types) => {
  let actions = {};

  requestStates.forEach((state) => {
    const actionName = feature + upperFirst(camelCase(requestName)) + upperFirst(camelCase(state));
    const SSC_ACTION_NAME = snakeCase(actionName).toUpperCase();
    if (state === 'SUCCESS') {
      actions[actionName] = (isEmpty = false, key = null) => ({
        type: types[SSC_ACTION_NAME],
        isEmpty,
        key,
        lastUpdated: Date.now(),
      });
    } else if (state === 'FAILURE') {
      actions[actionName] = (error, key = null) => ({
        type: types[SSC_ACTION_NAME],
        key,
        error,
        lastUpdated: Date.now(),
      });
    } else {
      actions[actionName] = (key = null) => ({
        type: types[SSC_ACTION_NAME],
        key,
        lastUpdated: Date.now(),
      });
    }
  });

  return actions;
};

/**
 * Generates a status tracking reducer for an asynchronous request
 *
 * @param {string} feature        - Feature to create reducer for
 * @param {string} requestName    - Request name to create reducer for
 * @param {Object} types          - Request redux types
 *
 * @yields {Function} Request reducer
 */
export const requestReducerGenerator = (feature, requestName, types) => {
  const SSC_FEATURE = snakeCase(feature).toUpperCase();
  const SSC_REQUEST_NAME = snakeCase(requestName).toUpperCase();

  return (
    state = {
      key: null,
      notStarted: true,
      isLoading: false,
      hasFailed: false,
      hasFinished: false,
      isEmpty: false,
      lastUpdated: null,
    },
    action
  ) => {
    switch (action.type) {
      case types[`${SSC_FEATURE}_${SSC_REQUEST_NAME}_REQUEST`]:
        return requestedState(state, action.lastUpdated, action.key);
      case types[`${SSC_FEATURE}_${SSC_REQUEST_NAME}_RESPONSE`]:
        return respondedState(state, action.lastUpdated, action.key);
      case types[`${SSC_FEATURE}_${SSC_REQUEST_NAME}_FAILURE`]:
        if (action.error instanceof JsonApiError) {
          return failedState(
            {
              error: action.error.errors,
              ...state,
            },
            action.lastUpdated,
            action.key
          );
        } else {
          return failedState(state, action.lastUpdated, action.key);
        }
      case types[`${SSC_FEATURE}_${SSC_REQUEST_NAME}_SUCCESS`]:
        return successState(state, action.lastUpdated, action.isEmpty, action.key);
      default:
        return state;
    }
  };
};

const requestedState = (state, lastUpdated, key) => ({
  ...state,
  key: key,
  notStarted: false,
  isLoading: true,
  hasFailed: false,
  hasFinished: false,
  lastUpdated: lastUpdated,
});

const respondedState = (state, lastUpdated, key) => ({
  ...state,
  key: key,
  notStarted: false,
  isLoading: false,
  hasFailed: false,
  hasFinished: true,
  isEmpty: false,
  lastUpdated: lastUpdated,
});

const failedState = (state, lastUpdated, key) => ({
  ...state,
  key: key,
  notStarted: false,
  isLoading: false,
  hasFailed: true,
  hasFinished: true,
  isEmpty: false,
  lastUpdated: lastUpdated,
});

const successState = (state, lastUpdated, isEmpty = false, key) => ({
  ...state,
  key: key,
  notStarted: false,
  isLoading: false,
  hasFailed: false,
  hasFinished: true,
  isEmpty: isEmpty,
  lastUpdated: lastUpdated,
});
