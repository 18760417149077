import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { getApiSingle } from '../../utils';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  fade,
  Link,
  DashboardSection,
  Header2,
  Alert,
  InputWrapper,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';

import { contactSaveBillingInfo } from '../redux/operations';
import ContactForm from '../components/contact-form';
import ContactResult from '../components/contact-result';
import { Fade } from '../../routing';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${(p) => (p.double ? 'space-between' : 'flex-end')};
`;
const Fragment = styled.div`
  ${fade};
`;
class Contact extends React.Component {
  static propTypes = {
    form: t.object,
    paymentType: t.string,
    postRequest: t.object,
    postUrl: t.string,
    saveBillingInfo: t.func.isRequired,
    saveRequest: t.object,
    token: t.string,
    alertText: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      data: {},
      errors: {},
      cache: {},
      alertText: undefined,
      show: false,
    };
  }

  componentDidMount() {
    const pathName = window.location.pathname;
    let AlertText = '';
    getApiSingle(null, '/message/' + pathName).then((data) => {
      if (data.messages !== null) {
        const alertMessage = data.messages.find((a) => a.messageType === 'ContactUs_Alert');
        if (alertMessage !== null) {
          AlertText = alertMessage.messageContent;
        }
      }
      if (AlertText.length > 0) {
        this.setState({
          ...this.state,
          alertText: AlertText,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const prevSave = prevProps.saveRequest;
    const currentSave = this.props.saveRequest;
    if (prevSave.isLoading && currentSave.hasFinished /* && !currentSave.hasFailed*/) {
      this.setState({ step: 2 });
    }
  }

  nextStep = () => {
    if (!this.props.saveRequest.isLoading) {
      if (this.props.form.subject !== undefined) {
        this.setState({ show: false });
        this.props.saveBillingInfo();
      } else {
        this.setState({ show: true });
      }
    }
  };

  prevStep = () => this.setState({ step: 1 });

  render() {
    let returnAlertText;
    if (this.state.alertText === undefined) {
      returnAlertText = <></>;
    } else {
      returnAlertText = (
        <Alert brand="primary">
          <div dangerouslySetInnerHTML={{ __html: this.state.alertText }}></div>
        </Alert>
      );
    }

    return (
      <Fragment className="container">
        <div className="row">
          <DashboardSection title="Contact Us">
            <Header2>
              Your time is important, contact us and we will respond as soon as possible
            </Header2>
          </DashboardSection>
          <div className="col-12 col-sm-10 col-lg-10">
            {returnAlertText}
            <Card>
              <CardBody>
                <CardText>
                  {this.state.step === 1 && <ContactForm handleChange={this.handleChange} />}

                  {this.state.step === 2 && <ContactResult />}
                </CardText>
              </CardBody>
              <CardBody>
                <Fade show={this.state.show}>
                  <InputWrapper>
                    <Alert>Please select a subject from the dropdown.</Alert>
                  </InputWrapper>
                </Fade>
                <ButtonWrapper>
                  {this.state.step === 1 && (
                    <Button brand="primary" onClick={this.nextStep}>
                      Submit Question
                    </Button>
                  )}
                  {this.state.step === 2 && (
                    <Button invert brand="primary" onClick={this.prevStep}>
                      Ask Another Question
                    </Button>
                  )}
                </ButtonWrapper>
              </CardBody>
              <CardFooter>
                <Link to="/dashboard">Return to dashboard</Link>
              </CardFooter>
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: safeAccess(state, 'contact.form'),
    postRequest: safeAccess(state, 'contact.requests.createResource', {}),
    saveRequest: safeAccess(state, 'contact.requests.updateResource', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveBillingInfo: () => dispatch(contactSaveBillingInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
