import React, { createContext, useState, useEffect } from 'react';
import { defaultState, getNewState } from '../components/state';
import { connect } from 'react-redux';
import Summary from '../components/summary';
import '../styles/styles.css';

import { planToolHandleFormChange, loadPolarisData } from '../redux/operations';

import { getUserId } from '../../../auth';
import { getBenefitData } from '../redux/selectors';

export const AppContext = createContext({
  state: defaultState,
  onChange: () => () => {},
  onChangeDeductionAction: () => () => {},
});

export const FocusContext = createContext({
  focusPage: () => {},
  focused: null,
});

const PlanTool = ({ handleForm, defaultData, id, loadData2 }) => {
  const [focused, setFocused] = useState(null);
  const focusPage = (page) => {
    setFocused(page);
  };

  const [state, setState] = useState(defaultState);
  const [dataLoaded, setDataLoaded] = useState(false);
  const onChange = (page) => (e) => {
    const { name, value } = e.currentTarget;
    setState((s) => getNewState(s, page, name, value));
  };

  useEffect(() => {
    loadData2(id).then(() => setDataLoaded(true));
  }, []);

  useEffect(() => {
    loadFormObject();
  }, [state]);

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      state['PovertyGuidelines'] = defaultData.povertyTable;

      // Set the default values in the state object
      state['General']['Plan Type'].value =
        defaultData.planType === null ? state['General']['Plan Type'].value : defaultData.planType;
      state['General']['Plan Type'].multiplier =
        defaultData.planTypeMultiplier === null ? 1.0 : defaultData.planTypeMultiplier;
      state['General']['Household Size'].value = defaultData.householdSize;
      state['General']['Marital Status'].value = defaultData.maritalStatus;
      state['General']['Client Gross Income'].value = defaultData.clientGrossIncome;
      state['General']['Spouse Gross Income'].value = defaultData.spouseGrossIncome;
      state['General']['IDR Type'].value =
        defaultData.idrType === null ? state['General']['IDR Type'].value : defaultData.idrType;
      state['General']['Client Loan Balance'].value = defaultData.clientLoanBalance;
      state['General']['Spouse Loan Balance'].value = defaultData.spouseLoanBalance;
      state['General']['Current Loan Payment'].value = defaultData.currentLoanPayment;
      state['General']['Qualifying Payments'].value = defaultData.qualifyingPayments;

      state['Taxes']['Filing Status'].value = defaultData.filingStatus;
      state['Taxes']['Tax Cost'].value = defaultData.taxCost;

      state['Monthly Retirement Contribution']['projectedRetirementDate'].value =
        defaultData.projectedRetirementDate === null ? '' : defaultData.projectedRetirementDate;
      state['Monthly Retirement Contribution']['Retirement Percentage'].value =
        defaultData.retirementPercentage;
      state['Monthly Retirement Contribution']['Monthly Retirement Amount'].value =
        defaultData.monthlyRetirementAmount;
      state['Monthly Retirement Contribution']['Modified Retirement Percentage'].value =
        defaultData.modifiedRetirementPercentage;
      state['Monthly Retirement Contribution']['Modified Retirement Amount'].value =
        defaultData.modifiedMonthlyRetirementAmount;

      state['Health Insurance']['Insurance Type'].value = defaultData.insuranceType;
      state['Health Insurance']['Insurance Coverage'].value = defaultData.insuranceCoverage;
      state['Health Insurance']['Pre-Tax Insurance Cost'].value = defaultData.preTaxInsuranceCost;
      state['Health Insurance']['Annual HSA Contribution'].value =
        defaultData.annualHsaContribution;

      // Other Pre-Tax Costs / Contributions
      defaultData.deductions &&
        defaultData.deductions.map((k, v) => {
          state['Other Pre-Tax Costs / Contributions'][k.name] = Object.assign({
            type: 'number',
            customInput: 'deduction',
            value: k.value,
            help: 'Enter the monthly value of any other pre-tax deductions here.',
            showDeleteButton: v !== 0,
          });
        });

      defaultData.modifiedDeductions &&
        defaultData.modifiedDeductions.map((k, v) => {
          state['Modified Other Pre-Tax Costs / Contributions'][k.name] = Object.assign({
            type: 'number',
            customInput: 'deduction',
            value: k.value,
            help: 'Enter the monthly value of any other pre-tax deductions here.',
            showDeleteButton: v !== 0,
          });
        });

      loadFormObject();
    } else {
      state['PovertyGuidelines'] = {};
    }
  }, [dataLoaded]);

  const onChangeDeductionAction = (page, action) => (e) => {
    const { name } = e.currentTarget;
    setState((s) => getNewState(s, page, action, name));
  };

  const loadFormObject = () => {
    const {
      General: general,
      'Health Insurance': healthInsurance,
      'Monthly Retirement Contribution': monthlyRetirementContribution,
      Taxes: taxes,
    } = state;
    const { 'Other Pre-Tax Costs / Contributions': otherPreTaxCosts } = state;
    const { 'Modified Other Pre-Tax Costs / Contributions': modifiedOtherPreTaxCosts } = state;
    const { UIData: UIData } = state;

    const mergedObject = {
      ...general,
      ...healthInsurance,
      ...monthlyRetirementContribution,
      ...taxes,
      ...UIData,
    };

    let formObject = Object.keys(mergedObject).reduce((tmp, x) => {
      tmp[x] = mergedObject[x].value;

      return tmp;
    }, {});

    let index = 0;
    let deductionArray = [];
    let deductionsObjects = { ...otherPreTaxCosts };
    for (let value in deductionsObjects) {
      let newDeduction = {
        name: Object.keys(otherPreTaxCosts)[index],
        value: deductionsObjects[value].value,
      };
      deductionArray.push(newDeduction);
      index += 1;
    }

    index = 0;
    let modifiedDeductionArray = [];
    let modifiedDeductionsObjects = { ...modifiedOtherPreTaxCosts };
    for (let value in modifiedDeductionsObjects) {
      let newModifiedDeduction = {
        name: Object.keys(modifiedOtherPreTaxCosts)[index],
        value: modifiedDeductionsObjects[value].value,
      };
      modifiedDeductionArray.push(newModifiedDeduction);
      index += 1;
    }

    formObject = {
      ...formObject,
      deductions: deductionArray,
      modifiedDeductions: modifiedDeductionArray,
    };

    handleForm(formObject, Object.keys(formObject));
    return formObject;
  };

  return (
    <FocusContext.Provider value={{ focusPage, focused }}>
      <AppContext.Provider value={{ state, onChange, onChangeDeductionAction }}>
        <Summary loadFormObject={loadFormObject} data={state} />
      </AppContext.Provider>
    </FocusContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  defaultData: getBenefitData(state, getUserId(state)),
  id: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleForm: (data, attributes) => dispatch(planToolHandleFormChange(data, attributes)),
  loadData2: async (id) => await dispatch(loadPolarisData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanTool);
