import React from 'react';
import t from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardText, Header1, Link } from 'fiducius-ui';

import { FullscreenContainer } from '../../routing';

import SignFulfillment from '../components/sign-fulfillment';

const Fulfillment = ({ match }) => (
  <FullscreenContainer className="container">
    <div className="row">
      <div className="col-12">
        <Card>
          <CardBody>
            <Header1>Electronic Document Signature</Header1>
            <CardText>
              <SignFulfillment id={match.params.fileId} />
            </CardText>
          </CardBody>
          <CardFooter>
            <Link to="/dashboard">Return home</Link>
          </CardFooter>
        </Card>
      </div>
    </div>
  </FullscreenContainer>
);

Fulfillment.propTypes = {
  match: t.shape({
    params: t.object,
  }),
};

export default withRouter(Fulfillment);
