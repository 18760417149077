import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Workflow, Button } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { debounce, safeAccess } from '../../../utils';

import TabInstitution from './tab-institution';
import TabTerm from './tab-term';
import TabClass from './tab-class';
import TabReview from './tab-review';
import {
  tuitionInstitutionClassClearForm,
  tuitionInstitutionClassHandleFormChange,
  tuitionInstitutionClassValidateAttributes,
} from '../redux/operations';

class TuitionWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    errors: t.object,
    hasAdvisorResults: t.bool.isRequired,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
    clearForm: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { current: props.current || 0, farthest: props.current || 0, redirectURI: '' };
  }

  componentDidMount() {}

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    if (formState.institutionId !== data.institutionId) {
      formState.termId = undefined;
    }
    const newData = { ...data, ...formState };
    updateForm(newData, this.getDependentAttributes(attribute));
  }, 250);

  getDependentAttributes = (attribute) => {
    switch (attribute) {
      case 'institutionId':
        return ['institutionId', 'termId'];
      default:
        return [attribute];
    }
  };

  handleTabChange = (index) => {
    this.props.validateTab(Object.keys(tabs)[this.state.current]);
    this.setState({
      current: index,
      farthest: index > this.state.farthest ? index : this.state.farthest,
    });
  };

  sectionValidationStatus(section, index) {
    const { data, errors } = this.props;
    let exists = false;
    if (index <= this.state.farthest) {
      for (let i = 0; i < tabs[section].length; i++) {
        let attribute = tabs[section][i];
        if (safeAccess(errors, `[${attribute}]`)) {
          return 'danger';
        } else if (!safeAccess(data, `[${attribute}]`) || data.hours >= 100) {
          exists = false;
        } else if (safeAccess(errors, `[${attribute}]`) === false) {
          exists = true;
        }
      }
    }
    return exists ? 'success' : null;
  }

  reviewValidationStatus = () => {
    const institution = this.sectionValidationStatus('institution', 0);
    const term = this.sectionValidationStatus('term', 1);
    const classSection = this.sectionValidationStatus('class', 2);

    if (institution === 'success' && term === 'success' && classSection === 'success') {
      return 'success';
    } else {
      return null;
    }
  };

  addAnotherClass = () => {
    this.props.clearForm();
    this.setState({ ...this.state, redirectURI: '/tuition/new-class' });
  };

  render() {
    const { data = {}, errors } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <>
          <Workflow
            tabs={[
              <TabInstitution
                key="1"
                id="institution"
                data={data}
                errors={errors}
                brand={this.sectionValidationStatus('institution', 0)}
                name="Institution"
                handleChange={this.handleChange}
              />,
              <TabTerm
                key="2"
                id="term"
                data={data}
                errors={errors}
                brand={this.sectionValidationStatus('term', 1)}
                name="Term"
                handleChange={this.handleChange}
              />,
              <TabClass
                key="3"
                id="class"
                data={data}
                errors={errors}
                brand={this.sectionValidationStatus('class', 2)}
                name="Class"
                handleChange={this.handleChange}
              />,
              <TabReview
                key="4"
                id="summary"
                data={data}
                errors={errors}
                brand={this.reviewValidationStatus()}
                name="Summary"
                handleChange={this.handleChange}
                addAnotherClass={this.addAnotherClass}
              />,
            ]}
            finalButton={
              <Button
                brand="primary"
                invert
                onClick={() => {
                  this.setState({ ...this.state, redirectURI: '/benefits/tuition/overview' });
                }}
              >
                Back to Tuition Reimbursement Dashboard
              </Button>
            }
            handleTabChange={this.handleTabChange}
            next
            prev
            finalPageLocksFlow
            finalPageCheck={this.reviewValidationStatus}
            penUltimateButton={'Add Class'}
          />
        </>
      </>
    );
  }
}

const tabs = {
  institution: ['institutionId'],
  term: ['termId'],
  class: ['className'],
  results: [],
};

const mapStateToProps = (state) => {
  const { errors, form } = state.tuitionInstitutionClass;
  return {
    id: getUserId(state),
    data: form,
    errors: errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateForm: (data, attributes) => {
    dispatch(tuitionInstitutionClassHandleFormChange(data, attributes));
  },
  clearForm: () => {
    dispatch(tuitionInstitutionClassClearForm());
  },
  validateTab: async (tab) => {
    await dispatch(tuitionInstitutionClassValidateAttributes(tabs[tab]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionWorkflow);
