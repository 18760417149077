import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  Header5,
  InputAutocomplete,
  AsyncLoader,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { safeAccess, getApi, stringIsNullOrEmpty } from '../../utils';
import { Fade } from '../../routing';
import { getToken } from '../../auth';
import { connect } from 'react-redux';

const TextLeft = styled.div`
  text-align: left;
`;

const ResultWrapper = styled.div`
  color: ${(p) => p.theme.textSecondary};
`;

const InstitutionWrapper = styled.div`
  align-self: stretch;
  align-items: center;
  display: flex;
`;

const Highlight = styled.strong`
  background-color: ${(p) => p.theme.colorLevel(p.theme.warning, -5)};
`;

class TuitionInstitutionForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    getResource: t.object,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    onUpdateDropdown: t.func.isRequired,
    token: t.any,
    allowedDegreeTypes: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { showFetcher: false };
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }
    /*if (!prevProps.errors.generalErrors && this.props.errors.generalErrors) {
      this.modalRef.current.scrollTo(0, 0);
    }*/

    if (!this.state.showFetcher) {
      if (
        (prevProps.data.institutionName !== this.props.data.institutionName &&
          prevProps.data.dapipId) ||
        this.props.action === 'Add'
      ) {
        this.setState({ ...this.state, showFetcher: true });
      }
    }
  }

  asyncFetcher = (value) => {
    if (value && value.length > 2) {
      return getApi(
        this.props.token,
        `/drop-down-list/Institution__${encodeURIComponent(value)}`
      ).then((data) => {
        if (data.length > 50) {
          return [
            {
              selected: false,
              render: () => (
                <ResultWrapper>
                  <div key="too-many-results">Too many results. Try narrowing it down.</div>
                </ResultWrapper>
              ),
            },
          ];
        } else {
          return data.map((institution) => ({
            id: institution.id,
            name: institution.description,
            render: () => this.renderInstitutionResult(institution, value),
          }));
        }
      });
    } else if (value) {
      return [
        {
          selected: false,
          render: () => (
            <ResultWrapper>
              <div key="need-more">Too many results. Type more to help narrow it down.</div>
            </ResultWrapper>
          ),
        },
      ];
    } else {
      return new Promise((resolve) => resolve([]));
    }
  };

  asyncNullFetcher = (value) => {
    return new Promise((resolve) => resolve([]));
  };

  renderInstitutionResult = (institution, value) => {
    const parts = institution.description.split(value);
    return (
      <InstitutionWrapper>
        <Header5>
          {parts.map((part, i) => (
            <React.Fragment key={i}>
              {part}
              {i + 1 !== parts.length && <Highlight>{value}</Highlight>}
            </React.Fragment>
          ))}
        </Header5>
      </InstitutionWrapper>
    );
  };

  getForm() {
    const {
      action,
      data,
      errors,
      onChange,
      onSubmit,
      onUpdateDropdown,
      allowedDegreeTypes,
    } = this.props;

    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <InputWrapper error={!!errors.degreeType}>
          <InputLabel htmlFor="degreeType" required>
            Degree Type
          </InputLabel>
          <InputError>{safeAccess(errors, 'degreeType.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="degreeType"
              value={data.degreeType}
              options={allowedDegreeTypes}
              disabled={action === 'Delete'}
            />
          </InputGroup>
        </InputWrapper>
        <Fade show={data.degreeType}>
          <Fade show={data.degreeType === 'CERT'}>
            <InputWrapper error={!!errors.institutionName}>
              <InputLabel required>Certification Location</InputLabel>
              <InputError>{safeAccess(errors, 'institutionName.detail')}</InputError>
              <InputGroup affordance="large">
                <InputText
                  name="institutionName"
                  placeholder="Certification Location"
                  defaultValue={data.institutionName || ''}
                  disabled={action === 'Delete'}
                />
              </InputGroup>
            </InputWrapper>
          </Fade>
          <Fade show={data.degreeType !== 'CERT'}>
            <InputWrapper error={!!errors.institutionName}>
              <InputLabel required>Institution Name</InputLabel>
              <InputError>{safeAccess(errors, 'institutionName.detail')}</InputError>
              <InputGroup affordance="large">
                <InputAutocomplete
                  fetcher={this.state.showFetcher ? this.asyncFetcher : this.asyncNullFetcher}
                  name="institutionName"
                  placeholder="Institution Name"
                  onSelect={onUpdateDropdown}
                  defaultValue={data.institutionName || ''}
                  disabled={action === 'Delete'}
                />
              </InputGroup>
            </InputWrapper>
          </Fade>
        </Fade>
      </Form>
    );
  }

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {} } = this.props;
    return !stringIsNullOrEmpty(data.institutionName) && !stringIsNullOrEmpty(data.degreeType);
  };

  checkNoErrors = () => {
    const { errors = {} } = this.props;
    return !errors.institutionName && !errors.degreeType && !errors.dapipId;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { action, getResource, closeForm, errors = {}, isLoading, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Institution</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            {action === 'Add' && <TextLeft>{this.getForm()}</TextLeft>}
            {(action === 'Update' || action === 'Delete') && (
              <AsyncLoader request={getResource}>
                <TextLeft>{this.getForm()}</TextLeft>
              </AsyncLoader>
            )}
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' institution'}
                </Button>
              </Fade>
              <Fade show={!getResource.isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade
                show={!getResource.isLoading && this.checkNoMissingData() && !this.checkNoErrors()}
              >
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: getToken(state),
  };
};

export default connect(mapStateToProps)(TuitionInstitutionForm);
