import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardHeader, CardText, Header5, Link } from 'fiducius-ui';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const RefinanceQualify = withPermissions(({ rate, cache }) => (
  <Card brand="primary">
    <CardHeader>Refinance</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Are you paying too much interest on your student loans?</Header5>
        <Spaced>
          Find out if you can lower your interest rate or change your terms to save money. Check out
          your Refinance Tab or the <Link to="/faq">FAQ's</Link> to learn more.
        </Spaced>
      </CardText>
      {/*<Fade show={cache.qualifiedForgiveness === false}>
        <StyledDiv className="col-12">
          <a href="http://www.LKrefi.com/fiducius" target="_blank" rel="noopener noreferrer">
            <Button brand="primary">See Payment Options</Button>
          </a>
        </StyledDiv>
</Fade>*/}
    </CardBody>
  </Card>
));

RefinanceQualify.propTypes = {
  rate: t.number.isRequired,
};

const mapStateToProps = (state) => {
  const { advisor } = state;
  const { cache } = advisor;
  const id = getUserId(state);
  return {
    cache: cache[id],
  };
};

export default connect(mapStateToProps)(withPermissions(RefinanceQualify));
