import React from 'react';
import t from 'prop-types';
import { CardBody, CardTitle, CardText, Form, InputRadio } from 'fiducius-ui';

import { useScrollToTopOnMount } from '../../../utils';

import AdjustedHeader from '../styles/adjusted-header';
import PlanCard from '../styles/plan-card';

const getOption = (option, disabled, currentPayInFull) => {
  const optionLabel = (
    <PlanCard>
      <CardBody>
        <CardTitle>{option.payInFull ? 'Pay in Full' : 'Payment Plan'}</CardTitle>
        <CardText>
          <p dangerouslySetInnerHTML={option.message} />
        </CardText>
      </CardBody>
    </PlanCard>
  );

  return (
    <InputRadio
      name="payInFull"
      value={option.payInFull ? 'Y' : 'N'}
      label={optionLabel}
      defaultChecked={option.payInFull === currentPayInFull}
      disabled={disabled}
    />
  );
};

const TabPlan = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  const fullMessage =
    data.planType === 'FS' ? data.fullServicePayInFullMessage : data.selfServicePayInFullMessage;
  const planMessage =
    data.planType === 'FS'
      ? data.fullServicePaymentPlanMessage
      : data.selfServicePaymentPlanMessage;
  return (
    <Form id={id} handleChange={handleChange} defaults={data}>
      <AdjustedHeader>Select a payment option</AdjustedHeader>
      <div className="row">
        <div className="col-12 col-md-6">
          {getOption(
            {
              payInFull: true,
              message: { __html: fullMessage },
            },
            false,
            data.payInFull
          )}
        </div>
        {
          /*data.planType === 'FS' &&*/ <div className="col-12 col-md-6">
            {getOption(
              {
                payInFull: false,
                message: { __html: planMessage },
              },
              false,
              data.payInFull
            )}
          </div>
        }
      </div>
    </Form>
  );
};

TabPlan.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabPlan;
