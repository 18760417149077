import { FormValidator } from '../../utils';

export class TuitionInstitutionValidator extends FormValidator {
  degreeType(validator) {
    validator.isRequired();
  }

  institutionName(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(500);
    if (state.degreeType && state.degreeType !== 'CERT') {
      if (state.dapipId === undefined || state.dapipId === '' || isNaN(state.dapipId)) {
        validator.handleFailure('needs a valid selection.');
      }
    }
  }
}
