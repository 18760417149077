import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Form,
  Header2,
  InputError,
  InputGroup,
  InputLabel,
  InputNumeric,
  InputRadio,
  InputWrapper,
  Lede,
} from 'fiducius-ui';

import { Fade } from '../../../routing';
import { useScrollToTopOnMount, safeAccess } from '../../../utils';

import DependentForm from './dependent-form';

const StyledLede = styled(Lede)`
  margin-bottom: 2rem;
`;

const getDependents = (size, dependents = [], errors) => {
  let forms = [];
  const existingSize = dependents != null ? dependents.length : 0;
  for (let i = 0; i < size; i++) {
    let data = {};
    let dependentErrors = {};
    if (i < existingSize) {
      data = dependents[i];
      dependentErrors['dependentName'] = safeAccess(errors, 'dependentName' + i);
      dependentErrors['dependentLastName'] = safeAccess(errors, 'dependentLastName' + i);
      dependentErrors['dependentRelationship'] = safeAccess(errors, 'dependentRelationship' + i);
      dependentErrors['dependentBirthdate'] = safeAccess(errors, 'dependentBirthdate' + i);
    }
    forms.push(
      <DependentForm key={`dependent-form-${i}`} data={data} id={i} errors={dependentErrors} />
    );
  }

  return forms;
};

const TabHousehold = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your household</Header2>
      <StyledLede>
        Household size always includes you, spouse, and children (including unborn children who will
        be born during the year). Household size also includes other people that live with you now
        that are assisting at least 50% of the household expenses or that are receiving 50% or more
        in support. Your household size may be different from the number of exemptions you claim on
        your federal income tax return.
      </StyledLede>
      <InputWrapper>
        <InputLabel>
          Our records indicate that you have a household size of {data.currentHouseholdSize}{' '}
          <strong>including yourself</strong>. Is that correct?
        </InputLabel>
        <InputRadio name="clientHouseholdSizeCorrect" value="Y" label="Yes" defaultChecked={true} />
        <InputRadio name="clientHouseholdSizeCorrect" value="N" label="No" />
      </InputWrapper>
      <Fade id="householdGate" show={data.clientHouseholdSizeCorrect === 'N'}>
        <InputWrapper error={!!errors.householdSize}>
          <InputLabel htmlFor="householdSize">
            How many people are there in your household <strong>not including yourself</strong>?
          </InputLabel>
          <InputError>{safeAccess(errors, 'householdSize.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric name="householdSize" min={0} defaultValue={data.householdSize} />
          </InputGroup>
        </InputWrapper>
      </Fade>
      {getDependents(data.householdSize, data.dependents, errors)}
    </Form>
  );
};

TabHousehold.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabHousehold;
