import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';
import { TuitionApprovalValidator } from './validators';

const tuitionApprovalValidator = new TuitionApprovalValidator();

const fixData = (data) => {
  return data.map((resource) => {
    //resource.email = resource.email;
    //resource.classes = resource.classes;
    resource.classLength = resource.classes.length;
    for (let i = 0; i < resource.classes.length; i++) {
      resource['classId' + i] = resource.classes[i].classId;
      resource['approvalStatus' + i] = getValueFromNullableBool(resource.classes[i].approvalStatus);
      resource['classNote' + i] = resource.classes[i].classNote;
    }
    return { ...resource };
  });
};

const getValueFromNullableBool = (initialValue) => {
  if (initialValue) {
    if (initialValue === true) {
      return 'Y';
    }
    return 'N';
  }
  return null;
};

const endpointHandler = {
  endpoint: '/tuition-approval',
  customResponseHandler: fixData,
  resourceType: 'tuition-approval',
};

const operations = resourceOperationsGenerator(
  'tuitionApproval',
  actions,
  null,
  tuitionApprovalValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null,
  null
);

const {
  tuitionApprovalClearForm,
  tuitionApprovalCopyResourceToForm,
  tuitionApprovalHandleFormChange,
  tuitionApprovalLoadResource,
  tuitionApprovalUpdateResource,
} = operations;

const tuitionApprovalSaveApprovalInfo = (id) => async (dispatch, getState) => {
  const state = getState();
  let data = state.tuitionApproval.form;

  data.email = data.email.trim();
  let classes = [];
  for (let i = 0; i < data.classes.length; i++) {
    let myClass = {
      'class-id': data.classes[i].classId,
      'class-name': data.classes[i].className,
      'approval-status': data.classes[i].approvalStatus === 'Y',
      'class-note': (data.classes[i].classNote || '').trim(),
      'class-status': (data.classes[i].classStatus || '').trim(),
    };
    classes.push(myClass);
  }

  data.classes = classes;
  state.tuitionApproval.form = data;
  await dispatch(tuitionApprovalUpdateResource(id));
};

export {
  tuitionApprovalClearForm,
  tuitionApprovalCopyResourceToForm,
  tuitionApprovalHandleFormChange,
  tuitionApprovalLoadResource,
  tuitionApprovalSaveApprovalInfo,
};

export default operations;
