import actions from './actions';

import { getToken } from '../../auth';
import { getApiSingle, resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/documents',
  resourceType: 'document',
};

const operations = resourceOperationsGenerator(
  'documents',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null,
  null
);

const documentsLoadContbFaq = () => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  let data = null;
  try {
    dispatch(actions.documentsLoadResourceRequest(1));
    data = await getApiSingle(getToken(state), '/documents/1/CONTBFAQ');

    dispatch(actions.documentsLoadResourceResponse(1));

    dispatch(actions.documentsLoadResourceSuccess(false, 1));
  } catch (error) {
    dispatch(operations.documentsHandleError(error));
    dispatch(actions.documentsLoadResourceFailure(error, 1));
  }
  return data;
};

const documentsLoadTrFaq = () => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  let data = null;
  try {
    dispatch(actions.documentsLoadResourceRequest(1));
    data = await getApiSingle(getToken(state), '/documents/1/TRFAQ');

    dispatch(actions.documentsLoadResourceResponse(1));

    dispatch(actions.documentsLoadResourceSuccess(false, 1));
  } catch (error) {
    dispatch(operations.documentsHandleError(error));
    dispatch(actions.documentsLoadResourceFailure(error, 1));
  }
  return data;
};

const documentsRemoveFromCache = (id) => (dispatch, getState) => {
  let cache = getState().documents.cache;
  dispatch(actions.documentsRemoveFromCache(id));
  delete cache[id];
  dispatch(actions.documentsHandleCacheChange(cache));
};

const documentClearUploadedFile = (id) => (dispatch, getState) => {
  let cache = getState().documents.cache;
  dispatch(actions.documentsRemoveFromCache(id));
  if (cache[id]) {
    cache[id].name = undefined;
    dispatch(actions.documentsHandleCacheChange(cache));
    dispatch(actions.documentsHandleFormChange(cache[id]));
  }
};

const documentsLoadExampleFile = (code, hostFileId) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  try {
    dispatch(actions.documentsLoadResourceRequest(hostFileId));
    let data = await getApiSingle(getToken(state), `/documents/1/${code}`);

    dispatch(actions.documentsLoadResourceResponse(hostFileId));

    let { cache } = state.documents;
    data.id = 'example';
    cache[hostFileId]['exampleFile'] = data;

    await dispatch(operations.documentsHandleCacheChange(cache));
    dispatch(actions.documentsLoadResourceSuccess(false, hostFileId));
  } catch (error) {
    dispatch(operations.documentsHandleError(error));
    dispatch(actions.documentsLoadResourceFailure(error, hostFileId));
  }
};

const documentsLoadScreeningNSLDS = () => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  let data = null;
  try {
    dispatch(actions.documentsLoadResourceRequest(-1));
    data = await getApiSingle(getToken(state), '/documents/1/SCRNNSLDS');

    dispatch(actions.documentsLoadResourceResponse(-1));

    let { cache } = state.documents;
    cache['-1'] = data;

    await dispatch(operations.documentsHandleCacheChange(cache));

    dispatch(actions.documentsLoadResourceSuccess(false, -1));
  } catch (error) {
    dispatch(operations.documentsHandleError(error));
    dispatch(actions.documentsLoadResourceFailure(error, -1));
  }
};

const { documentsDisableValidation } = actions;
const {
  documentsHandleFormChange,
  documentsLoadCollection,
  documentsLoadResource,
  documentsCreateResource,
} = operations;

export {
  documentsCreateResource,
  documentsDisableValidation,
  documentsHandleFormChange,
  documentsLoadCollection,
  documentsLoadExampleFile,
  documentsLoadResource,
  documentsRemoveFromCache,
  documentsLoadContbFaq,
  documentsLoadTrFaq,
  documentClearUploadedFile,
  documentsLoadScreeningNSLDS,
};

export default operations;
