import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection, Header5 } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';

class DocNeededRSFS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    docsNeeded: t.object,
    loadDocs: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <Card brand="primary">
            <CardBody>
              <CardTitle>Documentation Needed</CardTitle>
              <CardText>
                <Header5>
                  <p>
                    We are waiting for you to return documents to continue your renewal process.
                    Please go to the “My Documents” page to determine what documents are still
                    needed.
                  </p>
                  <p>
                    {/*<DocsNeededForRSUpload documentButton={true} />*/}
                    <RenewalConfirmation stepId={202} />
                  </p>
                </Header5>
              </CardText>
            </CardBody>
          </Card>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  docsNeeded: state.documents.cache,
  //request: state.todos.requests.loadCollection,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(DocNeededRSFS));
