import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Form,
  Header4,
  InputCheckbox,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';

const TextMessageText = styled.div`
  margin-left: 2rem;
`;

const StyledCheckbox = styled.div`
  & > label.input-checkbox {
    margin-bottom: 0;
    color: ${(p) => p.theme.textPrimary};
  }
`;

const ContactForm = ({ data = {}, errors = {}, handleChange }) => (
  <Form id="contact" handleChange={handleChange} defaults={data}>
    <div className="row">
      <div className="col-12 col-sm-6">
        <InputWrapper error={!!errors.cellPhone}>
          <InputLabel htmlFor="cellPhone" required>
            Phone Number
          </InputLabel>
          <InputError>{safeAccess(errors, 'cellPhone.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={data.cellPhone}
              mask="(###) ###-####"
              name="cellPhone"
              placeholder="(___) ___-____"
              type="tel"
            />
          </InputGroup>
          <StyledCheckbox>
            <InputCheckbox
              defaultChecked={data.textNotifications}
              name="textNotifications"
              label="I agree to receive text messages from Fiducius."
            />
          </StyledCheckbox>
          <TextMessageText>
            Message and data rates may apply. Message frequency varies. Reply HELP for help and STOP
            to cancel. View our <a href="/terms-of-use">Terms of Use</a> and{' '}
            <a href="/privacy">Privacy Policy</a>.
          </TextMessageText>
        </InputWrapper>
      </div>
      <div className="col-12 col-sm-6">
        <InputWrapper error={!!errors.altPhone}>
          <InputLabel htmlFor="altPhone">Alternate Number</InputLabel>
          <InputError>{safeAccess(errors, 'altPhone.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={data.altPhone}
              mask="(###) ###-####"
              name="altPhone"
              placeholder="(___) ___-____"
              type="tel"
            />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
    <Header4>Home Address</Header4>
    <InputWrapper error={!!errors.addressOne}>
      <InputLabel htmlFor="addressOne" required>
        Address Line 1
      </InputLabel>
      <InputError>{safeAccess(errors, 'addressOne.detail')}</InputError>
      <InputGroup>
        <InputText defaultValue={data.addressOne} name="addressOne" />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.addressTwo}>
      <InputLabel htmlFor="addressTwo">Address Line 2</InputLabel>
      <InputError>{safeAccess(errors, 'addressTwo.detail')}</InputError>
      <InputGroup>
        <InputText defaultValue={data.addressTwo} name="addressTwo" />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.city}>
      <InputLabel htmlFor="city" required>
        City
      </InputLabel>
      <InputError>{safeAccess(errors, 'city.detail')}</InputError>
      <InputGroup>
        <InputText defaultValue={data.city} name="city" />
      </InputGroup>
    </InputWrapper>
    <div className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.usState}>
          <InputLabel htmlFor="usState" required>
            State
          </InputLabel>
          <InputError>{safeAccess(errors, 'usState.detail')}</InputError>
          <InputGroup>
            <InputSelect
              defaultValue={data.usState}
              name="usState"
              options={[
                '',
                'AL',
                'AK',
                'AR',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MS',
                'MT',
                'NC',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'ND',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VT',
                'VA',
                'WA',
                'WI',
                'WV',
                'WY',
              ]}
            />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.zipCode}>
          <InputLabel htmlFor="zipCode" required>
            Zip Code
          </InputLabel>
          <InputError>{safeAccess(errors, 'zipCode.detail')}</InputError>
          <InputGroup>
            <InputText defaultValue={data.zipCode} name="zipCode" />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
  </Form>
);

ContactForm.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func.isRequired,
};

export default ContactForm;
