import React from 'react';
import t from 'prop-types';

import { FullscreenContainer, ContrastDisplay } from '../../routing';

const LegalPage = ({ children, title }) => (
  <FullscreenContainer className="container">
    <div className="row no-gutters">
      <div className="col-12">
        <ContrastDisplay>{title}</ContrastDisplay>
        {children}
      </div>
    </div>
  </FullscreenContainer>
);

LegalPage.propTypes = {
  children: t.node,
  title: t.string.isRequired,
};

export default LegalPage;
