import types from './types';

// Data actions
const persistInitialLoad = (initialLoad) => ({
  type: types.PERSIST_INITIAL_LOAD,
  initialLoad,
});
const persistRememberMe = (rememberMe) => ({
  type: types.PERSIST_REMEMBER_ME,
  rememberMe,
});
// Note: must be handled in rootReducer in order to act on the entire state tree
const persistHydrateState = (data) => ({
  type: types.PERSIST_HYDRATE_STATE,
  data,
});
// Note: must be handled in rootReducer in order to act on the entire state tree
const persistResetState = () => ({
  type: types.PERSIST_RESET_STATE,
});

// Clear state and browser storage
const persistClearRequest = () => ({
  type: types.PERSIST_CLEAR_REQUEST,
});
const persistClearResponse = () => ({
  type: types.PERSIST_CLEAR_RESPONSE,
});
const persistClearFailure = (error) => ({
  error,
  type: types.PERSIST_CLEAR_FAILURE,
  lastUpdated: Date.now(),
});
const persistClearSuccess = () => ({
  type: types.PERSIST_CLEAR_SUCCESS,
  lastUpdated: Date.now(),
});

// Load from browser storage to redux
const persistLoadRequest = () => ({
  type: types.PERSIST_LOAD_REQUEST,
});
const persistLoadResponse = () => ({
  type: types.PERSIST_LOAD_RESPONSE,
});
const persistLoadFailure = (error) => ({
  error,
  type: types.PERSIST_LOAD_FAILURE,
  lastUpdated: Date.now(),
});
const persistLoadSuccess = () => ({
  type: types.PERSIST_LOAD_SUCCESS,
  lastUpdated: Date.now(),
});

// Save from redux to browser storage
const persistSaveRequest = () => ({
  type: types.PERSIST_SAVE_REQUEST,
});
const persistSaveResponse = (data) => ({
  type: types.PERSIST_SAVE_RESPONSE,
  data,
});
const persistSaveFailure = () => ({
  type: types.PERSIST_SAVE_FAILURE,
  lastUpdated: Date.now(),
});
const persistSaveSuccess = () => ({
  type: types.PERSIST_SAVE_SUCCESS,
  lastUpdated: Date.now(),
});

export default {
  persistInitialLoad,
  persistRememberMe,
  persistHydrateState,
  persistResetState,

  persistClearRequest,
  persistClearResponse,
  persistClearFailure,
  persistClearSuccess,

  persistLoadRequest,
  persistLoadResponse,
  persistLoadFailure,
  persistLoadSuccess,

  persistSaveRequest,
  persistSaveResponse,
  persistSaveFailure,
  persistSaveSuccess,
};
