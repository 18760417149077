import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Alert, AsyncLoader, Button, Loader, Workflow } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { fulfillmentUpdateResource } from '../../../fulfillment';
import { debounce, mergeRequestStatuses, safeAccess } from '../../../utils';

import TabIdr, { IDR_DOC_ID } from './tab-idr';
import TabHousehold from './tab-household';
import TabSimple from './tab-simple';
import TabPlan from './tab-plan';
import {
  idrClearForm,
  idrCopyResourceToForm,
  idrHandleFormChange,
  idrHandleCacheChange,
  idrLoadResource,
  idrValidateAttributes,
} from '../redux/operations';
import { withPermissions } from '../../../routing';

class IdrWorkflow extends React.Component {
  static propTypes = {
    clearData: t.func,
    current: t.number,
    data: t.object,
    errors: t.object,
    id: t.string,
    loadData: t.func.isRequired,
    permissions: t.object,
    request: t.object.isRequired,
    signApplication: t.func.isRequired,
    signRequest: t.object,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { current: props.current || 0 };
  }

  componentDidMount() {
    this.props.clearData();
    this.props.loadData(this.props.id);
  }

  // componentWillUnmount() {
  //   this.props.clearData();
  // }

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };
    updateForm(
      this.clearDependentAttributes(attribute, newData),
      this.getDependentAttributes(attribute)
    );
  }, 250);

  clearDependentAttributes = (attribute, data) => {
    let newData = data;
    switch (attribute) {
      case 'question7Response': {
        if (data[attribute] !== 'B') {
          tabs.household.slice(3).forEach((k) => {
            newData[k] = null;
          });
          break;
        }
        if (data[attribute] !== 'A' && data[attribute] !== 'B' && data[attribute] !== 'C') {
          newData.question11Response = null;
          newData.question12Response = null;
        }
        break;
      }
      case 'question10Response': {
        if (data[attribute] !== 'A') {
          newData.question13Response = null;
          newData.question14Response = null;
          break;
        }
        if (data[attribute] !== 'B') {
          newData.question11Response = null;
          newData.question12Response = null;
        }
        break;
      }
      case 'question11Response': {
        if (data.question11Response !== 'A' && data.question11Response !== 'C') {
          newData.question12Response = null;
        }
        break;
      }
      case 'question13Response': {
        if (data[attribute] !== 'B') {
          newData.question14Response = null;
        }
        break;
      }
      default:
        break;
    }
    return newData;
  };

  getDependentAttributes = (attribute) => {
    return [attribute];
  };

  getPlanAttributes = () => {
    return this.props.permissions.clientStatus === 'CRET'
      ? ['inDeferOrForbearance']
      : ['repaymentPlan', 'repaymentBegan', 'inDeferOrForbearance'];
  };

  getSimpleAttributes = () => {
    const { data } = this.props;
    let retArray = ['question5Response', 'question6Response'];
    if (data.isMarried) {
      retArray.push('question8Response', 'question9BResponse', 'question9CResponse');
    }
    return retArray;
  };

  getHouseHoldAttributes = () => {
    const { data } = this.props;
    let retArray = ['agi', 'question5Response', 'question6Response', 'question7Response'];
    if (data.question7Response === 'B') {
      retArray.push(
        'question8Response',
        'question9BResponse',
        'question9CResponse',
        'question10Response',
        ''
      );
    }

    if (data.question10Response === 'A') {
      retArray.push('question13Response');
      if (data.question13Response === 'B') {
        retArray.push('question14Response');
      }
      if (
        data.question13Response === 'A' ||
        data.question13Response === 'C' ||
        data.question14Response === 'A'
      ) {
        retArray.push('question15Response');

        if (data.question15Response === 'A') {
          retArray.push('question16Response');
        }
      }
    }
    if (data.question10Response === 'B') {
      retArray.push('question11Response');
      if (data.question11Response === 'A' || data.question11Response === 'C') {
        retArray.push('question12Response');
      }
    }

    if (data.question7Response === 'A' || data.question7Response === 'C') {
      retArray.push('question11Response');
      if (data.question11Response === 'A' || data.question11Response === 'C') {
        retArray.push('question12Response');
      }
    }
    return retArray;
  };

  sectionValidationStatus(section) {
    const { data, errors } = this.props;
    let exists = false;

    let selectableAttributes = [];
    if (data.selfService && section === 'plan') {
      selectableAttributes = this.getPlanAttributes();
    } else if (data.selfService && section === 'household') {
      selectableAttributes = this.getHouseHoldAttributes();
    } else if (!data.selfService && section === 'simple') {
      selectableAttributes = this.getSimpleAttributes();
    }

    for (let i = 0; i < tabs[section].length; i++) {
      let attribute = tabs[section][i];
      if (selectableAttributes.length > 0 && selectableAttributes.includes(attribute)) {
        if (safeAccess(errors, `[${attribute}]`)) {
          return 'danger';
        } else if (safeAccess(errors, `[${attribute}]`) === null) {
          return null;
        } else if (safeAccess(errors, `[${attribute}]`) === false) {
          exists = true;
        }
      } else if (selectableAttributes.length === 0) {
        exists = true;
      }
    }
    return exists ? 'success' : null;
  }

  reviewValidationStatus = () => {
    const { data } = this.props;

    const idr = this.sectionValidationStatus('idr');
    const plan = data.selfService ? this.sectionValidationStatus('plan') : 'success';
    const household = data.selfService ? this.sectionValidationStatus('household') : 'success';
    const simple = !data.selfService ? this.sectionValidationStatus('simple') : 'success';
    //alert(plan + ' : ' + idr + ' : ' + plan + ' : ' + simple)
    if (
      (idr === 'success' || idr === null) &&
      plan === 'success' &&
      household === 'success' &&
      simple === 'success'
    ) {
      return 'success';
    } else {
      return null;
    }
  };

  getTabs = (data, errors) => {
    let tabs = [
      <TabIdr
        key="4"
        id="idr"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('idr')}
        name="Application"
        handleChange={this.handleChange}
      />,
    ];
    if (data.selfService) {
      tabs.unshift(
        <TabPlan
          key="2"
          id="plan"
          data={data}
          errors={errors}
          brand={this.sectionValidationStatus('plan')}
          name="Plan"
          handleChange={this.handleChange}
        />,
        <TabHousehold
          key="3"
          id="household"
          data={data}
          errors={errors}
          brand={this.sectionValidationStatus('household')}
          name="Household"
          handleChange={this.handleChange}
        />
      );
    } else {
      tabs.unshift(
        <TabSimple
          key="1"
          id="simple"
          data={data}
          errors={errors}
          brand={this.sectionValidationStatus('simple')}
          name="Household"
          handleChange={this.handleChange}
        />
      );
    }
    return tabs;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.signRequest.isLoading) {
      this.props.signApplication();
    }
  };

  render() {
    const { data, errors, request, signRequest } = this.props;
    let idrStatus = this.sectionValidationStatus('idr');
    if (idrStatus !== 'danger' && signRequest.hasFinished) {
      idrStatus = signRequest.hasFailed ? 'danger' : 'success';
    }

    return (
      <>
        <AsyncLoader request={request}>
          <>
            {safeAccess(errors, 'generalErrors')
              ? errors.generalErrors.map((e, i) => (
                  <Alert key={i} brand="danger">
                    {e.detail}
                  </Alert>
                ))
              : null}
            {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
            {data && (
              <Workflow
                tabs={this.getTabs(data, errors)}
                finalButton={
                  <Button brand="primary" onClick={(e) => this.submit(e)}>
                    {signRequest.isLoading ? (
                      <Loader variant="push" size={1.5} />
                    ) : (
                      'Sign application'
                    )}
                  </Button>
                }
                //handleTabChange={this.handleTabChange}
                next
                prev
                finalPageLocksFlow
                finalPageCheck={this.reviewValidationStatus}
                penUltimateButton={'Next'}
              />
            )}
          </>
        </AsyncLoader>
      </>
    );
  }
}

const tabs = {
  simple: [
    'question5Response',
    'question6Response',
    'question8Response',
    'question9BResponse',
    'question9CResponse',
  ],
  plan: ['repaymentPlan', 'repaymentBegan', 'inDeferOrForbearance'],
  household: [
    'question5Response',
    'question6Response',
    'question7Response',
    'question8Response',
    'question9BResponse',
    'question9CResponse',
    'question10Response',
    'question11Response',
    'question12Response',
    'question13Response',
    'question14Response',
    'question15Response',
    'question16Response',
    'question17Response',
    'agi',
  ],
  idr: [],
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.idr;
  return {
    id: getUserId(state),
    data: form,
    errors: errors,
    request: mergeRequestStatuses([requests.loadResource, requests.copyResourceToForm]),
    signRequest: state.fulfillment.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(idrLoadResource(id));
    await dispatch(idrCopyResourceToForm(id));
  },
  clearData: () => {
    dispatch(idrClearForm());
    dispatch(idrHandleCacheChange({}));
  },
  updateForm: (data, attributes) => {
    dispatch(idrHandleFormChange(data, attributes));
  },
  signApplication: () => dispatch(fulfillmentUpdateResource(IDR_DOC_ID)),
  validateTab: async (tab) => {
    await dispatch(idrValidateAttributes(tabs[tab]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(IdrWorkflow));
