import actions from './actions';
import { FulfillmentValidator } from './validators';
import { resourceOperationsGenerator } from '../../utils';

const fulfillmentValidator = new FulfillmentValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/fulfillment',
  resourceType: 'fulfillment',
};

const operations = resourceOperationsGenerator(
  'fulfillment',
  actions,
  null,
  fulfillmentValidator,
  endpointHandler,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const { fulfillmentDisableValidation } = actions;

const {
  fulfillmentCopyResourceToForm,
  fulfillmentHandleFormChange,
  fulfillmentInvalidateCache,
  fulfillmentLoadCollection,
  fulfillmentLoadResource,
  fulfillmentUpdateResource,
  fulfillmentValidateAttributes,
} = operations;

export {
  fulfillmentCopyResourceToForm,
  fulfillmentDisableValidation,
  fulfillmentHandleFormChange,
  fulfillmentInvalidateCache,
  fulfillmentLoadCollection,
  fulfillmentLoadResource,
  fulfillmentUpdateResource,
  fulfillmentValidateAttributes,
};

export default operations;
