import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardText, DashboardSection, Header3 } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';

class ForbearancePhaseOneFS extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection title="Forbearance">
          <div className="row">
            <div className="col-12">
              <Card>
                <CardBody>
                  <CardText>
                    <Header3>Forberance Request</Header3>
                    <ul>
                      <li>
                        Your loan payments will be put on hold if you have forbearance time
                        available.
                      </li>
                      <li>
                        Cancel any automatic payments currently in place for these loans unless
                        otherwise directed.
                      </li>
                      <li>
                        If you receive a bill or any information regarding your loans, please upload
                        to your Documents page.
                      </li>
                      <li>You can submit a Contact Us request if you have any questions.</li>
                    </ul>
                  </CardText>
                </CardBody>
              </Card>
            </div>
          </div>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ForbearancePhaseOneFS));
