import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { BrandColor, Card, CardBody, CardHeader, CardText, Header5 } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const ForgivenessQualify = ({ newPayment, savings }) => (
  <Card brand="primary">
    <CardHeader>Forgiveness</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Is it possible to lower my monthly payments?</Header5>
        <Spaced>
          You may qualify for a federal program that would lower your monthly payment to
          <strong>
            <BrandColor brand="primary">
              {' '}
              {newPayment < 0 ? '—' : formatAsMoney(newPayment)}{' '}
            </BrandColor>
          </strong>
          and potentially forgive
          <strong>
            <BrandColor brand="primary"> {savings < 0 ? '—' : formatAsMoney(savings)} </BrandColor>
          </strong>
          of your loans.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
);

ForgivenessQualify.propTypes = {
  newPayment: t.number.isRequired,
  savings: t.number.isRequired,
};

export default ForgivenessQualify;
