import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import { Card, CardBody, CardTitle, CardText } from 'fiducius-ui';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';
import { TuitionInstitutionTermHistoryRow } from '../../tuition-institution-term';

class TuitionInstitutionHistoryRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showInstitution: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.setState({
      showInstitution: !this.state.showInstitution,
    });
  };

  componentDidMount() {}

  getDegreeText = (degreeType) => {
    let retVal = '';
    if (degreeType) {
      if (degreeType === 'CERT') {
        retVal = 'Certification';
      } else if (degreeType === 'ASSOC') {
        retVal = 'Associates';
      } else if (degreeType === 'BA') {
        retVal = 'Bachelors';
      } else if (degreeType === 'MA') {
        retVal = 'Masters';
      } else if (degreeType === 'PHD') {
        retVal = 'Doctorate';
      }
    }
    return retVal;
  };

  render() {
    const { data, institutionId } = this.props;
    const institution = data[institutionId];
    const terms = [];
    return (
      <Card>
        <CardBody>
          <CardTitle onClick={this.handleClick}>
            <FontAwesomeIcon
              icon={this.state.showInstitution ? faAngleDown : faAngleRight}
              fixedWidth
            />
            {institution.institutionName}
          </CardTitle>
          {this.state.showInstitution && (
            <CardText>
              <div className="row">
                <div className="col-12">
                  <dl>
                    <dt>Degree Type</dt>
                    <dd>{this.getDegreeText(institution.degreeType)}</dd>
                  </dl>
                </div>
                <div className="col-12">
                  {Object.keys(institution.terms).length > 0 &&
                    Object.keys(institution.terms).forEach((term) => {
                      if (institution.terms[term].previous) {
                        terms.push(
                          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <TuitionInstitutionTermHistoryRow
                              institutionId={institutionId}
                              termId={term}
                            />
                          </div>
                        );
                      }
                    })}
                  {terms.length > 0 && <div className="row">{terms}</div>}
                </div>
              </div>
            </CardText>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (state) => {};

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionHistoryRow);
