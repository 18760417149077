import React from 'react';
import LegalPage from './legal-page';
import { Card, CardBody, CardFooter, CardText, Link } from 'fiducius-ui';

const SharedData = () => (
  <LegalPage title="Shared Data Consent">
    <Card>
      <CardBody>
        <CardText>
          <p>
            Consent allows Fiducius, LLC to share your personal information with non-affiliated
            third parties to market their services to you. The personal information we will share
            includes: your name, address, age, telephone number, email address; if applicable, your
            dependent's age and address; and information regarding your student loan amount,
            balance, loan payments, retirement savings, and other financial information. You have
            the right to opt-out of this sharing at any time by contacting us at{' '}
            <a href="mailto:info@getfiducius.com" target="_top">
              info@getfiducius.com
            </a>{' '}
            or <a href="tel:5136455400">(513) 645-5400</a>.
          </p>
        </CardText>
      </CardBody>
      <CardFooter>
        <Link to="/dashboard">Return to dashboard</Link>
      </CardFooter>
    </Card>
  </LegalPage>
);

export default SharedData;
