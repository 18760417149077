import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <strong>Step 1 – Wait for Employment Certification approval letter</strong>
    <ul>
      <li>This letter states your form was accepted.</li>
      <li>This typically arrives three to four weeks after the form is submitted.</li>
    </ul>
    <strong>Step 2 – Check on the status of the form</strong>
    <ul>
      <li>
        Create or Login to your account at{' '}
        <a href="https://www.mohela.com" target="_blank" rel="noopener noreferrer">
          MOHELA.
        </a>
      </li>
      <li>
        You should be able to complete this step <strong>six to seven weeks</strong> from your
        submission.
      </li>
    </ul>
    <strong>
      Step 3 – Loan(s) will transfer to MOHELA (check every two to three weeks once you receive the
      approval letter)
    </strong>
    <ul>
      <li>
        The transfer typically completes <strong>three to four months</strong> after the submission.
      </li>
      <li>The full federal loan balance, and repayment plan will be transferred.</li>
      <li>Confirm the total loan balance is $0 at your previous loan servicer(s).</li>
      <li>
        Go to your{' '}
        <a href="https://www.mohela.com" target="_blank" rel="noopener noreferrer">
          MOHELA account
        </a>{' '}
        to confirm your loans are now showing.
      </li>
    </ul>
    <strong>Step 4 – Confirm completion</strong>
    <ul>
      <li>
        Confirm the entire loan balance shown at your previous loan servicer(s) has been transferred
        to MOHELA.
      </li>
      <li>
        Please remember your employment certification form can take up to three months to process.
      </li>
    </ul>
    <strong>
      Do not confirm this step is finished until you have verified the transfer is complete.
    </strong>
    <br />
    <br />
    <strong>
      If you submitted your employment certification form more than three months ago and your loan
      balance has not transferred, please visit the Contact Us button on the left of your portal to
      reach out to us.
      {/*If you submitted your employment certification form more than three months ago and your loan
      balance has not transferred, contact us at <a href="tel:+15136455400">(513) 645-5400</a>{' '}
      option: 2.*/}
    </strong>
  </>
);

const EmpCertCheck = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/documents')}
    text={<TodoText />}
    title="Check on your Employer Certification"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmpCertCheck);
