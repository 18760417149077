import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { BrandColor, CardBody, CardText, CardTitle, Form, Header2, InputRadio } from 'fiducius-ui';

import { useScrollToTopOnMount } from '../../../utils';

import PlanCard from '../styles/plan-card';
import AdjustedHeader from '../styles/adjusted-header';
import PlanService from './plan-service';

const FlexTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const fullServicePlan = {
  //message:
  //  'Our Full Service Enterprise Platform is recommended for those that understand how their student loans will be affected, but feel more confident having the professionals handle everything on their behalf. This platform includes the following:',
  message: 'Your Plan Implementation Toolkit includes the following:',
  points: [
    'A dedicated team responsible for completing all necessary documentation and steps for the implementation process.',
    'Defined timeline driven by team to complete set up of initial repayment amount and remaining payment schedule.',
    'Ensured continuous compliance within the forgiveness program.',
    'All responsibility is shouldered by Fiducius, individuals are simply responsible for returning signed forms promptly and making student loan payments on time.',
  ],
  //title: 'Full Service Enterprise Platform',
  title: 'Plan Implementation Toolkit',
  type: 'FS',
};

const selfServicePlan = {
  //message:
  //  'Our Do It Yourself Toolkit is recommended for those that are comfortable and confident taking the wheel into their own hands. This toolkit includes the following:',
  message: 'Your Plan Implementation Toolkit includes the following:',
  points: [
    'Step by step instructions guiding you through the implementation process.',
    'All the necessary documentation for you to complete on your behalf.',
    'You are responsible for completing everything in a timely manner, but you are able to go at your own pace.',
    'A support team available should you have questions as you go through the process.',
  ],
  //title: 'Do It Yourself Toolkit',
  title: 'Plan Implementation Toolkit',
  type: 'SS',
};

const getServices = (services, handleChange) =>
  services.map((service) => (
    <PlanService key={service.planServiceId} service={service} handleChange={handleChange} />
  ));

const getPlan = (type, plan, disabled, cost) => {
  const planLabel = (
    <PlanCard>
      <CardBody>
        <CardTitle>
          <FlexTitle>
            <span>{plan.title}</span>
            <BrandColor brand="success">${cost}</BrandColor>
          </FlexTitle>
        </CardTitle>
        <CardText>
          <p>{plan.message}</p>
          <ul>
            {plan.points.map((p, i) => (
              <li key={plan.title + i.toString()}>{p}</li>
            ))}
          </ul>
        </CardText>
      </CardBody>
    </PlanCard>
  );

  return (
    <InputRadio
      name="planType"
      value={plan.type}
      label={planLabel}
      defaultChecked={type === plan.type}
      disabled={disabled}
    />
  );
};

const TabPlan = ({ data = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  return (
    <>
      <AdjustedHeader>
        {data.allowPlanTypeChoice ? 'Pick a plan that works best for you' : 'Your Plan'}
      </AdjustedHeader>
      <Form id={id} handleChange={handleChange}>
        {data.allowPlanTypeChoice ? (
          <>
            {getPlan(data.planType, selfServicePlan, true, data.selfServiceCost)}
            {/*getPlan(data.planType, fullServicePlan, false, data.fullServiceCost)*/}
          </>
        ) : data.planType === 'SS' ? (
          getPlan(data.planType, selfServicePlan, true, data.selfServiceCost)
        ) : (
          getPlan(data.planType, fullServicePlan, true, data.fullServiceCost)
        )}
      </Form>
      {data.services && (
        <>
          <Header2>Elect additional plan services</Header2>
          {getServices(data.services, handleChange)}
        </>
      )}
    </>
  );
};

TabPlan.propTypes = {
  data: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabPlan;
