import { FormValidator } from '../../utils';

export class TuitionApprovalValidator extends FormValidator {
  email(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidEmail();
  }

  approvalStatus0(validator) {
    validator.isRequired();
  }

  approvalStatus1(validator) {
    validator.isRequired();
  }

  approvalStatus2(validator) {
    validator.isRequired();
  }

  approvalStatus3(validator) {
    validator.isRequired();
  }

  approvalStatus4(validator) {
    validator.isRequired();
  }

  approvalStatus5(validator) {
    validator.isRequired();
  }

  approvalStatus6(validator) {
    validator.isRequired();
  }

  approvalStatus7(validator) {
    validator.isRequired();
  }

  approvalStatus8(validator) {
    validator.isRequired();
  }

  approvalStatus9(validator) {
    validator.isRequired();
  }

  approvalStatus10(validator) {
    validator.isRequired();
  }

  classNote0(validator, state) {
    if (state.approvalStatus0 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote1(validator, state) {
    if (state.approvalStatus1 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote2(validator, state) {
    if (state.approvalStatus2 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote3(validator, state) {
    if (state.approvalStatus3 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote4(validator, state) {
    if (state.approvalStatus4 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote5(validator, state) {
    if (state.approvalStatus5 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote6(validator, state) {
    if (state.approvalStatus6 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote7(validator, state) {
    if (state.approvalStatus7 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote8(validator, state) {
    if (state.approvalStatus8 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote9(validator, state) {
    if (state.approvalStatus9 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }

  classNote10(validator, state) {
    if (state.approvalStatus10 === 'N') {
      validator.isRequired();
    }
    validator.hasMaxLength(500);
  }
}
