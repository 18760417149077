import React from 'react';
import t from 'prop-types';
import { Header4 } from 'fiducius-ui';
import styled from 'styled-components';

import { safeAccess } from '../../utils';

import Meeting from './meeting';

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const StyledHeader4 = styled(Header4)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textTertiary : 'inherit')};
`;

const MeetingList = ({ cancelMeeting, meetings, request }) => (
  <Wrapper className="meeting-list">
    {safeAccess(meetings, 'length', 0) > 0 ? (
      meetings.map((m) => (
        <Meeting
          key={m.id}
          meeting={m}
          isLoading={request.key === m.id ? request.isLoading : false}
          cancelMeeting={() => cancelMeeting(m.id)}
        />
      ))
    ) : (
      <StyledHeader4>You don't have any upcoming meetings</StyledHeader4>
    )}
  </Wrapper>
);

MeetingList.propTypes = {
  cancelMeeting: t.func.isRequired,
  meetings: t.array,
  request: t.object,
};

export default MeetingList;
