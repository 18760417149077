import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/fresh-start',
  resourceType: 'fresh-start',
};

const operations = resourceOperationsGenerator(
  'freshStart',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const { freshStartLoadResource } = operations;

export { freshStartLoadResource };

export default operations;
