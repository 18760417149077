import React from 'react';
import t from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  AsyncLoader,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';
import styled from 'styled-components';
import LwoConfirmation from '../components/lwo-confirmation';
import LwoProgress from '../components/lwo-progress';

const StyledDiv = styled.div`
  margin-top: 1em;
`;

class LwoFinalConfirmation extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: null };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    if (this.state.redirectURI !== null) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <DashboardSection title="Federal Confirmation">
        <LwoProgress removeContainer={true} />

        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Confirmation of Additional Qualifying Payments</CardTitle>
                <CardText>
                  <AsyncLoader request={this.props.request}>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          Now that you've heard back from the Department of Education, we need you
                          to provide your updated NSLDS file. It will have been updated
                          automatically by the Department of Education to confirm the exact number
                          of additional qualifying payments you will receive. Click the "Submit My
                          Updated NSLDS" button to complete this step. Remember, we provide detailed
                          instructions to make this easy for you.
                        </p>
                      </div>
                    </div>
                  </AsyncLoader>

                  <LwoConfirmation stepId={609} />
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.payment.requests.loadResource,
    state.limitedWaiverOpportunity.requests.loadResource,
  ]),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoFinalConfirmation));
