import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Modal } from 'fiducius-ui';

import { authLoadPermissions } from '../../auth';
import { todosLoadCollection } from '../../todos';

import { legalHandleFormChange, legalUpdateResource } from '../redux/operations';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 3rem 0;
`;

const ExitButton = styled.div`
  color: ${(p) => p.theme.textSecondary};
  float: right;

  &:hover {
    color: ${(p) => p.theme.primary};
    cursor: pointer;
  }
`;

const OPT_IN_KEY = 'PDCON';
const OPT_OUT_KEY = 'PDOPT';
const SD_KEY = 'SHARED_DATA';

class SharedDataConsentPlaid extends React.Component {
  static propTypes = {
    consent: t.func.isRequired,
    isOpen: t.bool,
    optOut: t.func.isRequired,
    request: t.object,
    toggleModal: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { submitWarningModal: false };
  }

  componentDidUpdate(prevProps) {
    const { request } = this.props;
    if (
      request.key === SD_KEY &&
      prevProps.request.isLoading &&
      !request.isLoading &&
      !request.hasFailed
    ) {
      this.props.toggleModal();
    }
  }

  toggleSubmitWarningModal = () => {
    this.props.toggleModal();
    this.setState({ ...this.state, submitWarningModal: !this.state.submitWarningModal });
  };

  closeSubmitWarningModal = () => {
    this.setState({ ...this.state, submitWarningModal: false });
  };

  render() {
    const { isOpen, request, toggleModal, hideIcon } = this.props;
    return (
      <Modal isOpen={isOpen} onRequestClose={toggleModal}>
        <Card brand={request.hasFailed ? 'danger' : 'primary'}>
          {request.hasFailed && <CardHeader>Saving Data Consent Failure</CardHeader>}
          <CardBody>
            {!hideIcon && (
              <ExitButton>
                <FontAwesomeIcon icon={faTimes} fixedWidth onClick={toggleModal} size="lg" />
              </ExitButton>
            )}
            <CardTitle>Shared Data Consent</CardTitle>
            <CardText>
              <p>
                Fiducius works with non-affiliated third parties, including some health welfare
                financial service providers that may work with your employer (i.e. insurance broker,
                retirement provider). These parties have financial solutions that may further help
                you save on your student loan payment or otherwise help you with your financial
                wellness. By clicking on "I Accept" you are authorizing Fiducius to share your
                personal information you provide with these parties to help facilitate best possible
                outcomes for your individual situation. You may opt out at any time by contacting us
                at{' '}
                <a href="mailto:info@getfiducius.com" target="_top">
                  info@getfiducius.com
                </a>{' '}
                or <a href="tel:5136455400">(513) 645-5400</a>.
              </p>
            </CardText>
          </CardBody>
          <ButtonWrapper>
            {/* <Button brand="highContrast" size="lg" onClick={this.props.optOut}> */}
            <Button brand="highContrast" size="lg" onClick={this.toggleSubmitWarningModal}>
              I Decline
            </Button>
            <Button brand="primary" size="lg" onClick={this.props.consent}>
              I Accept
            </Button>
          </ButtonWrapper>
        </Card>

        <Modal isOpen={this.state.submitWarningModal} onRequestClose={this.closeSubmitWarningModal}>
          <Card brand={request.hasFailed ? 'danger' : 'primary'}>
            {request.hasFailed && <CardHeader>Saving Data Consent Failure</CardHeader>}
            <CardBody>
              {!hideIcon && (
                <ExitButton>
                  <FontAwesomeIcon
                    icon={faTimes}
                    fixedWidth
                    onClick={this.toggleSubmitWarningModal}
                    size="lg"
                  />
                </ExitButton>
              )}
              <CardTitle>Shared Data Consent</CardTitle>
              <CardText>
                <p>
                  Declining this consent will result in a loss of certain benefits, as they cannot
                  be managed without your consent. Are you sure you want to Decline and lose certain
                  eligibility?
                </p>
              </CardText>
            </CardBody>
            <ButtonWrapper>
              <Button brand="highContrast" size="lg" onClick={this.props.optOut}>
                I Decline
              </Button>
              <Button brand="primary" size="lg" onClick={this.props.consent}>
                I Accept to Maintain My Benefit Eligibility
              </Button>
            </ButtonWrapper>
          </Card>
        </Modal>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  request: state.legal.requests.updateResource,
});

const mapDispatchToProps = (dispatch) => ({
  consent: async (event) => {
    event.currentTarget.disabled = true;
    await dispatch(legalHandleFormChange({ id: OPT_IN_KEY, customerAuthorization: true }, []));
    await dispatch(legalUpdateResource(OPT_IN_KEY, SD_KEY));
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  optOut: async (event) => {
    event.currentTarget.disabled = true;
    await dispatch(legalHandleFormChange({ id: OPT_OUT_KEY, customerAuthorization: false }, []));
    await dispatch(legalUpdateResource(OPT_OUT_KEY, SD_KEY));
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedDataConsentPlaid);
