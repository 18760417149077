import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  Link,
  BrandColor,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
import { formatAsMoney } from '../../../utils';

import { forgivenessLoadResource } from '../../forgiveness/redux/operations';
import {
  getForgivenessData,
  getMonthlyPayment,
  getMonthlySavings,
  getNewPayment,
  getOldPayment,
  getStandardPayment,
  getTotalForgiveness,
  getTotalIdrRepayment,
  getTotalStandardRepayment,
} from '../../forgiveness/redux/selectors';

import ReducedIDRProgress from './reduced-idr-progress';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const ReducedIDRSummary = ({
  monthlyPayment,
  monthlySavings,
  permissions,
  request,
  standardPayment,
  id,
  load,
}) => {
  useEffect(() => {
    load(id);
  }, []);

  const [redirectURI, setRedirectURI] = useState('');

  if (redirectURI && redirectURI.length > 0) {
    return <Redirect to={redirectURI} />;
  } else {
    return (
      <Card brand="secondary">
        <CardBody>
          <div className="row">
            <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <CardTitle>Reduced IDR</CardTitle>
            </div>
          </div>
          <AsyncLoader request={request}>
            <CardText>
              <div className="row">
                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <dl>
                    <dt>Standard Monthly Payment</dt>
                    <dd>{standardPayment < 0 ? '—' : formatAsMoney(standardPayment)}</dd>
                    <dt>Monthly Savings</dt>
                    <dd>
                      {monthlySavings < 0 ? (
                        <>—</>
                      ) : (
                        <BrandColor brand="secondary">
                          <strong>{formatAsMoney(monthlySavings)}</strong>
                        </BrandColor>
                      )}
                    </dd>
                  </dl>
                </div>
                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <dl>
                    <dt>Income-Driven Monthly Payment</dt>
                    <dd>
                      {monthlyPayment < 0 ? (
                        <>—</>
                      ) : (
                        <BrandColor brand="secondary">
                          <strong>{formatAsMoney(monthlyPayment)}</strong>
                        </BrandColor>
                      )}
                    </dd>
                    <dt>Yearly Savings</dt>
                    <dd>
                      {monthlySavings < 0 ? (
                        <>—</>
                      ) : (
                        <BrandColor brand="secondary">
                          <strong>{formatAsMoney(monthlySavings * 12)}</strong>
                        </BrandColor>
                      )}
                    </dd>
                  </dl>
                </div>
              </div>
            </CardText>
            {!permissions.btsQuestionnaireCompleted && (
              <ReducedIDRProgress removeContainer={true} />
            )}
          </AsyncLoader>
        </CardBody>
        <FlexFooter>
          <Link to="/benefits/reducedidr/overview">My Reduced IDR</Link>
        </FlexFooter>
      </Card>
    );
  }
};

ReducedIDRSummary.propTypes = {
  data: t.object,
  id: t.string.isRequired,
  load: t.func.isRequired,
  monthlyPayment: t.number,
  monthlySavings: t.number,
  newPayment: t.number,
  oldPayment: t.number,
  permissions: t.object,
  request: t.object.isRequired,
  standardPayment: t.number,
  totalForgiveness: t.number,
  totalIdrRepayment: t.number,
  totalStandardRepayment: t.number,
};

const mapStateToProps = (state) => ({
  data: getForgivenessData(state),
  id: getUserId(state),
  monthlyPayment: getMonthlyPayment(state),
  monthlySavings: getMonthlySavings(state),
  newPayment: getNewPayment(state),
  oldPayment: getOldPayment(state),
  request: state.forgiveness.requests.loadResource,
  standardPayment: getStandardPayment(state),
  totalForgiveness: getTotalForgiveness(state),
  totalIdrRepayment: getTotalIdrRepayment(state),
  totalStandardRepayment: getTotalStandardRepayment(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(forgivenessLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ReducedIDRSummary));
