import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { Header4, Card, CardBody, CardTitle, AsyncLoader, Button } from 'fiducius-ui';

import { getUserId, getToken } from '../../auth';
import { safeAccess } from '../../utils';

import { withPermissions } from '../../routing';

import { customerContributionMilestoneLoadCollection } from '../redux/operations';
import RecentMilestonesTable from '../components/recent-milestones-table';
import AvailableMilestonesTable from '../components/available-milestones-table';
import MilestoneHelp from '../components/milestone-help';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;
const DivRight = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class MyMilestones extends React.Component {
  static propTypes = {
    id: t.string,
    token: t.string,
    data: t.object,
    load: t.func,
    permissions: t.object,
    request: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { showModal: false, showHelp: false };
    this.closeModal = this.closeModal.bind(this);

    this.openModal = this.openModal.bind(this);

    this.closeHelp = this.closeHelp.bind(this);

    this.openHelp = this.openHelp.bind(this);
  }

  componentDidMount() {
    this.props.load();
  }

  componentDidUpdate() {}

  closeModal() {
    this.setState({ ...this.state, showModal: false });
  }

  openModal() {
    this.setState({ ...this.state, showModal: true });
  }

  closeHelp() {
    this.setState({ ...this.state, showHelp: false });
  }

  openHelp() {
    this.setState({ ...this.state, showHelp: true });
  }

  render() {
    const { request, data } = this.props;
    if (Object.keys(data).length === 0) {
      return null;
    }
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row">
                <div className="col-12 col-sm-auto">Milestone(s)</div>
                <AsyncLoader request={request} empty={<></>} loading={<></>}>
                  <DivRight className="col-12 col-sm">
                    <StyledButton brand="primary" onClick={this.openHelp}>
                      <PaddedIcon icon={faQuestionCircle} fixedWidth />
                      What are Milestones
                    </StyledButton>
                    <StyledButton brand="primary" onClick={this.openModal}>
                      View All Milestones
                    </StyledButton>
                  </DivRight>
                </AsyncLoader>
              </div>
            </CardTitle>
            <AsyncLoader request={request} empty={<>You have not yet accrued any milestones.</>}>
              <Header4>Recently Accrued Milestones</Header4>
              <div className="row">
                <div className="col">
                  <RecentMilestonesTable data={data} />
                </div>
              </div>
            </AsyncLoader>
          </CardBody>
        </Card>
        <AvailableMilestonesTable
          data={data}
          isOpen={this.state.showModal}
          closeForm={this.closeModal}
        />
        <MilestoneHelp data={data} isOpen={this.state.showHelp} closeForm={this.closeHelp} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: getToken(state),
    id: getUserId(state),
    data: safeAccess(state, 'customerContributionMilestone.cache', {}),
    request: state.customerContributionMilestone.requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: () => {
    //dispatch(customerContributionMilestoneLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyMilestones));
