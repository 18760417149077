import React from 'react';
import { Redirect } from 'react-router-dom';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  DashboardSection,
  Link,
  Header4,
  Lede,
  Button,
} from 'fiducius-ui';

import { MyReferences } from '../../../references';
import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';

import { authLoadPermissions } from '../../../auth';

import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';

import { todosLoadCollection } from '../../../todos/redux/operations';

import { legalHandleFormChange, legalUpdateResource } from '../../../legal/redux/operations';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 3rem 0;
`;

const TextLeftList = styled.ul`
  text-align: left;
`;

const TextLeft = styled.p`
  text-align: left;
`;

const AUTH_KEY = 'AUTH';

class Welcome extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    todos: t.array,
    consent: t.func.isRequired,
    reload: t.func.isRequired,
    load: t.func.isRequired,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showProfile: false,
      showReferences: false,
      showServicers: false,
      showAuthorizedUse: false,
      authUseCompleted: false,
      referencesCompleted: false,
      redirect: '',
      CurrentForgivenessStep: '',
    };
  }

  async componentDidMount() {
    const { permissions, load } = this.props;
    await load();

    let forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);
    if (forgivenessStep !== undefined) {
      this.setState({
        ...this.state,
        authUseCompleted: this.checkAuthorizedUseCompleted(),
        referencesCompleted: this.checkReferencesCompleted(),
        CurrentForgivenessStep: forgivenessStep.stepPath,
      });
    }
  }

  handleProfileClick = () => {
    this.handleSectionClick('profile');
  };
  handleReferencesClick = () => {
    this.handleSectionClick('references');
  };
  handleServicersClick = () => {
    this.handleSectionClick('servicers');
  };
  handleAuthorizedUseClick = () => {
    this.handleSectionClick('authorizedUse');
  };

  handleSectionClick = (sectionName) => {
    if (sectionName === 'profile') {
      this.setState(
        {
          showProfile: true,
          showReferences: false,
          showServicers: false,
          showAuthorizedUse: false,
        },
        this.handleProfileScroll
      );
    } else if (sectionName === 'references') {
      this.setState(
        {
          showProfile: false,
          showReferences: true,
          showServicers: false,
          showAuthorizedUse: false,
        },
        this.handleReferenceScroll
      );
    } else if (sectionName === 'servicers') {
      this.setState(
        {
          showProfile: false,
          showReferences: false,
          showServicers: true,
          showAuthorizedUse: false,
        },
        this.handleServicerScroll
      );
    } else if (sectionName === 'authorizedUse') {
      this.setState(
        {
          showProfile: false,
          showReferences: false,
          showServicers: false,
          showAuthorizedUse: true,
        },
        this.handleAuthorizedUseScroll
      );
    }
  };

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  handleProfileScroll = () => {
    document.getElementById('profileSection').scrollIntoView({ behavior: 'smooth' });
  };
  handleReferenceScroll = () => {
    document.getElementById('referenceSection').scrollIntoView({ behavior: 'smooth' });
  };
  handleServicerScroll = () => {
    document.getElementById('servicerSection').scrollIntoView({ behavior: 'smooth' });
  };
  handleAuthorizedUseScroll = () => {
    document.getElementById('authorizedUseSection').scrollIntoView({ behavior: 'smooth' });
  };

  componentDidUpdate() {
    const { todos, permissions, reload } = this.props;
    const prevState = this.state;

    if (this.state.CurrentForgivenessStep !== '') {
      if (this.state.referencesCompleted !== this.checkReferencesCompleted()) {
        reload();
        this.setState({ ...this.state, referencesCompleted: !prevState.referencesCompleted });
      }

      if (this.state.authUseCompleted !== this.checkAuthorizedUseCompleted()) {
        reload();
        this.setState({ ...this.state, authUseCompleted: !prevState.authUseCompleted });
      }
      let forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);
      if (
        this.state.redirect === '' &&
        this.state.CurrentForgivenessStep !== forgivenessStep.stepPath
      ) {
        if (
          forgivenessStep !== undefined &&
          forgivenessStep !== null &&
          todos !== undefined &&
          todos !== null &&
          forgivenessStep.stepPath.toLowerCase() !== '/welcome' &&
          forgivenessStep.stepPath.toLowerCase() !== ''
        ) {
          this.setState({
            ...this.state,
            redirect: '/benefits/forgiveness' + forgivenessStep.stepPath,
            CurrentForgivenessStep: forgivenessStep.stepPath,
          });
        }
      }
    }
  }

  checkProfileCompleted = () => {
    let retVal = false;
    const { todos } = this.props;

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 10);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = true;
      }
    }
    return retVal;
  };

  checkReferencesCompleted = () => {
    let retVal = false;
    const { todos } = this.props;

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 12);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = true;
      }
    }
    return retVal;
  };

  checkServicersCompleted = () => {
    let retVal = false;
    const { todos } = this.props;

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 11);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = true;
      }
    }
    return retVal;
  };

  checkAuthorizedUseCompleted = () => {
    let retVal = false;
    const { todos } = this.props;

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 7);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = true;
      }
    }
    return retVal;
  };

  getForbearanceText = () => {
    return (
      <div>
        <p>
          Your team of experts are working very hard ensuring your payments are on hold and
          analyzing your account to provide next steps. They will reach out with further direction
          once the analysis is complete.
        </p>

        <ul>
          <li>Your loan payments will be put on hold if you have forbearance time available.</li>
          <li>
            Cancel any automatic payments currently in place for these loans unless otherwise
            directed.
          </li>
          <li>
            If you receive a bill or any information regarding your loans, please upload it to your
            Documents page.
          </li>
          <li>You can submit a Contact Us request if you have any questions.</li>
        </ul>
      </div>
    );
  };

  getResource = () => {
    return (
      <CardBody>
        <CardTitle>Welcome to Forgiveness!</CardTitle>
        <CardText>
          <p>Thank you for providing your information.</p>
          {this.getForbearanceText()}
        </CardText>
      </CardBody>
    );
  };

  render() {
    const { request, permissions } = this.props;

    if (this.state.redirect && this.state.redirect.length > 0) {
      return <Redirect to={this.state.redirect} />;
    } else {
      return (
        <>
          <div>
            <ForgivenessProgress />
            <DashboardSection>
              <Card brand="primary">
                <AsyncLoader request={request} empty={request.isEmpty ? this.getResource() : null}>
                  <CardBody>
                    <CardTitle>Welcome to Forgiveness!</CardTitle>
                    <CardText>
                      <p>
                        Congratulations on taking the first step to improve your student loan
                        situation! We have customized step by step instructions to help you achieve
                        financial wellness. Use the buttons below to complete some information to
                        continue with the process.
                      </p>
                      <div className="row col-12">
                        <div className="col-3">
                          <Lede>
                            <Button
                              brand={this.checkProfileCompleted() ? 'success' : 'info'}
                              onClick={this.handleProfileClick}
                            >
                              Profile
                            </Button>
                          </Lede>
                        </div>
                        <div className="col-3">
                          <Lede>
                            <Button
                              brand={this.checkReferencesCompleted() ? 'success' : 'info'}
                              onClick={this.handleReferencesClick}
                            >
                              References
                            </Button>
                          </Lede>
                        </div>
                        <div className="col-3">
                          <Lede>
                            <Button
                              brand={this.checkAuthorizedUseCompleted() ? 'success' : 'info'}
                              onClick={this.handleAuthorizedUseClick}
                            >
                              Authorized Use Consent
                            </Button>
                          </Lede>
                        </div>
                        <div className="col-3">
                          <Lede>
                            <Button
                              brand={this.checkServicersCompleted() ? 'success' : 'info'}
                              onClick={this.handleServicersClick}
                            >
                              Servicers
                            </Button>
                          </Lede>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                  {this.checkProfileCompleted() &&
                    this.checkReferencesCompleted() &&
                    this.checkServicersCompleted() &&
                    this.checkAuthorizedUseCompleted() &&
                    permissions.isFullService && (
                      <Card>
                        <CardBody>
                          <CardText>
                            <p>Thank you for providing your information.</p>
                            <p>{this.getForbearanceText()}</p>
                          </CardText>
                        </CardBody>
                      </Card>
                    )}
                </AsyncLoader>
                {this.checkProfileCompleted() &&
                  this.checkReferencesCompleted() &&
                  this.checkServicersCompleted() &&
                  this.checkAuthorizedUseCompleted() &&
                  !permissions.isFullService && <StandardConfirmation stepId={101} />}
              </Card>
            </DashboardSection>

            {this.state.showProfile && (
              <DashboardSection>
                <AsyncLoader request={request} empty={request.isEmpty ? null : null} loading={null}>
                  <Card id="profileSection">
                    <CardBody>
                      <CardTitle>Profile</CardTitle>
                      <CardText>
                        <p>
                          We need you to make sure that your profile information is up to date. You
                          can go to your profile using the link below. Your profile is always
                          available by clicking the icon in the top right and selecting "My Profile"
                          from the list.{' '}
                        </p>
                        <Link to="/profile">Go to My Profile</Link>
                      </CardText>
                    </CardBody>
                  </Card>
                </AsyncLoader>
              </DashboardSection>
            )}
            {this.state.showReferences && (
              <DashboardSection>
                <AsyncLoader request={request} empty={request.isEmpty ? null : null} loading={null}>
                  <Card id="referenceSection">
                    <CardBody>
                      <CardTitle>References</CardTitle>
                      <MyReferences />
                    </CardBody>
                  </Card>
                </AsyncLoader>
              </DashboardSection>
            )}
            {this.state.showServicers && (
              <DashboardSection>
                <AsyncLoader request={request} empty={request.isEmpty ? null : null} loading={null}>
                  <Card id="servicerSection">
                    <CardBody>
                      <CardTitle>Servicers</CardTitle>
                      <CardText>
                        <p>
                          We need you to make sure that your servicer information is up to date. You
                          can view your servicers using the link below. Your servicer information is
                          always available by clicking "My Accounts" in the left navigation pane.{' '}
                        </p>
                        <Link to="/accounts">Go to My Accounts</Link>
                      </CardText>
                    </CardBody>
                  </Card>
                </AsyncLoader>
              </DashboardSection>
            )}
            {this.state.showAuthorizedUse && (
              <DashboardSection>
                <AsyncLoader request={request} empty={request.isEmpty ? null : null} loading={null}>
                  <Card brand={request.hasFailed ? 'danger' : 'primary'} id="authorizedUseSection">
                    {request.hasFailed && <CardHeader>Saving Authorized Use Failure</CardHeader>}
                    <CardBody>
                      <CardTitle>Authorized Use Consent</CardTitle>
                      <CardText>
                        <Header4>What am I authorizing?</Header4>
                        <TextLeft>Your signature authorizes Fiducius to:</TextLeft>
                        <TextLeftList>
                          <li>
                            Properly align your student loans and establish the correct repayment
                            plan with The Department of Education for loan forgiveness.
                          </li>
                          <li>
                            Create and/or access your student loan service(s) account(s) to manage
                            the loan forgiveness program on your behalf.
                          </li>
                          <li>
                            Submit pre-filled consolidation application on your behalf at the loan
                            servicer of your choice.
                          </li>
                          <li>
                            Put your loan(s) in forbearance if that's part of your financial plan
                            built by your advisor.
                          </li>
                          <li>We will not utilize your FSA ID to access your account.</li>
                        </TextLeftList>
                      </CardText>
                    </CardBody>
                    <ButtonWrapper>
                      {this.checkAuthorizedUseCompleted() && (
                        <CardBody>
                          <CardText>
                            <p>
                              You've already consented to the items listed above. Please submit a
                              Contact Us request if you would like this changed.
                            </p>
                          </CardText>
                        </CardBody>
                      )}
                      {!this.checkAuthorizedUseCompleted() && (
                        <Button brand="primary" size="lg" onClick={this.props.consent}>
                          I agree
                        </Button>
                      )}
                    </ButtonWrapper>
                  </Card>
                </AsyncLoader>
              </DashboardSection>
            )}
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.loadCollection,
    state.legal.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  reload: async () => {
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },

  load: async () => {
    await dispatch(todosLoadCollection());
  },
  consent: async () => {
    await dispatch(legalHandleFormChange({ id: AUTH_KEY, customerAuthorization: true }, []));
    await dispatch(legalUpdateResource(AUTH_KEY, AUTH_KEY));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Welcome));
