const getCurrentPaymentType = (state) => state.payment.cache.paymentType;
const getPaymentCache = (state) => state.payment.cache;

export const getPaymentInfo = (state) => {
  const cache = getPaymentCache(state);
  const type = getCurrentPaymentType(state);
  if (cache && type) {
    return cache[type];
  } else {
    return undefined;
  }
};
