import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

const MessageDiv = styled.div`
  padding: 5px 5px 5px 5px;
  text-align: left;
`;

const MessageDivNoPadding = styled.div`
  padding: 0px;
  text-align: left;
`;

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 46.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border: 0;
`;

const EmbedContainerIFrame = styled.iframe`
    position: absolute; 
    top: 0; left: 0; bottom: 0; right 0;
    width: 100%; 
    height: 100%;
    border: 0; 
`;

const Messages = ({
  messageType,
  contentType,
  avatarLink,
  messageContent,
  showAvatar,
  showMessage,
  noPadding,
}) => {
  if (contentType === 'AVATAR') {
    return (
      <>
        {showAvatar && (
          <EmbedContainer>
            <EmbedContainerIFrame
              id={'avatarFrame_' + messageType}
              src={avatarLink}
              allow="autoplay; fullscreen; picture-in-picture"
              title="Fresh Start Program"
              allowfullscreen
            ></EmbedContainerIFrame>
          </EmbedContainer>
        )}
        {showMessage && !!!noPadding && (
          <MessageDiv dangerouslySetInnerHTML={{ __html: messageContent }}></MessageDiv>
        )}
        {showMessage && noPadding && (
          <MessageDivNoPadding
            dangerouslySetInnerHTML={{ __html: messageContent }}
          ></MessageDivNoPadding>
        )}
      </>
    );
  } else {
    return (
      <>
        {showMessage && !!!noPadding && (
          <MessageDiv dangerouslySetInnerHTML={{ __html: messageContent }}></MessageDiv>
        )}
        {showMessage && noPadding && (
          <MessageDivNoPadding
            dangerouslySetInnerHTML={{ __html: messageContent }}
          ></MessageDivNoPadding>
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => ({});

Messages.propTypes = {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
