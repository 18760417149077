import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { BrandColor, CardBody, CardHeader, Lede, Loader, Header1 } from 'fiducius-ui';
import { Link, Redirect, withRouter } from 'react-router-dom';

import { ShadowAddCard } from '../../routing';
import { persistRememberMe } from '../../persist';
import { debounce, safeAccess } from '../../utils';

import SignUpInfoForm from '../components/sign-up-info-form';
import SignUpRegisterForm from '../components/sign-up-register-form';
import FormSwitcher from '../styles/form-switcher';
import LoaderWrapper from '../styles/loader-wrapper';
import { isAuthenticated, authGetRedirectPath } from '../redux/selectors';
import { authHandleFormChange, authSignup } from '../redux/operations';

class Register extends React.Component {
  static propTypes = {
    auth: t.object,
    form: t.object,
    domain: t.string.isRequired,
    errors: t.object,
    hasFailed: t.bool.isRequired,
    isAuthenticated: t.bool.isRequired,
    isLoading: t.bool.isRequired,
    loadingPartnerData: t.bool.isRequired,
    location: t.object.isRequired,
    rememberMe: t.bool.isRequired,
    saveRequest: t.object,
    signUp: t.func.isRequired,
    updateFormState: t.func.isRequired,
    updateRememberMe: t.func.isRequired,
    getRedirectPath: t.string,
    showNFPQuestion: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      rememberMe: props.rememberMe,
      showFormError: false,
      stepTwo: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rememberMe !== prevState.rememberMe) {
      this.props.updateRememberMe(this.state.rememberMe);
    }
  }

  handleChange = debounce((formState, id, attribute) => {
    const { form, updateFormState } = this.props;
    const newData = { ...form, ...formState, partnerDomain: this.props.domain };
    updateFormState(newData, [attribute]);
  }, 200);

  step = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, stepTwo: true, showFormError: false });
  };

  back = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, stepTwo: false });
    const { auth } = this.props;
    let generalErrors = safeAccess(auth, 'errors.generalErrors', []);
    delete generalErrors[0];
  };

  createUser = (e) => {
    e.preventDefault();
    // Don't include meta info in signUp action
    if (!this.props.saveRequest.isLoading) {
      let formState = this.props.form;
      formState['altPhoneNumber'] = formState.phoneNumber;
      if (!formState.partnerMemberId) {
        formState['partnerMemberId'] = '0';
      }
      this.props.signUp(formState);
    }
  };

  toggleFormError = (e, newState) => {
    e.preventDefault();
    this.setState({ ...this.state, showFormError: newState });
  };

  getLoader() {
    return (
      <LoaderWrapper>
        <Loader variant="atom" size={3} />
      </LoaderWrapper>
    );
  }

  getRegistrationCode() {
    const { auth, domain, form } = this.props;
    if (domain === 'islsoffice') {
      return safeAccess(form, 'registrationCode') || 'loan4give';
    } else {
      return (
        safeAccess(form, 'registrationCode') ||
        safeAccess(auth, 'partnerLocations.registrationCode')
      );
    }
  }

  getForm() {
    const { auth, errors, domain, form, loadingPartnerData, showNFPQuestion } = this.props;
    if (loadingPartnerData) {
      return this.getLoader();
    }

    if (this.state.stepTwo) {
      let showLocations = false;
      let locations = { '0': '', '999': 'Other' };
      let cacheLocations = safeAccess(auth, 'partnerLocations.partnerMembers', []);
      if (cacheLocations.length > 0) {
        showLocations = true;
        locations = cacheLocations.reduce((acc, l) => ({ [l.id]: l.name, ...acc }), locations);
      }

      return (
        <SignUpInfoForm
          data={form}
          domain={domain}
          errors={errors}
          handleChange={this.handleChange}
          locations={locations}
          onSubmit={this.createUser}
          onBack={this.back}
          showLocations={showLocations}
          showNFPQuestion={showNFPQuestion}
        />
      );
    } else {
      return (
        <SignUpRegisterForm
          data={form}
          errors={errors}
          handleChange={this.handleChange}
          onSubmit={this.step}
          registrationCode={this.getRegistrationCode()}
          rememberMe={this.props.rememberMe}
          toggleFormError={this.toggleFormError}
        />
      );
    }
  }

  render() {
    const { hasFailed, isAuthenticated, isLoading, loadingPartnerData, errors } = this.props;

    // Redirect after creating user and receiving token
    if (isAuthenticated) {
      return (
        <Redirect
          to={
            this.props.getRedirectPath === 'referrer'
              ? this.state.referrer || '/dashboard'
              : this.props.getRedirectPath
          }
        />
      );
      //return <Redirect to="/screening" />;
    }

    return (
      <>
        <Header1>Create Account</Header1>
        <ShadowAddCard brand={(hasFailed && 'danger') || 'secondary'}>
          {hasFailed && <CardHeader>User Registration Failed</CardHeader>}
          <CardBody>
            {hasFailed && (
              <div>
                <Lede>
                  <BrandColor brand="danger">
                    {!!errors.email && <p>Error: {errors.email.detail}</p>}
                    {!!errors.password && <p>Error: {errors.password.detail}</p>}
                    {errors.generalErrors &&
                      errors.generalErrors.map((e) => <p>Error: {e.detail}</p>)}
                    Please fill in all required fields and fix any errors in the form. If your
                    information looks correct, you may already have an account with us, try{' '}
                    <Link to="/login">logging in here</Link> or contact us at{' '}
                    <a href="mailto:customer.service@getfiducius.com">
                      customer.service@getfiducius.com
                    </a>
                    {/*<a href="tel:+15136455400">(513) 645-5400</a>.*/}
                  </BrandColor>
                </Lede>
              </div>
            )}
            {this.state.showFormError && (
              <div>
                <Lede>
                  <BrandColor brand="danger">
                    Please fill in all required fields and fix any errors before continuing.
                  </BrandColor>
                </Lede>
              </div>
            )}
            {isLoading || loadingPartnerData ? this.getLoader() : this.getForm()}
          </CardBody>

          <FormSwitcher>
            Have an account?{' '}
            <Link to="/login" onClick={this.switchForm}>
              Sign in
            </Link>
          </FormSwitcher>
        </ShadowAddCard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.auth;
  return {
    auth: cache,
    errors: errors,
    form: form,
    hasFailed: requests.signup.hasFailed,
    isAuthenticated: isAuthenticated(state),
    isLoading:
      requests.signup.isLoading ||
      requests.loadPermissions.isLoading ||
      state.todos.requests.loadCollection.isLoading,
    loadingPartnerData: requests.partnerLocation.isLoading,
    rememberMe: safeAccess(cache, 'rememberMe', false),
    getRedirectPath: authGetRedirectPath(state, true),
    showNFPQuestion:
      safeAccess(state, 'auth.cache.permissions.showNfpQuestion', undefined) ||
      safeAccess(state, 'auth.cache.partnerLocations.showNfpQuestion', undefined) ||
      false,
    saveRequest: safeAccess(state, 'auth.requests.login', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  signUp: (username, password) => dispatch(authSignup(username, password)),
  updateFormState: (data, attributes) => dispatch(authHandleFormChange(data, attributes)),
  updateRememberMe: (rememberMe) => dispatch(persistRememberMe(rememberMe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
