import React from 'react';
import { connect } from 'react-redux';
import { CardText } from 'fiducius-ui';

const EmpcertApprovalSuccess = () => {
  return (
    <>
      <CardText>
        <p>The information you submitted has been processed successfully!</p>
      </CardText>
    </>
  );
};

EmpcertApprovalSuccess.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmpcertApprovalSuccess);
