import React, { useState } from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  CardText,
  Form,
  InputAddon,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputNumeric,
  InputSelect,
  InputWrapper,
  Loader,
  Modal,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { withPermissions, Fade } from '../../routing';
import { convertIsoToSlash, formatAsMoney, safeAccess, stringIsNullOrEmpty } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const TextLeft = styled.div`
  text-align: left;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 3rem 0;
`;

const ExitButton = styled.div`
  color: ${(p) => p.theme.textSecondary};
  float: right;

  &:hover {
    color: ${(p) => p.theme.primary};
    cursor: pointer;
  }
`;

const RepaymentHistoryForm = ({
  action,
  closeForm,
  permissions,
  data,
  errors = {},
  isLoading,
  isOpen,
  onSubmit,
  onChange,
  servicerNameList = [],
  monthlyInvoiceAmount,
}) => {
  let servicerNames = [];

  const [showWarningDialog, setShowWarningDialog] = useState(false);

  //Add the options from the NSLDS if they exist
  if (servicerNameList !== null) {
    Object.keys(servicerNameList).forEach((k) => {
      servicerNames.push(servicerNameList[k]);
    });
  }

  servicerNames.push('Other');

  data.servicerName = data.servicerName || servicerNames[0];

  let deleteDialog = action === 'Delete';

  const getForm = () => {
    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <InputWrapper error={!!errors.servicerName}>
          <InputLabel htmlFor="servicerName" required>
            Loan Servicer
          </InputLabel>
          <InputError>{safeAccess(errors, 'servicerName.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="servicerName"
              options={servicerNames}
              defaultValue={data.servicerName || ''}
              disabled={deleteDialog}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.requiredIdrPaymentAmount}>
          <InputLabel htmlFor="requiredIdrPaymentAmount" required={true}>
            Required IDR Payment
          </InputLabel>
          <InputError>{safeAccess(errors, 'requiredIdrPaymentAmount.detail')}</InputError>
          <InputGroup affordance="full">
            <InputAddon>$</InputAddon>
            <InputNumeric
              name="requiredIdrPaymentAmount"
              defaultValue={data.requiredIdrPaymentAmount}
              disabled={deleteDialog}
            />
          </InputGroup>
          <InputHelp>
            *The Required IDR Payment value should match the value from your loan servicer monthly
            invoice
          </InputHelp>
        </InputWrapper>
        <InputWrapper error={!!errors.paymentAmount}>
          <InputLabel htmlFor="paymentAmount" required={true}>
            Payment Amount
          </InputLabel>
          <InputError>{safeAccess(errors, 'paymentAmount.detail')}</InputError>
          <InputGroup affordance="full">
            <InputAddon>$</InputAddon>
            <InputNumeric
              name="paymentAmount"
              defaultValue={data.paymentAmount}
              disabled={deleteDialog}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.paymentDate}>
          <InputLabel htmlFor="paymentDate" required={true}>
            Paid On
          </InputLabel>
          <InputError>{safeAccess(errors, 'paymentDate.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={convertIsoToSlash(data.paymentDate)}
              mask="##/##/####"
              name="paymentDate"
              placeholder="MM/DD/YYYY"
              disabled={deleteDialog}
            />
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  };

  const enableSubmit = () => {
    return checkNoMissingData() && checkNoErrors();
  };

  const checkNoMissingData = () => {
    return (
      !stringIsNullOrEmpty(data.requiredIdrPaymentAmount) &&
      !stringIsNullOrEmpty(data.paymentAmount) &&
      !stringIsNullOrEmpty(data.paymentDate)
    );
  };

  const checkNoErrors = () => {
    return !errors.requiredIdrPaymentAmount && !errors.paymentAmount && !errors.paymentDate;
  };

  const submit = (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (
        action !== 'Delete' &&
        (data.requiredIdrPaymentAmount < monthlyInvoiceAmount - 5 ||
          data.requiredIdrPaymentAmount > monthlyInvoiceAmount + 5 ||
          data.paymentAmount > monthlyInvoiceAmount + 5)
      ) {
        setShowWarningDialog(true);
      } else {
        onSubmit(false, monthlyInvoiceAmount);
      }
    }
  };

  const closeWarningDialog = () => {
    setShowWarningDialog(false);
  };

  const submitWarningDialog = (e) => {
    e.preventDefault();
    if (!isLoading) {
      setShowWarningDialog(false);
      onSubmit(true, monthlyInvoiceAmount);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} fullWidth={true}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Payment History</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            <TextLeft>{getForm()}</TextLeft>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade id="deleteInfo" show={!isLoading && action === 'Delete'}>
                Are you sure you want to delete this record?
              </Fade>
              <Fade id="loader" show={enableSubmit()}>
                <Button
                  brand={action === 'Delete' ? 'danger' : 'success'}
                  onClick={(e) => submit(e)}
                >
                  {isLoading ? <Loader variant="push" size={1.5} /> : action}
                </Button>
              </Fade>
              <Fade id="missingInfo" show={!isLoading && !checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade id="errors" show={!isLoading && checkNoMissingData() && !checkNoErrors()}>
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
      <Modal isOpen={showWarningDialog} onRequestClose={closeWarningDialog}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardBody>
            <ExitButton>
              <FontAwesomeIcon icon={faTimes} fixedWidth onClick={closeWarningDialog} size="lg" />
            </ExitButton>
            <CardTitle>Payment Amount Mismatch</CardTitle>
            <CardText>
              {(data.requiredIdrPaymentAmount < monthlyInvoiceAmount - 5 ||
                data.requiredIdrPaymentAmount > monthlyInvoiceAmount + 5) && (
                <p>
                  The value input, {formatAsMoney(data.requiredIdrPaymentAmount)}, for Required IDR
                  Payment does not match our records. Can you confirm that the new value input is
                  accurate and matches your loan servicer monthly invoice amount?
                </p>
              )}
              {data.paymentAmount > monthlyInvoiceAmount + 5 && (
                <p>
                  The Payment Amount entered, {formatAsMoney(data.paymentAmount)} is higher than the
                  amount in our records. We recommend you do not pay more than the Required IDR
                  Payment.
                </p>
              )}
            </CardText>
          </CardBody>
          <ButtonWrapper>
            <Button brand="highContrast" size="lg" onClick={closeWarningDialog}>
              Cancel
            </Button>
            <Button brand="primary" size="lg" onClick={submitWarningDialog}>
              Confirm
            </Button>
          </ButtonWrapper>
        </Card>
      </Modal>
    </>
  );
};

RepaymentHistoryForm.propTypes = {
  action: t.string,
  closeForm: t.func.isRequired,
  data: t.object,
  errors: t.object,
  isLoading: t.bool,
  isOpen: t.bool,
  onChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
  servicers: t.object,
  permissions: t.object,
  token: t.any,
};

export default withPermissions(RepaymentHistoryForm);
