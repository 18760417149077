import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import {
  Alert,
  CardBody,
  Form,
  Header2,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputNumeric,
  InputRadio,
  InputWrapper,
  AsyncLoader,
  InputAddon,
} from 'fiducius-ui';

import { Fade } from '../../../routing';

import { convertIsoToSlash, safeAccess, flatten } from '../../../utils';

import LoanDataTable from './loan-data-table';

import {
  questionnaireBtsHandleFormChange,
  questionnaireBtsLoadResource,
  questionnaireBtsCopyResourceToForm,
} from '../redux/operations';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
class TabEducation extends React.Component {
  static propTypes = {
    institutionData: t.object,
    data: t.object,
    errors: t.object,
    educationErrors: t.object,
    request: t.object,
    getRequest: t.object,
    permissions: t.object,
    handleChange: t.func,
    loadEducation: t.func,
    clearInstitutionForm: t.func.isRequired,
    handleInstitutionForm: t.func.isRequired,
    createInstitution: t.func.isRequired,
    updateFormState: t.func,
    id: t.string.isRequired,
    form: t.object,
    isLoading: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = { showForm: false, allowedDegreeTypes: {}, school: '' };
  }

  componentDidMount() {
    const { id } = this.props;
    this.props.loadEducation(id);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (data.school !== undefined && data.school !== null && this.state.school === '') {
      this.setState({ ...this.state, school: data.school });
    }
  }

  render() {
    const { data, form, errors = {}, getRequest, handleChange, permissions } = this.props;

    let graduationIncomeText = '';

    if (permissions.isMarried) {
      graduationIncomeText =
        'What is your expected income, separate from your spouse after graduation?';
    } else {
      graduationIncomeText = 'What is your expected income after graduation?';
    }

    return (
      <>
        <Header2>Education Information</Header2>
        <AsyncLoader request={getRequest}>
          <Form handleChange={handleChange} defaults={form}>
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <LoanDataTable data={data} />
                  {/*<LoanDataTableDepreciated />*/}
                  <br />
                  <InputWrapper error={!!errors.continuedEducation}>
                    <InputLabel htmlFor="className">
                      Are you still enrolled at a college or university?
                    </InputLabel>
                    <InputError>{safeAccess(errors, 'continuedEducation.detail')}</InputError>
                    <InputRadio
                      name="continuedEducation"
                      value={true}
                      defaultChecked={form.continuedEducation === 'true'}
                      label="Yes"
                    />
                    <InputRadio
                      name="continuedEducation"
                      value={false}
                      defaultChecked={form.continuedEducation === 'false'}
                      label="No"
                    />
                  </InputWrapper>

                  <InputWrapper error={!!errors.borrowedAddFedLoans}>
                    <InputLabel htmlFor="className">
                      Have you borrowed any additional federal loans?
                    </InputLabel>
                    <InputError>{safeAccess(errors, 'borrowedAddFedLoans.detail')}</InputError>
                    <InputRadio
                      name="borrowedAddFedLoans"
                      value={true}
                      defaultChecked={form.borrowedAddFedLoans === 'true'}
                      label="Yes"
                    />
                    <InputRadio
                      name="borrowedAddFedLoans"
                      value={false}
                      defaultChecked={form.borrowedAddFedLoans === 'false'}
                      label="No"
                    />
                  </InputWrapper>

                  <Fade show={form.borrowedAddFedLoans === 'true'}>
                    <InputWrapper error={!!errors.loanAmtBorrow}>
                      <InputLabel htmlFor="loanAmtBorrow">Loans Amount Borrowed</InputLabel>
                      <InputError>{safeAccess(errors, 'loanAmtBorrow.detail')}</InputError>
                      <InputGroup affordance="medium">
                        <InputAddon>$</InputAddon>
                        <InputNumeric name="loanAmtBorrow" defaultValue={form.loanAmtBorrow} />
                        <InputAddon>.00</InputAddon>
                      </InputGroup>
                    </InputWrapper>
                  </Fade>

                  <Fade show={form.borrowedAddFedLoans === 'false'}>
                    <Alert brand="info">
                      The table above details the recommendations made for borrowing in your
                      financial wellness plan.
                    </Alert>
                  </Fade>

                  <InputWrapper error={!!errors.graduateDate}>
                    <InputLabel htmlFor="graduateDate">When do you expect to graduate?</InputLabel>
                    <InputError>{safeAccess(errors, 'graduateDate.detail')}</InputError>
                    <InputGroup affordance="medium">
                      <InputMask
                        defaultValue={convertIsoToSlash(form.graduateDate || data.graduateDate)}
                        mask="##/##/####"
                        name="graduateDate"
                        placeholder="MM/DD/YYYY"
                      />
                    </InputGroup>
                  </InputWrapper>

                  <InputWrapper error={!!errors.graduationIncome}>
                    <InputLabel htmlFor="graduationIncome">{graduationIncomeText}</InputLabel>
                    <InputError>{safeAccess(errors, 'graduationIncome.detail')}</InputError>
                    <InputGroup affordance="medium">
                      <InputAddon>$</InputAddon>
                      <InputNumeric name="graduationIncome" defaultValue={form.graduationIncome} />
                      <InputAddon>.00</InputAddon>
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
            </CardBody>
          </Form>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests, errors } = state.questionnaireBts;
  const formData = flatten(safeAccess(state.questionnaireBts, 'cache'));
  return {
    id: getUserId(state),
    data: formData,
    educationErrors: errors,
    getRequest: requests.loadResource,
    isLoading: requests.loadResource.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadEducation: async (id) => {
    await dispatch(questionnaireBtsLoadResource());
    await dispatch(questionnaireBtsCopyResourceToForm(id));
  },
  updateFormState: (data, attributes) =>
    dispatch(questionnaireBtsHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabEducation));
