import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { withPermissions } from '../../routing';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  BrandColor,
  Button,
  Card,
  CardBody,
  CardText,
  Header4,
  Header5,
  Loader,
} from 'fiducius-ui';
import { formatRelative, parseISO } from 'date-fns';
import englishDefault from 'date-fns/locale/en-US/index';

import { ActionIcon } from '../../routing';

import Scheduler from '../containers/scheduler';

// TODO: meeting type 9 is onsite, room-name becomes the name for the room and then phone for the
// other meeting types

const StyledMeetingCard = styled(Card)`
  box-shadow: ${(p) => p.theme.boxShadow};
  transition: box-shadow 200ms ease-in-out;
  &:hover {
    box-shadow: ${(p) => p.theme.boxShadowLarge};
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  & > button {
    margin: 0 0.15rem;
  }
`;

const customRelativeLocale = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "MMMM do, yyyy 'at' p",
};

const customLocale = {
  ...englishDefault,
  formatRelative: (token, date, baseDate, options) => customRelativeLocale[token],
};

const adjustedMeetingTime = (time) => {
  // TODO: fix API to return ISO 8601 with TZ.
  // TODO: THIS IS BROKEN FOR DAYLIGHT SAVINGS CHANGES
  // Everything in the database is EST (UTC-5)
  //return parseISO(time + '-0400');
  return parseISO(time);
};

const isMeetingPast = (meeting) => {
  let retVal = new Date() > adjustedMeetingTime(meeting.meetingStartTime);
  return retVal;
};

const Meeting = ({ cancelMeeting, isLoading, meeting, permissions }) => (
  <StyledMeetingCard brand="secondary">
    <CardBody>
      <CardText>
        {isLoading ? (
          <Loader variant="push" />
        ) : (
          <>
            <Header4>
              <BrandColor brand="textPrimary">{meeting.advisorName}</BrandColor>
            </Header4>
            <Header5>
              <BrandColor brand="textPrimary">
                {formatRelative(adjustedMeetingTime(meeting.meetingStartTime), new Date(), {
                  addSuffix: true,
                  locale: customLocale,
                })}
              </BrandColor>
            </Header5>
            {!isMeetingPast(meeting) && (
              <StyledFooter>
                {permissions.clientStatus !== 'PLAN' && (
                  <Scheduler buttonText="Reschedule" rescheduleID={{ meetingid: meeting.id }} />
                )}
                <Button brand="primary" onClick={cancelMeeting}>
                  Cancel
                </Button>
              </StyledFooter>
            )}
          </>
        )}
      </CardText>
    </CardBody>
  </StyledMeetingCard>
);

Meeting.propTypes = {
  cancelMeeting: t.func.isRequired,
  isLoading: t.bool,
  meeting: t.object,
  permissions: t.object,
};

export default withPermissions(Meeting);
