import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import StepDisplay from '../components/step-display';

class Taxes extends React.Component {
  static propTypes = {
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  getStep3RestrictionDate = () => {
    let retVal = undefined;
    const { permissions } = this.props;
    const taxesStep = permissions.forgivenessSteps.find((a) => a.id === 'Taxes');
    if (taxesStep.stepDateRestriction !== undefined) {
      let stepRestriction = new Date(taxesStep.stepDateRestriction);
      retVal =
        (stepRestriction.getMonth() + 1).toString() +
        '/' +
        stepRestriction.getDate() +
        '/' +
        stepRestriction.getFullYear();
    }
    return retVal;
  };

  render() {
    const { permissions } = this.props;
    const restrictionDate = this.getStep3RestrictionDate();
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection title="Taxes">
          <div className="row">
            <div className="col-12">
              {permissions.isFullService === false && (
                <Card overlayCard={restrictionDate !== undefined}>
                  <CardBody overlayCard hideOverlay={restrictionDate === undefined}>
                    {restrictionDate !== undefined && (
                      <>
                        <CardTitle overlayCard>Wait for tax filing</CardTitle>
                        <CardText overlayCard>
                          <p>
                            Based on the normal date tax forms are received for filing, the earliest
                            you will be able to complete this step is {restrictionDate}.
                          </p>
                        </CardText>
                      </>
                    )}
                  </CardBody>
                  <CardBody disableChildren={restrictionDate !== undefined}>
                    <StepDisplay title={'Step 1 – Confirm taxes filed correctly'}>
                      <ul>
                        <li>
                          Confirm that you have filed your most recent tax return as outlined in
                          your Financial Wellness Plan to maximize the benefit of this program.
                        </li>
                        <li>
                          If your marital status has changed since speaking with your advisor,
                          please visit the Contact Us button on the left of your portal to reach out
                          to us.
                        </li>
                        <li>
                          Haven't filed your taxes yet? Another benefit of being a client of ours is
                          you (and your spouse) can utilize our national partner, TurboTax and
                          receive a discounted rate to file. Visit{' '}
                          <a href="http://www.jdoqocy.com/click-8973232-13149824">TurboTax</a> to
                          start filing.
                        </li>
                      </ul>
                      <strong>
                        Do not confirm this step is finished until you have filed your taxes in
                        accordance with your Financial Wellness Plan{' '}
                      </strong>
                    </StepDisplay>
                    <StandardConfirmation stepId={110} />
                  </CardBody>
                </Card>
              )}
              {permissions.isFullService && (
                <Card>
                  <CardBody>
                    <CardTitle>File Your Taxes</CardTitle>
                    <CardText>
                      <ul>
                        <li>
                          Confirm that you have filed your most recent tax return as outlined in
                          your Financial Wellness Plan to maximize the benefit of this program.
                        </li>
                        <li>
                          If your marital status has changed since speaking with your advisor,
                          please visit the Contact Us button on the left of your portal to reach out
                          to us.
                        </li>
                        <li>
                          Haven't filed your taxes yet? As a Fiducius client, you (and your spouse)
                          can take advantage of our national partnership with{' '}
                          <a href="http://www.jdoqocy.com/click-8973232-13149824">TurboTax</a>.
                        </li>
                      </ul>
                    </CardText>
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Taxes));
