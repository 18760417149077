import actions from './actions';
import { MeetingsValidator } from './validators';

import { getToken } from '../../auth';
import {
  checkForEmpty,
  getApi,
  keyById,
  normalize,
  postApi,
  resourceOperationsGenerator,
} from '../../utils';

const meetingsValidator = new MeetingsValidator();
const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/schedule-meeting',
  resourceType: 'schedule-meeting',
};

const operations = resourceOperationsGenerator(
  'meetings',
  actions,
  null,
  meetingsValidator,
  endpointHandler,
  null,
  endpointHandler,
  null,
  endpointHandler
);

const {
  meetingsCreateResource,
  meetingsDeleteResource,
  meetingsHandleCacheChange,
  meetingsHandleFormChange,
} = operations;

const meetingsClearSlots = () => async (dispatch, getState) => {
  await dispatch(
    operations.meetingsHandleCacheChange({ ...getState().meetings.cache, available: {} })
  );
};

const meetingsLoadSlots = () => async (dispatch, getState) => {
  try {
    dispatch(actions.meetingsLoadCollectionRequest());
    let available = await getApi(getToken(getState()), '/schedule-meeting'); // wat

    dispatch(actions.meetingsLoadCollectionResponse());
    available = available || [];
    available = keyById(available);

    let cache = getState().meetings.cache;
    await dispatch(operations.meetingsHandleCacheChange({ ...cache, available }));

    dispatch(actions.meetingsLoadCollectionSuccess(checkForEmpty(getState().meetings)));
  } catch (error) {
    dispatch(operations.meetingsHandleError(error));
    dispatch(actions.meetingsLoadCollectionFailure(error));
  }
};

const meetingsLoadMeetings = () => async (dispatch, getState) => {
  try {
    dispatch(actions.meetingsLoadCollectionRequest());
    let current = await getApi(getToken(getState()), '/schedule-meeting/0/GetAll');

    dispatch(actions.meetingsLoadCollectionResponse());
    current = current || [];
    current = keyById(current);

    let cache = getState().meetings.cache;
    await dispatch(operations.meetingsHandleCacheChange({ ...cache, current }));

    dispatch(actions.meetingsLoadCollectionSuccess(checkForEmpty(getState().meetings)));
  } catch (error) {
    dispatch(operations.meetingsHandleError(error));
    dispatch(actions.meetingsLoadCollectionFailure(error));
  }
};

const meetingsLoadCollection = () => async (dispatch, getState) => {
  try {
    dispatch(actions.meetingsLoadCollectionRequest());
    let [available, current] = await Promise.all([
      getApi(getToken(getState()), '/schedule-meeting'),
      getApi(getToken(getState()), '/schedule-meeting/0/GetAll'), // wat
    ]);

    dispatch(actions.meetingsLoadCollectionResponse());
    available = available || [];
    current = current || [];
    [available, current] = [keyById(available), keyById(current)];

    await dispatch(operations.meetingsHandleCacheChange({ available, current }));

    dispatch(actions.meetingsLoadCollectionSuccess(checkForEmpty(getState().meetings)));
  } catch (error) {
    dispatch(operations.meetingsHandleError(error));
    dispatch(actions.meetingsLoadCollectionFailure(error));
  }
};

const meetingsScheduleMeeting = (meeting, rescheduleID) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(actions.meetingsCreateResourceRequest());

    if (Object.keys(rescheduleID).length > 0) {
      await dispatch(meetingsDeleteResource(rescheduleID.meetingid, rescheduleID.meetingid));
      let cache = state.meetings.cache;
      delete cache.current[rescheduleID.meetingid];
      dispatch(meetingsHandleCacheChange(cache));
    }

    const postData = normalize('schedule-meeting', meeting);
    let data = await postApi(getToken(state), '/schedule-meeting', postData);

    dispatch(actions.meetingsCreateResourceResponse());

    let cache = state.meetings.cache;
    cache.current = { ...cache.current, ...keyById(data) };
    await dispatch(operations.meetingsHandleCacheChange(cache));

    dispatch(actions.meetingsCreateResourceSuccess(false));
    if (!(state.profile.form === undefined)) {
      state.profile.form.cellPhone = meeting.phoneNumber;
    }
  } catch (error) {
    dispatch(operations.meetingsHandleError(error));
    dispatch(actions.meetingsCreateResourceFailure(error));
  }
};

const meetingsCancelMeeting = (id) => async (dispatch, getState) => {
  await dispatch(meetingsDeleteResource(id, id));
  let cache = getState().meetings.cache;
  delete cache.current[id];
  dispatch(meetingsHandleCacheChange(cache));
  await dispatch(meetingsLoadCollection());
};

export {
  meetingsCancelMeeting,
  meetingsClearSlots,
  meetingsCreateResource,
  meetingsDeleteResource,
  meetingsHandleFormChange,
  meetingsLoadCollection,
  meetingsLoadMeetings,
  meetingsLoadSlots,
  meetingsScheduleMeeting,
};

export default operations;
