import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import t from 'prop-types';
import { Header4, Loader, Dropdown, DropdownItem } from 'fiducius-ui';

import { safeAccess } from '../../utils';

import Todo from './todo';

const LoaderWrapper = styled.div`
  height: calc(${(p) => p.height}px - 2em);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHeader4 = styled(Header4)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textTertiary : 'inherit')};
`;
class TodoList extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    loadAllRequest: t.object,
    todos: t.array,
    toggleNavigation: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      todoSet: '0',
    };
  }

  componentDidMount() {}

  getDropdownItems() {
    const { todos } = this.props;
    let dropdownItems = [];
    let filteredTodos = this.checkForECFOverlap(todos);

    dropdownItems.push(<DropdownItem value="0">All Items</DropdownItem>);
    if (filteredTodos.filter((a) => a.id >= 1 && a.id <= 15).length > 0) {
      dropdownItems.push(<DropdownItem value="1">General</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 100 && a.id <= 199).length > 0) {
      dropdownItems.push(<DropdownItem value="2">Forgiveness</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 200 && a.id <= 299).length > 0) {
      dropdownItems.push(<DropdownItem value="3">Renewal</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 300 && a.id <= 399).length > 0) {
      dropdownItems.push(<DropdownItem value="4">SimpliFi</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 400 && a.id <= 499).length > 0) {
      dropdownItems.push(<DropdownItem value="5">Tuition Reimbursement</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 500 && a.id <= 599).length > 0) {
      dropdownItems.push(<DropdownItem value="6">Contribution</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 600 && a.id <= 699).length > 0) {
      dropdownItems.push(<DropdownItem value="7">Assure&#8480;</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 800 && a.id <= 899).length > 0) {
      dropdownItems.push(<DropdownItem value="8">Emplify</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 900 && a.id <= 999).length > 0) {
      dropdownItems.push(<DropdownItem value="9">Fresh Start</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 1000 && a.id <= 1099).length > 0) {
      dropdownItems.push(<DropdownItem value="10">Educational Reengagement</DropdownItem>);
    }
    if (filteredTodos.filter((a) => a.id >= 1100 && a.id <= 1630).length > 0) {
      dropdownItems.push(<DropdownItem value="11">Reduced IDR</DropdownItem>);
    }

    return dropdownItems;
  }

  checkForECFOverlap = (todos) => {
    let ecfActionIdArray = [106, 107, 252, 253];
    let lwoEcfActionIdArray = [606, 607];
    if (
      todos.filter((a) => ecfActionIdArray.includes(a.id)).length > 0 &&
      todos.filter((a) => lwoEcfActionIdArray.includes(a.id)).length > 0
    ) {
      let index;
      ecfActionIdArray.forEach((a) => {
        let toDoIds = todos.map((b) => b.id);
        index = toDoIds.indexOf(a);
        if (index >= 0) {
          todos.splice(index, 1);
        }
      });
    }

    return todos;
  };

  reorderToDos = (todos, beforeIdArray, afterIdArray) => {
    let todoArray = Object.values(todos);
    if (todoArray.length > 0) {
      let tempItemsArray = [];
      let beforeMatchesArray = todoArray.filter((a) => beforeIdArray.includes(a.id));
      let afterMatchesArray = todoArray.filter((a) => afterIdArray.includes(a.id));

      if (afterMatchesArray.length > 0 && beforeMatchesArray.length > 0) {
        let index;
        afterMatchesArray.forEach((a) => {
          // Gets an array of id numbers in the todo array
          let toDoIds = todoArray.map((b) => b.id);

          // Finds the index of the first item in the 'after' array in the full todo list
          index = toDoIds.indexOf(a.id);

          if (index >= 0) {
            // If it exists, remove it from the array.  This would remove the items from the array that are in empCertIdArray
            tempItemsArray.splice(tempItemsArray.length, 0, ...todoArray.splice(index, 1));
          }
        });

        //Find the index of the LAST item in beforeMatchesArray in the full todoArray, and splice in tempEmpCertArray
        index = todoArray.indexOf(beforeMatchesArray[beforeMatchesArray.length - 1]);
        todoArray.splice(index + 1, 0, ...tempItemsArray);
      }
    }

    return todoArray;
  };

  getDropdownHeader() {
    let header = <></>;
    switch (this.state.todoSet) {
      case '0':
        header = <>All Items</>;
        break;
      case '1':
        header = <>General</>;
        break;
      case '2':
        header = <>Forgiveness</>;
        break;
      case '3':
        header = <>Renewal</>;
        break;
      case '4':
        header = <>SimpliFi</>;
        break;
      case '5':
        header = <>Tuition Reimbursement</>;
        break;
      case '6':
        header = <>Contribution</>;
        break;
      case '7':
        header = <>Assure&#8480;</>;
        break;
      case '8':
        header = <>Emplify</>;
        break;
      case '9':
        header = <>Fresh Start</>;
        break;
      case '10':
        header = <>Educational Reengagement</>;
        break;
      case '11':
        header = <>Reduced IDR</>;
      default:
        break;
    }
    return header;
  }

  updateDropdownSelection = (value) => {
    this.setState({ todoSet: value });
  };

  getFilteredTodoSteps = () => {
    const { todos } = this.props;

    // Put Repayment Todos Before...
    let beforeIdArray = [111, 112, 113, 114, 115, 255, 256, 257, 260, 261];

    // The EmpCert Todos
    let afterIdArray = [106, 107, 108, 252, 253, 254];

    let reorderedTodos = this.reorderToDos(todos, beforeIdArray, afterIdArray);

    let filteredTodos = this.checkForECFOverlap(reorderedTodos);

    switch (this.state.todoSet) {
      case '0':
        return filteredTodos;
      case '1':
        return filteredTodos.filter((a) => a.id >= 1 && a.id <= 15);
      case '2':
        return filteredTodos.filter((a) => a.id >= 100 && a.id <= 199);
      case '3':
        return filteredTodos.filter((a) => a.id >= 200 && a.id <= 299);
      case '4':
        return filteredTodos.filter((a) => a.id >= 300 && a.id <= 399);
      case '5':
        return filteredTodos.filter((a) => a.id >= 400 && a.id <= 499);
      case '6':
        return filteredTodos.filter((a) => a.id >= 500 && a.id <= 599);
      case '7':
        return filteredTodos.filter((a) => a.id >= 600 && a.id <= 699);
      case '8':
        return filteredTodos.filter((a) => a.id >= 800 && a.id <= 899);
      case '9':
        return filteredTodos.filter((a) => a.id >= 900 && a.id <= 999);
      case '10':
        return filteredTodos.filter((a) => a.id >= 1000 && a.id <= 1099);
      case '11':
        return filteredTodos.filter((a) => a.id >= 1100 && a.id <= 1630);
      default:
        return filteredTodos;
    }
  };

  render() {
    const { todos, actionRequest, loadAllRequest } = this.props;
    return (
      <div className="todo-list">
        {loadAllRequest.isLoading && (
          <>
            <StyledHeader4>Checking to see what items need completed</StyledHeader4>
            <LoaderWrapper>
              <Loader variant="atom" size={2} />
            </LoaderWrapper>
          </>
        )}
        {!loadAllRequest.isLoading && (
          <>
            {safeAccess(todos, 'length') > 0 ? (
              <>
                <div className="row">
                  <div className="col-12">
                    <Dropdown
                      key="exampleTheme"
                      header={this.getDropdownHeader()}
                      selected={this.state.todoSet}
                      onSelect={this.updateDropdownSelection} //make a new function that gets change theme func, and async
                      align="left"
                    >
                      {this.getDropdownItems()}
                    </Dropdown>
                  </div>
                </div>
                {this.getFilteredTodoSteps().map((t) => (
                  <Todo
                    key={t.id}
                    todo={t}
                    request={t.id === actionRequest.key ? actionRequest : {}}
                    {...this.props}
                  />
                ))}
              </>
            ) : (
              <StyledHeader4>You don't have anything to do.</StyledHeader4>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  todos: Object.values(state.todos.cache),
  actionRequest: state.todos.requests.updateResource,
  loadAllRequest: state.todos.requests.loadCollection,
});

export default connect(mapStateToProps)(TodoList);
