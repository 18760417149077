import React from 'react';
import t from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { dark, greenlight, GlobalTheme, light, newlight } from 'fiducius-ui';

import { PersistGate, RouterEntry, PermissionProvider } from '../../routing';

import AppStore from '../redux';

class App extends React.Component {
  static propTypes = {
    children: t.node,
    getThemePreference: t.func.isRequired,
    theme: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      darkTheme: false,
      lightTheme: false,
      greenLight: false,
      newLight: true,
      fluid: false,
      navigationOpen: false,
      drawers: {
        meetings: false,
        settings: false,
        todos: false,
      },
    };
    this.toggleNavigation = this.toggleNavigation.bind(this);
  }

  changeTheme = (e) => {
    if (e === 'dark') {
      this.setState({ darkTheme: true, lightTheme: false, greenLight: false, newLight: false });
    } else if (e === 'light') {
      this.setState({ darkTheme: false, lightTheme: false, greenLight: false, newLight: true });
    } else if (e === 'newlight') {
      this.setState({ darkTheme: false, lightTheme: false, greenLight: false, newLight: true });
    } else if (e === 'greenlight') {
      this.setState({ darkTheme: false, lightTheme: false, greenLight: true, newLight: false });
    } else {
      this.setState({ darkTheme: false, lightTheme: false, greenLight: false, newLight: true });
    }
  };

  changeThemeLight = (e) => {
    this.setState({ lightTheme: e.target.checked });
  };

  changeFluid = (e) => {
    this.setState({ ...this.state, fluid: e.target.checked });
  };

  toggleDrawer = (drawer) => {
    const drawers = Object.keys(this.state.drawers).reduce((acc, d) => {
      const open = d === drawer ? !this.state.drawers[d] : false;
      return {
        ...acc,
        [d]: open,
      };
    }, {});

    this.setState({ ...this.state, drawers, navigationOpen: false });
  };

  toggleNavigation() {
    const closeDrawers = Object.keys(this.state.drawers).reduce((acc, d) => {
      const open = false;
      return {
        ...acc,
        [d]: open,
      };
    }, {});

    this.setState({
      ...this.state,
      drawers: closeDrawers,
      navigationOpen: !this.state.navigationOpen,
    });
  }

  getTheme() {
    const { darkTheme, lightTheme, greenLight, newLight } = this.state;
    let theme = light;
    if (darkTheme) {
      theme = dark;
    } else if (lightTheme) {
      theme = light;
    } else if (greenLight) {
      theme = greenlight;
    } else if (newLight) {
      theme = newlight;
    }
    return theme;
  }

  render() {
    return (
      <Provider store={AppStore}>
        <ThemeProvider theme={this.getTheme()}>
          <PersistGate>
            <PermissionProvider>
              <BrowserRouter basename="/">
                <>
                  <GlobalTheme />
                  <RouterEntry
                    changeFluid={this.changeFluid}
                    changeTheme={this.changeTheme}
                    darkTheme={this.state.darkTheme}
                    drawers={this.state.drawers}
                    fluid={this.state.fluid}
                    toggleDrawer={this.toggleDrawer}
                    theme={this.getTheme().themeName}
                    navigationOpen={this.state.navigationOpen}
                    toggleNavigation={this.toggleNavigation}
                  />
                </>
              </BrowserRouter>
            </PermissionProvider>
          </PersistGate>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
