import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { AsyncLoader } from 'fiducius-ui';

import { debounce, mergeRequestStatuses, safeAccess } from '../../utils';
import { todosLoadCollection } from '../../todos';
import { withPermissions } from '../../routing';
import { getUserId } from '../../auth';

import EmploymentApproverForm from '../components/employment-approver-form';

import {
  employmentHistoryClearForm,
  employmentHistoryCopyResourceToForm,
  employmentDeleteResource,
  employmentUpdateResource,
  employmentHistoryHandleFormChange,
  employmentCreateResource,
  employmentHistoryLoadCollection,
  empcertSaveApproverInfo,
} from '../redux/operations';

const EmployerApprovers = ({
  employers,
  request,
  form,
  handleForm,
  submit,
  stepState,
  isReducedIDREmpCert,
}) => {
  const [errorState, setErrorState] = useState({});

  useEffect(() => {
    checkNoMissingData();
  }, []);

  const emailPattern = /^([a-zA-Z\d!#$%&'*+\-/=?^_`{}|]+[_+.-])*[a-zA-Z\d!#$%&'*+\-/=?^_`{}|']+@(\w+[.-])*\w{1,63}\.[a-zA-Z]+$/g;
  const multipleEmailWithoutSemicolon = /^.+[^;][\s]\S+$/g;

  const isValidEmail = (value) => {
    if (value.includes(';') || value.match(multipleEmailWithoutSemicolon)) {
      let failure = false;
      let array = value.split(';');
      array.forEach((value) => {
        if ((value !== null && !value.trim().match(emailPattern)) || array.length === 1) {
          failure = true;
        }
      });

      if (failure) {
        return false;
      } else {
        return true;
      }
    } else {
      if (value !== null && !value.match(emailPattern)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleChange = debounce((formState, attribute) => {
    const newData = { ...form, ...formState };

    validateForm(newData, attribute);
    handleForm(newData, Object.keys(newData));
  }, 500);

  const validateForm = (data) => {
    var formErrors = {};

    if (employers) {
      Object.keys(employers).forEach((key) => {
        if (employers[key].empcertNeeded) {
          // Verify that input is in valid email address format OR corresponding checkbox is checked
          var employerId = key;

          var isEmployerUnknown = data[`empcertApproverUnknown_${employerId}`];
          var empcertApproverEmail = data[`empcertApproverEmail_${employerId}`];

          if (!isEmployerUnknown && empcertApproverEmail !== null) {
            if (empcertApproverEmail !== undefined && !isValidEmail(empcertApproverEmail)) {
              let newError = { ...formErrors };
              newError[`empcertApproverEmail_${employerId}`] = true;
              formErrors = newError;
            } else {
              let newError = { ...formErrors };
              newError[`empcertApproverEmail_${employerId}`] = false;
              formErrors = newError;
            }
          } else {
            let newError = { ...formErrors };
            newError[`empcertApproverEmail_${employerId}`] = false;
            formErrors = newError;
          }
        }
      });
    }

    setErrorState(formErrors);
  };

  const getEmpcertApproverEmail = (key) => {
    let empcertApproverEmail = '';

    if (employers) {
      if (employers[key].eligfileEmpcertApproverEmail !== null) {
        empcertApproverEmail = employers[key].eligfileEmpcertApproverEmail;
      } else if (employers[key].emplifyAuthorizedOfficialEmail !== null) {
        empcertApproverEmail = employers[key].emplifyAuthorizedOfficialEmail;
      } else {
        empcertApproverEmail =
          employers[key].empcertApproverEmail === null ? '' : employers[key].empcertApproverEmail;
      }

      return empcertApproverEmail;
    }

    return empcertApproverEmail;
  };

  const checkNoMissingData = () => {
    var formErrors = {};

    if (employers) {
      Object.keys(employers).forEach((key) => {
        if (employers[key].empcertNeeded) {
          //Load dynamic data into form
          let newFormData = { ...form };
          var approverEmail = getEmpcertApproverEmail(key);
          var approverUnknown =
            employers[key].empcertApproverUnknown === null
              ? ''
              : employers[key].empcertApproverUnknown;
          newFormData[`empcertApproverEmail_${key}`] = approverEmail;
          newFormData[`empcertApproverUnknown_${key}`] = approverUnknown;

          form = newFormData;
          handleForm(form, Object.keys(form));

          if (approverEmail.length === 0 && employers[key].empcertApproverUnknown !== true) {
            // create new error
            var newError = { ...formErrors };
            newError[`empcertApproverEmail_${key}`] = true;
            formErrors = newError;
          }
        }
      });
    }

    setErrorState(formErrors);
  };

  return (
    <>
      <AsyncLoader request={request} emptyMessage={'You have no Employers on file.'}>
        {employers && (
          <>
            <EmploymentApproverForm
              employers={employers}
              onChange={handleChange}
              onSubmit={submit}
              errors={errorState}
              stepState={stepState}
              isReducedIDREmpCert={isReducedIDREmpCert}
            />
          </>
        )}
      </AsyncLoader>
    </>
  );
};

EmployerApprovers.propTypes = {
  id: t.string.isRequired,
  employers: t.object,
  actionRequest: t.object,
  requests: t.object,
  clearForm: t.func.isRequired,
  createEmployer: t.func.isRequired,
  checkQualification: t.func.isRequired,
  deleteEmployer: t.func.isRequired,
  errors: t.object,
  form: t.object,
  handleForm: t.func.isRequired,
  hydrateForm: t.func.isRequired,
  reload: t.func.isRequired,
  reloadWithTodos: t.func.isRequired,
  request: t.object,
  updateEmployer: t.func.isRequired,
  permissions: t.object,
  toDos: t.object,
  isReducedIDREmpCert: t.bool,
};

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.employmentHistory;

  return {
    id: getUserId(state),
    employers: cache,
    actionRequest: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
    ]),
    errors: errors,
    form: form,
    request: mergeRequestStatuses([requests.loadCollection, requests.updateResource]),
    requests: requests,
    toDos: safeAccess(state, 'todos.cache', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearForm: () => dispatch(employmentHistoryClearForm()),
  createEmployer: async () => {
    await dispatch(employmentCreateResource());
  },
  reload: () => dispatch(employmentHistoryLoadCollection()),
  reloadWithTodos: () => {
    dispatch(employmentHistoryClearForm());
    dispatch(employmentHistoryLoadCollection());
    dispatch(todosLoadCollection());
  },
  handleForm: (employer, attributes) =>
    dispatch(employmentHistoryHandleFormChange(employer, attributes)),
  hydrateForm: async (id) => await dispatch(employmentHistoryCopyResourceToForm(id)),
  updateEmployer: async (id) => {
    await dispatch(employmentUpdateResource(id));
  },
  submit: async (stepState) => {
    await dispatch(empcertSaveApproverInfo(stepState));
  },
  deleteEmployer: async (id) => {
    await dispatch(employmentDeleteResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmployerApprovers));
