import React from 'react';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import { Card, CardBody, CardTitle, CardText } from 'fiducius-ui';

class StepDisplay extends React.Component {
  static propTypes = {
    title: t.string,
    children: t.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      showBody: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.setState({
      showBody: !this.state.showBody,
    });
  };

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle onClick={this.handleClick}>
              <FontAwesomeIcon icon={this.state.showBody ? faAngleDown : faAngleRight} fixedWidth />
              {this.props.title}
            </CardTitle>

            {this.state.showBody && <CardText>{this.props.children}</CardText>}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default StepDisplay;
