import actions from './actions';
import { normalize, patchApi, resourceOperationsGenerator } from '../../utils';
import { getToken } from '../../auth';
import { TuitionInstitutionValidator } from './validators';

const tuitionInstitutionValidator = new TuitionInstitutionValidator();

const endpointHandler = {
  endpoint: '/tuition-reimbursement-institution',
  customResponseHandler: null,
  resourceType: 'tuition-reimbursement-institution',
};

const operations = resourceOperationsGenerator(
  'tuitionInstitution',
  actions,
  null,
  tuitionInstitutionValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  tuitionInstitutionClearForm,
  tuitionInstitutionCopyResourceToForm,
  tuitionInstitutionHandleFormChange,
  tuitionInstitutionLoadResource,
  tuitionInstitutionLoadCollection,
  tuitionInstitutionCreateResource,
  tuitionInstitutionDeleteResource,
} = operations;

const institutionCreateResource = () => async (dispatch, getState) => {
  const addedId = await dispatch(tuitionInstitutionCreateResource());
  await dispatch(tuitionInstitutionLoadCollection());
  return addedId;
};

const institutionUpdateResource = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(actions.tuitionInstitutionUpdateResourceRequest());
    const patchData = normalize('tuition-reimbursement-institution', state.tuitionInstitution.form);
    await patchApi(getToken(state), `/tuition-reimbursement-institution/${id}`, patchData);

    dispatch(actions.tuitionInstitutionUpdateResourceResponse());

    dispatch(actions.tuitionInstitutionUpdateResourceSuccess());
    await dispatch(tuitionInstitutionLoadCollection());
  } catch (error) {
    dispatch(operations.tuitionInstitutionHandleError(error));
    dispatch(actions.tuitionInstitutionUpdateResourceFailure(error));
  }
};

const institutionDeleteResource = (id) => async (dispatch, getState) => {
  await dispatch(tuitionInstitutionDeleteResource(id));
  await dispatch(tuitionInstitutionLoadCollection());
};

export {
  tuitionInstitutionClearForm,
  tuitionInstitutionCopyResourceToForm,
  tuitionInstitutionHandleFormChange,
  tuitionInstitutionLoadResource,
  tuitionInstitutionLoadCollection,
  institutionCreateResource,
  institutionDeleteResource,
  institutionUpdateResource,
};

export default operations;
