import actions from './actions';
import { normalize, patchApi, resourceOperationsGenerator } from '../../utils';
import { getToken } from '../../auth';
import { tuitionInstitutionLoadCollection } from '../../tuition-institution';
import { TuitionInstitutionClassValidator } from './validators';
const endpointHandler = {
  endpoint: '/tuition-reimbursement-class',
  customResponseHandler: null,
  resourceType: 'tuition-reimbursement-class',
};

const tuitionInstitutionClassValidator = new TuitionInstitutionClassValidator();

const operations = resourceOperationsGenerator(
  'tuitionInstitutionClass',
  actions,
  null,
  tuitionInstitutionClassValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  tuitionInstitutionClassClearForm,
  tuitionInstitutionClassCopyResourceToForm,
  tuitionInstitutionClassHandleFormChange,
  tuitionInstitutionClassLoadResource,
  tuitionInstitutionClassLoadCollection,
  tuitionInstitutionClassCreateResource,
  tuitionInstitutionClassDeleteResource,
} = operations;

const classCreateResource = () => async (dispatch, getState) => {
  const addedId = await dispatch(tuitionInstitutionClassCreateResource());
  await dispatch(tuitionInstitutionLoadCollection());
  return addedId;
};

const classUpdateResource = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(actions.tuitionInstitutionClassUpdateResourceRequest());
    const patchData = normalize('tuition-reimbursement-class', state.tuitionInstitutionClass.form);
    await patchApi(getToken(state), `/tuition-reimbursement-class/${id}`, patchData);

    dispatch(actions.tuitionInstitutionClassUpdateResourceResponse());

    dispatch(actions.tuitionInstitutionClassUpdateResourceSuccess());
    await dispatch(tuitionInstitutionLoadCollection());
  } catch (error) {
    dispatch(operations.tuitionInstitutionClassHandleError(error));
    dispatch(actions.tuitionInstitutionClassUpdateResourceFailure(error));
  }
};

const classDeleteResource = (id) => async (dispatch, getState) => {
  await dispatch(tuitionInstitutionClassDeleteResource(id));
  await dispatch(tuitionInstitutionLoadCollection());
};

export {
  tuitionInstitutionClassClearForm,
  tuitionInstitutionClassCopyResourceToForm,
  tuitionInstitutionClassHandleFormChange,
  tuitionInstitutionClassLoadResource,
  tuitionInstitutionClassLoadCollection,
  classCreateResource,
  classDeleteResource,
  classUpdateResource,
};

export default operations;
