import React from 'react';
import { Card, CardBody, CardText, Header1 } from 'fiducius-ui';

import { FullscreenContainer } from '../../../routing';

import QuestionnaireBTSWorkflow from '../components/questionnaire-workflow';

const QuestionnaireBTS = () => (
  <FullscreenContainer className="container">
    <div className="row">
      <div className="col-12">
        <Card>
          <CardBody>
            <Header1>StudentFORGIVE Snapshot Questionnaire</Header1>
            <CardText>
              <QuestionnaireBTSWorkflow />
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  </FullscreenContainer>
);

export default QuestionnaireBTS;
