import React from 'react';
import styled from 'styled-components';
import { Alert, Button } from 'fiducius-ui';

const StyledAlert = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  & > button {
    margin-left: auto;
  }
`;

const EnrollingTuition = () => (
  <Alert brand="primary">
    <StyledAlert>
      If you are participating in your employer's tuition assistance benefit and have not entered
      your bank and account information yet, please click here to complete it.
      <Button
        brand="primary"
        invert
        onClick={() => {
          window.location.href = '/accounts';
        }}
      >
        Click here to get set up
      </Button>
    </StyledAlert>
  </Alert>
);

EnrollingTuition.propTypes = {};

export default EnrollingTuition;
