import React from 'react';
import t from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { Card, Header5, Header6, Undraw } from 'fiducius-ui';

const hoverCss = (disabled, hasFile) => {
  if (!disabled) {
    return css`
      &.active {
        cursor: copy;
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        cursor: pointer;
        ${hasFile
          ? css`
              box-shadow: ${(p) => p.theme.boxShadowLarge};
              transition: box-shadow 250ms ease-in-out;
            `
          : css`
              box-shadow: inset 0 0 0.75rem 0.25rem ${(p) => p.theme.primary};
              transition: box-shadow 100ms ease-in-out;
            `}
        outline: 0;
        & > .file-overlay {
          opacity: 1;
        }
      }
    `;
  } else {
    return css`
      cursor: not-allowed;
    `;
  }
};

const fileCss = (disabled, hasFile) => css`
  align-items: center;
  border-radius: ${(p) => p.theme.borderRadius};
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: 1em;
  min-height: 13rem;
  max-height: 17rem;
  min-width: 9rem;
  max-width: 12rem;
  padding: 1em;
  position: relative;
  ${hasFile
    ? css`
        box-shadow: ${(p) => p.theme.boxShadow};
        transition: box-shadow 250ms ease-in-out;
      `
    : css`
        border: ${(p) => p.theme.borderWidth} dashed ${(p) => p.theme.highContrast};
        transition: box-shadow 100ms ease-in-out;
      `}
  ${hoverCss(disabled, hasFile)}
`;

// eslint-disable-next-line no-unused-vars
const UploadZone = styled(({ hasFile, zoneRef, ...rest }) => <div ref={zoneRef} {...rest} />)`
  ${(p) => fileCss(p.disabled, p.hasFile)}
`;

// eslint-disable-next-line no-unused-vars
const FileItem = styled(({ hasFile, ...rest }) => <Card {...rest} />)`
  ${(p) => fileCss(p.disabled, p.hasFile)}
`;

const ActionOverlay = styled.div`
  align-items: center;
  color: ${(p) => p.theme.highContrast};
  display: flex;
  flex-shrink: 1;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const OverlayBackground = styled.div`
  background-color: ${(p) => p.theme.level4};
  color: ${(p) => p.theme.danger};
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
`;

const OverlayIconLayers = styled.span`
  z-index: 1;
  .overlay-icon-front {
    color: ${(p) => p.theme.highContrast};
  }

  .overlay-icon-back {
    color: ${(p) => p.theme.lowContrast};
  }
`;

const FileName = styled(Header5)`
  text-align: center;
  word-wrap: break-word;
  width: 100%;
`;

const FulfillmentFileLink = ({ history, link, name, title, type }) => {
  const image = type === 'img' ? 'photo' : 'document';
  return (
    <FileItem className="file-item" hasFile>
      <FileName>{title}</FileName>
      <>
        <Undraw image={image} size={1 / 4} />
        <Header6>{name}</Header6>
        <ActionOverlay className="file-overlay" onClick={() => history.push(link)}>
          <OverlayBackground />
          <OverlayIconLayers className="fa-layers fa-7x fa-fw">
            <FontAwesomeIcon className="overlay-icon-back" icon={faSquare} fixedWidth />
            <FontAwesomeIcon
              className="overlay-icon-front"
              icon={faExternalLink}
              fixedWidth
              transform="up-1 right-2"
            />
          </OverlayIconLayers>
        </ActionOverlay>
      </>
    </FileItem>
  );
};

FulfillmentFileLink.propTypes = {
  history: t.object,
  link: t.string.isRequired,
  name: t.node,
  title: t.string.isRequired,
  type: t.string.isRequired,
};

export default withRouter(FulfillmentFileLink);
