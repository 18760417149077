const clientStatuses = {
  // non-profit
  NFP_CONTRIBUTION: ['NPCTB', 'VERFY'],
  NFP_PRESALE: ['OPEN', 'LEAD', 'WAIT', 'INQ', 'DCLT', 'PLAN'],
  NFP_POSTSALE: ['AGREE', 'PROC', 'CCONS', 'CSERV', 'CRET', 'COMP', 'SMPFI', 'NFPSS'],
  NFP_RENEWAL: ['CRET'],
  NFP_SSRENEWAL: ['NSSRW'],

  // for profit
  FP_CONTRIBUTION: ['FPCTB', 'VERFY'],
  FP_PRESALE: ['OPEN', 'WAIT', 'LEAD', 'PROSP', 'INQ', 'FPMOD'],
  FP_POSTSALE: ['FPAGR', 'PROC', 'FCONS', 'FPCSR', 'FPRWL', 'COMP', 'FPSS'],
  FP_RENEWAL: ['FPRWL'],
  FP_SSRENEWAL: ['FSSRW'],

  // combined
  CONTRIBUTION: ['NPCTB', 'FPCTB', 'VERFY'],
  PRESALE: ['OPEN', 'WAIT', 'LEAD', 'PROSP', 'INQ', 'DCLT', 'PLAN', 'FPMOD'],
  POSTSALE: [
    'AGREE',
    'PROC',
    'CCONS',
    'CSERV',
    'CRET',
    'COMP',
    'SMPFI',
    'FPAGR',
    'FPRWL',
    'FCONS',
    'FPCSR',
    'NFPSS',
    'NSSRW',
    'FSSRW',
  ],
  RENEWAL: ['CRET', 'FPRWL', 'NSSRW', 'FSSRW'],

  // specials
  CLOSED: ['CLOSE'],
  OHPAY: ['OHPAY'],
  REFI: ['REFI'],
  SIMPLIFI: ['SMPFI'],
  UPSELL: ['FUPHB'],
};

export default clientStatuses;
