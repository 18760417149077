import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { CardBody, CardText, Header2, Link, Undraw } from 'fiducius-ui';

import ContrastDisplay from '../styles/contrast-display';
import FullscreenContainer from '../styles/fullscreen-container';
import ShadowAddCard from '../styles/shadow-add-card';
import FullscreenSpacer from '../styles/fullscreen-spacer';

const TextCenter = styled(CardText)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  & > div,
  & > h2,
  & > a {
    margin: 1rem 0;
  }
`;

const errors = {
  '401': 'Unauthorized Access',
  '404': 'Page not found',
  '500': 'Something went wrong',
};

const ErrorPage = ({ match }) => (
  <FullscreenContainer className="container-fluid">
    <div className="row">
      <div className="col-12 col-sm-10 col-md-8">
        <ContrastDisplay>{match.params.code}</ContrastDisplay>
        <ShadowAddCard>
          <CardBody>
            <TextCenter>
              <Header2>{errors[match.params.code]}</Header2>
              <Undraw image="thought" />
              <Link to="/">Return home</Link>
            </TextCenter>
          </CardBody>
        </ShadowAddCard>
        <FullscreenSpacer />
      </div>
    </div>
  </FullscreenContainer>
);

ErrorPage.propTypes = {
  match: t.shape({
    params: t.shape({
      code: t.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ErrorPage;
