import React from 'react';
import t from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { paymentSetPaymentType } from '../../../payment';
import { getUserId } from '../../../auth';
import { formatAsMoney } from '../../../utils';

import {
  limitedWaiverOpportunityLoadResource,
  limitedWaiverOpportunityCopyResourceToForm,
} from '../redux/operations';

import LwoConfirmation from '../components/lwo-confirmation';
import LwoProgress from '../components/lwo-progress';

class LwoFee extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    load: t.func,
    data: t.object,
    setPaymentType: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: null };
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  redirectForLwoPayment = () => {
    this.props.setPaymentType().then(() => {
      this.setState({ ...this.state, redirectURI: '/payment' });
    });
  };

  renderPaidTile() {
    const { data } = this.props;
    if (data.companyPaid) {
      return this.renderCompanyPaid();
    } else if (data.originationPaid) {
      return this.renderOriginationPaid();
    } else {
      return this.renderVoluntaryPaid();
    }
  }

  renderCompanyPaid() {
    return (
      <CardText>
        <p>The cost of Assure&#8480; was paid for by your employer.</p>
      </CardText>
    );
  }

  renderOriginationPaid() {
    return (
      <CardText>
        <p>The cost of Assure&#8480; was included in your Forgiveness implementation fee.</p>
      </CardText>
    );
  }

  renderVoluntaryPaid() {
    const { data } = this.props;
    return (
      <CardText>
        <p>
          Almost done. Now that you've signed the agreement, it's time to pay{' '}
          {formatAsMoney(data.lwoCost)} to get your peace of mind. Click the "Pay for Assure&#8480;"
          button and you'll be taken to our online payment form.
        </p>
      </CardText>
    );
  }

  render() {
    if (this.state.redirectURI !== null) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <DashboardSection>
          <LwoProgress removeContainer={true} />
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <CardTitle>Pay for Assure&#8480;</CardTitle>
                  <AsyncLoader request={this.props.request}>
                    {this.props.data && this.renderPaidTile()}
                  </AsyncLoader>
                  <LwoConfirmation stepId={605} />
                </div>
              </div>
            </CardBody>
          </Card>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: state.limitedWaiverOpportunity.requests.loadResource,
  data: state.limitedWaiverOpportunity.form,
  id: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
    await dispatch(limitedWaiverOpportunityCopyResourceToForm(id));
  },
  setPaymentType: async () => await dispatch(paymentSetPaymentType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoFee));
