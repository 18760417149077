import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  AsyncLoader,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { getUserId } from '../../../auth';
import {
  limitedWaiverOpportunityLoadResource,
  limitedWaiverOpportunityCopyResourceToForm,
} from '../redux/operations';
class LwoNotQualified extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    currentStage: t.object,
    load: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  showPSLFMessage = () => {
    const { permissions } = this.props;
    if (permissions.hasForgiveness) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <DashboardSection>
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-12">
                <CardTitle>Options</CardTitle>
                <AsyncLoader request={this.props.request}>
                  <CardText>
                    {!this.showPSLFMessage() && (
                      <p>
                        Based on our analysis of your information you would not benefit from using
                        Assure&#8480; at this time. Fiducius will review your current situation and
                        if we determine that there are any other options we can provide to assist
                        you a representative will reach out to you. If you do not hear from us then
                        unfortunately there is no further assistance we can provide. Thank you for
                        your interest in Fiducius.
                      </p>
                    )}
                    {this.showPSLFMessage() && (
                      <p>
                        Based on our analysis of your information you would not benefit from using
                        Assure&#8480; at this time. We recommend that you continue on with your
                        student loan forgiveness program as you have been. Thank you for your
                        continued interest in Fiducius.
                      </p>
                    )}
                  </CardText>
                </AsyncLoader>
              </div>
            </div>
          </CardBody>
        </Card>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const lwoRequests = state.limitedWaiverOpportunity.requests;

  return {
    id: getUserId(state),
    currentStage: state.limitedWaiverOpportunity.form.currentBenefitStage,
    request: lwoRequests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
    await dispatch(limitedWaiverOpportunityCopyResourceToForm(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoNotQualified));
