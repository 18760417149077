import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { Card, CardBody, CardText, CardTitle, InputCheckbox } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

const PlanBody = styled(CardBody)`
  background-color: ${(p) => (p.disabled ? p.theme.level2 : p.theme.level0)};
`;

const stripTitle = (text) => {
  const matches = text.split(/(-|–|—)/g); // this is why we can't have nice things.
  if (safeAccess(matches, 'length', 0) > 1) {
    return matches[2];
  } else {
    return null;
  }
};

const stripCheck = (text) => {
  const matches = text.split('Check');
  if (safeAccess(matches, 'length', 0) > 0) {
    return matches[0];
  } else {
    return null;
  }
};

const PlanService = ({ handleChange, service }) => (
  <Card brand={service.planSelected ? 'secondary' : 'primary'}>
    <PlanBody disabled={!service.allowedChangeChoice}>
      <CardTitle>{service.planServiceName || 'Plan Service'}</CardTitle>
      <CardText>
        <InputCheckbox
          name="planSelected"
          label={
            (service.allowedChangeChoice
              ? stripTitle(service.planServiceText)
              : stripCheck(stripTitle(service.planServiceText))) || 'Elect plan service'
          }
          disabled={!service.allowedChangeChoice}
          defaultChecked={service.planSelected}
          onChange={(e) => handleChange(e.target.checked, service.planServiceId, 'services')}
        />
      </CardText>
    </PlanBody>
  </Card>
);

PlanService.propTypes = {
  handleChange: t.func.isRequired,
  service: t.shape({
    allowedChangeChoice: t.bool.isRequired,
    planSelected: t.bool.isRequired,
    planServiceId: t.number.isRequired,
    planServiceText: t.string,
    planServiceName: t.string,
  }).isRequired,
};

export default PlanService;
