import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';
import { TuitionValidator } from './validators';

const tuitionValidator = new TuitionValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/tuition-reimbursement',
  resourceType: 'tuition-reimbursement',
};

const operations = resourceOperationsGenerator(
  'tuition',
  actions,
  null,
  tuitionValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  tuitionLoadResource,
  tuitionCopyResourceToForm,
  tuitionHandleFormChange,
  tuitionUpdateResource,
} = operations;

export {
  tuitionLoadResource,
  tuitionCopyResourceToForm,
  tuitionHandleFormChange,
  tuitionUpdateResource,
};

export default operations;
