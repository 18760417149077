import React from 'react';
import t from 'prop-types';
import { formatAsMoney } from '../../../utils/format';
import {
  Alert,
  BrandColor,
  Card,
  CardTitle,
  CardBody,
  Form,
  Header5,
  InputAddon,
  InputGroup,
  InputHelp,
  InputLabel,
  InputSelect,
  InputNumeric,
  InputWrapper,
  Header2,
} from 'fiducius-ui';

import { Fade } from '../../../routing';
import { MessageDiv } from '../../../messages';

class TaxEstimateTool extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    longFilingStatus: t.string,
    onChange: t.func.isRequired,
  };

  constructor(props, data = {}) {
    super(props);

    this.state = {
      clientIncome: 0,
      spouseIncome: 0,

      clientChildCliam: 0,
      spouseChildClaim: 0,

      educationCredit: 0,
      childCareCredit: 0,
      singleEducationCredit: 0,
      singleChildCareCredit: 0,
      clientOtherCredit: 0,
      spouseOtherCredit: 0,
      jointOtherCredit: 0,

      clientDeductions: 0,
      spouseDeductions: 12200,

      clientLoanBalance: 0,
      spouseLoanBalance: 0,

      studentInterestDeduction: 0,
      jointInterestDeduction: 0,

      tuitionDeduction: 0,
      jointTuitionDeduction: 0,

      educatorExpense: 0,
      spouseEducatorExpense: 0,

      filingStatus: '',
    };

    this.handleFilingStatus = this.handleFilingStatus.bind(this);

    this.handleClientIncomeChange = this.handleClientIncomeChange.bind(this);
    this.handleSpouseIncomeChange = this.handleSpouseIncomeChange.bind(this);

    this.handleClientChildCliam = this.handleClientChildCliam.bind(this);
    this.handleSpouseChildCliam = this.handleSpouseChildCliam.bind(this);

    this.handleClientDeductionChange = this.handleClientDeductionChange.bind(this);
    this.handleSpouseDeductionChange = this.handleSpouseDeductionChange.bind(this);

    this.handleEducationCreditChange = this.handleEducationCreditChange.bind(this);
    this.handleChildCareCreditChange = this.handleChildCareCreditChange.bind(this);
    this.handleSingleEducationCreditChange = this.handleSingleEducationCreditChange.bind(this);
    this.handleSingleChildCareCreditChange = this.handleSingleChildCareCreditChange.bind(this);

    this.handleClientOtherCredit = this.handleClientOtherCredit.bind(this);
    this.handleSpouseOtherCredit = this.handleSpouseOtherCredit.bind(this);
    this.handleJointOtherCredit = this.handleJointOtherCredit.bind(this);

    this.handleClientLoanBalance = this.handleClientLoanBalance.bind(this);
    this.handleSpouseLoanBalance = this.handleSpouseLoanBalance.bind(this);

    this.handleStudentLoanIntDeduction = this.handleStudentLoanIntDeduction.bind(this);
    this.handleJointInterestDeduction = this.handleJointInterestDeduction.bind(this);
    this.handleTuitionDeduction = this.handleTuitionDeduction.bind(this);
    this.handleJointTuitionDeduction = this.handleJointTuitionDeduction.bind(this);
    this.handleEducatorExpense = this.handleEducatorExpense.bind(this);
    this.handleSpouseEducatorExpense = this.handleSpouseEducatorExpense.bind(this);
  }
  handleFilingStatus(event) {
    if (event.target.value === 'Married Filing Separate') {
      this.setState({
        filingStatus: event.target.value,
        singleEducationCredit: 0,
        singleChildCareCredit: 0,
      });
    } else {
      this.setState({ filingStatus: event.target.value });
    }
  }

  //Income changes
  handleClientIncomeChange(event) {
    this.setState({ clientIncome: event.target.value });
  }
  handleSpouseIncomeChange(event) {
    this.setState({ spouseIncome: event.target.value });
  }
  //Deduction Change
  handleClientDeductionChange(event) {
    this.setState({ clientDeductions: event.target.value });
  }
  handleSpouseDeductionChange(event) {
    this.setState({ spouseDeductions: event.target.value });
  }
  //Children claims
  handleClientChildCliam(event) {
    this.setState({ clientChildCliam: event.target.value });
  }
  handleSpouseChildCliam(event) {
    this.setState({ spouseChildCliam: event.target.value });
  }
  handleEducationCreditChange(event) {
    this.setState({ educationCredit: event.target.value });
  }
  handleChildCareCreditChange(event) {
    this.setState({ childCareCredit: event.target.value });
  }
  handleSingleEducationCreditChange(event) {
    this.setState({ singleEducationCredit: event.target.value });
  }
  handleSingleChildCareCreditChange(event) {
    this.setState({ singleChildCareCredit: event.target.value });
  }
  handleClientOtherCredit(event) {
    this.setState({ clientOtherCredit: event.target.value });
  }
  handleSpouseOtherCredit(event) {
    this.setState({ spouseOtherCredit: event.target.value });
  }
  handleJointOtherCredit(event) {
    this.setState({ jointOtherCredit: event.target.value });
  }
  handleClientLoanBalance(event) {
    this.setState({ clientLoanBalance: event.target.value });
  }
  handleSpouseLoanBalance(event) {
    this.setState({ spouseLoanBalance: event.target.value });
  }
  handleStudentInterestDeduction(event) {
    this.setState({ studentInterestDeduction: event.target.value });
  }
  handleJointInterestDeduction(event) {
    this.setState({ jointInterestDeduction: event.target.value });
  }
  handleStudentLoanIntDeduction(event) {
    this.setState({ studentInterestDeduction: event.target.value });
  }
  handleTuitionDeduction(event) {
    this.setState({ tuitionDeduction: event.target.value });
  }
  handleJointTuitionDeduction(event) {
    this.setState({ jointTuitionDeduction: event.target.value });
  }
  handleEducatorExpense(event) {
    this.setState({ educatorExpense: event.target.value });
  }
  handleSpouseEducatorExpense(event) {
    this.setState({ spouseEducatorExpense: event.target.value });
  }

  render() {
    const { data = {}, onChange } = this.props;
    const filingStatus = this.state.filingStatus || data.filingStatus;

    let clientIncome = this.state.clientIncome || data.agi;
    let spouseIncome = this.state.spouseIncome;

    const clientChildClaim = this.state.clientChildCliam || 0;
    const spouseChildClaim = this.state.spouseChildCliam || 0;

    let studentIntDeduction = this.state.studentInterestDeduction || 0;
    let jointIntDeduction = this.state.jointInterestDeduction || 0;
    let tuitionDeduction = this.state.tuitionDeduction || 0;
    let jointTuitionDeduction = this.state.jointTuitionDeduction || 0;
    let educatorDeduction = this.state.educatorExpense || 0;
    let spouseEducatorDed = this.state.spouseEducatorExpense;

    let educationCredit = this.state.educationCredit || 0;
    let childCareCredit = this.state.childCareCredit || 0;
    let singleEducationCredit = this.state.singleEducationCredit || 0;
    let singleChildCareCredit = this.state.singleChildCareCredit || 0;

    let clientLoanBalance = this.state.clientLoanBalance || data.totalPrincipal;
    let spouseLoanBalance = this.state.spouseLoanBalance || 0;

    /* DEDUCTION SECTION*/
    let clientDeductions = this.state.clientDeductions;
    let spouseDeductions = this.state.spouseDeductions;

    let itemizedOrStandardNameClient;
    let itemizedOrStandardNameSpouse;
    let defaultDeduction = 12200;
    if (this.state.filingStatus === 'Head of Household') {
      if (clientDeductions === 0) {
        clientDeductions = 18350;
        spouseDeductions = 0;
      }
    } else if (this.state.filingStatus === 'Married Filing Jointly') {
      if (clientDeductions === 0) {
        clientDeductions = 24400;
        spouseDeductions = 0;
      }
    } else {
      if (clientDeductions === 0) {
        clientDeductions = 12200;
      }
    }

    if (
      (clientDeductions === 12200 && this.state.filingStatus === 'Single') ||
      (clientDeductions === 12200 && this.state.filingStatus === 'Married Filing Separate') ||
      (clientDeductions === 18350 && this.state.filingStatus === 'Head of Household') ||
      (clientDeductions === 24400 && this.state.filingStatus === 'Married Filing Jointly')
    ) {
      itemizedOrStandardNameClient = 'Standard Deduction';
    } else {
      itemizedOrStandardNameClient = 'Itemized Deduction';
    }
    if (
      (spouseDeductions === 12200 && this.state.filingStatus === 'Single') ||
      (spouseDeductions === 12200 && this.state.filingStatus === 'Married Filing Separate') ||
      (spouseDeductions === 18350 && this.state.filingStatus === 'Head of Household') ||
      (spouseDeductions === 24400 && this.state.filingStatus === 'Married Filing Jointly')
    ) {
      itemizedOrStandardNameSpouse = 'Standard Deduction';
    } else {
      itemizedOrStandardNameSpouse = 'Itemized Deduction';
    }

    let jointDeductionsForm = clientDeductions / 1 + spouseDeductions / 1;
    let jointIncomeForm = clientIncome / 1 + spouseIncome / 1;

    //Student Loan Interest Deduction
    if (clientIncome > 82200) {
      studentIntDeduction = 0;
    } else {
      if (studentIntDeduction > 2500) {
        studentIntDeduction = 2500;
      }
    }
    if (jointIncomeForm > 164400) {
      jointIntDeduction = 0;
    } else {
      if (jointIntDeduction > 2500) {
        jointIntDeduction = 2500;
      }
    }

    //Tuition Deduction
    if (clientIncome > 77200 || singleEducationCredit > 0) {
      tuitionDeduction = 0;
    } else {
      if (tuitionDeduction > 4000) {
        tuitionDeduction = 4000;
      }
    }
    if (jointIncomeForm > 154400 || educationCredit > 0) {
      jointTuitionDeduction = 0;
    } else {
      if (jointIntDeduction > 4000) {
        jointTuitionDeduction = 4000;
      }
    }

    //Educator Deduction
    if (educatorDeduction > 250) {
      educatorDeduction = 250;
    }
    if (spouseEducatorDed > 250) {
      spouseEducatorDed = 250;
    }

    /* INCOME SECTION*/
    let jointTaxableIncome = jointIncomeForm - jointDeductionsForm;

    clientIncome -= studentIntDeduction / 1;
    clientIncome -= tuitionDeduction / 1;
    clientIncome -= educatorDeduction / 1;
    spouseIncome -= spouseEducatorDed / 1;
    jointTaxableIncome -= jointIntDeduction / 1;
    jointTaxableIncome -= jointTuitionDeduction / 1;
    jointTaxableIncome -= educatorDeduction / 1 + spouseEducatorDed / 1;

    const jointChildClaimForm = clientChildClaim / 1 + spouseChildClaim / 1;
    let clientTaxableIncome = clientIncome - clientDeductions;

    if (clientTaxableIncome < 0) {
      clientTaxableIncome = 0;
    }
    let spouseTaxableIncome = spouseIncome - spouseDeductions;
    if (spouseTaxableIncome < 0) {
      spouseTaxableIncome = 0;
    }

    if (jointTaxableIncome < 0) {
      jointTaxableIncome = 0;
    }

    //add differences for filing status
    let incomeCollection = [spouseTaxableIncome, jointTaxableIncome, clientTaxableIncome];

    /*TAX BRACKET SECTION*/
    let clientTaxBracket = 0;
    let spouseTaxBracket = 0;
    let jointTaxBracket;
    let index = 0;
    incomeCollection.forEach((income) => {
      if (filingStatus === 'Married Filing Jointly' || index === 1) {
        if (income <= 19400) {
          clientTaxBracket = income * 0.1;
        } else if (income > 19400 && income <= 78950) {
          clientTaxBracket = (income - 19400) * 0.12 + 1940;
        } else if (income > 78950 && income <= 168400) {
          clientTaxBracket = (income - 78950) * 0.22 + 9086;
        } else if (income > 168400 && income <= 321450) {
          clientTaxBracket = (income - 168400) * 0.24 + 28765;
        } else if (income > 321450 && income <= 408200) {
          clientTaxBracket = (income - 321450) * 0.32 + 65497;
        } else if (income > 408200 && income <= 612350) {
          clientTaxBracket = (income - 408200) * 0.35 + 93257;
        } else if (income > 612350) {
          clientTaxBracket = (income - 612350) * 0.37 + 164709.5;
        }
      } else if (filingStatus === 'Head of Household') {
        if (income <= 13850) {
          clientTaxBracket = income * 0.1;
        } else if (income > 13850 && income <= 52850) {
          clientTaxBracket = (income - 13850) * 0.12 + 1385;
        } else if (income > 52850 && income <= 84200) {
          clientTaxBracket = (income - 52850) * 0.22 + 6065;
        } else if (income > 84200 && income <= 160700) {
          clientTaxBracket = (income - 84200) * 0.24 + 12962;
        } else if (income > 160700 && income <= 204100) {
          clientTaxBracket = (income - 160700) * 0.32 + 31322;
        } else if (income > 204100 && income <= 510300) {
          clientTaxBracket = (income - 204100) * 0.35 + 45210;
        } else if (income > 510300) {
          clientTaxBracket = (income - 510300) * 0.37 + 152380;
        }
      } else if (filingStatus === 'Single' || filingStatus === 'Married Filing Separate') {
        if (income <= 9700) {
          clientTaxBracket = income * 0.1;
        } else if (income > 9700 && income <= 39475) {
          clientTaxBracket = (income - 9700) * 0.12 + 970;
        } else if (income > 39475 && income <= 84200) {
          clientTaxBracket = (income - 39475) * 0.22 + 4543;
        } else if (income > 84200 && income <= 160725) {
          clientTaxBracket = (income - 84200) * 0.24 + 14382.5;
        } else if (income > 160725 && income <= 204100) {
          clientTaxBracket = (income - 160725) * 0.32 + 32748.5;
        } else if (income > 204100 && income <= 510300) {
          clientTaxBracket = (income - 204100) * 0.35 + 46628.5;
        } else if (income > 510300) {
          clientTaxBracket = (income - 510300) * 0.37 + 153798.5;
        }
      }

      if (index === 0) {
        spouseTaxBracket = clientTaxBracket;
      } else if (index === 1) {
        jointTaxBracket = clientTaxBracket;
      } else if (index === 2) {
        //Do Nothing
      }
      index = index + 1;
    });

    /* CHILD TAX SECTION*/
    let clientChildTaxCredit = 0;
    if (
      clientIncome < 200000 ||
      (filingStatus === 'Married Filing Jointly' && clientIncome < 400000)
    ) {
      clientChildTaxCredit = clientChildClaim * 2000;
      if (clientTaxBracket < clientChildTaxCredit) {
        if (clientChildTaxCredit - clientTaxBracket > 1400) {
          clientTaxBracket = -1400;
        } else {
          clientTaxBracket = clientTaxBracket / 1 - clientChildTaxCredit;
        }
      } else {
        clientTaxBracket = clientTaxBracket / 1 - clientChildTaxCredit;
      }
    }

    let spouseChildTaxCredit = 0;
    if (
      spouseIncome < 200000 ||
      (filingStatus === 'Married Filing Jointly' && spouseIncome < 400000)
    ) {
      spouseChildTaxCredit = spouseChildClaim * 2000;
      if (spouseTaxBracket < spouseChildTaxCredit) {
        if (spouseChildTaxCredit - spouseTaxBracket > 1400) {
          spouseTaxBracket = -1400;
        } else {
          spouseTaxBracket = spouseTaxBracket - spouseChildTaxCredit;
        }
      } else {
        spouseTaxBracket = spouseTaxBracket - spouseChildTaxCredit;
      }
    }

    let TaxCredit = 0;
    if (jointIncomeForm < 400000) {
      TaxCredit = jointChildClaimForm * 2000;
      if (jointTaxBracket < TaxCredit) {
        if (TaxCredit - jointTaxBracket > 1400) {
          jointTaxBracket = -1400;
        } else {
          jointTaxBracket = jointTaxBracket - TaxCredit;
        }
      } else {
        jointTaxBracket = jointTaxBracket - TaxCredit;
      }
    }

    //Child Care Credit section (Only claimed by single, head of household, qualifying widow or widower with a qualifying child, or married filing jointly.)
    //Child care cannot reduce your taxes due below zero as of 2019 tax year.
    if (childCareCredit > 3000) {
      childCareCredit = 3000;
    } else if (childCareCredit < 1) {
      childCareCredit = null;
    }

    TaxCredit = TaxCredit / 1 + childCareCredit / 1;
    if (jointTaxBracket > 0) {
      if (jointTaxBracket - childCareCredit > 0) {
        jointTaxBracket = jointTaxBracket / 1 - childCareCredit / 1;
      } else {
        jointTaxBracket = 0;
      }
    }

    if (singleChildCareCredit > 3000) {
      singleChildCareCredit = 3000;
    } else if (singleChildCareCredit < 1) {
      singleChildCareCredit = null;
    }

    if (clientTaxBracket > 0) {
      clientChildTaxCredit = clientChildTaxCredit / 1 + singleChildCareCredit / 1;
      if (clientTaxBracket - singleChildCareCredit > 0) {
        clientTaxBracket = clientTaxBracket / 1 - singleChildCareCredit / 1;
        clientChildTaxCredit = clientChildTaxCredit / 1 + singleChildCareCredit / 1;
      } else if (clientTaxBracket > 0 && clientTaxBracket - singleChildCareCredit < 0) {
        clientTaxBracket = 0;
      }
    } else if (clientTaxBracket < 0) {
      clientChildTaxCredit = clientChildTaxCredit / 1 + singleChildCareCredit / 1;
    }

    //Education Credit section (Only claimed by joint taxes, not Separate)
    if (educationCredit > 2500) {
      educationCredit = 2500;
    } else if (educationCredit < 1) {
      educationCredit = null;
    }

    if (jointIncomeForm < 136000) {
      TaxCredit = TaxCredit / 1 + educationCredit / 1;
      if (jointTaxBracket - educationCredit >= 0) {
        jointTaxBracket = jointTaxBracket - educationCredit;
      } else if (
        jointTaxBracket - educationCredit < 0 &&
        jointTaxBracket - educationCredit > -1000
      ) {
        jointTaxBracket = jointTaxBracket - educationCredit;
      } else if (jointTaxBracket < -1000) {
        //Do Nothing clientTaxBracket = clientTaxBracket;
      } else {
        jointTaxBracket = -1000;
      }
    }

    if (singleEducationCredit > 2500) {
      singleEducationCredit = 2500;
    } else if (singleEducationCredit < 1) {
      singleEducationCredit = null;
    }

    if (clientIncome < 60000) {
      clientChildTaxCredit = clientChildTaxCredit / 1 + singleEducationCredit / 1;
      if (clientTaxBracket - singleEducationCredit >= 0) {
        clientTaxBracket = clientTaxBracket - singleEducationCredit;
      } else if (
        clientTaxBracket - singleEducationCredit < 0 &&
        clientTaxBracket - singleEducationCredit > -1000
      ) {
        clientTaxBracket = clientTaxBracket - singleEducationCredit;
      } else if (clientTaxBracket < -1000) {
        //Do Nothing clientTaxBracket = clientTaxBracket;
      } else {
        clientTaxBracket = -1000;
      }
    }

    //OTHER CREDITS SECTION
    clientChildTaxCredit = clientChildTaxCredit / 1 + this.state.clientOtherCredit / 1;
    clientTaxBracket = clientTaxBracket - this.state.clientOtherCredit;

    spouseTaxBracket = spouseTaxBracket - this.state.spouseOtherCredit;
    spouseChildTaxCredit = spouseChildTaxCredit / 1 + this.state.spouseOtherCredit / 1;

    jointTaxBracket = jointTaxBracket - this.state.jointOtherCredit;
    TaxCredit = TaxCredit / 1 + this.state.jointOtherCredit / 1;
    /* TAX DUE SECTION*/
    let clientTaxDue = clientTaxBracket;
    let spouseTaxDue = spouseTaxBracket;
    let jointTaxDue = jointTaxBracket;

    /* IDR SECTION*/

    let planMultiplier;
    let povertyGuideline = data.povertyGuideline;
    if (data.repaymentPlan === 'PAYE' || data.repaymentPlan === 'REPAYE') {
      planMultiplier = 0.1;
    } else if (data.repaymentPlan === 'ICR') {
      planMultiplier = 0.2;
    } else if (data.repaymentPlan === 'SAVE') {
      planMultiplier = data.saveMultiplier;
      povertyGuideline = data.savePovertyGuideline;
    } else {
      planMultiplier = 0.15;
    }

    let jointPlanMultiplier;
    if (data.repaymentPlan === 'ICR') {
      jointPlanMultiplier = 0.2;
    } else {
      jointPlanMultiplier = 0.1;
    }

    /*const povGuidCalc = (householdSize, povGuidelines) => {
      //modifies pov guidelines if a client lives in alaska or is ICR elegible.
      if (data.state === 'AK' && data.repaymentPlan === 'ICR') {
        return (povGuidelines = 15600 + 5530 * (householdSize - 1));
      } else if (data.state === 'AK') {
        return (povGuidelines = 23400 + 8295 * (householdSize - 1));
      } else if (data.repaymentPlan === 'ICR') {
        return (povGuidelines = 12490 + 4420 * (householdSize - 1));
      } else {
        return (povGuidelines = 18735 + 6630 * (householdSize - 1));
      }
    };*/

    let idrAmount = ((clientIncome - povertyGuideline) * planMultiplier) / 12;
    let jointIdrAmount = ((jointIncomeForm - povertyGuideline) * jointPlanMultiplier) / 12;
    let jointIdrName = 'Estimated Joint IDR Monthly Payment';

    if (spouseLoanBalance > 0) {
      jointIdrAmount =
        jointIdrAmount * (clientLoanBalance / (clientLoanBalance / 1 + spouseLoanBalance / 1));
    }

    if (jointIdrAmount > data.standardRepaymentAmt && data.standardRepaymentAmt != null) {
      jointIdrAmount = data.standardRepaymentAmt;
      jointIdrName = 'Estimated Standard Monthly Payment';
    }
    if (idrAmount < 0) {
      idrAmount = 0;
    }
    if (isNaN(jointIdrAmount) || jointIdrAmount === null || jointIdrAmount < 0) {
      jointIdrAmount = 0;
    }

    let overallSavings = (
      (jointIdrAmount - idrAmount) * 12 -
      (spouseTaxDue + clientTaxDue - jointTaxDue)
    ).toFixed(2);

    return (
      <Form id="retirement" defaults={data} handleChange={onChange}>
        <CardTitle>Tax Estimator Tool</CardTitle>
        <Header2>
          Estimates are not exact. Please consult a tax professional for accurate estimates.
        </Header2>
        <p>
          <MessageDiv messageType={'Tax_Estimator_Tool'} />
        </p>
        <CardBody>
          <ui>
            <li>
              This tool will give you a rough estimate for your federal taxes and will show how your
              taxes can affect your Income Driven repayment amount. All figures are estimates, and
              do not include local and state tax. Please consult a professional for accurate
              estimates.
            </li>
          </ui>
        </CardBody>
        <div className="row">
          <InputWrapper>
            <InputGroup>
              <Alert brand="info">
                <p>
                  <span>
                    Haven't filed your taxes yet? Another benefit of being a client of ours is you
                    (and your spouse) can utilize our national partner, TurboTax and receive a
                    discounted rate to file. Visit{' '}
                  </span>
                  <span>
                    <a
                      href="https://turbotax.intuit.com/lp/aff/?zn1=c60KRkuK6,c44bb5xGe,c4CNdkWIA&priorityCode=6099000013&PID=8973232&cid=all_cjtto-8973232_int&sid=&ref_id=89e9d978219911ea821000a60a240612_800803290092577363%3AiPwCTCpIH4MQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      TurboTax
                    </a>{' '}
                    to start filing.
                  </span>
                </p>
              </Alert>
            </InputGroup>
          </InputWrapper>
        </div>
        <div className="row">
          <div className="col-4">
            <InputWrapper>
              <InputLabel htmlFor="state">Filing Status</InputLabel>
              <InputGroup>
                <InputSelect
                  name="filingStatus"
                  onChange={this.handleFilingStatus}
                  options={[
                    '',
                    'Single',
                    'Head of Household',
                    'Married Filing Separate',
                    'Married Filing Jointly',
                  ]}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-md-4">
            <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
              <InputWrapper>
                <InputLabel htmlFor="spouse size">
                  <BrandColor brand="highContrast">
                    <strong>Client Federal Loan Balance</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    id="ClientLoanBalance"
                    name="ClientLoanBalance"
                    min="0"
                    defaultValue={clientLoanBalance}
                    onChange={this.handleClientLoanBalance}
                  />
                </InputGroup>
                <InputHelp></InputHelp>
              </InputWrapper>
            </Fade>
          </div>
          <div className="col-6 col-md-4">
            <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
              <InputWrapper>
                <InputLabel htmlFor="spouse size">
                  <BrandColor brand="highContrast">
                    <strong>Spouse Federal Loan Balance</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    id="ClientLoanBalance"
                    name="ClientLoanBalance"
                    min="0"
                    defaultValue={0}
                    onChange={this.handleSpouseLoanBalance}
                  />
                </InputGroup>
                <InputHelp>
                  Only include balance that are in a repayment or forbearance status. Do not include
                  loans with a Parent Plus Loan balance.
                </InputHelp>
              </InputWrapper>
            </Fade>
          </div>
        </div>

        <Fade show={this.state.filingStatus !== ''}>
          <div className="row">
            <div className="col-6 col-lg-4">
              <InputWrapper>
                <InputLabel htmlFor="client income">
                  {' '}
                  <BrandColor brand="highContrast">
                    <strong>My Annual Income</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    id="clientIncome"
                    name="income"
                    defaultValue={clientIncome}
                    onChange={this.handleClientIncomeChange}
                  />
                </InputGroup>
              </InputWrapper>
            </div>

            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="spouse size">
                    <BrandColor brand="highContrast">
                      <strong>Spouse's Annual Income</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      id="spouseIncome"
                      name="spouseIncome"
                      min="0"
                      value={this.state.spouseIncome}
                      onChange={this.handleSpouseIncomeChange}
                    />
                  </InputGroup>
                </InputWrapper>
              </Fade>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="spouse size">
                    <strong>Joint Income</strong>
                  </InputLabel>
                  <p></p>
                  <Header5>
                    <BrandColor brand="highContrast">
                      {formatAsMoney(jointIncomeForm.toFixed(2))}
                    </BrandColor>
                  </Header5>
                </InputWrapper>
              </Fade>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-lg-4">
              <InputWrapper>
                <InputLabel htmlFor="client income">
                  {' '}
                  <BrandColor brand="highContrast">
                    {' '}
                    <strong>Children Under 17 You Claim</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputNumeric
                    id="clientChildren"
                    name="clientChildren"
                    min={0}
                    onChange={this.handleClientChildCliam}
                  />
                </InputGroup>
                <InputHelp>
                  $2,000 credit per child claimed. Phases out at $200,000 annual income.
                </InputHelp>
              </InputWrapper>
            </div>

            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="spouse size">
                    <BrandColor brand="highContrast">
                      <strong>Children Under 17 your Spouse Claims</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="spouseChildren"
                      name="spouseChildren"
                      min={0}
                      onChange={this.handleSpouseChildCliam}
                    />
                  </InputGroup>
                  <InputHelp>
                    $2,000 credit per child claimed. Phases out at $200,000 annual income.
                  </InputHelp>
                </InputWrapper>
              </Fade>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="spouse size">
                    <strong>Total Children Under 17 claimed</strong>
                  </InputLabel>
                  <p></p>
                  <Header5>
                    <BrandColor brand="highContrast">{jointChildClaimForm}</BrandColor>
                  </Header5>
                  <InputHelp>
                    $2,000 credit per child claimed. Phases out at $400,000 joint annual income.
                  </InputHelp>
                </InputWrapper>
              </Fade>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-lg-4">
              <InputWrapper>
                <InputLabel htmlFor="client income">
                  {' '}
                  <BrandColor brand="highContrast">
                    {' '}
                    <strong>My Educator Expenses</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    id="clientChildren"
                    name="clientChildren"
                    min={0}
                    value={educatorDeduction}
                    onChange={this.handleEducatorExpense}
                  />
                </InputGroup>
                <InputHelp>A teacher can claim a maximum of $250.</InputHelp>
              </InputWrapper>
            </div>

            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="spouse size">
                    <BrandColor brand="highContrast">
                      <strong>Spouse Educator Expenses</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      id="spouseEducatorExpense"
                      name="spouseEducatorExpense"
                      min={0}
                      value={spouseEducatorDed}
                      onChange={this.handleSpouseEducatorExpense}
                    />
                  </InputGroup>
                  <InputHelp>A teacher can claim a maximum of $250.</InputHelp>
                </InputWrapper>
              </Fade>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="spouse size">
                    <strong>Joint Educator Expenses</strong>
                  </InputLabel>
                  <p></p>
                  <Header5>
                    <BrandColor brand="highContrast">
                      {formatAsMoney(spouseEducatorDed / 1 + educatorDeduction / 1)}
                    </BrandColor>
                  </Header5>
                  <InputHelp>Teachers can claim a maximum of $500 jointly.</InputHelp>
                </InputWrapper>
              </Fade>
            </div>
          </div>

          <Fade id="notMarried" show={filingStatus !== 'Married Filing Separate'}>
            <div className="row">
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Student Loan Interest Deduction</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="educationCredits"
                      min={0}
                      defaultValue={studentIntDeduction}
                      value={studentIntDeduction}
                      onChange={this.handleStudentLoanIntDeduction}
                    />
                  </InputGroup>
                  <InputHelp>You can claim a max of $2,500.</InputHelp>
                </InputWrapper>
              </div>
            </div>
          </Fade>
          <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
            <div className="row">
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Joint Student Loan Interest Deduction</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="educationCredits"
                      min={0}
                      onChange={this.handleJointInterestDeduction}
                      defaultValue={jointIntDeduction}
                      value={jointIntDeduction}
                    />
                  </InputGroup>
                  <InputHelp>
                    You can claim a max of $2,500. You cannot claim this deduction when filing
                    seperate.
                  </InputHelp>
                </InputWrapper>
              </div>
            </div>
          </Fade>

          <Fade id="notMarried" show={filingStatus !== 'Married Filing Separate'}>
            <div className="row">
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Tuition and Fee Deduction</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="educationCredits"
                      min={0}
                      defaultValue={tuitionDeduction}
                      value={tuitionDeduction}
                      onChange={this.handleTuitionDeduction}
                    />
                  </InputGroup>
                  <InputHelp>
                    You can claim a max of $4,000. You cannot claim this deduction and Education
                    Credits at the same time.
                  </InputHelp>
                </InputWrapper>
              </div>
            </div>
          </Fade>
          <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
            <div className="row">
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Joint Tuition and Fee Deduction</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="educationCredits"
                      min={0}
                      defaultValue={jointTuitionDeduction}
                      value={jointTuitionDeduction}
                      onChange={this.handleJointTuitionDeduction}
                    />
                  </InputGroup>
                  <InputHelp>
                    You can claim a max of $4,000. You cannot claim this deduction and Education
                    Credits at the same time.
                  </InputHelp>
                </InputWrapper>
              </div>
            </div>
          </Fade>

          <div className="row">
            <div className="col-6 col-md-4">
              <InputWrapper>
                <InputLabel htmlFor="Percent Contribution">
                  <BrandColor brand="highContrast">
                    <strong>{itemizedOrStandardNameClient}</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    name="clientDeduction"
                    id="clientDeduction"
                    value={clientDeductions}
                    onChange={this.handleClientDeductionChange}
                  />
                </InputGroup>
                {itemizedOrStandardNameClient === 'Itemized Deduction' && (
                  <InputHelp>
                    For itemized deductions, it is recommended you do not claim less than the
                    standard amount
                  </InputHelp>
                )}
              </InputWrapper>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>{itemizedOrStandardNameSpouse}</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="spouseDeduction"
                      id="spouseDeduction"
                      defaultValue={defaultDeduction}
                      //value={spouseDeductions}
                      onChange={this.handleSpouseDeductionChange}
                    />
                  </InputGroup>
                  {itemizedOrStandardNameSpouse === 'Itemized Deduction' && (
                    <InputHelp>
                      For itemized deductions, it is recommended you do not claim less than the
                      standard amount
                    </InputHelp>
                  )}
                </InputWrapper>
              </Fade>
            </div>

            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <strong>Joint Itemized / Standard Deduction</strong>
                  </InputLabel>
                  <p></p>
                  <Header5>
                    <BrandColor brand="highContrast">
                      {formatAsMoney(jointDeductionsForm)}
                    </BrandColor>
                  </Header5>
                </InputWrapper>
              </Fade>
            </div>
          </div>

          <div>
            <hr />
          </div>

          <div className="row">
            <div className="col-6 col-md-4">
              <InputWrapper>
                <InputLabel htmlFor="Percent Contribution">
                  <strong>My Taxable Income</strong>
                </InputLabel>
                <p></p>
                <Header5>
                  <BrandColor brand="highContrast">
                    {formatAsMoney(clientTaxableIncome.toFixed(2)) || '—'}
                  </BrandColor>
                </Header5>
                {itemizedOrStandardNameClient === 'Itemized Deduction' && (
                  <InputHelp>
                    For itemized deductions, it is recommended you do not claim less than the
                    standard amount
                  </InputHelp>
                )}
              </InputWrapper>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <strong>Spouse Taxable Income</strong>
                  </InputLabel>
                  <p></p>
                  <Header5>
                    <BrandColor brand="highContrast">
                      {formatAsMoney(spouseTaxableIncome.toFixed(2)) || '—'}
                    </BrandColor>
                  </Header5>
                  {itemizedOrStandardNameSpouse === 'Itemized Deduction' && (
                    <InputHelp>
                      For itemized deductions, it is recommended you do not claim less than the
                      standard amount
                    </InputHelp>
                  )}
                </InputWrapper>
              </Fade>
            </div>

            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <strong>Joint Taxable Income</strong>
                  </InputLabel>
                  <p></p>
                  <Header5>
                    <BrandColor brand="highContrast">
                      {formatAsMoney(jointTaxableIncome.toFixed(2)) || '—'}
                    </BrandColor>
                  </Header5>
                </InputWrapper>
              </Fade>
            </div>
          </div>

          <div>
            <hr />
          </div>

          {/*review values and onChange to make sure still works*/}
          <Fade id="notMarried" show={filingStatus !== 'Married Filing Separate'}>
            <div className="row">
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Education Credits Claimed</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="educationCredits"
                      min={0}
                      value={singleEducationCredit}
                      onChange={this.handleSingleEducationCreditChange}
                    />
                  </InputGroup>
                  <InputHelp>You can claim a max of $2,500.</InputHelp>
                </InputWrapper>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Child Care Credits Claimed</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="childCareCredits"
                      min={0}
                      value={singleChildCareCredit}
                      onChange={this.handleSingleChildCareCreditChange}
                    />
                  </InputGroup>
                  <InputHelp>You can claim a max of $3,000.</InputHelp>
                </InputWrapper>
              </div>
            </div>
          </Fade>

          <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
            <div className="row">
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Education Credits Claimed</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="educationCredits"
                      min={0}
                      value={educationCredit}
                      onChange={this.handleEducationCreditChange}
                    />
                  </InputGroup>
                  <InputHelp>
                    You are unable to claim this credit when filing seperate. You can claim a max of
                    $2,500.
                  </InputHelp>
                </InputWrapper>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4"></div>
              <div className="col-6 col-md-4">
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Child Care Credits Claimed</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="childCareCredits"
                      min={0}
                      value={childCareCredit}
                      onChange={this.handleChildCareCreditChange}
                    />
                  </InputGroup>
                  <InputHelp>
                    You are unable to claim this credit when filing seperate. You can claim a max of
                    $3,000.
                  </InputHelp>
                </InputWrapper>
              </div>
            </div>
          </Fade>

          <div className="row">
            <div className="col-6 col-md-4">
              <InputWrapper>
                <InputLabel htmlFor="Percent Contribution">
                  <BrandColor brand="highContrast">
                    <strong>Other Credits</strong>
                  </BrandColor>
                </InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    name="clientOtherCredits"
                    min={0}
                    defaultValue={''}
                    onChange={this.handleClientOtherCredit}
                  />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Other Credits Spouse Claims</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="spouseOutherCredits"
                      min={0}
                      defaultValue={''}
                      onChange={this.handleSpouseOtherCredit}
                    />
                  </InputGroup>
                </InputWrapper>
              </Fade>
            </div>
            <div className="col-6 col-md-4">
              <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                <InputWrapper>
                  <InputLabel htmlFor="Percent Contribution">
                    <BrandColor brand="highContrast">
                      <strong>Other Joint Credits</strong>
                    </BrandColor>
                  </InputLabel>
                  <InputGroup>
                    <InputAddon>$</InputAddon>
                    <InputNumeric
                      name="JointOtherCredits"
                      min={0}
                      defaultValue={''}
                      onChange={this.handleJointOtherCredit}
                    />
                  </InputGroup>
                </InputWrapper>
              </Fade>
            </div>
          </div>

          <div className="col-12 col-md-12">
            <Card brand="primary">
              <CardBody>
                <div className="row">
                  <div className="col-4 col-md-4">
                    <CardTitle>My Information</CardTitle>
                    <dl>
                      <dt>Credits Claimed</dt>
                      <dd>{formatAsMoney(clientChildTaxCredit) || '—'}</dd>
                      <dt>My Estimated Tax Due</dt>
                      <dd>{formatAsMoney(clientTaxDue.toFixed(2)) || '—'}</dd>
                      <InputHelp>
                        This is an estimate before your Federal witholdings are taken out, and
                        estimates may vary depending on your unique tax situation.
                      </InputHelp>
                    </dl>
                  </div>
                  <div className="col-4 col-md-4">
                    <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                      <CardTitle>Spouse Information</CardTitle>
                      <dl>
                        <dt>Spouse Credits Claimed</dt>
                        <dd>{formatAsMoney(spouseChildTaxCredit) || '—'}</dd>
                        <dt>Spouse Estimated Tax Due</dt>
                        <dd>{formatAsMoney(spouseTaxDue.toFixed(2)) || '—'}</dd>
                        <InputHelp>
                          This is an estimate before your Federal witholdings are taken out, and
                          estimates may vary depending on your unique tax situation.
                        </InputHelp>
                      </dl>
                    </Fade>
                  </div>
                  <div className="col-4 col-md-4">
                    <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
                      <CardTitle>Joint Information</CardTitle>
                      <dl>
                        <dt>Joint Credits Claimed</dt>
                        <dd>{formatAsMoney(TaxCredit) || '—'}</dd>
                        <dt>Joint Estimated Tax Due</dt>
                        <dd>{formatAsMoney(jointTaxDue.toFixed(2)) || '—'}</dd>
                        <InputHelp>
                          This is an estimate before your Federal witholdings are taken out, and
                          estimates may vary depending on your unique tax situation.
                        </InputHelp>
                      </dl>
                    </Fade>
                  </div>
                  <Fade
                    id="alertFilingStatus"
                    show={clientTaxDue < 0 && filingStatus !== 'Married Filing Separate'}
                  >
                    <InputWrapper>
                      <InputGroup>
                        <Alert brand="info">
                          <p>
                            Your Estimated Tax due is less than zero, which means you don't owe tax
                            and are elegible to be refund for more than just the amount of taxes
                            withheld from your paycheck.
                          </p>
                          <p>Please be advised these are estimates and may not be exact.</p>
                        </Alert>
                      </InputGroup>
                    </InputWrapper>
                  </Fade>
                  <Fade
                    id="alertFilingStatus"
                    show={
                      (clientTaxDue < 0 || spouseTaxDue < 0 || jointTaxDue < 0) &&
                      filingStatus === 'Married Filing Separate'
                    }
                  >
                    <InputWrapper>
                      <InputGroup>
                        <Alert brand="info">
                          <p>
                            One or more of your Estimated Tax Due fields is less than zero, which
                            means you don't owe tax and are elegible to be refund for more than just
                            the amount of taxes withheld from your paycheck.
                          </p>
                          <p>Please be advised these are estimates and may not be exact.</p>
                        </Alert>
                      </InputGroup>
                    </InputWrapper>
                  </Fade>
                </div>
              </CardBody>
            </Card>
          </div>

          <Fade id="notMarried" show={filingStatus === 'Married Filing Separate'}>
            <div className="col-12 col-md-12">
              <Card brand="secondary">
                <CardBody>
                  <div className="row">
                    <div className="col-6 col-md-5">
                      <CardTitle>Results</CardTitle>
                      <dl>
                        <dt>Estimated Separate {data.repaymentPlan} Monthly Payment</dt>
                        <dd>
                          {formatAsMoney(idrAmount) || '—'} <br />{' '}
                          <InputHelp>This is a rough estimate and may not be exact</InputHelp>
                        </dd>

                        <dt>{jointIdrName}</dt>
                        <dd>
                          {formatAsMoney(jointIdrAmount) || '—'} <br />{' '}
                          <InputHelp>This is a rough estimate and may not be exact</InputHelp>
                        </dd>
                      </dl>
                    </div>
                    <div className="col-6 col-md-5">
                      <dl>
                        <br /> <br />
                        {/*<Header5><BrandColor brand="highContrast"> </strong></BrandColor></Header5>*/}
                        <dt>Annual Separate IDR Savings</dt>
                        <dd>{formatAsMoney((jointIdrAmount - idrAmount) * 12) || '—'}</dd>
                        <dt>Tax Difference of Filing Joint vs Separate</dt>
                        <dd>{formatAsMoney(spouseTaxDue + clientTaxDue - jointTaxDue) || '—'}</dd>
                        <dt>Overall Savings When Filing Separately</dt>
                        <dd>{formatAsMoney(overallSavings) || '—'}</dd>
                      </dl>
                    </div>
                    <Fade id="alertFilingStatus" show={overallSavings < 0}>
                      <InputWrapper>
                        <InputGroup>
                          <Alert brand="secondary">
                            <p>
                              Filing your taxes married filing jointly will be more beneficial for
                              your forgiveness plan this year.
                            </p>
                          </Alert>
                        </InputGroup>
                      </InputWrapper>
                    </Fade>
                    <Fade id="alertFilingStatus" show={overallSavings >= 0}>
                      <InputWrapper>
                        <InputGroup>
                          <Alert brand="primary">
                            <p>
                              Filing your taxes as married filing separately will be more beneficial
                              for your forgiveness plan this year.
                            </p>
                          </Alert>
                        </InputGroup>
                      </InputWrapper>
                    </Fade>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Fade>

          <Fade id="notMarried" show={filingStatus !== 'Married Filing Separate'}>
            <div className="col-12 col-md-12">
              <Card brand="secondary">
                <CardBody>
                  <div className="row">
                    <div className="col-6 col-md-5">
                      <CardTitle>Results</CardTitle>
                      <dl>
                        <dt>Estimated {data.repaymentPlan} Monthly Payment</dt>
                        <dd>
                          {formatAsMoney(idrAmount) || '—'} <br />{' '}
                          <InputHelp>This is a rough estimate and may not be exact</InputHelp>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Fade>
        </Fade>
      </Form>
    );
  }
}

export default TaxEstimateTool;
