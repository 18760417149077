import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import {
  Form,
  Header2,
  Header4,
  InputError,
  InputLabel,
  InputRadio,
  InputWrapper,
  AsyncLoader,
  Button,
  Lede,
} from 'fiducius-ui';

import { safeAccess, mergeRequestStatuses, getApi, debounce } from '../../../utils';

import { getTuitionInstitutionData } from '../../../benefits/tuition';

import {
  TuitionInstitutionForm,
  tuitionInstitutionClearForm,
  tuitionInstitutionCopyResourceToForm,
  tuitionInstitutionHandleFormChange,
  tuitionInstitutionLoadResource,
  tuitionInstitutionLoadCollection,
  institutionCreateResource,
} from '../../../tuition-institution';

import { withPermissions } from '../../../routing';

import { todosLoadCollection } from '../../../todos';

import { getToken } from '../../../auth';

class TabInstitution extends React.Component {
  static propTypes = {
    institutionData: t.object,
    data: t.object,
    errors: t.object,
    institutionErrors: t.object,
    request: t.object,
    getRequest: t.object,
    handleChange: t.func,
    loadInstitution: t.func.isRequired,
    clearInstitutionForm: t.func.isRequired,
    handleInstitutionForm: t.func.isRequired,
    createInstitution: t.func.isRequired,
    id: t.string.isRequired,
    form: t.object,
    token: t.any,
  };

  constructor(props) {
    super(props);
    this.state = { showForm: false, allowedDegreeTypes: {} };
  }

  componentDidMount() {
    this.props.loadInstitution();
    this.getDegreeTypeDropDownOptions();
  }

  componentDidUpdate(prevProps) {
    const prevLoading = prevProps.request.isLoading;
    const current = this.props.request;

    if (prevLoading && !current.isLoading && !current.hasFailed) {
      this.closeForm();
    }
  }

  getDegreeTypeDropDownOptions = () => {
    getApi(this.props.token, '/drop-down-list/DegreeType__A').then((data) => {
      let degrees = {};
      Object.keys(data).forEach((a) => {
        degrees[data[a].id] = data[a].description;
      });
      this.setState({ ...this.state, allowedDegreeTypes: degrees });
    });
  };

  addInstitution = () => {
    this.setState({ showForm: true });
  };

  updateInstitutionDropdown = debounce((institutionSelection) => {
    let formData = this.props.form;
    formData['institutionName'] = institutionSelection['name'];
    formData['dapipId'] = institutionSelection['id'];
    this.props.handleInstitutionForm({ ...formData }, ['institutionName', 'dapipId']);
  }, 250);

  onChangeInstitution = debounce((tuitionInstitution, id, attributes) => {
    this.props.handleInstitutionForm({ ...this.props.form, ...tuitionInstitution }, [attributes]);
  }, 250);

  onCreateInstitution = () => {
    this.props.createInstitution().then((addedId) => {
      if (addedId && addedId > 0) {
        let formData = this.props.data;
        formData['institutionId'] = addedId;
        this.props.handleChange({ ...formData }, ['institutionId']);
      }
    });
  };

  closeForm = () => {
    this.props.clearInstitutionForm();
    this.setState({
      ...this.state,
      showForm: false,
    });
  };

  render() {
    const {
      data = {},
      form,
      institutionData,
      errors = {},
      getRequest,
      request,
      handleChange,
      id,
      institutionErrors,
    } = this.props;
    const { showForm, allowedDegreeTypes } = this.state;
    const institutions = [];
    return (
      <>
        <Header2>Institution Information</Header2>
        <AsyncLoader
          request={getRequest}
          empty={
            <Header4>
              You have no instutions on file to select. Please use the Add New Institution button
              below to add a new one.
            </Header4>
          }
        >
          <Form handleChange={handleChange} id={id} defaults={data}>
            <div className="row">
              <div className="col-12">
                <InputWrapper error={!!safeAccess(errors, 'institutionId')}>
                  <InputLabel htmlFor="institutionId">Select an Institution:</InputLabel>
                  <InputError>{safeAccess(errors, 'institutionId.detail')}</InputError>
                  {Object.keys(institutionData).length > 0 &&
                    Object.keys(institutionData).forEach((institution) => {
                      institutions.push(
                        <InputRadio
                          name="institutionId"
                          value={institutionData[institution].id}
                          label={institutionData[institution].institutionName}
                          defaultChecked={institutionData[institution].id === data.institutionId}
                        />
                      );
                    })}
                  {institutions}
                </InputWrapper>
              </div>
            </div>
          </Form>
        </AsyncLoader>
        <Lede>
          <Button brand="primary" onClick={() => this.addInstitution()}>
            Add New Institution
          </Button>
        </Lede>
        {showForm && (
          <TuitionInstitutionForm
            action={'Add'}
            closeForm={this.closeForm}
            data={form}
            allowedDegreeTypes={allowedDegreeTypes}
            errors={institutionErrors}
            isLoading={request.isLoading}
            getResource={getRequest}
            isOpen={showForm}
            onChange={this.onChangeInstitution}
            onUpdateDropdown={this.updateInstitutionDropdown}
            onSubmit={this.onCreateInstitution}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests, form, errors } = state.tuitionInstitution;
  return {
    institutionData: getTuitionInstitutionData(state),
    token: getToken(state),
    getRequest: requests.loadCollection,
    institutionErrors: errors,
    request: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
    ]),
    form: form,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
  clearInstitutionForm: () => dispatch(tuitionInstitutionClearForm()),
  createInstitution: async () => {
    const addedId = await dispatch(institutionCreateResource());
    dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
    dispatch(tuitionInstitutionClearForm());
    return addedId;
  },
  handleInstitutionForm: (tuitionInstitution, attributes) =>
    dispatch(tuitionInstitutionHandleFormChange(tuitionInstitution, attributes)),
  hydrateInstitutionForm: async (id) => {
    await dispatch(tuitionInstitutionLoadResource(id));
    await dispatch(tuitionInstitutionCopyResourceToForm(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabInstitution));
