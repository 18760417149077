import React from 'react';
import styled from 'styled-components';
import { BrandColor, Card, CardBody, CardFooter, CardText, CardTitle, Link } from 'fiducius-ui';

import SummaryQuotes from './summary-quotes';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const rateFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 2rem;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const RefinanceEnrolled = () => (
  <Card brand="primary">
    <CardBody>
      <CardTitle>Refinance</CardTitle>
      <CardText>
        <Spaced>
          The average student loan interest rate is
          <strong>
            <BrandColor brand="primary"> 6.00% </BrandColor>
          </strong>
          but you may be eligible for a rate as low as
          <strong>
            <BrandColor brand="primary"> {rateFormatter.format(0.0519)}</BrandColor>
          </strong>
          .
        </Spaced>
        <div className="row">
          <div className="col-6">
            <CardText>
              <dl>
                <dt>Current Monthly Payment</dt>
                <dd>$1,200</dd>
                <dt>Status</dt>
                <dd>Qualified</dd>
              </dl>
            </CardText>
          </div>
          <div className="col-6">
            <CardText>
              <dl>
                <dt>Current Loan Balance</dt>
                <dd>$100,000.00</dd>
                <dt>Next Payment</dt>
                <dd>03/01/2019</dd>
              </dl>
            </CardText>
          </div>
        </div>
      </CardText>
    </CardBody>
    <SummaryQuotes />
    <FlexFooter>
      <Link to="/benefits/refinance">My Refinance</Link>
      <Link to="/accounts">My Accounts</Link>
    </FlexFooter>
  </Card>
);

export default RefinanceEnrolled;
