import { useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';

export const useFocusOnMount = () => {
  const focusRef = useRef(null);
  useEffect(() => focusRef.current.focus(), []);
  return focusRef;
};

export const useScrollToTopOnMount = () => {
  useEffect(() => window.scrollTo(0, 0), []);
};

export const useResize = (ref, setWidth) => {
  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [ref, setWidth]);

  useEffect(() => {
    const handleResize = throttle(() => {
      if (ref && ref.current) {
        setWidth(ref.current.clientWidth);
      }
    }, 500);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, setWidth]);
};
