import { FormValidator } from '../../utils';

export class TuitionInstitutionClassValidator extends FormValidator {
  className(validator) {
    validator.isRequired();
    validator.hasMaxLength(150);
  }

  grade(validator) {}

  hours(validator) {
    if (validator.value) {
      validator.validNumber();
      validator.isLessThan(100);
    }
  }
}
