import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  BrandColor,
  Form,
  Header5,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { convertIsoToSlash, debounce, formatAsMoney, safeAccess } from '../../../utils';
import { TodoComplete } from '../../../todos';

import {
  idrCopyCompleteToForm,
  idrHandleFormChange,
  idrLoadComplete,
  idrSaveComplete,
} from '../redux/operations';

const TodoText = ({ errors = {}, form = {}, handleChange, repayTypeName }) => (
  <Form id="idrComplete" handleChange={handleChange} defaults={form}>
    <Header5>Check on your application</Header5>
    <strong>Step 1 – Access MOHELA account</strong>
    <ul>
      <li>Login to your account at MOHELA.</li>
    </ul>
    <strong>Step 2 – Check the status of the income-driven repayment (Every seven days)</strong>
    <ul>
      <li>Check your Account Summary for the “In Repayment” status.</li>
      <li>
        <strong>Please note:</strong> MOHELA will not process the annual recertification request
        until the last bill for the current year is generated.
      </li>
      <li>Check your Account Inbox for Repayment Details.</li>
      <li>What are you looking for?</li>
      <ul>
        <li>
          {repayTypeName !== undefined && repayTypeName !== null && repayTypeName !== '' ? (
            <>
              Your repayment plan type should be <strong>{repayTypeName}</strong>.
            </>
          ) : (
            <strong>
              <BrandColor brand="danger">
                Your repayment plan type isn&#39;t set. Please visit the Contact Us button on the
                left of your portal to reach out to us regarding this.
                {/*
                 Contact us at{' '}
                <a href="tel:+15136455400">(513) 645-5400</a> option: 2. */}
              </BrandColor>
            </strong>
          )}
        </li>
        <li>
          {form.projectedIdr !== undefined &&
          form.projectedIdr !== null &&
          !isNaN(form.projectedIdr) ? (
            <>
              Your repayment amount should be <strong>{formatAsMoney(form.projectedIdr)}</strong>.
            </>
          ) : (
            <strong>
              <BrandColor brand="danger">
                Your repayment amount isn&#39;t set. Please visit the Contact Us button on the left
                of your portal to reach out to us regarding this.
                {/*Contact us at{' '}
                <a href="tel:+15136455400">(513) 645-5400</a> option: 2.*/}
              </BrandColor>
            </strong>
          )}
        </li>
      </ul>
    </ul>
    <strong>Step 3 – Provide repayment details</strong>
    <ul>
      <li>
        <InputWrapper error={!!errors.doeRepayDate}>
          <InputLabel htmlFor="doeRepayDate">My repayment plan begins on</InputLabel>
          <InputError>{safeAccess(errors, 'doeRepayDate.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputMask
              defaultValue={convertIsoToSlash(form.doeRepayDate)}
              mask="##/##/####"
              name="doeRepayDate"
              placeholder="MM/DD/YYYY"
            />
          </InputGroup>
        </InputWrapper>
      </li>
      <li>
        <InputWrapper error={!!errors.doeRepayEndDate}>
          <InputLabel htmlFor="doeRepayEndDate">My repayment plan ends on</InputLabel>
          <InputError>{safeAccess(errors, 'doeRepayEndDate.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputMask
              defaultValue={convertIsoToSlash(form.doeRepayEndDate)}
              mask="##/##/####"
              name="doeRepayEndDate"
              placeholder="MM/DD/YYYY"
            />
          </InputGroup>
        </InputWrapper>
      </li>
      <li>
        {/* TODO: This needs to be an input field for actual-idr*/}
        <InputWrapper error={!!errors.actualIdr}>
          <InputLabel htmlFor="actualIdr">My repayment amount is</InputLabel>
          <InputError>{safeAccess(errors, 'actualIdr.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputAddon>$</InputAddon>
            <InputText name="actualIdr" type="number" step="0.01" defaultValue={form.actualIdr} />
          </InputGroup>
        </InputWrapper>
      </li>
    </ul>
    <ul>
      <li>
        If your repayment details reflect a higher repayment amount than the one shown on your
        Fiducius account, please visit the Contact Us button on the left of your portal to reach out
        to us regarding this.
        {/*contact us at <a href="tel:+15136455400">(513) 645-5400</a> option: 2.*/}
      </li>
      <li>
        <strong>Please note:</strong> If your repayment from the previous year has not ended,
        continue to make the payment until your new repayment begins on the date input above.
      </li>
    </ul>
    <strong>Step 4 – Confirm completion</strong>
    <ul>
      <li>Confirm your correct income-driven repayment has been set up.</li>
      <ul>
        <li>
          The plan type on your repayment details should match the repayment plan type on your
          Fiducius account.
        </li>
        <li>
          Save a copy of your correct repayment details. Store them in your Fiducius account by
          uploading a copy on the My Documents tab under Other.
        </li>
      </ul>
    </ul>
    <strong>Step 5 – Make payments</strong>
    <ul>
      <li>
        Payments <strong>must</strong> be paid in the exact amount due.
      </li>
      <li>
        Payments <strong>must</strong> be made on time.
      </li>
      <li>
        You may set-up automatic payments through your bank (recommended) or your loan servicer.
      </li>
    </ul>
    <strong>
      Do not confirm this step is finished until you have verified the repayment is complete
    </strong>
    <br />
    <br />
    Questions? Please visit the Contact Us button on the left of your portal to reach out to us.
    {/*Contact us at <a href="tel:+15136455400">(513) 645-5400</a> option: 2*/}
  </Form>
);
TodoText.propTypes = {
  form: t.object,
  errors: t.object,
  handleChange: t.func,
  repayTypeName: t.string,
};

class RepaymentCheck extends React.Component {
  static propTypes = {
    updateForm: t.func.isRequired,
    form: t.object,
  };

  handleChange = debounce((formState, id, attribute) => {
    this.props.updateForm({ ...this.props.form, ...formState }, [attribute]);
  }, 250);

  render() {
    return (
      <TodoComplete
        {...this.props}
        text={<TodoText handleChange={this.handleChange} form={this.props.form} {...this.props} />}
        title="Check on your repayment application"
      />
    );
  }
}

const mapRepaymentType = (type) => {
  switch (type) {
    case 'PAYE':
      return 'Pay as you earn';
    case 'REPAY':
      return 'Revised pay as you earn';
    case 'IBR':
      return 'Income based repayment';
    case 'ICR':
      return 'Income contingent repayment';
    case 'SAVE':
      return 'Saving on a Valuable Education';
    default:
      return 'Other';
  }
};

const mapStateToProps = (state, ownProps) => {
  const userId = getUserId(state);
  let data = safeAccess(state, `idr.cache[${userId}]`, {});
  const repayTypeName = mapRepaymentType(data.projectedRepaymentType);

  let form = safeAccess(state, 'idr.form', {});
  form.actualRepaymentType = data.projectedRepaymentType;
  form.projectedRepaymentType = data.projectedRepaymentType;

  return {
    form: form,
    errors: safeAccess(state, 'idr.errors', {}),
    loadRequest: state.idr.requests.loadResource,
    repayTypeName: repayTypeName,
    request: state.idr.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateForm: (state, attributes) => dispatch(idrHandleFormChange(state, attributes)),
  onLoad: async () => {
    await dispatch(idrLoadComplete(ownProps.id));
    dispatch(idrCopyCompleteToForm());
  },
  action: async () => {
    await dispatch(idrSaveComplete(ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RepaymentCheck);
