import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../../forgiveness/components/renewal-confirmation';
import EmpCertProgress from '../components/emp-cert-progress';
import StepDisplay from '../../forgiveness/components/step-display';

class EmpCertSubmit extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { request, permissions } = this.props;
    const reducedIdrRedirect =
      permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null;

    return (
      <>
        {reducedIdrRedirect && (
          <>
            <Redirect to={'/benefits/reducedidr/empcert-ss/step-2'} />
          </>
        )}
        <AsyncLoader request={request}>
          <div id="step2Section">
            <EmpCertProgress />
            <DashboardSection title="Submit your Employment Certification">
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Complete and send Employment Certification form'}>
                      <ul>
                        <li>
                          You will be required to complete the first page of the form electronically
                          by selecting your employer below.
                        </li>
                        <li>
                          The submission of an employment certification form will initiate a
                          transfer of your student loans to MOHELA, if your loans are not currently
                          at that loan servicer.
                        </li>
                      </ul>
                    </StepDisplay>
                  </AsyncLoader>
                  <RenewalConfirmation stepId={810} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertSubmit));
