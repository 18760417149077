import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import {
  AsyncLoader,
  Form,
  Header4,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Alert,
} from 'fiducius-ui';
import { Fade } from '../../../routing';

import {
  fulfillmentCopyResourceToForm,
  fulfillmentHandleFormChange,
  fulfillmentLoadResource,
  PdfViewer,
  Signature,
} from '../../../fulfillment';
import { debounce, mergeRequestStatuses, safeAccess } from '../../../utils';

import { idrFixUpdateData, idrUpdateResource } from '../redux/operations';

export const IDR_DOC_ID = 11;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const TextLeft = styled.div`
  text-align: left;
`;

class TabIdr extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    form: t.object,
    html: t.string,
    loadHtml: t.func.isRequired,
    request: t.object,
    saveContract: t.func.isRequired,
    signRequest: t.object,
    updateForm: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = { hasHtml: false, redirect: false };
  }

  componentDidMount() {
    this.props.saveContract();

    if (this.scrollRef.current) {
      this.scrollRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { request, signRequest } = this.props;
    if (prevProps.request.isLoading && request.hasFinished && !request.hasFailed) {
      if (!this.state.hasHtml) {
        this.setState({ hasHtml: true }, () => this.props.loadHtml());
      }
    }

    if (prevProps.signRequest.isLoading && signRequest.hasFinished && !signRequest.hasFailed) {
      this.setState({ redirect: true });
    }
  }

  handleChange = debounce((formState, id, attribute) => {
    const { form, updateForm } = this.props;
    formState.docId = IDR_DOC_ID;
    const newData = { ...form, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  handleClientSignature = (data) => {
    this.props.updateForm({ ...this.props.form, signaturePadClient: data }, ['signaturePadClient']);
  };

  handleSpouseSignature = (data) => {
    this.props.updateForm({ ...this.props.form, signaturePadSpouse: data }, ['signaturePadSpouse']);
  };

  handleNumericChange = (e) => {
    const re = /^[0-9\b]+$/;
    switch (e.target.name) {
      case 'ssn1':
        if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({ ssn1State: e.target.value });
        }
        break;
      case 'ssn2':
        if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({ ssn2State: e.target.value });
        }
        break;
      case 'ssn3':
        if (e.target.value === '' || re.test(e.target.value)) {
          this.setState({ ssn3State: e.target.value });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { data = {}, errors = {}, form, request } = this.props;
    let ssnOnFile = safeAccess(data, 'haveSsn', false);

    if (this.state.redirect) {
      return <Redirect to="/documents" />;
    }
    return (
      <>
        <AsyncLoader request={request}>
          <PdfViewer base64={safeAccess(data, 'fileData', '')} isFulfillment={true} />
        </AsyncLoader>
        <Form id="signatureForm" handleChange={this.handleChange} defaults={form}>
          {safeAccess(errors, 'na') && (
            <Alert key={'invalid-info'} brand="danger">
              {errors.na.detail}
            </Alert>
          )}
          <Fade show={request.isLoading}>
            <p></p>
            <Header4>
              <p>Please wait for the document preview to load before you sign your application.</p>{' '}
              <p>This may take up to a minute or two.</p>
            </Header4>
          </Fade>
          <Fade show={!request.isLoading}>
            <Header4>
              For your security, please input the correct information below to sign the application.
            </Header4>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.firstName}>
                  <InputLabel2>First Name</InputLabel2>
                  <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
                  <InputGroup>
                    <InputText name="firstName" />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.lastName}>
                  <InputLabel2>Last Name</InputLabel2>
                  <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
                  <InputGroup>
                    <InputText name="lastName" />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.email}>
                  <InputLabel2>Email</InputLabel2>
                  <InputError>{safeAccess(errors, 'email.detail')}</InputError>
                  <InputGroup>
                    <InputText name="email" type="email" />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.password}>
                  <InputLabel2>Password</InputLabel2>
                  <InputError>{safeAccess(errors, 'password.detail')}</InputError>
                  <InputGroup>
                    <InputText name="password" type="password" />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
            {!ssnOnFile && (
              <>
                <TextLeft>
                  <p>
                    One requirement of this form is your Social Security Number (SSN), please
                    provide it and we will populate it to the form
                  </p>
                </TextLeft>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextLeft>
                      <InputWrapper error={!!errors.idrSSN}>
                        <InputLabel required>Social Security Number</InputLabel>
                        <InputError>{safeAccess(errors, 'idrSSN.detail')}</InputError>
                        <InputGroup>
                          <InputText
                            name="ssn1"
                            type="password"
                            autoComplete="off"
                            placeholder="XXX"
                            maxLength="3"
                            onChange={this.handleNumericChange}
                            value={this.state.ssn1State}
                          />
                          &nbsp;
                          <InputText
                            name="ssn2"
                            type="password"
                            autoComplete="off"
                            placeholder="XX"
                            maxLength="2"
                            onChange={this.handleNumericChange}
                            value={this.state.ssn2State}
                          />
                          &nbsp;
                          <InputText
                            name="ssn3"
                            type="password"
                            autoComplete="off"
                            placeholder="XXXX"
                            maxLength="4"
                            onChange={this.handleNumericChange}
                            value={this.state.ssn3State}
                          />
                        </InputGroup>
                      </InputWrapper>
                    </TextLeft>
                  </div>
                </div>
              </>
            )}
            <Signature handleChange={this.handleClientSignature} label="Your signature" />
            {/* {data.needSpouseSignature && (
              <Signature handleChange={this.handleSpouseSignature} label="Spouse's Signature" />
            )} */}
          </Fade>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.fulfillment.cache[IDR_DOC_ID],
  form: state.fulfillment.form,
  errors: state.fulfillment.errors,
  request: mergeRequestStatuses([
    state.fulfillment.requests.loadResource,
    state.idr.requests.updateResource,
  ]), // separate and then componentdidupdate stuff
  signRequest: state.fulfillment.requests.updateResource,
});

const mapDispatchToProps = (dispatch) => ({
  loadHtml: async () => {
    await dispatch(fulfillmentLoadResource(IDR_DOC_ID));
    await dispatch(fulfillmentCopyResourceToForm(IDR_DOC_ID));
  },
  saveContract: async () => {
    await dispatch(idrFixUpdateData());
    dispatch(idrUpdateResource(IDR_DOC_ID));
  },
  updateForm: (data, attributes) => dispatch(fulfillmentHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabIdr);
