import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  Header2,
  InputError,
  InputGroup,
  InputLabel,
  InputWrapper,
  InputSelect,
  InputTextarea,
  Form,
} from 'fiducius-ui';

import { withPermissions } from '../../routing';
import { debounce, mergeRequestStatuses, safeAccess } from '../../utils';

import {
  contactCopyResourceToForm,
  contactHandleFormChange,
  contactLoadInfo,
} from '../redux/operations';

class ContactForm extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    form: t.object,
    getInfo: t.func.isRequired,
    permissions: t.object,
    request: t.object,
    updateForm: t.func.isRequired,
    handleChange: t.func,
  };

  componentDidMount() {
    this.props.getInfo();
    this.props.form.subject = '';
    this.props.updateForm(this.props.form.subject, Object.keys(this.props.form));
  }

  handleChange = debounce((formState, id, attributes) => {
    this.props.updateForm({ ...this.props.form, ...formState }, [attributes]);
  }, 250);

  render() {
    const { data = {}, errors = {}, permissions } = this.props;
    let options = [];

    if (permissions.enrolledForgiveness && permissions.clientStatus !== 'OHPAY') {
      if (permissions.enterpriseEnabled) {
        options.push('');
        options.push('Forbearance');
        options.push('Consolidation');
        options.push('Employment Verification');
        options.push('Repayment');
        options.push('Tax Filing');
        options.push('Continuing Education');
        options.push('Billing');
        if (permissions.hasFreshStart) {
          options.push('Fresh Start');
        }
        if (permissions.hasReducedIDR) {
          options.push('Reduced IDR');
        }
        if (permissions.hasEducationalReengagement) {
          options.push('Educational Reengagement');
        }
        if (permissions.hasEnterpriseEmpCert) {
          options.push('Emplify');
        }
        if (permissions.hasOptimalSituation) {
          options.push('Additional Information');
        }
        if (permissions.hasMichiganProgram) {
          options.push('Michigan Loan Program');
        }
        options.push('Other');
      } else {
        options = [
          '',
          'Forbearance',
          'Consolidation',
          'Employment Verification',
          'Repayment',
          'Tax Filing',
          'Continuing Education',
          'Billing',
          'Other',
        ];
      }
    } else if (permissions.clientStatus === 'FUPHB') {
      if (permissions.enterpriseEnabled) {
        options.push('');
        if (permissions.hasFreshStart) {
          options.push('Fresh Start');
        }
        if (permissions.hasReducedIDR) {
          options.push('Reduced IDR');
        }
        if (permissions.hasEducationalReengagement) {
          options.push('Educational Reengagement');
        }
        if (permissions.hasEnterpriseEmpCert) {
          options.push('Emplify');
        }
        if (permissions.hasOptimalSituation) {
          options.push('Additional Information');
        }
        if (permissions.hasMichiganProgram) {
          options.push('Michigan Loan Program');
        }
        if (
          !(
            permissions.enrolledFreshStart ||
            permissions.enrolledEducationalReengagement ||
            permissions.enrollingReducedIDR
          )
        ) {
          options.push('General Information About Benefits');
          options.push('Miscellaneous');
        }
      } else {
        options = ['', 'General Information About Benefits', 'Miscellaneous'];
      }
    } else if (permissions.clientStatus === 'OHPAY') {
      options = ['', 'Billing'];
    } else if (permissions.showLwo) {
      options = [
        '',
        'Schedule a Meeting',
        'Change a Meeting',
        'Assure/Limited Waiver Opportunity',
        'General Information About Benefits',
        'Miscellaneous',
      ];
    } else {
      if (permissions.enterpriseEnabled) {
        options.push('');
        if (!permissions.disableScheduling) {
          options.push('Schedule a Meeting');
          options.push('Change a Meeting');
        }
        if (permissions.hasMichiganProgram) {
          options.push('Michigan Loan Program');
        }
        if (
          !(
            permissions.enrolledFreshStart ||
            permissions.enrolledEducationalReengagement ||
            permissions.enrollingReducedIDR
          )
        ) {
          options.push('General Information About Benefits');
          options.push('Miscellaneous');
        }
      } else {
        options.push('');
        if (!permissions.disableScheduling) {
          options.push('Schedule a Meeting');
          options.push('Change a Meeting');
        }
        options.push('General Information About Benefits');
        options.push('Miscellaneous');
      }
    }

    return (
      <Form id="contactForm" handleChange={this.handleChange} defaults={data}>
        <Header2>Please complete the below form to ask your question</Header2>
        <div className="row">
          <div className="col-12 col-sm-5">
            <InputWrapper error={errors && !!errors.subject}>
              <InputLabel htmlFor="subject">Contact us regarding questions on:</InputLabel>
              <InputError>{safeAccess(errors, 'subject.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  name="subject"
                  options={options}
                  onChange={(e) =>
                    this.handleChange({ subject: e.target.value }, 'subject', 'subject')
                  }
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-112">
            <InputWrapper>
              <InputLabel htmlFor="body">
                Please describe your question providing as much detail as possible, including dates,
                amounts, and loan specifics (if applicable).
              </InputLabel>
              <InputGroup>
                <InputTextarea
                  name="body"
                  placeholder=""
                  onChange={(e) => this.handleChange({ body: e.target.value }, 'body', 'body')}
                />
              </InputGroup>
              {/*<InputHelp>
                We can also be reached at <a href="tel:+15136455400">(513) 645-5400</a> to answer
                any of your questions.
              </InputHelp>*/}
            </InputWrapper>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  data: {},
  errors: safeAccess(state, 'contact.errors'),
  form: safeAccess(state, 'contact.form'),
  request: mergeRequestStatuses([
    safeAccess(state, 'contact.requests.loadResource', ''),
    safeAccess(state, 'contact.requests.updateResource', ''),
    safeAccess(state, 'contact.requests.createResource', ''),
  ]),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateForm: (data, attributes) => dispatch(contactHandleFormChange(data, attributes)),
  getInfo: async () => {
    await dispatch(contactLoadInfo());
    dispatch(contactCopyResourceToForm(ownProps.paymentType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ContactForm));
