import React from 'react';
import t from 'prop-types';
import { usd } from '../components/state';
import { CardTitle } from 'fiducius-ui';

export const PlanTable = ({ stdPayment, years, chartRef }) => (
  <div className="polaris-plan-table">
    <div className="polaris-plan-table-window" ref={chartRef}>
      <CardTitle>Plan Projection</CardTitle>
      <table>
        <thead>
          <tr>
            <td>Year</td>
            <td>
              Current Monthly
              <br />
              Payment
            </td>
            <td>
              New Monthly
              <br />
              Payment
            </td>
            <td>
              Yearly
              <br />
              Savings
            </td>
            <td>
              Accumulated
              <br />
              Loan Savings
            </td>
            <td>
              Retirement
              <br />
              Account
              <br />
              Accumulation
            </td>
            <td>
              HSA
              <br />
              Account
            </td>
          </tr>
        </thead>
        <tbody>
          {years.map((y) => (
            <tr key={y.id}>
              <td>{y.id}</td>
              <td>{usd(stdPayment)}</td>
              <td>{usd(y.payment)}</td>
              <td>{usd(y.savings)}</td>
              <td>{usd(y.accSavings)}</td>
              <td>{usd(y.accRetirement)}</td>
              <td>{usd(y.accHsa)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

PlanTable.propTypes = {
  stdPayment: t.number.isRequired,
  years: t.arrayOf(t.object).isRequired,
};
