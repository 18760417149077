import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Alert, AsyncLoader, Button, Loader, Workflow } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { fulfillmentUpdateResource } from '../../../fulfillment';
import { debounce, mergeRequestStatuses, safeAccess } from '../../../utils';

import TabAgreement, { AGREEMENT_DOC_ID } from './tab-agreement';
import TabPayment from './tab-payment';
import TabPlan from './tab-plan';
import {
  agreementCopyResourceToForm,
  agreementHandleFormChange,
  agreementLoadResource,
  agreementValidateAttributes,
} from '../redux/operations';

import { paymentSetTypeDeposit } from '../../../payment';

class AgreementWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    errors: t.object,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
    signContract: t.func.isRequired,
    signRequest: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { current: props.current || 0 };
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;

    if (attribute === 'services') {
      let services = data.services;
      let matches = Object.keys(services).filter((k) => services[k].planServiceId === id);
      if (safeAccess(matches, 'length', 0) === 1) {
        data.services[matches[0]].planSelected = formState;
      }
    }

    if (attribute === 'payInFull') {
      formState.payInFull = formState.payInFull === 'Y';
    }

    const newData = { ...data, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  handleTabChange = (index) => {
    this.props.validateTab(Object.keys(tabs)[this.state.current]);
    this.setState({ current: index });
  };

  sectionValidationStatus(section) {
    const { errors } = this.props;
    let exists = false;
    for (let i = 0; i < tabs[section].length; i++) {
      let attribute = tabs[section][i];
      if (safeAccess(errors, `[${attribute}]`)) {
        return 'danger';
      } else if (safeAccess(errors, `[${attribute}]`) === false) {
        exists = true;
      }
    }
    return exists ? 'success' : null;
  }

  getTabs = (data, errors, agreementStatus) => {
    let tabs = [
      <TabAgreement
        key="2"
        id="agreement"
        data={data}
        errors={errors}
        brand={agreementStatus}
        name="Sign Agreement"
        skipSave={data.skipToAgreement}
      />,
    ];

    if (!data.skipToAgreement) {
      tabs.unshift(
        <TabPlan
          key="2"
          id="plan"
          data={data}
          errors={errors}
          brand={this.sectionValidationStatus('plan')}
          name="Your Plan"
          handleChange={this.handleChange}
        />,
        <TabPayment
          key="2"
          id="payment"
          data={data}
          errors={errors}
          brand={this.sectionValidationStatus('payment')}
          name="Payment Options"
          handleChange={this.handleChange}
        />
      );
    }

    return tabs;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.signRequest.isLoading) {
      this.props.signContract();
    }
  };

  render() {
    const { data, errors, request, signRequest } = this.props;
    let agreementStatus = this.sectionValidationStatus('agreement');
    if (agreementStatus !== 'danger' && signRequest.hasFinished) {
      agreementStatus = signRequest.hasFailed ? 'danger' : 'success';
    }

    return (
      <>
        <AsyncLoader request={request}>
          <>
            {safeAccess(errors, 'generalErrors')
              ? errors.generalErrors.map((e, i) => (
                  <Alert key={i} brand="danger">
                    {e.detail}
                  </Alert>
                ))
              : null}
            {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
            {data && (
              <Workflow
                tabs={this.getTabs(data, errors, agreementStatus)}
                finalButton={
                  <Button brand="primary" onClick={(e) => this.submit(e)}>
                    {signRequest.isLoading ? <Loader variant="push" size={1.5} /> : 'Sign contract'}
                  </Button>
                }
                handleTabChange={this.handleTabChange}
                next
                prev
              />
            )}
          </>
        </AsyncLoader>
      </>
    );
  }
}

const tabs = {
  plan: ['planType', 'services'],
  payment: ['payInFull'],
  agreement: [],
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.agreement;
  return {
    id: getUserId(state),
    data: form,
    errors: errors,
    request: mergeRequestStatuses([requests.loadResource, requests.copyResourceToForm]),
    signRequest: state.fulfillment.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(agreementLoadResource(id));
    await dispatch(agreementCopyResourceToForm(id));
  },
  updateForm: (data, attributes) => {
    dispatch(agreementHandleFormChange(data, attributes));
  },
  signContract: () => {
    dispatch(fulfillmentUpdateResource(AGREEMENT_DOC_ID));
    dispatch(paymentSetTypeDeposit());
  },
  validateTab: async (tab) => {
    await dispatch(agreementValidateAttributes(tabs[tab]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementWorkflow);
