import React from 'react';
import t from 'prop-types';
import {
  Form,
  Header2,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputNumeric,
  InputRadio,
  InputSelect,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { Fade } from '../../../routing';
import { convertIsoToSlash, useScrollToTopOnMount, safeAccess } from '../../../utils';

const TabEducation = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your education</Header2>
      <InputWrapper error={!!errors.returnToSchool}>
        <InputLabel>
          Have you recently, or are you planning on returning to school in the next 6-12 months?
        </InputLabel>
        <InputError>{safeAccess(errors, 'returnToSchool.detail')}</InputError>
        <InputRadio
          name="returnToSchool"
          value="Y"
          label="Yes"
          defaultChecked={data.returnToSchool === 'Y'}
        />
        <InputRadio
          name="returnToSchool"
          value="N"
          label="No"
          defaultChecked={data.returnToSchool === 'N'}
        />
      </InputWrapper>
      <Fade id="returnToSchoolGate" show={data.returnToSchool === 'Y'}>
        <InputWrapper error={!!errors.returnToSchoolDate}>
          <InputLabel htmlFor="returnToSchoolDate">
            When are you planning on going back to school?
          </InputLabel>
          <InputError>{safeAccess(errors, 'returnToSchoolDate.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputMask
              defaultValue={convertIsoToSlash(data.returnToSchoolDate)}
              mask="##/##/####"
              name="returnToSchoolDate"
              placeholder="MM/DD/YYYY"
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.degreeType}>
          <InputLabel htmlFor="degreeType">What degree will you be pursuing?</InputLabel>
          <InputError>{safeAccess(errors, 'degreeType.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputSelect
              name="degreeType"
              defaultValue={data.degreeType}
              options={{
                '': '',
                ASSOC: 'Associates',
                BA: 'Bachelors',
                MA: 'Masters',
                PHD: 'Doctorate',
                CERT: 'Licensures',
              }}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.degreeSpecialty}>
          <InputLabel htmlFor="degreeSpecialty">What field are you planning to study?</InputLabel>
          <InputError>{safeAccess(errors, 'degreeSpecialty.detail')}</InputError>
          <InputGroup affordance="large">
            <InputText name="degreeSpecialty" defaultValue={data.degreeSpecialty} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.creditHours}>
          <InputLabel htmlFor="creditHours">How many credit hours are you taking?</InputLabel>
          <InputError>{safeAccess(errors, 'creditHours.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric name="creditHours" defaultValue={data.creditHours} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.borrowMore}>
          <InputLabel>
            Do you plan on borrowing more federal student loans for your new degree?
          </InputLabel>
          <InputError>{safeAccess(errors, 'borrowMore.detail')}</InputError>
          <InputRadio
            name="borrowMore"
            value="Y"
            label="Yes"
            defaultChecked={data.borrowMore === 'Y'}
          />
          <InputRadio
            name="borrowMore"
            value="N"
            label="No"
            defaultChecked={data.borrowMore === 'N'}
          />
        </InputWrapper>
        <Fade id="borrowMoreGate" show={data.borrowMore === 'Y'}>
          <InputWrapper error={!!errors.borrowMoreAmount}>
            <InputLabel htmlFor="borrowMoreAmount">How much do you plan on borrowing?</InputLabel>
            <InputError>{safeAccess(errors, 'borrowMoreAmount.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputAddon>$</InputAddon>
              <InputNumeric name="borrowMoreAmount" defaultValue={data.borrowMoreAmount} />
              <InputAddon>.00</InputAddon>
            </InputGroup>
          </InputWrapper>
          <InputWrapper error={!!errors.borrowMoreGraduationDate}>
            <InputLabel htmlFor="borrowMoreGraduationDate">
              When do you expect to graduate?
            </InputLabel>
            <InputError>{safeAccess(errors, 'borrowMoreGraduationDate.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputMask
                defaultValue={convertIsoToSlash(data.borrowMoreGraduationDate)}
                mask="##/##/####"
                name="borrowMoreGraduationDate"
                placeholder="MM/DD/YYYY"
              />
            </InputGroup>
          </InputWrapper>
          <InputWrapper error={!!errors.borrowMoreExpectedIncome}>
            <InputLabel htmlFor="borrowMoreExpectedIncome">
              What is your expected income after graduation?
            </InputLabel>
            <InputError>{safeAccess(errors, 'borrowMoreExpectedIncome.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputAddon>$</InputAddon>
              <InputNumeric
                name="borrowMoreExpectedIncome"
                defaultValue={data.borrowMoreExpectedIncome}
              />
              <InputAddon>.00</InputAddon>
            </InputGroup>
          </InputWrapper>
        </Fade>
      </Fade>
    </Form>
  );
};

TabEducation.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabEducation;
