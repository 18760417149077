import React from 'react';
import t from 'prop-types';
import {
  Card,
  ChartBar,
  CardTitle,
  CardBody,
  Form,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputAddon,
  InputNumeric,
} from 'fiducius-ui';

import { formatAsMoney } from '../../../utils/format';
import { debounce } from '../../../utils';
import PretaxItems from './pretax-items';
import PretaxExistingItems from './pretax-existing-items';
import { MessageDiv } from '../../../messages';

class PretaxForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.childDeduction = React.createRef();

    this.state = {
      income: this.props.agi,
      famSize: '',
      totalDeductions: 0,
      totalNewDeductions: 0,
      totalExistingDeductions: 0,
      existingDeductions: [],

      renderCount: 0,
    };

    this.handleIncomeChange = this.handleIncomeChange.bind(this);
    this.handleFamSizeChange = this.handleFamSizeChange.bind(this);
    this.handleExistingDeductions = this.handleExistingDeductions.bind(this);

    this.handleTotalDeductions = this.handleTotalDeductions.bind(this);
  }

  handleExistingDeductions(array) {
    this.setState({ existingDeductions: array });
  }

  handleIncomeChange(event) {
    this.setState({ income: event.target.value });
  }
  handleFamSizeChange(event) {
    this.setState({ famSize: event.target.value });
  }

  handleTotalDeductions(total) {
    this.setState({ totalDeductions: total });
  }

  handleChange = debounce((formState) => {
    let sum = 0;
    let regex = /existing/;

    //Updates the total sum of deductions depending on if existing or new deduction is updated.
    if (regex.test(Object.entries(formState))) {
      //existing deductions

      let values = Object.values(formState);
      let index = [];
      let count = 0;

      for (let items in formState) {
        index.push(items.slice(8, 9));
        this.state.existingDeductions.splice(index[count] - 1, 1, values[count]);
        count += 1;
      }

      for (const value in this.state.existingDeductions) {
        sum += this.state.existingDeductions[value] / 1;
      }

      this.state.totalExistingDeductions = sum;
      sum += this.state.totalNewDeductions;
    } else {
      //added deductions
      for (const value in formState) {
        sum += formState[value] / 1;
      }

      this.state.totalNewDeductions = sum;
      sum += this.state.totalExistingDeductions;
    }

    this.handleTotalDeductions(sum);
  }, 150);

  render() {
    const { data = {}, onChange } = this.props;

    const householdSize = data.householdSize || 1;
    const agiIncome = this.state.income || data.agi;
    let povertyGuideline = data.povertyGuideline;

    let repaymentPlanValue;
    if (data.repaymentPlan === 'PAYE' || data.repaymentPlan === 'REPAYE') {
      repaymentPlanValue = 0.1;
    } else if (data.repaymentPlan === 'ICR') {
      repaymentPlanValue = 0.2;
    } else if (data.repaymentPlan === 'SAVE') {
      repaymentPlanValue = data.saveMultiplier;
      povertyGuideline = data.savePovertyGuideline;
    } else {
      repaymentPlanValue = 0.15;
    }

    const agi = this.state.income || data.agi;
    let idr = ((agi - povertyGuideline) * repaymentPlanValue) / 12;

    const newAgi = agiIncome - this.state.totalDeductions;
    let newIdr = ((newAgi - povertyGuideline) * repaymentPlanValue) / 12;

    if (idr < 0) {
      idr = 0;
    }
    if (newIdr < 0) {
      newIdr = 0;
    }

    ////EXISTING DEDUCTIONS - gets the ref prop from the component and gets total deductions from state.
    this.state.renderCount += 1;
    if (this.state.renderCount === 10) {
      const childDeduction = this.childDeduction.current || Object || null;

      if (childDeduction.state !== undefined) {
        let tempArray = [];
        for (let deduction of childDeduction.state.deductions) {
          tempArray.push(deduction * 26);
        }
        this.handleExistingDeductions(tempArray);
      }

      if (childDeduction.state !== undefined) {
        this.state.totalExistingDeductions = childDeduction.state.deductionAmount;
        this.handleTotalDeductions(childDeduction.state.deductionAmount);
      }
    }

    return (
      <Form id="retirement" defaults={data} handleChange={onChange}>
        <CardTitle>Pre-Tax Deduction Tool</CardTitle>
        <p>
          <MessageDiv messageType={'PreTax_Tool'} />
        </p>
        <CardBody>
          <ui>
            <li>
              This tool will show how adding pre-tax deductions can lower your adjusted gross
              income, and in turn help lower your qualifying Income Driven Repayment amount.
            </li>
          </ui>
        </CardBody>
        <div className="row">
          <div className="col-7 col-sm-7 col-md-5 col-lg-4">
            <InputWrapper>
              <InputLabel htmlFor="income">
                <strong>Annual Income</strong>
              </InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric
                  id="income"
                  name="income"
                  defaultValue={data.agi}
                  onChange={this.handleIncomeChange}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-7 col-sm-7 col-md-5 col-lg-4">
            <InputWrapper>
              <InputLabel htmlFor="Household size">
                <strong>Household Size</strong>
              </InputLabel>
              <InputGroup>
                <InputNumeric
                  id="famSize"
                  name="HouseHoldSize"
                  min="0"
                  defaultValue={householdSize}
                  onChange={this.handleFamSizeChange}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-7 col-sm-7 col-md-5 col-lg-4">
            <InputLabel htmlFor="deductions">
              <strong>Annual Deduction Amounts</strong>
            </InputLabel>
            <PretaxExistingItems
              data={data}
              onChange={this.onChange}
              handleChange={this.handleChange}
              ref={this.childDeduction}
            />
          </div>
        </div>
        <PretaxItems data={data} onChange={this.onChange} handleChange={this.handleChange} />
        <p />
        <p />

        <div>
          <hr />
        </div>

        <div className="row">
          <div className="col-12 col-md-4 col-sm-10">
            <Card brand="primary">
              <CardBody>
                <div className="col">
                  <CardTitle>Results</CardTitle>
                  <dl>
                    <dt>Total Deductions</dt>
                    <dd>{formatAsMoney(this.state.totalDeductions.toFixed(2)) || '—'}</dd>
                    <dt>Adjusted Gross Income</dt>
                    <dd>{formatAsMoney(newAgi.toFixed(2)) || '—'}</dd>
                    <dt>Monthly {data.repaymentPlan} Payment Without Deductions</dt>
                    <dd>{formatAsMoney(idr.toFixed(2)) || '—'}</dd>
                    <dt>Monthly {data.repaymentPlan} Payment With Deductions</dt>
                    <dd>{formatAsMoney(newIdr.toFixed(2)) || '—'}</dd>
                  </dl>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-10 col-md-6">
            <br />
            <br />
            <br />
            <InputWrapper>
              <ChartBar
                bars={[
                  {
                    label: '',
                    dataType: 'currency',
                    data: [idr.toFixed(2), newIdr.toFixed(2)],
                  },
                ]}
                config={{
                  labels: [
                    (data.repaymentPlan || 'IDR') + ' Without Deductions',
                    (data.repaymentPlan || 'IDR') + ' With Deductions',
                  ],
                  formatter: formatAsMoney,
                }}
              />
            </InputWrapper>
          </div>
        </div>
      </Form>
    );
  }
}

export default PretaxForm;
