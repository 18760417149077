import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';

import { AuthContainer, FullscreenSpacer } from '../../routing';
import { Card, CardBody, CardText, Header1 } from 'fiducius-ui';

import CustomWelcomeMessage from '../components/custom-welcome-message.jsx';

const StyledCard = styled(Card)`
  height: 100%;
`;

const StyledRow = styled.div``;
//<div className="col-6 col-sm-10 col-md-8 col-lg-6 col-xl-6">

const AuthPage = ({ children, domain, greeting, onboarding, extraOnboard = false }) => (
  <AuthContainer className="container">
    {onboarding ? (
      <>
        <StyledCard>
          <CardBody>
            <CardText>
              <StyledRow className="row">
                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  <Header1>
                    {domain.includes('trinityhealth') ? 'Welcome colleagues!' : 'Welcome!'}
                  </Header1>
                  <CustomWelcomeMessage extraOnboard={extraOnboard} />
                </div>
                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                  {children}
                </div>
              </StyledRow>
            </CardText>
          </CardBody>
        </StyledCard>

        <FullscreenSpacer />
      </>
    ) : (
      <>
        <StyledCard>
          <CardBody>
            <CardText>
              <StyledRow className="row">
                <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  {children}
                </div>
              </StyledRow>
            </CardText>
          </CardBody>
        </StyledCard>
        <FullscreenSpacer />
      </>
    )}
  </AuthContainer>
);

AuthPage.propTypes = {
  children: t.node.isRequired,
  domain: t.string.isRequired,
  greeting: t.string,
  onboarding: t.bool,
  extraOnboard: t.bool,
};

export default AuthPage;
