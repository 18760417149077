import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header1, Header3, LogoDisplay } from 'fiducius-ui';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';

import { safeAccess } from '../../utils';
import { authGetPartnerLocations, getCRMUserId, getUserId } from '../../auth';

import RouterFullscreen from './router-fullscreen';

const Logo = styled.div`
  /*position: absolute;
  top: 0;
  left: 0;*/
  width: 100%;
  margin: 0.75em 1.25em;
  & > h1 {
    font-size: 3rem;
    color: ${(p) => (p.invert ? p.theme.textPrimary : p.theme.white)};
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.align};
  min-height: 10vh;
  position: absolute;
  width: 100%;
  background-image: radial-gradient(
    at left top,
    ${(p) =>
      p.theme.themeName === 'light'
        ? p.theme.colorLevel(p.theme.primary, -3)
        : p.theme.themeName === 'newlight'
        ? p.theme.colorLevel(p.theme.primaryA, -3)
        : p.theme.colorLevel(p.theme.level1, -3)},
    ${(p) =>
        p.theme.themeName === 'light'
          ? p.theme.colorLevel(p.theme.primary, 4)
          : p.theme.themeName === 'newlight'
          ? p.theme.colorLevel(p.theme.primaryA, 4)
          : p.theme.colorLevel(p.theme.level1, 4)}
      70%,
    ${(p) =>
      p.theme.themeName === 'light'
        ? p.theme.colorLevel(p.theme.primary, 5)
        : p.theme.themeName === 'newlight'
        ? p.theme.colorLevel(p.theme.primaryA, 5)
        : p.theme.colorLevel(p.theme.level1, 5)}
  );

  a {
    color: ${(p) => p.theme.secondary};
  }
`;

const StyledLogo = styled(LogoDisplay)`
  padding-left: 1rem;
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem 1rem;
  padding-left: 1rem;
`;

const StyledLogoWrapper = styled.div`
  padding-left: 1rem;
`;

const StyledHeader1 = styled(Header1)`
  color: ${(p) => p.theme.white};
`;

const StyledHeader3 = styled(Header3)`
  color: ${(p) => p.theme.white};
`;

class LayoutFullscreen extends React.Component {
  static propTypes = {
    align: t.string,
    children: t.node,
    company: t.string,
    greeting: t.string,
    loadPartnerLocations: t.func.isRequired,
    shouldLoadLocations: t.bool.isRequired,
    getCRMUserId: t.func.isRequired,
    getUserId: t.func.isRequired,
    changeTheme: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      partnerDomain: this.parseSubdomain(),
      isEmulation: false,
      emulationId: 0,
    };
  }

  componentDidMount() {
    if (this.props.shouldLoadLocations) {
      this.props.loadPartnerLocations(this.state.partnerDomain);
    }
    const { getCRMUserId, getUserId } = this.props;
    const crmId = getCRMUserId();

    if (crmId !== 'Error') {
      const userId = parseInt(getUserId()) + 1000;
      this.setState({ ...this.state, isEmulation: true, emulationId: userId });
    }
  }

  parseSubdomain() {
    // subdomain.domain.tld <=> app.getfiducius.com
    // domains = [subdomain, domain, tld];
    const domains = window.location.hostname.split('.');
    // If there's more than the domain and top level domain,
    // return the first sub-domain
    return domains.length > 2 ? domains[domains.length - 3] : 'islsoffice';
  }

  render() {
    const { align = 'center', company, children, greeting, changeTheme } = this.props;
    const { isEmulation, emulationId } = this.state;
    let emulationMessage = '';
    if (isEmulation) {
      emulationMessage = 'You are currently emulating account X' + emulationId.toString() + '.';
    }
    return (
      <>
        <Wrapper align={align}>
          <Logo>
            {company && (
              <>
                <div className="row no-gutters">
                  <StyledLogoWrapper>
                    <StyledLogo />
                  </StyledLogoWrapper>
                  <div className="col-auto">
                    <StyledHeader1>
                      <FontAwesomeIcon size="1x" fixedWidth icon={faPlus} />
                      {company}
                    </StyledHeader1>
                  </div>
                </div>
                <LogoWrapper>
                  <StyledHeader3>{greeting}</StyledHeader3>
                </LogoWrapper>
                <LogoWrapper>
                  {isEmulation && <StyledHeader3>{emulationMessage}</StyledHeader3>}
                </LogoWrapper>
              </>
            )}
            {!company && (
              <>
                <div className="row no-gutters">
                  <StyledLogoWrapper>
                    <StyledLogo />
                  </StyledLogoWrapper>
                </div>
                <LogoWrapper>
                  {isEmulation && <StyledHeader3>{emulationMessage}</StyledHeader3>}
                </LogoWrapper>
              </>
            )}
          </Logo>
          {children || (
            <RouterFullscreen
              changeTheme={changeTheme}
              greeting={''}
              domain={this.state.partnerDomain}
            />
          )}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  shouldLoadLocations:
    safeAccess(state, 'persist.requests.load.hasFinished', false) &&
    !safeAccess(state, 'auth.cache.partnerLocations') &&
    safeAccess(state, 'auth.requests.partnerLocation.notStarted', true),
  company:
    safeAccess(state, 'auth.cache.permissions.partnerLine1') ||
    safeAccess(state, 'auth.cache.partnerLocations.partnerLine1'),
  greeting:
    safeAccess(state, 'auth.cache.permissions.partnerLine2') ||
    safeAccess(state, 'auth.cache.partnerLocations.partnerLine2'),
  getCRMUserId: () => getCRMUserId(state),
  getUserId: () => getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadPartnerLocations: (domain) => dispatch(authGetPartnerLocations(domain)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutFullscreen);
