import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  Header2,
  InputWrapper,
  Table,
} from 'fiducius-ui';
import { FlexEnd } from '../../../root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as faXIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { getTuitionData } from '../redux/selectors';
import { tuitionLoadResource } from '../redux/operations';
import { getUserId } from '../../../auth';

class TuitionStatusInfo extends React.Component {
  static propTypes = {
    data: t.object.isRequired,
    trSetUp: t.object.isRequired,
    closeModal: t.func.isRequired,
    load: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {}; // must be initialized for data handling
  }

  componentDidMount() {
    const { load, id, trSetUp } = this.props;
    if (Object.keys(trSetUp).length < 1) {
      load(id);
    }
  }

  render() {
    const { trSetUp } = this.props;

    let preApprovalStatus = false;
    let postApprovalStatus = false;
    if (trSetUp.reimbursementTime === 'BOTHHRLY' || trSetUp.reimbursementTime === 'BOTHSLRY') {
      preApprovalStatus = true;
    }
    if (
      (trSetUp.reimbursementTime === 'BOTHHRLY' ||
        trSetUp.reimbursementTime === 'BOTHSLRY' ||
        trSetUp.reimbursementTime === 'AFTER') &&
      trSetUp.approvalType === 'MANUAL'
    ) {
      postApprovalStatus = true;
    }

    const headers = ['Status', 'Description'];
    const body = [];

    body.push(['Created', 'Your class was created.']);
    body.push(['Waiting on Approval', "Your manager's approval is required to move forward."]);
    body.push([
      'Approved',
      'You have provided the required information to this point and your tuition assistance application is approved.' +
        '\n' +
        'Please Note: You will be required to provide your final transcript and proof of payment for the active term/semester once the class has been completed.',
    ]);
    if (preApprovalStatus) {
      body.push([
        'Pre-Term/Semester Payment Approved',
        "Your pre-payment has been approved by your manager and will be provided based on your employer's next scheduled disbursement of funds.",
      ]);
      body.push([
        'Pre-Term/Semester Payment Pending',
        'Your tuition assistance disbursement is pending.',
      ]);
      body.push([
        'Pre-Term/Semester Payment Complete',
        'Your tuition assistance request has been disbursed.' +
          '\n' +
          'Please Note: You will be required to provide your final transcript and proof of payment for the active term/semester once the class has been completed.',
      ]);
    }
    body.push([
      'Waiting on Proof of Payment and Transcript',
      'Your final transcript and proof of payment is required to approve your tuition assistance disbursement.',
    ]);
    body.push([
      'Waiting on Proof of Payment',
      'Your proof of payment is required to approve your tuition assistance disbursement.',
    ]);
    body.push([
      'Waiting on Transcript',
      'Your final transcript is required to approve your tuition assistance disbursement.',
    ]);
    body.push([
      'Proof of Payment and Transcript Uploaded',
      'Your final transcript and proof of payment have been uploaded and are under review.',
    ]);
    body.push([
      'Proof of Payment and Transcript Denied',
      'Your final transcript and/or proof of payment have been denied.',
    ]);
    body.push([
      'Proof of Payment and Transcript Approved',
      'Your final transcript and proof of payment have been approved.',
    ]);
    if (postApprovalStatus) {
      body.push([
        'Post-Term/Semester Payment Approved ',
        "Your tuition assistance has been approved by your manager and will be provided based on your employer's next scheduled disbursement of funds.",
      ]);
      body.push([
        'Post-Term/Semester Payment Denied',
        'Your tuition assistance has been denied by your manager.',
      ]);
    }
    body.push(['Reimbursement Denied', 'Your tuition assistance request has been denied.']);
    body.push(['Reimbursement Pending', 'Your tuition assistance disbursement is pending.']);
    body.push(['Reimbursement Complete', 'Your tuition assistance request has been disbursed.']);

    return (
      <Card>
        <FlexEnd>
          <InputWrapper>
            <Button brand="lowContrast" onClick={this.props.closeModal}>
              <FontAwesomeIcon icon={faXIcon} />
            </Button>
          </InputWrapper>
        </FlexEnd>
        <CardBody>
          <CardText>
            <Header2>
              <strong>Class Status Information</strong>
            </Header2>
            <div className="row">
              <Table header={headers} body={body} />
            </div>
          </CardText>
        </CardBody>
        <CardFooter>
          <Button brand="lowContrast" onClick={this.props.closeModal}>
            Close
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  id: getUserId(state),
  trSetUp: getTuitionData(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => {
    dispatch(tuitionLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionStatusInfo);
