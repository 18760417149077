import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardText,
  DashboardSection,
  Button,
  Header2,
  Header4,
  InputWrapper,
  ProgressBar,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { safeAccess, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

const StyleBar = styled(ProgressBar)`
  margin-bottom: 2rem;
`;

class ForgivenessProgress extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
    removeContainer: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', fullServiceSteps: 9, currentURI: '' };
  }

  componentDidMount() {
    this.setState({ ...this.state, currentURI: window.location.pathname });
  }

  componentDidUpdate() {}

  goToCurrentStep = () => {
    const { permissions, todos } = this.props;
    const forgivenessSteps = permissions.forgivenessSteps.filter((a) => a.stepCurrent);
    let forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);
    if (
      forgivenessSteps !== undefined &&
      forgivenessSteps !== null &&
      forgivenessSteps.length > 1 &&
      forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      forgivenessStep = forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }
    let retVal = '/benefits/forgiveness/overview';
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (
        forgivenessStep.id === 'Consolidation' ||
        forgivenessStep.id === 'EmpCert' ||
        forgivenessStep.id === 'IDR' ||
        forgivenessStep.id === 'Contribution'
      ) {
        retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
        const currentToDo = todos.find(
          (a) =>
            (forgivenessStep.id === 'Consolidation' &&
              (a.id === 103 || a.id === 104 || a.id === 105)) ||
            (forgivenessStep.id === 'EmpCert' &&
              (a.id === 106 ||
                a.id === 107 ||
                a.id === 108 ||
                a.id === 252 ||
                a.id === 253 ||
                a.id === 254)) ||
            (forgivenessStep.id === 'IDR' &&
              (a.id === 111 ||
                a.id === 112 ||
                a.id === 113 ||
                a.id === 255 ||
                a.id === 256 ||
                a.id === 257)) ||
            (forgivenessStep.id === 'Contribution' &&
              (a.id === 114 || a.id === 115 || a.id === 260 || a.id === 261))
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 103:
            case 106:
            case 111:
            case 114:
            case 252:
            case 255:
            case 260:
              retVal += '/step-1';
              break;
            case 104:
            case 107:
            case 112:
            case 115:
            case 253:
            case 256:
            case 261:
              retVal += '/step-2';
              break;
            case 105:
            case 108:
            case 113:
            case 254:
            case 257:
              retVal += '/step-3';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
      }
    }
    this.setState({ ...this.state, redirectURI: retVal });
  };

  getCurrentStepText = () => {
    const { permissions, todos } = this.props;
    const forgivenessSteps = permissions.forgivenessSteps.filter((a) => a.stepCurrent);
    let forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);
    if (
      forgivenessSteps !== undefined &&
      forgivenessSteps !== null &&
      forgivenessSteps.length > 1 &&
      forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      forgivenessStep = forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }

    let retVal = '';
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (
        forgivenessStep.id === 'Consolidation' ||
        forgivenessStep.id === 'EmpCert' ||
        forgivenessStep.id === 'IDR' ||
        forgivenessStep.id === 'Contribution'
      ) {
        retVal = forgivenessStep.stepText;
        const currentToDo = todos.find(
          (a) =>
            (forgivenessStep.id === 'Consolidation' &&
              (a.id === 103 || a.id === 104 || a.id === 105)) ||
            (forgivenessStep.id === 'EmpCert' &&
              (a.id === 106 ||
                a.id === 107 ||
                a.id === 108 ||
                a.id === 252 ||
                a.id === 253 ||
                a.id === 254)) ||
            (forgivenessStep.id === 'IDR' &&
              (a.id === 111 ||
                a.id === 112 ||
                a.id === 113 ||
                a.id === 255 ||
                a.id === 256 ||
                a.id === 257)) ||
            (forgivenessStep.id === 'Contribution' &&
              (a.id === 114 || a.id === 115 || a.id === 260 || a.id === 261))
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 103:
            case 106:
            case 111:
            case 252:
            case 255:
              retVal += ' - Generate';
              break;
            case 104:
            case 107:
            case 112:
            case 253:
            case 256:
              retVal += ' - Submit';
              break;
            case 105:
            case 108:
            case 113:
            case 254:
            case 257:
              retVal += ' - Review';
              break;
            case 114:
            case 260:
              retVal += ' - Repayment Details';
              break;
            case 115:
            case 261:
              retVal += ' - Validation';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = forgivenessStep.stepText;
      }
    }

    return retVal;
  };

  getTotalSteps = () => {
    const { permissions } = this.props;
    const forgivenessSteps = permissions.forgivenessSteps.filter(
      (a) => a.id !== 'Overview' && a.id !== 'BtsReview'
    );
    if (permissions.isFullService && !permissions.inAuth) {
      return this.state.fullServiceSteps;
    } else {
      return forgivenessSteps.length;
    }
  };

  getCompletedSteps = (normalized) => {
    const { permissions } = this.props;
    const forgivenessSteps = permissions.forgivenessSteps.filter(
      (a) => a.id !== 'Overview' && a.id !== 'BtsReview'
    );
    const completedSteps = forgivenessSteps.filter((a) => a.stepComplete);
    if (permissions.isFullService && !permissions.inAuth)
      if (normalized) {
        return 100.0 * (completedSteps.length / this.state.fullServiceSteps);
      } else {
        return Math.min(completedSteps.length + 1, this.state.fullServiceSteps);
      }
    else {
      if (normalized) {
        return 100.0 * (completedSteps.length / forgivenessSteps.length);
      } else {
        return Math.min(completedSteps.length + 1, forgivenessSteps.length);
      }
    }
  };

  getCurrentStep = () => {
    const { permissions } = this.props;
    const forgivenessSteps = permissions.forgivenessSteps.filter(
      (a) => a.id !== 'Overview' && a.id !== 'BtsReview'
    );
    const currentSteps = permissions.forgivenessSteps.filter(
      (a) => a.id !== 'Overview' && a.id !== 'BtsReview' && a.stepCurrent
    );
    if (permissions.isFullService && !permissions.inAuth) {
      return 100.0 * (1.0 / this.state.fullServiceSteps);
    } else {
      return 100.0 * (currentSteps.length / forgivenessSteps.length);
    }
  };

  getCurrentStepPath = () => {
    const { permissions } = this.props;

    const currentSteps = permissions.forgivenessSteps.filter(
      (a) => a.id !== 'Overview' && a.id !== 'BtsReview' && a.stepCurrent
    );

    let stepPaths = [];
    currentSteps.forEach((step) => {
      stepPaths.push(step.stepPath);
    });
    //return an array since we can have multiple current steps.
    return stepPaths;
  };

  onCurrentStep = () => {
    let steps = this.getCurrentStepPath();
    var retVal = false;
    steps.forEach((step) => {
      if (retVal === false) {
        retVal = this.state.currentURI.includes(step);
      }
    });
    return retVal;
  };

  getLockedSteps = () => {
    return 100.0 - this.getCompletedSteps(true) - this.getCurrentStep();
  };

  render() {
    const { permissions, removeContainer } = this.props;
    if (
      permissions.clientStatus === 'CRET' ||
      !permissions.isFullService ||
      permissions.isFullService
    ) {
      if (this.state.redirectURI && this.state.redirectURI.length > 0) {
        return <Redirect to={this.state.redirectURI} />;
      } else {
        if (
          safeAccess(permissions, 'forgivenessSteps') &&
          safeAccess(permissions, 'forgivenessSteps').length > 1
        ) {
          return (
            <>
              {removeContainer && (
                <Card>
                  <CardBody>
                    <CardText>
                      <Header2>
                        Your Forgiveness Progress : {this.getCompletedSteps(false)} /{' '}
                        {this.getTotalSteps()}
                      </Header2>
                      <StyleBar
                        categories={[
                          {
                            brand: 'success',
                            name: 'success',
                            title: 'Completed',
                            value: this.getCompletedSteps(true),
                          },

                          {
                            brand: 'info',
                            name: 'info',
                            title: 'Current',
                            value: this.getCurrentStep(),
                          },
                          {
                            brand: 'warning',
                            name: 'warning',
                            title: 'Locked',
                            value: this.getLockedSteps(),
                          },
                        ]}
                      />
                      <div className="row col-12">
                        <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                          <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                        </div>
                        <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                          <InputWrapper>
                            {!this.onCurrentStep() && (
                              <Button brand="primary" onClick={this.goToCurrentStep}>
                                {'Go to current step'}
                              </Button>
                            )}
                          </InputWrapper>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              )}
              {!removeContainer && (
                <DashboardSection>
                  <Card>
                    <CardBody>
                      <CardText>
                        <Header2>
                          Your Forgiveness Progress : {this.getCompletedSteps(false)} /{' '}
                          {this.getTotalSteps()}
                        </Header2>
                        <StyleBar
                          categories={[
                            {
                              brand: 'success',
                              name: 'success',
                              title: 'Completed',
                              value: this.getCompletedSteps(true),
                            },

                            {
                              brand: 'info',
                              name: 'info',
                              title: 'Current',
                              value: this.getCurrentStep(),
                            },
                            {
                              brand: 'warning',
                              name: 'warning',
                              title: 'Locked',
                              value: this.getLockedSteps(),
                            },
                          ]}
                        />
                        <div className="row col-12">
                          <div className="col-8">
                            <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                          </div>
                          <div className="col-4">
                            <InputWrapper>
                              {!this.onCurrentStep() && (
                                <Button brand="info" onClick={this.goToCurrentStep}>
                                  {'Go to current step'}
                                </Button>
                              )}
                            </InputWrapper>
                          </div>
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </DashboardSection>
              )}
            </>
          );
        } else {
          return <></>;
        }
      }
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ForgivenessProgress));
