import React from 'react';
import t from 'prop-types';
import {
  Form,
  InputAddon,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputNumeric,
  InputRadio,
  InputWrapper,
  Button,
} from 'fiducius-ui';

import { Fade } from '../../routing';

const QualQuestions = ({ id, handleChange, data, errors, onSubmitClick }) => {
  return (
    <Form id={id} handleChange={handleChange}>
      <InputWrapper error={!!errors.salary}>
        <InputLabel>Annual Salary</InputLabel>
        <InputError>{errors.salary}</InputError>
        <InputGroup affordance="medium">
          <InputAddon>$</InputAddon>
          <InputNumeric name="salary" step="1" min={0} defaultValue={data.salary} />
          <InputAddon>.00</InputAddon>
        </InputGroup>
      </InputWrapper>
      <InputWrapper error={!!errors.loanBalance}>
        <InputLabel>Federal Student Loan Balance</InputLabel>
        <InputError>{errors.loanBalance}</InputError>
        <InputGroup affordance="medium">
          <InputAddon>$</InputAddon>
          <InputNumeric name="loanBalance" step="1" min={0} defaultValue={data.loanBalance} />
          <InputAddon>.00</InputAddon>
        </InputGroup>
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Do you have parent plus loans in your name?</InputLabel>
        <InputRadio name="ppLoan" value="Y" label="Yes" defaultChecked={data.ppLoan === 'Y'} />
        <InputRadio name="ppLoan" value="N" label="No" defaultChecked={data.ppLoan === 'N'} />
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Are you married?</InputLabel>
        <InputRadio
          name="maritalStatus"
          value="Y"
          label="Yes"
          defaultChecked={data.maritalStatus === 'Y'}
        />
        <InputRadio
          name="maritalStatus"
          value="N"
          label="No"
          defaultChecked={data.maritalStatus === 'N'}
        />
      </InputWrapper>
      <InputWrapper error={!!errors.householdSize}>
        <InputLabel htmlFor="householdSize">
          How many people are there in your household?
        </InputLabel>
        <InputError>{errors.householdSize}</InputError>
        <InputGroup affordance="small">
          <InputNumeric name="householdSize" min={1} defaultValue={data.householdSize} />
        </InputGroup>
        <InputHelp>
          Household size always includes you, spouse, and children (including unborn children who
          will be born during the year). Household size also includes other people that live with
          you now that are assisting at least 50% of the household expenses or that are receiving
          50% or more in support. Your household size may be different from the number of exemptions
          you claim on your federal income tax return.
        </InputHelp>
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Do you plan on going back to school?</InputLabel>
        <InputRadio name="bts" value="Y" label="Yes" defaultChecked={data.bts === 'Y'} />
        <InputRadio name="bts" value="N" label="No" defaultChecked={data.bts === 'N'} />
      </InputWrapper>
      <Fade id="showSubmitGate" show={true}>
        <Button className="col-4" onClick={onSubmitClick} brand="primary" name="submitQuestions">
          Submit
        </Button>
      </Fade>
    </Form>
  );
};

QualQuestions.propTypes = {
  id: t.string,
  handleChange: t.func.isRequired,
  data: t.object,
  errors: t.object,
  onSubmitClick: t.func.isRequired,
};

export default QualQuestions;
