import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import Messages from '../components/messages';
import { loadMessage, messagesLoadResource } from '../redux/operations';

const MessageDiv = ({ loadMessage, messageType, showModal, modalMessage, data = {} }) => {
  useEffect(() => {
    loadMessage(messageType);
  }, []);

  const messageObject = getMessageObject();
  const contentType = messageObject && messageObject.contentType;
  const avatarLink = messageObject && messageObject.avatarLink;
  const messageContent = messageObject && messageObject.messageContent;
  const showAvatar = avatarLink && avatarLink.length > 0;
  const showMessage = messageContent && messageContent.length > 0;
  const displayContent = showAvatar || showMessage;

  if (displayContent && modalMessage) {
    showModal();
  }

  return (
    <>
      {displayContent && (
        <div>
          <Messages
            noPadding={true}
            messageType={messageType}
            contentType={contentType}
            avatarLink={avatarLink}
            messageContent={messageContent}
            showAvatar={showAvatar}
            showMessage={showMessage}
          />
        </div>
      )}
    </>
  );

  function getMessageObject() {
    const dataObject = data && data.length > 0 && data.find((a) => a.id === messageType);
    const messageObject =
      dataObject && Object.keys(dataObject).length > 0
        ? dataObject.messages && dataObject.messages.find((a) => a.id === messageType)
        : undefined;
    return messageObject;
  }
};

const mapStateToProps = (state) => ({
  data: Object.values(state.messages.cache),
});

MessageDiv.propTypes = {
  load: t.func.isRequired,
  loadMessage: t.func.isRequired,
  messageType: t.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(messagesLoadResource(id));
  },
  loadMessage: async (id) => {
    await dispatch(loadMessage(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDiv);
