import { FormValidator } from '../../utils';

export class EmpCertApprovalValidator extends FormValidator {
  email(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    if (validator.value !== null && validator.value !== undefined) {
      validator.isValidEmail();
    }
  }

  emailNotQualified(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    if (validator.value !== null && validator.value !== undefined) {
      validator.isValidEmail();
    }
  }

  employerName(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  ein(validator) {
    validator.isRequired();
    if (validator.value !== undefined && validator.value !== '') {
      validator.hasMaxLength(10);
      validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
    }
  }

  address1(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  address2(validator) {
    validator.hasMaxLength(250);
  }

  city(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  state(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  zipCode(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  employerWebsite(validator) {
    validator.hasMaxLength(250);
  }

  employmentBeginDate(validator) {
    validator.isRequired();
    if (validator.value && validator.value !== '') {
      validator.validDate();
    }
  }

  employmentHoursPerWeek(validator) {
    validator.isRequired();
    validator.validNumber();
    validator.isGreaterThan(0);
  }

  approverName(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  approverTitle(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  approverPhone(validator) {
    validator.isRequired();
    validator.isValidPhoneNumber();
  }

  phoneExtension(validator) {
    validator.hasMaxLength(250);
  }

  employmentStatus(validator) {
    validator.isRequired();
  }
}
