import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Loader,
  InputCheckbox,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { Fade } from '../../routing';
import { connect } from 'react-redux';

const TextLeft = styled.div`
  text-align: left;
`;

const StyledCheckbox = styled.div`
  & > label.input-checkbox {
    margin-bottom: 0;
    color: ${(p) => p.theme.textPrimary};
  }
`;

const EmploymentApproverForm = ({
  data = {},
  errors = {},
  onChange,
  onSubmit,
  isLoading,
  employers = {},
  stepState,
  isReducedIDREmpCert,
}) => {
  const getForm = () => {
    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        {Object.keys(employers).map((key) => {
          return (
            <div key={key}>
              {employers[key].empcertNeeded && (
                <div className="row" key={key}>
                  <div className="col-12 col-md-12">
                    {approverEmailFromEligFile(key) && (
                      <p>
                        {' '}
                        This authorized official was provided from an eligibility file. Click Save
                        to continue.{' '}
                      </p>
                    )}
                    {!approverEmailFromEligFile(key) && approverEmailFromPartnerSetup(key) && (
                      <p>
                        {' '}
                        This authorized official was provided within the partnership setup. Click
                        Save to continue.{' '}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-12">
                    <InputWrapper error={errors && errors[`empcertApproverEmail_${key}`]}>
                      <InputLabel htmlFor={`empcertApproverEmail_${key}`}>
                        Authorized Official's Email for <b>{employers[key].employerName}</b>
                      </InputLabel>
                      <InputError>Please enter a valid email address</InputError>
                      <InputGroup>
                        <InputText
                          name={`empcertApproverEmail_${key}`}
                          defaultValue={getEmpcertApproverEmail(key)}
                          disabled={
                            approverEmailFromEligFile(key) || approverEmailFromPartnerSetup(key)
                          }
                        />
                      </InputGroup>
                      <StyledCheckbox>
                        <InputCheckbox
                          name={`empcertApproverUnknown_${key}`}
                          defaultChecked={employers[key].empcertApproverUnknown}
                          label="I do not know my authorized official's email"
                          disabled={
                            approverEmailFromEligFile(key) || approverEmailFromPartnerSetup(key)
                          }
                        />
                      </StyledCheckbox>
                    </InputWrapper>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </Form>
    );
  };

  const enableSubmit = () => {
    return !foundErrors();
  };

  const foundErrors = () => {
    var errorCount = 0;

    // input text box must be email address OR checkbox is checked and email field is null
    Object.keys(employers).forEach((key) => {
      if (errors[`empcertApproverEmail_${key}`] === true) {
        errorCount++;
      }
    });

    return errorCount > 0;
  };

  const submit = (e) => {
    e.preventDefault();
    if (!isLoading) {
      onSubmit(stepState);
    }
  };

  const approverEmailFromEligFile = (key) => {
    return employers[key].eligfileEmpcertApproverEmail != null;
  };

  const approverEmailFromPartnerSetup = (key) => {
    return isReducedIDREmpCert && employers[key].emplifyAuthorizedOfficialEmail != null;
  };

  const getEmpcertApproverEmail = (key) => {
    let empcertApproverEmail = '';

    if (employers) {
      if (employers[key].eligfileEmpcertApproverEmail !== null) {
        empcertApproverEmail = employers[key].eligfileEmpcertApproverEmail;
      } else if (employers[key].emplifyAuthorizedOfficialEmail !== null) {
        empcertApproverEmail = employers[key].emplifyAuthorizedOfficialEmail;
      } else {
        empcertApproverEmail =
          employers[key].empcertApproverEmail === null ? '' : employers[key].empcertApproverEmail;
      }

      return empcertApproverEmail;
    }

    return empcertApproverEmail;
  };

  return (
    <Card brand={errors.generalErrors || undefined}>
      <CardHeader>Employer List</CardHeader>
      <CardBody>
        {errors.generalErrors &&
          errors.generalErrors.map((e, i) => (
            <Alert key={i} brand="danger">
              {e.detail}
            </Alert>
          ))}

        <TextLeft>{getForm()}</TextLeft>

        <FlexBetween>
          <Fade id="loading_fade" show={enableSubmit()}>
            <Button brand={'success'} onClick={(e) => submit(e)}>
              {isLoading ? <Loader variant="push" size={1.5} /> : 'Save'}
            </Button>
          </Fade>
          <Fade id="show_fade" show={!isLoading && foundErrors()}>
            Please fix the errors above.
          </Fade>
        </FlexBetween>
      </CardBody>
    </Card>
  );
};

EmploymentApproverForm.propTypes = {
  data: t.object,
  errors: t.object,
  isLoading: t.bool,
  isOpen: t.bool,
  onChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
  isReducedIDREmpCert: t.bool,
};
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(EmploymentApproverForm);
