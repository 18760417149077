import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import {
  Button,
  Form,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  InputError,
  InputSelect,
  AsyncLoader,
  CardBody,
  CardFooter,
  Loader,
  CardText,
} from 'fiducius-ui';
import { safeAccess, debounce, stringIsNullOrEmpty } from '../../utils';
import { withPermissions, Fade } from '../../routing';
import { getToken, authLoadPermissions, getUserId, LoaderWrapper } from '../../auth';
import { todosLoadCollection } from '../../todos';
import { mergeRequestStatuses } from '../../utils';
import { FlexBetween } from '../../root';
import {
  securityQuestionsSetupClearForm,
  securityQuestionsSetupCopyResourceToForm,
  securityQuestionsSetupHandleFormChange,
  securityQuestionsSetupLoadResource,
  securityQuestionsSetupCreateResource,
} from '../redux/operations';

class SecurityQuestionsForm extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    token: t.any,
    data: t.object,
    form: t.object,
    errors: t.object,
    request: t.object,
    saveRequest: t.object,
    onLoad: t.func,
    clearForm: t.func,
    saveSecurityQuestions: t.func,
    handleForm: t.func,
    hydrateForm: t.func,
    group1Questions: t.object.isRequired,
    group2Questions: t.object.isRequired,
    group3Questions: t.object.isRequired,
    onSubmit: t.func.isRequired,
    showSaveButton: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      stateSecurityQuestion1: 0,
      stateSecurityQuestion2: 0,
      stateSecurityQuestion3: 0,
    };
  }

  async componentDidMount() {
    this.props.clearForm();
    await this.props.onLoad(this.props.id);
    this.props.handleForm({ ...this.props.form, ...this.props.data }, [
      'securityQuestion1',
      'securityQuestion2',
      'securityQuestion3',
      'securityResponse1',
      'securityResponse2',
      'securityResponse3',
    ]);
    const { data, showSaveButton } = this.props;

    this.setState({
      ...this.state,
      stateSecurityQuestion1: data.securityQuestion1,
      stateSecurityQuestion2: data.securityQuestion2,
      stateSecurityQuestion3: data.securityQuestion3,
      stateShowSaveButton: showSaveButton,
    });
  }

  onChange = debounce((securityQuestionsSetup, id, attributes) => {
    this.props.handleForm({ ...this.props.form, ...securityQuestionsSetup }, [attributes]);
    this.setState({
      ...this.state,
      stateShowSaveButton: false,
    });
  }, 250);

  onCreate = () => this.props.saveSecurityQuestions();

  enableSubmit = () => {
    const { stateShowSaveButton } = this.state;
    return (this.checkNoMissingData() && this.checkNoErrors()) || stateShowSaveButton;
  };

  checkNoMissingData = () => {
    const { data = {}, form } = this.props;

    let missingData = false;
    missingData =
      !stringIsNullOrEmpty(form.securityResponse1) &&
      !stringIsNullOrEmpty(form.securityResponse2) &&
      !stringIsNullOrEmpty(form.securityResponse3) &&
      form.securityQuestion1 !== 0 &&
      form.securityQuestion2 !== 0 &&
      form.securityQuestion3 !== 0;

    return missingData;
  };

  checkNoErrors = () => {
    const { data = {}, errors = {} } = this.props;

    return (
      !errors.securityResponse1 &&
      !errors.securityResponse2 &&
      !errors.securityResponse3 &&
      !errors.securityQuestion1 &&
      !errors.securityQuestion2 &&
      !errors.securityQuestion3
    );

    //return true;
  };

  submit = async (e) => {
    e.preventDefault();
    if (!this.props.request.isLoading && !this.props.saveRequest.isLoading) {
      await this.props.saveSecurityQuestions();
      this.props.onSubmit();
    }
  };

  render() {
    const {
      data,
      form,
      errors,
      request,
      saveRequest,
      group1Questions,
      group2Questions,
      group3Questions,
    } = this.props;

    const { stateShowSaveButton } = this.state;

    if (request.isLoading) {
      return (
        <LoaderWrapper>
          <Loader variant="atom" size={3} />
        </LoaderWrapper>
      );
    }

    return (
      <div className="row">
        <div className="col-12">
          <AsyncLoader request={request}>
            <CardBody>
              <CardText>
                <p>
                  Keeping your data secure is one of our top priorities. As an extra layer of
                  protection for your information, we ask that you complete security questions and
                  answers for your Fiducius account.
                </p>
                <p>
                  Please select the security questions from the available options and provide
                  answers to complete your account setup. The answers you provide will allow access
                  to your account and unlock it if you forget your password.
                </p>
              </CardText>
              <Form id="create" handleChange={this.onChange} defaults={data}>
                <InputWrapper error={!!errors.securityQuestion1}>
                  <InputLabel>Question 1:</InputLabel>
                  <InputError>{safeAccess(errors, 'securityQuestion1.detail')}</InputError>
                  <InputGroup>
                    <InputSelect
                      name="securityQuestion1"
                      options={group1Questions}
                      value={form.securityQuestion1}
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper
                  error={
                    !!errors.securityResponse1 ||
                    (!stateShowSaveButton && form.securityResponse1 === null)
                  }
                >
                  <InputError>{safeAccess(errors, 'securityResponse1.detail')}</InputError>
                  <InputGroup>
                    <InputText
                      name="securityResponse1"
                      defaultValue={form.securityResponse1}
                      placeholder={
                        this.state.stateSecurityQuestion1 === 0
                          ? ''
                          : !stateShowSaveButton && form.securityResponse1 === null
                          ? ''
                          : '*********'
                      }
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.securityQuestion2}>
                  <InputLabel>Question 2:</InputLabel>
                  <InputError>{safeAccess(errors, 'securityQuestion2.detail')}</InputError>
                  <InputGroup>
                    <InputSelect
                      name="securityQuestion2"
                      options={group2Questions}
                      value={form.securityQuestion2}
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper
                  error={
                    !!errors.securityResponse2 ||
                    (!stateShowSaveButton && form.securityResponse2 === null)
                  }
                >
                  <InputError>{safeAccess(errors, 'securityResponse2.detail')}</InputError>
                  <InputGroup>
                    <InputText
                      name="securityResponse2"
                      defaultValue={form.securityResponse2}
                      placeholder={
                        this.state.stateSecurityQuestion2 === 0
                          ? ''
                          : !stateShowSaveButton && form.securityResponse2 === null
                          ? ''
                          : '*********'
                      }
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.securityQuestion3}>
                  <InputLabel>Question 3:</InputLabel>
                  <InputError>{safeAccess(errors, 'securityQuestion3.detail')}</InputError>
                  <InputGroup>
                    <InputSelect
                      name="securityQuestion3"
                      options={group3Questions}
                      value={form.securityQuestion3}
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper
                  error={
                    !!errors.securityResponse3 ||
                    (!stateShowSaveButton && form.securityResponse3 === null)
                  }
                >
                  <InputError>{safeAccess(errors, 'securityResponse3.detail')}</InputError>
                  <InputGroup>
                    <InputText
                      name="securityResponse3"
                      defaultValue={form.securityResponse3}
                      placeholder={
                        this.state.stateSecurityQuestion3 === 0
                          ? ''
                          : !stateShowSaveButton && form.securityResponse3 === null
                          ? ''
                          : '*********'
                      }
                    />
                  </InputGroup>
                </InputWrapper>
              </Form>
            </CardBody>
            <CardFooter>
              <FlexBetween>
                <Fade show={this.enableSubmit() && !stateShowSaveButton}>
                  <Button brand="success" onClick={(e) => this.submit(e)}>
                    {saveRequest.isLoading ? (
                      <Loader variant="push" size={1.5} />
                    ) : (
                      'Save Security Questions'
                    )}
                  </Button>
                </Fade>
                <Fade
                  show={
                    !saveRequest.isLoading && !this.checkNoMissingData() && !stateShowSaveButton
                  }
                >
                  Please enter the missing information above.
                </Fade>
                <Fade
                  show={
                    !saveRequest.isLoading &&
                    this.checkNoMissingData() &&
                    !this.checkNoErrors() &&
                    !stateShowSaveButton
                  }
                >
                  Please fix the errors above.
                </Fade>
              </FlexBetween>
            </CardFooter>
          </AsyncLoader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.securityQuestionsSetup;
  let id = getUserId(state);
  return {
    id: id,
    token: getToken(state),
    request: requests.loadResource,
    saveRequest: mergeRequestStatuses([
      requests.createResource,
      state.todos.requests.loadCollection,
      state.auth.requests.loadPermissions,
    ]),
    form: form,
    errors: errors,
    data: safeAccess(cache, '[' + id + ']') || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: async (id) => {
    await dispatch(securityQuestionsSetupLoadResource(id));
  },
  clearForm: () => dispatch(securityQuestionsSetupClearForm()),
  saveSecurityQuestions: async () => {
    await dispatch(securityQuestionsSetupCreateResource());
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  handleForm: (securityQuestionsSetup, attributes) =>
    dispatch(securityQuestionsSetupHandleFormChange(securityQuestionsSetup, attributes)),
  hydrateForm: async (id) => await dispatch(securityQuestionsSetupCopyResourceToForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(SecurityQuestionsForm));
