import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { withPermissions } from '../../../routing';
import { AsyncLoader, DashboardSection, Header2 } from 'fiducius-ui';
import { MessageTile } from '../../../messages';

import { getUserId } from '../../../auth';

import {
  tuitionLoadResource,
  tuitionCopyResourceToForm,
  tuitionHandleFormChange,
  tuitionUpdateResource,
} from '../redux/operations';
import { getTuitionData } from '../redux/selectors';

import { documentsLoadTrFaq } from '../../../documents';

import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import OverviewSummary from '../components/overview-summary';
import OverviewInstitutions from '../components/overview-institutions';
import RequiredInformation from '../components/required-information';

class MyTuition extends React.Component {
  static propTypes = {
    data: t.object,
    institutionData: t.object,
    id: t.string.isRequired,
    load: t.func.isRequired,
    loadInstitution: t.func.isRequired,
    request: t.object.isRequired,
    updateRequest: t.object.isRequired,
    institutionRequest: t.object.isRequired,
    errors: t.object,
    getFormData: t.func.isRequired,
    updateFormState: t.func.isRequired,
    saveData: t.func.isRequired,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id, load } = this.props;
    load(id);
  }

  render() {
    const { request, permissions = {} } = this.props;
    return (
      <>
        <DashboardSection title="My Tuition Reimbursement">
          <Header2>Maximize the value of your education with tuition reimbursement.</Header2>
          <div id="messageSection">
            <MessageTile messageType={'Tuition_Reimbursement'} />
          </div>
          <AsyncLoader request={request}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <OverviewSummary />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <RequiredInformation />
              </div>
              {!permissions.homeAddressIsMissing && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <OverviewInstitutions />
                </div>
              )}
            </div>
          </AsyncLoader>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors } = state.tuition;
  return {
    data: getTuitionData(state),
    id: getUserId(state),
    errors: errors,
    request: state.tuition.requests.loadResource,
    updateRequest: state.tuition.requests.updateResource,
    institutionRequest: state.tuitionInstitution.requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    dispatch(tuitionInstitutionLoadCollection());
    await dispatch(tuitionLoadResource(id));
    dispatch(tuitionCopyResourceToForm(id));
  },
  updateFormState: (data, attributes) => dispatch(tuitionHandleFormChange(data, attributes)),
  saveData: async (id) => {
    await dispatch(tuitionUpdateResource(id));
  },
  getFAQ: () => {
    return dispatch(documentsLoadTrFaq());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyTuition));
