//CalculatorID, LoanBalanceMin, LoanBalanceMax, RepaymentYears, RepaymentMonths
// const ConsolidationCalculator = [
//   [1, 0.0, 7499.99, 10, 120],
//   [2, 7500.0, 9999.99, 12, 144],
//   [3, 10000.0, 19999.99, 15, 180],
//   [4, 20000.0, 39999.99, 20, 240],
//   [5, 40000.0, 59999.99, 25, 300],
//   [7, 60000.0, 999999.99, 30, 360],
// ];

//Household size, Lower48, Alaska, Hawaii
const PovertyGuidelines = [
  [1, 12760.0, 15950.0, 14680.0],
  [2, 17240.0, 21550.0, 19830.0],
  [3, 21720.0, 27150.0, 24980.0],
  [4, 26200.0, 32750.0, 30130.0],
  [5, 30680.0, 38350.0, 35280.0],
  [6, 35160.0, 43950.0, 40430.0],
  [7, 39640.0, 49550.0, 45580.0],
  [8, 44120.0, 55150.0, 50730.0],
  [9, 48600.0, 60750.0, 55880.0],
  [10, 53080.0, 66350.0, 61030.0],
];

const runQuickQualifier = (
  salary,
  loanAmount,
  hasParentPlus,
  isMarried,
  householdSize,
  yearFirstLoan,
  bts,
  nPer
) => {
  let intRate = 6.0;
  // let payPeriods = 26;
  let retirementPercentage = 0.0;
  let perCt = 0.0;

  let totalDeductions = retirementPercentage / 100.0;
  let netSalary = parseFloat(salary) * (1.0 - totalDeductions);
  // let netSalaryNoRetire = parseFloat(salary);
  // let retireAmount = netSalaryNoRetire * totalDeductions;
  let totalInterest = (loanAmount * intRate) / 100.0;

  if (loanAmount) {
    loanAmount = parseFloat(loanAmount);
  } else {
    loanAmount = 0.0;
  }

  if (nPer) {
    nPer = parseInt(nPer);
  } else {
    nPer = 120;
  }

  if (hasParentPlus === 'Y') {
    perCt = 0.2;
  } else if (yearFirstLoan > 2007) {
    perCt = 0.1;
  } else if (yearFirstLoan <= 2007 && isMarried === 'N') {
    perCt = 0.1;
  } else {
    perCt = 0.15;
  }

  let // existLoanAmount,
    standRate,
    standRepay,
    standTotal,
    // consolRate,
    // consolRepay,
    // consolMonths,
    // consolTotal,
    ibRepay,
    ibTotal;

  // existLoanAmount = loanAmount;

  if (loanAmount === 0) {
    standRate = 0.0;
    standRepay = 0.0;
    standTotal = 0.0;
    // consolRate = 0.0;
    // consolRepay = 0.0;
    // consolMonths = 0;
    // consolTotal = 0.0;
  } else {
    if (nPer > 120) {
      standRate = roundToFraction((totalInterest * 100.0) / loanAmount, 8, 1) / 100.0;
    } else {
      standRate = totalInterest / loanAmount;
    }
    standRepay = calculatePayment(standRate / 12.0, nPer, loanAmount);
    standTotal = standRepay * nPer;

    // consolRate = round8(totalInterest / loanAmount);
    // consolMonths = getConsolidationMonths(loanAmount);
    // consolRepay = calculatePayment(consolRate / 12.0, consolMonths, loanAmount);
    // consolTotal = consolRepay * consolMonths;
  }

  if (
    ((netSalary - getPovertyGuidelineAmount(householdSize, 'Lower48')) * 1.5 * 0.15) / 12.0 <
    0.0
  ) {
    ibRepay = 0.0;
  } else {
    ibRepay =
      ((netSalary - getPovertyGuidelineAmount(householdSize, 'Lower48') * 1.5) * perCt) / 12.0;
  }
  ibTotal =
    (netSalary - getPovertyGuidelineAmount(householdSize, 'Lower48') * 1.5) * perCt * (nPer / 12.0);

  ibTotal = ibTotal < 0 ? 0 : ibTotal;

  let lbf = standTotal - ibTotal;

  let message = '';
  if (lbf > 0) {
    switch (perCt) {
      case 0.1:
        message += 'a pay as you earn';
        break;
      case 0.15:
        message += 'an income-based';
        break;
      case 0.2:
        message += 'an income-contingent';
        break;
      default:
        message += 'an income-based';
        break;
    }
  } else {
    message = 'DNQ';
    lbf = 0;
  }

  return { lbf, perCt, ibRepay, message };
};

const roundToFraction = (decimal, fraction, direction) => {
  let retVal = decimal * fraction;

  if (direction === 1) {
    retVal = Math.ceil(retVal);
  } else {
    retVal = Math.floor(retVal);
  }

  return retVal / fraction;
};

const calculatePayment = (rate, periods, principal) => {
  let pmt =
    (principal * (rate * Math.pow(1.0 + rate, periods))) / (Math.pow(1.0 + rate, periods) - 1.0);
  return parseFloat(pmt.toFixed(9));
};

// const round8 = (decimal) => {
//   let num = parseInt(decimal) * 100;
//   let intRate = decimal * 100;
//   let fract = intRate - num;
//   let newFract = 0.0;

//   if (fract > 0.0) {
//     if (fract <= 0.125) {
//       newFract = 0.125;
//     } else if (fract <= 0.25) {
//       newFract = 0.25;
//     } else if (fract <= 0.375) {
//       newFract = 0.375;
//     } else if (fract <= 0.5) {
//       newFract = 0.5;
//     } else if (fract <= 0.625) {
//       newFract = 0.625;
//     } else if (fract <= 0.75) {
//       newFract = 0.75;
//     } else if (fract <= 0.875) {
//       newFract = 0.875;
//     } else if (fract < 1.0) {
//       newFract = 1.0;
//     }
//   }

//   return (num + newFract) / 100.0;
// };

// const getConsolidationMonths = (loanAmount) => {
//   let consolidationMonths = 0;
//   if (loanAmount >= 1000000.0) {
//     loanAmount = 999999.99;
//   }

//   let index = 0;
//   do {
//     if (
//       ConsolidationCalculator[index][1] <= loanAmount &&
//       ConsolidationCalculator[index][2] >= loanAmount
//     ) {
//       consolidationMonths = ConsolidationCalculator[index][4];
//     }
//     index++;
//   } while (consolidationMonths === 0);

//   return consolidationMonths;
// };

const getPovertyGuidelineAmount = (householdSize, location) => {
  let locationIndex = 1;
  switch (location) {
    case 'AL':
      locationIndex = 2;
      break;
    case 'HI':
      locationIndex = 3;
      break;
    default:
      locationIndex = 1;
      break;
  }

  if (householdSize > 10) {
    householdSize = 10;
  }
  return PovertyGuidelines[householdSize - 1][locationIndex];
};

export default runQuickQualifier;
