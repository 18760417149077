import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  InputAddon,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputNumeric,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { withPermissions, Fade } from '../../routing';
import { convertIsoToSlash, safeAccess, stringIsNullOrEmpty } from '../../utils';

const TextLeft = styled.div`
  text-align: left;
`;

const InputAddonStyled = styled(InputAddon)`
  &.btn-addon {
    align-items: center;
    display: inline;
  }
`;

class AccountForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    servicers: t.object,
    permissions: t.object,
    accounts: t.object,
    token: t.any,
    allowedAccountTypes: t.object,
    hasContributionAccountTypes: t.bool,
    hasTRAccountTypes: t.bool,
    contributionData: t.object,
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
    this.state = { contbReverification: '' };
  }

  componentDidMount() {
    this.props.data.error = '';
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }

    if (
      !prevProps.errors.generalErrors &&
      this.props.errors.generalErrors &&
      this.modalRef.current !== null
    ) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  handleContributionChanges = () => {
    if (this.props.data.contributionStatus === 'APPROVE') {
      this.setState({
        contbReverification:
          'Changing the Monthly Payment and/or Final Due Date will require re-validation of your contribution.',
      });
    }
  };

  getForm() {
    const {
      action,
      contributionData,
      data,
      errors,
      onChange,
      onSubmit,
      servicers = {},
      allowedAccountTypes,
      hasContributionAccountTypes,
      hasTRAccountTypes,
      permissions,
    } = this.props;

    if (permissions.showForgiveness) {
      data.monthlyPayment = contributionData.idrRepayment;
      data.dueDate = contributionData.doeRepayDate;
    }

    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <InputWrapper error={!!errors.loanDescription}>
          <InputLabel
            htmlFor="loanDescription"
            required={data.loanTypeCode === 'RMBRS' || hasContributionAccountTypes}
          >
            Account Nickname
          </InputLabel>
          <InputError>{safeAccess(errors, 'loanDescription.detail')}</InputError>
          <InputGroup>
            <InputText
              inputRef={this.firstInput}
              placeholder="My Account Nickname"
              name="loanDescription"
              defaultValue={data.loanDescription}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.loanServicerId}>
          <InputLabel htmlFor="loanServicerId" required>
            Account Servicer / Bank
          </InputLabel>
          <InputError>{safeAccess(errors, 'loanServicerId.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="loanServicerId"
              options={servicers}
              value={data.loanServicerId || ''}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.loanTypeCode}>
          <InputLabel htmlFor="loanTypeCode" required>
            Account Type
          </InputLabel>
          <InputError>{safeAccess(errors, 'loanTypeCode.detail')}</InputError>
          {hasContributionAccountTypes && (
            <InputHelp>
              If you have multiple loans under one account number, please select "Direct Subsidized
              Consolidated" and enter your total account balance with that loan servicer. Your
              contribution payment will be applied to all loans under the account number entered.
            </InputHelp>
          )}
          <InputGroup>
            <InputSelect
              name="loanTypeCode"
              options={allowedAccountTypes}
              value={data.loanTypeCode}
            />
          </InputGroup>
          {hasContributionAccountTypes && !hasTRAccountTypes && (
            <InputHelp>^ - Account types eligible for contribution</InputHelp>
          )}
          {!hasContributionAccountTypes && hasTRAccountTypes && (
            <InputHelp>+ - Account type used for reimbursement account</InputHelp>
          )}
          {hasContributionAccountTypes && hasTRAccountTypes && (
            <InputHelp>
              ^ - Account types eligible for contribution
              <br />+ - Account type used for reimbursement account
            </InputHelp>
          )}
        </InputWrapper>
        <InputWrapper error={!!errors.accountNumber}>
          <InputLabel
            htmlFor="accountNumber"
            required={data.loanTypeCode === 'RMBRS' || hasContributionAccountTypes}
          >
            Account Number
          </InputLabel>
          <InputError>{safeAccess(errors, 'accountNumber.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputText name="accountNumber" defaultValue={data.accountNumber} />
          </InputGroup>
        </InputWrapper>

        <Fade
          show={
            data.loanTypeCode !== undefined &&
            data.loanTypeCode !== '' &&
            data.loanTypeCode !== '529PN' &&
            data.loanTypeCode !== 'RMBRS'
          }
        >
          <div className="row">
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <InputWrapper error={!!errors.initialLoanBalance}>
                <InputLabel htmlFor="initialLoanBalance" required>
                  Initial Loan Balance
                </InputLabel>
                <InputError>{safeAccess(errors, 'initialLoanBalance.detail')}</InputError>
                <InputGroup affordance="full">
                  <InputAddon>$</InputAddon>
                  <InputNumeric name="initialLoanBalance" defaultValue={data.initialLoanBalance} />
                  <InputAddon>.00</InputAddon>
                </InputGroup>
                <InputHelp>
                  If you do not know your initial loan balance, please input your current loan
                  balance.
                </InputHelp>
              </InputWrapper>
            </div>
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <InputWrapper error={!!errors.currentLoanBalance}>
                <InputLabel htmlFor="currentLoanBalance" required>
                  Current Balance
                </InputLabel>
                <InputError>{safeAccess(errors, 'currentLoanBalance.detail')}</InputError>
                <InputGroup affordance="full">
                  <InputAddon>$</InputAddon>
                  <InputNumeric name="currentLoanBalance" defaultValue={data.currentLoanBalance} />
                  <InputAddon>.00</InputAddon>
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
              <InputWrapper error={!!errors.interestRate}>
                <InputLabel htmlFor="interestRate" required>
                  Interest Rate
                </InputLabel>
                <InputError>{safeAccess(errors, 'interestRate.detail')}</InputError>
                <InputGroup affordance="full">
                  <InputNumeric name="interestRate" defaultValue={data.interestRate} />
                  <InputAddonStyled>%</InputAddonStyled>
                </InputGroup>
                <InputHelp>
                  Interest rate must be greater than 0. An average interest rate will be accepted.
                </InputHelp>
              </InputWrapper>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <InputWrapper error={!!errors.monthlyPayment}>
                <InputLabel
                  htmlFor="monthlyPayment"
                  required={
                    !stringIsNullOrEmpty(data.loanTypeCode) &&
                    allowedAccountTypes[data.loanTypeCode] !== undefined &&
                    allowedAccountTypes[data.loanTypeCode].includes('^')
                  }
                >
                  Monthly Payment
                </InputLabel>
                <InputError>{safeAccess(errors, 'monthlyPayment.detail')}</InputError>
                <InputGroup affordance="full">
                  <InputAddon>$</InputAddon>
                  <InputNumeric
                    name="monthlyPayment"
                    defaultValue={data.monthlyPayment}
                    onChange={() => this.handleContributionChanges()}
                    disabled={permissions.showForgiveness}
                  />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <InputWrapper error={!!errors.dueDate}>
                <InputLabel
                  htmlFor="dueDate"
                  required={
                    !stringIsNullOrEmpty(data.loanTypeCode) &&
                    allowedAccountTypes[data.loanTypeCode] !== undefined &&
                    allowedAccountTypes[data.loanTypeCode].includes('^')
                    //added undefined check in case we are not getting a specific type, such as OTH other loan type
                  }
                >
                  Final Due Date
                </InputLabel>
                <InputError>{safeAccess(errors, 'dueDate.detail')}</InputError>
                <InputGroup>
                  <InputMask
                    defaultValue={convertIsoToSlash(data.dueDate)}
                    mask="##/##/####"
                    name="dueDate"
                    placeholder="MM/DD/YYYY"
                    onChange={() => this.handleContributionChanges()}
                    disabled={permissions.showForgiveness}
                  />
                </InputGroup>
                <InputHelp>
                  If you do not know the final due date of your loan, please enter your next loan
                  payment date.
                </InputHelp>
              </InputWrapper>
            </div>
          </div>
        </Fade>
      </Form>
    );
  }

  checkForMultipleTRAccounts = (data, accounts) => {
    //let accountNames;
    let retVal = false;

    for (let value of Object.values(accounts)) {
      if (`${value.loanTypeCode}` === 'RMBRS') {
        retVal = true;
        //accountNames = '"'+`${value.loanDescription}` + '", ' + accountNames
        data.error =
          'cannot be a tuition reimbursement account. Please select "Cancel" and select the edit option for your current reimbursement account on file to update your information.';
      }
    }

    return retVal;
  };

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {}, hasContributionAccountTypes } = this.props;

    let missingData = false;

    if (data.loanTypeCode === 'RMBRS' || hasContributionAccountTypes) {
      missingData =
        !stringIsNullOrEmpty(data.loanDescription) &&
        !stringIsNullOrEmpty(data.accountNumber) &&
        !stringIsNullOrEmpty(data.loanServicerId) &&
        !stringIsNullOrEmpty(data.loanTypeCode);
    } else {
      missingData =
        !stringIsNullOrEmpty(data.loanServicerId) && !stringIsNullOrEmpty(data.loanTypeCode);
    }
    return missingData;
  };

  checkNoErrors = () => {
    const { data = {}, errors = {}, action, accounts } = this.props;
    let multipleTrAccounts = false;
    if (action === 'Create' && data.loanTypeCode === 'RMBRS') {
      multipleTrAccounts = this.checkForMultipleTRAccounts(data, accounts);
    }

    return (
      !multipleTrAccounts &&
      !errors.loanDescription &&
      !errors.accountNumber &&
      !errors.loanServicerId &&
      !errors.loanTypeCode &&
      !errors.initialLoanBalance &&
      !errors.currentLoanBalance &&
      !errors.interestRate &&
      !errors.monthlyPayment &&
      !errors.dueDate
    );
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { action, closeForm, errors = {}, isLoading, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Account</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            <TextLeft>{this.getForm()}</TextLeft>

            <Fade show={this.state.contbReverification !== ''}>
              <Alert brand="warning">{this.state.contbReverification}</Alert>
            </Fade>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' account'}
                </Button>
              </Fade>
              <Fade show={!isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade show={!isLoading && this.checkNoMissingData() && !this.checkNoErrors()}>
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default withPermissions(AccountForm);
