import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { AsyncLoader, Button, Card, CardBody, CardText, DashboardSection } from 'fiducius-ui';
import { mergeRequestStatuses } from '../../utils';
import { withPermissions } from '../../routing';

import { getActivePlaidConnections } from '../redux/operations';

import PlaidButton from '../components/plaid-button';

import { SharedDataConsent } from '../../legal';

import PlaidLogoLight from '../../maintenance/assets/Plaid_logo.png';
import PlaidLogoDark from '../../maintenance/assets/Plaid_logo_white.png';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlaidOverview = ({ theme, request, todos, getActivePlaidConnections, plaidData }) => {
  const [redirectUri, setRedirectUri] = useState('');

  const checkAuthorizedUseCompleted = () => {
    let retVal = false;

    // if (plaidData && plaidData.length > 0 && plaidData[0].consentComplete)
    //   {
    //     retVal = true;
    //   }

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 2020 || a.id === 6);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = true;
      }
    }
    return retVal;
  };

  const checkForReconnectTodo = () => {
    let retVal = true;

    if (todos !== undefined && todos !== null) {
      const currentToDo = todos.find((a) => a.id === 2010);
      if (currentToDo === undefined || currentToDo === null) {
        retVal = false;
      }
    }
    return retVal;
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(true);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const showPlaidConsentDialog = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    getActivePlaidConnections();
  }, [modalOpen, loadingToggle]);

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <>
        <div className="row">
          <div className="col-12 col-lg-12">
            <AsyncLoader request={request}>
              <DashboardSection>
                {theme === 'dark' && <img src={PlaidLogoDark} alt="Plaid Logo" width="250" />}
                {theme !== 'dark' && <img src={PlaidLogoLight} alt="Plaid Logo" width="250" />}
                <Card>
                  <CardBody>
                    <AsyncLoader request={request} loading={<></>}>
                      <div className="row">
                        <div className="col">
                          {checkAuthorizedUseCompleted() &&
                            plaidData &&
                            plaidData.length > 1 &&
                            !!!plaidData[0].optedOut && (
                              <>
                                {checkForReconnectTodo() && (
                                  <>
                                    <CardText>
                                      You have linked your loan servicer(s) via Plaid, but
                                      connectivity has been lost. To maintain all of your benefits,
                                      you need to re-link to your loan servicer(s), please select
                                      "Re-link Account" below.
                                    </CardText>
                                    <CardText>
                                      If you wish to link additional accounts, please select "Link
                                      Account(s)" below.
                                    </CardText>
                                  </>
                                )}
                                {!checkForReconnectTodo() && (
                                  <CardText>
                                    <p>
                                      Congratulations, you successfully linked your loan servicer(s)
                                      via Plaid!
                                    </p>
                                    <p>
                                      If you wish to link additional accounts, please select "Link
                                      Account(s)" below.
                                    </p>
                                    <p>
                                      We will reach out if there are ever steps required as it
                                      relates to your connection via Plaid.
                                    </p>
                                  </CardText>
                                )}
                              </>
                            )}
                          {checkAuthorizedUseCompleted() &&
                            plaidData &&
                            plaidData.length === 1 &&
                            !!!plaidData[0].optedOut && (
                              <CardText>
                                In order to take advantage of all benefits available to you, Plaid
                                connectivity is required. To start the process of linking your loan
                                servicer(s) via Plaid, please select "Link Account(s)" below.
                              </CardText>
                            )}
                          {checkAuthorizedUseCompleted() &&
                            plaidData &&
                            plaidData.length > 0 &&
                            !!!plaidData[0].optedOut &&
                            !!plaidData[0].consentComplete &&
                            Object.keys(plaidData).map((p, i) => {
                              return (
                                <PlaidButton
                                  plaidConnectionId={plaidData[p].id}
                                  keyId={i}
                                  setLoadingToggle={setLoadingToggle}
                                  loadingToggle={loadingToggle}
                                />
                              );
                            })}
                          {plaidData &&
                            plaidData.length > 0 &&
                            plaidData[0].optedOut &&
                            checkAuthorizedUseCompleted() && (
                              <>
                                {modalOpen && (
                                  <SharedDataConsent
                                    hideIcon={false}
                                    isOpen={modalOpen}
                                    toggleModal={toggleModal}
                                  />
                                )}
                                <CardText>
                                  <p>
                                    You previously opted to decline the Shared Data Consent. In
                                    order to take advantage of all benefits available to you, Plaid
                                    connectivity is required. To start the process of linking your
                                    loan servicer(s) via Plaid and receive your maximum benefit,
                                    please recomplete the Shared Data Consent.
                                  </p>
                                </CardText>
                                <ButtonWrapper>
                                  <Button
                                    brand="primary"
                                    size="lg"
                                    onClick={showPlaidConsentDialog}
                                  >
                                    Consent to Share Data
                                  </Button>
                                </ButtonWrapper>
                              </>
                            )}

                          {!checkAuthorizedUseCompleted() && (
                            <>
                              {modalOpen && (
                                <SharedDataConsent
                                  hideIcon={false}
                                  isOpen={modalOpen}
                                  toggleModal={toggleModal}
                                />
                              )}
                              <CardText>
                                <p>
                                  In order to take advantage of all benefits available to you, Plaid
                                  connectivity is required. To start the process of linking your
                                  loan servicer(s) via Plaid and receive your maximum benefit,
                                  please complete the Shared Data Consent.
                                </p>
                              </CardText>
                              <ButtonWrapper>
                                <Button brand="primary" size="lg" onClick={showPlaidConsentDialog}>
                                  Consent to Share Data
                                </Button>
                              </ButtonWrapper>
                            </>
                          )}
                        </div>
                      </div>
                    </AsyncLoader>
                  </CardBody>
                </Card>
              </DashboardSection>
            </AsyncLoader>
          </div>
        </div>
      </>
    );
  }
};

PlaidOverview.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    //state.todos.requests.loadCollection,
    state.plaid.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
  plaidData: Object.values(state.plaid.cache),
});

const mapDispatchToProps = (dispatch) => ({
  getActivePlaidConnections: async () => {
    await dispatch(getActivePlaidConnections());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(PlaidOverview));
