import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';

import {
  AsyncLoader,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  CardFooter,
} from 'fiducius-ui';

import { debounce, mergeRequestStatuses, safeAccess } from '../../utils';
import { todosLoadCollection } from '../../todos';
import { withPermissions } from '../../routing';

import AccountForm from '../components/account-form';
import AccountTable from '../components/account-table';
import {
  accountsClearForm,
  accountsCopyResourceToForm,
  accountsUpdateResource,
  accountsHandleFormChange,
  accountsCreateResource,
} from '../redux/operations';
import { contributionLoadResource } from '../../benefits/contribution/redux/operations';
import { getContributionData } from '../../benefits/contribution/redux/selectors';

const StyledButton = styled(Button)`
  margin-right: 1em;
  margin-top: 1em;
`;

const StyledCardText = styled(CardText)`
  text-align: left;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const PaddedBadge = styled(Badge)`
  margin-right: 0.45em;
  font-size: 0.75em;
  top: -0.75rem;
`;

class Accounts extends React.Component {
  static propTypes = {
    accounts: t.object,
    actionRequest: t.object,
    allowedAccountTypes: t.object,
    clearForm: t.func.isRequired,
    createAccount: t.func.isRequired,
    errors: t.object,
    form: t.object,
    handleForm: t.func.isRequired,
    hasContributionAccountTypes: t.bool.isRequired,
    hasTRAccountTypes: t.bool.isRequired,
    hydrateForm: t.func.isRequired,
    reload: t.func.isRequired,
    request: t.object,
    servicers: t.object,
    updateAccount: t.func.isRequired,
    permissions: t.object,
    toDos: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      formId: null,
      showForm: false,
      showTuitionInstructions: false,
      showContributionInstructions: false,
    };
    this.handleInstructionsButton = this.handleInstructionsButton.bind(this);
  }

  componentDidMount() {
    const { load, id } = this.props;
    load(id);
  }
  componentDidUpdate(prevProps) {
    const wasLoading = prevProps.actionRequest.isLoading;
    const currently = this.props.actionRequest;
    if (wasLoading && !currently.isLoading && !currently.hasFailed) {
      this.setState({ ...this.state, showForm: false }, () => this.props.reload());
    }
  }

  closeForm = () => {
    this.props.clearForm();
    this.setState({ ...this.state, showForm: false });
  };

  onAddAccount = () => this.setState({ formId: null, showForm: true });

  onEditAccount = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true });
  };

  onChange = debounce((account, id, attributes) => {
    this.props.handleForm({ ...this.props.form, ...account }, [attributes]);
  }, 250);

  onCreate = () => this.props.createAccount();

  onUpdate = () => this.props.updateAccount(this.props.form.id);

  handleInstructionsButton = (instructionType) => {
    switch (instructionType) {
      case 'TR':
        this.setState({ showTuitionInstructions: !this.state.showTuitionInstructions });
        break;
      case 'CONTB':
        this.setState({ showContributionInstructions: !this.state.showContributionInstructions });
        break;
      default:
        break;
    }
  };

  getIconForAdd() {
    const { toDos } = this.props;
    if (
      Object.keys(toDos).includes('403') ||
      Object.keys(toDos).includes('13') ||
      Object.keys(toDos).includes('502')
    ) {
      return (
        <PaddedBadge brand="primary">
          <FontAwesomeIcon icon={faSolidExclamation} />
        </PaddedBadge>
      );
    } else {
      return <PaddedIcon icon={faPlusCircle} fixedWidth />;
    }
  }

  showAddButton() {
    const { permissions, contributionData } = this.props;

    let showAddForForgive = true;
    if (
      permissions.showForgiveness &&
      (contributionData.idrRepayment === null || contributionData.doeRepayDate === null)
    ) {
      showAddForForgive = false;
    }

    return showAddForForgive;
  }

  render() {
    const {
      accounts,
      actionRequest,
      contributionData,
      errors,
      form,
      permissions,
      request,
      servicers,
      allowedAccountTypes,
      hasContributionAccountTypes,
      hasTRAccountTypes,
    } = this.props;
    const { formId, showForm } = this.state;

    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>Accounts</CardTitle>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {(permissions.hasTuition || this.showAddButton()) && (
                  <StyledButton brand="primary" onClick={this.onAddAccount}>
                    {this.getIconForAdd()}Add Account
                  </StyledButton>
                )}
                {permissions.hasTuition && (
                  <StyledButton brand="primary" onClick={() => this.handleInstructionsButton('TR')}>
                    <PaddedIcon icon={faQuestionCircle} fixedWidth /> Add Account for Tuition
                    Reimbursement
                  </StyledButton>
                )}
                {permissions.showContribution &&
                  hasContributionAccountTypes &&
                  this.showAddButton() && (
                    <StyledButton
                      brand="primary"
                      onClick={() => this.handleInstructionsButton('CONTB')}
                    >
                      <PaddedIcon icon={faQuestionCircle} fixedWidth /> Add Account for Contribution
                    </StyledButton>
                  )}
              </div>
            </div>
          </CardBody>
          <AsyncLoader request={request} emptyMessage={'You have no Accounts on file.'}>
            {accounts && <AccountTable accounts={accounts} onEditAccount={this.onEditAccount} />}
          </AsyncLoader>
          {showForm && (
            <AccountForm
              action={formId ? 'Update' : 'Create'}
              closeForm={this.closeForm}
              data={form}
              errors={errors}
              isLoading={actionRequest.isLoading}
              isOpen={showForm}
              onChange={this.onChange}
              onSubmit={formId ? this.onUpdate : this.onCreate}
              servicers={servicers}
              accounts={accounts}
              allowedAccountTypes={allowedAccountTypes}
              hasContributionAccountTypes={hasContributionAccountTypes}
              hasTRAccountTypes={hasTRAccountTypes}
              contributionData={contributionData}
            />
          )}
          <CardBody></CardBody>
        </Card>
        <Modal isOpen={this.state.showTuitionInstructions}>
          <Card>
            <CardBody>
              <CardTitle>Adding an Account for Tuition Reimbursement</CardTitle>
              <StyledCardText>
                <ol>
                  <li>
                    Ensure that you have completed the "Add Bank for Tuition Reimbursement" steps
                  </li>
                  <li>Click on "Add Account"</li>
                  <li>Select the bank previously added as your "Account Servicer / Bank"</li>
                  <li>Select "Reimbursement Account+" for the "Account Type"</li>
                  <li>Fill out all other required information</li>
                </ol>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={() => this.handleInstructionsButton('TR')}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
        <Modal isOpen={this.state.showContributionInstructions}>
          <Card>
            <CardBody>
              <CardTitle>Adding an Account for Contribution</CardTitle>
              <StyledCardText>
                <ol>
                  <li>Ensure that you have completed the "Add Servicer for Contribution" steps</li>
                  <li>Click on "Add Account"</li>
                  <li>Select the bank previously added as your "Account Servicer/Bank"</li>
                  <li>
                    Select an account flagged as eligible for contribution for the "Account Type"
                  </li>
                  <li>Fill out all other required information</li>
                </ol>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={() => this.handleInstructionsButton('CONTB')}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.accounts;
  const servicerCache = state.servicers.cache;
  let servicers = { '': '' };
  Object.keys(servicerCache).forEach((k) => {
    servicers[servicerCache[k].id] = servicerCache[k].servicerName;
  });

  return {
    accounts: cache,
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    servicers: servicers,
    errors: errors,
    form: form,
    request: requests.loadCollection,
    toDos: safeAccess(state, 'todos.cache', {}),
    contributionData: getContributionData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearForm: () => dispatch(accountsClearForm()),
  createAccount: async () => {
    await dispatch(accountsCreateResource());
    dispatch(todosLoadCollection());
  },
  handleForm: (account, attributes) => dispatch(accountsHandleFormChange(account, attributes)),
  hydrateForm: async (id) => await dispatch(accountsCopyResourceToForm(id)),
  updateAccount: async (id) => {
    await dispatch(accountsUpdateResource(id));
    dispatch(todosLoadCollection());
  },
  load: (id) => {
    dispatch(contributionLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Accounts));
