import actions from './actions';
import { ServicerValidator } from './validators';
import { resourceOperationsGenerator } from '../../utils';

const servicerValidator = new ServicerValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-servicer',
  resourceType: 'customer-servicer',
};

const operations = resourceOperationsGenerator(
  'servicers',
  actions,
  null,
  servicerValidator,
  endpointHandler,
  null,
  endpointHandler,
  endpointHandler,
  null
);

const {
  servicersClearForm,
  servicersCopyResourceToForm,
  servicersHandleFormChange,
  servicersLoadCollection,
  servicersCreateResource,
  servicersUpdateResource,
} = operations;

export {
  servicersClearForm,
  servicersCopyResourceToForm,
  servicersHandleFormChange,
  servicersLoadCollection,
  servicersCreateResource,
  servicersUpdateResource,
};

export default operations;
