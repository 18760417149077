import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import LwoProgress from '../components/lwo-progress';

class ConsolidationLwo extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <DashboardSection title="More To Do">
        <LwoProgress removeContainer={true} />

        <Card>
          <CardBody>
            <CardTitle>Complete One or More Steps in the Loan Forgiveness Process</CardTitle>
            <CardText>
              <p>
                We'd like to tell you whether or not you could benefit from using Assure&#8480; to
                get all the qualifying payments you deserve! However, it appears that you must first
                complete some steps in the loan forgiveness process. Your "To-Do" list on your
                dashboard will tell you your next step.
              </p>
              <p>
                Note: Additional steps of the loan forgiveness process are required before we can
                determine your eligibility to use Assure&#8480;.
              </p>
            </CardText>
          </CardBody>
        </Card>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ConsolidationLwo));
