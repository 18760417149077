import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Header5 } from 'fiducius-ui';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../../todos';

const TodoText = () => (
  <>
    <Header5>Opt in or out of having a renewal meeting</Header5>
    <p>
      Thank you for completing the questionnaire and uploading your documents! Based on the
      information you provided, you are not required to have a phone meeting with a Renewal Services
      Specialist. However, because this is your first time renewing with us, you have the option of
      scheduling a phone meeting.
    </p>
  </>
);

const RenewalWantsMeeting = (props) => (
  <TodoComplete
    {...props}
    leftButtonAction={() => {
      props.toggleModal();
      props.toggleDrawer('meetings');
    }}
    leftButtonText="Schedule meeting"
    rightButtonText="Opt out"
    text={<TodoText />}
    title="Schedule Meeting"
  />
);

RenewalWantsMeeting.propTypes = {
  toggleDrawer: t.func.isRequired,
  toggleModal: t.func.isRequired,
};

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenewalWantsMeeting);
