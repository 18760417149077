import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { FlexBetween } from '../../../root';
import { Button, Card, CardBody, CardFooter, CardTitle, CardText, Modal } from 'fiducius-ui';

const StyledCardText = styled(CardText)`
  text-align: left;
`;

class LwoOptOutModal extends React.Component {
  static propTypes = {
    handleModalAction: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (clickOption) => {
    this.props.handleModalAction(clickOption);
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.showModal} fullWidth={true}>
          <Card>
            <CardBody>
              <CardTitle>Opt-Out of Assure&#8480;</CardTitle>
              <StyledCardText>
                <p>
                  To Assure&#8480; or not to Assure&#8480;… obviously the choice is yours. The
                  Department of Education will be making decisions too, about whether you're
                  eligible for LWO and how many additional qualifying payments you receive.
                  Remember, only 2% of borrowers who self-enrolled in PSLF got it all right and
                  received forgiveness. For the thousands of borrowers we've worked with over the
                  years, it's always paid for them to have Fiducius on their side. Please let us
                  know if there's anything else we can do to help you decide.
                </p>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <FlexBetween>
                <Button brand="success" onClick={() => this.handleClick('CLOSE')}>
                  I've changed my mind!
                </Button>
                <Button invert brand="danger" onClick={() => this.handleClick('OPTOUT')}>
                  I really don't want Assure&#8480;
                </Button>
              </FlexBetween>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

export default LwoOptOutModal;
