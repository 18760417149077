import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { Button, Header4 } from 'fiducius-ui';

import { useScrollToTopOnMount } from '../../../utils';

import NSLDSWorkflowScreening from '../../nslds/components/nslds-workflow-screening';

const FRIEND = 'Friend or colleague';
const OTHER = 'Other';

export const referrerHasExplanation = (value) => value === FRIEND || value === OTHER;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

const Centered = styled.div`
  text-align: center;
`;

const TabNSLDS = ({
  inNSLDSWorkflow,
  handleWorkflowComplete,
  isNSLDSWorkflowComplete,
  clearUploadedNslds,
  updateNsldsUploaded,
  nsldsUploaded,
  data,
}) => {
  useScrollToTopOnMount();

  const [showNotNeeded, setShowNotNeeded] = useState(false);

  useEffect(() => {
    if (data && parseInt(data.federalLoanBalance) <= 0 && inNSLDSWorkflow) {
      isNSLDSWorkflowComplete = true;
      setShowNotNeeded(true);
      handleWorkflowComplete();
    } else if (data && parseInt(data.federalLoanBalance) > 0 && inNSLDSWorkflow) {
      setShowNotNeeded(false);
      clearUploadedNslds();
    }
  }, []);

  //const firstInput = useFocusOnMount();
  return (
    <>
      {!isNSLDSWorkflowComplete && (
        <NSLDSWorkflowScreening
          inNSLDSWorkflow={inNSLDSWorkflow}
          handleWorkflowComplete={handleWorkflowComplete}
          updateNsldsUploaded={updateNsldsUploaded}
          nsldsUploaded={nsldsUploaded}
        />
      )}

      {isNSLDSWorkflowComplete && (
        <>
          {showNotNeeded && (
            <>
              <Header4>NSLDS Upload</Header4>
              <Centered>
                <div className="row">
                  <div className="col-12">
                    Based on the information you provided, you do not have any federal student loans
                    and you are not required to return an NSLDS file.
                  </div>
                </div>
              </Centered>
            </>
          )}
          {!showNotNeeded && (
            <>
              <Header4>NSLDS Upload Complete</Header4>
              <Centered>
                <StyledButton brand="primary" onClick={() => clearUploadedNslds()}>
                  Upload Different NSLDS
                </StyledButton>
              </Centered>
            </>
          )}
        </>
      )}
    </>
  );
};

TabNSLDS.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
  isNSLDSWorkflowComplete: t.bool.isRequired,
};

export default TabNSLDS;
