import React from 'react';
import styled from 'styled-components';
import { Card, CardBody, CardHeader, CardText, Header5, Link } from 'fiducius-ui';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const ReducedIDRQualify = () => (
  <Card brand="primary">
    <CardHeader>Reduced IDR</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Reduce your loan payment!</Header5>
        <Spaced>
          You can reduce your loan payment and see immediate financial relief. Click{' '}
          <Link to="/benefits/reducedidr/welcome">here</Link> to get started.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
);

ReducedIDRQualify.propTypes = {};

export default ReducedIDRQualify;
