import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import StepDisplay from '../components/step-display';
import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';

class ForbearancePhaseOne extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection title="Request General Forbearance - Phase 1">
          <div className="row">
            <div className="col-12">
              <Card>
                <CardBody>
                  <StepDisplay title={'Step 1 – Login to your current loan servicer(s) account(s)'}>
                    <ul>
                      <li>
                        Request <strong>general</strong> forbearance on any loan(s) that have a
                        payment due.{' '}
                      </li>
                      <li>
                        <strong>Please note:</strong> You will want to remove any direct debit that
                        is currently placed on these loans. If a payment is due within the next
                        week, you may want to contact your bank to ensure the payment is not drawn
                        from your account.
                      </li>
                    </ul>
                  </StepDisplay>
                  <StepDisplay title={'Step 2 – Confirm you have requested forbearance.'}>
                    <ul>
                      <li>
                        I confirm I have submitted a forbearance request at my current loan
                        servicer(s) to place my current loan payment(s) on hold. We recommend you
                        request general forbearance for approximately <strong>four months</strong>{' '}
                        to allow enough time to complete the remaining steps.
                      </li>
                      <li>
                        Forbearance requests typically take up to two weeks to process and any
                        payment made while in forbearance may not qualify.
                      </li>
                      <li>
                        <strong>Please note:</strong> If you have used more than 36 months of
                        general forbearance, your request may be denied by the loan servicer.
                      </li>
                    </ul>
                  </StepDisplay>
                  <StandardConfirmation stepId={102} />
                </CardBody>
              </Card>
            </div>
          </div>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ForbearancePhaseOne));
