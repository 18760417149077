import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';

import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Form, Button } from 'fiducius-ui';
import { getUserId } from '../../../auth';
import { mergeRequestStatuses } from '../../../utils';

import {
  planToolCopyResourceToForm,
  planToolHandleFormChange,
  planToolUpdateResource,
} from '../redux/operations';

import { DataColumn } from './data-column';
import { Fade } from './fade';
import { Fade as OrigFade } from '../../../routing';
import { FocusPage } from './focus-page';
import { InputGrid } from './input-grid';
import { AppContext, FocusContext } from '../containers/plan-tool';

const Summary = ({ id, request, formDefaults = {}, loadFormObject, savePlanToolData, errors }) => {
  const scrollDiv = useRef(null);
  const absFix = useRef(null);
  const gridRef = useRef(null);
  const chartRef = useRef(null);
  const compareChartRef = useRef(null);
  const { state } = useContext(AppContext);
  const { focused, focusPage } = useContext(FocusContext);

  useEffect(() => {
    formDefaults = loadFormObject(id);
  }, []);

  useEffect(() => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollTo(0, 0); // eslint-disable-line no-unused-expressions
      scrollDiv.current.scrollIntoView(); // eslint-disable-line no-unused-expressions
    }
  }, [scrollDiv, focused]);

  useLayoutEffect(() => {
    if (absFix.current && gridRef.current) {
      absFix.current.style.height = `${gridRef.current.scrollHeight + 50}px`;
    }
  }, [absFix, gridRef]);

  const onSubmit = () => {
    //savePlanToolData(id);
    focusPage('Confirmation');

    //delete errors.na;
    //update(form.id);

    //setHasSubmitted(true);
  };

  const onCancel = () => {
    focusPage(null);

    //delete errors.na;
    //update(form.id);

    //setHasSubmitted(true);
  };

  const onSave = () => {
    state['UIData']['ChartData'].value = chartRef.current.innerHTML;
    state['UIData']['ComparisonChartData'].value = compareChartRef.current.innerHTML;
    state['UIData']['GraphData'].value = document.getElementById('planGraph').toDataURL();
    loadFormObject();
    savePlanToolData(id);

    //focusPage('Confirmation');

    //delete errors.na;
    //update(form.id);

    //setHasSubmitted(true);
  };

  const checkNoErrors = () => {
    return !errors.projectedRetirementDate || errors.projectedRetirementDate.detail === undefined;
  };

  return (
    <div className="polaris polaris-app">
      <div className="polaris-title">
        <h1>
          Polaris Student Loan Financial Planner<sup> SM</sup>
        </h1>
      </div>

      <Fade id="confirmation_fade" show={focused === 'Confirmation'}>
        <div className="polaris-container">
          <p>New Page Here</p>
          <p>
            <Button brand="primary" onClick={onSave}>
              Save
            </Button>
            <Button brand="primary" onClick={onCancel}>
              Cancel
            </Button>
          </p>
          <AsyncLoader request={request}>
            <DataColumn request={request} />
          </AsyncLoader>
        </div>
      </Fade>

      <Fade id="main_fade" show={focused !== 'Confirmation'}>
        <div className="polaris-container">
          <div className="polaris-input-column" ref={scrollDiv}>
            <div ref={absFix} />
            <Fade show={focused === null} id="grid">
              <Form defaults={formDefaults} id={'planToolForm'}>
                {/* <Form defaults={formDefaults} id={'planToolForm'} onSubmit={onSubmit}> */}

                <InputGrid ref={gridRef} key="grid" />

                {/* <OrigFade id="submit_fade" show={checkNoErrors()}>
                <Button brand="primary" onClick={onSubmit}>
                  Submit
                </Button>
              </OrigFade> */}
              </Form>
            </Fade>
            {Object.keys(state).map((p) => (
              <Fade key={p} show={focused === p} reverse id={p}>
                <FocusPage page={p} />
              </Fade>
            ))}
          </div>
          <AsyncLoader request={request}>
            <DataColumn
              request={request.isLoading}
              ref={chartRef}
              compareChartRef={compareChartRef}
            />
          </AsyncLoader>
        </div>
      </Fade>
    </div>
  );
};

Summary.propTypes = {
  actionRequest: t.object,
  data: t.object,
  errors: t.object,
  form: t.object,
  hasData: t.bool,
  id: t.string,
  permissions: t.object,
  request: t.object,
  modeler: t.object,
  updateFormState: t.func.isRequired,
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.planTool;
  return {
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    form: form,
    errors: errors,
    id: getUserId(state),
    request: state.planTool.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFormState: (data, attributes) => dispatch(planToolHandleFormChange(data, attributes)),
  handleForm: (data, attributes) => dispatch(planToolHandleFormChange(data, attributes)),
  hydrateForm: async (id) => await dispatch(planToolCopyResourceToForm(id)),
  savePlanToolData: async (id) => await dispatch(planToolUpdateResource(id)),
  loadFormFromData: async (id) => await dispatch(planToolCopyResourceToForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
