import React, { useContext } from 'react';
import styled from 'styled-components';
import t from 'prop-types';

import Input from './input';
import { Button } from 'fiducius-ui';
import { AppContext } from '../containers/plan-tool';

const StyledButton = styled(Button)`
  margin-right: 1em;
  margin-top: 0.5em;
  width: inherit;
`;

export const FocusPageInputs = ({ page }) => {
  const { state, onChange, onChangeDeductionAction } = useContext(AppContext);
  if (!page) return null;
  return (
    <>
      {Object.entries(state[page]).map(([k, v]) => (
        <Input
          {...v}
          key={`focus-${page}-${k}`}
          name={k}
          hidden={v && v.hide && !!v.hide(state)}
          onChange={onChange(page)}
          onClick={onChangeDeductionAction(page, 'Delete Deduction')}
          withHelp
        />
      ))}
      {page === 'Other Pre-Tax Costs / Contributions' && (
        <div className="row">
          <div className="col-2">
            <StyledButton brand="primary" onClick={onChangeDeductionAction(page, 'Add Deduction')}>
              Add
            </StyledButton>
          </div>
        </div>
      )}
      {page === 'Modified Other Pre-Tax Costs / Contributions' && (
        <div className="row">
          <div className="col-2">
            <StyledButton
              brand="primary"
              onClick={onChangeDeductionAction(
                'Modified Other Pre-Tax Costs / Contributions',
                'Add Propsed Deduction'
              )}
            >
              Add
            </StyledButton>
          </div>
        </div>
      )}
    </>
  );
};

FocusPageInputs.propTypes = {
  page: t.string,
};
