import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Header2,
  DashboardSection,
  AsyncLoader,
  Lede,
  Modal,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { getUserId } from '../../../auth';

import {
  limitedWaiverOpportunityLoadResource,
  limitedWaiverOpportunityCopyResourceToForm,
} from '../redux/operations';
import ResultsTable from '../components/results-table';
import LwoConfirmation from '../components/lwo-confirmation';
import LwoProgress from '../components/lwo-progress';
import { formatAsMoney } from '../../../utils';

const SpacedDiv = styled.div`
  margin-bottom: 1em;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em;
`;

const StyledLede = styled(Lede)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textPrimary : 'inherit')};
`;

const AlignTextLeft = styled.div`
  text-align: left;
`;
class LwoAgreement extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    load: t.func,
    loans: t.object,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: null, openNeverWorkFiduModal: false };
    this.handleNeverWorkFiduModal = this.handleNeverWorkFiduModal.bind(this);
  }

  handleNeverWorkFiduModal() {
    this.setState({ openNeverWorkFiduModal: !this.state.openNeverWorkFiduModal });
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  redirectToSignAgreement = () => {
    this.setState({ ...this.state, redirectURI: '/lwo-agreement' });
  };

  renderPaidTile() {
    const { data } = this.props;
    if (data.companyPaid) {
      return this.renderCompanyPaid();
    } else if (data.originationPaid) {
      return this.renderOriginationPaid();
    } else {
      return this.renderVoluntaryPaid();
    }
  }

  renderCompanyPaid() {
    return (
      <p>
        Ready to get started? Click the "I Want Assure&#8480;" button to sign up. The cost of
        Assure&#8480; was paid for by your employer, so you'll get peace of mind at no additional
        cost. And since we guarantee you'll receive qualifying payments, you're assured to come out
        ahead.
      </p>
    );
  }

  renderOriginationPaid() {
    return (
      <p>
        Ready to get started? Click the "I Want Assure&#8480;" button to sign up. The cost of
        Assure&#8480; was included in your Forgiveness implementation fee, so you'll get peace of
        mind at no additional cost. And since we guarantee you'll receive qualifying payments,
        you're assured to come out ahead.
      </p>
    );
  }

  renderVoluntaryPaid() {
    return (
      <p>
        Ready to get started? Click the "I Want Assure&#8480;" button to sign up. You'll get peace
        of mind for only {formatAsMoney(this.props.data.lwoCost)}. And since we guarantee you'll
        receive qualifying payments, you're assured to come out ahead.
      </p>
    );
  }

  render() {
    const { data } = this.props;
    if (this.state.redirectURI !== null) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <DashboardSection title="Get Started">
        <LwoProgress removeContainer={true} />
        <Card>
          <CardBody>
            <div className="row">
              <div className="col-12">
                <CardTitle>Learn About Assure&#8480; and Sign Up</CardTitle>
                <AsyncLoader request={this.props.request}>
                  <CardText>
                    <strong> Qualifying Payment Information</strong>
                  </CardText>
                  <SpacedDiv className="row">
                    <div className="col-12">
                      <ResultsTable loans={this.props.loans} />
                    </div>
                  </SpacedDiv>
                  <div className="row">
                    <div className="col-12">
                      <CardText>
                        <div className="row">
                          <div className="col-12">
                            <p>
                              Great, you're eligible to take advantage of the Limited Waiver
                              Opportunity. Now, let's make sure you get all the additional
                              qualifying payments you deserve. It requires some detailed work on
                              your part, but Fiducius has it covered.
                            </p>
                            <p>
                              We created Assure&#8480;, the only solution of its kind, to guarantee
                              you get all those additional payments and don't have to worry. It does
                              the hard work for you, identifying qualifying payments, producing 100%
                              accurate documentation and helping manage all the steps.
                            </p>
                            {!data.companyPaid && !data.originationPaid && (
                              <p>
                                With Fiducius, be confident you'll receive every single qualifying
                                payment you deserve. If you don't completely trust your lender to
                                follow through, we have your back. In fact, we're so confident in
                                our ability to help you get all those qualifying payments, we're
                                offering a money-back guarantee when you purchase Assure&#8480;.
                              </p>
                            )}
                            <p>
                              <StyledButton brand="primary" onClick={this.handleNeverWorkFiduModal}>
                                <PaddedIcon icon={faQuestionCircle} fixedWidth />
                                Never worked with Fiducius before?
                              </StyledButton>
                            </p>
                            {this.renderPaidTile()}
                          </div>
                        </div>
                      </CardText>
                    </div>
                  </div>
                </AsyncLoader>
                <LwoConfirmation stepId={604} />
              </div>
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.openNeverWorkFiduModal} fullWidth={true}>
          <Card>
            <CardBody>
              <CardText>
                <CardTitle>Never worked with Fiducius before?</CardTitle>
                <AlignTextLeft>
                  <FlexRow>
                    <StyledLede>
                      For more than 10 years, Fiducius has been trusted by thousands of borrowers to
                      ensure they are verified to participate in PSLF and receive the maximum amount
                      of forgiveness. 100% of borrowers who work with Fiducius are qualified for
                      forgiveness, and they've had their monthly payments reduced by 80% or more on
                      average. Now that's results!
                    </StyledLede>
                  </FlexRow>
                </AlignTextLeft>
              </CardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={this.handleNeverWorkFiduModal}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => ({
  request: state.limitedWaiverOpportunity.requests.loadResource,
  loans: state.limitedWaiverOpportunity.form.eligibleLoans,
  data: state.limitedWaiverOpportunity.form,
  id: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
    await dispatch(limitedWaiverOpportunityCopyResourceToForm(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoAgreement));
