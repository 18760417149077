import actions from './actions';
import { getApi, keyById, resourceOperationsGenerator } from '../../utils';

import { getToken } from '../../auth';

const endpointHandler = {
  endpoint: '/internal-message',
  customResponseHandler: null,
  resourceType: 'internal-message',
};

const operations = resourceOperationsGenerator(
  'messages',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  null,
  null
);

const { messagesLoadResource } = operations;

const loadMessage = (id) => async (dispatch, getState) => {
  let data = [];
  try {
    const state = getState();
    dispatch(actions.messagesLoadResourceRequest());
    data = await getApi(getToken(state), `/internal-message/${id}`);

    dispatch(actions.messagesLoadResourceResponse());

    const cache = {
      ...getState().messages.cache,
      ...keyById(data),
    };

    await dispatch(operations.messagesHandleCacheChange(cache));
    await dispatch(actions.messagesUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.messagesHandleError(error));
    dispatch(actions.messagesLoadResourceFailure(error));
  }

  return data;
};

export { messagesLoadResource, loadMessage };

export default operations;
