import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/forgiveness',
  resourceType: 'forgiveness',
};

const operations = resourceOperationsGenerator(
  'forgiveness',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  null,
  null
);

const { forgivenessLoadResource } = operations;

export { forgivenessLoadResource };

export default operations;
