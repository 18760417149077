import React from 'react';
import t from 'prop-types';
import {
  Header3,
  InputError,
  InputGroup,
  InputLabel,
  InputRadio,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';

const ApprovalClass = ({ data, errors = {}, id }) => (
  <>
    <Header3>Class: {data.className}</Header3>
    <div className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.approvalStatus}>
          <InputLabel htmlFor={'approvalStatus' + id}>Approval Status</InputLabel>
          <InputError>{safeAccess(errors, 'approvalStatus.detail')}</InputError>
          <InputRadio
            name={'approvalStatus' + id}
            value="Y"
            label="Approve"
            defaultChecked={data.approvalStatus === 'Y'}
          />
          <InputRadio
            name={'approvalStatus' + id}
            value="N"
            label="Deny"
            defaultChecked={data.approvalStatus === 'N'}
          />
        </InputWrapper>
      </div>
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.classNote}>
          <InputLabel htmlFor={'classNote' + id}>Class Note</InputLabel>
          <InputError>{safeAccess(errors, 'classNote.detail')}</InputError>
          <InputGroup>
            <InputText name={'classNote' + id} defaultValue={data.classNote} />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
  </>
);

ApprovalClass.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.number.isRequired,
};

export default ApprovalClass;
