import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { safeAccess } from '../../utils';
import {
  AsyncLoader,
  ButtonCarousel,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Lede,
} from 'fiducius-ui';

import { faqLoadSubjects, faqLoadQuestionAnswers } from '../redux/operations';
import QuestionAnswer from '../components/question-answer';

const BottomRow = styled.div`
  padding-top: 10px;
`;

class Faq extends React.Component {
  static propTypes = {
    cache: t.object,
    loadFaqSubjects: t.func.isRequired,
    loadQustionAnswers: t.func.isRequired,
    questionRequest: t.object,
    request: t.object,
    token: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadFaqSubjects();
  }

  getFaqSubects = (data) => {
    let retButtons = [];
    if (data !== undefined && data !== {} && data.subjects !== undefined) {
      Object.values(data.subjects).forEach((item) => {
        retButtons.push(item.subject);
      });
    }
    return retButtons;
  };

  getFaqComponents = (data) => {
    let retComponents = [];
    if (data !== undefined && data !== {} && data.subjects !== undefined) {
      Object.values(data.subjects).forEach((item) => {
        retComponents.push(<QuestionAnswer subject={item.subject} subjectId={item.id} />);
      });
    }
    return retComponents;
  };

  render() {
    const { cache = {}, request, questionRequest } = this.props;
    return (
      <>
        <DashboardSection title="Frequently Asked Questions" ref={this.myRef}>
          <Card brand="primary">
            <CardBody>
              <CardTitle>What do you have questions about?</CardTitle>
              <AsyncLoader request={request}>
                <p>
                  <ButtonCarousel
                    buttonLocation={1}
                    components={this.getFaqComponents(cache)}
                    componentDisplayNames={this.getFaqSubects(cache)}
                  />
                </p>
              </AsyncLoader>
              <BottomRow>
                <CardText>
                  <p>
                    If you can't find the answer to your question in one of the above categories,
                    please submit a Contact Us request.
                  </p>
                </CardText>
              </BottomRow>
            </CardBody>
          </Card>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cache: safeAccess(state, 'customerFaq.cache'),
    request: state.customerFaq.requests.loadCollection,
    questionRequest: state.customerFaq.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadFaqSubjects: () => dispatch(faqLoadSubjects()),
  loadQuestionAnswers: (id) => dispatch(faqLoadQuestionAnswers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
