import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Loader,
  Modal,
} from 'fiducius-ui';

import { FlexBetween } from '../../../root';
import { safeAccess, mergeRequestStatuses } from '../../../utils';
import { connect } from 'react-redux';

import { PdfViewer } from '../../../fulfillment';

import { authLoadPermissions } from '../../../auth';
import { todosLoadCollection } from '../../../todos/redux/operations';

import {
  selfServiceDocumentUpdateResource,
  selfServiceDocumentLoadDocuments,
  selfServiceDocumentMarkStatus,
  selfServiceDocumentClearDocument,
} from '../../../self-service-document';

const TextLeft = styled.div`
  text-align: left;
`;

class FaxApproval extends React.Component {
  static propTypes = {
    closeForm: t.func.isRequired,
    isOpen: t.bool,
    onSubmit: t.func.isRequired,
    request: t.object.isRequired,
    documents: t.object.isRequired,
    documentId: t.string.isRequired,
    markDocument: t.func.isRequired,
    update: t.func.isRequired,
    documentIncorrect: t.func.isRequired,
    alreadySent: t.func.isRequired,
    stepId: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  submitApproval = () => {
    const { markDocument, documentId, closeForm } = this.props;
    markDocument(documentId, true, false, false).then(closeForm(false));
  };

  submitAlreadySent = () => {
    const { alreadySent, documentId, closeForm } = this.props;
    alreadySent(documentId).then(closeForm(true));
  };

  submitDocumentIncorrect = () => {
    const { documentIncorrect, documentId, closeForm } = this.props;
    documentIncorrect(documentId).then(closeForm(true));
  };

  getFormReviewInstructions = () => {
    const { stepId, documents, documentId } = this.props;

    if (stepId.id === 253 || stepId.id === 107) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information you provided. We
            recommend that you review the document to confirm it is filled out properly.
          </p>
          <p>
            <strong>Employment Certification review recommendations:</strong>
          </p>
          <ul>
            <li>
              Please verify your Social Security Number is present on the first two pages of your
              employment certification(s) prior to submitting it.
            </li>
            <li>
              Questions 1 through 9 <strong>must</strong> be completed on the second page. Please
              read the answer to question 9 to determine if succeeding questions require an answer.
            </li>
          </ul>

          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
          <p>
            If you have already submitted your Employment Certification form to your loan servicer,
            please select the <strong>Form Already Submitted</strong> button.
          </p>
        </>
      );
    } else if (
      (stepId.id === 256 || stepId.id === 112) &&
      safeAccess(documents['current'][documentId], 'description', '') !== 'Taxes' &&
      safeAccess(documents['current'][documentId], 'description', '') !== "Spouse's Taxes"
    ) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information you provided. We
            recommend that you review the document to confirm it is filled out properly.
          </p>
          <p>
            <strong>Repayment review recommendations: </strong>
          </p>
          <ul>
            <li>
              Please verify your Social Security Number is present on the first six pages of your
              application prior to submitting it.
            </li>
            {/*ADD PART FOR SPOUSE's SSN IF TIME PERMITS*/}
          </ul>

          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    } else if (stepId.id === 104) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information you provided. We
            recommend that you review the document to confirm it is filled out properly.
          </p>
          <p>
            <strong>Consolidation review recommendations: </strong>
          </p>
          <ul>
            <li>
              Please verify your Social Security Number is present on all pages up to the signature
              page.
            </li>
            <li>Please verify that your address on line #9 is not a P.O. box.</li>
            <li>
              Please verify that your references at the bottom of page one have different home
              addresses and phone numbers from both you and each other.
            </li>
          </ul>

          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    }
  };

  componentDidUpdate(prevProps) {}

  render() {
    const { isOpen, closeForm, request, documents, documentId } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
        <Card>
          <CardHeader>
            Approve {safeAccess(documents['current'][documentId], 'description', '')} For Fax
          </CardHeader>
          <CardBody>
            <PdfViewer
              base64={safeAccess(documents['current'][documentId], 'fileData', '')}
              isFulfillment={false}
            />
            <Card>
              <TextLeft>
                <CardBody>
                  <CardText>{this.getFormReviewInstructions()}</CardText>
                </CardBody>
              </TextLeft>
            </Card>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Button brand="danger" onClick={this.submitDocumentIncorrect}>
                {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Form is Incorrect'}
              </Button>
              {safeAccess(documents['current'][documentId], 'employmentHistoryId', 0) > 0 && (
                <Button brand="success" onClick={this.submitAlreadySent}>
                  {request.isLoading ? (
                    <Loader variant="push" size={1.5} />
                  ) : (
                    'Form Already Submitted'
                  )}
                </Button>
              )}
              <Button brand="success" onClick={this.submitApproval}>
                {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Form is Correct'}
              </Button>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
      state.selfServiceDocument.requests.updateResource,
      state.selfServiceDocument.requests.loadCollection,
    ]),
    documents: safeAccess(state, 'selfServiceDocument.cache'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  update: async (id) => {
    await dispatch(selfServiceDocumentUpdateResource(id));
  },
  markDocument: async (id, readyToSend, sentToServicer, documentIncorrect) => {
    await dispatch(
      selfServiceDocumentMarkStatus(id, readyToSend, sentToServicer, documentIncorrect)
    );
  },
  alreadySent: async (id) => {
    dispatch(authLoadPermissions());
    await dispatch(selfServiceDocumentMarkStatus(id, false, true, false));
    await dispatch(selfServiceDocumentUpdateResource(id));
    await dispatch(selfServiceDocumentLoadDocuments(253));
    await dispatch(todosLoadCollection());
  },
  documentIncorrect: async (id) => {
    await dispatch(selfServiceDocumentMarkStatus(id, false, false, true));
    await dispatch(selfServiceDocumentUpdateResource(id));
    await dispatch(selfServiceDocumentClearDocument(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FaxApproval);
