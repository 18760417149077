import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { Card, CardBody, CardText, CardTitle, ChartPie } from 'fiducius-ui';

const StyledRow = styled.div`
  margin-top: 1em;
`;

const QpPie = ({ qualifiedPayments, paymentsRemain, cutout = 0, bigTitle = false }) => (
  <Card>
    <CardBody>
      {bigTitle && <CardTitle>My Payment Breakdown</CardTitle>}
      <CardText>
        {!bigTitle && (
          <center>
            <strong>My Payment Breakdown</strong>
          </center>
        )}
        <StyledRow className="row">
          <div className="col-12">
            <ChartPie
              pie={{ data: [paymentsRemain, qualifiedPayments] }}
              config={{
                labels: ['Payments Remaining', 'Total Qualified Payments'],
                cutout: cutout,
              }}
            />
          </div>
        </StyledRow>
      </CardText>
    </CardBody>
  </Card>
);

QpPie.propTypes = {
  qualifiedPayments: t.number,
  paymentsRemain: t.number,
  cutout: t.number,
  bigTitle: t.bool,
};

export default QpPie;
