import React from 'react';
import t from 'prop-types';
import { Card, CardBody, CardText, ChartBar } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils/format';

const PaymentCompare = ({ newPayment = 0, oldPayment = 0, year = 1 }) => (
  <Card>
    <CardBody>
      <CardText>
        <center>
          <strong>My Payment Comparison</strong>
        </center>
        <ChartBar
          bars={[
            {
              label: '',
              dataType: 'currency',
              data: [oldPayment, newPayment],
            },
          ]}
          config={{ labels: ['Standard', 'Income Driven'], formatter: formatAsMoney }}
        />
      </CardText>
    </CardBody>
  </Card>
);

PaymentCompare.propTypes = {
  newPayment: t.number,
  oldPayment: t.number,
  year: t.number,
};

export default PaymentCompare;
