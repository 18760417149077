import React from 'react';
import t from 'prop-types';
import {
  Alert,
  Header3,
  InputError,
  InputGroup,
  InputLabel,
  InputSelect,
  InputText,
  InputWrapper,
  InputMask,
  InputRadio,
  InputHelp,
} from 'fiducius-ui';

import { convertIsoToSlash, safeAccess } from '../../../utils';

const EmployerForm = ({ data = {}, errors = {}, id }) => (
  <>
    <Header3>Employer Information {id + 1}</Header3>
    <div className="row">
      <div className="col-12">
        <InputWrapper error={!!errors.employmentEmploymentClassification}>
          <InputLabel htmlFor={'employmentEmploymentClassification' + id}>
            Is this employer not-for-profit?
          </InputLabel>
          <InputError>{safeAccess(errors, 'employmentEmploymentClassification.detail')}</InputError>
          <InputRadio
            name={'employmentEmploymentClassification' + id}
            value="NPROF"
            label="Yes"
            defaultChecked={data.employmentEmploymentClassification === 'NPROF'}
          />
          <InputRadio
            name={'employmentEmploymentClassification' + id}
            value="PROF"
            label="No"
            defaultChecked={data.employmentEmploymentClassification === 'PROF'}
          />
        </InputWrapper>
      </div>
    </div>
    <div className="row">
      <div className="col-12">
        <InputWrapper error={!!errors.employmentEmploymentType}>
          <InputLabel htmlFor={'employmentEmploymentType' + id}>
            Are you working there full-time?
          </InputLabel>
          <InputError>{safeAccess(errors, 'employmentEmploymentType.detail')}</InputError>
          <InputRadio
            name={'employmentEmploymentType' + id}
            value="FULL"
            label="Yes"
            defaultChecked={data.employmentEmploymentType === 'FULL'}
          />
          <InputRadio
            name={'employmentEmploymentType' + id}
            value="PART"
            label="No"
            defaultChecked={data.employmentEmploymentType === 'PART'}
          />
          <InputWrapper>
            <InputHelp>
              Working full time for the program's requirements means you are currently considered
              full time by your employer, and work an average of 30 or more hours per week.
            </InputHelp>
          </InputWrapper>

          {data.currentStatus === 'CRET' && data.employmentEmploymentClassification === 'NPROF' && (
            <Alert brand="info">
              Please keep in mind, loan payments made during an employment lapse, between a previous
              and current employer, will not count as qualifying payments towards the Public Service
              Loan Forgiveness Program. If you know you are going to experience an employment lapse
              in the future, please contact us.
            </Alert>
          )}
        </InputWrapper>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentEmployerName}>
          <InputLabel htmlFor={'employmentEmployerName' + id}>Employer Name</InputLabel>
          <InputError>{safeAccess(errors, 'employmentEmployerName.detail')}</InputError>
          <InputGroup>
            <InputText
              name={'employmentEmployerName' + id}
              defaultValue={safeAccess(data, 'employmentEmployerName')}
            />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentJobTitle}>
          <InputLabel htmlFor={'employmentJobTitle' + id}>Job Title</InputLabel>
          <InputError>{safeAccess(errors, 'employmentJobTitle.detail')}</InputError>
          <InputGroup>
            <InputText name={'employmentJobTitle' + id} defaultValue={data.employmentJobTitle} />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentStartDate}>
          <InputLabel htmlFor={'employmentStartDate' + id}>Start Date</InputLabel>
          <InputError>{safeAccess(errors, 'employmentStartDate.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={convertIsoToSlash(data.employmentStartDate)}
              mask="##/##/####"
              name={'employmentStartDate' + id}
              placeholder="MM/DD/YYYY"
            />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentEndDate}>
          <InputLabel htmlFor={'employmentEndDate' + id}>End Date</InputLabel>
          <InputError>{safeAccess(errors, 'employmentEndDate.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={convertIsoToSlash(data.employmentEndDate)}
              mask="##/##/####"
              name={'employmentEndDate' + id}
              placeholder="MM/DD/YYYY"
            />
          </InputGroup>
          <InputHelp>If you are still employed, please leave this field blank.</InputHelp>
        </InputWrapper>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentEin}>
          <InputLabel htmlFor={'employmentEin' + id}>EIN</InputLabel>
          <InputError>{safeAccess(errors, 'employmentEin.detail')}</InputError>
          <InputGroup>
            <InputText
              name={'employmentEin' + id}
              defaultValue={safeAccess(data, 'employmentEin')}
            />
          </InputGroup>
          <InputWrapper>
            <InputHelp>
              You can find your employer's EIN number in box B of your most recent W-2.
            </InputHelp>
          </InputWrapper>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-3">
        <InputWrapper error={!!errors.employmentPayPeriods}>
          <InputLabel htmlFor={'employmentPayPeriods' + id}>Pay Periods</InputLabel>
          <InputError>{safeAccess(errors, 'employmentPayPeriods.detail')}</InputError>
          <InputGroup>
            <InputText
              name={'employmentPayPeriods' + id}
              defaultValue={safeAccess(data, 'employmentPayPeriods')}
            />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-3">
        <InputWrapper error={!!errors.employmentHours}>
          <InputLabel htmlFor={'employmentHours' + id}>Hours</InputLabel>
          <InputError>{safeAccess(errors, 'employmentHours.detail')}</InputError>
          <InputGroup>
            <InputText
              name={'employmentHours' + id}
              defaultValue={safeAccess(data, 'employmentHours')}
            />
          </InputGroup>
          <InputWrapper>
            {' '}
            <InputHelp>Average hours per week</InputHelp>
          </InputWrapper>
        </InputWrapper>
      </div>
    </div>
    <InputWrapper error={!!errors.employmentAddress1}>
      <InputLabel htmlFor={'employmentAddress1' + id}>Address Line 1</InputLabel>
      <InputError>{safeAccess(errors, 'employmentAddress1.detail')}</InputError>
      <InputGroup>
        <InputText
          name={'employmentAddress1' + id}
          defaultValue={safeAccess(data, 'employmentAddress1')}
        />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.employmentAddress2}>
      <InputLabel htmlFor={'employmentAddress2' + id} optional>
        Address Line 2
      </InputLabel>
      <InputError>{safeAccess(errors, 'employmentAddress2.detail')}</InputError>
      <InputGroup>
        <InputText
          name={'employmentAddress2' + id}
          defaultValue={safeAccess(data, 'employmentAddress2')}
        />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.employmentCity}>
      <InputLabel htmlFor={'employmentCity' + id}>City</InputLabel>
      <InputError>{safeAccess(errors, 'employmentCity.detail')}</InputError>
      <InputGroup>
        <InputText name={'employmentCity' + id} defaultValue={safeAccess(data, 'employmentCity')} />
      </InputGroup>
    </InputWrapper>
    <div className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentState}>
          <InputLabel htmlFor={'employmentState' + id}>State</InputLabel>
          <InputError>{safeAccess(errors, 'employmentState.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name={'employmentState' + id}
              defaultValue={safeAccess(data, 'employmentState')}
              options={[
                '',
                'AL',
                'AK',
                'AR',
                'AZ',
                'CA',
                'CO',
                'CT',
                'DC',
                'DE',
                'FL',
                'GA',
                'HI',
                'IA',
                'ID',
                'IL',
                'IN',
                'KS',
                'KY',
                'LA',
                'MA',
                'MD',
                'ME',
                'MI',
                'MN',
                'MO',
                'MS',
                'MT',
                'NC',
                'NE',
                'NH',
                'NJ',
                'NM',
                'NV',
                'NY',
                'ND',
                'OH',
                'OK',
                'OR',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VT',
                'VA',
                'WA',
                'WI',
                'WV',
                'WY',
              ]}
            />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.employmentZip}>
          <InputLabel htmlFor={'employmentZip' + id}>Zip Code</InputLabel>
          <InputError>{safeAccess(errors, 'employmentZip.detail')}</InputError>
          <InputGroup>
            <InputText
              name={'employmentZip' + id}
              defaultValue={safeAccess(data, 'employmentZip')}
            />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
  </>
);

EmployerForm.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.number.isRequired,
};

export default EmployerForm;
