import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  DashboardSection,
  Modal,
  InputWrapper,
  Header2,
} from 'fiducius-ui';

import FreshStartProgress from '../components/fresh-start-progress';
import { mergeRequestStatuses } from '../../../utils';
import { authLoadPermissions, getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
import { todosLoadCollection, todosSendUpdate } from '../../../todos/redux/operations';

import { freshStartLoadResource } from '../redux/operations';

import { FlexEnd } from '../../../root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as faXIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';

const FreshStartContact = ({ request, confirm, permissions, id, load, data }) => {
  useEffect(() => {
    load(id);
  }, []);

  const [redirectUri, setRedirectUri] = useState('');

  const hasCompletedStep = () => {
    const completedContact = permissions.freshStartSteps.filter(
      (a) => a.id === 'ContactFreshStart' && a.stepComplete
    );

    if (completedContact.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const isCurrentStep = () => {
    const currentContact = permissions.freshStartSteps.filter(
      (a) => a.id === 'ContactFreshStart' && a.stepCurrent
    );

    if (currentContact.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const confirmContact = () => {
    confirm().then(() => {
      setRedirectUri('/benefits/freshstart/awaiting');
    });
  };

  const [openAgenciesModal, setOpenAgenciesModal] = useState(false);

  const getGuarantyAgencyInfo = () => {
    //Object.keys(data).filter(a => a.)
    let retVal = [];
    const noPhoneText =
      "Your guaranty agency's contact information was not present on your NSLDS text file. We recommend visiting their website to determine the best way to contact them.";
    if (Object.values(data)[0]) {
      Object.values(data)[0]
        .freshStartDefaultedLoans.filter((a) => a.heldByGuaranty)
        .forEach((a) => {
          retVal.push(
            <p>
              Agency: {a.name}
              <br />
              {a.phoneNumber ? 'Phone: ' + a.phoneNumber : noPhoneText}
            </p>
          );
        });
    }

    if (hasDeptOfEdAgency() && hasGuarantyAgency) {
      retVal.push(
        <p>
          <b>
            The exact request you want to place is that you would like to participate in Fresh Start
            and to get out of default.
          </b>
        </p>
      );
    }

    return retVal;
  };

  const qualifiedForForgiveness = () => {
    if (Object.values(data).length > 0 && Object.values(data)[0].qualifiedForForgive) {
      return true;
    } else {
      return false;
    }
  };

  const hasGuarantyAgency = () => {
    return (
      Object.values(data)[0] &&
      Object.values(data)[0].freshStartDefaultedLoans.filter((a) => a.heldByGuaranty).length > 0
    );
  };

  const hasDeptOfEdAgency = () => {
    return (
      Object.values(data)[0] &&
      Object.values(data)[0].freshStartDefaultedLoans.filter((a) => a.heldByDeptOfEd).length > 0
    );
  };

  const getMainText = () => {
    const hasDeptOfEd = hasDeptOfEdAgency();
    const hasGuaranty = hasGuarantyAgency();
    const retVal = [];

    if (hasDeptOfEd && hasGuaranty) {
      retVal.push(
        <>
          <p>
            Based on your NSLDS, you have loans held by both the Department of Education and a
            guaranty agency, or multiple agencies.
          </p>
          <p>The Department of Education can be contacted via one of the following methods:</p>
        </>
      );
    } else if (hasDeptOfEd) {
      retVal.push(
        <p>
          Based on your NSLDS, your loans are held by the Department of Education and they can be
          contacted via one of the following methods:
        </p>
      );
    } else if (hasGuaranty) {
      retVal.push(
        <>
          <p>
            Based on your NSLDS, your loans are held by a guaranty agency, or multiple agencies, and
            they can be contacted via phone.
          </p>
          <p>
            <b>
              The exact request you want to place is that you would like to participate in Fresh
              Start and to get out of default.
            </b>
          </p>
        </>
      );
    }

    return retVal;
  };

  const getDeptOfEdText = () => {
    return (
      <>
        <ul>
          <li>
            Online
            <ul>
              <li>
                Go to <a href="https://myeddebt.ed.gov">myeddebt.ed.gov</a> and access your account.
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Phone
            <ul>
              <li>Call the Department of Education at 1-800-621-3115.</li>
              <li>
                <b>
                  The exact request you want to place is that you would like to participate in Fresh
                  Start and to get out of default.
                </b>
              </li>
              {qualifiedForForgiveness() && (
                <li>
                  After you place that request, the Department of Education will ask you to select a
                  repayment plan. You will want to request a 10-Year Standard Repayment.
                  <ul>
                    <li>
                      <b>
                        Please note, the Department of Education, and its servicers, are not
                        equipped to guide you through loan forgiveness to obtaining the most
                        beneficial payment. Life events, household size, and tax filing all play a
                        role in your potential monthly payment. Your employer has hired us to assist
                        you through that process upon completion of Fresh Start.
                      </b>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            Mail
            <ul>
              <li>
                Write a letter that includes your name, social security, date of birth, and the
                following: "I would like to use Fresh Start to bring my loans back into good
                standing."
              </li>
              <li>This letter should be mailed to P.O. Box 5609, Greenville, TX 75403.</li>
            </ul>
          </li>
        </ul>
      </>
    );
  };

  // const handleAgenciesModal = () => {
  //     !openAgenciesModal
  //       ? (document.body.style.overflow = 'hidden')
  //       : (document.body.style.overflow = '');

  //     document.getElementsByClassName('modal-wrapper')[0].style['position'] = 'fixed';

  //     setOpenAgenciesModal(!openAgenciesModal);
  //   };

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    // {Object.values(data)[0] && Object.values(data)[0].freshStartDefaultedLoans.filter((a) => a.heldByGuaranty).forEach((a) => console.log(a.name))}
    getGuarantyAgencyInfo();
    return (
      <>
        <div className="row">
          <div className="col-12 col-lg-12">
            {/* <Card brand="secondary">
                    <CardBody>
                        <CardText>
                        <CardTitle>Fresh Start Overview</CardTitle> */}
            <AsyncLoader request={request}>
              <FreshStartProgress />
              <DashboardSection title="Contact Loan Holder">
                <Card>
                  <CardBody>
                    <AsyncLoader request={request} loading={<></>}>
                      {getMainText()}
                      {hasDeptOfEdAgency() && getDeptOfEdText()}
                      {hasGuarantyAgency() && hasDeptOfEdAgency() && (
                        <p>Your guaranty agency(s) can be contacted via phone:</p>
                      )}
                      {hasGuarantyAgency() && getGuarantyAgencyInfo()}
                      {/* <Button brand="primary" onClick={() => {setOpenAgenciesModal(true)}}>
                                            {'Modal'}
                                        </Button>*/}
                      {!hasCompletedStep() && isCurrentStep() && (
                        <Button brand="primary" onClick={confirmContact}>
                          {'Confirm'}
                        </Button>
                      )}
                      {hasCompletedStep() && <p>You've completed this step.</p>}
                    </AsyncLoader>
                  </CardBody>
                  {/* <CardFooter>                                   
                                    </CardFooter>                         */}
                </Card>
              </DashboardSection>
            </AsyncLoader>
          </div>
        </div>
        <Modal isOpen={openAgenciesModal} fullWidth theme={'position:fixed;'}>
          <Card>
            <FlexEnd>
              <InputWrapper>
                <Button
                  brand="lowContrast"
                  onClick={() => {
                    setOpenAgenciesModal(false);
                  }}
                >
                  <FontAwesomeIcon icon={faXIcon} />
                </Button>
              </InputWrapper>
            </FlexEnd>
            <CardBody>
              <CardText>
                <Header2>
                  <strong>Qualifying Employment Definitions</strong>
                </Header2>

                <p>
                  A <b>Qualifying employer</b> includes the government, a not-for-profit
                  organization that is tax-exempt under Section 501(c)(3) of the Internal Revenue
                  Code, or a private not-for-profit organization that provides certain public
                  services. Service in an AmeriCorps or Peace Corps position is also qualifying
                  employment.
                </p>
              </CardText>
            </CardBody>
            <CardFooter>
              <Button
                brand="lowContrast"
                onClick={() => {
                  setOpenAgenciesModal(false);
                }}
              >
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
};

FreshStartContact.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  id: getUserId(state),
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.freshStart.requests.loadResource,
  ]),
  todos: Object.values(state.todos.cache),
  data: Object.values(state.freshStart.cache),
});

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    dispatch(todosSendUpdate(900)).then(() => {
      dispatch(authLoadPermissions());
      dispatch(todosLoadCollection());
    });
  },
  load: async (id) => {
    await dispatch(freshStartLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FreshStartContact));
