import actions from './actions';

import { getToken } from '../../auth';
import { getApi, getApiSingle, resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-faq',
  resourceType: 'customer-faq',
};

const operations = resourceOperationsGenerator(
  'customerFaq',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const faqLoadSubjects = () => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  let data = null;
  try {
    await dispatch(operations.customerFaqHandleCacheChange({}));
    dispatch(actions.customerFaqLoadCollectionRequest());
    data = await getApi(getToken(state), '/customer-faq/0/SUBJ');

    const cache = {
      subjects: data,
    };

    await dispatch(operations.customerFaqHandleCacheChange(cache));

    dispatch(actions.customerFaqLoadCollectionResponse());

    dispatch(actions.customerFaqLoadCollectionSuccess());
  } catch (error) {
    dispatch(operations.customerFaqHandleError(error));
    dispatch(actions.customerFaqLoadCollectionFailure(error));
  }
  return data;
};

let dataAlreadyExists = (subjectId, faq) => {
  let retVal = false;

  if (faq !== undefined && Object.values(faq).find((a) => a.id === subjectId)) {
    retVal = true;
  }
  return retVal;
};

const faqLoadQuestionAnswers = (subjId) => async (dispatch, getState) => {
  const state = getState();
  let data =
    state.customerFaq.cache.questionAnswers === undefined
      ? {}
      : state.customerFaq.cache.questionAnswers;

  try {
    dispatch(actions.customerFaqLoadResourceRequest());

    if (state.customerFaq.cache.questionAnswers === undefined || !dataAlreadyExists(subjId, data)) {
      data[subjId] = await getApiSingle(getToken(state), '/customer-faq/' + subjId + '/QA');

      const cache = {
        ...state.customerFaq.cache,
        questionAnswers: data,
      };
      await dispatch(operations.customerFaqHandleCacheChange(cache));
    }

    dispatch(actions.customerFaqLoadResourceResponse());

    dispatch(actions.customerFaqLoadResourceSuccess());
  } catch (error) {
    dispatch(operations.customerFaqHandleError(error));
    dispatch(actions.customerFaqLoadResourceFailure(error));
  }
};

const { customerFaqHandleFormChange } = operations;
export { customerFaqHandleFormChange, faqLoadSubjects, faqLoadQuestionAnswers };

export default operations;
