import React from 'react';
import LegalPage from './legal-page';
import { Card, CardBody, CardFooter, CardHeader, CardText, Link } from 'fiducius-ui';

const TermsOfUse = () => (
  <LegalPage title="Terms of Use">
    <Card>
      <CardHeader>Last Updated: May 28, 2024</CardHeader>
      <CardBody>
        <CardText>
          <p>
            Welcome to the website ("site") of Fiducius, LLC (together "Fiducius, LLC," "we," "us,"
            or "our"). This site is owned and maintained by SLS to provide information about our
            organization and services and your use of our services. Your access to and use of this
            site are subject to these Terms of Use ("Terms of Use" or "TOS"). Please read the TOS
            carefully before you start to use the site. By using our site, you accept and agree to
            be bound and abide by the TOS and our Privacy Policy, which is incorporated herein by
            reference. If you do not want to agree to our TOS or our Privacy Policy, you must exit
            the site. You also agree to abide by all applicable local, state, federal, and
            international law in your use of this site and not to use this site in any manner not
            intended by Fiducius, LLC.
          </p>

          <h4>1. Amendment of the TOS</h4>
          <p>
            We reserve the right to amend the TOS without notice. If we decide to change the TOS we
            will post those changes here. You are responsible for regularly monitoring this site in
            order to remain informed of any changes. Any such amendment shall be effective once the
            revised terms have been posted on this site and your use of this site indicates your
            acceptance of any modified terms. The date of the most recent revisions will be provided
            at the top of this page.
          </p>

          <h4>2. Modification of Site and Services</h4>
          <p>
            We reserve the right to withdraw or modify this site and/or any service or content we
            provide on the site in our sole discretion without notice and without penalty. We will
            not be liable if for any reason all or any part of the site is unavailable at any time
            or for any period. From time to time, we may restrict access to some parts of the site,
            or the entire site, to users, including registered users.
          </p>
          <p>
            You are responsible for making all arrangements necessary for you to have access to the
            site.
          </p>
          <p>
            You are responsible for ensuring that all persons, including any children or minors, who
            access the site through your internet connection are aware of the TOS, and that they
            comply with it.
          </p>
          <p>
            To access the site or some of the resources it offers, you may be asked to provide
            certain registration details or other information. It is a condition of your use of the
            site that all the information you provide on the site is correct, current and complete.
            If you choose, or you are provided with, a user name, password or any other piece of
            information as part of our security procedures, you must treat such information as
            confidential, and you must not disclose it to any third party. You agree to immediately
            notify Fiducius, LLC of any unauthorized use of your user name or password or any other
            breach of security. You also agree to ensure that you exit from your account at the end
            of each session. You should use particular caution when accessing your account from a
            public or shared computer so that others are not able to view or record your password or
            other personal information.
          </p>
          <p>
            We have the right to disable any user identification code or password, whether chosen by
            you or provided by us, at any time in our sole discretion for any or no reason,
            including, if in our opinion, you have failed to comply with any provision of the TOS.
            At our sole discretion, we may terminate, change, suspend, or discontinue any aspect of
            the site, including, but not limited to, our offered services. We may also impose limits
            on certain features of the site and restrict all or part of access to this site, without
            notice, and without being subject to penalty.
          </p>

          <h4>3. Restrictions</h4>
          <p>
            This site is available only to individuals that can form legally binding contracts under
            applicable law. By accessing and/or otherwise using this site and/or its services, you
            (a) certify that you are at least 18 years of age and (b) have read and agree to be
            legally bound by the TOS. In accordance with the Federal Children's Online Privacy
            Protection Act of 1998 (COPPA), we will never knowingly solicit, nor will we accept,
            personally identifiable information from users known to be under thirteen (13) years of
            age. However, we will accept and store data on minors submitted by you as part of the
            application information required in order for us to provide our services.
          </p>

          <h4>4. Intellectual Property Rights</h4>
          <p>
            The site and its entire contents, features and functionality (including but not limited
            to all information, software, text, displays, images, video and audio, and the design,
            selection and arrangement thereof), are owned by Fiducius, LLC, its licensors or other
            providers of such material and are protected by United States and international
            copyright, trademark, patent, trade secret and other intellectual property or
            proprietary rights laws. You must not reproduce, sell or exploit for any commercial
            purposes any part of the site, access to the site or use of the site or any services or
            materials available through the site.
          </p>
          <p>
            If you print, copy, modify, download or otherwise use any part of the site in breach of
            the TOS, your right to use the site will cease immediately and you must, at our option,
            return or destroy any copies of the materials you have made. No right, title or interest
            in or to the site or any content on the site is transferred to you, and all rights not
            expressly granted are reserved by Fiducius, LLC. Any use of the site not expressly
            permitted by the TOS is a breach of the TOS and may violate copyright, trademark and
            other laws.
          </p>

          <h4>5. Fiducius, LLC Trademarks</h4>
          <p>
            Our name, logo and all related names, logos, product and service marks, designs and
            slogans are trademarks of Fiducius, LLC or its affiliates or licensors. You must not use
            such marks without our prior written permission. All other names, brands and marks are
            either used for identification purposes only, or displayed with permission, and are the
            trademarks of their respective owners.
          </p>

          <h4>6. Communications to Fiducius, LLC; Participation Disclaimer</h4>
          <p>
            Although we encourage you to email us, you should not email us any content that contains
            personal or confidential information. With respect to all emails that you send to us,
            including but not limited to, feedback, questions, comments, suggestions, and the like,
            Fiducius, LLC shall be free to use any ideas, concepts, know-how, or techniques
            contained in your communications for any purpose whatsoever, including but not limited
            to, the development, production and marketing of our services that incorporate such
            information.
          </p>
          <p>
            We reserve the right to block or remove communications or materials that are determined
            to be (a) abusive, defamatory or obscene, (b) fraudulent, deceptive or misleading, (c)
            in violation of a copyright, trademark or; other intellectual property right of another,
            or (d) offensive or otherwise unacceptable to Fiducius, LLC in its sole discretion.
          </p>

          <h4>7. Third-Party Sites, Products and Services</h4>
          <p>
            This site may contain information provided by, and links to, third parties other than
            Fiducius, LLC and its affiliates that provide services to, or for, users of this site or
            Fiducius, LLC. Fiducius, LLC specifically does not represent or warrant that it is
            responsible for the content or performance of third-party sites or any transactions with
            them. Furthermore, Fiducius, LLC disclaims all liability and makes no representations or
            warranties for any products or services sold or provided by any third-party. Any
            transaction with a third-party is subject to the agreements and/or the terms and
            conditions of the third-party. Third-party links do not imply that Fiducius, LLC is
            affiliated or associated with, or is legally authorized to use any trademark, trade
            name, logo, or copyright symbol displayed in or accessible through the links, or that
            any linked site is authorized to use any trademark, trade name, logo, or copyright
            symbol of Fiducius, LLC.
          </p>

          <h4>8. Prohibited Uses</h4>
          <p>
            You may use the site only for lawful purposes and in accordance with the TOS. You agree
            not to use the site:
          </p>
          <ul>
            <li>
              In any way that violates any applicable federal, state, local and international law or
              regulation (including, without limitation, any laws regarding the export of data or
              software to and from the US or other countries).
            </li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any material which does
              not comply with the standards set out in the TOS.
            </li>
            <li>
              To impersonate or attempt to impersonate Fiducius, LLC or an Fiducius, LLC employee,
              another user, or person or entity (including, without limitation, the use of e-mail
              addresses or screen names associated with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of
              the site, or which, as determined by us, may harm Fiducius, LLC, its affiliates,
              partners, service providers or users of the site or expose them to liability.
            </li>
          </ul>
          <p>Additionally, you agree not to:</p>
          <ul>
            <li>
              Use the site in any manner that could disable, overburden, damage, or impair the site
              or interfere with any other party's use of the site, including their ability to engage
              in real time activities through the site.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means to access the site
              for any purpose, including to monitor or copy any of the material on the site.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on the site or for any
              other unauthorized purpose without our prior written consent.
            </li>
            <li>
              Use any device, software or routine that interferes with the proper working of the
              site.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs or other material which is
              malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of
              the site, the server on which the site is stored, or any server, computer or database
              connected to the site.
            </li>
            <li>
              Attack the site via a denial-of-service attack or a distributed denial-of-service
              attack.
            </li>
            <li>Otherwise attempt to interfere with the proper working of the site.</li>
          </ul>

          <h4>9. Reliance on Information Posted</h4>
          <p>
            The information presented on or through the site is made available solely for general
            information purposes. We do not warrant the accuracy, completeness or usefulness of this
            information. Any reliance you place on such information is strictly at your own risk. We
            disclaim all liability and responsibility arising from any reliance placed on such
            materials by you or any other visitor to the site, or by anyone who may be informed of
            any of its contents.
          </p>

          <h4>10. Changes to the Site</h4>
          <p>
            We may update the site from time to time, but its content is not necessarily complete or
            up-to-date. Any of the material on the site may be out of date at any given time, and we
            are under no obligation to update such material. We may change the site at any time with
            or without notice. We may suspend access to the site, or close it indefinitely.
          </p>

          <h4>11. Information About You and Your Visits to the Site</h4>
          <p>
            All information we collect on this site is subject to our Privacy Policy. By using the
            site, you consent to all actions taken by us with respect to your information in
            compliance with the Privacy Policy. You represent and warrant that all data provided by
            you is accurate.
          </p>

          <h4>12. Online Purchases</h4>
          <p>
            All purchases through our site or other transactions for the sale of goods, or services
            formed through the site or as a result of visits made by you are governed by our Privacy
            Policy, which is incorporated into the TOS.
          </p>

          <h4 id="SMS">13. Fiducius | 16 Hands, LLC - Short Code 89216</h4>
          <p>
            <strong>Terms of Service</strong>
          </p>
          <ol>
            <li>
              When you opt-in to the service, we will send you a message to confirm your signup.
              Text <strong>JOIN</strong> to <strong>89216</strong> to receive Fiducius Alerts.{' '}
              <strong>
                Message and data rates may apply. Your message rate will vary based on your use.
              </strong>{' '}
              Text <strong>HELP</strong> for help. Text <strong>STOP</strong> to cancel.
            </li>
            <li>
              You can cancel this service at any time. Just text <strong>STOP</strong> to{' '}
              <strong>89216</strong>. After you send the message <strong>STOP</strong> to us, we
              will send you a reply message to confirm that you have been unsubscribed. After this,
              you will no longer receive messages from us.
              <br />
              If you want to join again, just sign up as you did the first time and we will start
              sending messages to you again.
            </li>
            <li>
              If at any time you forget what keywords are supported, just text <strong>HELP</strong>{' '}
              to <strong>89216</strong>. After you send the message <strong>HELP</strong> to us, we
              will respond with instructions on how to use our service as well as how to
              unsubscribe.
            </li>
            <li>
              <strong>Participating carriers:</strong> AT&T, Verizon Wireless, Sprint, T-Mobile,
              U.S. Cellular, Boost Mobile, MetroPCS, Virgin Mobile, Alaska Communications Systems
              (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central,
              IL (ECIT), Cellular One of Northeast Pennsylvania, Cricket, Coral Wireless (Mobi PCS),
              COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI,
              Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley
              Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix
              Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos,
              Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry
              (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless,
              and West Central (WCC or 5 Star Wireless).
            </li>
            <li>Carriers are not liable for delayed or undelivered messages.</li>
            <li>
              As always, message and data rates may apply for any messages sent to you from us and
              to us from you. If you have any questions about your text plan or data plan, it is
              best to contact your wireless provider.
              <br />
              For all questions about the services provided by this short code, you can send an
              email to{' '}
              <a href="mailto:customer.service@getfiducius.com">
                customer.service@getfiducius.com{' '}
              </a>
              .
            </li>
            <li>
              If you have any questions regarding privacy, please read our{' '}
              <a href="https://www.myfiducius.com/privacy">privacy policy</a>.
            </li>
          </ol>

          <h4 id="SMS">14. Fiducius | 16 Hands, LLC - Long Codes</h4>
          <p>
            <strong>Terms of Service</strong>
          </p>
          <ol>
            <li>
              When you opt-in to the service, we will send you a message to confirm your signup.
              Text <strong>JOIN</strong> to one of our long codes to receive Fiducius Alerts.{' '}
              <strong>
                Message and data rates may apply. Your message rate will vary based on your use.
              </strong>{' '}
              Text <strong>HELP</strong> for help. Text <strong>STOP</strong> to cancel.
            </li>
            <li>
              You can cancel this service at any time. Just text <strong>STOP</strong> back to the
              long code. After you send the message <strong>STOP</strong> to us, we will send you a
              reply message to confirm that you have been unsubscribed. After this, you will no
              longer receive messages from us from that long code.
              <br />
              If you want to join again, just sign up as you did the first time and we will start
              sending messages to you again.
            </li>
            <li>
              If at any time you forget what keywords are supported, just text <strong>HELP</strong>{' '}
              to the long code. After you send the message <strong>HELP</strong> to us, we will
              respond with instructions on how to use our service as well as how to unsubscribe.
            </li>
            <li>
              <strong>Participating carriers:</strong> AT&T, Verizon Wireless, Sprint, T-Mobile,
              U.S. Cellular, Boost Mobile, MetroPCS, Virgin Mobile, Alaska Communications Systems
              (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central,
              IL (ECIT), Cellular One of Northeast Pennsylvania, Cricket, Coral Wireless (Mobi PCS),
              COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI,
              Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley
              Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix
              Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos,
              Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry
              (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless,
              and West Central (WCC or 5 Star Wireless).
            </li>
            <li>Carriers are not liable for delayed or undelivered messages.</li>
            <li>
              As always, message and data rates may apply for any messages sent to you from us and
              to us from you. If you have any questions about your text plan or data plan, it is
              best to contact your wireless provider.
              <br />
              For all questions about the services provided by this short code, you can send an
              email to{' '}
              <a href="mailto:customer.service@getfiducius.com">
                customer.service@getfiducius.com{' '}
              </a>
              .
            </li>
            <li>
              If you have any questions regarding privacy, please read our{' '}
              <a href="https://www.myfiducius.com/privacy">privacy policy</a>.
            </li>
          </ol>

          <h4>15. Geographic Restrictions</h4>
          <p>
            The owner of the site is based in the State of Ohio. We provide this site for use only
            by persons located in the United States. We make no claims that the site or any of its
            content is accessible or appropriate outside of the United States. Access to the site
            may not be legal by certain persons or in certain countries. If you access the site from
            outside the United States, you do so on your own initiative and are responsible for
            compliance with local laws.
          </p>

          <h4>16. Disclaimer of Warranties</h4>
          <p>
            You understand that we cannot and do not guarantee or warrant that files available for
            downloading from the internet or the site will be free of viruses or other destructive
            code. You are responsible for implementing sufficient procedures and checkpoints to
            satisfy your particular requirements for anti-virus protection and accuracy of data
            input and output, and for maintaining a means external to our site for any
            reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY
            A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
            MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
            PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED
            THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY SITE
            LINKED TO IT.
          </p>
          <p>
            YOUR USE OF THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS
            AT YOUR OWN RISK. THE SITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY
            KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
            COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SITE. WITHOUT LIMITING THE
            FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR
            WARRANTS THAT THE SITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE
            WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
            THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS OR THAT THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL
            OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p>
            THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
            STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
            NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
            APPLICABLE LAW.
          </p>

          <h4>17. Limitation on Liability</h4>

          <p>
            IN NO EVENT WILL Fiducius, LLC, ITS AFFILIATES OR THEIR LICENSORS, PARTNERS, SERVICE
            PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
            UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
            USE, THE SITE, ANY SITES LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER SITES OR
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER SITES, INCLUDING ANY
            DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT
            NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
            REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
            GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
            CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
          </p>
          <p>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
            APPLICABLE LAW.
          </p>

          <h4>18. Indemnification</h4>
          <p>
            You agree to defend, indemnify and hold harmless Fiducius, LLC, its affiliates and
            licensors and their respective officers, directors, employees, contractors, agents,
            licensors and suppliers from and against any claims, liabilities, damages, judgments,
            awards, losses, costs, expenses or fees (including reasonable attorneys' fees) resulting
            from your violation of the TOS or your use of the site, including, without limitation,
            any use of the site's content, services and products other than as expressly authorized
            in the TOS or your use of any information obtained from the site.
          </p>

          <h4>19. Governing Law and Jurisdiction</h4>
          <p>
            This TOS and any dispute or claim arising out of, or related to, it, their subject
            matter or their formation (in each case, including non-contractual disputes or claims)
            shall be governed by and construed in accordance with the internal laws of the State of
            Ohio without giving effect to any choice or conflict of law provision or rule (whether
            of the State of Ohio or any other jurisdiction).
          </p>
          <p>
            Any legal suit, action or proceeding arising out of, or related to, the TOS or the site
            shall be instituted exclusively in the federal courts of the United States or the courts
            of the State of Ohio in each case located in the City of Cincinnati and County of
            Hamilton, although we retain the right to bring any suit, action or proceeding against
            you for breach of these Terms of Use in your country of residence or any other relevant
            country. You agree to waive any and all objections to the exercise of jurisdiction over
            you by such courts and to venue in such courts.
          </p>

          <h4>20. Limitation on Time to File Claims</h4>
          <p>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF
            USE OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
            OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
          </p>

          <h4>21. Waiver and Severability</h4>
          <p>
            No waiver of the TOS by Fiducius, LLC shall be deemed a further or continuing waiver of
            such term or condition or any other term or condition, and any failure of Fiducius, LLC
            to assert a right or provision under the TOS shall not constitute a waiver of such right
            or provision.
          </p>
          <p>
            If any provision of these TOS is held by a court of competent jurisdiction to be
            invalid, illegal or unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of the TOS will
            continue in full force and effect.
          </p>

          <h4>22. Entire Agreement</h4>
          <p>
            The TOS and our Privacy Policy constitute the sole and entire agreement between you and
            Fiducius, LLC with respect to the site and supersede all prior and contemporaneous
            understandings, agreements, representations and warranties, both written and oral, with
            respect to the site.
          </p>

          <h4>23. Your Comments and Concerns</h4>
          <p>
            This site is operated by Fiducius, LLC at:
            <br />
            151 West 4th St.
            <br />
            Suite 700C
            <br />
            Cincinnati, OH 45202
            <br />
            <a href="tel:5136455400">(513) 645-5400</a>
            <br />
            <br />
            All feedback, comments, requests for technical support and other communications relating
            to the site including notices of copyright infringement claims should be directed to:{' '}
            <a href="mailto:customer.service@getfiducius.com">customer.service@getfiducius.com </a>
            <br />
            <br />
            Thank you for visiting the site.
          </p>
        </CardText>
      </CardBody>
      <CardFooter>
        <Link to="/dashboard">Return to dashboard</Link>
      </CardFooter>
    </Card>
  </LegalPage>
);

export default TermsOfUse;
