import React from 'react';
import styled from 'styled-components';

import t from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  ProgressBar,
  Header4,
  Header5,
} from 'fiducius-ui';

import PaymentHelp from './payment-help';

import { withPermissions, PaddedDD } from '../../routing';
import { formatAsMoney, formatAsPercent, convertIsoToSlash } from '../../utils';
import { getContributionData } from '../../benefits/contribution/redux/selectors';
import { getContributionSetupData } from '../../contribution-setup';

import { getEmployerMonthlyAmountDisplay } from '../redux/selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import AvailablePaymentHistoryTable from './available-payment-history-table';

const PaddedDiv = styled.div`
  padding-bottom: 1em;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;
const DivRight = styled.div`
  text-align: right;
`;

const PaymentHelpButton = styled(Button)`
  margin: 0.5em;
`;

const StyledButton = styled(Button)`
  margin-left: 0em;
`;

class ActiveContribution extends React.Component {
  static propTypes = {
    key: t.any,
    data: t.object.isRequired,
    setup: t.object,
    dataLevel: t.number,
    contributionData: t.object,
    customerContributionData: t.object,
    permissions: t.object,
    onEditContribution: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showHelp: false, showModal: false };

    this.closeHelp = this.closeHelp.bind(this);
    this.openHelp = this.openHelp.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  closeHelp() {
    this.setState({ ...this.state, showHelp: false });
  }

  openHelp() {
    this.setState({ ...this.state, showHelp: true });
  }

  closeModal() {
    this.setState({ ...this.state, showModal: false });
  }

  openModal() {
    this.setState({ ...this.state, showModal: true });
  }

  getProgressBar() {
    const { data } = this.props;
    let stepsCompleted = 0;
    let stageText = 'Account Denied for Contribution';
    if (
      data.contributionStatus === 'CREATED' ||
      data.contributionStatus === 'WAITINIT' ||
      data.contributionStatus === 'WAITREVAL'
    ) {
      stepsCompleted = 1;
      stageText = 'Waiting on Account Statement';
    } else if (data.contributionStatus === 'UPLOAD') {
      stepsCompleted = 2;
      stageText = 'Account Statement Uploaded and Under Review';
    } else if (data.contributionStatus === 'APPROVE') {
      stepsCompleted = 3;
      stageText = 'Account Approved for Contribution';
    }

    return (
      <>
        <dl>
          <dd>
            <ProgressBar
              categories={[
                {
                  brand: 'success',
                  name: 'success',
                  title: 'Completed',
                  value: stepsCompleted > 0 ? (100 * stepsCompleted) / 3 : 0,
                },

                {
                  brand: 'info',
                  name: 'info',
                  title: 'Current',
                  value: stepsCompleted > 0 && stepsCompleted < 3 ? 100 / 3 : 0,
                },
                {
                  brand: 'warning',
                  name: 'warning',
                  title: 'To Be Completed',
                  value:
                    stepsCompleted > 0
                      ? (100 * (stepsCompleted === 3 ? 0 : 3 - (stepsCompleted + 1))) / 3
                      : 0,
                },
                {
                  brand: 'danger',
                  name: 'danger',
                  title: 'Denied',
                  value: stepsCompleted === 0 ? 100 : 0,
                },
              ]}
            />
          </dd>
          <dd>Stage: {stageText}</dd>
        </dl>
      </>
    );
  }

  getEmployerContribution() {
    const { data } = this.props;
    let retVal = 0;
    if (data.paymentHistory !== null && Object.values(data.paymentHistory).length > 0) {
      Object.values(data.paymentHistory).forEach((element) => {
        retVal += element.employerAmount;
      });
    }
    return retVal;
  }

  getMonthlyEmployerContribution(monthlyAccountPayment, monthlyEmployerContribution) {
    const { setup } = this.props;
    let retVal = monthlyEmployerContribution;
    if (
      setup.nonPslfAllowCustomEmployeeContribution === 'PMTAMT' &&
      monthlyAccountPayment < monthlyEmployerContribution
    ) {
      retVal = monthlyAccountPayment;
    }
    return retVal;
  }

  getEmployeeContribution() {
    const { data } = this.props;
    let retVal = 0;
    if (data.paymentHistory !== null && Object.values(data.paymentHistory).length > 0) {
      Object.values(data.paymentHistory).forEach((element) => {
        retVal += element.employeeAmount;
      });
    }
    return retVal;
  }

  allowedEdit() {
    const { data } = this.props;
    return data.contributionStatus !== 'DENIED';
  }

  showEmployeeContribution() {
    const { setup, permissions } = this.props;
    let retVal = true;

    if (
      !permissions.showForgiveness &&
      (setup.nonPslfAllowCustomEmployeeContribution === 'NO' ||
        setup.nonPslfAllowCustomEmployeeContribution === 'PMTNOPD' ||
        setup.nonPslfAllowCustomEmployeeContribution === 'PMTAMT')
    ) {
      retVal = false;
    }

    return retVal;
  }

  getBaseInfo() {
    const { data, dataLevel, contributionData, onEditContribution } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-12 col-sm-5">
            {dataLevel > 0 && <CardTitle>{data.account}</CardTitle>}
            {dataLevel === 0 && (
              <dl>
                <dd>
                  <Header5>{data.account}</Header5>
                </dd>
                <PaddedDD></PaddedDD>
              </dl>
            )}
          </div>
          <div className="col-12 col-sm-7">
            <CardText>
              <div className="row">
                <div className="col-10">{this.getProgressBar()}</div>

                {dataLevel === 2 && this.allowedEdit() && (
                  <DivRight className="col-2">
                    <StyledButton
                      invert
                      brand="secondary"
                      onClick={() => onEditContribution(data.id)}
                      title={'Edit Contribution'}
                    >
                      <FontAwesomeIcon icon={faEdit} fixedWidth />
                    </StyledButton>
                  </DivRight>
                )}
              </div>
            </CardText>
          </div>
        </div>
        <CardText>
          {dataLevel === 1 && (
            <>
              <Header4>Current Elections</Header4>
              <div className="row">
                <div className="col-auto">
                  <dl>
                    <dt>Employer Allocation</dt>
                    <PaddedDD>
                      {getEmployerMonthlyAmountDisplay(
                        data.contributionType,
                        data.employerContribution,
                        this.getMonthlyEmployerContribution(
                          data.monthlyAccountPayment,
                          contributionData.monthlyEmployerContribution
                        )
                      )}
                    </PaddedDD>
                  </dl>
                </div>
                {this.showEmployeeContribution() && (
                  <div className="col-auto">
                    <dl>
                      <dt>Employee Allocation</dt>
                      <PaddedDD>{formatAsMoney(data.employeeContribution)}</PaddedDD>
                    </dl>
                  </div>
                )}
              </div>
            </>
          )}
        </CardText>
      </>
    );
  }

  getFooterText() {
    return (
      <>
        <PaddedDiv className="row">
          <div className="col-12">
            Note: Contribution payments are submitted within 3-5 business days of the Scheduled
            Contribution Date. However, please note that the processing time may vary with different
            loan servicers.
          </div>
        </PaddedDiv>
      </>
    );
  }

  getDetailedInfo() {
    const { data, contributionData, permissions } = this.props;

    return (
      <CardText>
        <div className="row">
          <div className="col-auto">
            <dl>
              <dt>Total Employer Contribution</dt>
              <PaddedDD>{formatAsMoney(this.getEmployerContribution())}</PaddedDD>
            </dl>
          </div>
          {this.showEmployeeContribution() && (
            <div className="col-auto">
              <dl>
                <dt>Total Employee Contribution</dt>
                <PaddedDD>{formatAsMoney(this.getEmployeeContribution())}</PaddedDD>
              </dl>
            </div>
          )}
        </div>
        <Header4>Account Information on File</Header4>
        <div className="row">
          <div className="col-auto">
            <dl>
              <dt>Account Type</dt>
              <PaddedDD>{data.accountType}</PaddedDD>
            </dl>
          </div>

          <div className="col-auto">
            <dl>
              <dt>Account Balance</dt>
              <PaddedDD>{formatAsMoney(data.currentBalance)}</PaddedDD>
            </dl>
          </div>

          <div className="col-auto">
            <dl>
              <dt>Monthly Payment</dt>
              <PaddedDD>{formatAsMoney(data.monthlyAccountPayment)}</PaddedDD>
            </dl>
          </div>

          <div className="col-auto">
            <dl>
              <dt>Interest Rate</dt>
              <PaddedDD>{formatAsPercent(data.interestRate)}</PaddedDD>
            </dl>
          </div>
        </div>
        <Header4>Current Elections</Header4>
        <div className="row">
          <div className="col-auto">
            <dl>
              <dt>Employer Allocation</dt>
              <PaddedDD>
                {getEmployerMonthlyAmountDisplay(
                  data.contributionType,
                  data.employerContribution,
                  this.getMonthlyEmployerContribution(
                    data.monthlyAccountPayment,
                    contributionData.monthlyEmployerContribution
                  )
                )}
              </PaddedDD>
            </dl>
          </div>
          {this.showEmployeeContribution() && (
            <div className="col-auto">
              <dl>
                <dt>Employee Allocation</dt>
                <PaddedDD>{formatAsMoney(data.employeeContribution)}</PaddedDD>
              </dl>
            </div>
          )}
        </div>
      </CardText>
    );
  }

  getRecentPaymentInfo() {
    const { data, dataLevel } = this.props;

    return (
      <CardText>
        {dataLevel === 1 && (
          <>
            {' '}
            <Header4>Most Recent Contribution Payment</Header4>
            <div className="row">
              <div className="col-12">
                {data.paymentHistory === null ||
                  (Object.values(data.paymentHistory).length === 0 && (
                    <div className="col">
                      <p>No payments on file for this account.</p>
                    </div>
                  ))}
                {data.paymentHistory !== null && Object.values(data.paymentHistory).length > 0 && (
                  <div className="row">
                    <div className="col-3">
                      <dl>
                        <dt>Employer Allocation</dt>
                        <PaddedDD>{formatAsMoney(data.paymentHistory[0].employerAmount)}</PaddedDD>
                      </dl>
                    </div>
                    {this.showEmployeeContribution() && (
                      <div className="col-3">
                        <dl>
                          <dt>Employee Allocation</dt>
                          <PaddedDD>
                            {formatAsMoney(data.paymentHistory[0].employeeAmount)}
                          </PaddedDD>
                        </dl>
                      </div>
                    )}
                    <div className="col-3">
                      <dl>
                        <dt>Contribution Date</dt>
                        <PaddedDD>
                          {data.paymentHistory[0].paymentSuccessDate !== undefined &&
                          data.paymentHistory[0].paymentSuccessDate !== null
                            ? convertIsoToSlash(data.paymentHistory[0].paymentSuccessDate)
                            : convertIsoToSlash(data.paymentHistory[0].paymentScheduleDate)}
                        </PaddedDD>
                      </dl>
                    </div>
                    <div className="col-3">
                      <dl>
                        <dt>Loan Due Date</dt>
                        <PaddedDD>
                          {data.dueDate !== undefined && data.dueDate !== null
                            ? convertIsoToSlash(data.dueDate)
                            : ''}
                        </PaddedDD>
                      </dl>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-auto">
            <Header4>
              Next Scheduled Contribution Payment{' '}
              <PaymentHelpButton brand="primary" onClick={this.openHelp}>
                <PaddedIcon icon={faQuestionCircle} fixedWidth />
                How are Payments Scheduled
              </PaymentHelpButton>
            </Header4>
          </div>
          {dataLevel === 2 &&
            data.paymentHistory !== null &&
            Object.values(data.paymentHistory).length > 0 && (
              <DivRight className="col">
                <Button brand="primary" onClick={this.openModal}>
                  Show Payment History
                </Button>
              </DivRight>
            )}
        </div>
        <div className="row">
          <div className="col-12">
            {data.scheduledPayments === null ||
              (Object.values(data.scheduledPayments).length === 0 && (
                <div className="col">
                  <p>No scheduled payments on file for this account.</p>
                </div>
              ))}
            {data.scheduledPayments !== null && Object.values(data.scheduledPayments).length > 0 && (
              <div className="row">
                <div className="col-3">
                  <dl>
                    <dt>Employer Allocation</dt>
                    <PaddedDD>
                      {formatAsMoney(
                        data.scheduledPayments[Object.values(data.scheduledPayments).length - 1]
                          .employerAmount
                      )}
                    </PaddedDD>
                  </dl>
                </div>
                {this.showEmployeeContribution() && (
                  <div className="col-3">
                    <dl>
                      <dt>Employee Allocation</dt>
                      <PaddedDD>
                        {formatAsMoney(
                          data.scheduledPayments[Object.values(data.scheduledPayments).length - 1]
                            .employeeAmount
                        )}
                      </PaddedDD>
                    </dl>
                  </div>
                )}
                <div className="col-3">
                  <dl>
                    <dt>Scheduled Contribution Date</dt>
                    <PaddedDD>
                      {convertIsoToSlash(
                        data.scheduledPayments[Object.values(data.scheduledPayments).length - 1]
                          .paymentScheduleDate
                      )}
                    </PaddedDD>
                  </dl>
                </div>
                <div className="col-3">
                  <dl>
                    <dt>Loan Due Date</dt>
                    <PaddedDD>
                      {data.dueDate !== undefined && data.dueDate !== null
                        ? convertIsoToSlash(data.dueDate)
                        : ''}
                    </PaddedDD>
                  </dl>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardText>
    );
  }

  render() {
    const { data, dataLevel = 0 } = this.props;

    if (!data.active) {
      return null;
    } else if (dataLevel === 0) {
      return this.getBaseInfo();
    } else if (dataLevel === 1) {
      return (
        <>
          <Card>
            <CardBody>
              {this.getBaseInfo()}
              {this.getRecentPaymentInfo()}
              {this.getFooterText()}
            </CardBody>
          </Card>
          <PaymentHelp data={data} isOpen={this.state.showHelp} closeForm={this.closeHelp} />
          <AvailablePaymentHistoryTable
            data={data.paymentHistory}
            employeeContribution={this.getEmployeeContribution()}
            isOpen={this.state.showModal}
            closeForm={this.closeModal}
          />
        </>
      );
    } else if (dataLevel === 2) {
      return (
        <>
          <Card>
            <CardBody>
              {this.getBaseInfo()}
              {this.getDetailedInfo()}
              {this.getRecentPaymentInfo()}
              {this.getFooterText()}
            </CardBody>
          </Card>
          <PaymentHelp data={data} isOpen={this.state.showHelp} closeForm={this.closeHelp} />
          <AvailablePaymentHistoryTable
            data={data.paymentHistory}
            employeeContribution={this.getEmployeeContribution()}
            isOpen={this.state.showModal}
            closeForm={this.closeModal}
          />
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contributionData: getContributionData(state),
    setup: getContributionSetupData(state),
    customerContributionData: state.customerContribution.cache,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ActiveContribution));
