import React from 'react';
import styled from 'styled-components';
import { Alert, Button } from 'fiducius-ui';

const StyledAlert = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  & > button {
    margin-left: auto;
  }
`;

const QualifiedTuition = () => (
  <Alert brand="primary">
    <StyledAlert>
      If you are participating in your employer's tuition assistance benefit, please click here to
      enter information for your upcoming classes.
      <Button
        brand="primary"
        invert
        onClick={() => {
          window.location.href = '/benefits/tuition/overview';
        }}
      >
        Click here to get set up
      </Button>
    </StyledAlert>
  </Alert>
);

QualifiedTuition.propTypes = {};

export default QualifiedTuition;
