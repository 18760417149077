import React from 'react';
import t from 'prop-types';
import {
  Form,
  Header2,
  Header4,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputRadio,
  InputSelect,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import {
  convertIsoToSlash,
  useFocusOnMount,
  useScrollToTopOnMount,
  safeAccess,
} from '../../../utils';
import { Fade } from '../../../routing';

const FRIEND = 'Friend or colleague';
const OTHER = 'Other';

export const referrerHasExplanation = (value) => value === FRIEND || value === OTHER;

const referOpts = [
  '',
  'Email from employer',
  'Employer newsletter',
  'Employer benefits website',
  'Employer benefits guide',
  'Poster or flyer',
  'Benefits fair or onsite meeting',
  FRIEND,
  'Email from Fiducius',
  'Fiducius website',
  OTHER,
];

const TabInfo = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  const firstInput = useFocusOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us how you heard about us</Header2>
      <InputWrapper error={!!errors.referrerName}>
        <InputLabel htmlFor="referrerName" optional>
          How did you first learn about Fiducius?
        </InputLabel>
        <InputError>{safeAccess(errors, 'referrerName.detail')}</InputError>
        <InputGroup>
          <InputSelect
            inputRef={firstInput}
            name="referrerName"
            defaultValue={data.referrerName}
            options={referOpts}
          />
        </InputGroup>
        <InputHelp>Leave this blank if you found us on your own</InputHelp>
      </InputWrapper>
      <Fade id="referrerGate" show={referrerHasExplanation(data.referrerName)}>
        <InputWrapper error={!!errors.referrerExplanation}>
          <InputLabel htmlFor="referrerExplanation">
            {data.referrerName === FRIEND && "Friend or colleague's name"}
            {data.referrerName === OTHER && 'Please specify'}
          </InputLabel>
          <InputError>{safeAccess(errors, 'referrerExplanation.detail')}</InputError>
          <InputGroup>
            <InputText name="referrerExplanation" defaultValue={data.referrerExplanation} />
          </InputGroup>
        </InputWrapper>
      </Fade>
      <InputWrapper error={!!errors.knowSomeoneWithLoans}>
        <InputLabel>Do you know anyone with more than $5,000 of student loan debt?</InputLabel>
        <InputError>{safeAccess(errors, 'knowSomeoneWithLoans.detail')}</InputError>
        <InputRadio
          name="knowSomeoneWithLoans"
          value="Y"
          label="Yes"
          defaultChecked={data.knowSomeoneWithLoans === 'Y'}
        />
        <InputRadio
          name="knowSomeoneWithLoans"
          value="N"
          label="No"
          defaultChecked={data.knowSomeoneWithLoans === 'N'}
        />
      </InputWrapper>
      <Header2>Tell us about your current job</Header2>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.hireDate}>
            <InputLabel htmlFor="hireDate">Hire Date</InputLabel>
            <InputError>{safeAccess(errors, 'hireDate.detail')}</InputError>
            <InputGroup>
              <InputMask
                defaultValue={convertIsoToSlash(data.hireDate)}
                mask="##/##/####"
                name="hireDate"
                placeholder="MM/DD/YYYY"
              />
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.jobTitle}>
            <InputLabel htmlFor="jobTitle">Job Title</InputLabel>
            <InputError>{safeAccess(errors, 'jobTitle.detail')}</InputError>
            <InputGroup>
              <InputText name="jobTitle" defaultValue={data.jobTitle} />
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
      <InputWrapper error={!!errors.covidFurlough}>
        <InputLabel>
          Were you temporarily laid off or furloughed in the past year due to COVID-19?
        </InputLabel>
        <InputError>{safeAccess(errors, 'covidFurlough.detail')}</InputError>
        <InputRadio
          name="covidFurlough"
          value="Y"
          label="Yes"
          defaultChecked={data.covidFurlough === 'Y'}
        />
        <InputRadio
          name="covidFurlough"
          value="N"
          label="No"
          defaultChecked={data.covidFurlough === 'N'}
        />
      </InputWrapper>
      <Header4>
        <InputLabel optional>Home Address</InputLabel>
      </Header4>
      <InputWrapper error={!!errors.homeAddressOne}>
        <InputLabel htmlFor="homeAddressOne">Address Line 1</InputLabel>
        <InputError>{safeAccess(errors, 'homeAddressOne.detail')}</InputError>
        <InputGroup>
          <InputText name="homeAddressOne" defaultValue={data.homeAddressOne} />
        </InputGroup>
      </InputWrapper>
      <InputWrapper error={!!errors.homeAddressTwo}>
        <InputLabel htmlFor="homeAddressTwo">Address Line 2</InputLabel>
        <InputError>{safeAccess(errors, 'homeAddressTwo.detail')}</InputError>
        <InputGroup>
          <InputText name="homeAddressTwo" defaultValue={data.homeAddressTwo} />
        </InputGroup>
      </InputWrapper>
      <InputWrapper error={!!errors.homeCity}>
        <InputLabel htmlFor="homeCity">City</InputLabel>
        <InputError>{safeAccess(errors, 'homeCity.detail')}</InputError>
        <InputGroup>
          <InputText name="homeCity" defaultValue={data.homeCity} />
        </InputGroup>
      </InputWrapper>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.homeState}>
            <InputLabel htmlFor="homeState">State</InputLabel>
            <InputError>{safeAccess(errors, 'homeState.detail')}</InputError>
            <InputGroup>
              <InputSelect
                name="homeState"
                defaultValue={data.homeState}
                options={[
                  '',
                  'AL',
                  'AK',
                  'AR',
                  'AZ',
                  'CA',
                  'CO',
                  'CT',
                  'DC',
                  'DE',
                  'FL',
                  'GA',
                  'HI',
                  'IA',
                  'ID',
                  'IL',
                  'IN',
                  'KS',
                  'KY',
                  'LA',
                  'MA',
                  'MD',
                  'ME',
                  'MI',
                  'MN',
                  'MO',
                  'MS',
                  'MT',
                  'NC',
                  'NE',
                  'NH',
                  'NJ',
                  'NM',
                  'NV',
                  'NY',
                  'ND',
                  'OH',
                  'OK',
                  'OR',
                  'PA',
                  'PR',
                  'RI',
                  'SC',
                  'SD',
                  'TN',
                  'TX',
                  'UT',
                  'VT',
                  'VA',
                  'WA',
                  'WI',
                  'WV',
                  'WY',
                ]}
              />
            </InputGroup>
          </InputWrapper>
        </div>
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.homeZipCode}>
            <InputLabel htmlFor="homeZipCode">Zip Code</InputLabel>
            <InputError>{safeAccess(errors, 'homeZipCode.detail')}</InputError>
            <InputGroup>
              <InputText name="homeZipCode" defaultValue={data.homeZipCode} />
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
    </Form>
  );
};

TabInfo.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabInfo;
