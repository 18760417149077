import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';

import { Button, Card, CardBody, CardTitle, CardText, CardFooter } from 'fiducius-ui';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getToken } from '../../auth';
import { PaddedBadge } from '../../routing';
import { editableGrade } from '../redux/selectors';
import { format, parseISO } from 'date-fns';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';

import { tuitionInstitutionLoadCollection } from '../../tuition-institution';

import { default as TuitionInstitutionClassForm } from './tuition-institution-class-form';

import {
  tuitionInstitutionClassClearForm,
  tuitionInstitutionClassCopyResourceToForm,
  tuitionInstitutionClassHandleFormChange,
  tuitionInstitutionClassLoadResource,
  classDeleteResource,
  classUpdateResource,
} from '../redux/operations';

import { todosLoadCollection } from '../../todos';

import { debounce, mergeRequestStatuses, getApi } from '../../utils';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const DivEnd = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 1em;
`;

class TuitionInstitutionClassRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    termId: t.number.isRequired,
    classId: t.number.isRequired,
    data: t.object,
    form: t.object,
    errors: t.object,
    getRequest: t.object,
    request: t.object,
    loadClass: t.func.isRequired,
    clearClassForm: t.func.isRequired,
    handleClassForm: t.func.isRequired,
    updateClass: t.func.isRequired,
    handleDeleteClass: t.func.isRequired,
    hydrateClassForm: t.func.isRequired,
    token: t.any,
    hasMissingInformation: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      isDeleting: false,
      formId: null,
      allowedCertifications: {},
      endDate: null,
    };
  }

  componentDidMount() {
    this.getCertificationDropDownOptions();
  }

  getCertificationDropDownOptions = () => {
    getApi(this.props.token, '/drop-down-list/Certification__A').then((data) => {
      let certifications = {};
      Object.keys(data).forEach((a) => {
        certifications[data[a].description] = data[a].description;
      });
      this.setState({ ...this.state, allowedCertifications: certifications });
    });
  };

  getInstitutionList = () => {
    const { data } = this.props;
    let realInstitutions = Object.keys(data).reduce(
      (obj, k) => ({
        ...obj,
        [k]: data[k].institutionName,
      }),
      {}
    );
    let institutions = { ...realInstitutions };
    return institutions;
  };

  getTermList = (institutionId) => {
    const { data } = this.props;
    if (institutionId !== undefined) {
      const termList = data[institutionId].terms;
      return Object.keys(termList).reduce(
        (obj, k) => ({
          ...obj,
          [k]: termList[k].termName,
        }),
        {}
      );
    }
  };

  editClass = () => {
    const classId = this.props.data[this.props.institutionId].terms[this.props.termId].classes[
      this.props.classId
    ].id;
    const endDate = this.props.data[this.props.institutionId].terms[this.props.termId].endDate;

    this.props.hydrateClassForm(classId);
    this.setState({
      formId: classId,
      showForm: true,
      isDeleting: false,
      endDate: endDate,
    });
  };
  deleteClass = () => {
    const classId = this.props.data[this.props.institutionId].terms[this.props.termId].classes[
      this.props.classId
    ].id;
    this.props.hydrateClassForm(classId);
    this.setState({ formId: classId, showForm: true, isDeleting: true });
  };

  onChangeClass = debounce((tuitionInstitutionClass, id, attributes) => {
    this.props.handleClassForm({ ...this.props.form, ...tuitionInstitutionClass }, [attributes]);
  }, 250);

  onUpdateClass = () => {
    this.props.updateClass(this.props.form.id);
  };

  onDeleteClass = () => {
    this.props.handleDeleteClass(this.props.form.id);
  };

  renderMissingInfoLengend = () => {
    this.props.hasMissingInformation(true);
  };

  closeForm = () => {
    this.props.clearClassForm();
    this.setState({
      ...this.state,
      showForm: false,
      formType: null,
      institutionId: null,
      termId: null,
    });
  };

  setGradeTextValue = (grade, degreeType, endDate) => {
    let termEndDate;
    const today = new Date();

    if (endDate !== undefined) {
      termEndDate = new Date(format(parseISO(endDate), 'MM/dd/yyyy'));
    }

    if (termEndDate < today) {
      if (degreeType !== 'CERT') {
        if (grade === 'AC') {
          grade = 'Credit';
        } else if (grade === 'FW') {
          grade = 'Withdrawal';
        } else if (grade === 'FI') {
          grade = 'Incomplete';
        }
      } else {
        if (grade === 'A') {
          grade = 'Pass';
        } else if (grade === 'F') {
          grade = 'Fail';
        }
      }
    } else {
      grade = 'Term In Progress';
    }
    return grade;
  };

  isClassCurrentAndEditable = (thisClass) => {
    let retVal = false;
    if (editableGrade.includes(thisClass.currentStatusCode)) {
      retVal = true;
    }

    return retVal;
  };

  render() {
    const {
      data = {},
      institutionId,
      termId,
      classId,
      request,
      errors,
      form = {},
      getRequest,
    } = this.props;
    const { showForm, formId, isDeleting } = this.state;

    const institution = data[institutionId];
    const term = data[institutionId].terms[termId];
    const thisClass = data[institutionId].terms[termId].classes[classId];
    const classCurrent = this.isClassCurrentAndEditable(thisClass);

    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row">
                <div className="col">{thisClass.className}</div>
                <DivEnd className="col-5">
                  <StyledButton
                    invert
                    brand="secondary"
                    onClick={() => this.editClass()}
                    disabled={!thisClass.editable}
                    title={
                      !thisClass.editable
                        ? 'A class in a reimbursement pending, complete or denied status cannot be edited.'
                        : 'Edit Class'
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                  </StyledButton>
                  <StyledButton
                    invert
                    brand="danger"
                    onClick={() => this.deleteClass()}
                    disabled={!thisClass.deletable}
                    title={
                      !thisClass.deletable
                        ? 'A class in a reimbursement pending, complete or denied status cannot be deleted or if a document has been uploaded for it.'
                        : 'Delete Class'
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </StyledButton>
                </DivEnd>
              </div>
            </CardTitle>
            <CardText>
              <div className="row">
                <dl className="col-4">
                  <dt>
                    Grade
                    {classCurrent &&
                      (thisClass.grade === null ||
                        thisClass.grade === undefined ||
                        thisClass.grade === '') && (
                        <PaddedBadge brand="primary">
                          {this.renderMissingInfoLengend()}
                          <FontAwesomeIcon icon={faSolidExclamation} />
                        </PaddedBadge>
                      )}
                  </dt>
                  <dd>
                    {this.setGradeTextValue(thisClass.grade, institution.degreeType, term.endDate)}
                  </dd>
                  <dt>Hours</dt>
                  <dd>{thisClass.hours}</dd>
                </dl>
                <dl className="col-4">
                  <dt>Current Status</dt>
                  <dd>{thisClass.currentStatus}</dd>
                </dl>
                <dl className="col-4"></dl>
              </div>
            </CardText>
          </CardBody>
          {false && (
            <FlexFooter>
              <Button
                invert
                brand="secondary"
                onClick={() => this.editClass()}
                disabled={!thisClass.editable}
                title={
                  !thisClass.editable
                    ? 'You cannot edit a class that is in a pending, completed, or denied status.'
                    : ''
                }
              >
                Edit
              </Button>
              <Button
                invert
                brand="danger"
                onClick={() => this.deleteClass()}
                disabled={!thisClass.deletable}
                title={
                  !thisClass.deletable
                    ? 'You cannot delete a class that has been reimbursed or is in a pending, completed, or denied status.'
                    : ''
                }
              >
                Delete
              </Button>
            </FlexFooter>
          )}
        </Card>
        {showForm && (
          <TuitionInstitutionClassForm
            action={formId ? (isDeleting ? 'Delete' : 'Update') : 'Add'}
            closeForm={this.closeForm}
            data={form}
            errors={errors}
            isLoading={request.isLoading}
            getResource={getRequest}
            isOpen={showForm}
            onChange={this.onChangeClass}
            onSubmit={
              formId ? (isDeleting ? this.onDeleteClass : this.onUpdateClass) : this.onCreateClass
            }
            institutionList={this.getInstitutionList()}
            termList={this.getTermList(this.props.institutionId || form.institutionId)}
            isCertification={institution.degreeType === 'CERT'}
            allowedCertifications={this.state.allowedCertifications}
            endDate={this.state.endDate}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests, errors, form } = state.tuitionInstitutionClass;
  return {
    data: getTuitionInstitutionData(state),
    token: getToken(state),
    getRequest: requests.loadResource,
    errors: errors,
    form: form,
    request: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearClassForm: () => dispatch(tuitionInstitutionClassClearForm()),
  handleClassForm: (tuitionInstitutionClass, attributes) =>
    dispatch(tuitionInstitutionClassHandleFormChange(tuitionInstitutionClass, attributes)),
  hydrateClassForm: async (id) => {
    await dispatch(tuitionInstitutionClassLoadResource(id));
    await dispatch(tuitionInstitutionClassCopyResourceToForm(id));
  },
  updateClass: async (id) => {
    await dispatch(classUpdateResource(id));
    await dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
    dispatch(tuitionInstitutionClassClearForm());
  },
  handleDeleteClass: async (id) => {
    await dispatch(classDeleteResource(id));
    await dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
    dispatch(tuitionInstitutionClassClearForm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionClassRow);
