import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import { mergeRequestStatuses } from '../../../utils';

import ForgivenessProgress from '../components/forgiveness-progress';
import StandardConfirmation from '../components/standard-confirmation';

class BtsQuestionnaire extends React.Component {
  static propTypes = {
    data: t.object,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  showEmpty = (questNeeded) => {
    if (!questNeeded) {
      return (
        <Card brand="primary">
          <CardBody>
            <CardTitle>StudentFORGIVE Snapshot Questionnaire</CardTitle>
            <CardText>
              <p>Thank you for completing your questionnaire. You may review your results now.</p>
            </CardText>
          </CardBody>
        </Card>
      );
    }
  };

  render() {
    const { request, todos } = this.props;

    let questNeeded = todos.find((a) => a.id === 150);
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <AsyncLoader request={request} empty={this.showEmpty(questNeeded)}>
            {questNeeded && (
              <Card brand="primary">
                <CardBody>
                  <CardTitle>StudentFORGIVE Snapshot Questionnaire</CardTitle>
                  <CardText>
                    <p>
                      In order to determine if you are on track with your loan forgiveness plan,
                      please fill out and submit the Snapshot Questionnaire.
                    </p>
                    <StandardConfirmation stepId={150} />
                  </CardText>
                </CardBody>
              </Card>
            )}
            {this.showEmpty(questNeeded)}
          </AsyncLoader>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(BtsQuestionnaire));
