import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Badge, Card, CardBody, CardFooter, CardTitle, Link } from 'fiducius-ui';
import { mergeRequestStatuses, convertIsoToSlash, debounce, safeAccess } from '../../../utils';
import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { educationalReengagementLoadResource } from '../redux/operations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faExclamationDark } from '@fortawesome/pro-solid-svg-icons/faExclamation';

import styled from 'styled-components';

const PaddedBadge = styled(Badge)`
  margin-left: 0.45em;
  font-size: 0.85em;
  vertical-align: middle;
`;

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const EducationalReengagementSummary = ({ request, id, load, data = {} }) => {
  useEffect(() => {
    load(id);
  }, []);

  const translateDegree = (degree) => {
    let retVal = '';
    switch (degree) {
      case 'NONE':
        retVal = 'None';
        break;
      case 'ASSOC':
        retVal = 'Associates';
        break;
      case 'BA':
        retVal = 'Bachelors';
        break;
      case 'MA':
        retVal = 'Masters';
        break;
      case 'PHD':
        retVal = 'Doctorate';
        break;
      default:
        break;
    }

    return retVal;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12">
          <Card brand="primary">
            <CardBody>
              <CardTitle>Educational Reengagement</CardTitle>
              <AsyncLoader request={request}>
                <p>Institution: {Object.values(data) && safeAccess(data, 'institution', '')}</p>
                <p>
                  Degree: {Object.values(data) && translateDegree(safeAccess(data, 'degree', ''))}
                </p>
                <p>
                  Expected Return to School Date:{' '}
                  {Object.values(data) &&
                  data.expectedReturnToSchoolDate &&
                  safeAccess(data, 'expectedReturnToSchoolDate').length > 0 ? (
                    convertIsoToSlash(data.expectedReturnToSchoolDate)
                  ) : (
                    <PaddedBadge brand="primary">
                      <FontAwesomeIcon icon={faExclamationDark} />
                    </PaddedBadge>
                  )}
                </p>
                <p>
                  Expected Graduation Date:{' '}
                  {Object.values(data) &&
                  data.expectedGraduationDate &&
                  safeAccess(data, 'expectedGraduationDate').length > 0 ? (
                    convertIsoToSlash(data.expectedGraduationDate)
                  ) : (
                    <PaddedBadge brand="primary">
                      <FontAwesomeIcon icon={faExclamationDark} />
                    </PaddedBadge>
                  )}
                </p>
                <p>
                  Our team of expert advisors will ensure you understand what options are available
                  to you based on your return to school. Please visit your Overview page, by
                  selecting <b>My Educational Reengagement</b> below, for more information.
                </p>
              </AsyncLoader>
            </CardBody>
            <FlexFooter>
              <Link to="/benefits/educational-reengagement/overview">
                My Educational Reengagement
              </Link>
            </FlexFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

EducationalReengagementSummary.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  id: getUserId(state),
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.educationalReengagement.requests.loadCollection,
    state.educationalReengagement.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
  data: Object.values(state.educationalReengagement.cache)[0],
  form: state.educationalReengagement.form,
  errors: state.educationalReengagement.errors,
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(educationalReengagementLoadResource(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(EducationalReengagementSummary));
