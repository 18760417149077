import actions from './actions';
import { AgreementValidator } from './validators';
import { resourceOperationsGenerator } from '../../../utils';

const agreementValidator = new AgreementValidator();

const endpointHandler = {
  endpoint: '/sign-contract-questions',
  customResponseHandler: null,
  resourceType: 'sign-contract-questions',
};

const operations = resourceOperationsGenerator(
  'agreement',
  actions,
  null,
  agreementValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  agreementCopyResourceToForm,
  agreementHandleFormChange,
  agreementInvalidateCache,
  agreementLoadResource,
  agreementUpdateResource,
  agreementValidateAttributes,
  agreementValidateState,
} = operations;

export {
  agreementCopyResourceToForm,
  agreementHandleFormChange,
  agreementInvalidateCache,
  agreementLoadResource,
  agreementUpdateResource,
  agreementValidateAttributes,
  agreementValidateState,
};

export default operations;
