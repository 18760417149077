import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText, DashboardSection, AsyncLoader } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import LwoConfirmation from '../components/lwo-confirmation';
import { limitedWaiverOpportunityLoadResource } from '../redux/operations';
import { getUserId } from '../../../auth';
import ResultsTable from '../components/results-table';
import { mergeRequestStatuses } from '../../../utils';

class LwoComplete extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    load: t.func,
    id: t.string.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  render() {
    const { data = {}, permissions, request } = this.props;
    let currentLwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);
    if (currentLwoStep === undefined) {
      currentLwoStep = {};
      currentLwoStep.id = 'UnDef';
    }
    return (
      <DashboardSection>
        <AsyncLoader request={request}>
          <Card>
            <CardBody>
              {data && currentLwoStep.id === 'Complete' && (
                <div className="row">
                  <div className="col-12">
                    <CardTitle>You're Done For Now</CardTitle>
                    <CardText>
                      <p>
                        Congratulations, you have completed all of the steps to put Assure&#8480; to
                        work for you student loan forgiveness. Please refer to the Overview page for
                        more information.
                      </p>
                    </CardText>
                  </div>
                </div>
              )}
              {data && currentLwoStep.id !== 'Complete' && (
                <div className="row">
                  <div className="col-12">
                    <CardTitle>You Have Steps Left To Complete</CardTitle>
                    <LwoConfirmation stepId={610} />
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
          {data &&
            data.submittedLoans !== undefined &&
            data.submittedLoans !== null &&
            data.submittedLoans.length > 0 && (
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <CardTitle>Estimated Qualifying Payment Information</CardTitle>
                          <ResultsTable loans={data.eligibleLoans} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-12 col-lg-6">
                  <Card>
                    <CardBody>
                      <div className="row">
                        <div className="col-12">
                          <CardTitle>Actual Qualifying Payment Information</CardTitle>
                          <ResultsTable loans={data.submittedLoans} showEstimated={false} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            )}
        </AsyncLoader>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const lwoRequests = state.limitedWaiverOpportunity.requests;
  return {
    employers: state.employmentHistory.cache,
    id: getUserId(state),
    data: state.limitedWaiverOpportunity.cache[getUserId(state)],
    request: mergeRequestStatuses([lwoRequests.loadResource, state.todos.requests.loadCollection]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoComplete));
