import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CardBody, Loader } from 'fiducius-ui';

import { ShadowAddCard } from '../../routing';

import LoaderWrapper from '../styles/loader-wrapper';
import { isAuthenticated } from '../redux/selectors';
import { authLogout } from '../redux/operations';

class Logout extends React.Component {
  static propTypes = {
    isAuthenticated: t.bool.isRequired,
    isLoading: t.bool.isRequired,
    signOut: t.func.isRequired,
    auth: t.object,
  };

  componentDidMount() {
    // On mount we log out the user
    const { isAuthenticated, signOut, auth } = this.props;
    if (isAuthenticated) {
      window.hideChat();
      signOut(auth.username);
    }
  }

  render() {
    const { isAuthenticated, isLoading } = this.props;

    // User has been logged out, redirect them to login
    if (!isAuthenticated && !isLoading) {
      return <Redirect to="/login" />;
    }

    // Render loading while we wait for the logout
    return (
      <ShadowAddCard>
        <CardBody>
          <LoaderWrapper>
            <Loader variant="atom" size={3} />
          </LoaderWrapper>
        </CardBody>
      </ShadowAddCard>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  isLoading: state.auth.requests.logout.isLoading,
  auth: state.auth.cache,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: (username) => dispatch(authLogout(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
