export const degreeGradeList = {
  '': '',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  F: 'F',
  AC: 'Credit',
  FW: 'Withdrawal',
  FI: 'Incomplete',
};

export const certificationGradeList = { '': ' ', A: 'Pass', F: 'Fail' };

export const editableGrade = ['WAITPRFRPT', 'WAITPRF', 'WAITRPT', 'PRFRPTUPLD'];

export const editableClassName = [
  'REIMDENY',
  'REIMSENT',
  'COMPLETE',
  'REIMOVER',
  'PREPAYCOMP',
  'PSTAPPR',
  'PRFRPTDENY',
];
