import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  DashboardSection,
  InputWrapper,
} from 'fiducius-ui';
import { FlexEnd } from '../../../root';
import EducationalReengagementForm from '../components/edu-reengagement-form';
import { mergeRequestStatuses, convertIsoToSlash, debounce, safeAccess } from '../../../utils';
import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
import { todosLoadCollection } from '../../../todos/redux/operations';

import {
  educationalReengagementHandleFormChange,
  educationalReengagementLoadResource,
  educationalReengagementUpdateResource,
} from '../redux/operations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faExclamationDark } from '@fortawesome/pro-solid-svg-icons/faExclamation';

import styled from 'styled-components';

const PaddedBadge = styled(Badge)`
  margin-left: 0.45em;
  font-size: 0.85em;
  vertical-align: middle;
`;

const EducationalReengagementOverview = ({
  request,
  id,
  load,
  data = {},
  form,
  errors,
  handleForm,
  updateEducationalReengagement,
}) => {
  useEffect(() => {
    load(id);
  }, []);

  const [redirectUri, setRedirectUri] = useState('');

  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const closeForm = () => {
    setOpenUpdateModal(false);
  };

  const updateInstitutionDates = () => {
    updateEducationalReengagement(id).then(() => {
      closeForm();
    });
  };

  const translateDegree = (degree) => {
    let retVal = '';
    switch (degree) {
      case 'NONE':
        retVal = 'None';
        break;
      case 'ASSOC':
        retVal = 'Associates';
        break;
      case 'BA':
        retVal = 'Bachelors';
        break;
      case 'MA':
        retVal = 'Masters';
        break;
      case 'PHD':
        retVal = 'Doctorate';
        break;
      default:
        break;
    }

    return retVal;
  };

  const onChange = debounce((educationalReengagement, id, attribute) => {
    let formData = form;
    handleForm({ ...formData, ...educationalReengagement }, [attribute]);
  }, 250);

  const handleUpdateModal = () => {
    !openUpdateModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');

    setOpenUpdateModal(!openUpdateModal);
  };

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <>
        <div className="row">
          <div className="col-12 col-lg-12">
            <DashboardSection title="Educational Reengagement">
              <div className="row">
                <div className="col-6 col-lg-6">
                  <Card>
                    <CardBody>
                      <CardTitle>Overview</CardTitle>
                      <p>
                        Congratulations on your plans to further your education in the future. Going
                        back to school is a big commitment requiring significant preparation and
                        planning. Don't worry, our team of expert advisors will ensure you
                        understand what options are available to you based on your return to school.
                      </p>
                      <p>What should you do now?</p>
                      <ul>
                        <li>
                          Great question! You want to remain on your current loan repayment plan and
                          always keep us up to speed on your schooling plans.
                        </li>
                        <li>
                          Don't forget to keep us updated on important milestone dates like when you
                          plan on returning to school and when you're expecting to graduate.
                        </li>
                      </ul>
                      <p>What can you expect from Fiducius?</p>
                      <ul>
                        <li>
                          We'll be in touch as your milestone dates approach to ensure you can take
                          advantage of opportunities to improve your financial wellness at that
                          time.
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-6 col-lg-6">
                  <Card>
                    <CardBody>
                      <CardTitle>School Information</CardTitle>
                      <AsyncLoader request={request}>
                        <p>
                          Institution: {Object.values(data) && safeAccess(data, 'institution', '')}
                        </p>
                        <p>
                          Degree:{' '}
                          {Object.values(data) && translateDegree(safeAccess(data, 'degree', ''))}
                        </p>
                        <p>
                          Expected Return to School Date:{' '}
                          {Object.values(data) &&
                          data.expectedReturnToSchoolDate &&
                          safeAccess(data, 'expectedReturnToSchoolDate').length > 0 ? (
                            convertIsoToSlash(data.expectedReturnToSchoolDate)
                          ) : (
                            <PaddedBadge brand="primary">
                              <FontAwesomeIcon icon={faExclamationDark} />
                            </PaddedBadge>
                          )}
                        </p>
                        <p>
                          Expected Graduation Date:{' '}
                          {Object.values(data) &&
                          data.expectedGraduationDate &&
                          safeAccess(data, 'expectedGraduationDate').length > 0 ? (
                            convertIsoToSlash(data.expectedGraduationDate)
                          ) : (
                            <PaddedBadge brand="primary">
                              <FontAwesomeIcon icon={faExclamationDark} />
                            </PaddedBadge>
                          )}
                        </p>
                        <div className="row">
                          <div className="col-12">
                            <FlexEnd>
                              <InputWrapper>
                                <Button id={'update'} brand={'primary'} onClick={handleUpdateModal}>
                                  {'Update Information'}
                                </Button>
                              </InputWrapper>
                            </FlexEnd>
                          </div>
                        </div>
                      </AsyncLoader>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </DashboardSection>
          </div>
        </div>
        {openUpdateModal && (
          <EducationalReengagementForm
            closeForm={closeForm}
            data={form}
            errors={errors}
            isLoading={request.isLoading}
            isOpen={openUpdateModal}
            onChange={onChange}
            onSubmit={updateInstitutionDates}
          />
        )}
      </>
    );
  }
};

EducationalReengagementOverview.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  id: getUserId(state),
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.educationalReengagement.requests.loadCollection,
    state.educationalReengagement.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
  data: Object.values(state.educationalReengagement.cache)[0],
  form: state.educationalReengagement.form,
  errors: state.educationalReengagement.errors,
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(educationalReengagementLoadResource(id));
  },
  handleForm: (educationalReengagement, attributes) =>
    dispatch(educationalReengagementHandleFormChange(educationalReengagement, attributes)),
  updateEducationalReengagement: async (id) => {
    await dispatch(educationalReengagementUpdateResource(id));
    dispatch(todosLoadCollection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(EducationalReengagementOverview));
