import { FormValidator } from '../../utils';
import { format } from 'date-fns';

export class TuitionInstitutionTermValidator extends FormValidator {
  institutionId(validator) {
    validator.isRequired();
    validator.hasMaxLength(50);
  }

  termName(validator) {
    validator.isRequired();
    validator.hasMaxLength(50);
  }

  startDate(validator, state) {
    validator.isRequired();
    let startDateLimit = new Date();
    startDateLimit.setDate(startDateLimit.getDate() + 90);
    startDateLimit = format(startDateLimit, 'MM/dd/yyyy');

    validator.validDate();
    validator.isBeforeDate(startDateLimit);
    if (state.endDate) {
      validator.isBeforeDate(state.endDate);
    }
  }

  endDate(validator, state) {
    validator.isRequired();
    validator.validDate();
    validator.isAfterDate(state.startDate);
  }

  totalCost(validator) {
    validator.isRequired();
    validator.validNumber();
  }

  costPerCreditHour(validator) {
    if (validator.value) {
      validator.validNumber();
    }
  }
}
