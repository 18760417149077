import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardText } from 'fiducius-ui';
import Messages from '../components/messages';
import { loadMessage } from '../redux/operations';

const MessageTile = ({ tileTitle, loadMessage, messageType, data = {}, brand }) => {
  useEffect(() => {
    loadMessage(messageType);
  }, []);

  const dataObject = data && data.length > 0 && data.find((a) => a.id === messageType); // [0].messages && data[0].messages.find((a) => a.messageType === messageType);
  const messageObject =
    dataObject && Object.keys(dataObject).length > 0
      ? dataObject.messages && dataObject.messages.find((a) => a.id === messageType)
      : undefined;
  const contentType = messageObject && messageObject.contentType;
  const avatarLink = messageObject && messageObject.avatarLink;
  const messageContent = messageObject && messageObject.messageContent;
  const showAvatar = avatarLink && avatarLink.length > 0;
  const showMessage = messageContent && messageContent.length > 0;
  const displayContent = showAvatar || showMessage;

  return (
    <>
      {displayContent && (
        <Card brand={brand}>
          <CardBody>
            <CardTitle>{tileTitle}</CardTitle>
            <CardText>
              <Messages
                messageType={messageType}
                contentType={contentType}
                avatarLink={avatarLink}
                messageContent={messageContent}
                showAvatar={showAvatar}
                showMessage={showMessage}
              />
            </CardText>
          </CardBody>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: Object.values(state.messages.cache),
});

MessageTile.propTypes = {
  load: t.func.isRequired,
  loadMessage: t.func.isRequired,
  messageType: t.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  loadMessage: async (id) => {
    await dispatch(loadMessage(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageTile);
