import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  Link,
  BrandColor,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
import { formatAsMoney, convertIsoToSlash } from '../../../utils';

import PaymentCompare from '../components/payment-compare';
import RepaymentPie from '../components/repayment-pie';
import { forgivenessLoadResource } from '../redux/operations';
import {
  getForgivenessData,
  getMonthlyPayment,
  getMonthlySavings,
  getNewPayment,
  getOldPayment,
  getStandardPayment,
  getTotalForgiveness,
  getTotalIdrRepayment,
  getTotalStandardRepayment,
} from '../redux/selectors';

import ForgivenessProgress from './forgiveness-progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

class ForgivenessSummary extends React.Component {
  static propTypes = {
    data: t.object,
    id: t.string.isRequired,
    load: t.func.isRequired,
    monthlyPayment: t.number,
    monthlySavings: t.number,
    newPayment: t.number,
    oldPayment: t.number,
    permissions: t.object,
    request: t.object.isRequired,
    standardPayment: t.number,
    totalForgiveness: t.number,
    totalIdrRepayment: t.number,
    totalStandardRepayment: t.number,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '' };
  }

  componentDidMount() {
    const { id, load } = this.props;
    load(id);
  }

  redirectToFAQ = () => {
    this.setState({ ...this.state, redirectURI: '/faq' });
  };

  render() {
    const {
      data = {},
      monthlyPayment,
      monthlySavings,
      newPayment,
      oldPayment,
      permissions,
      request,
      standardPayment,
      totalForgiveness,
      totalIdrRepayment,
      totalStandardRepayment,
    } = this.props;

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      return (
        <Card brand="secondary">
          <CardBody>
            <div className="row">
              <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                <CardTitle>
                  Forgiveness {permissions.inPreSale && <small>{'(Estimated Values)'}</small>}
                </CardTitle>
              </div>
              <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <Button brand="primary" onClick={this.redirectToFAQ}>
                  <PaddedIcon icon={faQuestionCircle} fixedWidth />
                  Frequently Asked Questions
                </Button>
              </div>
            </div>
            <AsyncLoader request={request}>
              <CardText>
                <div className="row">
                  <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <dl>
                      <dt>Qualifying Plan</dt>
                      <dd>{data.plan || '—'}</dd>
                      <dt>Standard Monthly Payment</dt>
                      <dd>{standardPayment < 0 ? '—' : formatAsMoney(standardPayment)}</dd>
                      <dt>Total Standard Repayment</dt>
                      <dd>{formatAsMoney(totalStandardRepayment)}</dd>
                      {data.btsReviewDate !== null && (
                        <>
                          <dt>StudentFORGIVE Snapshot Date</dt>
                          <dd>{convertIsoToSlash(data.btsReviewDate) || '—'}</dd>
                        </>
                      )}
                      {data.btsReviewDate === null && (
                        <>
                          <dt>Annual Recertification Date</dt>
                          <dd>{data.recertDate ? convertIsoToSlash(data.recertDate) : '—'}</dd>
                        </>
                      )}
                      {permissions.clientStatus === 'CRET' && data.renewalQuestionnaireDate && (
                        <>
                          <dt>Deadline to Complete Recertification</dt>
                          <dd>
                            {data.renewalRecertificationDeadline
                              ? convertIsoToSlash(data.renewalRecertificationDeadline)
                              : '—'}
                          </dd>
                          {data.renewalDocumentDate && (
                            <>
                              <dt>Recertification Documents Due Date</dt>
                              <dd>
                                {data.renewalDocumentDate
                                  ? convertIsoToSlash(data.renewalDocumentDate)
                                  : '—'}
                              </dd>
                            </>
                          )}
                        </>
                      )}
                    </dl>
                  </div>
                  <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <dl>
                      <dt>Status</dt>
                      <dd>{data.status || '—'}</dd>
                      <dt>Income-Driven Monthly Payment</dt>
                      <dd>
                        {monthlyPayment < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlyPayment)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Total Income-Driven Forgiveness</dt>
                      <dd>
                        {totalForgiveness < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(totalForgiveness)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Monthly Savings</dt>
                      <dd>
                        {monthlySavings < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlySavings)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
                {permissions.clientStatus !== 'NFPSS' && (
                  <div className="row">
                    <div className="col-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      {standardPayment > 0 && standardPayment > monthlyPayment && (
                        <PaymentCompare
                          oldPayment={oldPayment}
                          newPayment={newPayment}
                          year={data.year}
                        />
                      )}
                    </div>
                    <div className="col-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <RepaymentPie
                        repay={totalIdrRepayment}
                        forgive={totalForgiveness}
                        cutout={65}
                      />
                    </div>
                  </div>
                )}
              </CardText>
              {!permissions.btsQuestionnaireCompleted && (
                <ForgivenessProgress removeContainer={true} />
              )}
            </AsyncLoader>
          </CardBody>
          <FlexFooter>
            <Link to="/benefits/forgiveness/overview">My Forgiveness</Link>
            {permissions.btsQuestionnaireCompleted && (
              <Link to="/benefits/forgiveness/bts-review">StudentFORGIVE Snapshot</Link>
            )}
            <Link to="/accounts">My Accounts</Link>
          </FlexFooter>
        </Card>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  data: getForgivenessData(state),
  id: getUserId(state),
  monthlyPayment: getMonthlyPayment(state),
  monthlySavings: getMonthlySavings(state),
  newPayment: getNewPayment(state),
  oldPayment: getOldPayment(state),
  request: state.forgiveness.requests.loadResource,
  standardPayment: getStandardPayment(state),
  totalForgiveness: getTotalForgiveness(state),
  totalIdrRepayment: getTotalIdrRepayment(state),
  totalStandardRepayment: getTotalStandardRepayment(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(forgivenessLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ForgivenessSummary));
