import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/simple-authorize',
  resourceType: 'simple-authorize',
};

const operations = resourceOperationsGenerator(
  'legal',
  actions,
  null,
  null,
  null,
  null,
  null,
  endpointHandler,
  null
);

const { legalHandleFormChange, legalUpdateResource } = operations;

export { legalHandleFormChange, legalUpdateResource };

export default operations;
