import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardHeader,
  BrandColor,
  Header5,
  Button,
  Link,
} from 'fiducius-ui';

import { Fade } from '../../routing';
import { formatAsMoney } from '../../utils';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const SpacedButton = styled(Button)`
  margin: 1em;
`;

const QuickQualResults = ({ data, onBackClick }) => {
  return (
    <Card>
      <CardBody>
        <CardTitle>Fiducius Quick Qualifier Results</CardTitle>
        <div className="row">
          <div className="col-12 col-md-6">
            <Card brand="primary">
              <CardHeader>Forgiveness</CardHeader>
              <CardBody>
                <CardText>
                  <Fade id="qualifyForgiveness" show={data.message !== 'DNQ'}>
                    <Header5>Is it possible to lower my monthly payments?</Header5>
                    <Spaced>
                      You may qualify for a federal program that would lower your monthly payment to
                      <strong>
                        <BrandColor brand="primary">
                          {' '}
                          {data.ibRepay < 0 ? '—' : formatAsMoney(data.ibRepay)}{' '}
                        </BrandColor>
                      </strong>
                      and potentially forgive
                      <strong>
                        <BrandColor brand="primary">
                          {' '}
                          {data.lbf < 0 ? '—' : formatAsMoney(data.lbf)}{' '}
                        </BrandColor>
                      </strong>
                      of your loans. This is based on {data.message} repayment plan.
                    </Spaced>
                  </Fade>
                  <Fade id="dnqForgiveness" show={data.message === 'DNQ'}>
                    <Spaced>
                      We're sorry, but based on the information you provided you do not look like
                      you're eligible for an income-driven repayment plan.
                    </Spaced>
                  </Fade>
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 col-md-6">
            <Card brand="primary">
              <CardHeader>Refinance</CardHeader>
              <CardBody>
                <CardText>
                  <Header5>Are you paying too much interest on your student loans?</Header5>
                  <Spaced>
                    Find out if you can lower your interest rate or change your terms to save money.
                    Check out your Refinance Tab or the <Link to="/faq">FAQ's</Link> to learn more.
                  </Spaced>
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>
        <SpacedButton
          className="col-4"
          onClick={onBackClick}
          brand="primary"
          name="backToQuestions"
        >
          Back To Questions
        </SpacedButton>
      </CardBody>
    </Card>
  );
};

QuickQualResults.propTypes = {
  data: t.object,
  onBackClick: t.func.isRequired,
};

export default QuickQualResults;
