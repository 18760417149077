import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AsyncLoader, Header2, Badge } from 'fiducius-ui';

import { documentsDisableValidation, documentsLoadCollection } from '../redux/operations';
import ApiFile from './api-file';
import UploadSuccess from '../components/upload-success';
import NsldsFileLink from '../components/nslds-file-link';

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

class DocumentLibrary extends React.Component {
  static propTypes = {
    files: t.object,
    loadDocs: t.func.isRequired,
    request: t.object,
    errors: t.object,
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      errorCount: 0,
      openModal: false,
      isTrUpload: false,
      errors: [],
      keyCount: 0,
    };
  }

  componentDidMount() {
    this.props.loadDocs();
  }

  closeForm = () => {
    if (this.props.errors && this.props.errors.generalErrors) {
      this.props.errors.generalErrors = [];
    }
    this.setState({ ...this.state, openModal: false, errors: [] });
  };

  openModal = () => {
    this.setState({ ...this.state, openModal: true });
  };

  handleUpload = (isTrDoc, refreshPage) => {
    //isTrDoc is a boolean that determines if uploaded doc is for TR.
    this.setState({
      isTrUpload: isTrDoc,
      openModal: true,
    });
    if (refreshPage) {
      this.props.loadDocs();
    }
  };

  getOtherProps = () => {
    const { files } = this.props;
    const id = Object.keys(files).length * -1 - 1;
    return {
      addError: this.addError,
      key: id,
      uploadCompleted: () => this.handleUpload(false),
      file: {
        description: 'Other',
        exampleFile: null,
        fileTypeCd: 'OTH', // depends on database file type
        id: id,
        name: null,
        type: 'doc',
      },
    };
  };

  needsNSLDS = () => {
    const { files } = this.props;
    const matches = Object.keys(files).filter((k) => files[k].fileTypeCd === 'LNDT');
    return matches.length > 0;
  };

  addError = (error) => {
    const { errors } = this.state;
    errors.push(error);
    this.setState({ ...this.state, errors: errors });
  };

  getErrors = () => {
    if (this.state.errors.length > 0) {
      return this.state.errors[0];
    } else if (
      this.props.errors &&
      this.props.errors.generalErrors &&
      this.props.errors.generalErrors.length > 0
    ) {
      return this.props.errors.generalErrors[0];
    }
    return undefined;
  };

  render() {
    const { files, request } = this.props;
    //283907, 285483

    return (
      <>
        <Header2>Manage your important benefit documents here</Header2>
        <AsyncLoader request={request}>
          <FlexWrap>
            {files &&
              Object.keys(files)
                .sort((a, b) => {
                  if (files[a].description < files[b].description) {
                    return -1;
                  }
                  if (files[a].description > files[b].description) {
                    return 1;
                  }
                  return 0;
                })
                .map((key) => {
                  if (
                    files[key].fileTypeCd === 'LNDT' /*|| files[key].fileTypeCd === 'SNLDS'*/ &&
                    (files[key].name === undefined ||
                      files[key].name === null ||
                      files[key].name === '')
                  ) {
                    return (
                      <NsldsFileLink
                        key={key}
                        link={'/nslds'}
                        name={<Badge brand="danger">Go To Upload</Badge>}
                        title={files[key].description}
                        type="document"
                      />
                    );
                  } else {
                    return (
                      <ApiFile
                        key={key}
                        file={files[key]}
                        exampleFile={files[key].exampleFile}
                        uploadCompleted={this.handleUpload}
                        requiredUpload
                        addError={this.addError}
                      />
                    );
                  }
                })}
            <ApiFile {...this.getOtherProps()} />
          </FlexWrap>
          <div id="uploadError" />
          <UploadSuccess
            isOpen={this.state.openModal}
            errors={this.getErrors()}
            closeForm={this.closeForm}
            trFileUpload={this.state.isTrUpload}
          />
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  files: state.documents.cache,
  errors: state.documents.errors,
  request: state.documents.requests.loadCollection,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocs: () => {
    dispatch(documentsDisableValidation());
    dispatch(documentsLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentLibrary);
