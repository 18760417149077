import React, { useState, useEffect, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faCheck as faCheckDark } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faExclamation as faExclamationDark } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { AsyncLoader, Badge, Button, CardBody, CardText, Loader } from 'fiducius-ui';

import { todosLoadCollection } from '../../todos';
import { authLoadPermissions } from '../../auth';
import { mergeRequestStatuses } from '../../utils';
import LoaderWrapper from '../../auth/styles/loader-wrapper';
import {
  getPlaidToken,
  setPlaidPublicToken,
  unlinkPlaidAccount,
  savePlaidLinkMetadata,
} from '../redux/operations';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaddedBadge = styled(Badge)`
  margin-left: 0.45em;
  font-size: 0.85em;
  vertical-align: middle;
`;

const PlaidLink = ({
  request,
  getPlaidToken,
  plaidData,
  setPlaidPublicToken,
  plaidConnectionId,
  keyId,
  setLoadingToggle,
  loadingToggle,
  unlinkPlaidAccount,
  savePlaidLinkMetadata,
}) => {
  const [token, setToken] = useState(null);

  const [linkTokenGenerated, setLinkTokenGenerated] = useState(false);

  const onSuccess = useCallback(async (publicToken, metadata) => {
    await setPlaidPublicToken(plaidConnectionId, publicToken, metadata);
    setLoadingToggle(!loadingToggle);
  }, []);

  const onExit = () => {};

  const onEvent = useCallback(async (event, metadata) => {
    if (event === 'OPEN' || event === 'EXIT' || event === 'HANDOFF') {
      await savePlaidLinkMetadata(event, metadata);
    }
  }, []);

  let isOauth = false;

  const config = {
    token,
    onSuccess,
    onExit,
    onEvent,
  };

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes('?oauth_state_id=')) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { error, open, ready, exit } = usePlaidLink(config);

  useEffect(() => {
    let isMounted = true;
    if (token === null) {
      getPlaidToken(plaidConnectionId).then(() => {
        if (isMounted) setLinkTokenGenerated(true);
      });
    }
    if (isOauth && ready) {
      // open();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (token === null && linkTokenGenerated) {
      setToken(plaidData[keyId].linkToken);
    }
  }, [linkTokenGenerated]);

  const plaidToken = plaidData[keyId];
  return (
    <>
      <AsyncLoader request={request}>
        {plaidToken.linkToken !== 'previously connected' && (
          <>
            {/* <CardText>
            {plaidToken.linkToken === 'previously connected' && (
              <>                
                 <ButtonWrapper>
                        <Button brand="primary" size="lg" onClick={() => {
                          setToken(null);
                          setLinkTokenGenerated(false);
                          unlinkPlaidAccount(plaidConnectionId);                                
                          }}>
                            Un-Link Account
                        </Button>                
                    </ButtonWrapper>                         
              </>
            )}
          </CardText> */}
            {/* {plaidToken.linkToken === null && (
            <LoaderWrapper>
              <Loader variant="atom" size={2} />
            </LoaderWrapper>
          )} */}
            {plaidToken.linkToken !== null &&
              plaidToken.linkToken !== 'previously connected' &&
              (plaidToken.plaidConnectionId === -1 ||
                plaidToken.connectionStatus === 'loginrequired') && (
                <>
                  <CardBody>
                    <ButtonWrapper>
                      <Button
                        brand="primary"
                        size="lg"
                        onClick={() => {
                          open();
                        }}
                        disabled={!ready}
                      >
                        {plaidToken.connectionStatus === 'loginrequired' && (
                          <div>&nbsp;&nbsp;Re-link Account&nbsp;&nbsp;</div>
                        )}
                        {plaidToken.connectionStatus !== 'loginrequired' && (
                          <div>&nbsp;Link Account(s)&nbsp;</div>
                        )}
                      </Button>
                    </ButtonWrapper>
                  </CardBody>
                </>
              )}
          </>
        )}
      </AsyncLoader>
    </>
  );
};

PlaidLink.propTypes = {
  request: t.object,
  getPlaidToken: t.func.isRequired,
  setPlaidPublicToken: t.func.isRequired,
  unlinkPlaidAccount: t.func.isRequired,
  savePlaidLinkMetadata: t.func.isRequired,
  todos: t.array,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.plaid.requests.loadResource,
    state.plaid.requests.updateResource,
    state.plaid.requests.loadCollection,
  ]),
  plaidData: Object.values(state.plaid.cache),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  getPlaidToken: async (plaidConnectionId) => {
    await dispatch(getPlaidToken(plaidConnectionId));
  },
  setPlaidPublicToken: async (id, token, metadata) => {
    await dispatch(setPlaidPublicToken(id, token, metadata));
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  unlinkPlaidAccount: async (id) => {
    await dispatch(unlinkPlaidAccount(id));
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  savePlaidLinkMetadata: async (event, metadata) => {
    await dispatch(savePlaidLinkMetadata(event, metadata));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaidLink);
