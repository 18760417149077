import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/simplifi',
  resourceType: 'simplifi',
};

const operations = resourceOperationsGenerator(
  'simplifi',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  null,
  null
);

const { simplifiLoadResource } = operations;

export { simplifiLoadResource };

export default operations;
