import React, { useRef, useState } from 'react';
import t from 'prop-types';
import { Button } from 'fiducius-ui';
import styled from 'styled-components';
import { pdfjs, Document, Page } from 'react-pdf';

import { useResize } from '../../utils';

// See https://github.com/wojtekmaj/react-pdf#browserify-and-others for the purpose of this global
// namespace shim.
// It's related to this bug: https://github.com/wojtekmaj/react-pdf/issues/280 and should be
// removed when that's fixed to properly support create-react-app etc.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentPane = styled.div`
  border: ${(p) => p.theme.borderWidth} solid ${(p) => p.theme.level2};
  border-radius: ${(p) => p.theme.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  overflow: auto;
  padding-left: 1rem;
`;

const DocumentPageControls = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
  & > button {
    margin-right: 2rem;
  }
`;

const PdfViewer = ({ base64, isFulfillment }) => {
  const paneRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [state, setState] = useState({ numPages: null, pageNumber: 1 });
  useResize(paneRef, setWidth);

  const onLoadSuccess = ({ numPages }) => {
    setState({ ...state, numPages });
  };

  //using variables to set the color does not work, so we need 2 functions.
  const textLayerOffsetBlack = () => {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.color = 'black';
      style.opacity = '0.5';
      style.lineHeight = '1';
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  };

  const textLayerOffsetTransparent = () => {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.color = 'transparent';
      style.opacity = '0.5';
      style.lineHeight = '1';
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  };

  return (
    <DocumentPane ref={paneRef}>
      {base64 && (
        <Document file={'data:application/pdf;base64,' + base64} onLoadSuccess={onLoadSuccess}>
          <Page
            pageNumber={state.pageNumber}
            width={width - 16}
            // onLoadSuccess={isFulfillment ? textLayerOffsetTransparent : textLayerOffsetBlack}
          />
        </Document>
      )}
      <DocumentPageControls>
        <Button
          brand={state.pageNumber <= 1 ? 'quaternary' : 'primary'}
          onClick={() => setState({ ...state, pageNumber: state.pageNumber - 1 })}
          disabled={state.pageNumber <= 1}
        >
          Previous
        </Button>
        <Button
          brand={state.pageNumber >= state.numPages ? 'quaternary' : 'primary'}
          onClick={() => setState({ ...state, pageNumber: state.pageNumber + 1 })}
          disabled={state.pageNumber >= state.numPages}
        >
          Next
        </Button>
      </DocumentPageControls>
    </DocumentPane>
  );
};

PdfViewer.propTypes = {
  base64: t.string,
  isFulfillment: t.bool,
};

export default PdfViewer;
