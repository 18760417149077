import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/limited-waiver-opportunity',
  resourceType: 'limited-waiver-opportunity',
};

const operations = resourceOperationsGenerator(
  'limitedWaiverOpportunity',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  limitedWaiverOpportunityLoadResource,
  limitedWaiverOpportunityUpdateResource,
  limitedWaiverOpportunityCopyResourceToForm,
} = operations;

export {
  limitedWaiverOpportunityLoadResource,
  limitedWaiverOpportunityUpdateResource,
  limitedWaiverOpportunityCopyResourceToForm,
};

export default operations;
