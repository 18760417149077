import React from 'react';
import t from 'prop-types';
import {
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';
import styled from 'styled-components';

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const BillingForm = ({ data = {}, errors = {}, handleChange }) => (
  <Form id="billingForm" handleChange={handleChange} defaults={data}>
    <div className="row">
      <div className="col-6">
        <InputWrapper error={!!errors.firstName}>
          <InputLabel2>First Name</InputLabel2>
          <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
          <InputGroup>
            <InputText name="firstName" defaultValue={data.firstName} />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-6">
        <InputWrapper error={!!errors.lastName}>
          <InputLabel2>Last Name</InputLabel2>
          <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
          <InputGroup>
            <InputText name="lastName" defaultValue={data.lastName} />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
    <InputWrapper error={!!errors.phone}>
      <InputLabel2 htmlFor="phone">Phone Number</InputLabel2>
      <InputError>{safeAccess(errors, 'phone.detail')}</InputError>
      <InputGroup affordance="medium">
        <InputMask
          defaultValue={data.phone}
          mask="(###) ###-####"
          name="phone"
          placeholder="(___) ___-____"
          type="tel"
        />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.email}>
      <InputLabel2>Email</InputLabel2>
      <InputError>{safeAccess(errors, 'email.detail')}</InputError>
      <InputGroup affordance="large">
        <InputText name="email" defaultValue={data.email} />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.address1}>
      <InputLabel2>Address Line 1</InputLabel2>
      <InputError>{safeAccess(errors, 'address1.detail')}</InputError>
      <InputGroup>
        <InputText name="address1" defaultValue={data.address1} />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.address2}>
      <InputLabel2>Address Line 2</InputLabel2>
      <InputError>{safeAccess(errors, 'address2.detail')}</InputError>
      <InputGroup>
        <InputText name="address2" defaultValue={data.address2} />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.city}>
      <InputLabel2>City</InputLabel2>
      <InputError>{safeAccess(errors, 'city.detail')}</InputError>
      <InputGroup>
        <InputText name="city" defaultValue={data.city} />
      </InputGroup>
    </InputWrapper>
    <div className="row">
      <div className="col-6">
        <InputWrapper error={!!errors.state}>
          <InputLabel2>State</InputLabel2>
          <InputError>{safeAccess(errors, 'state.detail')}</InputError>
          <InputGroup>
            <InputText name="state" defaultValue={data.state} />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-6">
        <InputWrapper error={!!errors.zipCode}>
          <InputLabel2>Zip Code</InputLabel2>
          <InputError>{safeAccess(errors, 'zipCode.detail')}</InputError>
          <InputGroup>
            <InputText name="zipCode" defaultValue={data.zipCode} />
          </InputGroup>
        </InputWrapper>
      </div>
    </div>
  </Form>
);

BillingForm.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func.isRequired,
};

export default BillingForm;
