import React from 'react';
import { Card, CardBody, CardHeader, CardText, DashboardSection, Header2, Link } from 'fiducius-ui';

const MyIdentity = () => (
  <DashboardSection title="My Identity Theft Protection">
    <Header2>
      Keep your identity safe with identity theft protection from{' '}
      <Link to="https://cyberscout.com/" external icon>
        CyberScout
      </Link>
    </Header2>
    <div className="row">
      <div className="col-12 col-sm-10 col-lg-8">
        <Card brand="success">
          <CardHeader>More than just student loans</CardHeader>
          <CardBody>
            <CardText>
              <p>
                As part of our fiduciary responsibility to you, we strive to enhance all areas of
                your financial well-being. Within your first year as a client you are enrolled in
                our identity theft restoration benefit covering you and your household members.
              </p>
              <p>
                If you have your identity compromised, you have unlimited resources and a dedicated
                team available to assist with restoring your identity through your included benefit.
                This incorporates lost wallet and travel assistance, child identity protection,
                estate support and an unlimited service guarantee.
              </p>
              <p>
                For further protection online, we offer more intensive solutions to protect your
                identity. This includes, but is not limited to, social security and bank account
                monitoring, driver's license and passport protection and online credit reporting. To
                enhance your identity theft protection wellness benefit or to learn more about your
                existing coverage, contact us at <a href="tel:+15136455400">(513) 645-5400</a>.
              </p>
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  </DashboardSection>
);

export default MyIdentity;
