import styled from 'styled-components';
import { Header1 } from 'fiducius-ui';

const ContrastDisplay = styled(Header1)`
  color: ${(p) => p.theme.white};
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

export default ContrastDisplay;
