import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { Animation, fade } from 'fiducius-ui';

const FadeClasses = styled.div`
  ${fade};
`;

const Fade = ({ children, id, show }) => (
  <Animation key={id} animation="fade" appear={false} in={show} unmountOnExit>
    <FadeClasses>{children}</FadeClasses>
  </Animation>
);

Fade.propTypes = {
  children: t.node.isRequired,
  id: t.string.isRequired,
  show: t.bool.isRequired,
};

export default Fade;
