import types from './types';
import { combineReducers } from 'redux';
import { requestReducerGenerator } from '../../utils/request';

const clear = requestReducerGenerator('persist', 'clear', types);
const load = requestReducerGenerator('persist', 'load', types);
const save = requestReducerGenerator('persist', 'save', types);

const cache = (
  state = {
    rememberMe: false,
    initialLoad: false,
  },
  action
) => {
  switch (action.type) {
    case types.PERSIST_INITIAL_LOAD:
      return { ...state, initialLoad: action.initialLoad };
    case types.PERSIST_REMEMBER_ME:
      return { ...state, rememberMe: action.rememberMe };
    default:
      return state;
  }
};

const persist = combineReducers({
  cache,
  requests: combineReducers({
    clear,
    load,
    save,
  }),
});

export default persist;
