import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  AsyncLoader,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { safeAccess, stringIsNullOrEmpty } from '../../utils';
import { Fade } from '../../routing';
import {
  degreeGradeList,
  certificationGradeList,
  editableGrade,
  editableClassName,
} from '../redux/selectors';
import { format, parseISO } from 'date-fns';

const TextLeft = styled.div`
  text-align: left;
`;

class TuitionInstitutionClassForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    getResource: t.object,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    institutionList: t.object.isRequired,
    termList: t.object.isRequired,
    isCertification: t.bool.isRequired,
    allowedCertifications: t.object,
    endDate: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }
    /*if (!prevProps.errors.generalErrors && this.props.errors.generalErrors) {
      this.modalRef.current.scrollTo(0, 0);
    }*/
  }
  setGradeTextValue = (grade, isCertification, endDate) => {
    let termEndDate;
    const today = new Date();

    if (endDate !== undefined && endDate !== null) {
      termEndDate = new Date(format(parseISO(endDate), 'MM/dd/yyyy'));
    }

    if (termEndDate < today) {
      if (!isCertification) {
        if (grade === 'AC') {
          grade = 'Credit';
        } else if (grade === 'FW') {
          grade = 'Withdrawal';
        } else if (grade === 'FI') {
          grade = 'Incomplete';
        }
      } else {
        if (grade === 'A') {
          grade = 'Pass';
        } else if (grade === 'F') {
          grade = 'Fail';
        }
      }
    } else {
      grade = 'Term In Progress';
    }
    return grade;
  };

  getForm() {
    const {
      action,
      data,
      errors,
      endDate,
      onChange,
      onSubmit,
      institutionList,
      termList,
      isCertification,
      allowedCertifications,
    } = this.props;

    let gradeList = isCertification ? certificationGradeList : degreeGradeList;
    let grade = this.setGradeTextValue(data.grade, isCertification, endDate);

    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <InputWrapper error={!!errors.institutionId}>
          <InputLabel htmlFor="institutionId" required>
            Institution
          </InputLabel>
          <InputError>{safeAccess(errors, 'institutionId.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="institutionId"
              value={data.institutionId}
              options={institutionList}
              disabled={true}
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.termId}>
          <InputLabel htmlFor="termId" required>
            Term / Semester
          </InputLabel>
          <InputError>{safeAccess(errors, 'termId.detail')}</InputError>
          <InputGroup>
            <InputSelect name="termId" value={data.termId} options={termList} disabled={true} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.className}>
          <InputLabel htmlFor="className" required>
            Class Name
          </InputLabel>
          <InputError>{safeAccess(errors, 'className.detail')}</InputError>
          <InputGroup>
            {isCertification && Object.keys(allowedCertifications).length > 1 && (
              <InputSelect
                name="className"
                value={data.className}
                options={allowedCertifications}
                disabled={action === 'Delete' || editableClassName.includes(data.currentStatusCode)}
              />
            )}
            {(!isCertification || Object.keys(allowedCertifications).length === 1) && (
              <InputText
                name="className"
                defaultValue={data.className}
                disabled={action === 'Delete' || editableClassName.includes(data.currentStatusCode)}
              />
            )}
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.grade}>
          <InputLabel htmlFor="grade">Grade</InputLabel>
          <InputError>{safeAccess(errors, 'grade.detail')}</InputError>
          <InputGroup>
            <InputSelect
              value={grade === 'Term In Progress' ? 'Term In Progress' : data.grade}
              disabled={
                grade === 'Term In Progress' ||
                action === 'Add' ||
                action === 'Delete' ||
                !editableGrade.includes(data.currentStatusCode)
              }
              name="grade"
              options={
                grade === 'Term In Progress'
                  ? { 'Term In Progress': 'Term In Progress' }
                  : gradeList
              }
            />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.hours}>
          <InputLabel htmlFor="hours">Hours</InputLabel>
          <InputError>{safeAccess(errors, 'hours.detail')}</InputError>
          <InputGroup>
            <InputText name="hours" defaultValue={data.hours} disabled={action === 'Delete'} />
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  }

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {}, isCertification, endDate, action } = this.props;
    let grade = this.setGradeTextValue(data.grade, isCertification, endDate);

    if (
      grade === 'Term In Progress' ||
      action === 'Add' ||
      action === 'Delete' ||
      !editableGrade.includes(data.currentStatusCode)
    ) {
      return !stringIsNullOrEmpty(data.className);
    } else {
      return !stringIsNullOrEmpty(data.className) && !stringIsNullOrEmpty(grade);
    }
  };

  checkNoErrors = () => {
    const { errors = {} } = this.props;
    return !errors.className && !errors.grade && !errors.hours;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { getResource, action, closeForm, errors = {}, isLoading, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Class</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            {action === 'Add' && <TextLeft>{this.getForm()}</TextLeft>}
            {(action === 'Update' || action === 'Delete') && (
              <AsyncLoader request={getResource}>
                {!getResource.isLoading && <TextLeft>{this.getForm()}</TextLeft>}
              </AsyncLoader>
            )}
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' class'}
                </Button>
              </Fade>
              <Fade show={!getResource.isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade
                show={!getResource.isLoading && this.checkNoMissingData() && !this.checkNoErrors()}
              >
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default TuitionInstitutionClassForm;
