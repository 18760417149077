import React from 'react';
import styled from 'styled-components';
import { InputWrapper } from 'fiducius-ui';

const FinePrint = styled.div`
  font-size: calc(1rem);
`;

const StyledLink = styled.a`
  color: rgb(24, 188, 112);
`;

const HavingTrouble = () => {
  return (
    <InputWrapper>
      <FinePrint>Having trouble unlocking your account?</FinePrint>
      <FinePrint>
        Contact us at{' '}
        <StyledLink href="mailto:LoginAssistance@getfiducius.com">
          LoginAssistance@getfiducius.com
        </StyledLink>{' '}
        for assistance.
      </FinePrint>
    </InputWrapper>
  );
};

export default HavingTrouble;
