import React from 'react';
import t from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  AsyncLoader,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';
import styled from 'styled-components';
import LwoConfirmation from '../components/lwo-confirmation';
import LwoProgress from '../components/lwo-progress';

const StyledDiv = styled.div`
  margin-top: 1em;
`;

class LwoLoanInfo extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: null };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    if (this.state.redirectURI !== null) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <DashboardSection title="Loans">
        <LwoProgress removeContainer={true} />

        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Provide Federal Loan Information</CardTitle>
                {this.props.request.hasFinished && (
                  <CardText>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          To determine if you will benefit from using Assure&#8480;, we start by
                          analyzing all of your federal student loan history. To complete this step,
                          you need to provide your NSLDS file to us (as a text file). Don't worry,
                          we provide detailed instructions to make this easy for you.
                        </p>
                        <p>Simply click the "Submit My NSLDS" button to get started!</p>
                        <p>
                          Note: This is the first of two steps required to determine if you can
                          benefit from using Assure&#8480;.
                        </p>
                      </div>
                    </div>
                  </CardText>
                )}
                <LwoConfirmation stepId={602} />
              </CardBody>
            </Card>
          </div>
        </div>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.payment.requests.loadResource,
    state.limitedWaiverOpportunity.requests.loadResource,
  ]),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoLoanInfo));
