import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../../todos';

const TodoText = () => (
  <>
    <strong>Step 1 – Access your MOHELA account</strong>
    <ul>
      <li>
        Create or Login to your account at{' '}
        <a href="https://www.mohela.com" target="_blank" rel="noopener noreferrer">
          MOHELA.
        </a>
      </li>
      <li>
        You will not be able to create an account for about <strong>three to four weeks</strong>.
      </li>
    </ul>
    <strong>Step 2 – Check on the status of the consolidation</strong>
    <ul>
      <li>Check your Account Summary for updates on the status.</li>
      <li>
        <strong>Please note:</strong> It typically takes <strong>four to six weeks</strong> for a
        consolidation to process, with the first change in status occurring approximately three
        weeks after you submit.
      </li>
      <li>What are you looking for?</li>
      <ul>
        <li>Notifications that indicate the consolidation is progressing.</li>
        <li>Examples include:</li>
        <ul>
          <li>Consolidation Application Received</li>
          <li>Consolidation Request Almost Complete</li>
          <li>We Completed Your Consolidation!</li>
        </ul>
      </ul>
    </ul>
    <strong>Step 3 – Confirm completion</strong>
    <ul>
      <li>
        You will receive a letter titled “We Completed Your Consolidation!” in your MOHELA inbox.
        Once received, confirm the number of loans on the letter matches the number of loans on your
        consolidation application.
      </li>
      <li>
        Confirm that the entire loan balance shown on your consolidation application has been
        transferred to MOHELA.
      </li>
      <li>
        Confirm all loans that were included in the consolidation have a balance of $0 at your
        previous loan servicer(s).
      </li>
      <li>
        <strong>Please Note:</strong> your consolidation application can take up to two months to
        process.
      </li>
    </ul>
    <strong>
      Do not confirm this step is finished until you have verified the transfer is complete.
    </strong>
    <br />
    If you submitted your consolidation more than two months ago and your loan balance has not
    transferred, please visit the Contact Us button on the left of your portal to reach out to us.
    {/* contact us at <a href="tel:+15136455400">(513) 645-5400</a> option: 2. */}
  </>
);

const ConsolidationCheck = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/documents')}
    text={<TodoText />}
    title="Finalize Your Consolidation"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidationCheck);
