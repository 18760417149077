const nsldsFieldType = {
  //student fields
  student: [
    'Undergraduate Aggregate Subsidized Total',
    'Undergraduate Aggregate Unsubsidized Total',
    'Undergraduate Aggregate Combined Total',
    'Graduate Aggregate Subsidized Total',
    'Graduate Aggregate Unsubsidized Total',
    'Graduate Aggregate Combined Total',
    'Aggregate Subsidized Total (Undergraduate and Graduate)',
    'Aggregate Unsubsidized Total (Undergraduate and Graduate)',
    'Aggregate Combined Total (Undergraduate and Graduate)',
  ],
};

export default nsldsFieldType;
