import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  DashboardSection,
  AsyncLoader,
  Lede,
  Modal,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { getUserId } from '../../../auth';
import { Employers, employmentHistoryLoadCollection } from '../../../employment-history';
import {
  limitedWaiverOpportunityLoadResource,
  limitedWaiverOpportunityUpdateResource,
  limitedWaiverOpportunityCopyResourceToForm,
} from '../redux/operations';
import LwoConfirmation from '../components/lwo-confirmation';
import LwoProgress from '../components/lwo-progress';
import { todosLoadCollection } from '../../../todos';
import Results from '../components/results';
import { convertIsoToSlash, mergeRequestStatuses } from '../../../utils';
import { authLoadPermissions } from '../../../auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

const StyledDiv = styled.div`
  margin-top: 1em;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class LwoQuestionnaire extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    employers: t.object,
    load: t.func.isRequired,
    update: t.func.isRequired,
    loans: t.object,
    currentStage: t.string,
    fullRequest: t.object,
    firstLoanDate: t.string,
    updateRequest: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { openPartTimeEmpModal: false };
    this.handlePartTimeEmpModal = this.handlePartTimeEmpModal.bind(this);
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  handlePartTimeEmpModal() {
    this.setState({ openPartTimeEmpModal: !this.state.openPartTimeEmpModal });
  }

  getFirstLoanDateFormatted = (firstLoanDate) => {
    let retVal = convertIsoToSlash(firstLoanDate);
    if (retVal !== undefined && retVal !== null) {
      retVal = retVal.toString();
      if (retVal[0] === '0') {
        retVal = retVal.substring(1);
      }
    }
    return retVal;
  };

  innerAsyncLoaderContent() {
    return (
      <CardText>
        <p>
          One more step before you see your results! Please provide information for all
          not-for-profit employers while carrying federal student loans.{' '}
        </p>
        <p>
          Based on our initial analysis of your NSLDS file, the earliest date your loans went into
          repayment was {this.getFirstLoanDateFormatted(this.props.firstLoanDate)}. This means you
          do NOT need to provide employment information prior to that date. Also, you do not need to
          provide information for any employment prior to 10/1/2007, when the PSLF program started.
        </p>
        <p>
          Once you have entered all your employers, click the "Calculate My Results" button. Hang
          tight, it may take a few seconds while we're analyzing all your information. It's worth
          the wait since you'll learn if Assure&#8480; can guarantee you receive additional
          qualifying payments!
        </p>
        <Employers checkQualification={this.props.update} />
        <Results
          loans={this.props.loans}
          permissions={this.props.permissions}
          request={this.props.updateRequest}
        />
      </CardText>
    );
  }

  render() {
    return (
      <DashboardSection title="Employment &#38; Results">
        <LwoProgress removeContainer={true} />

        <Card>
          <CardBody>
            <div className="row">
              <div className="col-12">
                <CardTitle>Establish Employment History and Learn Results</CardTitle>
                <AsyncLoader
                  request={this.props.fullRequest}
                  empty={this.innerAsyncLoaderContent()}
                >
                  {this.innerAsyncLoaderContent()}
                </AsyncLoader>
              </div>
            </div>
            <StyledDiv className="row">
              <div className="col-12">
                <LwoConfirmation stepId={603} />
              </div>
            </StyledDiv>
          </CardBody>
        </Card>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const eHRequests = state.employmentHistory.requests;
  const lwoRequests = state.limitedWaiverOpportunity.requests;

  return {
    id: getUserId(state),
    employers: state.employmentHistory.cache,
    loans: state.limitedWaiverOpportunity.form.eligibleLoans,
    currentStage: state.limitedWaiverOpportunity.form.currentBenefitStage,
    firstLoanDate: state.limitedWaiverOpportunity.form.firstLoanDate,
    fullRequest: mergeRequestStatuses([eHRequests.loadCollection, lwoRequests.loadResource]),
    updateRequest: lwoRequests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
    dispatch(employmentHistoryLoadCollection());
    await dispatch(limitedWaiverOpportunityCopyResourceToForm(id));
  },
  update: async (id) => {
    await dispatch(limitedWaiverOpportunityUpdateResource(id));
    await dispatch(limitedWaiverOpportunityCopyResourceToForm(id));
    dispatch(todosLoadCollection());
    await dispatch(authLoadPermissions());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoQuestionnaire));
