import types from './types';

// Auth cache actions
const authClear = () => ({
  type: types.AUTH_CLEAR,
});
const authExpiring = () => ({
  type: types.AUTH_EXPIRING,
});
const authExpired = () => ({
  type: types.AUTH_EXPIRED,
});
const authHandleError = (errors) => ({
  type: types.AUTH_HANDLE_ERROR,
  errors,
});
const authPopulate = (data) => ({
  type: types.AUTH_POPULATE,
  data,
});
const authSessionTerminated = () => ({
  type: types.AUTH_SESSION_TERMINATED,
});
const authSilentRenewError = (error) => ({
  type: types.AUTH_SILENT_RENEW_ERROR,
  error,
});
const authHandleFormChange = (data) => ({
  type: types.AUTH_HANDLE_FORM_CHANGE,
  data,
});
const authInvalidateAttributes = (attributes, errorState) => ({
  type: types.AUTH_INVALIDATE_ATTRIBUTES,
  attributes,
  errorState,
});
const authInvalidateCache = () => ({
  type: types.AUTH_INVALIDATE_CACHE,
});
const authValidateAttributes = (attributes, errorState) => ({
  type: types.AUTH_VALIDATE_ATTRIBUTES,
  attributes,
  errorState,
});
const authValidateCache = () => ({
  type: types.AUTH_VALIDATE_CACHE,
});

// Login
const authLoginRequest = () => ({
  type: types.AUTH_LOGIN_REQUEST,
});
const authLoginResponse = (data) => ({
  type: types.AUTH_LOGIN_RESPONSE,
  data,
});
const authLoginFailure = () => ({
  type: types.AUTH_LOGIN_FAILURE,
  lastUpdated: Date.now(),
});
const authLoginSuccess = () => ({
  type: types.AUTH_LOGIN_SUCCESS,
  lastUpdated: Date.now(),
});

// Load permissions
const authLoadPermissionsRequest = () => ({
  type: types.AUTH_LOAD_PERMISSIONS_REQUEST,
});
const authLoadPermissionsResponse = (data) => ({
  type: types.AUTH_LOAD_PERMISSIONS_RESPONSE,
  data,
});
const authLoadPermissionsFailure = () => ({
  type: types.AUTH_LOAD_PERMISSIONS_FAILURE,
  lastUpdated: Date.now(),
});
const authLoadPermissionsSuccess = () => ({
  type: types.AUTH_LOAD_PERMISSIONS_SUCCESS,
  lastUpdated: Date.now(),
});

// Logout
const authLogoutRequest = () => ({
  type: types.AUTH_LOGOUT_REQUEST,
});
const authLogoutResponse = () => ({
  type: types.AUTH_LOGOUT_RESPONSE,
});
const authLogoutFailure = () => ({
  type: types.AUTH_LOGOUT_FAILURE,
  lastUpdated: Date.now(),
});
const authLogoutSuccess = () => ({
  type: types.AUTH_LOGOUT_SUCCESS,
  lastUpdated: Date.now(),
});

// Forgot password
const authPasswordResetRequest = () => ({
  type: types.AUTH_PASSWORD_RESET_REQUEST,
});
const authPasswordResetResponse = () => ({
  type: types.AUTH_PASSWORD_RESET_RESPONSE,
});
const authPasswordResetFailure = () => ({
  type: types.AUTH_PASSWORD_RESET_FAILURE,
  lastUpdated: Date.now(),
});
const authPasswordResetSuccess = () => ({
  type: types.AUTH_PASSWORD_RESET_SUCCESS,
  lastUpdated: Date.now(),
});

const authPasswordUpdateRequest = () => ({
  type: types.AUTH_PASSWORD_UPDATE_REQUEST,
});
const authPasswordUpdateResponse = () => ({
  type: types.AUTH_PASSWORD_UPDATE_RESPONSE,
});
const authPasswordUpdateFailure = () => ({
  type: types.AUTH_PASSWORD_UPDATE_FAILURE,
  lastUpdated: Date.now(),
});
const authPasswordUpdateSuccess = () => ({
  type: types.AUTH_PASSWORD_UPDATE_SUCCESS,
  lastUpdated: Date.now(),
});

// Signup
const authSignupRequest = () => ({
  type: types.AUTH_SIGNUP_REQUEST,
});
const authSignupResponse = (data) => ({
  type: types.AUTH_SIGNUP_RESPONSE,
  data,
});
const authSignupFailure = () => ({
  type: types.AUTH_SIGNUP_FAILURE,
  lastUpdated: Date.now(),
});
const authSignupSuccess = () => ({
  type: types.AUTH_SIGNUP_SUCCESS,
  lastUpdated: Date.now(),
});

// Silent renew
const authSilentRenewRequest = () => ({
  type: types.AUTH_SILENT_RENEW_REQUEST,
});
const authSilentRenewResponse = (data) => ({
  type: types.AUTH_SILENT_RENEW_RESPONSE,
  data,
});
const authSilentRenewFailure = () => ({
  type: types.AUTH_SILENT_RENEW_FAILURE,
  lastUpdated: Date.now(),
});
const authSilentRenewSuccess = () => ({
  type: types.AUTH_SILENT_RENEW_SUCCESS,
  lastUpdated: Date.now(),
});

const authPartnerLocationRequest = () => ({
  type: types.AUTH_PARTNER_LOCATION_REQUEST,
});
const authPartnerLocationResponse = (data) => ({
  type: types.AUTH_PARTNER_LOCATION_RESPONSE,
  data,
});
const authPartnerLocationFailure = () => ({
  type: types.AUTH_PARTNER_LOCATION_FAILURE,
  lastUpdated: Date.now(),
});
const authPartnerLocationSuccess = () => ({
  type: types.AUTH_PARTNER_LOCATION_SUCCESS,
  lastUpdated: Date.now(),
});

// Load theme preference
const authLoadThemeRequest = () => ({
  type: types.AUTH_LOAD_THEME_REQUEST,
});
const authLoadThemeResponse = (data) => ({
  type: types.AUTH_LOAD_THEME_RESPONSE,
  data,
});
const authLoadThemeFailure = () => ({
  type: types.AUTH_LOAD_THEME_FAILURE,
  lastUpdated: Date.now(),
});
const authLoadThemeSuccess = () => ({
  type: types.AUTH_LOAD_THEME_SUCCESS,
  lastUpdated: Date.now(),
});

export default {
  authHandleFormChange,
  authInvalidateAttributes,
  authInvalidateCache,
  authValidateAttributes,
  authValidateCache,

  authLoadPermissionsRequest,
  authLoadPermissionsResponse,
  authLoadPermissionsFailure,
  authLoadPermissionsSuccess,

  authLoginRequest,
  authLoginResponse,
  authLoginFailure,
  authLoginSuccess,

  authLogoutRequest,
  authLogoutResponse,
  authLogoutFailure,
  authLogoutSuccess,

  authPasswordResetRequest,
  authPasswordResetResponse,
  authPasswordResetFailure,
  authPasswordResetSuccess,

  authPasswordUpdateRequest,
  authPasswordUpdateResponse,
  authPasswordUpdateFailure,
  authPasswordUpdateSuccess,

  authSignupRequest,
  authSignupResponse,
  authSignupFailure,
  authSignupSuccess,

  authSilentRenewRequest,
  authSilentRenewResponse,
  authSilentRenewFailure,
  authSilentRenewSuccess,

  authPartnerLocationRequest,
  authPartnerLocationResponse,
  authPartnerLocationFailure,
  authPartnerLocationSuccess,

  authClear,
  authExpiring,
  authExpired,
  authHandleError,
  authPopulate,
  authSessionTerminated,
  authSilentRenewError,

  authLoadThemeRequest,
  authLoadThemeResponse,
  authLoadThemeFailure,
  authLoadThemeSuccess,
};
