import { createSelector } from 'reselect';
import { getUserId } from '../../../auth';
import { safeAccess } from '../../../utils';

export const getBenefitData = (state) =>
  safeAccess(state, `modeler.cache[${getUserId(state)}]`, {});

export const getGrossPayment = createSelector([getBenefitData], (data = {}) => data.grossPay || 0);

export const getHouseholdSize = createSelector(
  [getBenefitData],
  (data = {}) => data.householdSize || 0
);
