import actions from './actions';
import { ProfileValidator } from './validators';
import { convertSlashToIso, resourceOperationsGenerator } from '../../utils';

const profileValidator = new ProfileValidator();

const fixData = (data) => {
  return data.map((resource) => {
    const { altPhone, cellPhone, dob } = resource;
    resource.altPhone = altPhone ? altPhone.replace(/\D/g, '') : null;
    resource.cellPhone = cellPhone ? cellPhone.replace(/\D/g, '') : null;
    resource.dob = dob ? convertSlashToIso(dob) : '';
    return resource;
  });
};

const endpointHandler = {
  customResponseHandler: fixData,
  endpoint: '/demographic-info',
  resourceType: 'demographic-info',
};

const operations = resourceOperationsGenerator(
  'profile',
  actions,
  null,
  profileValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  profileCopyResourceToForm,
  profileHandleFormChange,
  profileLoadResource,
  profileUpdateResource,
} = operations;

const profileSaveForm = (id) => async (dispatch, getState) => {
  const state = getState();
  // state.profile.form = revertData(state.profile.form)
  await dispatch(profileUpdateResource(id));
  if (
    state.profile.form.needsProfileVerification === true &&
    state.profile.form.verifyProfile === true
  ) {
    state.profile.form.needsProfileVerification = false;
  }
};

export {
  profileCopyResourceToForm,
  profileHandleFormChange,
  profileLoadResource,
  profileUpdateResource,
  profileSaveForm,
};

export default operations;
