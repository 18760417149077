import { createSelector } from 'reselect';
import { getUserId } from '../../../auth';
import { getBenefitStatus } from '../../../auth/redux/selectors';
import { benefits, benefitStatuses } from '../../../auth/redux/benefits';
import { safeAccess } from '../../../utils';

const yearlyResults = (data) => safeAccess(data, 'planYearlyResults[0]', {});

export const getForgivenessData = (state) =>
  safeAccess(state, `forgiveness.cache[${getUserId(state)}]`, {});

export const getMonthlyPayment = createSelector([getForgivenessData], (data = {}) => {
  let payment = data.monthlyPayment;
  if (payment === undefined || payment === null || isNaN(data.monthlyPayment)) {
    payment = yearlyResults(data).incomeBasedRepayment || data.estimatedMonthlyPayment || 0;
  }
  return payment;
});

export const getMonthlySavings = createSelector([getForgivenessData], (data = {}) => {
  const planResults = yearlyResults(data).standardRepayment || 0;
  let payment = data.monthlyPayment;

  if (payment === undefined || payment === null || isNaN(data.monthlyPayment)) {
    payment = yearlyResults(data).incomeBasedRepayment || data.estimatedMonthlyPayment || 0;
  }
  return planResults - payment;
});

export const getNewPayment = createSelector([getForgivenessData], (data = {}) => {
  let payment = data.monthlyPayment;

  if (payment === undefined || payment === null || isNaN(data.monthlyPayment)) {
    payment = yearlyResults(data).incomeBasedRepayment || data.estimatedMonthlyPayment || 0;
  }
  return payment;
});

export const getOldPayment = createSelector(
  [getForgivenessData],
  (data = {}) => yearlyResults(data).standardRepayment
);

export const getStandardPayment = createSelector(
  [getForgivenessData],
  (data = {}) => yearlyResults(data).standardRepayment || 0
);

export const getTotalForgiveness = createSelector([getForgivenessData], (data = {}) =>
  data.estimatedLoanForgiveness ? data.estimatedLoanForgiveness : 0
);

export const getTotalStandardRepayment = createSelector([getForgivenessData], (data = {}) => {
  const stdPayment = yearlyResults(data).standardRepayment || 0;
  const planYears = data.planYearlyResults ? data.planYearlyResults.length - 1 : 1;
  return stdPayment * planYears * 12;
});

export const getTotalIdrRepayment = createSelector([getForgivenessData], (data = {}) => {
  const totalForgiveness = data.estimatedLoanForgiveness ? data.estimatedLoanForgiveness : 0;
  const stdPayment = yearlyResults(data).standardRepayment || 0;
  const planYears = data.planYearlyResults ? data.planYearlyResults.length - 1 : 1;
  const totalSTD = stdPayment * planYears * 12;
  return data.estimatedBalanceToBePaid || totalSTD - totalForgiveness || 0;
});

export const getRepaymentTerm = createSelector([getForgivenessData], (data = {}) =>
  data.planYearlyResults ? data.planYearlyResults.length - 1 : 10
);

export const getShowTaxCompare = (state) => {
  //return getBenefitStatus(state, benefits.FORGIVENESS) == benefitStatuses.QUALIFIED || false;
  return getBenefitStatus(state, benefits.FORGIVENESS) === benefitStatuses.QUALIFIED || false;
};

export const getPastYearSavings = createSelector([getForgivenessData], (data = {}) => {
  const stdPayment = yearlyResults(data).standardRepayment || 0;
  const DOEIDRCalculation = data.doeIdrPayment;
  return (stdPayment - DOEIDRCalculation) * 12;
});

export const getCurrentYearSavings = createSelector([getForgivenessData], (data = {}) => {
  const stdPayment = yearlyResults(data).standardRepayment || 0;
  const ISLSIDRCalculation = data.islsIdrPayment;
  return (stdPayment - ISLSIDRCalculation) * 12;
});

export const getSAVEPlanSavings = createSelector([getForgivenessData], (data = {}) => {
  const stdPayment = yearlyResults(data).standardRepayment || 0;
  let saveCalculation = data.saveCalculation;

  if (data.saveCalculation === null) {
    saveCalculation = stdPayment;
  } else if (data.saveCalculation <= 0) {
    saveCalculation = 0;
  }

  return (stdPayment - saveCalculation) * 12;
});

export const getISLSIDRPayment = createSelector([getForgivenessData], (data = {}) => {
  return data.islsIdrPayment;
});
