import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  CardBody,
  CardText,
  Button,
  InputWrapper,
  InputCheckbox,
  InputLabel,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { formatRelative, parseISO } from 'date-fns';
import englishDefault from 'date-fns/locale/en-US/index';

import { FlexBetween } from '../../../root';

import { safeAccess, debounce, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import { authLoadPermissions } from '../../../auth';

import { todosLoadCollection } from '../../../todos/redux/operations';

import {
  selfServiceDocumentLoadDocuments,
  selfServiceDocumentUpdateAll,
  selfServiceDocumentUpdateParentFile,
} from '../../../self-service-document';

import FaxApproval from './fax-approval';

const InputWrapper2 = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

class LwoFax extends React.Component {
  static propTypes = {
    confirm: t.func.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    stepId: t.number.isRequired,
    todos: t.array,
    additionalInfoNeeded: t.bool,
    handleChange: t.func,
    reload: t.func.isRequired,
    load: t.func.isRequired,
    documents: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCheckbox: false,
      redirectURI: '',
      goToInfo: false,
      showForm: false,
      documentId: '',
    };
    this.onConfirm = this.onConfirm.bind(this);
    this.goToInfo = this.goToInfo.bind(this);
  }

  componentDidMount() {
    const { load, stepId } = this.props;
    load(stepId);
  }

  componentDidUpdate(prevProps, prevState) {}

  onConfirm = async () => {
    await this.props.confirm(this.props.stepId);
    this.goToCurrentStep();
  };

  goToInfo(e) {
    this.props.handleChange(e.type);
  }

  checkStepCompleted = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const lwoStep = permissions.lwoSteps.find((a) => a.id === stepMapping);

    let retVal = false;
    if (lwoStep !== undefined && lwoStep !== null && todos !== undefined && todos !== null) {
      retVal = lwoStep.stepComplete;
      if (!retVal) {
        if (stepId === 606 || stepId === 607 || stepId === 608) {
          const currentToDo = todos.find((a) => a.id === 606 || a.id === 607 || a.id === 608);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        }
      }
    }

    return retVal;
  };

  getStepMapping = (id) => {
    let retVal = '';
    switch (id) {
      case 602:
        retVal = 'LoanInformation';
        break;
      case 603:
        retVal = 'Questionnaire';
        break;
      case 604:
        retVal = 'Agreement';
        break;
      case 605:
        retVal = 'Fee';
        break;
      case 606:
      case 607:
      case 608:
        retVal = 'EmploymentCertification';
        break;
      case 609:
        retVal = 'QualifyingPaymentCheck';
        break;
      case 610:
        retVal = 'Complete';
        break;
      default:
        retVal = 'Overview';
        break;
    }
    return retVal;
  };

  checkNotYetAtStep = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const lwoStep = permissions.lwoSteps.find((a) => a.id === stepMapping);
    const currentStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    let retVal = false;
    if (
      lwoStep !== undefined &&
      lwoStep !== null &&
      currentStep !== undefined &&
      currentStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (lwoStep.id === currentStep.id) {
        if (stepId === 606 || stepId === 607 || stepId === 608) {
          const currentToDo = todos.find((a) => a.id === 606 || a.id === 607 || a.id === 608);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        }
      } else {
        retVal = !lwoStep.stepComplete && !lwoStep.stepCurrent;
      }
    }
    return retVal;
  };

  getCurrentToDo = () => {
    const { permissions, todos } = this.props;
    let currentToDo;

    const lwoSteps = permissions.lwoSteps.filter((a) => a.stepCurrent);
    let lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    if (
      lwoSteps !== undefined &&
      lwoSteps !== null &&
      lwoSteps.length > 1 &&
      lwoSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      lwoStep = lwoSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }
    if (lwoStep !== undefined && lwoStep !== null && todos !== undefined && todos !== null) {
      currentToDo = todos.find(
        (a) =>
          lwoStep.id === 'EmploymentCertification' && (a.id === 606 || a.id === 607 || a.id === 608)
      );
    }

    return currentToDo;
  };

  goToCurrentStep = () => {
    const { permissions, todos } = this.props;
    const lwoSteps = permissions.lwoSteps.filter((a) => a.stepCurrent);
    let lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    if (
      lwoSteps !== undefined &&
      lwoSteps !== null &&
      lwoSteps.length > 1 &&
      lwoSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      lwoStep = lwoSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }

    let retVal = '/benefits/limited-waiver-opportunity/overview';
    if (lwoStep !== undefined && lwoStep !== null && todos !== undefined && todos !== null) {
      if (lwoStep.id === 'EmploymentCertification') {
        retVal = '/benefits/limited-waiver-opportunity' + lwoStep.stepPath;
        const currentToDo = todos.find(
          (a) =>
            lwoStep.id === 'EmploymentCertification' &&
            (a.id === 606 || a.id === 607 || a.id === 608)
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 606:
              retVal += '/step-1';
              break;
            case 607:
              retVal += '/step-2';
              break;
            case 608:
              retVal += '/step-3';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = '/benefits/limited-waiver-opportunity' + lwoStep.stepPath;
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  showDocument = (documentId) => {
    this.setState({ ...this.state, showForm: true, documentId: documentId });
  };

  handleChange = debounce((formState, id, attributes) => {
    this.setState({ ...this.state, showCheckbox: !this.state.showCheckbox });
  }, 250);

  getDocumentList = () => {
    const { documents = [] } = this.props;

    let documentBody = [];

    if (documents['current'] !== undefined) {
      Object.keys(documents['current']).forEach((a) => {
        let disabled =
          documents['current'][a].fileData === undefined ||
          documents['current'][a].fileData === null ||
          documents['current'][a].fileData === '';
        documentBody.push(
          <Button
            brand={
              documents['current'][a].sentToServicer || documents['current'][a].readyToSend
                ? 'success'
                : disabled
                ? 'danger'
                : 'info'
            }
            disabled={disabled}
            onClick={() => this.showDocument(a)}
          >
            View {documents['current'][a].description}{' '}
            {disabled === true &&
              documents['current'][a].employmentHistoryId !== null &&
              '(Not Yet Uploaded)'}
          </Button>
        );
      });
    }
    return documentBody;
  };

  closeForm = async (reloadDocs) => {
    if (reloadDocs) {
      const { reload, stepId } = this.props;
      await reload(stepId);

      this.setState({
        ...this.state,
        showForm: false,
        documentId: '',
      });
    } else {
      this.setState({
        ...this.state,
        showForm: false,
        documentId: '',
      });
    }
  };

  showConfirmation = () => {
    const { documents = [] } = this.props;

    let allowedConfirm = true;
    if (documents['current'] !== undefined) {
      if (Object.keys(documents['current']).length === 0) {
        allowedConfirm = false;
      } else {
        Object.keys(documents['current']).forEach((a) => {
          if (!(documents['current'][a].sentToServicer || documents['current'][a].readyToSend)) {
            allowedConfirm = false;
          }
        });
      }
    }
    return allowedConfirm;
  };

  showGoToNextStep = () => {
    const { documents = [] } = this.props;

    let allowedConfirm = true;
    if (documents['current'] !== undefined) {
      if (Object.keys(documents['current']).length === 0) {
        allowedConfirm = false;
      } else {
        Object.keys(documents['current']).forEach((a) => {
          if (!documents['current'][a].sentToServicer) {
            allowedConfirm = false;
          }
        });
      }
    }
    return allowedConfirm;
  };

  faxesInProcessing = () => {
    const { documents = [] } = this.props;

    let inProcessing = false;
    if (documents['current'] !== undefined) {
      if (Object.keys(documents['current']).length === 0) {
        inProcessing = false;
      } else {
        Object.keys(documents['current']).forEach((a) => {
          if (documents['current'][a].processing) {
            inProcessing = true;
          }
        });
      }
    }
    return inProcessing;
  };

  getTodaysDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return (today = yyyy + '-' + mm + '-' + dd + 'T00:00:00');
  };

  getDateToSend = () => {
    const { documents = [] } = this.props;
    const customRelativeLocale = {
      lastWeek: "'last",
      yesterday: "'yesterday",
      today: "'today",
      tomorrow: "'tomorrow",
      nextWeek: 'eeee',
      other: 'MMMM do',
    };
    let retVal;

    const customLocale = {
      ...englishDefault,
      formatRelative: (token, date, baseDate, options) => customRelativeLocale[token],
    };

    Object.keys(documents['current']).forEach((a) => {
      if (documents['current'][a].dateToSend !== undefined) {
        let dateToSend = new Date(documents['current'][a].dateToSend);
        let todayDate = new Date();

        if (todayDate < dateToSend) {
          retVal = documents['current'][a].dateToSend;
        } else {
          //return 'today' if the date to send is in the past.
          retVal = this.getTodaysDate();
        }
      }
    });

    if (retVal !== undefined) {
      retVal = formatRelative(this.adjustedMeetingTime(retVal), new Date(), {
        addSuffix: false,
        locale: customLocale,
      });
    }

    return retVal;
  };

  adjustedMeetingTime = (time) => {
    return parseISO(time);
  };

  render() {
    const { documents, stepId } = this.props;

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      return (
        <>
          <AsyncLoader request={this.props.request}>
            <CardBody>
              <CardText>
                {this.faxesInProcessing() && stepId !== 256 && stepId !== 112 && (
                  <p>
                    Your fax is currently being processed. You will be notified when it has been
                    sent.
                  </p>
                )}
                {this.faxesInProcessing() && (stepId === 256 || stepId === 112) && (
                  <p>
                    Your IDR application is scheduled to fax {this.getDateToSend()}
                    {this.getDateToSend() === 'today' &&
                      '. You will be notified when it has been sent.'}
                    {this.getDateToSend() !== 'today' && (
                      <ul>
                        <li>
                          The fax is scheduled for a future date to ensure your IDR application is
                          not submitted too early, which could potentially cause issues
                        </li>
                      </ul>
                    )}
                  </p>
                )}
                {!this.faxesInProcessing() && (
                  <>
                    {documents['current'] !== undefined &&
                      Object.keys(documents['current']).length > 0 && (
                        <>
                          <p>
                            Below are the document(s) that you need to approve as ready to be faxed
                            to your servicer.
                          </p>
                          <FlexBetween>{this.getDocumentList()}</FlexBetween>
                        </>
                      )}
                    {documents['current'] !== undefined &&
                      Object.keys(documents['current']).length === 0 && (
                        <>
                          There's no document for you to approve to fax here. That could be for one
                          of the following reasons:
                          <ul>
                            <li>You have documents that need electronically signed</li>
                            <li>You have documents that need uploaded</li>
                            <li>
                              You requested help from Fiducius because an electronically signed
                              document looked incorrect
                            </li>
                          </ul>
                        </>
                      )}
                  </>
                )}
              </CardText>
            </CardBody>
            {this.showGoToNextStep() && (
              <div className="row col-12">
                <div className="col-8">
                  <InputWrapper>
                    <InputLabel>You've completed this step.</InputLabel>
                  </InputWrapper>
                </div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="info" onClick={this.goToCurrentStep}>
                      {'Go to current step'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            )}
            {this.showConfirmation() && !this.showGoToNextStep() && !this.faxesInProcessing() && (
              <>
                <div className="row col-12">
                  <div className="col-12">
                    <InputWrapper2>
                      <InputCheckbox
                        name="allowConfirmation"
                        label="I am ready to confirm that I have followed all of the above steps and am ready to continue."
                        onChange={(e) =>
                          this.handleChange(
                            { allowConfirmation: e.target.value },
                            'allowConfirmation',
                            'allowConfirmation'
                          )
                        }
                      ></InputCheckbox>
                    </InputWrapper2>
                  </div>
                </div>
                <div className="row col-12">
                  <div className="col-8"></div>
                  <div className="col-4">
                    <InputWrapper>
                      <Button
                        id={'confirm'}
                        brand={'primary'}
                        onClick={this.onConfirm}
                        disabled={!this.state.showCheckbox}
                      >
                        {'Confirm'}
                      </Button>
                    </InputWrapper>
                  </div>
                </div>
              </>
            )}
          </AsyncLoader>
          {this.state.showForm && (
            <FaxApproval
              isOpen={this.state.showForm}
              onSubmit={() => {}}
              documentId={this.state.documentId}
              closeForm={this.closeForm}
              stepId={this.getCurrentToDo()}
            />
          )}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.selfServiceDocument.requests.updateResource,
    state.selfServiceDocument.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
  documents: safeAccess(state, 'selfServiceDocument.cache'),
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(selfServiceDocumentLoadDocuments(id));
  },
  reload: async (id) => {
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
    await dispatch(selfServiceDocumentLoadDocuments(id));
  },
  confirm: async (id) => {
    await dispatch(selfServiceDocumentUpdateAll());

    await dispatch(selfServiceDocumentLoadDocuments(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoFax));
