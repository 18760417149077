import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Alert, AsyncLoader, Workflow, Button } from 'fiducius-ui';

import { advisorInvalidateCache } from '../../../advisor';
import { getUserId } from '../../../auth';
import { debounce, safeAccess } from '../../../utils';
import { withPermissions } from '../../../routing';
import { SharedDataConsent } from '../../../legal';
import { todosLoadCollection } from '../../../todos';

import TabEducation from './tab-education';
import TabFinance from './tab-finance';
import TabInfo from './tab-info';
import TabLoans from './tab-loans';
import TabResults from './tab-results';
import TabReview from './tab-review';
import TabNSLDS from './tab-nslds';
import {
  screeningCopyResourceToForm,
  screeningHandleFormChange,
  screeningLoadResource,
  screeningValidateAttributes,
} from '../redux/operations';

import { documentsRemoveFromCache } from '../../../documents/redux/operations';

class ScreeningWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    errors: t.object,
    hasAdvisorResults: t.bool.isRequired,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
    permissions: t.object,
    files: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      current: props.current || 0,
      modalOpen: false,
      inNSLDSWorkflow: false,
      nsldsWorkflowComplete: false,
      tabs: this.buildTabsObjectArray(),
    };
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  componentDidUpdate(prevProps) {
    const { request } = this.props;
    if (prevProps.request.isLoading && request.hasFinished && !request.hasFailed) {
      this.setState({ ...this.state, modalOpen: true });
    }
  }

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };
    updateForm(newData, this.getDependentAttributes(attribute));
  }, 250);

  getDependentAttributes = (attribute) => {
    switch (attribute) {
      case 'consolidated':
        return ['consolidated', 'federalPrivateConsolidatedMix'];
      case 'hasPrivateLoans':
        return [
          'privateLoans',
          'privateBalance',
          'privatePayment',
          'privateServicer',
          'privateInterestRate',
        ];
      case 'married':
        return ['married', 'spouseIncome'];
      case 'continuingEducation':
        return ['continuingEducation', 'degreeSought', 'school'];
      default:
        return [attribute];
    }
  };

  showNSLDS = () => {
    const { permissions } = this.props;
    return permissions.enterpriseEnabled;
  };

  allowedNavigate = () => {
    return !this.state.inNSLDSWorkflow || this.state.nsldsWorkflowComplete;
  };

  handleTabChange = (index) => {
    this.props.validateTab(this.state, Object.keys(this.state.tabs)[this.state.current]);
    if (this.showNSLDS() && index == 2) {
      this.setState({ inNSLDSWorkflow: true });
    }
    this.setState({ current: index });
  };

  sectionValidationStatus(section) {
    const { errors } = this.props;
    let exists = false;
    for (let i = 0; i < this.state.tabs[section].length; i++) {
      let attribute = this.state.tabs[section][i];
      if (safeAccess(errors, `[${attribute}]`)) {
        return 'danger';
      } else if (safeAccess(errors, `[${attribute}]`) === false) {
        exists = true;
      }
    }
    return exists ? 'success' : null;
  }

  NSLDSValidationStatus() {
    return this.state.nsldsWorkflowComplete ? 'success' : null;
  }

  reviewValidationStatus = () => {
    const info = this.sectionValidationStatus('info');
    const loans = this.sectionValidationStatus('loans');
    const finance = this.sectionValidationStatus('finance');
    const education = this.sectionValidationStatus('education');

    if (
      info === 'success' &&
      loans === 'success' &&
      finance === 'success' &&
      education === 'success'
    ) {
      return 'success';
    } else {
      return null;
    }
  };

  nsldsWorkFlowComplete = () => {
    this.setState({ inNSLDSWorkflow: false, nsldsWorkflowComplete: true });
  };

  nsldsWorkFlowStartOver = () => {
    this.setState({ inNSLDSWorkflow: true, nsldsWorkflowComplete: false });
  };

  clearUploadedNslds = () => {
    this.updateNsldsUploaded(false);
    this.nsldsWorkFlowStartOver();
    this.uploadDifferentNslds();
    this.removeNSLDSFileFromCache();
  };

  removeNSLDSFileFromCache = () => {
    const { files, nsldsCreateResourceRequest } = this.props;
    const matches = Object.keys(files).filter(
      (k) => files[k].fileTypeCd === 'LNDT' && files[k].id > 0
    );
    if (matches.length > 0) {
      this.props.removeFromCache(matches[0]);
    }
    nsldsCreateResourceRequest.hasFinished = false;
  };

  updateNsldsUploaded = (uploaded) => {
    this.setState({ ...this.state, nsldsUploaded: uploaded });
  };

  uploadDifferentNslds = () => {};

  toggleModal = () => {
    this.setState({ ...this.state, modalOpen: false });
  };

  showSharedDataConsent = (permissions) => {
    let retVal = false;
    if (permissions.hasConsentToShareData && permissions.consentFormCode === 'AREGI') {
      this.state.modalOpen
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = '');
      retVal = true;
    }
    return retVal;
  };

  loadTabs = () => {
    const { data, errors, hasAdvisorResults } = this.props;
    let tabs = [];
    let showNSLDS = this.showNSLDS();

    tabs.push(
      <TabInfo
        key="1"
        id="info"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('info')}
        name="Info"
        handleChange={this.handleChange}
      />
    );

    tabs.push(
      <TabLoans
        key="2"
        id="loans"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('loans')}
        name="Student Loans"
        handleChange={this.handleChange}
      />
    );

    if (showNSLDS) {
      tabs.push(
        <TabNSLDS
          key="3"
          id="NSLDS"
          data={data}
          errors={errors}
          brand={this.NSLDSValidationStatus()}
          name="NSLDS"
          handleChange={this.handleChange}
          inNSLDSWorkflow={this.state.inNSLDSWorkflow}
          handleWorkflowComplete={() => this.nsldsWorkFlowComplete()}
          isNSLDSWorkflowComplete={this.state.nsldsWorkflowComplete}
          clearUploadedNslds={this.clearUploadedNslds}
          updateNsldsUploaded={this.updateNsldsUploaded}
          nsldsUploaded={this.state.nsldsUploaded}
        />
      );
    }

    tabs.push(
      <TabFinance
        key="4"
        id="finance"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('finance')}
        name="Finances"
        handleChange={this.handleChange}
      />
    );

    tabs.push(
      <TabEducation
        key="5"
        id="education"
        data={data}
        errors={errors}
        brand={this.sectionValidationStatus('education')}
        name="Education"
        handleChange={this.handleChange}
      />
    );

    tabs.push(
      <TabReview
        key="6"
        id="review"
        data={data}
        errors={errors}
        brand={this.reviewValidationStatus()}
        name="Review"
        handleChange={this.handleChange}
      />
    );

    tabs.push(<TabResults key="7" brand={hasAdvisorResults ? 'success' : null} name="Results" />);

    return tabs;
  };

  // This method is supposed to only put the tabs in that are needed; exclude NSLDS
  buildTabsObjectArray = () => {
    let tabsObjectArray = {};

    tabsObjectArray = {
      info: [
        'knowSomeoneWithLoans',
        'referrerName',
        'hireDate',
        'jobTitle',
        'homeAddressOne',
        'homeAddressTwo',
        'homeCity',
        'homeState',
        'homeZipCode',
        'covidFurlough',
      ],
      loans: [
        'federalLoanBalance',
        'federalMonthlyPayment',
        'consolidated',
        'federalPrivateConsolidatedMix',
        'hasParentPlus',
        'privateBalance',
        'hasPrivateLoans',
        'privatePayment',
        'privateServicer',
        'privateInterestRate',
        'pslfOnOwn',
      ],
    };

    if (this.showNSLDS()) {
      tabsObjectArray = { ...tabsObjectArray, NSLDS: [] };
    }

    tabsObjectArray = {
      ...tabsObjectArray,
      finance: ['income', 'creditScore', 'taxFiling', 'householdSize', 'married', 'spouseIncome'],
      education: ['highestCompletedDegree', 'continuingEducation', 'degreeSought', 'school'],
      results: [],
    };

    return tabsObjectArray;
  };

  render() {
    const { data, errors, request, permissions } = this.props;
    return (
      <>
        {this.showSharedDataConsent(permissions) && (
          <SharedDataConsent
            hideIcon={true}
            isOpen={this.state.modalOpen}
            toggleModal={this.toggleModal}
          />
        )}
        <AsyncLoader request={request}>
          <>
            {safeAccess(errors, 'generalErrors')
              ? errors.generalErrors.map((e, i) => (
                  <Alert key={i} brand="danger">
                    {e.detail}
                  </Alert>
                ))
              : null}
            {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
            {data && (
              <Workflow
                tabs={this.loadTabs()}
                finalButton={
                  <Button
                    brand="primary"
                    invert
                    onClick={() => {
                      window.location.href = '/dashboard';
                    }}
                  >
                    View my dashboard
                  </Button>
                }
                handleTabChange={this.handleTabChange}
                next
                prev
                finalPageLocksFlow
                finalPageCheck={this.reviewValidationStatus}
                penUltimateButton={'Submit'}
                allowedNavigate={this.allowedNavigate}
              />
            )}
          </>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.screening;
  return {
    id: getUserId(state),
    data: form,
    errors: errors,
    hasAdvisorResults: state.advisor.meta.cacheIsCurrent,
    request: requests.loadResource,
    files: state.documents.cache,
    nsldsCreateResourceRequest: state.nslds.requests.createResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(screeningLoadResource(id));
    await dispatch(screeningCopyResourceToForm(id));
    await dispatch(todosLoadCollection());
  },
  updateForm: (data, attributes) => {
    dispatch(advisorInvalidateCache());
    dispatch(screeningHandleFormChange(data, attributes));
  },
  validateTab: async (state, tab) => {
    await dispatch(screeningValidateAttributes(state.tabs[tab]));
  },
  removeFromCache: (id) => dispatch(documentsRemoveFromCache(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ScreeningWorkflow));
