import actions from './actions';
import { getUserId, getToken } from '../../../auth';
import {
  checkForEmpty,
  getApi,
  keyById,
  normalize,
  patchApi,
  resourceOperationsGenerator,
} from '../../../utils';

const endpointHandler2 = {
  customResponseHandler: null,
  endpoint: '/refi',
  resourceType: 'refi',
};

// TODO: Ask about the purpose here.
const operations2 = resourceOperationsGenerator(
  'refi',
  actions,
  null,
  null,
  null,
  endpointHandler2,
  null,
  null,
  null
);

const { refiLoadResource } = operations2;

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/lend-key-step-one',
  resourceType: 'lend-key-step-one',
};

const operations = resourceOperationsGenerator(
  'refi',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const { refiDisableValidation } = actions;

const refiGetForm = () => async (dispatch, getState) => {
  const state = getState();
  await dispatch(operations2.refiLoadResource(getUserId(state)));
  let institutionName = '';
  let hasAddress = false;

  try {
    // TODO: serve from cache and short-circuit
    dispatch(actions.refiLoadResourceRequest());
    let data = await getApi(getToken(state), `/lend-key-step-one/${getUserId(state)}`);
    dispatch(actions.refiLoadResourceResponse());

    institutionName = data[0]['institutionName'];

    if (
      data[0]['address1'] &&
      data[0]['address1'].length > 0 &&
      data[0]['city'] &&
      data[0]['city'].length > 0 &&
      data[0]['state'] &&
      data[0]['state'].length === 2 &&
      data[0]['zipCode'] &&
      data[0]['zipCode'].length > 0
    ) {
      hasAddress = true;
    }

    const cache = {
      ...state.refi.cache,
      ...keyById(data),
    };

    const cacheKeys = Object.keys(cache);
    let resultId = null;
    if (cacheKeys.length === 1) {
      resultId = cacheKeys[0];
    }

    let formData = cache;
    if (resultId != null) {
      formData = cache[resultId];
      formData.acceptLoanType = 'both';
    }

    await dispatch(operations.refiHandleFormChange(formData, Object.keys(formData)));

    dispatch(actions.refiLoadResourceSuccess(checkForEmpty(getState().refi)));
  } catch (error) {
    dispatch(operations.refiHandleError(error));
    dispatch(actions.refiLoadResourceFailure(error));
  }
  return { institutionName, hasAddress };
};

const refiUpdateOpeId = (opeId) => (dispatch, getState) => {
  let formData = getState().refi.form;
  formData.opeId = opeId['id'];
  formData.institutionName = opeId['name'];
  dispatch(operations.refiHandleFormChange(formData));
};

const refiMakeFormReady = () => (dispatch, getState) => {
  let formData = getState().refi.form;
  dispatch(operations.refiHandleFormChange(formData));
};

const refiSendForQuotes = () => async (dispatch, getState) => {
  const id = getState().refi.form.id;
  const state = getState();
  try {
    dispatch(actions.refiUpdateResourceRequest());
    const patchData = normalize('lend-key-step-one', state.refi.form, id);
    let data = await patchApi(getToken(state), `/lend-key-step-one/${id}`, patchData);

    dispatch(actions.refiUpdateResourceResponse());

    const cache = { ...state.refi.cache, ...keyById(data) };
    await dispatch(operations.refiHandleCacheChange(cache));
  } catch (error) {
    dispatch(operations.refiHandleError(error));
    dispatch(actions.refiUpdateResourceFailure(error));
  }

  let data = [];
  try {
    const state = getState();
    // TODO: serve from cache and short-circuit
    dispatch(actions.refiLoadResourceRequest());
    data = await getApi(getToken(state), '/lend-key-step-two');

    dispatch(actions.refiLoadResourceResponse());

    const cache = {
      ...state.refi.cache,
      quotes: data,
    };

    await dispatch(operations.refiHandleCacheChange(cache, Object.keys(cache)));
  } catch (error) {
    dispatch(operations.refiHandleError(error));
    dispatch(actions.refiLoadResourceFailure(error));
  }

  dispatch(actions.refiUpdateResourceSuccess());
  dispatch(actions.refiLoadResourceSuccess(data.length === 0));
};

const refiSendApply = (id) => async (dispatch, getState) => {
  let formData = getState().refi.cache.quotes.filter((q) => q.id === id);
  if (formData.length !== 1) {
    throw new Error('Could not find quote for application');
  } else {
    formData = formData[0];
  }
  dispatch(operations.refiHandleFormChange(formData));
  if (formData.selectionUrl !== null && formData.selectionUrl !== undefined) {
    window.location.href = formData.selectionUrl;
  } else {
    try {
      const state = getState();
      dispatch(actions.refiUpdateResourceRequest());
      const patchData = normalize('lend-key-step-two', state.refi.form, id);
      let data = await patchApi(getToken(state), `/lend-key-step-two/${id}`, patchData);

      dispatch(actions.refiUpdateResourceResponse());

      const cache = { ...state.refi.cache, ...keyById(data) };
      await dispatch(operations.refiHandleCacheChange(cache));
    } catch (error) {
      dispatch(operations.refiHandleError(error));
      dispatch(actions.refiUpdateResourceFailure(error));
    }
  }
};

const refiGetExistingQuotes = () => async (dispatch, getState) => {
  let data = [];
  try {
    const state = getState();
    // TODO: serve from cache and short-circuit
    dispatch(actions.refiLoadResourceRequest());
    data = await getApi(getToken(state), '/lend-key-step-two');

    dispatch(actions.refiLoadResourceResponse());

    const cache = {
      ...state.refi.cache,
      quotes: data,
    };

    await dispatch(operations.refiHandleCacheChange(cache, Object.keys(cache)));
  } catch (error) {
    dispatch(operations.refiHandleError(error));
    dispatch(actions.refiLoadResourceFailure(error));
  }

  dispatch(actions.refiUpdateResourceSuccess());
  dispatch(actions.refiLoadResourceSuccess(data.length === 0));
  return data;
};

export {
  refiDisableValidation,
  refiGetExistingQuotes,
  refiGetForm,
  refiSendApply,
  refiSendForQuotes,
  refiUpdateOpeId,
  refiLoadResource,
  refiMakeFormReady,
};

export default operations;
