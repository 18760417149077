import React, { forwardRef, useContext } from 'react';

import { InputCard } from './input-card';
import { AppContext } from '../containers/plan-tool';

export const InputGrid = forwardRef((_props, ref) => {
  const { state } = useContext(AppContext);
  const {
    General: general,
    'Monthly Retirement Contribution': month,
    'Other Pre-Tax Costs / Contributions': pretax,
    'Modified Other Pre-Tax Costs / Contributions': modifiedPretax,
    ...rest
  } = state;
  return (
    <div ref={ref} className="polaris-input-grid">
      <div className="polaris-input-sub-grid">
        <InputCard key="General" page="General" inputs={general} />
      </div>
      <div className="polaris-input-sub-grid">
        <InputCard
          key="Monthly Retirement Contribution"
          page="Monthly Retirement Contribution"
          inputs={month}
        />
      </div>
      <div className="polaris-input-sub-grid">
        <InputCard
          key="Other Pre-Tax Costs / Contributions"
          page="Other Pre-Tax Costs / Contributions"
          inputs={pretax}
          modifiedInputs={modifiedPretax}
        />
      </div>
      <div className="polaris-input-sub-grid">
        {Object.entries(rest)
          .filter(([key]) => !key.includes('PovertyGuidelines') && !key.includes('UIData'))
          .map(([page, inputs]) => (
            <InputCard key={page} page={page} inputs={inputs} />
          ))}
      </div>
    </div>
  );
});

InputGrid.displayName = 'InputGrid';
