import { Card } from 'fiducius-ui';
import styled from 'styled-components';

const PlanCard = styled(Card)`
  box-shadow: ${(p) => p.theme.boxShadow};
  margin: -1.5rem 0 1.5rem -2.5rem;
  padding-left: 1.5rem;
  transition: box-shadow 200ms ease-in-out;
  width: 100%;
  word-wrap: break-word;

  &:hover {
    box-shadow: ${(p) => p.theme.boxShadowLarge};
    transition: box-shadow 200ms ease-in-out;
  }
`;

export default PlanCard;
