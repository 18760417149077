import types from './types';
import { isEqual, resourceReducerGenerator } from '../../../utils';

// This is super brittle...
const emptyState = {
  addressOne: null,
  addressTwo: null,
  birthDate: '', // TODO: remove from API
  city: null,
  consolidated: null,
  continuedEducation: null,
  creditScore: '',
  degree: '',
  degreeSought: '',
  federalPrivateMix: null,
  household: null,
  income: null,
  interestRate: null,
  jobTitle: null,
  knowSomeoneWithLoans: null,
  loanBalance: null,
  married: null,
  monthlyPayment: null,
  parentPlus: null,
  privateBalance: null,
  privateLoans: null,
  privatePayment: null,
  privateServicer: null,
  readOnly: false,
  referrerName: null,
  results: {},
  school: null,
  spouseIncome: null,
  startDate: '',
  taxFiling: '',
  usState: null,
  zipCode: null,
};

const emptyDecider = (state) => isEqual(state.cache, emptyState);

export default resourceReducerGenerator('renewal', types, undefined, emptyDecider);
