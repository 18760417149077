import React from 'react';
import t from 'prop-types';
import { parseISO } from 'date-fns';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal } from 'fiducius-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';

import { todosLoadCollection } from '../../todos';
import { mergeRequestStatuses, debounce } from '../../utils';
import { profileLoadResource } from '../../profile';
import { getUserId, getToken } from '../../auth';

import SchedulerForm from '../components/scheduler-form';
import {
  meetingsClearSlots,
  meetingsHandleFormChange,
  meetingsLoadSlots,
  meetingsScheduleMeeting,
} from '../redux/operations';

const StyledSuccess = styled.div`
  border: ${(p) => p.theme.borderWidth} solid ${(p) => p.theme.secondary};
  border-radius: ${(p) => p.theme.borderRadius};
  color: ${(p) => p.theme.secondary};
  display: inline-block;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  text-align: center;
  vertical-align: middle;
`;

class Scheduler extends React.Component {
  static propTypes = {
    buttonBrand: t.string,
    buttonText: t.string,
    clearSlots: t.func.isRequired,
    createRequest: t.object,
    formRequest: t.object,
    loadSlots: t.func.isRequired,
    reloadTodos: t.func.isRequired,
    scheduleMeeting: t.func.isRequired,
    slots: t.object,
    slotsRequest: t.object,
    errors: t.object,
    reloadProfile: t.func.isRequired,
  };

  state = {
    schedulerIsOpen: false,
    shouldClose: false,
    successfulSchedule: false,
  };

  componentDidUpdate() {
    const { createRequest, id } = this.props;

    if (this.state.shouldClose && createRequest.hasFinished && !createRequest.hasFailed) {
      this.setState(
        { schedulerIsOpen: false, shouldClose: false, successfulSchedule: true },
        () => {
          this.props.reloadTodos();
          this.props.reloadProfile(id);
        }
      );
    }
  }

  getOpenSlots = () => {
    const { slots = {} } = this.props;
    return Object.keys(slots).reduce((open, key) => {
      // TODO: fix API to return ISO 8601 with TZ.
      // TODO: THIS IS BROKEN FOR DAYLIGHT SAVINGS CHANGES
      // Everything in the database is EST (UTC-5)
      //let date = new Date(parseISO(slots[key].meetingStartTime + '-0400'));
      let date = new Date(parseISO(slots[key].meetingStartTime));
      return [...open, date];
    }, []);
  };

  getPhone = () => {
    const { slots = {} } = this.props;
    let phone = slots[Object.keys(slots)[0]] ? slots[Object.keys(slots)[0]].phoneNumber : undefined;
    return { cellPhone: phone };
  };

  schedule = (time, note, form, rescheduleID) => {
    const { slots = {} } = this.props;
    // TODO: THIS IS BROKEN FOR DAYLIGHT SAVINGS CHANGES
    const matches = Object.keys(slots).filter(
      //(k) => new Date(parseISO(slots[k].meetingStartTime + '-0400')).getTime() === time.getTime()
      (k) => new Date(parseISO(slots[k].meetingStartTime)).getTime() === time.getTime()
    );

    let meeting = slots[matches[0]];
    if (meeting) {
      meeting.note = note;

      if (this.phoneChanged(form)) {
        if (!(form.cellPhone === undefined)) {
          meeting.phoneNumber = form.cellPhone.replace(/\D/g, '');
          meeting.phoneType = 'CELL';
        }
      }
    } else {
      throw new Error('Slot is no longer available.');
    }
    this.setState({ ...this.state, shouldClose: true }, () =>
      this.props.scheduleMeeting(meeting, rescheduleID)
    );
  };

  toggleScheduler = () => {
    // Sooo... this is really bad to reload these every time, but when there's a
    // massive amount of meeting slots cached in the state it kills the rest of
    // the app... soo... idk.
    // TODO: figure out a better way to do this.
    if (this.state.schedulerIsOpen) {
      this.props.clearSlots();
    } else {
      this.props.loadSlots();

      // When showing the scheduler, always load phone with data from the database
      delete this.props.form.cellPhone;
      delete this.props.errors.cellPhone;
    }
    this.setState({ ...this.state, schedulerIsOpen: !this.state.schedulerIsOpen });
  };

  handleChange = debounce((formState) => {
    const { data, updateFormState } = this.props;
    const newData = { ...data, ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 200);

  phoneChanged = (form) => {
    return form.cellPhone === undefined ? false : true;
  };

  render() {
    const { errors = {}, form = {}, rescheduleID = {} } = this.props;
    if (this.state.successfulSchedule) {
      return (
        <StyledSuccess>
          <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
          You&#39;re scheduled!
        </StyledSuccess>
      );
    }

    return (
      <>
        <Button brand={this.props.buttonBrand || 'primary'} onClick={this.toggleScheduler}>
          {this.props.buttonText || 'Schedule'}
        </Button>
        <Modal isOpen={this.state.schedulerIsOpen}>
          <SchedulerForm
            closeModal={this.toggleScheduler}
            slots={this.getOpenSlots()}
            schedule={this.schedule}
            request={this.props.formRequest}
            handleChange={this.handleChange}
            errors={errors}
            form={form}
            phone={this.getPhone()}
            rescheduleID={rescheduleID}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  slots: state.meetings.cache.available,
  formRequest: mergeRequestStatuses([
    state.meetings.requests.createResource,
    state.meetings.requests.loadCollection,
  ]),
  createRequest: state.meetings.requests.createResource,
  slotsRequest: state.meetings.requests.loadCollection,
  id: getUserId(state),
  token: getToken(state),
  errors: state.meetings.errors,
  form: state.meetings.form,
});

const mapDispatchToProps = (dispatch) => ({
  loadSlots: () => dispatch(meetingsLoadSlots()),
  clearSlots: () => dispatch(meetingsClearSlots()),
  reloadTodos: () => dispatch(todosLoadCollection()),
  scheduleMeeting: (meeting, rescheduleID) =>
    dispatch(meetingsScheduleMeeting(meeting, rescheduleID)),
  updateForm: (meeting) => dispatch(meetingsHandleFormChange(meeting)),
  updateFormState: (data, attributes) => dispatch(meetingsHandleFormChange(data, attributes)),
  reloadProfile: (id) => dispatch(profileLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
