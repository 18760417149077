import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess } from '../../../utils';
import ForgivenessProgress from '../components/forgiveness-progress';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { renewalResultsLoadResource } from '../../../renewal-results';

class Complete extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  componentDidUpdate() {}

  getFailedInfo = () => {
    return (
      <Card>
        <CardBody>
          <CardTitle>Complete Your Forgiveness Steps</CardTitle>
          <CardText>
            <div>
              <p>
                Once all of your steps have been successfully completed, we will provide important
                instructions to ensure you properly navigate making your qualifying payments.
              </p>
            </div>
          </CardText>
        </CardBody>
      </Card>
    );
  };

  render() {
    const { data, request } = this.props;
    return (
      <>
        {(data.inRecert === undefined || data.inRecert === true) && <ForgivenessProgress />}
        <DashboardSection title="Complete">
          <div className="row">
            <div className="col-12">
              <Card>
                <AsyncLoader
                  request={request}
                  failed={request.hasFailed ? this.getFailedInfo() : null}
                >
                  <CardBody>
                    <CardText>
                      {/*data.AnnualRecertDate > date.today && ()*/}
                      {(data.inRecert || data.inRecert === null) && (
                        <div>
                          <CardTitle>Complete Your Forgiveness Steps</CardTitle>
                          <p>
                            Once all of your steps have been successfully completed we will provide
                            important instructions to ensure you properly navigate making your
                            qualifying payments.
                          </p>
                        </div>
                      )}
                      {!data.inRecert && data.inRecert !== null && (
                        <div>
                          <CardTitle>Make Payments</CardTitle>
                          <ul>
                            <li>
                              Payments <strong>must</strong> be paid in the exact amount due to your
                              loan servicer
                            </li>
                            <li>
                              Payments <strong>must</strong> be made on time or up to 15 days after
                              the due date
                            </li>
                            <li>
                              You may set-up automatic payments through your bank (recommended) or
                              your loan servicer
                            </li>
                          </ul>

                          <CardTitle>Notify Us of Life Changes</CardTitle>
                          <ul>
                            <li>Significant increase/decrease in income</li>
                            <li>Change in employment</li>
                            <li>Addition/Subtraction of household size</li>
                          </ul>
                        </div>
                      )}
                    </CardText>
                  </CardBody>
                </AsyncLoader>
              </Card>
            </div>
          </div>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: state.renewalResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(renewalResultsLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Complete));
