import React from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Form,
  Header2,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputNumeric,
  InputRadio,
  InputSelect,
  InputText,
  InputWrapper,
  InputAddon,
} from 'fiducius-ui';
import { idrHandleFormChange, idrValidateAttributes } from '../redux/operations';
import { Fade } from '../../../routing';
import { convertIsoToSlash, safeAccess, stringIsNullOrEmpty } from '../../../utils';
const WorkflowAlert = styled.div`
  & > .alert {
    margin-left: 0;
  }
`;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const InputHelp2 = styled(InputHelp)`
  color: ${(p) => p.theme.textPrimary};
`;

const ContactMessage = () => (
  <WorkflowAlert>
    <Alert brand="danger">
      Please visit the Contact Us button on the left of your portal to reach out to us to continue
      your application process.
      {/*
      Please contact us at <a href="tel:+15136455400">(513) 645-5400</a> and select Option 2 in our
    phone menu to continue your application process. */}
    </Alert>
  </WorkflowAlert>
);

class TabHousehold extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    handleChange: t.func,
    id: t.string.isRequired,
    validateForm: t.func,
    validateSpecificAttributes: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    //const attributes
    //this.props.validateForm(this.props.data, Object.keys(this.props.data));
  }
  componentDidMount() {
    const { data } = this.props;
    let validateAttributes = [];
    if (data.agi === 0) {
      data.agi = '';
    }
    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (!stringIsNullOrEmpty(value)) {
          validateAttributes.push(key);
        }
      }
    }

    this.props.validateSpecificAttributes(validateAttributes);
  }

  render() {
    const { data = {}, errors = {}, handleChange, id } = this.props;
    return (
      <Form id={id} defaults={data} handleChange={handleChange}>
        <Header2>Tell us about your family</Header2>
        <InputWrapper error={!!errors.question5Response}>
          <InputLabel2>
            How many children, including unborn children, are in your family and receive more than
            half of their support from you?
          </InputLabel2>
          <InputError>{safeAccess(errors, 'question5Response.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric name="question5Response" min="0" defaultValue={data.question5Response} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.question6Response}>
          <InputLabel2>
            How many other people, excluding your spouse and children, live with you and receive
            more than half of their support from you?
          </InputLabel2>
          <InputError>{safeAccess(errors, 'question6Response.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric name="question6Response" min="0" defaultValue={data.question6Response} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.question7Response}>
          <InputLabel2 htmlFor="question7Response">What is your marital status?</InputLabel2>
          <InputError>{safeAccess(errors, 'question7Response.detail')}</InputError>
          <InputGroup affordance="medium">
            <InputSelect
              name="question7Response"
              defaultValue={data.question7Response}
              options={{
                '': '',
                A: 'Single',
                B: 'Married',
                /*C: 'Married, but separated',*/
                C:
                  "Married, but separated or unable to reasonably access my spouse's income information",
              }}
            />
          </InputGroup>
        </InputWrapper>
        <Fade id="isMarried" show={data.question7Response === 'B'}>
          <InputWrapper error={!!errors.question8Response}>
            <InputLabel2>Does your spouse have federal student loans?</InputLabel2>
            <InputError>{safeAccess(errors, 'question8Response.detail')}</InputError>
            <InputRadio
              name="question8Response"
              value="A"
              label="Yes"
              defaultChecked={data.question8Response === 'A'}
            />
            <InputRadio
              name="question8Response"
              value="B"
              label="No"
              defaultChecked={data.question8Response === 'B'}
            />
          </InputWrapper>
          <InputWrapper error={!!errors.question9BResponse}>
            <InputLabel2>Spouse&#39;s Name</InputLabel2>
            <InputError>{safeAccess(errors, 'question9BResponse.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputText name="question9BResponse" defaultValue={data.question9BResponse} />
            </InputGroup>
          </InputWrapper>
          <InputWrapper error={!!errors.question9CResponse}>
            <InputLabel2>Spouse&#39;s Date of Birth</InputLabel2>
            <InputError>{safeAccess(errors, 'question9CResponse.detail')}</InputError>
            <InputGroup affordance="medium">
              <InputMask
                defaultValue={convertIsoToSlash(data.question9CResponse)}
                mask="##/##/####"
                name="question9CResponse"
                placeholder="MM/DD/YYYY"
              />
            </InputGroup>
          </InputWrapper>
          <InputWrapper error={!!errors.question10Response}>
            <InputLabel2>
              When you filed your last federal income tax return, did you file jointly with your
              spouse?
            </InputLabel2>
            <InputError>{safeAccess(errors, 'question10Response.detail')}</InputError>
            <InputRadio
              name="question10Response"
              value="A"
              label="Yes"
              defaultChecked={data.question10Response === 'A'}
            />
            <InputRadio
              name="question10Response"
              value="B"
              label="No"
              defaultChecked={data.question10Response === 'B'}
            />
          </InputWrapper>
          <Fade id="filedJointly" show={data.question10Response === 'A'}>
            <InputWrapper error={!!errors.question13Response}>
              <InputLabel2>
                Has your income significantly decreased since you filed your last federal income tax
                return?
              </InputLabel2>
              <InputError>{safeAccess(errors, 'question13Response.detail')}</InputError>
              <InputRadio
                name="question13Response"
                value="A"
                label="Yes"
                defaultChecked={data.question13Response === 'A'}
              />
              <InputRadio
                name="question13Response"
                value="B"
                label="No"
                defaultChecked={data.question13Response === 'B'}
              />
              <InputRadio
                name="question13Response"
                value="C"
                label="I haven't filed a federal income tax return in the last two years"
                defaultChecked={data.question13Response === 'C'}
              />
              <InputHelp2>
                For Example, have you lost your job or experienced a drop in income?
              </InputHelp2>
            </InputWrapper>
            <Fade id="jointClientIncomeNotChanged" show={data.question13Response === 'B'}>
              <InputWrapper error={!!errors.question14Response}>
                <InputLabel2>
                  Has your spouse&#39;s income significantly decreased since your spouse filed his
                  or her last federal income tax return?
                </InputLabel2>
                <InputError>{safeAccess(errors, 'question14Response.detail')}</InputError>
                <InputRadio
                  name="question14Response"
                  value="A"
                  label="Yes"
                  defaultChecked={data.question14Response === 'A'}
                />
                <InputRadio
                  name="question14Response"
                  value="B"
                  label="No"
                  defaultChecked={data.question14Response === 'B'}
                />
                <InputHelp2>
                  For Example, has your spouse lost his or her job or experienced a drop in income?
                </InputHelp2>
              </InputWrapper>
            </Fade>
            <Fade
              id="jointClientIncomeChanged"
              show={
                data.question13Response === 'A' ||
                data.question13Response === 'C' ||
                data.question14Response === 'A'
              }
            >
              <InputWrapper error={!!errors.question15Response}>
                <InputLabel2>Do you currently have taxable income?</InputLabel2>
                <InputError>{safeAccess(errors, 'question15Response.detail')}</InputError>
                <InputRadio
                  name="question15Response"
                  value="A"
                  label="Yes"
                  defaultChecked={data.question15Response === 'A'}
                />
                <InputRadio
                  name="question15Response"
                  value="B"
                  label="No"
                  defaultChecked={data.question15Response === 'B'}
                />
                <InputHelp2>
                  Check <strong>No</strong> if you do not have any income or receive only untaxed
                  income.
                </InputHelp2>
              </InputWrapper>
              <Fade id="jointClientNoTaxableIncome" show={data.question15Response === 'B'}>
                <ContactMessage />
              </Fade>
              <Fade id="jointClientTaxableIncome" show={data.question15Response === 'A'}>
                <InputWrapper error={!!errors.question16Response}>
                  <InputLabel2>Does your spouse currently have taxable income?</InputLabel2>
                  <InputError>{safeAccess(errors, 'question16Response.detail')}</InputError>
                  <InputRadio
                    name="question16Response"
                    value="A"
                    label="Yes"
                    defaultChecked={data.question16Response === 'A'}
                  />
                  <InputRadio
                    name="question16Response"
                    value="B"
                    label="No"
                    defaultChecked={data.question16Response === 'B'}
                  />
                  <InputHelp2>
                    Check <strong>No</strong> if your spouse does not have any income or receives
                    only untaxed income.
                  </InputHelp2>
                </InputWrapper>
              </Fade>
            </Fade>
          </Fade>
        </Fade>
        <Fade
          id="notMarriedorFilingSeparately"
          show={
            data.question7Response === 'A' ||
            data.question7Response === 'C' ||
            data.question7Response === 'D' ||
            data.question10Response === 'B'
          }
        >
          <InputWrapper error={!!errors.question11Response}>
            <InputLabel2>
              Has your income significantly decreased, or your marital status changed since you
              filed your last federal income tax return?
            </InputLabel2>
            <InputError>{safeAccess(errors, 'question11Response.detail')}</InputError>
            <InputRadio
              name="question11Response"
              value="A"
              label="Yes"
              defaultChecked={data.question11Response === 'A'}
            />
            <InputRadio
              name="question11Response"
              value="B"
              label="No"
              defaultChecked={data.question11Response === 'B'}
            />
            <InputRadio
              name="question11Response"
              value="C"
              label="I haven't filed a federal income tax return in the last two years"
              defaultChecked={data.question11Response === 'C'}
            />
            <InputHelp2>
              For Example, have you lost your job, experienced a drop in income, become divorced, or
              did you most recently file a joint return with your spouse, but you have since become
              separated or lost the ability to access your spouse&#39;s information?
            </InputHelp2>
          </InputWrapper>
          <Fade
            id="singleIncomeFiled"
            show={data.question11Response === 'A' || data.question11Response === 'C'}
          >
            {/* **question11Response === 'B' would go to the IRS Section (now Question 17)** */}
            <InputWrapper error={!!errors.question12Response}>
              <InputLabel>Do you currently have taxable income?</InputLabel>
              <InputError>{safeAccess(errors, 'question12Response.detail')}</InputError>
              <InputRadio
                name="question12Response"
                value="A"
                label="Yes"
                defaultChecked={data.question12Response === 'A'}
              />
              <InputRadio
                name="question12Response"
                value="B"
                label="No"
                defaultChecked={data.question12Response === 'B'}
              />
              <InputHelp2>
                Check <strong>No</strong> if you do not have any income or receive only untaxed
                income.
              </InputHelp2>
            </InputWrapper>
            <Fade id="singleNoTaxableIncome" show={data.question12Response === 'B'}>
              <ContactMessage />
            </Fade>
          </Fade>
        </Fade>
        <InputWrapper error={!!errors.agi}>
          <InputLabel2>Adjusted Gross Income</InputLabel2>
          <InputError>{safeAccess(errors, 'agi.detail')}</InputError>
          <InputGroup affordance="small">
            <InputAddon>$</InputAddon>
            <InputNumeric name="agi" defaultValue={data.agi} />
            <InputAddon>.00</InputAddon>
            <InputHelp2></InputHelp2>
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  validateForm: (data, attributes) => dispatch(idrHandleFormChange(data, attributes)),
  validateSpecificAttributes: (attributes) => dispatch(idrValidateAttributes(attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabHousehold);
