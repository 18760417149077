import React from 'react';
import t from 'prop-types';

import { Button, InputGroup, InputLabel, InputText, InputWrapper } from 'fiducius-ui';
import { safeAccess, useFocusOnMount } from '../../utils';

const SecurityQuestion = ({ data, handleClick }) => {
  const firstInput = useFocusOnMount();

  return (
    <>
      <InputWrapper>
        <InputLabel>{safeAccess(data, 'securityQuestion')}</InputLabel>
        <InputGroup>
          <InputText inputRef={firstInput} name="securityQuestionResponse" defaultValue="" />
        </InputGroup>
      </InputWrapper>
      <InputWrapper>
        <Button brand="primary" type="submit" onClick={handleClick}>
          Unlock Account
        </Button>
      </InputWrapper>
    </>
  );
};

SecurityQuestion.propTypes = {
  data: t.object.isRequired,
  handleClick: t.func.isRequired,
};

export default SecurityQuestion;
