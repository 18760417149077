import React from 'react';
import styled from 'styled-components';
import { Table, Modal, Card, CardBody, CardHeader, CardFooter, Button } from 'fiducius-ui';

import { formatAsMoney, convertIsoToSlash } from '../../utils';

const PaddedDiv = styled.div`
  padding-bottom: 1em;
`;

const getTable = (props) => {
  if (props.data === null || props.data.length === 0) {
    return null;
  }
  let columns = [];
  let displayHeaders = [];
  const displayBody = [];
  let displayEmployeeAmt = false;
  if (props.employeeContribution > 0) {
    displayEmployeeAmt = true;
  }

  if (displayEmployeeAmt) {
    displayHeaders = [
      'Account',
      'Employer Payment Amount',
      'Employee Payment Amount',
      'Total Payment Amount',
      'Scheduled Payment Date',
    ];

    props.data.forEach((row) => {
      displayBody.push([
        row.account,
        formatAsMoney(row.employerAmount),
        formatAsMoney(row.employeeAmount),
        formatAsMoney(row.totalAmount),
        convertIsoToSlash(row.paymentScheduleDate),
      ]);
    });
  } else {
    displayHeaders = ['Account', 'Employer Payment Amount', 'Total Payment Amount', 'Payment Date'];

    props.data.forEach((row) => {
      displayBody.push([
        row.account,
        formatAsMoney(row.employerAmount),
        formatAsMoney(row.totalAmount),
        convertIsoToSlash(row.paymentScheduleDate),
      ]);
    });
  }

  return (
    <Table
      meta={{
        pageSize: 10,
      }}
      body={displayBody}
      columns={columns}
      header={displayHeaders}
    />
  );
};
const AvailablePaymentHistoryTable = (props) => {
  const modalRef = React.createRef();
  if (modalRef.current !== null) {
    modalRef.current.scrollTo(0, 0);
  }

  return (
    <Modal ref={modalRef} isOpen={props.isOpen} fullWidth={false}>
      <Card>
        <CardHeader>Payment History</CardHeader>
        <CardBody>
          {getTable(props)}
          <PaddedDiv className="row">
            <div className="col-12">
              Note: Contribution payments are submitted within 3-5 business days of the Scheduled
              Payment Date. However, please note that the processing time may vary with different
              loan servicers.
            </div>
          </PaddedDiv>
        </CardBody>
        <CardFooter>
          <Button brand="lowContrast" onClick={props.closeForm}>
            Close
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

export default AvailablePaymentHistoryTable;
