import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';

import { mergeRequestStatuses, convertIsoToSlash } from '../../../utils';

import { withPermissions } from '../../../routing';

import EmpCertProgress from '../components/emp-cert-progress';

class EmpCertComplete extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  getNextReviewDate() {
    const { permissions } = this.props;
    const empCertStep = permissions.enterpriseEmpcertSteps.find(
      (a) => a.id === 'CompleteEnterpriseEmpcert'
    );

    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        return convertIsoToSlash(empCertStep.stepNextReviewDate);
      } else {
        return null;
      }
    }
  }

  componentDidUpdate() {}

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { request, permissions } = this.props;
    let isComplete = false;
    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      const currentStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);
      if (currentStep.id == 'CompleteEnterpriseEmpcert') {
        isComplete = true;
      }
    }

    return (
      <>
        <AsyncLoader request={request}>
          <div id="step3Section">
            <EmpCertProgress />
            <DashboardSection title="Employment Certification Completed">
              <Card>
                <CardBody>
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    {isComplete && (
                      <ul>
                        <li>
                          You have completed all of the steps of the employment certification
                          process for this year.
                        </li>
                        <li>
                          This process is completed on an annual basis and you will be able to start
                          the process again on {this.getNextReviewDate()}.
                        </li>
                      </ul>
                    )}
                    {!isComplete && <p>You have not yet completed all of the steps.</p>}
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertComplete));
