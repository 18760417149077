import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <strong>Step 1 – Login to your account at MOHELA.</strong>
    <ul>
      <li>
        Request general forbearance for <strong>twelve months</strong>.
      </li>
      <li>
        <strong>Please note:</strong> It is possible that you do not use the full length of your
        request. Your forbearance request will end once the correct income-driven repayment is
        established in a future step.
      </li>
    </ul>
    <strong>Step 2 – Confirm you have requested forbearance.</strong>
    <ul>
      <li>
        I confirm I have submitted a forbearance request at MOHELA to place my current loan payments
        on hold.
      </li>
      <li>
        Forbearance requests typically take up to two weeks to process and any payment made while in
        forbearance may not qualify.
      </li>
      <li>
        <strong>Please note:</strong> If you have used more than 36 months of general forbearance,
        your request may be denied by the loan servicer.
      </li>
    </ul>
    <br />
    <br />
    Questions? Please visit the Contact Us button on the left of your portal to reach out to us.
    {/*Contact us at <a href="tel:+15136455400">(513) 645-5400</a> Option: 2*/}
  </>
);

const ForbearancePhaseTwoComplete = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/dashboard')}
    text={<TodoText />}
    title="Request Forbearance - Phase 2"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForbearancePhaseTwoComplete);
