import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess, debounce, mergeRequestStatuses, flatten } from '../../../utils';
import ComponentStepDisplay from '../components/component-step-display';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import StepDisplay from '../components/step-display';

class ContributionRSSS extends React.Component {
  static propTypes = {
    data: t.object.isRequired,
    form: t.object.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    onLoad: t.func.isRequired,
    action: t.func.isRequired,
    isMarried: t.bool.isRequired,
    errors: t.object,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showStep1: false,
      showStep2: false,
    };
  }

  componentDidMount() {
    //this.props.onLoad();
    const pathName = window.location.pathname;
    if (pathName.includes('/step')) {
      this.setState({
        ...this.state,
        showStep1: pathName.includes('/step-1'),
        showStep2: pathName.includes('/step-2'),
      });
    }
  }

  componentDidUpdate() {}

  handleChange = debounce((formState, id, attribute) => {
    this.props.updateForm({ ...this.props.form, ...formState }, [attribute]);
  }, 250);

  getCurrentStep = () => {
    const { todos, permissions } = this.props;
    const forgivenessSteps = flatten(permissions.forgivenessSteps.filter((a) => a.stepCurrent));

    let CurrentToDo = todos.find(
      (a) => forgivenessSteps.id === 'Contribution' && (a.id === 260 || a.id === 261)
    );

    if (CurrentToDo === undefined || CurrentToDo === null) {
      CurrentToDo = 0;
    } else {
      CurrentToDo = CurrentToDo.id;
    }

    return CurrentToDo;
  };

  render() {
    const { todos } = this.props;
    let currentToDo = 0;

    if (Object.entries(todos).length > 0) {
      currentToDo = this.getCurrentStep();
    }

    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <Card>
            <CardBody>
              <AsyncLoader request={this.props.request}>
                <CardTitle>Contribution</CardTitle>
                <CardText>
                  Completing this step will allow you to set-up your employer contribution for this
                  year.
                </CardText>
                <ComponentStepDisplay
                  title={'Step 1 – Upload your repayment details'}
                  show={this.state.showStep1}
                >
                  <CardText>
                    <ul>
                      <li>
                        The next step of the process is for you to provide your repayment details
                        for verification.
                      </li>
                    </ul>

                    {currentToDo === 260 && <RenewalConfirmation stepId={260} />}
                  </CardText>
                </ComponentStepDisplay>
                <StepDisplay title={'Step 2 – Repayment details review'}>
                  <ul>
                    <li>
                      We are validating your income-driven repayment and employer contribution. The
                      review can take up to 45 days to complete.
                    </li>
                  </ul>
                </StepDisplay>
              </AsyncLoader>
            </CardBody>
          </Card>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const userId = getUserId(state);
  let data = safeAccess(state, `idr.cache[${userId}]`, {});
  let form = safeAccess(state, 'idr.form', {});
  form.actualRepaymentType = data.projectedRepaymentType;
  form.projectedRepaymentType = data.projectedRepaymentType;

  return {
    data: data,
    form: form,
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
    ]),
    todos: Object.values(state.todos.cache),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ContributionRSSS));
