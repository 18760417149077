import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { DashboardSection, Card, Loader } from 'fiducius-ui';

import { authGetRedirectPath, getToken, LoaderWrapper } from '../../auth';
import SecurityQuestionsForm from '../components/security-questions-form';
import { getApi, mergeRequestStatuses } from '../../utils';

class SecurityQuestionsDashboard extends React.Component {
  static propTypes = {
    getRedirectPath: t.string,
    saveRequest: t.object,
    token: t.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      group1Questions: {},
      group2Questions: {},
      group3Questions: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getQuestions().then((data) => {
      this.setState({
        ...this.state,
        group1Questions: data[0],
        group2Questions: data[1],
        group3Questions: data[2],
        isLoading: false,
        isSubmitted: true,
      });
    });
  }

  getQuestions = async () => {
    const group1Questions = await this.getSecurityQuestionGroup('1');
    const group2Questions = await this.getSecurityQuestionGroup('2');
    const group3Questions = await this.getSecurityQuestionGroup('3');

    return [group1Questions, group2Questions, group3Questions];
  };

  getSecurityQuestionGroup = async (groupId) => {
    const data = await getApi(this.props.token, '/drop-down-list/SecurityQuestions__' + groupId);
    let questions = {};
    questions['0'] = '';
    Object.keys(data).forEach((a) => {
      questions[data[a].id] = data[a].description;
    });
    return questions;
  };

  onSubmit = () => {
    this.setState({ ...this.state, isSubmitted: true });
  };

  render() {
    const { group1Questions, group2Questions, group3Questions, isLoading } = this.state;
    return (
      <DashboardSection title="Complete Your Security Questions">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Card>
              {isLoading && (
                <LoaderWrapper>
                  <Loader variant="atom" size={3} />
                </LoaderWrapper>
              )}
              {!isLoading && (
                <SecurityQuestionsForm
                  group1Questions={group1Questions}
                  group2Questions={group2Questions}
                  group3Questions={group3Questions}
                  onSubmit={this.onSubmit}
                  showSaveButton={true}
                />
              )}
            </Card>
          </div>
        </div>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.securityQuestionsSetup;

  return {
    token: getToken(state),
    getRedirectPath: authGetRedirectPath(state, true),
    saveRequest: mergeRequestStatuses([
      requests.createResource,
      state.todos.requests.loadCollection,
      state.auth.requests.loadPermissions,
    ]),
  };
};

export default connect(mapStateToProps)(withRouter(SecurityQuestionsDashboard));
