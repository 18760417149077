import React from 'react';
import t from 'prop-types';
import { Button, Card, CardBody, Header2, InputWrapper } from 'fiducius-ui';

import { MessageTile } from '../../../messages';

const TaxEstimatorIntro = ({ handleIntroButtonClick }) => {
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-12">
          <MessageTile messageType={'Tax_Tool'} />
          <Card brand="secondary">
            <CardBody>
              <Header2>What You Need to Begin</Header2>
              <p>
                Your & your spouse's last paycheck from the prior year, W2's & federal 1040's (if
                completed).
              </p>
              <p>
                Your & your spouse's current federal student loan balances, visit{' '}
                <a href="https://studentaid.gov" target="_blank">
                  https://studentaid.gov
                </a>{' '}
                for help.
              </p>
              <p>Take a few minutes to complete and get instant results!</p>
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          <InputWrapper>
            <Button brand="primary" onClick={handleIntroButtonClick}>
              {' '}
              {'Continue to PaySmart Loan Navigator'}{' '}
            </Button>
          </InputWrapper>
        </div>
        <div className="col-4"></div>
      </div>
    </>
  );
};

TaxEstimatorIntro.propTypes = {
  handleIntroButtonClick: t.func.isRequired,
};

export default TaxEstimatorIntro;
