import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Header4,
  Header5,
  Lede,
  AsyncLoader,
  CardFooter,
  Link,
} from 'fiducius-ui';
import b64toBlob from 'b64-to-blob';

import { convertIsoToSlash, formatAsMoney, safeAccess } from '../../../utils';
import { getContributionData } from '../redux/selectors';
import {
  getContributionSetupData,
  contributionSetupLoadResource,
} from '../../../contribution-setup';

import { getUserId, getToken } from '../../../auth';

import { withPermissions, PaddedDD } from '../../../routing';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { contributionLoadResource } from '../redux/operations';
import {
  customerContributionLoadCollection,
  ActiveContribution,
} from '../../../customer-contribution';
import { documentsLoadContbFaq } from '../../../documents';

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const StyledDiv = styled.div`
  text-align: right;
`;

const StyledHeader5 = styled(Header5)`
  color: ${(p) => p.theme.textPrimary};
`;

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

class ContributionSummary extends React.Component {
  static propTypes = {
    id: t.string,
    token: t.string,
    data: t.object,
    contributionData: t.object,
    setup: t.object,
    load: t.func,
    getFAQ: t.func.isRequired,
    permissions: t.object,
    request: t.object.isRequired,
    contributionRequest: t.object.isRequired,
    fromDashboard: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //get single api here
    const { id, load, fromDashboard = false } = this.props;
    if (fromDashboard) {
      load(id);
    }
  }

  componentDidUpdate() {}

  onDownloadFaq = () => {
    this.props.getFAQ().then((data) => {
      if (safeAccess(data, 'fileData')) {
        this.getFileFromData(data.fileData, 'FAQ.pdf');
      }
    });
  };

  getFileFromData = (data, name) => {
    const blob = b64toBlob(data);

    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      return navigator.msSaveBlob(blob, name);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      // Firefox and Edge require actual appended element for click()
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }
  };

  showEmployeeContribution() {
    const { setup, permissions } = this.props;
    let retVal = true;

    if (
      !permissions.showForgiveness &&
      (setup.nonPslfAllowCustomEmployeeContribution === 'NO' ||
        setup.nonPslfAllowCustomEmployeeContribution === 'PMTNOPD' ||
        setup.nonPslfAllowCustomEmployeeContribution === 'PMTAMT')
    ) {
      retVal = false;
    }

    return retVal;
  }

  getPayoutDate() {
    const { data } = this.props;

    let retComponent = null;

    let today = new Date();
    let comparison = new Date(data.payoutStartDate);
    if (comparison > today) {
      retComponent = (
        <Lede>
          You are not eligible for contribution payments until{' '}
          {convertIsoToSlash(data.payoutStartDate)}.
        </Lede>
      );
    }
    return retComponent;
  }

  render() {
    const {
      data,
      contributionData,
      request,
      contributionRequest,
      fromDashboard,
      permissions,
    } = this.props;

    let className = fromDashboard ? 'col-6 col-lg-3' : 'col-6 col-lg-4';

    return (
      <Card brand={fromDashboard ? 'tertiary' : undefined}>
        <CardBody>
          <CardTitle>
            <div className="row">
              <div className="col-12 col-sm-auto">
                <CardTitle>{fromDashboard ? 'Contribution' : 'Summary Information'}</CardTitle>
              </div>
              <StyledDiv className="col-12 col-sm">
                <AsyncLoader request={request} loading={<></>}>
                  {data.faqUploaded && (
                    <Button brand="primary" onClick={this.onDownloadFaq}>
                      <PaddedIcon icon={faFileDownload} fixedWidth />
                      Download Policy Information
                    </Button>
                  )}
                </AsyncLoader>
              </StyledDiv>
            </div>
          </CardTitle>
          <AsyncLoader request={request}>
            <CardText>
              <div className="row">
                <div className={className}>
                  <dl>
                    <dt>Total Contribution</dt>
                    <PaddedDD>{formatAsMoney(data.totalContribution)}</PaddedDD>
                  </dl>
                </div>
                {this.showEmployeeContribution() && (
                  <div className={className}>
                    <dl>
                      <dt>Total Employer Contribution</dt>
                      <PaddedDD>{formatAsMoney(data.totalEmployerContribution)}</PaddedDD>
                    </dl>
                  </div>
                )}

                {!fromDashboard && (
                  <div className={className}>
                    <dl>
                      <dt>Time in Program</dt>
                      <PaddedDD>{data.monthsInProgram} Month(s)</PaddedDD>
                    </dl>
                  </div>
                )}

                <div className={className}>
                  <dl>
                    <dt>YTD Contribution </dt>
                    <PaddedDD>{formatAsMoney(data.ytdContribution)}</PaddedDD>
                  </dl>
                </div>

                {this.showEmployeeContribution() && (
                  <div className={className}>
                    <dl>
                      <dt>YTD Employer Contribution</dt>
                      <PaddedDD>{formatAsMoney(data.ytdEmployerContribution)}</PaddedDD>
                    </dl>
                  </div>
                )}

                {!fromDashboard && (
                  <div className={className}>
                    <dl>
                      <dt>Max Monthly Employer Contribution </dt>
                      <PaddedDD>{formatAsMoney(data.monthlyEmployerContribution)}</PaddedDD>
                    </dl>
                  </div>
                )}
              </div>

              {fromDashboard && (
                <div className="row">
                  <div className="col-12">
                    <Header4>Active Contribution(s)</Header4>
                  </div>
                  <div className="col-12">
                    <AsyncLoader
                      request={contributionRequest}
                      loading={request.hasFinished ? undefined : <></>}
                      empty={
                        <>
                          <StyledHeader5>
                            There are no active contribution accounts on file.
                          </StyledHeader5>
                        </>
                      }
                    >
                      {Object.values(contributionData).map((a, i) => (
                        <ActiveContribution data={a} key={i} dataLevel={0} />
                      ))}
                      {(contributionData === undefined ||
                        Object.values(contributionData).length === 0 ||
                        Object.values(contributionData).filter((a) => a.active).length === 0) && (
                        <StyledHeader5>
                          There are no active contribution accounts on file.
                        </StyledHeader5>
                      )}
                    </AsyncLoader>
                  </div>
                </div>
              )}
              {!fromDashboard && this.getPayoutDate()}
            </CardText>
          </AsyncLoader>
        </CardBody>

        {fromDashboard && !permissions.contributionReadOnly && (
          <FlexFooter>
            <Link to="/benefits/contribution/overview">Contribution</Link>
            <Link to="/accounts">My Accounts</Link>
          </FlexFooter>
        )}
        {fromDashboard && permissions.contributionReadOnly && (
          <FlexFooter>{/*We will likely add something here at some point.*/}</FlexFooter>
        )}
      </Card>
    );
  }
}
//}

const mapStateToProps = (state) => {
  return {
    token: getToken(state),
    id: getUserId(state),
    request: state.contribution.requests.loadResource,
    contributionRequest: state.customerContribution.requests.loadCollection,
    data: getContributionData(state),
    contributionData: safeAccess(state, 'customerContribution.cache', {}),
    setup: getContributionSetupData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: (id) => {
    dispatch(contributionLoadResource(id));
    dispatch(contributionSetupLoadResource(id));
    dispatch(customerContributionLoadCollection());
  },
  getFAQ: () => {
    return dispatch(documentsLoadContbFaq());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ContributionSummary));
