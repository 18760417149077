import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AsyncLoader, Badge, Header2 } from 'fiducius-ui';

import { ApiFile } from '../../documents';
import { safeAccess } from '../../utils';

import FulfillmentFileLink from '../components/fulfillment-file-link';
import { fulfillmentDisableValidation, fulfillmentLoadCollection } from '../redux/operations';

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const fileLinks = {
  8: '/agreement',
  // 9: '/fulfillment/9', // Tax Prep
  // 10: '/fulfillment/10', // Forbearance
  11: '/idr',
  // 12: '/fulfillment/12', // Emp Cert
  // 13: '/fulfillment/13', // Consolidation
  // 14: '/fulfillment/14', // Fed Loan Release Info
  // 15: '/fulfillment/15', // Fedloan Request End Defer Forbear
  // 16: '/fulfillment/16', // Fed Loan School Defer Waiver
  // 17: '/fulfillment/17', // Pretax Letterhead
  // 18: '/fulfillment/18', // Emp Cert Ft Not Employed
  // 19: '/fulfillment/19', // Emp Cert Pt Employed
  // 20: '/fulfillment/20', // Emp Cert Pt Not Employed
};

class FulfillmentLibrary extends React.Component {
  static propTypes = {
    docs: t.object,
    files: t.object,
    loadDocs: t.func.isRequired,
    request: t.object,
  };

  componentDidMount() {
    this.props.loadDocs();
  }

  render() {
    const { docs, files, request } = this.props;
    const keys = Object.keys(files);

    if (keys.length > 0) {
      keys.forEach((key) => {
        files[key].allowedDownload = true;
        files[key].name = safeAccess(docs, `[${key}].name`, 'Download');
        files[key].fileName = safeAccess(docs, `[${key}].name`, 'Download');
        files[key].hasExampleFile = false;
        if (safeAccess(docs, `[${key}].fileData`)) {
          files[key].fileData = docs[key].fileData;
        }
      });
    }

    return (
      <AsyncLoader request={request} loading={null} empty={null}>
        {keys.length > 0 && (
          <>
            <Header2>Documents that need your attention</Header2>
            <FlexWrap>
              {files &&
                keys
                  .sort((a, b) => {
                    if (files[a].description < files[b].description) {
                      return -1;
                    }
                    if (files[a].description > files[b].description) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((key) => {
                    const file = files[key];
                    if (file.viewOnly) {
                      return <ApiFile key={key} file={file} exampleFile={file.exampleFile} />;
                    } else if (!file.signed) {
                      return (
                        <FulfillmentFileLink
                          key={key}
                          link={fileLinks[file.docId] || `/fulfillment/${file.docId}`}
                          name={<Badge brand="danger">Sign Document</Badge>}
                          title={file.description}
                          type="document"
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
            </FlexWrap>
          </>
        )}
      </AsyncLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  docs: state.documents.cache,
  files: state.fulfillment.cache,
  request: state.fulfillment.requests.loadCollection,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocs: () => {
    dispatch(fulfillmentDisableValidation());
    dispatch(fulfillmentLoadCollection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FulfillmentLibrary);
