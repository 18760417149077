import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardFooter,
  Modal,
  CardTitle,
  AsyncLoader,
} from 'fiducius-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { legalUpdateResource } from '../../legal/redux/operations';
import { withPermissions } from '../../routing';
import { authLoadPermissions } from '../../auth';
import { convertIsoToSlash, formatAsMoney, mergeRequestStatuses } from '../../utils';

const ExitButton = styled.div`
  color: ${(p) => p.theme.textSecondary};
  float: right;

  &:hover {
    color: ${(p) => p.theme.primary};
    cursor: pointer;
  }
`;

const TextLeft = styled.div`
  text-align: left;
`;

const IDRDIALOG_VIEWED_KEY = 'IDRACK';

const reducedIDRModal = ({ markAcknowledged, permissions, loadRequest }) => {
  const [openIDRModal, setOpenIDRModal] = useState(true);

  const closeModal = () => {
    setOpenIDRModal(false);
    markAcknowledged();
  };

  return (
    <AsyncLoader request={loadRequest}>
      <Modal isOpen={openIDRModal} fullWidth theme={'position:fixed;'}>
        <Card>
          <CardBody>
            <ExitButton>
              <FontAwesomeIcon icon={faTimes} fixedWidth onClick={closeModal} size="lg" />
            </ExitButton>
            <CardTitle>Information for Reduced IDR </CardTitle>
            <CardText>
              <TextLeft>
                You are currently on an Income-Driven Repayment (IDR) of{' '}
                {formatAsMoney(permissions.doeIdrCalculation)}. Based on the information you
                provided in the screening questionnaire, we estimate your IDR would be higher if you
                were to resubmit an IDR application at this time. For that reason, you will not have
                any additional steps under Reduced IDR until the annual recertification date,{' '}
                {convertIsoToSlash(permissions.annualRecertDate)}, of your IDR plan.
                <br />
                <br />
                If you have any questions, please submit a Contact Us.
              </TextLeft>
            </CardText>
          </CardBody>
          <CardFooter>
            <Button brand="lowContrast" onClick={closeModal}>
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
    </AsyncLoader>
  );
};

reducedIDRModal.propTypes = {
  permissions: t.object,
};

const mapStateToProps = (state) => ({
  request: state.legal.requests.updateResource,
  loadRequest: mergeRequestStatuses([
    state.todos.requests.loadCollection,
    state.legal.requests.updateResource,
    state.auth.requests.loadPermissions,
  ]),
});

const mapDispatchToProps = (dispatch) => ({
  markAcknowledged: async () => {
    await dispatch(legalUpdateResource(IDRDIALOG_VIEWED_KEY));
    await dispatch(authLoadPermissions());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(reducedIDRModal));
