import React, { useState } from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  InputLabel,
  InputError,
  InputGroup,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  Form,
} from 'fiducius-ui';

import { FlexBetween } from '../../../root';
import { safeAccess, mergeRequestStatuses, debounce } from '../../../utils';
import { connect } from 'react-redux';

import { PdfViewer, Signature } from '../../../fulfillment';

import { authLoadPermissions } from '../../../auth';
import { todosLoadCollection } from '../../../todos/redux/operations';

import {
  selfServiceDocumentUpdateResource,
  selfServiceDocumentLoadDocuments,
  selfServiceDocumentMarkStatus,
  selfServiceDocumentClearDocument,
  selfServiceDocumentClientEmpCertApproval,
} from '../../../self-service-document';

import {
  //empcertApprovalSaveClientApprovalInfo,
  empcertApprovalHandleFormChange,
} from '../../../empcert-approval';

const TextLeft = styled.div`
  text-align: left;
`;

const EmpcertClientApproval = ({
  isOpen,
  closeForm,
  request,
  documents,
  documentId,
  documentIncorrect,
  stepId,
  clientSignAndApprove,
  form,
  updateForm,
  errors = {},
}) => {
  const [formState, setFormState] = useState();
  const [ssn1State, setssn1State] = useState('');
  const [ssn2State, setssn2State] = useState('');
  const [ssn3State, setssn3State] = useState('');
  const [documentIncorrectModal, setDocumentIncorrectModal] = useState(false);

  let ssnOnFile = safeAccess(documents['current'][documentId], 'haveSsn', false);

  const handleClientSignature = (data) => {
    updateForm({ ...form, signaturePadClient: data }, ['signaturePadClient']);
    setFormState(data);
  };

  const toggleDocumentIncorrectModal = () => {
    setDocumentIncorrectModal(!documentIncorrectModal);
  };

  const handleNumericChange = (e) => {
    const re = /^[0-9\b]+$/;
    switch (e.target.name) {
      case 'ssn1':
        if (e.target.value === '' || re.test(e.target.value)) {
          setssn1State(e.target.value);
        }
        break;
      case 'ssn2':
        if (e.target.value === '' || re.test(e.target.value)) {
          setssn2State(e.target.value);
        }
        break;
      case 'ssn3':
        if (e.target.value === '' || re.test(e.target.value)) {
          setssn3State(e.target.value);
        }
        break;
      default:
        break;
    }
  };

  const handleChange = debounce((formState, id, attribute) => {
    formState.docId = documentId;
    const newData = { ...form, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  const submitClientSignAndApprove = () => {
    clientSignAndApprove(documentId).then(closeForm(false));
  };

  const submitDocumentIncorrect = () => {
    documentIncorrect(documentId).then(closeForm(true));
  };

  const allowedToSubmit = () => {
    let retVal = true;
    if (
      !formState ||
      (!ssnOnFile
        ? !(ssn1State.length === 3) || !(ssn2State.length === 2) || !(ssn3State.length === 4)
        : false)
    ) {
      retVal = false;
    }

    return retVal;
  };

  const getFormReviewInstructions = () => {
    if (stepId.id === 253 || stepId.id === 107) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information you provided. We
            recommend that you review the document to confirm it is filled out properly.
          </p>
          <p>Please review and electronically sign this document.</p>
          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    } else if (
      (stepId.id === 256 || stepId.id === 112) &&
      safeAccess(documents['current'][documentId], 'description', '') !== 'Taxes' &&
      safeAccess(documents['current'][documentId], 'description', '') !== "Spouse's Taxes"
    ) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information you provided. We
            recommend that you review the document to confirm it is filled out properly.
          </p>
          <p>
            <strong>Repayment review recommendations: </strong>
          </p>
          <ul>
            <li>
              Please verify your Social Security Number is present on the first six pages of your
              application prior to submitting it.
            </li>
            {/*ADD PART FOR SPOUSE's SSN IF TIME PERMITS*/}
          </ul>

          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    } else if (stepId.id === 104) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information you provided. We
            recommend that you review the document to confirm it is filled out properly.
          </p>
          <p>
            <strong>Consolidation review recommendations: </strong>
          </p>
          <ul>
            <li>
              Please verify your Social Security Number is present on all pages up to the signature
              page.
            </li>
            <li>Please verify that your address on line #9 is not a P.O. box.</li>
            <li>
              Please verify that your references at the bottom of page one have different home
              addresses and phone numbers from both you and each other.
            </li>
          </ul>

          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    }
    if (stepId.id === 810) {
      return (
        <>
          <p>
            The document above is customized to your situation and the information your employer
            provided. We recommend that you review the document to confirm it is filled out
            properly.
          </p>
          <p>Please review and electronically sign this document.</p>
          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. You will need to contact your employer to
            discuss the information you feel is incorrect.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer. The submission of an employment certification form will initiate a
            transfer of your student loans to MOHELA, if your loans are not currently at that loan
            servicer.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>
            {' '}
            If you find an issue with your document, please select the{' '}
            <strong>Form is Incorrect</strong> button. A member of your Fiducius team will be
            contact within 3 business days to address the issue.
          </p>
          <p>
            If you confirm that your document is accurate, please select the{' '}
            <strong>Form is Correct</strong> button to verify the document is ready to be faxed to
            your loan servicer.
          </p>
        </>
      );
    }
  };

  //only need to check for IDR's and Consolidations
  let isFulfillmentDoc =
    safeAccess(documents['current'][documentId], 'name', '') === 'IDR' ||
    safeAccess(documents['current'][documentId], 'name', '') === 'Consolidation';

  return (
    // <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
    <Modal isOpen={isOpen} fullWidth={true}>
      {!documentIncorrectModal && (
        <Card>
          <CardHeader>
            Approve {safeAccess(documents['current'][documentId], 'description', '')} For Signature
          </CardHeader>
          <CardBody>
            <PdfViewer
              base64={safeAccess(documents['current'][documentId], 'fileData', '')}
              isFulfillment={isFulfillmentDoc}
            />
            <Card>
              <TextLeft>
                <CardBody>
                  <CardText>{getFormReviewInstructions()}</CardText>
                </CardBody>
              </TextLeft>
            </Card>
          </CardBody>
          <Form id={'empcertClientApproval'} handleChange={handleChange}>
            <Card>
              <CardBody>
                <Signature
                  handleChange={handleClientSignature}
                  label="Signature:"
                  name="clientSignature"
                />
                {!ssnOnFile && (
                  <>
                    <TextLeft>
                      <p>
                        One requirement of this form is your Social Security Number (SSN), please
                        provide it and we will populate it to the form
                      </p>
                    </TextLeft>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <TextLeft>
                          <InputWrapper error={!!errors.empCertSSN}>
                            <InputLabel required>Social Security Number</InputLabel>
                            <InputError>{safeAccess(errors, 'empCertSSN.detail')}</InputError>
                            <InputGroup>
                              <InputText
                                name="ssn1"
                                type="password"
                                autoComplete="off"
                                placeholder="XXX"
                                maxLength="3"
                                onChange={handleNumericChange}
                                value={ssn1State}
                              />
                              &nbsp;
                              <InputText
                                name="ssn2"
                                type="password"
                                autoComplete="off"
                                placeholder="XX"
                                maxLength="2"
                                onChange={handleNumericChange}
                                value={ssn2State}
                              />
                              &nbsp;
                              <InputText
                                name="ssn3"
                                type="password"
                                autoComplete="off"
                                placeholder="XXXX"
                                maxLength="4"
                                onChange={handleNumericChange}
                                value={ssn3State}
                              />
                            </InputGroup>
                          </InputWrapper>
                        </TextLeft>
                      </div>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            <CardFooter>
              <FlexBetween>
                <Button brand="lowContrast" onClick={closeForm}>
                  Cancel
                </Button>
                {stepId.id !== 810 && (
                  <Button brand="danger" onClick={submitDocumentIncorrect}>
                    {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Form is Incorrect'}
                  </Button>
                )}
                {stepId.id === 810 && (
                  <Button brand="danger" onClick={toggleDocumentIncorrectModal}>
                    {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Form is Incorrect'}
                  </Button>
                )}
                {allowedToSubmit() ? (
                  <Button brand="success" onClick={submitClientSignAndApprove}>
                    {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Submit'}
                  </Button>
                ) : (
                  <>To submit, please finish filling out the above information correctly.</>
                )}
              </FlexBetween>
            </CardFooter>
          </Form>
        </Card>
      )}
      {documentIncorrectModal && (
        <Card>
          <CardHeader>Confirm Form is Incorrect</CardHeader>
          <CardBody>
            <TextLeft>
              <p>
                If you select "Confirm" you are confirming that information is incorrect on your
                employment certification form. It is your responsibility to contact your employer to
                discuss the information you feel is incorrect.
              </p>
            </TextLeft>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={toggleDocumentIncorrectModal}>
                Cancel
              </Button>
              <Button brand="danger" onClick={submitDocumentIncorrect}>
                {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Confirm'}
              </Button>
            </FlexBetween>
          </CardFooter>
        </Card>
      )}
    </Modal>
  );
};

EmpcertClientApproval.propTypes = {
  closeForm: t.func.isRequired,
  isOpen: t.bool,
  onSubmit: t.func.isRequired,
  request: t.object.isRequired,
  documents: t.object.isRequired,
  documentId: t.string.isRequired,
  markDocument: t.func.isRequired,
  clientSignAndApprove: t.func.isRequired,
  update: t.func.isRequired,
  documentIncorrect: t.func.isRequired,
  alreadySent: t.func.isRequired,
  stepId: t.object,
};

const mapStateToProps = (state) => {
  return {
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
      state.selfServiceDocument.requests.updateResource,
      state.selfServiceDocument.requests.loadCollection,
    ]),
    form: state.empcertApproval.form,
    documents: safeAccess(state, 'selfServiceDocument.cache'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  update: async (id) => {
    await dispatch(selfServiceDocumentUpdateResource(id));
  },
  markDocument: async (id, readyToSend, sentToServicer, documentIncorrect) => {
    await dispatch(
      selfServiceDocumentMarkStatus(id, readyToSend, sentToServicer, documentIncorrect)
    );
  },
  alreadySent: async (id) => {
    dispatch(authLoadPermissions());
    await dispatch(selfServiceDocumentMarkStatus(id, false, true, false));
    await dispatch(selfServiceDocumentUpdateResource(id));
    await dispatch(selfServiceDocumentLoadDocuments(253));
    await dispatch(todosLoadCollection());
  },

  clientSignAndApprove: async (documentId) => {
    await dispatch(selfServiceDocumentClientEmpCertApproval(documentId));
  },

  documentIncorrect: async (id) => {
    await dispatch(selfServiceDocumentMarkStatus(id, false, false, true));
    await dispatch(selfServiceDocumentUpdateResource(id));
    await dispatch(selfServiceDocumentClearDocument(id));
    await dispatch(todosLoadCollection());
    await dispatch(authLoadPermissions());
  },
  updateForm: (data, attributes) => dispatch(empcertApprovalHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmpcertClientApproval);
