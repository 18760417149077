import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Loader,
  Modal,
  InputCheckbox,
  InputWrapper,
  InputLabel,
} from 'fiducius-ui';

import { safeAccess, debounce } from '../../utils';

import { todosLoadCollection } from '../redux/operations';

const Actions = styled(CardBody)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1.5rem 0 2rem 0;
  & > button {
    margin-right: 5rem;
  }
  & > button:last-child {
    margin-right: 0;
  }
`;

const LoaderWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const TextLeft = styled(CardText)`
  text-align: left;
`;

class TodoComplete extends React.Component {
  static propTypes = {
    action: t.func.isRequired,
    id: t.number,
    isOpen: t.bool.isRequired,
    leftButtonAction: t.func,
    leftButtonText: t.string,
    onComplete: t.func,
    onLoad: t.func,
    reloadTodos: t.func.isRequired,
    request: t.object,
    rightButtonText: t.string,
    text: t.node.isRequired,
    title: t.string.isRequired,
    toggleModal: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showCheckbox: false }; // must be initialized for data handling
  }

  componentDidMount() {
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }

  componentDidUpdate(prevProps) {
    const { id, onComplete, request, reloadTodos, toggleModal } = this.props;

    if (safeAccess(request, 'key') === id) {
      if (prevProps.request.isLoading && request.hasFinished && !request.hasFailed) {
        toggleModal();
        reloadTodos();
        onComplete && onComplete();
      }
    }
  }

  handleChange = debounce((formState, id, attributes) => {
    this.setState({ ...this.state, showCheckbox: !this.state.showCheckbox });
    //this.props.updateForm({ ...this.props.form, ...formState }, [attributes]);
  }, 250);

  onConfirm = () => {
    this.setState({ ...this.state, showCheckbox: false });
    this.props.action();
  };

  render() {
    const {
      isOpen,
      leftButtonAction,
      leftButtonText,
      request,
      rightButtonText,
      text,
      title,
      toggleModal,
    } = this.props;

    return (
      <Modal isOpen={isOpen} fullWidth>
        <Card brand={safeAccess(request, 'hasFailed') ? 'danger' : 'primary'}>
          {safeAccess(request, 'hasFailed') && <CardHeader>Request Failed</CardHeader>}
          <CardBody>
            <CardTitle>{title}</CardTitle>
            {safeAccess(request, 'isLoading') ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <TextLeft>{text}</TextLeft>
            )}
          </CardBody>
          {safeAccess(request, 'isLoading') ? null : (
            <Actions>
              <Button invert brand="textTertiary" onClick={leftButtonAction || toggleModal}>
                {leftButtonText || 'Cancel'}
              </Button>
              <div>
                <InputWrapper>
                  <InputCheckbox
                    name="allowConfirmation"
                    label="I am ready to confirm that I have followed all of the above steps and am ready to continue."
                    onChange={(e) =>
                      this.handleChange(
                        { allowConfirmation: e.target.value },
                        'allowConfirmation',
                        'allowConfirmation'
                      )
                    }
                  ></InputCheckbox>
                </InputWrapper>
              </div>
              <br />
              <div>
                <InputWrapper>
                  <InputLabel>
                    <strong>Please Note:</strong> Once you click confirm, you will not be able to
                    return to this page.
                  </InputLabel>
                  <Button
                    brand="primary"
                    onClick={this.onConfirm}
                    disabled={!this.state.showCheckbox}
                  >
                    {rightButtonText || 'Confirm'}
                  </Button>
                </InputWrapper>
              </div>
            </Actions>
          )}
        </Card>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  reloadTodos: () => dispatch(todosLoadCollection()),
});

export default connect(null, mapDispatchToProps)(TodoComplete);
