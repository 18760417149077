import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import t from 'prop-types';
import { Button, InputMask, InputError, InputWrapper } from 'fiducius-ui';
import { convertIsoToSlash, safeAccess } from '../../../utils';

// export type ChangeEvent =
//   | FormEvent<HTMLInputElement>
//   | FormEvent<HTMLSelectElement>;

// type InputHandler = { onChange: (e: FormEvent<HTMLInputElement>) => void };
// type SelectHandler = { onChange: (e: FormEvent<HTMLSelectElement>) => void };

// type BaseInput = { hide?: (state: AppState) => boolean; help?: ReactNode };
// export type Text = BaseInput & { type: 'text'; value: string };
// export type Num = BaseInput & { type: 'number'; value: number };
// export type Filing = BaseInput & {
//   type: 'filing';
//   value: 'Filing Separate' | 'Filing Jointly';
// };
// export type Marital = BaseInput & {
//   type: 'marital';
//   value: 'Married' | 'Single';
// };
// export type Insurance = BaseInput & {
//   type: 'insurance';
//   value: 'HSA' | 'PPO' | 'HMO';
// };
// export type Coverage = BaseInput & {
//   type: 'coverage';
//   value: 'Employee Only' | 'Employee + Spouse' | 'Employee + Family';
// };

// export type Inputs = {
//   [key: string]: Text | Num | Marital | Insurance | Coverage;
// };

// type BaseProps = { name: string; hidden: boolean; withHelp?: boolean };
// type TextProps = Text & BaseProps & InputHandler;
// type NumberProps = Num & BaseProps & InputHandler;
// type FilingProps = Filing & BaseProps & SelectHandler;
// type MaritalProps = Marital & BaseProps & SelectHandler;
// type InsuranceProps = Insurance & BaseProps & SelectHandler;
// type CoverageProps = Coverage & BaseProps & SelectHandler;
// type InputProps =
//   | TextProps
//   | NumberProps
//   | FilingProps
//   | MaritalProps
//   | InsuranceProps
//   | CoverageProps;

const inputProps = {
  name: t.string.isRequired,
  onChange: t.func.isRequired,
  value: t.any,
};

const StyledButton = styled(Button)`
  margin-right: 1em;
`;

const DeleteButtonDiv = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 0px;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
`;

const DeductionInputDiv = styled.div`
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 15px;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
`;

const IDRTypeInput = (props) => (
  <InputWrapper>
    <select name={props.name} onChange={props.onChange} value={props.value}>
      <option value="IBR">IBR</option>
      <option value="ICR">ICR</option>
      <option value="PAYE">PAYE</option>
      <option value="REPAYE">REPAYE</option>
      <option value="SAVE">SAVE</option>
    </select>
  </InputWrapper>
);
IDRTypeInput.propTypes = inputProps;

const PlanTypeInput = (props) => (
  <InputWrapper>
    <select name={props.name} onChange={props.onChange} value={props.value}>
      <option value="NFP">NFP</option>
      <option value="FP20YR">FP - 20yr</option>
      <option value="FP25YR">FP - 25yr</option>
    </select>
  </InputWrapper>
);
PlanTypeInput.propTypes = inputProps;

const FilingInput = (props) => (
  <InputWrapper>
    <select name={props.name} onChange={props.onChange} value={props.value}>
      <option value="Filing Separate">Filing Separate</option>
      <option value="Filing Jointly">Filing Jointly</option>
    </select>
  </InputWrapper>
);
FilingInput.propTypes = inputProps;

const MaritalInput = (props) => (
  <InputWrapper>
    <select name={props.name} onChange={props.onChange} value={props.value}>
      <option value="Single">Single</option>
      <option value="Married">Married</option>
    </select>
  </InputWrapper>
);
MaritalInput.propTypes = inputProps;

const InsuranceInput = (props) => (
  <InputWrapper>
    <select name={props.name} onChange={props.onChange} value={props.value}>
      <option value="HSA">HSA</option>
      <option value="PPO">PPO</option>
      <option value="HMO">HMO</option>
    </select>
  </InputWrapper>
);
InsuranceInput.propTypes = inputProps;

const CoverageInput = (props) => (
  <InputWrapper>
    <select name={props.name} onChange={props.onChange} value={props.value}>
      <option value="Employee Only">Employee Only</option>
      <option value="Employee + Spouse">Employee + Spouse</option>
      <option value="Employee + Family">Employee + Family</option>
    </select>
  </InputWrapper>
);
CoverageInput.propTypes = inputProps;

const DeductionInput = (props) => (
  <>
    <div className="row">
      <DeductionInputDiv>
        <InputWrapper>
          <input
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            min="0"
            type={props.type}
            onFocus={(e) => e.target.select()}
          />
        </InputWrapper>
      </DeductionInputDiv>
      <DeleteButtonDiv>
        {props.showDeleteButton && (
          <StyledButton brand="primary" name={props.name} onClick={props.onClick}>
            Delete
          </StyledButton>
        )}
      </DeleteButtonDiv>
    </div>
  </>
);
DeductionInput.propTypes = inputProps;

const HtmlInput = ({ name, onChange, value, type, min = 0 }) => {
  return (
    <InputWrapper>
      <input
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        type={type}
        onFocus={(e) => e.target.select()}
      />
    </InputWrapper>
  );
};
HtmlInput.propTypes = { ...inputProps };

const HtmlInputMaskDate = ({ name, onChange, value, errors = {}, type, min = 0 }) => {
  return (
    <>
      <InputWrapper error={!!errors.projectedRetirementDate}>
        <InputError>{safeAccess(errors, 'projectedRetirementDate.detail')}</InputError>
        <InputMask
          value={convertIsoToSlash(value)}
          mask="##/##/####"
          name={name}
          placeholder="MM/DD/YYYY"
          onChange={onChange}
        />
      </InputWrapper>
    </>
  );
};
HtmlInputMaskDate.propTypes = { ...inputProps };

const Input = ({ withHelp, help, ...props }) => {
  if (props.hidden) return null;
  let delegate = null;
  if (props.type === 'idrtype') delegate = <IDRTypeInput {...props} />;
  if (props.type === 'plantype') delegate = <PlanTypeInput {...props} />;
  if (props.customInput === 'deduction') delegate = <DeductionInput {...props} />;
  else if (props.type === 'number') delegate = <HtmlInput {...props} />;
  if (props.type === 'filing') delegate = <FilingInput {...props} />;
  if (props.type === 'marital') delegate = <MaritalInput {...props} />;
  if (props.type === 'insurance') delegate = <InsuranceInput {...props} />;
  if (props.type === 'coverage') delegate = <CoverageInput {...props} />;
  if (props.type === 'date') delegate = <HtmlInputMaskDate {...props} />;

  if (!delegate) return null;
  const hasLabel = props.label;
  return (
    <>
      {hasLabel ? (
        <label htmlFor={props.label}>{props.label}</label>
      ) : (
        <label htmlFor={props.name}>{props.name}</label>
      )}
      {withHelp && help && <p className="polaris-help">{help}</p>}
      {delegate}
    </>
  );
};
Input.propTypes = {
  ...inputProps,
  withHelp: t.bool,
  help: t.string,
};

const mapStateToProps = (state) => {
  const { errors } = state.planTool;
  return {
    errors: errors,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
