import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  DashboardSection,
  Header2,
  Header4,
  Link,
} from 'fiducius-ui';

const MyInsurance = () => (
  <DashboardSection title="My Student Loan Disability Insurance">
    <Header2>
      Keep your credit intact and your payments on track if you get injured or can't work
    </Header2>
    <div className="row">
      <div className="col-12 col-sm-10 col-lg-8">
        <Card brand="primary">
          <CardHeader>Plan Information</CardHeader>
          <CardBody>
            <CardText>
              <Header4>
                <strong>Provider</strong>: Ameritas
              </Header4>
              <Header4>
                <strong>Product</strong>: DInamic Fundamental®
              </Header4>
              <p>
                The{' '}
                <Link
                  external
                  icon
                  to="https://www.ameritas.com/wps/wcm/connect/corp/individual/products/disability-income/dinamic-fundamental"
                >
                  Ameritas DInamic Fundamental®
                </Link>{' '}
                plan offers simple, affordable, and attainable disability insurance cover to assist
                with student loan debt. This plan is ideal for middle income earners and homemakers
                who have not protected their incomes and student loan debt from risk of disability.
              </p>
              <p>
                <strong>The plan provides significant value to those who:</strong>
              </p>
              <ul>
                <li>Earn $25,000-$100,000 annual income.</li>
                <li>$50,000 Guaranteed Standard Issue (no underwriting necessary).</li>
                <li>Work at least 30 hours per week.</li>
                <li>
                  Homemakers with little to no income (working less than 30 hours per week) outside
                  the home.
                </li>
              </ul>
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  </DashboardSection>
);

export default MyInsurance;
