import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <ul>
      <li>
        Confirm that you have filed your most recent tax return as outlined in your Financial
        Wellness Plan to maximize the benefit of this program.
      </li>
      <li>
        If your marital status has changed since speaking with your advisor, please visit the
        Contact Us button on the left of your portal to reach out to us.
        {/*contact us at{' '}
        <a href="tel:+15136455400">(513) 645-5400</a> option: 2.*/}
      </li>
      <li>
        Haven't filed your taxes yet? Another benefit of being a client of ours is you (and your
        spouse) can utilize our national partner, TurboTax and receive a discounted rate to file.
        Visit <a href="http://www.jdoqocy.com/click-8973232-13149824">TurboTax</a> to start filing.
      </li>
    </ul>

    <strong>
      Do not confirm this step is finished until you have filed your taxes in accordance with your
      Financial Wellness Plan
    </strong>
  </>
);

const TaxesComplete = withRouter((props) => (
  <TodoComplete {...props} text={<TodoText />} title="Tax filing confirmation" />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxesComplete);
