import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Button,
  Header5,
  InputWrapper,
} from 'fiducius-ui';

import { safeAccess, mergeRequestStatuses } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import DocsNeededForRSUpload from '../components/docs-upload-needed';
import ForgivenessProgress from '../components/forgiveness-progress';

import { renewalResultsLoadResource } from '../../../renewal-results';
import { fulfillmentLoadCollection } from '../../../fulfillment';

class IDRRSFS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    docs: t.object,
    loadData: t.func.isRequired,
    loadDocs: t.func,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '' };
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
    if (Object.entries(this.props.docs).length < 1) {
      this.props.loadDocs();
    }
  }

  redirectForDocumentUploadOrSign = () => {
    this.setState({ ...this.state, redirectURI: '/documents' });
  };

  render() {
    const { data, request, docs } = this.props;

    let IDRAvailable = false;

    Object.entries(docs).forEach(([key, value]) => {
      let newArray = Object.entries(value);
      //doc type [3] - is doc signed? [5]
      if (newArray[3][1] === 11 && newArray[5][1] === false) {
        IDRAvailable = true;
      }
    });
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      return (
        <div>
          <ForgivenessProgress />
          {data.idrDelivered === false && (
            <DashboardSection title="Income-Driven Repayment Application Generation">
              <Card>
                <AsyncLoader request={request}>
                  <Card>
                    <CardBody>
                      <CardTitle>Income-Driven Repayment Application Generation</CardTitle>
                      <CardText>
                        <Header5>
                          <p>
                            We are reviewing your account and your Income-Driven Repayment (IDR)
                            application will be shared in the next 1 to 3 business days.
                          </p>
                          <p>
                            <DocsNeededForRSUpload documentButton={true} />
                          </p>
                        </Header5>
                      </CardText>
                    </CardBody>
                  </Card>
                </AsyncLoader>
              </Card>
            </DashboardSection>
          )}
          {data.idrDelivered && IDRAvailable === true && (
            <DashboardSection title="Income-Driven Repayment Application Signature Required">
              <AsyncLoader request={request}>
                <Card>
                  <CardBody>
                    <CardTitle>Income-Driven Repayment Application Signature Required</CardTitle>
                    <CardText>
                      <Header5>
                        <p>
                          The next step of your renewal process is to sign your Income-Driven
                          Repayment (IDR) application. Please go to the “My Documents” page to sign
                          your IDR application.
                        </p>
                        <p>
                          <DocsNeededForRSUpload documentButton={false} />
                        </p>
                      </Header5>
                    </CardText>
                  </CardBody>
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        <Button brand="primary" onClick={this.redirectForDocumentUploadOrSign}>
                          {'Sign Application'}
                        </Button>
                      </InputWrapper>
                    </div>
                  </div>
                </Card>
              </AsyncLoader>
            </DashboardSection>
          )}
          {data.idrDelivered && IDRAvailable === false && (
            <DashboardSection title="Income-Driven Repayment Application Signature Required">
              <Card>
                <CardBody>
                  <CardTitle>Income-Driven Repayment Application Signature Received</CardTitle>
                  <CardText>
                    <Header5>
                      <p>
                        Your document has been signed, we will review your document and reach out if
                        we need anything additional.
                      </p>
                      <p>
                        <DocsNeededForRSUpload documentButton={false} />
                      </p>
                    </Header5>
                  </CardText>
                </CardBody>
              </Card>
            </DashboardSection>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    docs: state.fulfillment.cache,
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: mergeRequestStatuses([
      state.renewalResults.requests.loadResource,
      state.fulfillment.requests.loadCollection,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(renewalResultsLoadResource(id)),
  loadDocs: () => {
    dispatch(fulfillmentLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(IDRRSFS));
