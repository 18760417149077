import React from 'react';
import t from 'prop-types';
import { Form, Header2 } from 'fiducius-ui';

import { useScrollToTopOnMount } from '../../../utils';

const TabReview = ({ data = {}, errors = {}, handleChange, id, brand }) => {
  useScrollToTopOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Review Your Responses</Header2>
      <div className="row">
        <div className="col-12">
          {brand === 'success' && (
            <>
              <p>
                Thank you for completing the questionnaire. You can use the page links to the left
                to go back and review your responses.
              </p>
              <p>Please click Submit to confirm your responses and continue.</p>
            </>
          )}
          {brand === null && (
            <>
              <p>You have not yet completed all the questions.</p>
              <p>
                Please use the navigation items to the left to go back and finish the questionnaire.
              </p>
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

TabReview.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
  brand: t.string,
};

export default TabReview;
