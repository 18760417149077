import { resourceOperationsGenerator } from '../../../utils';

import actions from './actions';
import { QuestionnaireBtsValidator } from './validators';

const questionnaireBtsValidator = new QuestionnaireBtsValidator();

const endpointHandler = {
  endpoint: '/questionnaire-bts',
  customResponseHandler: null,
  resourceType: 'questionnaire-bts',
};

const operations = resourceOperationsGenerator(
  'questionnaireBts',
  actions,
  null,
  questionnaireBtsValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  questionnaireBtsClearForm,
  questionnaireBtsCopyResourceToForm,
  questionnaireBtsHandleFormChange,
  questionnaireBtsCreateResource,
  questionnaireBtsValidateAttributes,
  questionnaireBtsLoadCollection,
  questionnaireBtsLoadResource,
  questionnaireBtsUpdateResource,
} = operations;

export {
  questionnaireBtsClearForm,
  questionnaireBtsCopyResourceToForm,
  questionnaireBtsHandleFormChange,
  questionnaireBtsCreateResource,
  questionnaireBtsValidateAttributes,
  questionnaireBtsLoadCollection,
  questionnaireBtsLoadResource,
  questionnaireBtsUpdateResource,
};

export default operations;
