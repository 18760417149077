import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';

import { Button, Card, CardBody, CardTitle, CardText, CardFooter } from 'fiducius-ui';

import { getToken } from '../../auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';

import {
  tuitionInstitutionClearForm,
  tuitionInstitutionCopyResourceToForm,
  tuitionInstitutionHandleFormChange,
  tuitionInstitutionLoadResource,
  tuitionInstitutionLoadCollection,
  institutionDeleteResource,
  institutionUpdateResource,
} from '../redux/operations';

import { default as TuitionInstitutionForm } from './tuition-institution-form';

import { todosLoadCollection } from '../../todos';

import { debounce, mergeRequestStatuses, getApi } from '../../utils';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const DivEnd = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 1em;
`;

class TuitionInstitutionRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    data: t.object,
    form: t.object,
    errors: t.object,
    getRequest: t.object,
    request: t.object,
    loadInstitution: t.func.isRequired,
    clearInstitutionForm: t.func.isRequired,
    handleInstitutionForm: t.func.isRequired,
    updateInstitution: t.func.isRequired,
    handleDeleteInstitution: t.func.isRequired,
    hydrateInstitutionForm: t.func.isRequired,
    token: t.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      isDeleting: false,
      formId: null,
      allowedDegreeTypes: {},
      allowedCertifications: {},
    };
  }

  componentDidMount() {
    this.getDegreeTypeDropDownOptions();
    this.getCertificationDropDownOptions();
  }

  componentDidUpdate(prevProps) {}

  getDegreeTypeDropDownOptions = () => {
    getApi(this.props.token, '/drop-down-list/DegreeType__A').then((data) => {
      let degrees = {};
      Object.keys(data).forEach((a) => {
        degrees[data[a].id] = data[a].description;
      });
      this.setState({ ...this.state, allowedDegreeTypes: degrees });
    });
  };

  getCertificationDropDownOptions = () => {
    getApi(this.props.token, '/drop-down-list/Certification__A').then((data) => {
      let certifications = {};
      Object.keys(data).forEach((a) => {
        certifications[data[a].description] = data[a].description;
      });
      this.setState({ ...this.state, allowedCertifications: certifications });
    });
  };

  editInstitution = () => {
    this.props.hydrateInstitutionForm(this.props.institutionId);
    this.setState({
      formId: this.props.institutionId,
      showForm: true,
      isDeleting: false,
    });
  };
  deleteInstitution = () => {
    this.props.hydrateInstitutionForm(this.props.institutionId);
    this.setState({
      formId: this.props.institutionId,
      showForm: true,
      isDeleting: true,
    });
  };

  updateInstitutionDropdown = debounce((institutionSelection) => {
    let formData = this.props.form;
    formData['institutionName'] = institutionSelection['name'];
    formData['dapipId'] = institutionSelection['id'];
    this.props.handleInstitutionForm({ ...formData }, ['institutionName', 'dapipId']);
  }, 250);

  onUpdateInstitution = () => this.props.updateInstitution(this.props.form.id);

  onDeleteInstitution = () => {
    this.props.handleDeleteInstitution(this.props.form.id);
  };

  onChangeInstitution = debounce((tuitionInstitution, id, attributes) => {
    this.props.handleInstitutionForm({ ...this.props.form, ...tuitionInstitution }, [attributes]);
  }, 250);

  closeForm = () => {
    this.props.clearInstitutionForm();
    this.setState({
      ...this.state,
      showForm: false,
      formId: null,
    });
  };

  getDegreeText = (degreeType) => {
    let retVal = '';
    if (degreeType) {
      if (degreeType === 'CERT') {
        retVal = 'Certification';
      } else if (degreeType === 'ASSOC') {
        retVal = 'Associates';
      } else if (degreeType === 'BA') {
        retVal = 'Bachelors';
      } else if (degreeType === 'MA') {
        retVal = 'Masters';
      } else if (degreeType === 'PHD') {
        retVal = 'Doctorate';
      }
    }
    return retVal;
  };

  render() {
    const { data = {}, institutionId, request, errors, form = {}, getRequest } = this.props;
    const { showForm, formId, isDeleting, allowedDegreeTypes } = this.state;
    const institution = data[institutionId];
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row">
                <div className="col">{institution.institutionName}</div>
                <DivEnd className="col-5">
                  <StyledButton
                    invert
                    brand="secondary"
                    onClick={() => this.editInstitution()}
                    disabled={!institution.editable}
                    title={
                      !institution.editable
                        ? 'A term has been added for this institution, so the institution cannot be edited.'
                        : 'Edit Institution'
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                  </StyledButton>
                  <StyledButton
                    invert
                    brand="danger"
                    onClick={() => this.deleteInstitution()}
                    disabled={!institution.deletable}
                    title={
                      !institution.deletable
                        ? 'A term has been added for this institution, so the institution cannot be deleted.'
                        : 'Delete Institution'
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </StyledButton>
                </DivEnd>
              </div>
            </CardTitle>
            <CardText>
              <dl>
                <dt>Degree Type</dt>
                <dd>{this.getDegreeText(institution.degreeType)}</dd>
              </dl>
            </CardText>
          </CardBody>
          {false && (
            <FlexFooter>
              <Button
                invert
                brand="secondary"
                onClick={() => this.editInstitution()}
                disabled={!institution.editable}
                title={
                  !institution.editable
                    ? 'You cannot edit an institution that has a current term associated with it.'
                    : ''
                }
              >
                Edit
              </Button>
              <Button
                invert
                brand="danger"
                onClick={() => this.deleteInstitution()}
                disabled={!institution.deletable}
                title={
                  !institution.deletable
                    ? 'You cannot delete an institution that has term information associated with it.'
                    : ''
                }
              >
                Delete
              </Button>
            </FlexFooter>
          )}
        </Card>
        {showForm && (
          <TuitionInstitutionForm
            action={formId ? (isDeleting ? 'Delete' : 'Update') : 'Add'}
            closeForm={this.closeForm}
            data={form}
            allowedDegreeTypes={allowedDegreeTypes}
            errors={errors}
            isLoading={request.isLoading}
            getResource={getRequest}
            isOpen={showForm}
            onChange={this.onChangeInstitution}
            onUpdateDropdown={this.updateInstitutionDropdown}
            onSubmit={
              formId
                ? isDeleting
                  ? this.onDeleteInstitution
                  : this.onUpdateInstitution
                : this.onCreateInstitution
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests, errors, form } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    token: getToken(state),
    getRequest: requests.loadResource,
    errors: errors,
    form: form,
    request: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),

  clearInstitutionForm: () => dispatch(tuitionInstitutionClearForm()),
  handleInstitutionForm: (tuitionInstitution, attributes) =>
    dispatch(tuitionInstitutionHandleFormChange(tuitionInstitution, attributes)),
  hydrateInstitutionForm: async (id) => {
    await dispatch(tuitionInstitutionLoadResource(id));
    await dispatch(tuitionInstitutionCopyResourceToForm(id));
  },
  updateInstitution: async (id) => {
    await dispatch(institutionUpdateResource(id));
    await dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
    dispatch(tuitionInstitutionClearForm());
  },
  handleDeleteInstitution: async (id) => {
    await dispatch(institutionDeleteResource(id));
    await dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
    dispatch(tuitionInstitutionClearForm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionRow);
