import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { BrandColor, CardBody, CardText, CardTitle, Form, Header2, InputRadio } from 'fiducius-ui';

import { useScrollToTopOnMount } from '../../../utils';

import PlanCard from '../styles/plan-card';
import AdjustedHeader from '../styles/adjusted-header';

const FlexTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const selfServicePlan = {
  //message:
  //  'Our Do It Yourself Toolkit is recommended for those that are comfortable and confident taking the wheel into their own hands. This toolkit includes the following:',
  message: 'The expertise and support needed to get all your qualifying payments, including:',
  points: [
    'Step-by-step instructions ensure you meet all program requirements',
    'Accurate documentation to provide all mandatory information',
    'Dashboard to monitor and manage your progress',
    'Experienced support team you can trust if you have questions',
  ],
  //title: 'Do It Yourself Toolkit',
  title: 'Confirm Your LWO Eligibility',
  type: 'SLWO',
};

const getPlan = (type, plan, disabled, cost, companyPaid, originationPaid, partnershipName) => {
  const planLabel = (
    <PlanCard>
      <CardBody>
        <CardTitle>
          <FlexTitle>
            <span>{plan.title}</span>
            <BrandColor brand="success">
              {companyPaid
                ? 'Paid for by ' + partnershipName
                : originationPaid
                ? '$0.00'
                : '$' + cost}{' '}
            </BrandColor>
          </FlexTitle>
        </CardTitle>
        <CardText>
          <p>{plan.message}</p>
          <ul>
            {plan.points.map((p, i) => (
              <li key={plan.title + i.toString()}>{p}</li>
            ))}
          </ul>
        </CardText>
      </CardBody>
    </PlanCard>
  );

  return (
    <InputRadio
      name="planType"
      value={plan.type}
      label={planLabel}
      defaultChecked={true}
      disabled={disabled}
    />
  );
};

const TabPlan = ({ data = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  return (
    <>
      <AdjustedHeader>Your Assure&#8480;</AdjustedHeader>
      <Form id={id} handleChange={handleChange}>
        {getPlan(
          data.planType,
          selfServicePlan,
          true,
          data.selfServiceCost,
          data.companyPaid,
          data.originationPaid,
          data.partnershipName
        )}
      </Form>
    </>
  );
};

TabPlan.propTypes = {
  data: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabPlan;
