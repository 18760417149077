import actions from './actions';
import { todoMap } from './mapper';
import { resourceOperationsGenerator } from '../../utils';

const responseHandler = (data) => {
  return data.map((resource) => {
    const { id, itemDescription } = resource;
    let todo = todoMap[id];
    todo.fullText = itemDescription;
    todo.original = resource;
    return todo;
  });
};

const endpointHandler = {
  customResponseHandler: responseHandler,
  endpoint: '/action-item',
  resourceType: 'action-item',
};

const operations = resourceOperationsGenerator(
  'todos',
  actions,
  null,
  null,
  endpointHandler,
  null,
  null,
  endpointHandler,
  null
);

const { todosDisableValidation } = actions;
const { todosLoadCollection, todosUpdateResource } = operations;

const todosCopyResourceToForm = (id) => async (dispatch, getState) => {
  try {
    dispatch(actions.todosCopyResourceToFormRequest());
    const data = getState().todos.cache[id].original;
    dispatch(actions.todosHandleFormChange(data));
    dispatch(actions.todosCopyResourceToFormResponse());
    dispatch(actions.todosCopyResourceToFormSuccess());
  } catch (error) {
    dispatch(operations.todosHandleError(error));
  }
};

const todosSendDenied = (id) => async (dispatch, getState) => {
  try {
    const data = getState().todos.cache[id];
    dispatch(todosUpdateResource(id));
  } catch (error) {
    dispatch(operations.todosHandleError(error));
  }
};

const todosSendUpdate = (id) => async (dispatch, getState) => {
  try {
    const data = getState().todos.cache[id];
    await dispatch(todosUpdateResource(id));
  } catch (error) {
    await dispatch(operations.todosHandleError(error));
  }
};

export {
  todosCopyResourceToForm,
  todosDisableValidation,
  todosLoadCollection,
  todosUpdateResource,
  todosSendDenied,
  todosSendUpdate,
};

export default operations;
