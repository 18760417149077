import React from 'react';
import { Card, CardBody, CardText, CardTitle, Link } from 'fiducius-ui';

const IdentityEnrolled = () => (
  <Card brand="success">
    <CardBody>
      <CardTitle>Identity Protection</CardTitle>
      <CardText>
        You're covered! <Link to="/benefits/identity">Learn more</Link> about your protection plan.
      </CardText>
    </CardBody>
  </Card>
);

export default IdentityEnrolled;
