import React from 'react';
import t from 'prop-types';
import { Form, InputLabel, InputWrapper, InputGroup, InputAddon, InputText } from 'fiducius-ui';

class PretaxExistingItems extends React.Component {
  static propTypes = {
    data: t.object,
    handleChange: t.func,
  };

  constructor(props) {
    super(props);
    this.retirementVal = React.createRef();

    this.state = {
      deductions: [],
      deductionAmount: 0,
    };

    this.handleDeductionAmount = this.handleDeductionAmount.bind(this);
  }

  handleDeductionAmount(e, index) {
    //alert(this.state.deductions)
    let newArray = this.state.deductions; //we don't want to mutate state directly.
    newArray.splice(index, 1, e.target.value);
    this.setState({ deductions: newArray });
  }

  componentDidMount() {}

  render() {
    const { data = {}, handleChange } = this.props;

    let newArray = [];
    let deductionType = [];
    let deductionAmount = [];

    data.deductions !== undefined && (newArray = data.deductions.flatten());

    for (let [key, value] of Object.entries(newArray)) {
      for (let [key, value] of Object.entries(value)) {
        if (key === 'deductionName') {
          deductionType.push(value);
        }
        if (key === 'deductionAmount') {
          deductionAmount.push(value);
          this.state.deductions.push(value);
          this.state.deductionAmount += value * 26;
        }
      }
    }
    /*for(let [key, value] of Object.entries(deductionType)) {
      alert(`${key}: ${value}`);
    }

    for(let [key, value] of Object.entries(deductionAmount)) {
      alert(`${key}: ${value}`);
    }*/
    return (
      <Form handleChange={handleChange}>
        {deductionType.map((deduction, index) => {
          return (
            <div key={index}>
              <InputWrapper>
                <InputLabel htmlFor="income">{deductionType[index]}</InputLabel>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputText
                    id="existingDeduction"
                    defaultValue={(deductionAmount[index] * data.payPeriods).toFixed(2)}
                    onChange={(e) => this.handleDeductionAmount(e, index)}
                    name={'existing' + (index + 1).toString()}
                    type="existing"
                  />
                </InputGroup>
              </InputWrapper>
            </div>
          );
        })}
      </Form>
    );
  }
}

export default PretaxExistingItems;
