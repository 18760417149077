import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Header2, Button } from 'fiducius-ui';

import { stringIsNullOrEmpty } from '../../../utils';

import { withPermissions } from '../../../routing';

const TextBlock = styled.div`
  color: ${(p) =>
    p.theme.themeName === 'greenlight' ? p.theme.textPrimary : p.theme.textTertiary};
`;

const Centered = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class TabValidate extends React.Component {
  static propTypes = {
    nsldsUploaded: t.bool.isRequired,
    inScreening: t.bool.isRequired,
    updateNsldsUploaded: t.func.isRequired,
    uploadDifferentNslds: t.func.isRequired,
    files: t.object,
    nslds: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  clearUploadedNslds = () => {
    this.props.updateNsldsUploaded(false);
    this.props.uploadDifferentNslds();
  };

  hasErrors = () => {
    const { nslds } = this.props;
    return (
      stringIsNullOrEmpty(nslds.fileRequestDate) ||
      this.newFieldsMissing() ||
      nslds.loans === undefined ||
      nslds.loans.length === 0
    );
  };

  newFieldsMissing = () => {
    const { nslds } = this.props;
    return (
      stringIsNullOrEmpty(nslds.undergraduateAggregateSubsidizedTotal) ||
      stringIsNullOrEmpty(nslds.undergraduateAggregateUnsubsidizedTotal) ||
      stringIsNullOrEmpty(nslds.undergraduateAggregateCombinedTotal) ||
      stringIsNullOrEmpty(nslds.graduateAggregateSubsidizedTotal) ||
      stringIsNullOrEmpty(nslds.graduateAggregateUnsubsidizedTotal) ||
      stringIsNullOrEmpty(nslds.graduateAggregateCombinedTotal) ||
      stringIsNullOrEmpty(nslds.aggregateSubsidizedTotal) ||
      stringIsNullOrEmpty(nslds.aggregateUnsubsidizedTotal) ||
      stringIsNullOrEmpty(nslds.aggregateCombinedTotal)
    );
  };

  errorContent = () => {
    const { nslds, inScreening } = this.props;
    return (
      <>
        {stringIsNullOrEmpty(nslds.fileRequestDate) && !inScreening && (
          <p>
            The file you uploaded is missing required information. Please select the "Upload
            Different NSLDS" button to upload a new file.
          </p>
        )}
        {stringIsNullOrEmpty(nslds.fileRequestDate) && inScreening && (
          <p>
            The file you uploaded is missing required information. Please click the Back button to
            upload a new file.
          </p>
        )}
        {!stringIsNullOrEmpty(nslds.fileRequestDate) && this.newFieldsMissing() && (
          <p>
            This is not the newest version of the NSLDS text file. Your document was not uploaded.
            Please obtain the newest version of the NSLDS, and attempt to upload again.
          </p>
        )}
        {(nslds.loans === undefined || nslds.loans.length === 0) && !inScreening && (
          <p>
            The file you uploaded does not contain any loan information. Please select the "Upload
            Different NSLDS" button to upload a new file.
          </p>
        )}
        {(nslds.loans === undefined || nslds.loans.length === 0) && inScreening && (
          <p>
            The file you uploaded does not contain any loan information. Please click the Back
            button to upload a new file.
          </p>
        )}
      </>
    );
  };

  successContent = () => {
    return (
      <>
        <p>
          The file is an accurate NSLDS and contains the necessary information to proceed. Please
          select the "Finish" button to finalize your upload.
        </p>
      </>
    );
  };

  fileUploaded = () => {
    const { inScreening } = this.props;
    return (
      <>
        <p>Thank you for uploading your NSLDS.</p>
        {this.hasErrors() && this.errorContent()}
        {!this.hasErrors() && this.successContent()}
        {!inScreening && (
          <Centered>
            <StyledButton brand="primary" onClick={() => this.clearUploadedNslds()}>
              Upload Different NSLDS
            </StyledButton>
          </Centered>
        )}
      </>
    );
  };

  fileNotUploaded = () => {
    return <p>You need to upload a NSLDS to complete this step.</p>;
  };

  render() {
    const { nsldsUploaded } = this.props;

    return (
      <>
        <Header2>Review</Header2>
        <TextBlock>
          {nsldsUploaded && this.fileUploaded()}
          {!nsldsUploaded && this.fileNotUploaded()}
        </TextBlock>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const files = state.documents.cache;
  const nslds = state.nslds.cache;

  return { files, nslds };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabValidate));
