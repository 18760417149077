import { postApi, resourceOperationsGenerator, kebabCase } from '../../../utils';
import { documentsCreateResource } from '../../../documents';

import actions from './actions';
import { getToken } from '../../../auth';

const endpointHandler = {
  endpoint: '/nslds',
  customResponseHandler: null,
  resourceType: 'nslds',
};

const operations = resourceOperationsGenerator(
  'nslds',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  nsldsClearForm,
  nsldsCopyResourceToForm,
  nsldsHandleFormChange,
  nsldsCreateResource,
  nsldsValidateAttributes,
  nsldsHandleCacheChange,
} = operations;

const nsldsSetFormData = (nslds) => async (dispatch, getState) => {
  const state = getState();
  let { cache } = state.nslds;
  cache = nslds;
  await dispatch(nsldsHandleCacheChange(cache));
};

const nsldsUploadData = (inScreening) => async (dispatch, getState) => {
  const state = getState();
  const { documents, nslds } = state;

  const nsldsFile = Object.keys(documents.cache).filter((a) => a.fileCode === 'LNDT');
  const nsldsData = nslds.cache;
  nsldsData.fileId = await dispatch(documentsCreateResource(nsldsFile.id));
  nsldsData.fromScreening = inScreening;
  await dispatch(nsldsPostData(nsldsData));
};

const nsldsPostData = (nsldsData) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(actions.nsldsCreateResourceRequest(null));

    const postData = fixDataForPost(nsldsData);
    await postApi(getToken(state), '/nslds', postData);

    dispatch(actions.nsldsCreateResourceResponse(null));
    dispatch(actions.nsldsCreateResourceSuccess(null));
  } catch (error) {
    dispatch(operations.nsldsHandleError(error));
    dispatch(actions.nsldsCreateResourceFailure(error, null));
  }
};

const fixDataForPost = (postData) => {
  let data = Object.keys(postData).reduce(
    (kebabs, key) => ({
      ...kebabs,
      [kebabCase(key).replace('address-', 'address')]: postData[key],
    }),
    {}
  );

  data = fixSubdata(data);

  return {
    data: {
      type: 'nslds',
      attributes: data,
    },
  };
};

const fixSubdata = (data) => {
  let arrayKeys = Object.keys(data).filter((a) => Array.isArray(data[a]));

  for (let i = 0; i < arrayKeys.length; i++) {
    if (data[arrayKeys[i]] !== undefined && data[arrayKeys[i]].length > 0) {
      data[arrayKeys[i]] = fixArray(data[arrayKeys[i]]);
    }
  }
  return data;
};

const fixArray = (arrayData) => {
  let newArray = [];
  for (let i = 0; i < arrayData.length; i++) {
    let newItem = Object.keys(arrayData[i]).reduce(
      (kebabs, key) => ({
        ...kebabs,
        [kebabCase(key).replace('address-', 'address')]: arrayData[i][key],
      }),
      {}
    );

    newItem = fixSubdata(newItem);
    newArray.push(newItem);
  }
  return newArray;
};

export {
  nsldsClearForm,
  nsldsCopyResourceToForm,
  nsldsHandleFormChange,
  nsldsCreateResource,
  nsldsValidateAttributes,
  nsldsSetFormData,
  nsldsUploadData,
};

export default operations;
