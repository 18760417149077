import React from 'react';
import t from 'prop-types';

import styled /*, { css } */ from 'styled-components';
/*import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faDesktop } from '@fortawesome/pro-light-svg-icons/faDesktop';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie';*/
import { Header4 } from 'fiducius-ui';
import { safeAccess } from '../../utils';
import { connect } from 'react-redux';

//import { ContrastSubtitle } from '../../routing';

/*const center = css`
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: center;
`;*/

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

/*const CenteredIcons = styled.div`
  ${center}
  justify-content: space-evenly;
  margin: 2rem 0;
  color: ${(p) => p.theme.white};
  & > div:not(:last-child)::after {
    border-bottom: 7rem solid transparent;
    border-left: 8px solid #f8f9fa;
    border-right: 7rem solid transparent;
    border-top: 7rem solid transparent;
    content: '';
    height: 100%;
    position: absolute;
    right: -7rem;
    top: 0;
    width: 0;
  }
`;

const OnboardIcon = styled(FontAwesomeIcon)`
  margin-bottom: 1rem;
`;

const OnboardIconSet = styled.div`
  ${center}
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.25em;
  padding-right: 1em;
  position: relative;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
`;

const StyledLede = styled(Lede)`
  color: ${(p) => p.theme.white};
  text-align: center;
  width: 100%;
  word-wrap: break-word;
`;

const StyledHeader3 = styled(Header3)`
  //color: ${(p) => p.theme.white};
`;*/

const StyledHeader4 = styled(Header4)`
  //color: ${(p) => p.theme.white};
`;

const StandardOnboardingGraphic = ({ employerName, extraOnboard, ...props }) => (
  <Wrapper>
    {employerName && (
      <StyledHeader4>
        This is MyFiducius, your personal student loan and education assistance benefits manager,
        brought to you by {employerName}.
      </StyledHeader4>
    )}
    {!employerName && (
      <StyledHeader4>
        This is MyFiducius, your personal student loan and education assistance benefits manager,
        brought to you by your employer.
      </StyledHeader4>
    )}
    {extraOnboard && (
      <StyledHeader4>
        We've already created an account for you, so simply enter your email address from the
        message you received for this benefit and create your password.
      </StyledHeader4>
    )}
  </Wrapper>
);

StandardOnboardingGraphic.propTypes = {
  employerName: t.string,
  extraOnboard: t.bool,
};

const mapStateToProps = (state) => ({
  employerName:
    safeAccess(state, 'auth.cache.permissions.displayName', undefined) ||
    safeAccess(state, 'auth.cache.partnerLocations.displayName', undefined),
});
export default connect(mapStateToProps)(StandardOnboardingGraphic);
