import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardText, Header2, InputWrapper } from 'fiducius-ui';
import { FlexEnd } from '../../root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as faXIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';

const empcertSectionSix = ({ closeModal }) => {
  const LeftText = styled.div`
    text-align: left;
  `;

  return (
    <Card>
      <FlexEnd>
        <InputWrapper>
          <Button brand="lowContrast" onClick={closeModal}>
            <FontAwesomeIcon icon={faXIcon} />
          </Button>
        </InputWrapper>
      </FlexEnd>
      <CardBody>
        <CardText>
          <Header2>
            <strong>Qualifying Employment Definitions</strong>
          </Header2>
          <LeftText>
            <p>
              A <b>Qualifying employer</b> includes the government, a not-for-profit organization
              that is tax-exempt under Section 501(c)(3) of the Internal Revenue Code, or a private
              not-for-profit organization that provides certain public services. Service in an
              AmeriCorps or Peace Corps position is also qualifying employment.
            </p>

            <p>
              <b>Government</b> includes a Federal, State, local or Tribal government organization,
              agency or entity; a public child or family service agency; or a Tribal college or
              university.
            </p>

            <p>
              A <b>private not-for-profit organization</b> is an organization that is not organized
              for profit, and provides at least one of the following public services: <b>(1)</b>{' '}
              emergency management, <b>(2)</b> military service, <b>(3)</b> public safety,{' '}
              <b>(4)</b> law enforcement, <b>(5)</b> public interest legal services, <b>(6)</b>{' '}
              early childhood education, <b>(7)</b> public service for individuals with disabilities
              and the elderly, <b>(8)</b> public health, <b>(9)</b> public education, <b>(10)</b>{' '}
              public library services, <b>(11)</b> school library services, or <b>(12)</b> other
              school-based services.
            </p>

            <p>
              <b>AmeriCorps position</b> means a position approved by the Corporation for National
              and Community Service under Section 123 of the National and Community Service Act of
              1990 (42 U.S.C. 12573).
            </p>

            <p>
              <b>Peace Corps position</b> means a full-time assignment under the Peace Corps Act as
              provided for under 22 U.S.C. 2504.
            </p>

            <p>
              <b>An employee</b> means an individual who is hired and paid by the qualifying
              employer.
            </p>

            <p>
              <b>Full-time</b> means working for one or more qualifying employers for the greater
              of: <b>(1)</b> an annual average of at least 30 hours per week or, for a contractual
              or employment period of at least 8 months, an average of 30 hours per week; or{' '}
              <b>(2)</b> unless the qualifying employment is with two or more employers, the number
              of hours the employer considers full time.
            </p>

            <p>
              An <b>authorized official</b> is an official of a qualifying employer who has access
              to the borrower’s employment or service records and is authorized by the employer to
              certify the employment status of the organization’s employees or former employees, or
              the service of AmeriCorps or Peace Corps volunteers.
            </p>

            <p>
              <b>Early childhood education</b> includes licensed or regulated child care, Head
              Start, and State funded pre-kindergarten.
            </p>

            <p>
              <b>Law enforcement</b> means crime prevention, control or reduction of crim, or the
              enforcement of criminal law.
            </p>

            <p>
              <b>Military service</b> means service on behalf of the U.S. Armed Forces or the
              National Guard.
            </p>

            <p>
              <b>Public interest legal services</b> refers to legal services that are funded in
              whole or in part by a local, State, Federal or Tribal government.
            </p>

            <p>
              <b>Public health</b> includes nurses, nurse practitioners, nurses in clinical setting,
              and full-time professionals engaged in health care practitioner occupations, health
              support occupations, and counselors, social Workers, and other community and social
              service specialists as such terms are defined by the{' '}
              <a
                href="https://www.bls.gov/soc/2018/major_groups.htm#29-0000"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bureau of Labor Statistics
              </a>
              .
            </p>
          </LeftText>
        </CardText>
      </CardBody>
      <CardFooter>
        <Button brand="lowContrast" onClick={closeModal}>
          Close
        </Button>
      </CardFooter>
    </Card>
  );
};

empcertSectionSix.propTypes = {
  closeModal: t.func.isRequired,
  id: t.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(empcertSectionSix);
