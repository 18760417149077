import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

import { Redirect } from 'react-router-dom';
import TuitionStatusInfo from './tuition-status-info';

import {
  AsyncLoader,
  Badge,
  Button,
  ButtonCarousel,
  Card,
  CardBody,
  CardTitle,
  Lede,
  Modal,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { safeAccess } from '../../../utils';

import { getTuitionInstitutionData } from '../redux/selectors';

import { TuitionInstitutionCurrentRow } from '../../../tuition-institution';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaddedBadge = styled(Badge)`
  margin-right: 0.45em;
  font-size: 0.75em;
  top: -0.75rem;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class OverviewInstitutions extends React.Component {
  static propTypes = {
    data: t.object,
    permissions: t.object,
    request: t.object,
    toDos: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', openStatusModal: false };

    this.handleStatusModal = this.handleStatusModal.bind(this);
  }

  handleStatusModal() {
    this.setState({ openStatusModal: !this.state.openStatusModal });
  }

  componentDidMount() {}

  noReimbursementsOnFile() {
    return (
      <FlexRow>
        <Lede>You have not been reimbursed for any completed terms.</Lede>
      </FlexRow>
    );
  }

  manageInstitutionsRedirect() {
    this.setState({ ...this.state, redirectURI: '/benefits/tuition/institutions' });
  }

  manageTermsRedirect() {
    this.setState({ ...this.state, redirectURI: '/benefits/tuition/terms' });
  }

  manageClassesRedirect() {
    this.setState({ ...this.state, redirectURI: '/benefits/tuition/classes' });
  }

  showCarousel(data) {
    return (
      Object.keys(data).length > 0 &&
      Object.keys(data).filter(
        (institution) =>
          data[institution].current &&
          Object.keys(data[institution].terms).length > 0 &&
          Object.keys(data[institution].terms).filter(
            (term) => data[institution].terms[term].current
          ).length
      ).length > 0
    );
  }

  getCarouselComponents(data) {
    return Object.keys(data)
      .filter(
        (institution) =>
          data[institution].current &&
          Object.keys(data[institution].terms).length > 0 &&
          Object.keys(data[institution].terms).filter(
            (term) => data[institution].terms[term].current
          ).length
      )
      .map((institution, i) => (
        <TuitionInstitutionCurrentRow institutionId={institution} key={i} />
      ));
  }

  getCarouselButtons(data) {
    return Object.keys(data)
      .filter(
        (institution) =>
          data[institution].current &&
          Object.keys(data[institution].terms).length > 0 &&
          Object.keys(data[institution].terms).filter(
            (term) => data[institution].terms[term].current
          ).length
      )
      .map((institution, i) => data[institution].institutionName);
  }

  render() {
    const { data = {}, request, toDos } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row">
                <div className="col">My Current Classes</div>
              </div>
              <div className="row">
                <div className="col">
                  <Lede>
                    <StyledButton
                      brand="primary"
                      onClick={() => {
                        this.manageInstitutionsRedirect();
                      }}
                    >
                      {Object.keys(toDos).includes('422') && (
                        <PaddedBadge brand="primary">
                          <FontAwesomeIcon icon={faSolidExclamation} />
                        </PaddedBadge>
                      )}
                      Manage Institution(s)
                    </StyledButton>
                  </Lede>
                  <Lede>
                    <StyledButton
                      brand="primary"
                      onClick={() => {
                        this.manageTermsRedirect();
                      }}
                    >
                      {(Object.keys(toDos).includes('423') ||
                        Object.keys(toDos).includes('424')) && (
                        <PaddedBadge brand="primary">
                          <FontAwesomeIcon icon={faSolidExclamation} />
                        </PaddedBadge>
                      )}
                      Manage Term(s)
                    </StyledButton>
                  </Lede>
                  <Lede>
                    <StyledButton
                      brand="primary"
                      onClick={() => {
                        this.manageClassesRedirect();
                      }}
                    >
                      {(Object.keys(toDos).includes('425') ||
                        Object.keys(toDos).includes('426')) && (
                        <PaddedBadge brand="primary">
                          <FontAwesomeIcon icon={faSolidExclamation} />
                        </PaddedBadge>
                      )}
                      Manage Class(es)
                    </StyledButton>
                  </Lede>
                  <Lede>
                    <StyledButton brand="primary" onClick={this.handleStatusModal}>
                      <PaddedIcon icon={faQuestionCircle} fixedWidth />
                      Class Status Info
                    </StyledButton>
                  </Lede>
                </div>
              </div>
            </CardTitle>
            <AsyncLoader request={request} empty={this.noReimbursementsOnFile()} loading={<></>}>
              {this.showCarousel(data) && (
                <ButtonCarousel
                  components={this.getCarouselComponents(data)}
                  componentDisplayNames={this.getCarouselButtons(data)}
                />
              )}
              {!this.showCarousel(data) && this.noReimbursementsOnFile()}
            </AsyncLoader>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.openStatusModal} fullWidth>
          <TuitionStatusInfo data={data} closeModal={this.handleStatusModal} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
    toDos: safeAccess(state, 'todos.cache', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(OverviewInstitutions));
