import actions from './actions';
import { getToken, getUserId, authLoadPermissions } from '../../auth';
import {
  baseFetch,
  getApi,
  keyById,
  normalize,
  patchApi,
  resourceOperationsGenerator,
  safeAccess,
} from '../../utils';

const endpointHandler = {
  endpoint: '/payment',
  customResponseHandler: null,
  resourceType: 'payment',
};

const operations = resourceOperationsGenerator(
  'payment',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  null,
  endpointHandler
);

export const paymentTypes = {
  NOW: 'PayNow',
  RENEWAL: 'PayRenewal',
  DEPOSIT: 'PayDeposit',
  LWO: 'PayLWO',
  SERVICE: 'PayService',
};

const paymentSetTypeDeposit = () => async (dispatch, getState) => {
  await dispatch(
    operations.paymentHandleCacheChange({
      ...getState().payment.cache,
      paymentType: paymentTypes.DEPOSIT,
    })
  );
  dispatch(actions.paymentLoadResourceSuccess());
};

const paymentSetTypeRenewal = () => async (dispatch, getState) => {
  await dispatch(
    operations.paymentHandleCacheChange({
      ...getState().payment.cache,
      paymentType: paymentTypes.RENEWAL,
    })
  );
  dispatch(actions.paymentLoadResourceSuccess());
};

const paymentSetTypeLWO = () => async (dispatch, getState) => {
  await dispatch(
    operations.paymentHandleCacheChange({
      ...getState().payment.cache,
      paymentType: paymentTypes.LWO,
    })
  );
  dispatch(actions.paymentLoadResourceSuccess());
};

const paymentSetTypeService = (id) => async (dispatch, getState) => {
  await dispatch(
    operations.paymentHandleCacheChange({
      ...getState().payment.cache,
      paymentType: paymentTypes.SERVICE,
      paymentServiceId: id,
    })
  );
  dispatch(actions.paymentLoadResourceSuccess());
};

const paymentSetPaymentType = () => async (dispatch, getState) => {
  dispatch(actions.paymentLoadResourceRequest());
  const state = getState();
  const id = getUserId(state);

  let data = await getApi(getToken(state), `/pay-now-destination/${id}`);
  dispatch(actions.paymentLoadResourceResponse());
  await dispatch(
    operations.paymentHandleCacheChange({
      ...getState().payment.cache,
      paymentType: data[0].destination,
      paymentServiceId: 0,
    })
  );
  dispatch(actions.paymentLoadResourceSuccess());
};

const paymentResetStatus = () => async (dispatch, getState) => {
  try {
    await dispatch(paymentClearErrors());

    dispatch(actions.paymentCreateResourceRequest());
    dispatch(actions.paymentCreateResourceResponse());
    dispatch(actions.paymentCreateResourceSuccess());
  } catch (error) {
    dispatch(actions.paymentCreateResourceFailure());
  }
};

const paymentDefaultPaymentType = () => async (dispatch, getState) => {
  const { cache } = getState().payment;
  if (cache && !Object.values(paymentTypes).includes(cache.paymentType)) {
    dispatch(paymentSetPaymentType());
  }
};

const paymentLoadInfo = (key = null) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  try {
    dispatch(actions.paymentLoadResourceRequest(key));
    const state = getState();
    const type = state.payment.cache.paymentType;
    if (!type) {
      throw new Error('Invalid payment type');
    }

    let endpoint = `/payment-page-one/${type}`;

    if (state.payment.cache.paymentServiceId && state.payment.cache.paymentServiceId > 0) {
      endpoint = `/payment-page-one/${type}_${state.payment.cache.paymentServiceId}`;
    }

    let data = await getApi(getToken(state), endpoint);

    dispatch(actions.paymentLoadResourceResponse(key));

    let paymentServiceIdVal = key === null ? 0 : key;
    if (type === paymentTypes.SERVICE && key === null) {
      paymentServiceIdVal = safeAccess(data[0], 'paymentInfo.id', 0);
    }

    const cache = {
      ...getState().payment.cache,
      ...keyById(data),
      paymentServiceId: paymentServiceIdVal,
    };

    await dispatch(operations.paymentHandleCacheChange(cache));

    dispatch(actions.paymentLoadResourceSuccess(null, key));
  } catch (error) {
    dispatch(operations.paymentHandleError(error));
    dispatch(actions.paymentLoadResourceFailure(error, key));
  }
};

const paymentSaveBillingInfo = () => async (dispatch, getState) => {
  const state = getState();
  try {
    if (safeAccess(state, 'payment.errors.length', 0) > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }
    const paymentType = safeAccess(state, 'payment.cache.paymentType');
    if (!paymentType || !Object.values(paymentTypes).includes(paymentType)) {
      throw new Error('Invalid payment type');
    }

    dispatch(actions.paymentUpdateResourceRequest());
    const patchData = normalize('payment-page-one', state.payment.form, paymentType);

    let endpoint = `/payment-page-one/${paymentType}`;

    if (state.payment.cache.paymentServiceId && state.payment.cache.paymentServiceId > 0) {
      endpoint = `/payment-page-one/${paymentType}_${state.payment.cache.paymentServiceId}`;
    }

    let data = await patchApi(getToken(state), endpoint, patchData);

    dispatch(actions.paymentUpdateResourceResponse());

    const cache = { ...getState().payment.cache, ...keyById(data) };
    await dispatch(operations.paymentHandleCacheChange(cache));

    dispatch(actions.paymentUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.paymentHandleError(error));
    dispatch(actions.paymentUpdateResourceFailure(error));
  }
};

const paymentPostPayment = (form, postUrl) => async (dispatch, getState) => {
  try {
    const month = form.month.toString();
    const mm = month.length === 1 ? `0${month}` : month;
    const yy = form.year.substring(2);

    const data = {
      'billing-cc-number': form.cardNumber,
      'billing-cc-exp': `${mm}${yy}`,
      'billing-cvv': form.cvv,
    };

    const formData = Object.keys(data).reduce((form, k) => {
      form.append(k, data[k]);
      return form;
    }, new FormData());

    const opts = {
      body: formData,
      method: 'POST',
    };
    dispatch(actions.paymentCreateResourceRequest());
    await baseFetch(postUrl, opts);

    dispatch(actions.paymentCreateResourceResponse());
    dispatch(actions.paymentCreateResourceSuccess());
  } catch (error) {
    dispatch(actions.paymentCreateResourceFailure());
  }
};

const paymentGetPaymentStatus = (token, key = null) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  try {
    dispatch(actions.paymentLoadResourceRequest());
    const state = getState();

    let url = `/payment-page-three/${token}`;
    if (key !== null || (key && key > 0)) {
      url = `/payment-page-three/${token}_${key}`;
    }

    await getApi(getToken(state), url);

    dispatch(actions.paymentLoadResourceResponse());

    const cache = {
      ...getState().payment.cache,
      paymentStatus: 'success',
    };

    const paymentType = safeAccess(state, 'payment.cache.paymentType');
    if (paymentType === paymentTypes.NOW) {
      dispatch(authLoadPermissions());
    }

    await dispatch(operations.paymentHandleCacheChange(cache));

    dispatch(actions.paymentLoadResourceSuccess());
  } catch (error) {
    dispatch(operations.paymentHandleError(error));
    dispatch(actions.paymentLoadResourceFailure(error));
  }
};

const paymentResetUpdateRequest = () => async (dispatch) => {
  dispatch(actions.paymentUpdateResourceSuccess());
};

const { paymentClearErrors, paymentCopyResourceToForm, paymentHandleFormChange } = operations;

export {
  paymentClearErrors,
  paymentCopyResourceToForm,
  paymentResetUpdateRequest,
  paymentDefaultPaymentType,
  paymentGetPaymentStatus,
  paymentHandleFormChange,
  paymentLoadInfo,
  paymentPostPayment,
  paymentSaveBillingInfo,
  paymentSetPaymentType,
  paymentSetTypeDeposit,
  paymentSetTypeRenewal,
  paymentSetTypeLWO,
  paymentSetTypeService,
  paymentResetStatus,
};
