import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';
import { CardBody, CardHeader, Loader, Header1, Form } from 'fiducius-ui';

import { ShadowAddCard } from '../../routing';

import {
  accountUnlockHandleFormChange,
  accountUnlockUpdateResource,
  accountUnlockCopyResourceToForm,
} from '../redux/operations';
import { safeAccess, debounce } from '../../utils';

import Email from '../components/email';
import HavingTrouble from '../components/having-trouble';
import SecurityQuestion from '../components/security-question';
import SuccessfulUnlock from '../components/successful-unlock';

const LoaderWrapper = styled.div`
  height: calc(${(p) => p.height}px - 2em);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em 0;
`;

const AccountUnlock = ({ data, location, request, setForm, handleChange, submit }) => {
  const params = queryString.parse(location.search);
  const [token, _updateToken] = useState(params.token === undefined ? 'hello' : params.token);
  const [redirectPath, updateRedirectPath] = useState();

  useEffect(() => {
    if (token) {
      setForm(token);
    } else {
      updateRedirectPath('/login');
    }
  }, [setForm, token, updateRedirectPath]);

  const submitForm = () => {
    if (safeAccess(data, 'email')) {
      submit(token);
    }
  };

  const onChange = debounce((accountUnlock, id, attributes) => {
    handleChange({ ...data, ...accountUnlock }, [attributes]);
  }, 250);

  const getCardBrand = () => {
    if (request.hasFailed || safeAccess(data, 'errorMessage')) {
      return 'danger';
    }
    return 'primaryB';
  };

  const getErrorMessage = () => {
    return request.hasFailed ? 'Invalid Email or Token' : safeAccess(data, 'errorMessage');
  };

  if (redirectPath === undefined && safeAccess(data, 'securityChecksum') === 'LOCKED') {
    updateRedirectPath('/login');
  }

  if (redirectPath === undefined && safeAccess(data, 'securityChecksum') === 'LOGIN') {
    updateRedirectPath('/password-update?token=' + safeAccess(data, 'passwordToken'));
  }

  const setRedirectOnSuccess = (path) => {
    updateRedirectPath(path);
  };

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <>
      <Header1>Unlock Account</Header1>
      <ShadowAddCard brand={getCardBrand()}>
        {getErrorMessage() && <CardHeader>{getErrorMessage()}</CardHeader>}

        <CardBody>
          {request.isLoading ? (
            <LoaderWrapper>
              <Loader variant="atom" size={3} />
            </LoaderWrapper>
          ) : (
            <Form id={token} handleChange={onChange}>
              {safeAccess(data, 'securityChecksum') ? (
                <>
                  {data.securityChecksum === 'LOGIN' && (
                    <SuccessfulUnlock
                      goToLogin={() => setRedirectOnSuccess('/login')}
                      goToForgotPassword={() => setRedirectOnSuccess('/password-reset')}
                    />
                  )}
                  {data.securityChecksum !== 'LOGIN' && (
                    <SecurityQuestion data={data} handleClick={submitForm} />
                  )}
                </>
              ) : (
                <Email data={data} handleClick={submitForm} />
              )}
              <HavingTrouble />
            </Form>
          )}
        </CardBody>
      </ShadowAddCard>
    </>
  );
};

AccountUnlock.propTypes = {
  location: t.shape({
    search: t.string,
  }),
  data: t.object.isRequired,
  request: t.object,
  handleChange: t.func.isRequired,
  submit: t.func.isRequired,
  setForm: t.func.isRequired,
};

const mapStateToProps = (state) => {
  const accountUnlock = state.accountUnlock;

  return {
    data: accountUnlock.form,
    request: accountUnlock.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (accountUnlock, attributes) =>
    dispatch(accountUnlockHandleFormChange(accountUnlock, attributes)),
  submit: async (id) => {
    await dispatch(accountUnlockUpdateResource(id));
    dispatch(accountUnlockCopyResourceToForm(id));
  },
  setForm: (id) => dispatch(accountUnlockCopyResourceToForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountUnlock));
