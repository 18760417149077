import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { Alert, Button } from 'fiducius-ui';

const StyledAlert = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  & > button {
    margin-left: auto;
  }
`;

const OutstandingTodos = ({ toggleDrawer }) => (
  <Alert brand="primary">
    <StyledAlert>
      You have items that need your attention.
      <Button brand="primary" invert onClick={() => toggleDrawer('todos')}>
        See my to-do list
      </Button>
    </StyledAlert>
  </Alert>
);

OutstandingTodos.propTypes = {
  toggleDrawer: t.func.isRequired,
};

export default OutstandingTodos;
