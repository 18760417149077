import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Header3,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputNumeric,
  InputText,
  InputWrapper,
  fade,
} from 'fiducius-ui';

import { Fade } from '../../../routing';

import { safeAccess } from '../../../utils';

const RowFade = styled.div`
  ${fade};
`;

const IncomeSourceForm = ({ data = {}, errors = {}, id, size }) => (
  <>
    <Fade id={'additionalIncomeSource' + id} show={id < size}>
      <Header3>Additional income source {id + 1}</Header3>
      <RowFade className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.incomeSource}>
            <InputLabel htmlFor={'incomeSource' + id}>Income Source</InputLabel>
            <InputError>{safeAccess(errors, 'incomeSource.detail')}</InputError>
            <InputGroup>
              <InputText name={'incomeSource' + id} defaultValue={data.incomeSource} />
            </InputGroup>
          </InputWrapper>
        </div>
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.annualIncome}>
            <InputLabel htmlFor={'annualIncome' + id}>Annual Income Amount</InputLabel>
            <InputError>{safeAccess(errors, 'annualIncome.detail')}</InputError>
            <InputGroup>
              <InputAddon>$</InputAddon>
              <InputNumeric name={'annualIncome' + id} defaultValue={data.annualIncome} />
              <InputAddon>.00</InputAddon>
            </InputGroup>
          </InputWrapper>
        </div>
      </RowFade>
    </Fade>
  </>
);

IncomeSourceForm.propTypes = {
  data: t.object,
  errors: t.object,
  id: t.number.isRequired,
  size: t.number.isRequired,
};

export default IncomeSourceForm;
