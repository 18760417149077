import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Animation,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { safeAccess, stringIsNullOrEmpty } from '../../utils';
import { Fade } from '../../routing';

import { relationTypes } from '../redux/validators';

const TextLeft = styled.div`
  text-align: left;
`;

class ReferenceForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }

    if (!prevProps.errors.generalErrors && this.props.errors.generalErrors) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  getForm() {
    const { data, errors, onChange, onSubmit } = this.props;
    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <CardTitle>Reference Information</CardTitle>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.firstName}>
              <InputLabel htmlFor="firstName" required>
                First Name
              </InputLabel>
              <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
              <InputGroup>
                <InputText
                  inputRef={this.firstInput}
                  name="firstName"
                  defaultValue={data.firstName}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.lastName}>
              <InputLabel htmlFor="lastName" required>
                Last Name
              </InputLabel>
              <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
              <InputGroup>
                <InputText name="lastName" defaultValue={data.lastName} />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <InputWrapper error={!!errors.relationType}>
          <InputLabel htmlFor="relationType" required>
            Relationship Type
          </InputLabel>
          <InputError>{safeAccess(errors, 'relationType.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name="relationType"
              options={relationTypes}
              defaultValue={data.relationType}
            />
          </InputGroup>
        </InputWrapper>
        <Animation
          key="relationTypeGate"
          animation="fade"
          appear={false}
          in={data.relationType === 'OTHER'}
          unmountOnExit
        >
          <InputWrapper error={!!errors.otherRelationDescription}>
            <InputLabel htmlFor="otherRelationDescription" required>
              Relationship Description
            </InputLabel>
            <InputError>{safeAccess(errors, 'otherRelationDescription.detail')}</InputError>
            <InputGroup>
              <InputText
                name="otherRelationDescription"
                defaultValue={data.otherRelationDescription}
              />
            </InputGroup>
          </InputWrapper>
        </Animation>
        <InputWrapper error={!!errors.email}>
          <InputLabel htmlFor="email" required>
            Email
          </InputLabel>
          <InputError>{safeAccess(errors, 'email.detail')}</InputError>
          <InputGroup>
            <InputText name="email" defaultValue={data.email} />
          </InputGroup>
        </InputWrapper>
        <div className="row">
          <div className="col-12 col-sm-6">
            <InputWrapper error={!!errors.phone}>
              <InputLabel htmlFor="phone" required>
                Phone Number
              </InputLabel>
              <InputError>{safeAccess(errors, 'phone.detail')}</InputError>
              <InputGroup>
                <InputMask
                  defaultValue={data.phone}
                  mask="(###) ###-####"
                  name="phone"
                  placeholder="(___) ___-____"
                  type="tel"
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <InputWrapper error={!!errors.address1}>
          <InputLabel htmlFor="address1" required>
            Address Line 1
          </InputLabel>
          <InputError>{safeAccess(errors, 'address1.detail')}</InputError>
          <InputGroup>
            <InputText defaultValue={data.address1} name="address1" />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.address2}>
          <InputLabel htmlFor="address2">Address Line 2</InputLabel>
          <InputError>{safeAccess(errors, 'address2.detail')}</InputError>
          <InputGroup>
            <InputText defaultValue={data.address2} name="address2" />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.city}>
          <InputLabel htmlFor="city" required>
            City
          </InputLabel>
          <InputError>{safeAccess(errors, 'city.detail')}</InputError>
          <InputGroup>
            <InputText defaultValue={data.city} name="city" />
          </InputGroup>
        </InputWrapper>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.state}>
              <InputLabel htmlFor="state" required>
                State
              </InputLabel>
              <InputError>{safeAccess(errors, 'state.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  defaultValue={data.state}
                  name="state"
                  options={[
                    '',
                    'AL',
                    'AK',
                    'AR',
                    'AZ',
                    'CA',
                    'CO',
                    'CT',
                    'DC',
                    'DE',
                    'FL',
                    'GA',
                    'HI',
                    'IA',
                    'ID',
                    'IL',
                    'IN',
                    'KS',
                    'KY',
                    'LA',
                    'MA',
                    'MD',
                    'ME',
                    'MI',
                    'MN',
                    'MO',
                    'MS',
                    'MT',
                    'NC',
                    'NE',
                    'NH',
                    'NJ',
                    'NM',
                    'NV',
                    'NY',
                    'ND',
                    'OH',
                    'OK',
                    'OR',
                    'PA',
                    'PR',
                    'RI',
                    'SC',
                    'SD',
                    'TN',
                    'TX',
                    'UT',
                    'VT',
                    'VA',
                    'WA',
                    'WI',
                    'WV',
                    'WY',
                  ]}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.zip}>
              <InputLabel htmlFor="zip" required>
                Zip Code
              </InputLabel>
              <InputError>{safeAccess(errors, 'zip.detail')}</InputError>
              <InputGroup>
                <InputText defaultValue={data.zip} name="zip" />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
      </Form>
    );
  }

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {} } = this.props;
    return (
      !stringIsNullOrEmpty(data.firstName) &&
      !stringIsNullOrEmpty(data.lastName) &&
      !stringIsNullOrEmpty(data.address1) &&
      !stringIsNullOrEmpty(data.city) &&
      !stringIsNullOrEmpty(data.state) &&
      !stringIsNullOrEmpty(data.zip) &&
      !stringIsNullOrEmpty(data.relationType) &&
      !stringIsNullOrEmpty(data.phone)
    );
  };

  checkNoErrors = () => {
    const { data = {}, errors = {} } = this.props;
    return (
      data.phone.match(/(\([0-9]{3}\) [0-9]{3}-[0-9]{4}|[0-9]{10})/g) &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.address1 &&
      !errors.address2 &&
      !errors.city &&
      !errors.state &&
      !errors.zip &&
      !errors.phone &&
      !errors.email &&
      !errors.relationType &&
      !errors.otherRelationDescription
    );
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { action, closeForm, errors = {}, isLoading, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Reference</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            <TextLeft>{this.getForm()}</TextLeft>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' reference'}
                </Button>
              </Fade>
              <Fade show={!isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade show={!isLoading && this.checkNoMissingData() && !this.checkNoErrors()}>
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default ReferenceForm;
