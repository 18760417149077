import actions from './actions';
import { getToken } from '../../auth';
import { keyById, normalize, patchApi, resourceOperationsGenerator, safeAccess } from '../../utils';
import { ContactValidator } from './validators';

const contactValidator = new ContactValidator();

const endpointHandler = {
  endpoint: '/contact-us',
  customResponseHandler: null,
  resourceType: 'contact-us',
};

const operations = resourceOperationsGenerator(
  'contact',
  actions,
  null,
  contactValidator,
  endpointHandler,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const contactLoadInfo = (key = null) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  try {
    await dispatch(operations.contactHandleCacheChange({}));

    dispatch(actions.contactLoadResourceRequest(key));

    let data = await dispatch(contactLoadResource());
    dispatch(actions.contactLoadResourceResponse(key));

    const cache = {
      ...getState().contact.cache,
      ...keyById(data),
    };

    await dispatch(operations.contactHandleCacheChange(cache));

    dispatch(actions.contactLoadResourceSuccess(null, key));
  } catch (error) {
    dispatch(operations.contactHandleError(error));
    dispatch(actions.contactLoadResourceFailure(error, key));
  }
};

const contactSaveBillingInfo = () => async (dispatch, getState) => {
  const state = getState();
  try {
    if (safeAccess(state, 'contact.errors.length', 0) > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }

    dispatch(actions.contactUpdateResourceRequest());
    const patchData = normalize('contact-us', state.contact.form, 1);
    let data = await patchApi(getToken(state), `/contact-us/${1}`, patchData);

    dispatch(actions.contactUpdateResourceResponse());

    const cache = { ...getState().contact.cache, ...keyById(data) };
    cache['contactStatus'] = 'success';
    await dispatch(operations.contactHandleCacheChange(cache));

    dispatch(actions.contactUpdateResourceSuccess());
  } catch (error) {
    const cache = { ...getState().contact.cache };
    cache['contactStatus'] = 'failure';
    await dispatch(operations.contactHandleCacheChange(cache));
    dispatch(operations.contactHandleError(error));
    dispatch(actions.contactUpdateResourceFailure(error));
  }
};

const { contactCopyResourceToForm, contactHandleFormChange, contactLoadResource } = operations;

export {
  contactCopyResourceToForm,
  contactHandleFormChange,
  contactLoadInfo,
  contactSaveBillingInfo,
};
