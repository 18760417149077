import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { withPermissions } from '../../../routing';
import { FlexEnd } from '../../../root';
import { Redirect } from 'react-router-dom';

import { AsyncLoader, Badge, Button, Card, CardBody, CardTitle, CardText, Lede } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { debounce, mergeRequestStatuses } from '../../../utils';

import { getTuitionData } from '../redux/selectors';
import { tuitionHandleFormChange, tuitionUpdateResource } from '../redux/operations';

import ApproverEmail from './approver-email';

const PaddedBadge = styled(Badge)`
  margin-left: 0.45em;
  font-size: 0.75em;
`;

class RequiredInformation extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    permissions: t.object,
    updateFormState: t.func.isRequired,
    saveData: t.func.isRequired,
    id: t.string.isRequired,
    request: t.object.isRequired,
    updateRequest: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', showForm: false };
  }

  componentDidMount() {}

  handleChange = debounce((formState) => {
    const { data, updateFormState } = this.props;
    const newData = { ...data, ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 200);

  save = () => {
    this.props.saveData(this.props.id);
  };

  updateManagerEmail = () => {
    this.setState({ showForm: true });
  };

  closeForm = () => {
    this.setState({ showForm: false });
  };

  render() {
    const { data = {}, request } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>Other Required Information</CardTitle>
            <CardText>
              <AsyncLoader request={mergeRequestStatuses([request])}>
                <div className="row">
                  <div className="col-8">
                    <dl>
                      <dt>
                        <Lede>
                          Home Address{' '}
                          {!data.addressOne && (
                            <PaddedBadge brand="primary">
                              <FontAwesomeIcon icon={faSolidExclamation} />
                            </PaddedBadge>
                          )}
                        </Lede>
                      </dt>
                      {data.addressOne && (
                        <dd>
                          <div className="col">{data.addressOne}</div>
                          {data.addressTwo && data.addressTwo.length > 0 && (
                            <div className="col">{data.addressTwo}</div>
                          )}
                          <div className="col">
                            {data.city + ', ' + data.usState + ' ' + data.zipCode}
                          </div>
                        </dd>
                      )}
                      {!data.addressOne && (
                        <dd>
                          <div className="col">Not on file</div>
                        </dd>
                      )}
                    </dl>
                  </div>
                  <div className="col-4">
                    <FlexEnd>
                      <Button
                        brand="primary"
                        onClick={() => {
                          this.setState({ ...this.state, redirectURI: '/profile' });
                        }}
                      >
                        Manage Profile
                      </Button>
                    </FlexEnd>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <dl>
                      <dt>
                        <Lede>
                          Reimbursement Account{' '}
                          {!data.reimbursementAccountInfo && (
                            <PaddedBadge brand="primary">
                              <FontAwesomeIcon icon={faSolidExclamation} />
                            </PaddedBadge>
                          )}
                        </Lede>
                      </dt>
                      {data.reimbursementAccountInfo && (
                        <dd>
                          <div className="col">{data.reimbursementAccountInfo}</div>
                        </dd>
                      )}
                      {!data.reimbursementAccountInfo && (
                        <dd>
                          <div className="col">Not on file</div>
                        </dd>
                      )}
                    </dl>
                  </div>
                  <div className="col-4">
                    <FlexEnd>
                      <Button
                        brand="primary"
                        onClick={() => {
                          this.setState({ ...this.state, redirectURI: '/accounts' });
                        }}
                      >
                        Manage Accounts
                      </Button>
                    </FlexEnd>
                  </div>
                </div>
                {data.needApproverEmail && (
                  <div className="row">
                    <div className="col-8">
                      <dl>
                        <dt>
                          <Lede>
                            Manager Email{' '}
                            {!data.approverEmail && (
                              <PaddedBadge brand="primary">
                                <FontAwesomeIcon icon={faSolidExclamation} />
                              </PaddedBadge>
                            )}
                          </Lede>
                        </dt>
                        {data.approverEmail && (
                          <dd>
                            <div className="col">{data.approverEmail}</div>
                          </dd>
                        )}
                        {!data.approverEmail && (
                          <dd>
                            <div className="col">Not on file</div>
                          </dd>
                        )}
                      </dl>
                    </div>
                    <div className="col-4">
                      <FlexEnd>
                        <Button brand="primary" onClick={() => this.updateManagerEmail()}>
                          Update Manager Email
                        </Button>
                      </FlexEnd>
                    </div>
                  </div>
                )}
              </AsyncLoader>
            </CardText>
          </CardBody>
        </Card>
        {this.state.showForm && (
          <ApproverEmail isOpen={this.state.showForm} closeForm={this.closeForm} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: { ...state.tuition.form, ...getTuitionData(state) },
    id: getUserId(state),
    errors: state.tuition.errors,
    request: state.tuition.requests.loadResource,
    updateRequest: state.tuition.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFormState: (data, attributes) => dispatch(tuitionHandleFormChange(data, attributes)),
  saveData: async (id) => {
    await dispatch(tuitionUpdateResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(RequiredInformation));
