import { FormValidator } from '../../utils';

export const relationTypes = {
  '': '',
  ADV: 'Advisor',
  CHILD: 'Child',
  FIAF: 'Fiancée',
  FIAM: 'Fiancé',
  FRND: 'Friend',
  GRANC: 'Grandchild',
  GRANP: 'Grandparent',
  PARNT: 'Parent',
  REFER: 'Referral',
  SBLNG: 'Sibling',
  SPOUS: 'Spouse',
  'UN/AN': 'Uncle/Aunt',
  OTHER: 'Other',
};

const getRelationTypeRegex = () =>
  new RegExp(
    `(${Object.keys(relationTypes)
      .filter((t) => t !== '')
      .join('|')})`,
    'g'
  );

export class ReferenceValidator extends FormValidator {
  id(validator) {
    validator.isRequired();
  }

  firstName(validator) {
    validator.isRequired();
    validator.hasMaxLength(20);
  }

  lastName(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  address1(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  address2(validator) {
    validator.hasMaxLength(30);
  }

  city(validator) {
    validator.isRequired();
    validator.hasMaxLength(20);
  }

  state(validator) {
    validator.isRequired();
    validator.hasMaxLength(2);
  }

  zip(validator) {
    validator.isRequired();
    validator.hasMaxLength(10);
    validator.matchesRegex(
      /^[0-9]{5}($|-[0-9]{4}$)/g,
      'must be formatted as either ##### or #####-####'
    );
  }

  phone(validator) {
    validator.isRequired();
    validator.hasMaxLength(17);
    validator.isValidPhoneNumber();
  }

  email(validator) {
    // validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidEmail();
  }

  relationType(validator) {
    validator.isRequired();
    validator.hasMaxLength(5);

    // This can be slow, so if it's failed already just skip it.
    if (!validator.hasFailed()) {
      const regex = getRelationTypeRegex();
      validator.matchesRegex(regex, 'must be a valid relationship type.');
    }
  }

  otherRelationDescription(validator) {
    validator.hasMaxLength(250);
  }
}
