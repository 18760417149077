import React from 'react';
import t from 'prop-types';

import { CardTitle } from 'fiducius-ui';
import { FocusButton } from './focus-button';
import { FocusPageInputs } from './focus-page-inputs';

export const FocusPage = ({ page }) => {
  return (
    <div className="polaris-focus-page">
      <CardTitle>{page}</CardTitle>
      <FocusPageInputs page={page} />
      <FocusButton focus={null} />
    </div>
  );
};

FocusPage.propTypes = {
  page: t.string.isRequired,
};
