import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { Redirect } from 'react-router-dom';

import { withPermissions } from '../../../routing';
import { Button, Badge, Card, CardBody, CardText, CardTitle, Header4 } from 'fiducius-ui';

const PaddedBadge = styled(Badge)`
  margin-left: 0.45em;
  font-size: 0.75em;
`;
const DivRight = styled.div`
  text-align: right;
`;

class ContributionRequiredInfo extends React.Component {
  static propTypes = {
    data: t.object.isRequired,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      redirectUri: '',
    }; // must be initialized for data handling
  }

  componentDidMount() {}

  //handleLoanClick = () => {
  //  this.setState({ showLoanAccts: !this.state.showLoanAccts });
  //};

  render() {
    const { data = {}, permissions } = this.props;
    if (this.state.redirectUri && this.state.redirectUri.length > 0) {
      return <Redirect to={this.state.redirectUri} />;
    }
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>Other Required Information</CardTitle>
            <CardText>
              <div className="row">
                <div className="col-7">
                  <dl>
                    <dt>
                      <Header4>
                        Home Address{' '}
                        {!data.addressOne && (
                          <PaddedBadge brand="primary">
                            <FontAwesomeIcon icon={faSolidExclamation} />
                          </PaddedBadge>
                        )}
                      </Header4>
                    </dt>
                    {data.addressOne && (
                      <dd>
                        <div className="col">{data.addressOne}</div>
                        {data.addressTwo && data.addressTwo.length > 0 && (
                          <div className="col">{data.addressTwo}</div>
                        )}
                        <div className="col">
                          {data.city + ', ' + data.usState + ' ' + data.zipCode}
                        </div>
                      </dd>
                    )}
                    {!data.addressOne && (
                      <dd>
                        <div className="col">Not on file</div>
                      </dd>
                    )}
                  </dl>
                </div>
                <DivRight className="col-5">
                  <Button
                    brand="primary"
                    onClick={() => {
                      this.setState({ ...this.state, redirectUri: '/profile' });
                    }}
                  >
                    Manage Profile
                  </Button>
                </DivRight>
              </div>
              {!permissions.showForgiveness && (
                <div className="row">
                  <div className="col-7">
                    <dl>
                      <dt>
                        <Header4>
                          Contribution Account(s){' '}
                          {data.contributionEligibleAccounts === 0 && (
                            <PaddedBadge brand="primary">
                              <FontAwesomeIcon icon={faSolidExclamation} />
                            </PaddedBadge>
                          )}
                        </Header4>
                      </dt>
                      <dd>
                        <div className="col">
                          You have {data.contributionEligibleAccounts} eligible account(s) on file
                        </div>
                      </dd>
                    </dl>
                  </div>
                  <DivRight className="col-5">
                    <Button
                      brand="primary"
                      onClick={() => {
                        this.setState({ ...this.state, redirectUri: '/accounts' });
                      }}
                    >
                      Manage Accounts
                    </Button>
                  </DivRight>
                </div>
              )}
            </CardText>
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(ContributionRequiredInfo));
