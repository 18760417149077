import React from 'react';
import styled from 'styled-components';

import t from 'prop-types';
import {
  Button,
  Form,
  InputGroup,
  InputLabel,
  InputHelp,
  InputText,
  InputWrapper,
} from 'fiducius-ui';
import { useFocusOnMount } from '../../utils';

const FinePrint = styled.div`
  font-size: calc(1rem);
`;

const StyledLink = styled.a`
  color: rgb(24, 188, 112);
`;

const PasswordResetForm = ({ email, handleChange, onSubmit }) => {
  const firstInput = useFocusOnMount();
  return (
    <Form id="passwordResetForm" onSubmit={onSubmit}>
      <InputWrapper>
        <InputLabel>Email</InputLabel>
        <InputGroup>
          <InputText inputRef={firstInput} name="email" value={email} onChange={handleChange} />
        </InputGroup>
        <InputHelp>This is the email you use to login to your MyFiducius account.</InputHelp>
      </InputWrapper>
      <InputWrapper>
        <Button brand="primary" type="submit" onClick={onSubmit}>
          Reset password
        </Button>
      </InputWrapper>
      <InputWrapper>
        <FinePrint>Having trouble with your password reset?</FinePrint>
        <FinePrint>
          Contact us at{' '}
          <StyledLink href="mailto:LoginAssistance@getfiducius.com">
            LoginAssistance@getfiducius.com
          </StyledLink>{' '}
          for assistance.
        </FinePrint>
      </InputWrapper>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  email: t.string.isRequired,
  handleChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
};

export default PasswordResetForm;
