import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import { Card, CardBody, CardTitle, CardText, Table } from 'fiducius-ui';

import { convertIsoToSlash, formatAsMoney } from '../../utils';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';

class TuitionInstitutionHistoryTermRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    termId: t.number.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showTerm: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.setState({
      showTerm: !this.state.showTerm,
    });
  };

  componentDidMount() {}

  render() {
    const { data, institutionId, termId } = this.props;
    const term = data[institutionId].terms[termId];
    const classes = [];
    const classHeaders = ['Class', 'Grade', 'Hours', 'Status'];
    return (
      <Card>
        <CardBody>
          <CardTitle onClick={this.handleClick}>
            <FontAwesomeIcon icon={this.state.showTerm ? faAngleDown : faAngleRight} fixedWidth />
            {term.termName}
          </CardTitle>
          {this.state.showTerm && (
            <CardText>
              <div className="row">
                <dl className="col-6">
                  <dt>Start Date</dt>
                  <dd>{convertIsoToSlash(term.startDate)}</dd>
                  <dt>End Date</dt>
                  <dd>{convertIsoToSlash(term.endDate)}</dd>
                  {term.reimbursementDate && (
                    <>
                      <dt>Reimbursement Date</dt>
                      <dd>{convertIsoToSlash(term.reimbursementDate)}</dd>
                    </>
                  )}
                </dl>
                <dl className="col-6">
                  <dt>Total Cost of Term</dt>
                  <dd>{formatAsMoney(term.totalCost)}</dd>
                  <dt>Cost Per Credit Hour</dt>
                  <dd>
                    {term.costPerCreditHour ? formatAsMoney(term.costPerCreditHour) : <>&nbsp;</>}
                  </dd>
                  {!isNaN(term.reimbursedAmount) && (
                    <>
                      <dt>Reimbursed Amount</dt>
                      <dd>{formatAsMoney(term.reimbursedAmount)}</dd>
                    </>
                  )}
                </dl>
              </div>
              <div className="col-12">
                {Object.keys(term.classes).length > 0 &&
                  Object.keys(term.classes).forEach((myClass) => {
                    classes.push([
                      term.classes[myClass].className,
                      term.classes[myClass].grade,
                      term.classes[myClass].hours,
                      term.classes[myClass].currentStatus,
                    ]);
                  })}
                {classes.length > 0 && <Table header={classHeaders} body={classes} />}
              </div>
            </CardText>
          )}
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (state) => {};

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionHistoryTermRow);
