import React from 'react';
import t from 'prop-types';
import { AnimationGroup } from 'fiducius-ui';
import { Route, Switch } from 'react-router-dom';

import { Agreement } from '../../workflows/agreement';
import { Fulfillment } from '../../fulfillment';
import { Idr } from '../../workflows/idr';
import { QuestionnaireBTS } from '../../workflows/questionnaire-bts';
import { Renewal } from '../../workflows/renewal';
import { Payment } from '../../payment';
import { Screening } from '../../workflows/screening';
import { Prescreening } from '../../workflows/screening';
import { Tuition } from '../../workflows/tuition';
import { TuitionApproval } from '../../tuition-approval';
import { EmpcertApproval } from '../../empcert-approval';
import ErrorPage from './error-page';
import PrivateRoute from './private-route';
import { Privacy, SharedData, TermsOfUse } from '../../legal';
import { NSLDSContainer } from '../../workflows/nslds';
import { LwoAgreement } from '../../workflows/lwo-agreement';
import { SecurityQuestionsAuth } from '../../security-questions-setup';
import { SecurityQuestionCheck } from '../../security-questions';

const RouterFullscreen = (props) => {
  //const domain = props.domain || 'islsoffice';
  return (
    <Route
      render={({ location }) => (
        <AnimationGroup key={location.key} animation="fade">
          <Switch location={location}>
            {/* Errors */}
            <Route path="/error/:code" component={ErrorPage} />

            <Route
              exact
              path="/tuition-approval"
              render={() => <TuitionApproval location={location} />}
            />

            <Route
              exact
              path="/empcert-approval"
              render={() => <EmpcertApproval location={location} />}
            />

            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/shared-data" component={SharedData} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            {/* Private routes */}
            <PrivateRoute exact path="/agreement" component={Agreement} />
            <PrivateRoute exact path="/fulfillment/:fileId" component={Fulfillment} />
            <PrivateRoute exact path="/idr" component={Idr} />
            <PrivateRoute exact path="/bts" component={QuestionnaireBTS} />
            <PrivateRoute exact path="/payment" component={Payment} />
            <PrivateRoute exact path="/renewal" component={Renewal} />
            <PrivateRoute exact path="/screening" component={Screening} />
            <PrivateRoute exact path="/prescreening" component={Prescreening} />
            <PrivateRoute exact path="/tuition/new-class" component={Tuition} />
            <PrivateRoute exact path="/nslds" component={NSLDSContainer} />
            <PrivateRoute exact path="/lwo-agreement" component={LwoAgreement} />
            <PrivateRoute exact path="/security-questions" component={SecurityQuestionsAuth} />
            <PrivateRoute exact path="/security-question-check" component={SecurityQuestionCheck} />
          </Switch>
        </AnimationGroup>
      )}
    />
  );
};

RouterFullscreen.propTypes = {
  domain: t.string,
  changeTheme: t.func.isRequired,
};

export default RouterFullscreen;
