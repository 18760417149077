import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { BrandColor, Button, Card, CardBody, CardText, Header5, Loader } from 'fiducius-ui';

import { todoActionTypes } from '../redux/mapper';

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTodoCard = styled(Card)`
  box-shadow: ${(p) => p.theme.boxShadow};
  transition: box-shadow 200ms ease-in-out;
  &:hover {
    box-shadow: ${(p) => p.theme.boxShadowLarge};
  }
`;

const TodoActions = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const TodoText = styled.div`
  margin-top: 0.3rem;
`;

// TODO: I know there has to be a better way to encapsulate this stuff.
const renderTodo = (
  todo,
  history,
  isOpen,
  toggleDrawer,
  toggleModal,
  dispatch,
  toggleNavigation
) => {
  let onClick = null;
  let extraRender = null;
  let showButton = true;

  switch (todo.actionType) {
    case todoActionTypes.TODO_ACTION_DRAWER: {
      onClick = todo.action(toggleDrawer);
      break;
    }
    case todoActionTypes.TODO_ACTION_LINK: {
      onClick = todo.action(history, dispatch, toggleDrawer);
      break;
    }
    case todoActionTypes.TODO_ACTION_LINKPAYSERVICE: {
      onClick = todo.action(history, dispatch, toggleDrawer, todo.original.resultId);
      break;
    }
    case todoActionTypes.TODO_ACTION_MODAL: {
      extraRender = todo.action({ id: todo.id, isOpen, toggleModal, toggleDrawer });
      onClick = toggleModal;
      break;
    }
    case todoActionTypes.TODO_ACTION_READONLY: {
      showButton = false;
      onClick = undefined;
      break;
    }
    case todoActionTypes.TODO_ACTION_REDUX: {
      onClick = todo.action(todo.id, dispatch);
      break;
    }
    default: {
      throw new Error('Undefined todo action handler');
    }
  }

  return (
    <>
      {extraRender}
      {showButton && (
        <Button invert brand="primary" onClick={onClick}>
          {todo.buttonText}
        </Button>
      )}
    </>
  );
};

const getLoader = () => (
  <LoaderWrapper>
    <Loader variant="bounce" />
  </LoaderWrapper>
);

const Todo = ({ history, isComplete, request, todo, toggleDrawer, dispatch, toggleNavigation }) => {
  const [isOpen, setOpen] = useState(false);
  const toggleModal = () => setOpen(!isOpen);

  return (
    <StyledTodoCard brand={isComplete ? 'secondary' : request.hasFailed ? 'danger' : 'tertiary'}>
      <CardBody>
        <CardText>
          <TodoActions>
            <TodoText>
              {request.isLoading ? (
                getLoader()
              ) : (
                <Header5>
                  <BrandColor brand="textPrimary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isComplete ? (
                          <s>
                            {todo.fullText &&
                              todo.fullText.replace('Assure', 'Assure<span>&#8480;</span>')}
                          </s>
                        ) : (
                          todo.fullText &&
                          todo.fullText.replace('Assure', 'Assure<span>&#8480;</span>')
                        ),
                      }}
                    />
                  </BrandColor>
                </Header5>
              )}
              {!isComplete &&
                renderTodo(
                  todo,
                  history,
                  isOpen,
                  toggleDrawer,
                  toggleModal,
                  dispatch,
                  toggleNavigation
                )}
            </TodoText>
          </TodoActions>
        </CardText>
      </CardBody>
    </StyledTodoCard>
  );
};

Todo.propTypes = {
  brand: t.string,
  dispatch: t.func.isRequired,
  history: t.object,
  id: t.number,
  isComplete: t.bool,
  request: t.object,
  todo: t.shape({
    buttonText: t.string.isRequired,
    fullText: t.string.isRequired,
    action: t.func.isRequired,
  }).isRequired,
  toggleDrawer: t.func.isRequired,
  toggleNavigation: t.func.isRequired,
};

export default connect()(withRouter(Todo));
