import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  DashboardSection,
  Lede,
  Modal,
} from 'fiducius-ui';
import StepDisplay from '../../forgiveness/components/step-display';

import { withPermissions } from '../../../routing';

import { mergeRequestStatuses } from '../../../utils';
import LwoConfirmation from '../components/lwo-confirmation';
import LwoProgress from '../components/lwo-progress';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

const StyledDiv = styled.div`
  margin-top: 1em;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em;
`;

const StyledLede = styled(Lede)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textPrimary : 'inherit')};
`;

const AlignTextLeft = styled.div`
  text-align: left;
`;
class LwoEmploymentCertification extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showStep1: false,
      showStep2: false,
      showStep3: false,
      openWhatHappenStepModal: false,
    };
    this.handleWhatHappenStepModal = this.handleWhatHappenStepModal.bind(this);
  }

  componentDidMount() {
    const pathName = window.location.pathname;
    if (pathName.includes('/step')) {
      this.setState({
        ...this.state,
        showStep1: pathName.includes('/step-1'),
        showStep2: pathName.includes('/step-2'),
        showStep3: pathName.includes('/step-3'),
      });
    }
  }

  componentDidUpdate() {}

  handleWhatHappenStepModal() {
    this.setState({ openWhatHappenStepModal: !this.state.openWhatHappenStepModal });
  }

  handleStepClick = (stepNumber) => {
    let sectionName = 'step' + stepNumber.toString();
    switch (stepNumber) {
      case 1:
        this.setState({ showStep2: false, showStep3: false, showStep1: true }, () => {
          this.scrollToStep(sectionName);
        });
        break;
      case 2:
        this.setState({ showStep1: false, showStep3: false, showStep2: true }, () => {
          this.scrollToStep(sectionName);
        });
        break;
      case 3:
        this.setState({ showStep1: false, showStep2: false, showStep3: true }, () => {
          this.scrollToStep(sectionName);
        });
        break;
      default:
        break;
      // code block
    }
  };

  scrollToStep(section) {
    document.getElementById(section).scrollIntoView({ behavior: 'smooth' });
  }

  getButtonBrand = (buttonStageId) => {
    const { todos } = this.props;

    let currentStage = Object.values(todos).find((a) => a.id >= 600 && a.id <= 699);

    if (currentStage !== undefined) {
      if (buttonStageId < currentStage.id) {
        return 'success';
      } else if (buttonStageId === currentStage.id) {
        return 'info';
      } else {
        return 'warning';
      }
    } else {
      return 'warning';
    }
  };

  getRestrictionDate = () => {
    let retVal = undefined;
    const { permissions } = this.props;
    const lwoStep = permissions.lwoSteps.find((a) => a.id === 'EmploymentCertification');
    if (lwoStep.stepDateRestriction !== undefined) {
      let stepRestriction = new Date(lwoStep.stepDateRestriction);
      retVal =
        (stepRestriction.getMonth() + 1).toString() +
        '/' +
        stepRestriction.getDate() +
        '/' +
        stepRestriction.getFullYear();
    }
    return retVal;
  };

  render() {
    const restrictionDate = this.getRestrictionDate();
    return (
      <>
        <DashboardSection title="Employment Certification">
          <LwoProgress removeContainer={true} />
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-12">
                  <CardTitle>
                    Complete Required Employment Certification(s)
                    <StyledButton brand="primary" onClick={this.handleWhatHappenStepModal}>
                      <PaddedIcon icon={faQuestionCircle} fixedWidth />
                      What happens in this step?
                    </StyledButton>
                  </CardTitle>
                  <CardText>
                    <p>
                      You know how many additional qualified payments you could receive. In order to
                      make that happen, we now need to make sure all the documentation gets done
                      correctly and quickly. Completing this step will certify your employment and
                      previous loan payments. Assure&#8480; will generate forms, manage submission
                      to the lender and then enable the review process.
                    </p>
                    <p>
                      <div className="row">
                        <div className="col-4">
                          <Button
                            brand={this.getButtonBrand(606)}
                            onClick={() => this.handleStepClick(1)}
                          >
                            Generate
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button
                            brand={this.getButtonBrand(607)}
                            onClick={() => this.handleStepClick(2)}
                          >
                            Submit
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button
                            brand={this.getButtonBrand(608)}
                            onClick={() => this.handleStepClick(3)}
                          >
                            Review
                          </Button>
                        </div>
                      </div>
                    </p>
                  </CardText>
                </div>
              </div>
            </CardBody>
          </Card>
          <Modal isOpen={this.state.openWhatHappenStepModal} fullWidth={true}>
            <Card>
              <CardBody>
                <CardText>
                  <CardTitle>What happens in this step?</CardTitle>
                  <AlignTextLeft>
                    <FlexRow>
                      <StyledLede>
                        This step actually includes three separate steps:
                        <ol>
                          <li>
                            First, Fiducius correctly generates all the required certification forms
                            for the employers you identified.
                          </li>
                          <li>
                            Next, you have the forms signed by the employers and then upload them to
                            your MyFiducius dashboard so we can manage their submission.
                          </li>
                          <li>
                            Lastly, we wait while the forms are reviewed and approved. This step
                            won't be finished until you've received both the PSLF Application
                            Results and the new Qualifying Payments letters, and you let us know you
                            received them.
                          </li>
                        </ol>
                      </StyledLede>
                    </FlexRow>
                  </AlignTextLeft>
                </CardText>
              </CardBody>
              <CardFooter>
                <Button brand="lowContrast" onClick={this.handleWhatHappenStepModal}>
                  Close
                </Button>
              </CardFooter>
            </Card>
          </Modal>
        </DashboardSection>
        {this.state.showStep1 && (
          <div id="step1">
            <DashboardSection title="Generate your Employment Certification(s)">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <StepDisplay
                        title={'Step 1 - Generate and Download Employment Certification form(s)'}
                      >
                        <ul>
                          <li>
                            Please click the button below to generate your Employment Certification
                            form(s).
                          </li>
                          <li>
                            After clicking the button, you will be taken to your My Documents page
                            where you can download the file.
                          </li>
                          <li>
                            Once you've downloaded the document, you can continue to follow the
                            steps on this page.
                          </li>
                        </ul>
                      </StepDisplay>
                    </div>
                  </div>
                  <LwoConfirmation stepId={606} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep2 && (
          <div id="step2">
            <DashboardSection title="Submit your Employment Certification(s)">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <StepDisplay
                        title={'Step 1 – Complete and send Employment Certification form(s)'}
                      >
                        <ul>
                          <li>
                            Print the Employment Certification form(s) found in the My Documents
                            section of your MyFiducius portal and hand-sign page one.
                          </li>
                          <li>Your employer must complete and sign the second page.</li>
                          <li>
                            Please verify your Social Security Number is present on the first two
                            pages of your employment certification(s) prior to submitting it.
                          </li>
                          <li>
                            Questions 1 through 9 <strong>must</strong> be completed. Please read
                            the answer to question 9 to determine if succeeding questions require an
                            answer.
                          </li>
                        </ul>
                      </StepDisplay>
                      <StepDisplay title={'Step 2 – Upload the form(s) to your Fiducius account'}>
                        <ul>
                          <li>
                            Upload the form under <strong>My Documents</strong>.
                          </li>
                        </ul>
                      </StepDisplay>
                    </div>
                  </div>
                  <LwoConfirmation stepId={607} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep3 && (
          <div id="step3">
            <DashboardSection title="Check on your Employment Certification(s)">
              <Card overlayCard={restrictionDate !== undefined}>
                <CardBody overlayCard hideOverlay={restrictionDate === undefined}>
                  {restrictionDate !== undefined && (
                    <>
                      <CardTitle overlayCard>Wait for PSLF Application Results letter</CardTitle>
                      <CardText overlayCard>
                        <p>
                          Based on the average processing time for this document, it will be
                          processed by your loan servicer on {restrictionDate} at the earliest*. You
                          will not be able to confirm completion of this step until that date.
                        </p>
                        <p>
                          *Please note: Your document is not guaranteed to be processed by this
                          date. It is important to follow your steps closely.
                        </p>
                      </CardText>
                    </>
                  )}
                </CardBody>
                <CardBody disableChildren={restrictionDate !== undefined}>
                  <div className="row">
                    <div className="col-12">
                      <StepDisplay
                        title={'Step 1 – Wait for PSLF Application Results from MOHELA.'}
                      >
                        <ul>
                          <li>
                            The PSLF Application Results states your form was accepted, while the
                            Qualifying Payment update letter states your number of qualifying
                            payments towards the Public Service Loan Forgiveness program as of the
                            date your employer signed your employment certification form.
                          </li>
                          <li>
                            These typically arrive <strong>one to two months</strong> after the form
                            is submitted.{' '}
                          </li>
                        </ul>
                        <strong>
                          Do not confirm this step is finished until you have received both the PSLF
                          Application Results and Qualifying Payment update letters.
                        </strong>
                      </StepDisplay>
                    </div>
                  </div>
                  <LwoConfirmation stepId={608} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(LwoEmploymentCertification));
