import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'moment';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Header5,
} from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';
import DocsNeededForRSUpload from '../components/docs-upload-needed';

import { renewalResultsLoadResource } from '../../../renewal-results';

class Recertification extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    permissions: t.object.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  render() {
    const { data, request, permissions } = this.props;
    return (
      <div>
        <ForgivenessProgress />
        {data.recertificationSubmitted === false && (
          <DashboardSection>
            <Card>
              <AsyncLoader request={request}>
                <Card>
                  <CardBody>
                    <CardTitle>Recertification Submission Date Set</CardTitle>
                    <CardText>
                      <Header5>
                        <p>
                          Your recertification request will be submitted to your loan servicer
                          within 3 business days of{' '}
                          {Moment(data.submissionToBeCompletedDate).format('MMMM Do')}.
                        </p>
                        <p>
                          <DocsNeededForRSUpload documentButton={true} />
                        </p>
                      </Header5>
                    </CardText>
                  </CardBody>
                </Card>
              </AsyncLoader>
            </Card>
          </DashboardSection>
        )}

        {data.recertificationSubmitted && (
          <DashboardSection>
            <Card>
              <AsyncLoader request={request}>
                <Card>
                  <CardBody>
                    <CardTitle>Recertification Submitted</CardTitle>
                    <CardText>
                      <Header5>
                        <p>
                          Your recertification request was submitted on{' '}
                          {Moment(data.recertificationSubmittedDate).format('MMMM Do')}.
                        </p>
                        <p>
                          At this time, we are tracking the status of the recertification request at
                          your loan servicer and will notify you of any updates.
                        </p>
                        <p>
                          We are also tracking your Employment Certification form(s) to ensure your
                          employment is verified and your loan payments, from this past year, are
                          certified for your loan forgiveness.
                        </p>
                        <ul>
                          <li>
                            Please forward any information you receive from your loan servicer.
                          </li>

                          {permissions.showContribution && (
                            <li>
                              If your studdent loan payment is greater than your employer's
                              contribution, your portion of the payment will be automatically
                              updated as a payroll deduction to ensure that the full payment is met
                              each month.
                            </li>
                          )}
                        </ul>
                        <p></p>
                        <p></p>
                        <p>
                          <DocsNeededForRSUpload documentButton={true} />
                        </p>
                      </Header5>
                    </CardText>
                  </CardBody>
                </Card>
              </AsyncLoader>
            </Card>
          </DashboardSection>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: state.renewalResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(renewalResultsLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Recertification));
