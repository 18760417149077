import actions from './actions';
import { getApiSingle, resourceOperationsGenerator } from '../../../utils';
import { getToken } from '../../../auth';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/benefit-modeler',
  resourceType: 'benefit-modeler',
};

const operations = resourceOperationsGenerator(
  'modeler',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  modelerLoadResource,
  modelerClearForm,
  modelerUpdateResource,
  modelerHandleFormChange,
  modelerCopyResourceToForm,
  modelerCreateResource,
} = operations;

const getSingleMessage = (id) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  let data = null;
  try {
    data = await getApiSingle(getToken(state), '/internal-message/' + id);
  } catch (error) {}
  return data;
};

export {
  modelerLoadResource,
  modelerClearForm,
  modelerUpdateResource,
  modelerHandleFormChange,
  modelerCreateResource,
  modelerCopyResourceToForm,
  getSingleMessage,
};

export default operations;
