import React from 'react';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import {
  Button,
  Form,
  InputAddon,
  InputCheckbox,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Link,
} from 'fiducius-ui';

import { useFocusOnMount } from '../../utils';
import RememberMe from '../styles/remember-me';

const SignInForm = ({ handleChange, onSubmit, rememberMe = false, ...props }) => {
  const firstInput = useFocusOnMount();
  return (
    <Form id="signInForm" handleChange={handleChange} onSubmit={onSubmit} {...props}>
      <InputWrapper>
        <InputLabel>Email</InputLabel>
        <InputGroup>
          <InputAddon>
            <FontAwesomeIcon icon={faUser} fixedWidth />
          </InputAddon>
          <InputText
            inputRef={firstInput}
            name="username"
            autoComplete="email"
            placeholder="Email"
          />
        </InputGroup>
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Password</InputLabel>
        <InputGroup>
          <InputAddon>
            <FontAwesomeIcon icon={faLock} fixedWidth />
          </InputAddon>
          <InputText
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
          />
        </InputGroup>
      </InputWrapper>
      <RememberMe>
        <InputCheckbox name="rememberMe" label="Remember me" defaultChecked={rememberMe} />
        <Link to="/password-reset">Forgot password?</Link>
      </RememberMe>
      <Button brand="primary" type="submit" onClick={onSubmit}>
        Login
      </Button>
    </Form>
  );
};

SignInForm.propTypes = {
  handleChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
  rememberMe: t.bool,
};

export default SignInForm;
