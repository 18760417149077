import React from 'react';
import t from 'prop-types';
import { Alert } from 'fiducius-ui';

import { getApiSingle } from '../../utils';

class CurrentEventsBanner extends React.Component {
  static propTypes = {
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      alertText: undefined,
    }; // must be initialized for data handling
  }

  componentDidMount() {
    const pathName = window.location.pathname;
    let AlertText = '';
    getApiSingle(null, '/message/' + pathName).then((data) => {
      if (data.messages !== null) {
        const alertMessage = data.messages.find((a) => a.messageType === 'Dashboard_Alert');
        if (alertMessage !== null) {
          AlertText = alertMessage.messageContent;
        }
      }
      if (AlertText.length > 1) {
        this.setState({
          alertText: AlertText,
        });
      }
    });
  }

  render() {
    if (this.state.alertText !== undefined) {
      return (
        <Alert>
          <div dangerouslySetInnerHTML={{ __html: this.state.alertText }}></div>
        </Alert>
      );
    } else {
      return <></>;
    }
  }
}
export default CurrentEventsBanner;
