import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { Button, Card, CardBody, CardFooter, CardTitle, CardText, Modal } from 'fiducius-ui';

const StyledCardText = styled(CardText)`
  text-align: left;
`;

class LwoOverviewModals extends React.Component {
  static propTypes = {
    modalOption: t.string.isRequired,
    resetModalOption: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCalc: false,
      showConfirm: false,
      aModalIsOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.setState({
      showCalc: false,
      showConfirm: false,
      aModalIsOpen: false,
    });
    this.props.resetModalOption();
  };

  componentDidUpdate() {
    const { modalOption } = this.props;
    if (this.state.aModalIsOpen === false && modalOption !== null) {
      switch (modalOption) {
        case 'qpCalc':
          this.setState({ ...this.state, showCalc: true, showConfirm: false, aModalIsOpen: true });
          break;
        case 'qpConfirm':
          this.setState({ ...this.state, showConfirm: true, showCalc: false, aModalIsOpen: true });
          break;
        default:
          this.setState({
            ...this.state,
            showBorrow: false,
            showTaxes: false,
            aModalIsOpen: false,
          });
          break;
      }
    }
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.showCalc} fullWidth={true}>
          <Card>
            <CardBody>
              <CardTitle>How are the estimated qualifying payments calculated? </CardTitle>
              <StyledCardText>
                <p>
                  Our estimate is conservative because it will take into account factors such as
                  deferments and forbearance. Note: If any payments were missed or payments were not
                  made on time they may not be considered qualifying payments by the Department of
                  Education and would reduce the number of additional qualified payment estimated by
                  Fiducius.
                </p>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={this.handleClick}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
        <Modal isOpen={this.state.showConfirm}>
          <Card>
            <CardBody>
              <CardTitle>How will my additional qualifying payments be confirmed?</CardTitle>
              <StyledCardText>
                <p>
                  Once your case has been reviewed by the Department of Education, you will be
                  notified of your confirmed number of additional qualifying payments. If you're
                  using Assure&#8480;, at this point you will provide your updated NSLDS form, which
                  will reflect the approved number of additional payments. Note: Given the number of
                  borrowers in LWO, it's unclear how long the case review will take, but it could be
                  anywhere from weeks to several months.
                </p>
              </StyledCardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={this.handleClick}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

export default LwoOverviewModals;
