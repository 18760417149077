import styled from 'styled-components';

const FormSwitcher = styled.div`
  width: 100%;
  text-align: center;
  height: 1em;
  margin-top: 0.5em;
  margin-bottom: 1em;
`;

export default FormSwitcher;
