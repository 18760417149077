import styled from 'styled-components';

const FullscreenContainer = styled.div`
  display: block;
  justify-content: center;
  margin-top: 6rem;
  min-height: 100vh;
  & > .row {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
`;

//display: block; - could possibly fix overflow doc sign documents.
export default FullscreenContainer;
