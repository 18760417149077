import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Badge, Button, Card, CardBody, CardText, CardTitle } from 'fiducius-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faExclamationDark } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import {
  getPastYearSavings,
  getCurrentYearSavings,
  getSAVEPlanSavings,
  getISLSIDRPayment,
} from '../../benefits/forgiveness/redux/selectors';
import { formatAsMoney } from '../../utils';

import { paymentSetPaymentType } from '../redux/operations';

const PayNow = ({
  history,
  setPaymentType,
  payNowType,
  pastYearSavings,
  currentYearSavings,
  saveProgramSavings,
  ISLSIDRPayment,
}) => {
  const handleClick = async () => {
    await setPaymentType();
    history.push('/payment');
  };

  const PaddedBadge = styled(Badge)`
    margin-left: 0.45em;
    font-size: 0.45em;
    vertical-align: middle;
  `;

  const showSavingsText = pastYearSavings > 0 && currentYearSavings > 0;
  const showSAVESavingsText = saveProgramSavings > 0;
  const showIncreaseText = ISLSIDRPayment > 0;
  let savingsAmountText =
    'We anticipate you could save approximately ' +
    formatAsMoney(saveProgramSavings) +
    ' under the new plan.';

  return (
    <Card brand="success">
      <CardBody>
        <CardTitle>
          Make a Payment
          {payNowType === 'renewal' && (
            <PaddedBadge brand="primary">
              <FontAwesomeIcon icon={faExclamationDark} />
            </PaddedBadge>
          )}
        </CardTitle>
        <CardText>
          <p>
            This is for Fiducius fees only. Loan payments must be made directly to your loan
            servicer.
          </p>
          {payNowType === 'renewal' && showSavingsText && !showSAVESavingsText && (
            <p>
              Fiducius assistance helped you with an estimated savings of approximately{' '}
              {formatAsMoney(pastYearSavings)} this past year and we anticipate you'll save{' '}
              {formatAsMoney(currentYearSavings)} this year. To continue saving on your student
              loans with Fiducius, you need to complete your yearly renewal.
            </p>
          )}
          {payNowType === 'renewal' && showSavingsText && showSAVESavingsText && (
            <p>
              Fiducius assistance helped you with an estimated savings of approximately{' '}
              {formatAsMoney(pastYearSavings)} this past year and we anticipate you'll save{' '}
              {formatAsMoney(currentYearSavings)} this year. And with the recent announcement of the
              SAVE Repayment plan, your savings could increase!{' '}
              {showIncreaseText && savingsAmountText} To continue saving on your student loans with
              Fiducius, you need to complete your yearly renewal.
            </p>
          )}
          <Button brand="primary" onClick={handleClick}>
            Pay now
          </Button>
        </CardText>
      </CardBody>
    </Card>
  );
};

PayNow.propTypes = {
  history: t.object,
  setPaymentType: t.func.isRequired,
  payNowType: t.string,
};

const mapStateToProps = (state) => ({
  pastYearSavings: getPastYearSavings(state),
  currentYearSavings: getCurrentYearSavings(state),
  saveProgramSavings: getSAVEPlanSavings(state),
  ISLSIDRPayment: getISLSIDRPayment(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPaymentType: async () => await dispatch(paymentSetPaymentType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PayNow));
