import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { debounce } from '../../utils';
import { Button, Card, CardBody, CardFooter, CardText, Header1, Link } from 'fiducius-ui';

import { FullscreenContainer, withPermissions } from '../../routing';
import { safeAccess } from '../../utils';

import {
  paymentSaveBillingInfo,
  paymentPostPayment,
  paymentDefaultPaymentType,
  paymentLoadInfo,
  paymentResetUpdateRequest,
  paymentCopyResourceToForm,
  paymentTypes,
  paymentClearErrors,
} from '../redux/operations';
import Billing from '../components/billing';
import PaymentResult from '../components/payment-result';
import Pci from '../components/pci';

import { todosLoadCollection } from '../../todos';
import { authLoadPermissions } from '../../auth';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${(p) => (p.double ? 'space-between' : 'flex-end')};
`;

class Payment extends React.Component {
  static propTypes = {
    leavePage: t.func.isRequired,
    paymentType: t.string,
    permissions: t.object.isRequired,
    postPayment: t.func.isRequired,
    postRequest: t.object,
    postUrl: t.string,
    reloadPage: t.func.isRequired,
    saveBillingInfo: t.func.isRequired,
    saveRequest: t.object,
    setUpPaymentType: t.func.isRequired,
    status: t.string,
    token: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      data: {},
      errors: {},
      redirectUrl: null,
    };
  }

  componentDidMount() {
    if (!this.props.paymentType) {
      this.props.setUpPaymentType();
    }
  }

  componentDidUpdate(prevProps) {
    const prevSave = prevProps.saveRequest;
    const currentSave = this.props.saveRequest;
    if (prevSave.isLoading && currentSave.hasFinished && !currentSave.hasFailed) {
      this.setState({ step: 2 });
    }

    const prevPost = prevProps.postRequest;
    const currentPost = this.props.postRequest;
    if (prevPost.isLoading && currentPost.hasFinished) {
      this.setState({ step: 3 });
    }
  }

  resetTransaction = () => {
    this.props.reloadPage();
    this.setState({ step: 1 });
  };
  nextStep = () => this.props.saveBillingInfo();
  prevStep = () => this.setState({ step: 1 });
  submit = () => this.props.postPayment(this.state.data, this.props.postUrl);
  continueToBenefit = () => {
    const { permissions, paymentType, leavePage } = this.props;
    let redirectUrl = '/dashboard';
    if (
      paymentType !== paymentTypes.NOW &&
      paymentType !== paymentTypes.LWO &&
      paymentType !== paymentTypes.SERVICE
    ) {
      if (permissions.inForgiveness) {
        redirectUrl = '/benefits/forgiveness/overview';
      }
    } else if (paymentType === paymentTypes.LWO) {
      redirectUrl = '/benefits/limited-waiver-opportunity/employment-certification/step-1';
    }
    leavePage().then(this.setState({ ...this.state, redirectUrl: redirectUrl }));
  };

  handlePciChange = debounce((formState, id, attributes) => {
    Object.keys(formState).forEach((k) => (formState[k] = formState[k].replace(/(_| )/g, '')));
    this.setState({ ...this.state, data: { ...this.state.data, ...formState } });
  }, 250);

  render() {
    const { saveRequest } = this.props;
    if (this.state.redirectUrl !== null) {
      return <Redirect to={this.state.redirectUrl} />;
    }

    return (
      <FullscreenContainer className="container">
        <div className="row">
          <div className="col-12 col-sm-10 col-lg-8">
            <Card>
              <CardBody>
                <Header1>Make a Payment to Fiducius</Header1>
                <CardText>
                  {/*
                    TODO: Should we keep the payment plan tiles on the page when they're entering
                    PCI or create a "confirm" page?
                  */}
                  {this.state.step === 1 && <Billing paymentType={this.props.paymentType} />}
                  {this.state.step === 2 && (
                    <Pci
                      data={this.state.data}
                      errors={this.state.errors}
                      handleChange={this.handlePciChange}
                    />
                  )}
                  {this.state.step === 3 && <PaymentResult token={this.props.token} />}
                </CardText>
              </CardBody>
              <CardBody>
                <ButtonWrapper double={this.state.step === 2}>
                  {saveRequest.hasFailed && (
                    <Button brand="primary" onClick={this.resetTransaction}>
                      Retry Transaction
                    </Button>
                  )}
                  {!saveRequest.hasFailed && this.state.step === 1 && (
                    <Button brand="primary" onClick={this.nextStep}>
                      Continue
                    </Button>
                  )}
                  {!saveRequest.hasFailed && this.state.step === 2 && (
                    <>
                      <Button brand="lowContrast" onClick={this.prevStep}>
                        Back
                      </Button>
                      <Button brand="primary" onClick={this.submit}>
                        Submit
                      </Button>
                    </>
                  )}
                  {!saveRequest.hasFailed &&
                    this.state.step === 3 &&
                    this.props.status === 'success' && (
                      <>
                        <Button brand="primary" onClick={this.continueToBenefit}>
                          Continue
                        </Button>
                      </>
                    )}
                </ButtonWrapper>
              </CardBody>
              <CardFooter>
                <Link to="/dashboard">Return home</Link>
              </CardFooter>
            </Card>
          </div>
        </div>
      </FullscreenContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const paymentType = safeAccess(state, 'payment.cache.paymentType', '');
  const postUrl = safeAccess(state, `payment.cache[${paymentType}].pageTwoPostUrl`, '');
  const matches = postUrl && postUrl.split('/');
  const token = matches && matches[matches.length - 1];

  return {
    paymentType: paymentType,
    postUrl: postUrl,
    postRequest: state.payment.requests.createResource,
    saveRequest: state.payment.requests.updateResource,
    status: safeAccess(state, 'payment.cache.paymentStatus', ''),
    token: token,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setUpPaymentType: () => dispatch(paymentDefaultPaymentType()),
  postPayment: (data, url) => {
    dispatch(paymentPostPayment(data, url));
  },
  reloadPage: async () => {
    await dispatch(paymentResetUpdateRequest());
    await dispatch(paymentClearErrors());
  },
  leavePage: async () => {
    await dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  saveBillingInfo: () => dispatch(paymentSaveBillingInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Payment));
