import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardText,
  DashboardSection,
  Button,
  Header2,
  Header4,
  InputWrapper,
  ProgressBar,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { safeAccess, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

const StyleBar = styled(ProgressBar)`
  margin-bottom: 2rem;
`;

class FreshStartProgress extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
    removeContainer: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', fullServiceSteps: 9, currentURI: '' };
  }

  componentDidMount() {
    this.setState({ ...this.state, currentURI: window.location.pathname });
  }

  componentDidUpdate() {}

  goToCurrentStep = () => {
    const { permissions, todos } = this.props;
    let retVal = '/dashboard';
    if (permissions.freshStartSteps !== undefined && permissions.freshStartSteps !== null) {
      let freshStartStep = permissions.freshStartSteps.find((a) => a.stepCurrent);
      if (
        freshStartStep !== undefined &&
        freshStartStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (freshStartStep.id == 'CompleteFreshStart') {
          retVal = '/benefits/freshstart/complete';
        }

        const currentToDo = todos.find(
          (a) => a.id === 900 || a.id === 910 || a.id === 920 || a.id === 930
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 900:
              retVal = '/benefits/freshstart/contact';
              break;
            case 910:
              retVal = '/benefits/freshstart/awaiting';
              break;
            case 920:
              retVal = '/benefits/freshstart/nslds';
              break;
            case 930:
              retVal = '/benefits/freshstart/complete';
              break;
          }
        }
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  getCurrentStepText = () => {
    const { permissions, todos } = this.props;
    const freshStartSteps = permissions.freshStartSteps.filter((a) => a.stepCurrent);
    let freshStartStep = permissions.freshStartSteps.find((a) => a.stepCurrent);
    if (
      freshStartSteps !== undefined &&
      freshStartSteps !== null &&
      freshStartSteps.length > 1 &&
      freshStartSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      freshStartStep = freshStartSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }

    let retVal = '';
    if (
      freshStartStep !== undefined &&
      freshStartStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (
        freshStartStep.id === 'ContactFreshStart' ||
        freshStartStep.id === 'AwaitingFreshStart' ||
        freshStartStep.id === 'NSLDSUploadFreshStart' ||
        freshStartStep.id === 'CompleteFreshStart'
      ) {
        retVal = freshStartStep.stepText;
        const currentToDo = todos.find(
          (a) =>
            (freshStartStep.id === 'ContactFreshStart' && a.id === 900) ||
            (freshStartStep.id === 'AwaitingFreshStart' && a.id === 910) ||
            (freshStartStep.id === 'NSLDSUploadFreshStart' && a.id === 920) ||
            (freshStartStep.id === 'CompleteFreshStart' && a.id === 930)
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 900:
              retVal += '';
              break;
            case 910:
              retVal += '';
              break;
            case 920:
              retVal += '';
              break;
            case 930:
              retVal += '';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = freshStartStep.stepText;
      }
    }

    return retVal;
  };

  getTotalSteps = () => {
    const { permissions } = this.props;
    const freshStartSteps = permissions.freshStartSteps.filter(
      (a) => a.id !== 'OverviewFreshStart'
    );
    return freshStartSteps.length;
  };

  getCompletedSteps = (normalized) => {
    const { permissions } = this.props;
    const freshStartSteps = permissions.freshStartSteps.filter(
      (a) => a.id !== 'OverviewFreshStart'
    );
    const completedSteps = freshStartSteps.filter((a) => a.stepComplete);
    if (normalized) {
      return 100.0 * (completedSteps.length / freshStartSteps.length);
    } else {
      return Math.min(completedSteps.length + 1, freshStartSteps.length);
    }
  };

  getCurrentStep = () => {
    const { permissions } = this.props;
    const freshStartSteps = permissions.freshStartSteps.filter(
      (a) => a.id !== 'OverviewFreshStart'
    );
    const currentSteps = permissions.freshStartSteps.filter(
      (a) => a.id !== 'OverviewFreshStart' && a.stepCurrent
    );
    const allStepsCompleted = permissions.freshStartSteps.filter(
      (a) => a.id === 'CompleteFreshStart' && a.stepComplete
    );

    if (allStepsCompleted.length > 0) {
      return 0;
    } else {
      return 100.0 * (currentSteps.length / freshStartSteps.length);
    }
  };

  getCurrentStepPath = () => {
    const { permissions } = this.props;

    const currentSteps = permissions.freshStartSteps.filter(
      (a) => a.id !== 'OverviewFreshStart' && a.stepCurrent
    );

    let stepPaths = [];
    currentSteps.forEach((step) => {
      stepPaths.push(step.stepPath);
    });
    //return an array since we can have multiple current steps.
    return stepPaths;
  };

  onCurrentStep = () => {
    let steps = this.getCurrentStepPath();
    var retVal = false;
    steps.forEach((step) => {
      if (retVal === false) {
        retVal = this.state.currentURI.includes(step);
      }
    });
    return retVal;
  };

  getLockedSteps = () => {
    return 100.0 - this.getCompletedSteps(true) - this.getCurrentStep();
  };

  render() {
    const { permissions, removeContainer } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      if (
        safeAccess(permissions, 'freshStartSteps') &&
        safeAccess(permissions, 'freshStartSteps').length > 1
      ) {
        return (
          <>
            {removeContainer && (
              <Card>
                <CardBody>
                  <CardText>
                    <Header2>
                      Your Fresh Start Progress : {this.getCompletedSteps(false)} /{' '}
                      {this.getTotalSteps()}
                    </Header2>

                    <StyleBar
                      categories={[
                        {
                          brand: 'success',
                          name: 'success',
                          title: 'Completed',
                          value: this.getCompletedSteps(true),
                        },

                        {
                          brand: 'info',
                          name: 'info',
                          title: 'Current',
                          value: this.getCurrentStep(),
                        },
                        {
                          brand: 'warning',
                          name: 'warning',
                          title: 'Locked',
                          value: this.getLockedSteps(),
                        },
                      ]}
                    />
                    <div className="row col-12">
                      <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                      </div>
                      <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <InputWrapper>
                          {!this.onCurrentStep() && (
                            <Button brand="primary" onClick={this.goToCurrentStep}>
                              {'Go to current step'}
                            </Button>
                          )}
                        </InputWrapper>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {!removeContainer && (
              <DashboardSection>
                <Card>
                  <CardBody>
                    <CardText>
                      <Header2>
                        Your Fresh Start Progress : {this.getCompletedSteps(false)} /{' '}
                        {this.getTotalSteps()}
                      </Header2>
                      <StyleBar
                        categories={[
                          {
                            brand: 'success',
                            name: 'success',
                            title: 'Completed',
                            value: this.getCompletedSteps(true),
                          },

                          {
                            brand: 'info',
                            name: 'info',
                            title: 'Current',
                            value: this.getCurrentStep(),
                          },
                          {
                            brand: 'warning',
                            name: 'warning',
                            title: 'Locked',
                            value: this.getLockedSteps(),
                          },
                        ]}
                      />
                      <div className="row col-12">
                        <div className="col-8">
                          <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                        </div>
                        <div className="col-4">
                          <InputWrapper>
                            {!this.onCurrentStep() && (
                              <Button brand="info" onClick={this.goToCurrentStep}>
                                {'Go to current step'}
                              </Button>
                            )}
                          </InputWrapper>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </DashboardSection>
            )}
          </>
        );
      } else {
        return <></>;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FreshStartProgress));
