import { FormValidator } from '../../../utils';

export class QuestionnaireBtsValidator extends FormValidator {
  continuedEducation(validator, state) {
    validator.isRequired();
  }

  borrowedAddFedLoans(validator, state) {
    validator.isRequired();
  }

  graduateDate(validator, state) {
    validator.isRequired();
    validator.validDate();
  }

  graduationIncome(validator, state) {
    validator.isRequired();
    validator.validNumber();
  }

  loanAmtBorrow(validator, state) {
    if (state.borrowedAddFedLoans === 'true') {
      validator.isRequired();
      validator.validNumber();
    }
  }
}
export default QuestionnaireBtsValidator;
