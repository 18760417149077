import { FormValidator } from '../../utils';

export class AccountValidator extends FormValidator {
  loanDescription(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  accountNumber(validator, state) {
    if (state.loanTypeCode === 'RMBRS') {
      validator.isRequired();
      validator.hasMaxLength(30);
    } else {
      validator.hasMaxLength(30);
    }
  }

  loanServicerId(validator) {
    validator.isRequired();
  }

  loanTypeCode(validator, state) {
    validator.isRequired();
    if (state.error.length > 0) {
      validator.addCustomMessage(state.error);
    }
  }

  initialLoanBalance(validator, state) {
    if (state.loanTypeCode !== '529PN' && state.loanTypeCode !== 'RMBRS') {
      validator.isRequired();
      validator.isGreaterThan(0);
    }
  }

  currentLoanBalance(validator, state) {
    if (state.loanTypeCode !== '529PN' && state.loanTypeCode !== 'RMBRS') {
      validator.isRequired();
    }
  }

  interestRate(validator, state) {
    validator.isLessThan(100);
    if (state.loanTypeCode !== '529PN' && state.loanTypeCode !== 'RMBRS') {
      validator.isRequired();
      validator.isGreaterThan(0);
    }
  }

  monthlyPayment(validator, state) {
    if (state.loanTypeCode !== '529PN' && state.loanTypeCode !== 'RMBRS') {
      validator.isRequired();
    }
  }

  dueDate(validator, state) {
    if (state.loanTypeCode !== '529PN' && state.loanTypeCode !== 'RMBRS') {
      let today = new Date();
      validator.isRequired();
      validator.isAfterDate(today);
    }
  }

  studentFirstName(validator) {
    // validator.isRequired();
  }

  studentMiddleInitial(validator) {
    // validator.isRequired();
  }

  studentLastName(validator) {
    // validator.isRequired();
  }

  validatedForRelief(validator) {
    // validator.isRequired();
  }
}
