import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  BrandColor,
  Header2,
  Header5,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { MyReferences } from '../../../references';
import { withPermissions } from '../../../routing';
import { convertIsoToSlash, formatAsMoney } from '../../../utils';

import RepaymentPie from '../components/repayment-pie';
import TotalRepayment from '../components/total-repayment';
import PaymentCompare from '../components/payment-compare';
import TaxCompare from '../components/tax-compare';
import ForgivenessProgress from '../components/forgiveness-progress';
import { forgivenessLoadResource } from '../redux/operations';
import {
  getForgivenessData,
  getMonthlyPayment,
  getMonthlySavings,
  getNewPayment,
  getOldPayment,
  getTotalForgiveness,
  getTotalIdrRepayment,
  getTotalStandardRepayment,
  getRepaymentTerm,
  getShowTaxCompare,
} from '../redux/selectors';

const MyForgiveness = withPermissions(
  ({
    data = {},
    id,
    load,
    monthlyPayment,
    monthlySavings,
    newPayment,
    oldPayment,
    permissions,
    request,
    totalForgiveness,
    totalIdrRepayment,
    totalStandardRepayment,
    repaymentTerm,
    showTaxCompare,
  }) => {
    useEffect(() => {
      load(id);
    }, []);

    return (
      <DashboardSection title="My Loan Forgiveness">
        <Header2>
          Lower your payments and get forgiveness through Federal programs, including Public Service
          Loan Forgiveness (PSLF)
        </Header2>
        {permissions.isPreSale && (
          <Header5>All data shown contains estimated values at this point in the process.</Header5>
        )}
        <AsyncLoader request={request}>
          {!permissions.btsQuestionnaireCompleted && <ForgivenessProgress />}
          <div className="row">
            <div className="col-12 col-lg-4">
              <Card>
                <CardBody>
                  <CardTitle>Plan Details</CardTitle>
                  <CardText>
                    <dl>
                      <dt>Qualifying Plan</dt>
                      <dd>{data.plan || '—'}</dd>
                      <dt>Status</dt>
                      <dd>{data.status || '—'}</dd>
                      <dt>IDR Monthly Payment</dt>
                      <dd>
                        {monthlyPayment < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlyPayment)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Monthly Savings</dt>
                      <dd>
                        {monthlySavings < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlySavings)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Total Standard Repayment</dt>
                      <dd>
                        {totalStandardRepayment < 0 ? '—' : formatAsMoney(totalStandardRepayment)}
                      </dd>
                      <dt>Total IDR Repayment</dt>
                      <dd>{totalIdrRepayment < 0 ? '—' : formatAsMoney(totalIdrRepayment)}</dd>
                      <dt>Total Forgiveness</dt>
                      <dd>
                        {totalForgiveness < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(totalForgiveness)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Next Payment Due Date</dt>
                      <dd>{convertIsoToSlash(data.nextPayment || '—')}</dd>
                      {data.btsReviewDate !== null && (
                        <>
                          <dt>StudentFORGIVE Snapshot Date</dt>
                          <dd>{convertIsoToSlash(data.btsReviewDate || '—')}</dd>
                        </>
                      )}
                      {data.btsReviewDate === null && (
                        <>
                          <dt>Renewal Date</dt>
                          <dd>{convertIsoToSlash(data.recertDate || '—')}</dd>
                        </>
                      )}
                    </dl>
                  </CardText>
                </CardBody>
              </Card>
            </div>
            <div className="col-12 col-lg-8">
              <TotalRepayment
                totalStd={totalStandardRepayment}
                totalIdr={totalIdrRepayment}
                years={repaymentTerm}
              />
            </div>
            <div className="col-12 col-lg-6">
              <PaymentCompare
                oldPayment={oldPayment}
                newPayment={newPayment}
                year={data.year}
                bigTitle={true}
              />
            </div>
            <div className="col-12 col-lg-6">
              <RepaymentPie
                repay={totalIdrRepayment}
                forgive={totalForgiveness}
                cutout={60}
                bigTitle={true}
              />
            </div>
            {permissions.inForgiveness && (
              <div className="col-12 col-lg-6">
                <MyReferences showTitle={true} />
              </div>
            )}
            {permissions.isMarried && showTaxCompare && (
              <div className="col-12 col-lg-6">
                <TaxCompare
                  jointly={
                    data.marriedFilingJointly && data.marriedFilingJointly >= 0
                      ? data.marriedFilingJointly
                      : 0
                  }
                  separately={
                    data.marriedFilingSeparate && data.marriedFilingSeparate >= 0
                      ? data.marriedFilingSeparate
                      : 0
                  }
                  savings={data.taxSavings || 0}
                />
              </div>
            )}
          </div>
        </AsyncLoader>
      </DashboardSection>
    );
  }
);

MyForgiveness.propTypes = {
  data: t.object,
  id: t.string.isRequired,
  load: t.func.isRequired,
  monthlyPayment: t.number,
  monthlySavings: t.number,
  newPayment: t.number,
  oldPayment: t.number,
  request: t.object.isRequired,
  totalForgiveness: t.number,
  totalIdrRepayment: t.number,
  totalStandardRepayment: t.number,
  repaymentTerm: t.number,
};

const mapStateToProps = (state) => ({
  data: getForgivenessData(state),
  id: getUserId(state),
  monthlyPayment: getMonthlyPayment(state),
  monthlySavings: getMonthlySavings(state),
  newPayment: getNewPayment(state),
  oldPayment: getOldPayment(state),
  request: state.forgiveness.requests.loadResource,
  totalForgiveness: getTotalForgiveness(state),
  totalIdrRepayment: getTotalIdrRepayment(state),
  totalStandardRepayment: getTotalStandardRepayment(state),
  repaymentTerm: getRepaymentTerm(state),
  showTaxCompare: getShowTaxCompare(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(forgivenessLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyForgiveness);
