import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import t from 'prop-types';
import { Card, CardBody, CardText, CardTitle, CdnImage } from 'fiducius-ui';
import { safeAccess } from '../../../utils';

import { affiliateLoadCollection } from '../redux/operations';

const LinkWrapper = styled.div`
  border-top: 1px solid ${(p) => p.theme.textTertiary};
  padding-top: 1rem;
  text-align: left;

  /*display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  
  & > small {
    padding-left: 0.5rem;
  }*/
`;

const AffiliateLink = styled.a``;

const AffiliateImage = styled(CdnImage)`
  height: 75px;
  max-width: 100%;
`;

class AffiliateTile extends React.Component {
  static propTypes = {
    data: t.array,
    request: t.object,
    getAffiliates: t.func.isRequired,
    theme: t.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getAffiliates();
  }

  getSortedAffiliates() {
    const { data, theme } = this.props;

    let affiliates = [];
    data.forEach((t) => {
      affiliates.push({
        order: t.order,
        data: (
          <LinkWrapper key={t.order}>
            <p>
              <AffiliateLink href={t.affiliateLink}>
                <AffiliateImage
                  cdn={process.env.REACT_APP_CDN_URL}
                  imagePath={
                    'affiliates/' +
                    t.cdnLocation +
                    '/' +
                    (theme !== 'dark' ? t.lightImage : t.darkImage)
                  }
                  alt={t.imageAltText}
                />
              </AffiliateLink>
            </p>
            <p>{t.imageSubText}</p>
          </LinkWrapper>
        ),
      });
    });
    affiliates.sort(function(a, b) {
      return a.order - b.order;
    });
    let processedAffiliates = [];
    affiliates.forEach((t) => processedAffiliates.push(t.data));
    return processedAffiliates;
  }

  render() {
    const { data, theme } = this.props;
    return (
      <Card>
        <CardBody>
          <CardTitle>Affiliates</CardTitle>
          <CardText>
            <p>At Fiducius, we partner with companies to benefit your financial wellness.</p>
            {safeAccess(data, 'length') > 0 ? (
              this.getSortedAffiliates()
            ) : (
              <LinkWrapper>
                <p>
                  <AffiliateLink href="http://www.jdoqocy.com/click-8973232-13149824">
                    <AffiliateImage
                      cdn={process.env.REACT_APP_CDN_URL}
                      imagePath={
                        theme !== 'dark'
                          ? 'affiliates/turbo-tax/turbotax.png'
                          : 'affiliates/turbo-tax/turbotaxWhite.png'
                      }
                      alt="TurboTax - Choose Easy"
                    />
                  </AffiliateLink>
                </p>
                <p>File your taxes with our national partner, TurboTax.</p>
              </LinkWrapper>
            )}
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, requests } = state.affiliate;
  return {
    data: Object.values(cache),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAffiliates: () => dispatch(affiliateLoadCollection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateTile);
