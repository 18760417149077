import actions from './actions';
import { safeAccess } from '../../utils';

const APP_KEY = 'fiducius-app-state';

// Clear the stored state from browser storage if it exists
const persistClearStore = async () => {
  // No try catch, handle failure in loadState operation
  localStorage.removeItem(APP_KEY);
  sessionStorage.removeItem(APP_KEY);
};

// Get the stored state from browser storage if it exists
const persistLoadStore = async () => {
  let store = undefined;
  let localStore = null;
  let sessionStore = null;

  // No try catch, handle failure in loadState operation
  localStore = localStorage.getItem(APP_KEY);
  sessionStore = sessionStorage.getItem(APP_KEY);

  if (!localStore && sessionStore) {
    store = JSON.parse(sessionStore);
  } else if ((localStore && !sessionStore) || (localStore && sessionStore)) {
    // opt for localstore if both are there
    store = JSON.parse(localStore);
  }

  // Something bad happened to get here
  return store;
};

const persistSaveStore = async (fullState) => {
  const selectedState = {
    auth: { cache: fullState.auth.cache },
    payment: {
      cache: {
        paymentType: safeAccess(fullState, 'payment.cache.paymentType'),
      },
    },
    persist: { cache: fullState.persist.cache },
    // TODO: persist everything we want
  };

  // No try catch, handle failure in saveState operation
  const storageType = fullState.persist.cache.rememberMe ? localStorage : sessionStorage;
  const serializedState = JSON.stringify(selectedState);
  storageType.setItem(APP_KEY, serializedState);
};

// Request to load of the persisted state from browser storage
// Structure as external request b/c we're interacting with the browser API
export const persistLoadState = () => async (dispatch, getState) => {
  try {
    dispatch(actions.persistLoadRequest());

    const loadedState = await persistLoadStore();
    dispatch(actions.persistLoadResponse());

    if (loadedState === undefined) {
      dispatch(actions.persistResetState());
    } else {
      dispatch(actions.persistHydrateState(loadedState));
    }

    if (!getState().persist.cache.initialLoad) {
      dispatch(actions.persistInitialLoad(true));
    }

    dispatch(actions.persistLoadSuccess());
  } catch (error) {
    dispatch(actions.persistLoadFailure(error));
  }
};

// Request to clear the current state tree and persisted browser storage
// Structure as external request b/c we're interacting with the browser API
export const persistClearState = () => async (dispatch) => {
  try {
    dispatch(actions.persistClearRequest());
    await persistClearStore();
    dispatch(actions.persistClearResponse());
    await dispatch(actions.persistResetState());
    dispatch(actions.persistClearSuccess());
  } catch (error) {
    dispatch(actions.persistLoadFailure(error));
  }
};

// Request to save the current state tree to the browser storage
// Structure as external request b/c we're interacting with the browser API
export const persistSaveState = () => async (dispatch, getState) => {
  try {
    dispatch(actions.persistSaveRequest());
    await persistSaveStore(getState());
    dispatch(actions.persistSaveResponse());
    dispatch(actions.persistSaveSuccess());
  } catch (error) {
    dispatch(actions.persistSaveFailure(error));
  }
};

// Set the rememberMe flag
export const persistRememberMe = (rememberMe) => async (dispatch) => {
  dispatch(actions.persistRememberMe(rememberMe));
  await dispatch(persistSaveState());
};

// Set the initial load flag
export const persistInitialLoad = (initialLoad) => async (dispatch) => {
  dispatch(actions.persistInitialLoad(initialLoad));
  await dispatch(persistSaveState());
};

export default {
  persistClearState,
  persistLoadState,
  persistSaveState,
  persistRememberMe,
};
