import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { BrandColor, Header2, Header3, Loader, Button } from 'fiducius-ui';

import { getTuitionInstitutionData } from '../../../benefits/tuition';

import { classCreateResource } from '../redux/operations';

import { todosLoadCollection } from '../../../todos';
import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import { mergeRequestStatuses } from '../../../utils';

const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class TabReview extends React.Component {
  static propTypes = {
    data: t.object,
    institutionData: t.object,
    classSave: t.func.isRequired,
    errors: t.object,
    request: t.object,
    handleChange: t.func,
    addAnotherClass: t.func,
    id: t.string.isRequired,
    brand: t.string,
  };

  async componentDidMount() {
    const { classSave } = this.props;
    await classSave();
  }

  termIdMatchCheck(termIdA, termIdB) {
    let retVal = false;
    if (termIdA !== undefined && termIdA !== null && termIdB !== undefined && termIdB !== null) {
      retVal = parseInt(termIdA) === parseInt(termIdB);
    }
    return retVal;
  }

  getExistingInfo = () => {
    const { data = {}, institutionData } = this.props;

    const institution = institutionData[data.institutionId];
    const termId = Object.keys(institution.terms).find((termId) =>
      this.termIdMatchCheck(institution.terms[termId].id, data.termId)
    );
    const term = institution.terms[termId];
    const classes = [];
    let maxClassId = 0;
    if (term.classes.length > 0) {
      Object.keys(term.classes).forEach((classId) => {
        maxClassId = classId > maxClassId ? classId : maxClassId;
      });
      Object.keys(term.classes).forEach((classId) => {
        if (classId === maxClassId || term.classes.length === 1) {
          classes.push(
            <li>
              <BrandColor brand="success">
                {term.classes[classId].className}{' '}
                {!isNaN(term.classes[classId].hours) && (
                  <>( {term.classes[classId].hours} Credit Hours )</>
                )}
              </BrandColor>
            </li>
          );
        } else {
          classes.push(
            <li>
              {term.classes[classId].className}{' '}
              {!isNaN(term.classes[classId].hours) && (
                <>( {term.classes[classId].hours} Credit Hours )</>
              )}
            </li>
          );
        }
      });
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Header3>
              {institution.institutionName} - {term.termName}
            </Header3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ul>{classes}</ul>
          </div>
        </div>
      </>
    );
  };

  render() {
    const { request, addAnotherClass } = this.props;

    if (request.isLoading) {
      return (
        <Flex>
          <Loader variant="atom" size={3} />
        </Flex>
      );
    } else if (request.hasFailed) {
      return (
        <Flex>
          <Header2>An error has occured.</Header2>
        </Flex>
      );
    } else {
      return (
        <>
          <div className="row">
            <Header2>You've successfully added a class!</Header2>
          </div>
          {this.getExistingInfo()}
          <div className="row">
            <div className="col">
              <StyledButton brand="primary" onClick={() => addAnotherClass()}>
                Add Another Class
              </StyledButton>
            </div>
          </div>
          <div className="row">
            <div className="col">
              If you have added a class incorrectly, it can be edited or deleted on your My Classes
              page.
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { requests, form, errors } = state.tuitionInstitutionClass;

  return {
    institutionData: getTuitionInstitutionData(state),
    data: form,
    errors: errors,
    request: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
      state.tuitionInstitution.requests.loadCollection,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  classSave: async () => {
    await dispatch(classCreateResource());
    await dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabReview);
