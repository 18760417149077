import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  AsyncLoader,
  Form,
  Header4,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Alert,
} from 'fiducius-ui';

import { paymentSetTypeDeposit, paymentTypes } from '../../../payment';
import { debounce, mergeRequestStatuses, safeAccess } from '../../../utils';
import styled from 'styled-components';

import {
  fulfillmentCopyResourceToForm,
  fulfillmentHandleFormChange,
  fulfillmentLoadResource,
  PdfViewer,
  Signature,
} from '../../../fulfillment';
import { agreementUpdateResource } from '../redux/operations';

export const AGREEMENT_DOC_ID = 8;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

class TabAgreement extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    form: t.object,
    loadHtml: t.func.isRequired,
    request: t.object,
    saveContract: t.func.isRequired,
    signRequest: t.object,
    skipSave: t.bool,
    updateForm: t.func.isRequired,
    setPaymentType: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = { hasHtml: false, redirect: false };
  }

  componentDidMount() {
    if (this.props.skipSave) {
      this.setState({ hasHtml: true }, () => this.props.loadHtml());
    } else {
      this.props.saveContract();
    }

    if (this.scrollRef.current) {
      this.scrollRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { request, signRequest } = this.props;
    if (prevProps.request.isLoading && request.hasFinished && !request.hasFailed) {
      if (!this.state.hasHtml) {
        this.setState({ hasHtml: true }, () => this.props.loadHtml());
      }
    }

    if (prevProps.signRequest.isLoading && signRequest.hasFinished && !signRequest.hasFailed) {
      this.setState({ redirect: true });
    }
  }

  handleChange = debounce((formState, id, attribute) => {
    const { form, updateForm } = this.props;
    formState.docId = AGREEMENT_DOC_ID;
    const newData = { ...form, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  handleClientSignature = (data) => {
    this.props.updateForm({ ...this.props.form, signaturePadClient: data }, ['signaturePadClient']);
  };

  handleSpouseSignature = (data) => {
    this.props.updateForm({ ...this.props.form, signaturePadSpouse: data }, ['signaturePadSpouse']);
  };

  render() {
    const { data = {}, errors = {}, form, request } = this.props;
    if (this.state.redirect) {
      if (data.paymentType === paymentTypes.DEPOSIT) {
        return <Redirect to="/payment" />;
      } else {
        return <Redirect to="/dashboard" />;
      }
    }

    return (
      <>
        <AsyncLoader request={request}>
          <PdfViewer base64={safeAccess(data, 'fileData', '')} isFulfillment={true} />
        </AsyncLoader>
        <Form id="signatureForm" handleChange={this.handleChange} defaults={form}>
          {safeAccess(errors, 'na') && (
            <Alert key={'invalid-info'} brand="danger">
              {errors.na.detail}
            </Alert>
          )}
          <Header4>
            For your security, please input the correct information below to sign the application.
          </Header4>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.firstName}>
                <InputLabel2>First Name</InputLabel2>
                <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
                <InputGroup>
                  <InputText name="firstName" />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.lastName}>
                <InputLabel2>Last Name</InputLabel2>
                <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
                <InputGroup>
                  <InputText name="lastName" />
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.email}>
                <InputLabel2>Email</InputLabel2>
                <InputError>{safeAccess(errors, 'email.detail')}</InputError>
                <InputGroup>
                  <InputText name="email" type="email" />
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.password}>
                <InputLabel2>Password</InputLabel2>
                <InputError>{safeAccess(errors, 'password.detail')}</InputError>
                <InputGroup>
                  <InputText name="password" type="password" />
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
          <Signature handleChange={this.handleClientSignature} label="Your signature" />
          {data.needSpouseSignature && (
            <Signature handleChange={this.handleSpouseSignature} label="Spouse's Signature" />
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.fulfillment.cache[AGREEMENT_DOC_ID],
  form: state.fulfillment.form,
  errors: state.fulfillment.errors,
  request: mergeRequestStatuses([
    state.fulfillment.requests.loadResource,
    state.agreement.requests.updateResource,
  ]), // separate and then componentdidupdate stuff
  signRequest: state.fulfillment.requests.updateResource,
});

const mapDispatchToProps = (dispatch) => ({
  loadHtml: async () => {
    await dispatch(fulfillmentLoadResource(AGREEMENT_DOC_ID));
    await dispatch(fulfillmentCopyResourceToForm(AGREEMENT_DOC_ID));
  },
  saveContract: () => dispatch(agreementUpdateResource(AGREEMENT_DOC_ID)),
  updateForm: (data, attributes) => dispatch(fulfillmentHandleFormChange(data, attributes)),
  setPaymentType: async () => await dispatch(paymentSetTypeDeposit()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabAgreement);
