import styled from 'styled-components';
import { InputWrapper } from 'fiducius-ui';

const RememberMe = styled(InputWrapper)`
  color: ${(p) => (p.invert ? p.theme.white : p.theme.textPrimary)};
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5em;
  margin-top: -0.75em;
`;

export default RememberMe;
