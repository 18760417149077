import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { Form, InputWrapper, InputLabel, InputRadio, Button } from 'fiducius-ui';

import AdjustedHeader from '../styles/adjusted-header';

import { useScrollToTopOnMount } from '../../../utils';

import { EmploymentHistoryTable } from '../../../employment-history';

const StyledButton = styled(Button)`
  margin: 0.5em;
`;
const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const TabEmployment = ({
  data = {},
  errors = {},
  handleChange,
  id,
  employers = {},
  employmentIncorrect,
  showEmployerIncorrect,
}) => {
  useScrollToTopOnMount();

  return (
    <>
      <AdjustedHeader>Review Employment</AdjustedHeader>
      <div className="row">
        <div className="col">
          Please review the employer information you provided to ensure all employers are included
          and the details are correct. Once you sign your agreement, you can no longer change this
          information. If you need to make changes, we'll take you back to the input section now.
        </div>
      </div>

      <EmploymentHistoryTable employers={employers} showEdit={false} />
      <div className="row">
        <div className="col">
          <br></br>
        </div>
      </div>
      <Form id={id} handleChange={handleChange} defaults={data}>
        <InputWrapper>
          <InputLabel2>All my employer information is correct.</InputLabel2>
          <InputRadio name="employersCorrect" value="true" label="Yes" />
          <InputRadio name="employersCorrect" value="false" label="No" />
        </InputWrapper>
        <div className="row">
          <div className="col">
            {showEmployerIncorrect && (
              <StyledButton brand="primary" onClick={() => employmentIncorrect()}>
                Go to Employment Information
              </StyledButton>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

TabEmployment.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
  employers: t.object,
  employmentIncorrect: t.func,
  showEmployerIncorrect: t.bool,
};

export default TabEmployment;
