import types from './types';
import { resourceActionsGenerator } from '../../utils';

const documentsRemoveFromCache = (id) => ({
  type: types.DOCUMENTS_REMOVE_FROM_CACHE,
  id,
});

export default {
  ...resourceActionsGenerator('documents', types),
  documentsRemoveFromCache,
};
