import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';

import ReducedIDRProgress from '../components/reduced-idr-progress';
import { convertIsoToSlash, mergeRequestStatuses } from '../../../utils';
import { withPermissions } from '../../../routing';

const ReducedIDRComplete = ({ request, permissions, renewal }) => {
  const [redirectUri, setRedirectUri] = useState('');

  const hasCompletedStep = () => {
    const completedContact = permissions.reducedIdrSteps.filter(
      (a) => a.id === 'CompleteReducedIDR' && a.stepComplete
    );

    if (completedContact.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <div className="row">
        <div className="col-12 col-lg-12">
          <AsyncLoader request={request}>
            <ReducedIDRProgress />
            <DashboardSection title="Complete">
              <Card>
                <CardBody>
                  <AsyncLoader request={request} loading={<></>}>
                    {!hasCompletedStep() && <p>You haven't reached this step yet.</p>}
                    {hasCompletedStep() && !!!renewal && (
                      <p>
                        Congratulations on completing all the necessary steps to receive the Reduced
                        IDR benefit! To continue with the IDR payment plan, the Department of
                        Education requires annual documentation. We will contact you around{' '}
                        {convertIsoToSlash(permissions.annualRecertDate)} to begin this process. In
                        the meantime, Fiducius is happy to help you explore any additional benefits
                        for which you may be eligible. Please review your To-Do list to access those
                        benefits.
                      </p>
                    )}
                    {hasCompletedStep() && renewal && (
                      <>
                        <p>
                          Congratulations on completing all the necessary steps to receive the
                          Reduced IDR benefit! To continue with the IDR payment plan, the Department
                          of Education requires annual documentation. We will contact you around{' '}
                          {convertIsoToSlash(permissions.annualRecertDate)} to begin this process.
                          In the meantime, Fiducius is happy to help you explore any additional
                          benefits for which you may be eligible. Please review your To-Do list to
                          access those benefits.
                        </p>
                      </>
                    )}
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </AsyncLoader>
        </div>
      </div>
    );
  }
};

ReducedIDRComplete.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ReducedIDRComplete));
