import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  InputRadio,
  InputHelp,
  InputMask,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { safeAccess, stringIsNullOrEmpty, convertIsoToSlash } from '../../utils';
import { Fade } from '../../routing';
import { connect } from 'react-redux';

const TextLeft = styled.div`
  text-align: left;
`;

class EmploymentHistoryForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {}

  getForm() {
    const { data, errors, onChange, onSubmit } = this.props;

    return (
      <Form defaults={data} handleChange={onChange} id={data.id || 'create'} onSubmit={onSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.employerName}>
              <InputLabel htmlFor={'employerName'}>Employer Name</InputLabel>
              <InputError>{safeAccess(errors, 'employerName.detail')}</InputError>
              <InputGroup>
                <InputText name={'employerName'} defaultValue={safeAccess(data, 'employerName')} />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.jobTitle}>
              <InputLabel htmlFor={'jobTitle'}>Job Title</InputLabel>
              <InputError>{safeAccess(errors, 'jobTitle.detail')}</InputError>
              <InputGroup>
                <InputText name={'jobTitle'} defaultValue={data.jobTitle} />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.startDate}>
              <InputLabel htmlFor={'startDate'}>Start Date</InputLabel>
              <InputError>{safeAccess(errors, 'startDate.detail')}</InputError>
              <InputGroup>
                <InputMask
                  defaultValue={convertIsoToSlash(data.startDate)}
                  mask="##/##/####"
                  name={'startDate'}
                  placeholder="MM/DD/YYYY"
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.endDate}>
              <InputLabel htmlFor={'endDate'}>End Date</InputLabel>
              <InputError>{safeAccess(errors, 'endDate.detail')}</InputError>
              <InputGroup>
                <InputMask
                  defaultValue={convertIsoToSlash(data.endDate)}
                  mask="##/##/####"
                  name={'endDate'}
                  placeholder="MM/DD/YYYY"
                />
              </InputGroup>
              <InputHelp>If you are still employed, please leave this field blank.</InputHelp>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <CardText>
              <InputWrapper error={!!errors.employmentClassification}>
                <InputLabel htmlFor={'employmentClassification'}>
                  Is this employer not-for-profit?
                </InputLabel>
                <InputError>{safeAccess(errors, 'employmentClassification.detail')}</InputError>
                <InputRadio
                  name={'employmentClassification'}
                  value="NPROF"
                  label="Yes"
                  defaultChecked={data.employmentClassification === 'NPROF'}
                />
                <InputRadio
                  name={'employmentClassification'}
                  value="PROF"
                  label="No"
                  defaultChecked={data.employmentClassification === 'PROF'}
                />
              </InputWrapper>
            </CardText>
          </div>
          <div className="col-12  col-md-6">
            <CardText>
              <InputWrapper error={!!errors.employmentType}>
                <InputLabel htmlFor={'employmentType'}>Are you working there full-time?</InputLabel>
                <InputError>{safeAccess(errors, 'employmentType.detail')}</InputError>
                <InputRadio
                  name={'employmentType'}
                  value="FULL"
                  label="Yes"
                  defaultChecked={data.employmentType === 'FULL'}
                />
                <InputRadio
                  name={'employmentType'}
                  value="PART"
                  label="No"
                  defaultChecked={data.employmentType === 'PART'}
                />
                <InputWrapper>
                  <InputHelp>
                    Working full time for the program's requirements means you are currently
                    considered full time by your employer, and work an average of 30 or more hours
                    per week.
                  </InputHelp>
                </InputWrapper>
              </InputWrapper>
            </CardText>
          </div>
          <div className="col-12">
            {data.employmentClassification === 'NPROF' && (
              <Alert brand="info">
                Please keep in mind, loan payments made during an employment lapse, between a
                previous and current employer, will not count as qualifying payments towards the
                Public Service Loan Forgiveness Program. If you know you are going to experience an
                employment lapse in the future, please contact us.
              </Alert>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.ein}>
              <InputLabel htmlFor={'ein'}>Federal Employer Identification Number (FEIN)</InputLabel>
              <InputError>{safeAccess(errors, 'ein.detail')}</InputError>
              <InputGroup>
                <InputText name={'ein'} defaultValue={safeAccess(data, 'ein')} />
              </InputGroup>
              <InputWrapper>
                <InputHelp>
                  You can find your employer's FEIN number in box B of your most recent W-2.
                </InputHelp>
              </InputWrapper>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-3">
            <InputWrapper error={!!errors.payPeriods}>
              <InputLabel htmlFor={'payPeriods'}>Pay Periods</InputLabel>
              <InputError>{safeAccess(errors, 'payPeriods.detail')}</InputError>
              <InputGroup>
                <InputText name={'payPeriods'} defaultValue={safeAccess(data, 'payPeriods')} />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-3">
            <InputWrapper error={!!errors.hours}>
              <InputLabel htmlFor={'hours'}>Hours</InputLabel>
              <InputError>{safeAccess(errors, 'hours.detail')}</InputError>
              <InputGroup>
                <InputText name={'hours'} defaultValue={safeAccess(data, 'hours')} />
              </InputGroup>
              <InputWrapper>
                {' '}
                <InputHelp>Average hours per week</InputHelp>
              </InputWrapper>
            </InputWrapper>
          </div>
        </div>
        <InputWrapper error={!!errors.address1}>
          <InputLabel htmlFor={'address1'}>Address Line 1</InputLabel>
          <InputError>{safeAccess(errors, 'address1.detail')}</InputError>
          <InputGroup>
            <InputText name={'address1'} defaultValue={safeAccess(data, 'address1')} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.address2}>
          <InputLabel htmlFor={'address2'} optional>
            Address Line 2
          </InputLabel>
          <InputError>{safeAccess(errors, 'address2.detail')}</InputError>
          <InputGroup>
            <InputText name={'address2'} defaultValue={safeAccess(data, 'address2')} />
          </InputGroup>
        </InputWrapper>
        <InputWrapper error={!!errors.city}>
          <InputLabel htmlFor={'city'}>City</InputLabel>
          <InputError>{safeAccess(errors, 'city.detail')}</InputError>
          <InputGroup>
            <InputText name={'city'} defaultValue={safeAccess(data, 'city')} />
          </InputGroup>
        </InputWrapper>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.state}>
              <InputLabel htmlFor={'state'}>State</InputLabel>
              <InputError>{safeAccess(errors, 'state.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  name={'state'}
                  defaultValue={safeAccess(data, 'state')}
                  options={[
                    '',
                    'AL',
                    'AK',
                    'AR',
                    'AZ',
                    'CA',
                    'CO',
                    'CT',
                    'DC',
                    'DE',
                    'FL',
                    'GA',
                    'HI',
                    'IA',
                    'ID',
                    'IL',
                    'IN',
                    'KS',
                    'KY',
                    'LA',
                    'MA',
                    'MD',
                    'ME',
                    'MI',
                    'MN',
                    'MO',
                    'MS',
                    'MT',
                    'NC',
                    'NE',
                    'NH',
                    'NJ',
                    'NM',
                    'NV',
                    'NY',
                    'ND',
                    'OH',
                    'OK',
                    'OR',
                    'PA',
                    'PR',
                    'RI',
                    'SC',
                    'SD',
                    'TN',
                    'TX',
                    'UT',
                    'VT',
                    'VA',
                    'WA',
                    'WI',
                    'WV',
                    'WY',
                  ]}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.zip}>
              <InputLabel htmlFor={'zip'}>Zip Code</InputLabel>
              <InputError>{safeAccess(errors, 'zip.detail')}</InputError>
              <InputGroup>
                <InputText name={'zip'} defaultValue={safeAccess(data, 'zip')} />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
      </Form>
    );
  }

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {} } = this.props;
    return true;
  };

  checkNoErrors = () => {
    const { errors = {} } = this.props;

    let retVal =
      (!errors.employerName || errors.employerName.detail === undefined) &&
      (!errors.jobTitle || errors.jobTitle.detail === undefined) &&
      !errors.startDate &&
      !errors.endDate &&
      !errors.ein &&
      !errors.payPeriods &&
      (!errors.hours || errors.hours.detail === undefined) &&
      (!errors.address1 || errors.address1.detail === undefined) &&
      (!errors.city || errors.city.detail === undefined) &&
      (!errors.state || errors.state.detail === undefined) &&
      (!errors.zip || errors.zip.detail === undefined);
    return retVal;
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { action, closeForm, errors = {}, isLoading, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
        <Card
          brand={errors.generalErrors || action.toUpperCase() === 'DELETE' ? 'danger' : undefined}
        >
          <CardHeader>{action} Employer</CardHeader>
          <CardBody>
            {errors.generalErrors &&
              errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}

            <TextLeft>{this.getForm()}</TextLeft>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button
                  brand={action.toUpperCase() === 'DELETE' ? 'danger' : 'success'}
                  onClick={(e) => this.submit(e)}
                >
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' employer'}
                </Button>
              </Fade>
              <Fade show={!isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade show={!isLoading && this.checkNoMissingData() && !this.checkNoErrors()}>
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(EmploymentHistoryForm);
