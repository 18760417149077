import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  Link,
  BrandColor,
  Header5,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { formatAsMoney } from '../../../utils';

import PaymentCompare from '../components/payment-compare';
import { simplifiLoadResource } from '../redux/operations';
import {
  getSimplifiData,
  getMonthlyPayment,
  getNewPayment,
  getOldPayment,
  getStandardPayment,
  getMonthlySavings,
} from '../redux/selectors';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const SimpliFiSummary = ({
  data = {},
  id,
  load,
  monthlyPayment,
  monthlySavings,
  standardPayment,
  newPayment,
  oldPayment,
  request,
}) => {
  useEffect(() => {
    load(id);
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <Card brand="secondary">
          <CardBody>
            <CardText>
              <CardTitle>SimpliFi</CardTitle>
              <AsyncLoader request={request}>
                <Header5>Consolidation</Header5>
                <div className="row">
                  <div className="col-6 col-xl-4">
                    <dl>
                      <dt>Program</dt>
                      <dd>{data.program || ' - '}</dd>
                    </dl>
                  </div>
                  <div className="col-6 col-xl-4">
                    <dl>
                      <dt>Status</dt>
                      <dd>{data.status || ' - '}</dd>
                    </dl>
                  </div>
                </div>
                <div className="col-12">{data.message || ''}</div>
                <br />

                <Header5>IDR</Header5>
                <div className="row">
                  <div className="col-6 col-xl-4">
                    <dl>
                      <dt>Program</dt>
                      <dd>{data.plan || ' - '}</dd>
                    </dl>
                  </div>
                  <div className="col-6 col-xl-4">
                    <dl>
                      <dt>Status</dt>
                      <dd>{data.status || ' - '}</dd>
                    </dl>
                  </div>
                  <div className="col-12 col-xl-4 col-lg-6 col-md-6">
                    <dl>
                      <dt>Standard Monthly Payment</dt>
                      <dd>{oldPayment < 0 ? '—' : formatAsMoney(oldPayment)}</dd>
                    </dl>
                  </div>
                  <div className="col-12 col-xl-4 col-lg-6 col-md-6">
                    <dl>
                      <dt>Income-Driven Monthly Payment</dt>
                      <dd>
                        {newPayment < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(newPayment)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                    </dl>
                  </div>
                  <div className="col-12 col-xl-4 col-lg-6 col-md-6">
                    <dl>
                      <dt>Monthly Savings</dt>
                      <dd>
                        {monthlySavings < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlySavings)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-xl-10">
                    {oldPayment >= 0 && newPayment >= 0 && oldPayment > newPayment ? (
                      <PaymentCompare
                        oldPayment={oldPayment}
                        newPayment={newPayment}
                        year={data.year}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </AsyncLoader>
            </CardText>
          </CardBody>
          <FlexFooter>
            <Link to="/benefits/simplifi">My SimpliFi</Link>
            <Link to="/accounts">My Accounts</Link>
          </FlexFooter>
        </Card>
      </div>
    </div>
  );
};

SimpliFiSummary.propTypes = {
  data: t.object,
  id: t.string.isRequired,
  load: t.func.isRequired,
  monthlyPayment: t.number,
  monthlySavings: t.number,
  standardPayment: t.number,
  newPayment: t.number,
  oldPayment: t.number,
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: getSimplifiData(state),
  id: getUserId(state),
  monthlyPayment: getMonthlyPayment(state),
  monthlySavings: getMonthlySavings(state),
  standardPayment: getStandardPayment(state),
  newPayment: getNewPayment(state),
  oldPayment: getOldPayment(state),
  request: state.simplifi.requests.loadResource,
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(simplifiLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpliFiSummary);
