import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';

import { Redirect } from 'react-router-dom';

import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardTitle,
  DashboardSection,
  Lede,
} from 'fiducius-ui';

import { withPermissions, PaddedBadge } from '../../../routing';

import { getTuitionInstitutionData } from '../redux/selectors';

import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import { TuitionInstitutionTermRow } from '../../../tuition-institution-term';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

class MyTerms extends React.Component {
  static propTypes = {
    data: t.object,
    loadInstitution: t.func.isRequired,
    permissions: t.object,
    request: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', hasMissingInfo: false };
  }

  componentDidMount() {
    this.props.loadInstitution();
  }

  missingInfo = (data) => {
    this.setState({ ...this.state, hasMissingInfo: data });
  };

  noTermsOnFile = () => {
    return (
      <FlexRow>
        <dl>
          <dd>
            <Lede>You have not added any terms for this institution.</Lede>
          </dd>
          <dd>
            <Button
              brand="primary"
              onClick={() => {
                this.addClass();
              }}
            >
              <PaddedIcon icon={faPlusCircle} fixedWidth />
              Add New Term
            </Button>
          </dd>
        </dl>
      </FlexRow>
    );
  };

  noInstitutionsOnFile = () => {
    return (
      <Card>
        <CardBody>
          <FlexRow>
            <dl>
              <dd>
                <Lede>An institution must be added before a term can be added.</Lede>
              </dd>
              <dd>
                <Button
                  brand="primary"
                  onClick={() => {
                    this.addClass();
                  }}
                >
                  <PaddedIcon icon={faPlusCircle} fixedWidth />
                  Add New Institution
                </Button>
              </dd>
            </dl>
          </FlexRow>
        </CardBody>
      </Card>
    );
  };

  addClass() {
    this.setState({ ...this.state, redirectURI: '/tuition/new-class' });
  }

  render() {
    const { data = {}, request } = this.props;
    const institutions = [];
    let currentInstitution = false;
    let terms = [];
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <DashboardSection
          title={
            <div className="row">
              <div className="col-12">My Terms</div>
              <div className="col-12">
                <Button
                  brand="primary"
                  onClick={() => {
                    this.addClass();
                  }}
                >
                  <PaddedIcon icon={faPlusCircle} fixedWidth />
                  Add New Term
                </Button>
              </div>
            </div>
          }
        >
          <div className="row">
            <div className="col">
              {this.state.hasMissingInfo && (
                <Lede>
                  <PaddedBadge brand="primary">
                    <FontAwesomeIcon icon={faSolidExclamation} />
                  </PaddedBadge>{' '}
                  - Indicates Missing Information
                </Lede>
              )}
            </div>
          </div>
          <AsyncLoader request={request} empty={this.noInstitutionsOnFile()}>
            {Object.keys(data).length > 0 &&
              Object.keys(data).forEach((institution) => {
                if (data[institution].current) {
                  terms = [];
                  currentInstitution = true;
                  institutions.push(
                    <Card>
                      <CardBody>
                        <CardTitle>{data[institution].institutionName}</CardTitle>
                        {Object.keys(data[institution].terms).forEach((term) => {
                          if (data[institution].terms[term].current) {
                            terms.push(
                              <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <TuitionInstitutionTermRow
                                  institutionId={institution}
                                  termId={term}
                                  hasMissingInformation={this.missingInfo}
                                />
                              </div>
                            );
                          }
                        })}
                        {terms.length > 0 && <div className="row">{terms}</div>}
                        {terms.length === 0 && this.noTermsOnFile()}
                      </CardBody>
                    </Card>
                  );
                }
              })}
            {institutions.length > 0 && institutions}
            {institutions.length === 0 && !currentInstitution && this.noInstitutionsOnFile()}
          </AsyncLoader>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyTerms));
