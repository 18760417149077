import React from 'react';
import t from 'prop-types';
import b64toBlob from 'b64-to-blob';
import { connect } from 'react-redux';
import { FileUpload } from 'fiducius-ui';

import { authLoadPermissions } from '../../../auth';
import { getUserId } from '../../../auth';

import { safeAccess } from '../../../utils';

import {
  documentsHandleFormChange,
  documentsLoadExampleFile,
  documentsLoadCollection,
} from '../../../documents';

import { nsldsSetFormData } from '../redux/operations';

import { ApiFileWrapper, ExampleFileButton } from '../../../documents';
import { NSLDSImporter } from '../types/nslds-converter';

const NSLDSFileTypeList = ['.txt'];
const OthersFileTypeList = ['.pdf', '.gif', '.bmp', '.jpg', '.jpeg', '.tif', '.tiff', '.png'];

class NSLDSFile extends React.Component {
  static propTypes = {
    id: t.string,
    file: t.object,
    exampleFile: t.object,
    getExampleDocData: t.func.isRequired,
    hasFailed: t.bool.isRequired,
    //isDownloading: t.bool.isRequired,
    isUploading: t.bool.isRequired,
    requiredUpload: t.bool,
    uploadDoc: t.func.isRequired,
    uploadSuccessful: t.func,
    addError: t.func.isRequired,
    loadDocs: t.func.isRequired,
    nsldsHandleChange: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldDownload: false,
      shouldDownloadExampleFile: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { exampleFile, file, hasFailed, isUploading /*, isDownloading*/ } = this.props;
    const { shouldDownload, shouldDownloadExampleFile } = this.state;

    if (shouldDownload && file.fileData) {
      this.getFileFromData(file.fileData, file.name);
      this.setState({ shouldDownload: false });
    }

    if (shouldDownloadExampleFile && safeAccess(file.exampleFile, 'fileData')) {
      this.getFileFromData(
        file.exampleFile.fileData,
        `Example ${file.description}${file.exampleFile.name}`
      );
      this.setState({ shouldDownloadExampleFile: false });
    }

    if (prevProps.isUploading && !isUploading) {
      this.docUploaded(file, hasFailed);

      this.setState({ ...this.state });
    }
  }

  onUpload = async (file) => {
    const data = await this.extractFileData(file);
    const rest = this.props.file;
    this.props.uploadSuccessful();
    this.props.uploadDoc({ ...rest, ...data });
  };

  onUploadFailure = async (file) => {
    //const data = await this.extractFileData(file);
    const rest = this.props.file;

    if (rest.fileTypeCd === 'LNDT' || rest.fileTypeCd === 'SNLDS') {
      this.props.addError({
        title: 'Invalid file extension',
        detail: 'NSLDS files must be uploaded as a text file. (*.txt)',
      });
    } else {
      this.props.addError({
        title: 'Invalid file extension',
        detail:
          'File must be uploaded in one of the following file formats. (*.pdf, *.gif, *.bmp, *.jpg, *.jpeg, *.tif, *.tiff, *.png)',
      });
    }
  };

  extractFileData = async (file) => {
    const fileString = await this.fileToBase64(file);
    const nslds = await this.getNsldsData(file);
    this.props.nsldsHandleChange(nslds);
    return {
      fileData: fileString,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      contentType: file.type,
    };
  };

  getNsldsData = async (file) => {
    const importer = new NSLDSImporter();
    let nslds = await importer.handleFileChosen(file);
    nslds.isSpouseNslds = false;
    return nslds;
  };

  fileToBase64 = (file) => {
    let base64string;
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Error parsing input file'));
      };

      reader.onload = () => {
        const dataUrl = reader.result;
        base64string = dataUrl.split(',')[1];
        resolve(base64string);
      };

      try {
        reader.readAsDataURL(file);
      } catch (ex) {
        reader.abort();
      }
    });
  };

  onDownloadExample = () => {
    const { exampleFile, file } = this.props;
    if (safeAccess(file.exampleFile, 'fileData', false)) {
      this.getFileFromData(
        file.exampleFile.fileData,
        `Example ${file.description}${file.exampleFile.name}`
      );
    } else {
      this.setState({ shouldDownloadExampleFile: true }, () =>
        this.props.getExampleDocData(file.fileTypeCd, file.id)
      );
    }
  };

  getExampleFileBadge = () => {
    const { hasExampleFile } = this.props.file;
    return (
      <ExampleFileButton brand="secondary" show={hasExampleFile} onClick={this.onDownloadExample}>
        Example
      </ExampleFileButton>
    );
  };

  getFileFromData = (data, name) => {
    const blob = b64toBlob(data);

    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      return navigator.msSaveBlob(blob, name);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      // Firefox and Edge require actual appended element for click()
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }
  };

  getUploaderProps() {
    const { allowedDownload, description, fileTypeCd, name, type } = this.props.file;
    let props = {
      accept: [],
      allowDelete: false,
      allowDownload: false,
      allowUpload: false,
      disabled: true,
      fileName: name,
      fileType: type,
      isDownloading: this.props.isDownloading,
      isUploading: this.props.isUploading,
      requiredUpload: this.props.requiredUpload,
      title: description,
    };

    // Some file types in the database should be validated for type
    if (fileTypeCd === 'LNDT' || fileTypeCd === 'SNLDS') {
      props.accept = NSLDSFileTypeList;
    } else {
      props.accept = OthersFileTypeList;
    }

    // only enable actions that should be enabled
    if (allowedDownload) {
      props.allowDownload = true;
      props.disabled = false;
      props.onDownloadFile = this.onDownload;
    } else if (!name) {
      props.allowUpload = true;
      props.disabled = false;
      props.onAddFile = this.onUpload;
      props.onAddFileFailure = this.onUploadFailure;
    }

    return props;
  }

  render() {
    return (
      <ApiFileWrapper>
        {this.props.file && (
          <>
            {this.getExampleFileBadge()}
            <FileUpload {...this.getUploaderProps()} />
          </>
        )}
      </ApiFileWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { createResource, loadResource } = state.documents.requests;
  let hasFailed = true;
  let isUploading = false;
  //let isDownloading = false;
  if (ownProps.file) {
    if (createResource.key === ownProps.file.id || loadResource.key === ownProps.file.id) {
      hasFailed = createResource.hasFailed || loadResource.hasFailed;
      isUploading = createResource.isLoading;
      //isDownloading = loadResource.isLoading;
    }
  }

  return { hasFailed, isUploading, /*isDownloading,*/ id: getUserId(state) };
};

const mapDispatchToProps = (dispatch) => ({
  getExampleDocData: (code, hostFileId) => dispatch(documentsLoadExampleFile(code, hostFileId)),
  uploadDoc: async (file) => {
    await dispatch(documentsHandleFormChange(file));
  },
  loadDocs: async () => {
    await dispatch(authLoadPermissions());
    dispatch(documentsLoadCollection());
  },
  nsldsHandleChange: async (nslds) => {
    dispatch(nsldsSetFormData(nslds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NSLDSFile);
