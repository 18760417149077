import actions from './actions';
import { getToken } from '../../auth';
import {
  checkForEmpty,
  getApi,
  keyById,
  normalize,
  patchApi,
  resourceOperationsGenerator,
} from '../../utils';

/*const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/self-service-document',
  resourceType: 'self-service-document',
};*/

const operations = resourceOperationsGenerator(
  'selfServiceDocument',
  actions,
  null,
  null,
  null,
  null,
  null,
  null,
  null
);

const { selfServiceDocumentHandleCacheChange } = operations;

const selfServiceDocumentMarkStatus = (
  id,
  readyToSend,
  sentToServicer,
  documentIncorrect
) => async (dispatch, getState) => {
  let cache = getState().selfServiceDocument.cache;
  cache['current'][id].readyToSend = readyToSend;
  cache['current'][id].sentToServicer = sentToServicer;
  cache['current'][id].documentIncorrect = documentIncorrect;
  await dispatch(selfServiceDocumentHandleCacheChange({ ...cache }));
};

const selfServiceDocumentClearDocument = (id) => async (dispatch, getState) => {
  let cache = getState().selfServiceDocument.cache;
  let old = cache['current'];
  let current = [];
  Object.keys(old).forEach((a) => {
    if (a !== id) {
      current.push(old[a]);
    }
  });

  await dispatch(selfServiceDocumentHandleCacheChange({ ...cache, current }));
};

const selfServiceDocumentUpdateAll = () => async (dispatch, getState) => {
  let current = getState().selfServiceDocument.cache['current'];
  if (current !== undefined && current !== null && Object.keys(current).length > 0) {
    let ids = [];
    Object.keys(current).forEach((a) => {
      ids.push(a);
    });
    for (let i = 0; i < ids.length; i++) {
      await dispatch(selfServiceDocumentUpdateResource(ids[i]));
    }
  }
};

const selfServiceDocumentUpdateParentFile = () => async (dispatch, getState) => {
  let current = getState().selfServiceDocument.cache['current'];
  if (current !== undefined && current !== null && Object.keys(current).length > 0) {
    let ids = [];
    Object.keys(current).forEach((a) => {
      if (current[a].name === 'IDR' || current[a].name === 'Consolidation') {
        ids.push(a);
      }
    });
    for (let i = 0; i < ids.length; i++) {
      await dispatch(selfServiceDocumentUpdateResource(ids[i]));
    }
  }
};

const selfServiceDocumentUpdateResource = (id) => async (dispatch, getState) => {
  try {
    dispatch(actions.selfServiceDocumentUpdateResourceRequest());
    let cache = getState().selfServiceDocument.cache;
    let patchData = normalize('self-service-document', cache['current'][id]);
    patchData['data']['attributes']['id'] = id;
    patchData['data']['id'] = id;
    let data = await patchApi(getToken(getState()), `/self-service-document/${id}`, patchData);
    dispatch(actions.selfServiceDocumentUpdateResourceResponse());
    cache = { ...getState().selfServiceDocument.cache, ...keyById(data) };
    await dispatch(selfServiceDocumentHandleCacheChange({ ...cache }));
    dispatch(
      actions.selfServiceDocumentUpdateResourceSuccess(
        checkForEmpty(getState().selfServiceDocument)
      )
    );
  } catch (error) {
    dispatch(operations.selfServiceDocumentHandleError(error));
    dispatch(actions.selfServiceDocumentUpdateResourceFailure(error));
  }
};

const selfServiceDocumentClientEmpCertApproval = (documentId) => async (dispatch, getState) => {
  const state = getState();
  let form = state.empcertApproval.form;

  try {
    dispatch(actions.selfServiceDocumentUpdateResourceRequest());
    let cache = getState().selfServiceDocument.cache;
    let patchData = normalize('self-service-document', cache['current'][documentId]);
    patchData['data']['attributes']['id'] = documentId;
    patchData['data']['id'] = documentId;

    patchData['data']['attributes']['signature-pad-approver'] = form.signaturePadClient;
    patchData['data']['attributes']['additional-data'] = form.ssn1 + form.ssn2 + form.ssn3;

    let data = await patchApi(
      getToken(getState()),
      `/self-service-document/${documentId}`,
      patchData
    );
    dispatch(actions.selfServiceDocumentUpdateResourceResponse());
    cache = { ...getState().selfServiceDocument.cache, ...keyById(data) };
    cache['current'][documentId].status = 'X';
    await dispatch(selfServiceDocumentHandleCacheChange({ ...cache }));
    dispatch(
      actions.selfServiceDocumentUpdateResourceSuccess(
        checkForEmpty(getState().selfServiceDocument)
      )
    );
  } catch (error) {
    dispatch(operations.selfServiceDocumentHandleError(error));
    dispatch(actions.selfServiceDocumentUpdateResourceFailure(error));
  }
};

const selfServiceDocumentLoadDocuments = (toDoStepId) => async (dispatch, getState) => {
  try {
    dispatch(actions.selfServiceDocumentLoadCollectionRequest());
    let current = await getApi(getToken(getState()), '/self-service-document/0/' + toDoStepId);

    dispatch(actions.selfServiceDocumentLoadCollectionResponse());
    current = current || [];
    current = keyById(current);
    await dispatch(selfServiceDocumentHandleCacheChange({ current }));
    dispatch(
      actions.selfServiceDocumentLoadCollectionSuccess(
        checkForEmpty(getState().selfServiceDocument)
      )
    );
  } catch (error) {
    dispatch(operations.selfServiceDocumentHandleError(error));
    dispatch(actions.selfServiceDocumentLoadCollectionFailure(error));
  }
};

export {
  selfServiceDocumentUpdateResource,
  selfServiceDocumentLoadDocuments,
  selfServiceDocumentMarkStatus,
  selfServiceDocumentClearDocument,
  selfServiceDocumentUpdateAll,
  selfServiceDocumentUpdateParentFile,
  selfServiceDocumentClientEmpCertApproval,
};

export default operations;
