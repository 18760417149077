import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Animation,
  Form,
  Header2,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputNumeric,
  InputRadio,
  InputText,
  InputWrapper,
  fade,
} from 'fiducius-ui';

import { useFocusOnMount, useScrollToTopOnMount, safeAccess } from '../../../utils';

const FadeFragment = styled.div`
  ${fade};
`;

const TabLoans = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  const firstInput = useFocusOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your student loans</Header2>
      <div className="row">
        <div className="col-12 col-lg-6">
          <InputWrapper error={!!errors.federalLoanBalance}>
            <InputLabel htmlFor="federalLoanBalance">Federal Loan Balance</InputLabel>
            <InputError>{safeAccess(errors, 'federalLoanBalance.detail')}</InputError>
            <InputGroup>
              <InputAddon>$</InputAddon>
              <InputNumeric
                inputRef={firstInput}
                name="federalLoanBalance"
                defaultValue={data.federalLoanBalance}
              />
              <InputAddon>.00</InputAddon>
            </InputGroup>
          </InputWrapper>
        </div>
        <div className="col-12 col-lg-6">
          <InputWrapper error={!!errors.federalMonthlyPayment}>
            <InputLabel htmlFor="federalMonthlyPayment">Federal Loan Monthly Payment</InputLabel>
            <InputError>{safeAccess(errors, 'federalMonthlyPayment.detail')}</InputError>
            <InputGroup>
              <InputAddon>$</InputAddon>
              <InputNumeric
                name="federalMonthlyPayment"
                defaultValue={data.federalMonthlyPayment}
              />
              <InputAddon>.00</InputAddon>
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
      <InputWrapper error={!!errors.pslfOnOwn}>
        <InputLabel>Have you ever pursued Public Service Loan Forgiveness on your own?</InputLabel>
        <InputError>{safeAccess(errors, 'pslfOnOwn.detail')}</InputError>
        <InputRadio name="pslfOnOwn" value="Y" label="Yes" />
        <InputRadio name="pslfOnOwn" value="N" label="No" />
      </InputWrapper>
      <InputWrapper error={!!errors.consolidated}>
        <InputLabel>Have you consolidated your loans?</InputLabel>
        <InputError>{safeAccess(errors, 'consolidated.detail')}</InputError>
        <InputRadio
          name="consolidated"
          value="Y"
          label="Yes"
          defaultChecked={data.consolidated === 'Y'}
        />
        <InputRadio
          name="consolidated"
          value="N"
          label="No"
          defaultChecked={data.consolidated === 'N'}
        />
      </InputWrapper>
      <Animation
        key="consolidatedExtra"
        animation="fade"
        appear={false}
        in={data.consolidated === 'Y'}
        unmountOnExit
      >
        <FadeFragment>
          <InputWrapper error={!!errors.federalPrivateConsolidatedMix}>
            <InputLabel>
              Did you consolidate any private loans with a federal student loan?
            </InputLabel>
            <InputError>{safeAccess(errors, 'federalPrivateConsolidatedMix.detail')}</InputError>
            <InputRadio
              name="federalPrivateConsolidatedMix"
              value="Y"
              label="Yes"
              defaultChecked={data.federalPrivateConsolidatedMix === 'Y'}
            />
            <InputRadio
              name="federalPrivateConsolidatedMix"
              value="N"
              label="No"
              defaultChecked={data.federalPrivateConsolidatedMix === 'N'}
            />
          </InputWrapper>
        </FadeFragment>
      </Animation>
      <InputWrapper error={!!errors.hasParentPlus}>
        <InputLabel>Do you have any federal parent plus loans?</InputLabel>
        <InputError>{safeAccess(errors, 'hasParentPlus.detail')}</InputError>
        <InputRadio
          name="hasParentPlus"
          value="Y"
          label="Yes"
          defaultChecked={data.hasParentPlus === 'Y'}
        />
        <InputRadio
          name="hasParentPlus"
          value="N"
          label="No"
          defaultChecked={data.hasParentPlus === 'N'}
        />
      </InputWrapper>
      <InputWrapper error={!!errors.hasPrivateLoans}>
        <InputLabel>Do you have any private student loans?</InputLabel>
        <InputError>{safeAccess(errors, 'hasPrivateLoans.detail')}</InputError>
        <InputRadio
          name="hasPrivateLoans"
          value="Y"
          label="Yes"
          defaultChecked={data.hasPrivateLoans === 'Y'}
        />
        <InputRadio
          name="hasPrivateLoans"
          value="N"
          label="No"
          defaultChecked={data.hasPrivateLoans === 'N'}
        />
      </InputWrapper>
      <Animation
        key="privateLoansExtra"
        animation="fade"
        appear={false}
        in={data.hasPrivateLoans === 'Y'}
        unmountOnExit
      >
        <FadeFragment>
          <div className="row">
            <div className="col-12 col-lg-6">
              <InputWrapper error={!!errors.privateBalance}>
                <InputLabel htmlFor="privateBalance">Private Loan Balance</InputLabel>
                <InputError>{safeAccess(errors, 'privateBalance.detail')}</InputError>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric name="privateBalance" defaultValue={data.privateBalance} />
                  <InputAddon>.00</InputAddon>
                </InputGroup>
              </InputWrapper>
            </div>
            <div className="col-12 col-lg-6">
              <InputWrapper error={!!errors.privatePayment}>
                <InputLabel htmlFor="privatePayment">Private Loan Monthly Payment</InputLabel>
                <InputError>{safeAccess(errors, 'privatePayment.detail')}</InputError>
                <InputGroup>
                  <InputAddon>$</InputAddon>
                  <InputNumeric name="privatePayment" defaultValue={data.privatePayment} />
                  <InputAddon>.00</InputAddon>
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <InputWrapper error={!!errors.privateServicer}>
                <InputLabel htmlFor="privateServicer">Private Loan Servicer</InputLabel>
                <InputError>{safeAccess(errors, 'privateServicer.detail')}</InputError>
                <InputGroup>
                  <InputText name="privateServicer" defaultValue={data.privateServicer} />
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputWrapper error={!!errors.privateInterestRate}>
                <InputLabel htmlFor="privateInterestRate">Private Loan Interest Rate</InputLabel>
                <InputError>{safeAccess(errors, 'privateInterestRate.detail')}</InputError>
                <InputGroup>
                  <InputNumeric
                    name="privateInterestRate"
                    defaultValue={data.privateInterestRate}
                  />
                  <InputAddon>%</InputAddon>
                </InputGroup>
              </InputWrapper>
            </div>
          </div>
        </FadeFragment>
      </Animation>
    </Form>
  );
};

TabLoans.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabLoans;
