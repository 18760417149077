import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  Card,
  CardBody,
  CardTitle,
  AsyncLoader,
  ButtonCarousel,
  Button,
  Header5,
} from 'fiducius-ui';

import { getUserId, getToken } from '../../auth';
import { safeAccess } from '../../utils';

import { withPermissions } from '../../routing';
import ActiveContribution from '../components/active-contribution';

const StyledDiv = styled.div`
  display: flex;
  align-content: center;
`;

const DivRight = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

const StyledHeader5 = styled(Header5)`
  margin-left: 0em;
  margin-top: 1em;
  color: ${(p) => p.theme.textPrimary};
`;

class Overview extends React.Component {
  static propTypes = {
    id: t.string,
    token: t.string,
    data: t.object,
    load: t.func,
    permissions: t.object,
    request: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectUri: '' };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    const { request, data, permissions } = this.props;

    let numActive = Object.values(data).filter((a) => a.active).length;
    if (this.state.redirectUri && this.state.redirectUri.length > 0) {
      return <Redirect to={this.state.redirectUri} />;
    }
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <div className="row">
              <div className="col-12 col-sm-auto">Active Contribution(s)</div>
              <AsyncLoader request={request} empty={<></>} loading={<></>}>
                <DivRight className="col-12 col-sm">
                  <StyledButton
                    brand="primary"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        redirectUri: '/benefits/contribution/active-contributions',
                      });
                    }}
                  >
                    Manage Active Contribution(s)
                  </StyledButton>
                  {!permissions.showForgiveness && (
                    <StyledButton
                      brand="primary"
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          redirectUri: '/benefits/contribution/inactive-contributions',
                        });
                      }}
                    >
                      Manage Inactive Contribution(s)
                    </StyledButton>
                  )}
                </DivRight>
              </AsyncLoader>
            </div>
          </CardTitle>
          <AsyncLoader
            request={request}
            empty={
              <StyledHeader5>There are no active contribution accounts on file.</StyledHeader5>
            }
          >
            <StyledDiv>
              {numActive > 0 && (
                <ButtonCarousel
                  components={Object.values(data)
                    .filter((a) => a.active)
                    .map((contribution, i) => (
                      <ActiveContribution data={contribution} key={i} dataLevel={1} />
                    ))}
                  componentDisplayNames={Object.values(data)
                    .filter((a) => a.active)
                    .map((contribution, i) => contribution.account)}
                />
              )}
              {numActive === 0 && (
                <StyledHeader5>There are no active contribution accounts on file.</StyledHeader5>
              )}
            </StyledDiv>
          </AsyncLoader>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: getToken(state),
    id: getUserId(state),
    data: safeAccess(state, 'customerContribution.cache', {}),
    request: state.customerContribution.requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Overview));
