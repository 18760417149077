export const benefits = {
  ACCOUNT_SERVICERS: -1,
  ACCOUNTS: -2,
  SHARED_DATA: -3,
  REFINANCE: 1,
  CONTRIBUTION: 2,
  FORGIVENESS: 3,
  SIMPLIFI_CONSOLIDATION: 4,
  SIMPLIFI_IDR: 5,
  IDENTITY: 6,
  STUDENT_LOAN_DISABILITY_INSURANCE: 7,
  TUITION: 8,
  //company paid renews = 9
  LWO: 10,
  EMPCERT: 11,
  TWELVEKORLESS: 12,
  BANKINGASABENEFIT: 13,
  EDUCATIONALREENGAGEMENT: 14,
  FRESHSTART: 15,
  REDUCEDIDR: 16,
  RETIREMENT: -999, // TODO: Not implemented in the API yet.
};

export const benefitStatuses = {
  QUALIFIED: 'QUAL',
  ENROLLING: 'ENRLG',
  ENROLLED: 'ENRLD',
  INELIGIBLE: 'INEL',
  DECLINED: 'DECL',
  READONLY: 'READ',
};
