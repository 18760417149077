import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardText,
  CardTitle,
  DashboardSection,
  BrandColor,
  Header2,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { MyReferences } from '../../../references';
import { formatAsMoney } from '../../../utils';

import PaymentCompare from '../components/payment-compare';
import { simplifiLoadResource } from '../redux/operations';
import {
  getMonthlyPayment,
  getMonthlySavings,
  getNewPayment,
  getOldPayment,
  getSimplifiData,
} from '../redux/selectors';

const MySimpliFi = ({
  // withPermissions(
  data = {},
  id,
  load,
  monthlyPayment,
  monthlySavings,
  newPayment,
  oldPayment,
  request,
}) => {
  useEffect(() => {
    load(id);
  }, []);
  return (
    <DashboardSection title="My SimpliFi">
      <Header2>Lower and simplify your payments through our SimpliFi program.</Header2>
      <AsyncLoader request={request}>
        <div className="row">
          <div className="col-12 col-lg-10">
            <Card>
              <CardBody>
                <CardTitle>Consolidation</CardTitle>
                <CardText>
                  <div className="row">
                    <div className="col-6">
                      <dl>
                        <dt>Program</dt>
                        <dd>{data.plan || ' - '}</dd>
                      </dl>
                    </div>
                    <div className="col-6">
                      <dl>
                        <dt>Status</dt>
                        <dd>{data.status || ' - '}</dd>
                      </dl>
                    </div>
                  </div>
                  {data.message || ''}
                </CardText>
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-4">
            <Card>
              <CardBody>
                <CardTitle>Plan Details</CardTitle>
                <CardText>
                  <dl>
                    <dt>Qualifying Plan</dt>
                    <dd>{data.program || '—'}</dd>
                    <dt>Status</dt>
                    <dd>{data.status || '—'}</dd>
                    <dt>IDR Monthly Payment</dt>
                    <dd>
                      {newPayment < 0 ? (
                        <>—</>
                      ) : (
                        <BrandColor brand="secondary">
                          <strong>{formatAsMoney(newPayment)}</strong>
                        </BrandColor>
                      )}
                    </dd>
                    <dt>Monthly Savings</dt>
                    <dd>
                      {monthlySavings < 0 ? (
                        <>—</>
                      ) : (
                        <BrandColor brand="secondary">
                          <strong>{formatAsMoney(monthlySavings)}</strong>
                        </BrandColor>
                      )}
                    </dd>
                  </dl>
                </CardText>
              </CardBody>
            </Card>
          </div>
          <div className="col-12 col-lg-6">
            <MyReferences showTitle={true} />
          </div>
          {oldPayment >= 0 && newPayment >= 0 && oldPayment > newPayment && (
            <div className="col-12 col-lg-6">
              <PaymentCompare oldPayment={oldPayment} newPayment={newPayment} year={data.year} />
            </div>
          )}
        </div>
      </AsyncLoader>
    </DashboardSection>
  );
};

MySimpliFi.propTypes = {
  data: t.object,
  id: t.string.isRequired,
  load: t.func.isRequired,
  monthlyPayment: t.number,
  monthlySavings: t.number,
  newPayment: t.number,
  oldPayment: t.number,
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: getSimplifiData(state),
  id: getUserId(state),
  monthlyPayment: getMonthlyPayment(state),
  monthlySavings: getMonthlySavings(state),
  newPayment: getNewPayment(state),
  oldPayment: getOldPayment(state),
  request: state.simplifi.requests.loadResource,
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(simplifiLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MySimpliFi);
