import { FormValidator } from '../../utils';

export class LoanRepaymentValidator extends FormValidator {
  requiredIdrPaymentAmount(validator) {
    validator.isRequired();
    validator.isGreaterThanOrEqualTo(0);
    validator.validNumber();
  }

  paymentAmount(validator) {
    validator.isRequired();
    validator.isGreaterThanOrEqualTo(0);
    validator.validNumber();
  }

  paymentDate(validator) {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    validator.isRequired();
    validator.validDate();

    validator.isOnOrAfterDate(firstDay);
    validator.isOnOrBeforeDate(lastDay);
  }
}
