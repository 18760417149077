import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/automated-advisor',
  resourceType: 'automated-advisor',
};

const operations = resourceOperationsGenerator(
  'advisor',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  null,
  null,
  null
);

export default operations;
const { advisorInvalidateCache, advisorLoadResource } = operations;
export { advisorInvalidateCache, advisorLoadResource };
