import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/contribution-setup',
  resourceType: 'contribution-setup',
};

const operations = resourceOperationsGenerator(
  'contributionSetup',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  null,
  null
);

const { contributionSetupLoadResource } = operations;

export { contributionSetupLoadResource };

export default operations;
