import React from 'react';
import styled from 'styled-components';

import t from 'prop-types';
import { Card, CardBody, CardText, CardTitle, ChartBar } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils/format';

const StyledRow = styled.div`
  margin-top: 1em;
`;

const PaymentCompare = ({ newPayment, oldPayment, year = 1, bigTitle = false }) => (
  <Card>
    <CardBody>
      {bigTitle && <CardTitle>My Payment Comparison</CardTitle>}
      <CardText>
        {!bigTitle && (
          <center>
            <strong>My Payment Comparison</strong>
          </center>
        )}
        <StyledRow className="row">
          <div className="col">
            <ChartBar
              bars={[
                {
                  label: '',
                  dataType: 'currency',
                  data: [oldPayment, newPayment],
                },
              ]}
              config={{ labels: ['Standard', 'Income Driven'], formatter: formatAsMoney }}
            />
          </div>
        </StyledRow>
      </CardText>
    </CardBody>
  </Card>
);

PaymentCompare.propTypes = {
  newPayment: t.number,
  oldPayment: t.number,
  year: t.number,
  bigTitle: t.bool,
};

export default PaymentCompare;
