import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Button,
  InputWrapper,
  InputCheckbox,
  CardText,
  CardBody,
  Header4,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { safeAccess, debounce, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import { authLoadPermissions } from '../../../auth';
import styled from 'styled-components';

import { todosLoadCollection, todosUpdateResource } from '../../../todos/redux/operations';

import { idrSaveComplete } from '../../../workflows/idr/redux/operations';
import StandardFax from './standard-fax';

const InputWrapper2 = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

const StyledHeader4 = styled(Header4)`
  color: ${(p) => p.theme.textSecondary};
`;

class StandardConfirmation extends React.Component {
  static propTypes = {
    confirm: t.func.isRequired,
    idrConfirm: t.func.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    stepId: t.number.isRequired,
    todos: t.array,
    additionalInfoNeeded: t.bool,
    handleChange: t.func,
    forReducedIDR: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = { showCheckbox: false, redirectURI: '', goToInfo: false };
    this.onConfirm = this.onConfirm.bind(this);
    this.goToInfo = this.goToInfo.bind(this);
  }
  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  onConfirm = async () => {
    if (this.props.stepId === 113) {
      await this.props.idrConfirm(this.props.stepId);

      if (this.props.permissions.showContribution) {
        window.location.href = '/benefits/forgiveness/contribution-ss';
      } else {
        window.location.href = '/benefits/forgiveness/complete';
      }

      //this.setState({ ...this.state, redirectURL: '/benefits/forgiveness/overview' });
    } else if (this.props.stepId === 1400) {
      await this.props.idrConfirm(this.props.stepId);
      window.location.href = '/benefits/reducedidr/complete';
    } else if (this.props.stepId === 1620) {
      await this.props.idrConfirm(this.props.stepId);
      window.location.href = '/benefits/reducedidr/completerenewal';
    } else {
      await this.props.confirm(this.props.stepId);
      this.goToCurrentStep();
    }
  };

  goToInfo(e) {
    this.props.handleChange(e.type);
  }

  checkStepCompleted = () => {
    const { forReducedIDR } = this.props;
    if (forReducedIDR !== undefined && forReducedIDR !== null && forReducedIDR) {
      return this.checkStepCompletedReducedIDR();
    } else {
      return this.checkStepCompletedForgive();
    }
  };

  checkStepCompletedReducedIDR = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const reducedIDRStep = permissions.reducedIdrSteps.find((a) => a.id === stepMapping);

    let retVal = false;
    if (
      reducedIDRStep !== undefined &&
      reducedIDRStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      retVal = reducedIDRStep.stepComplete;
      if (!retVal) {
        if (stepId === 1100) {
          const currentToDo = todos.find((a) => a.id === 1100);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (
          stepId === 1200 ||
          stepId === 1300 ||
          stepId === 1400 ||
          stepId === 1600 ||
          stepId === 1610 ||
          stepId === 1620
        ) {
          const currentToDo = todos.find(
            (a) =>
              a.id === 1200 ||
              a.id === 1300 ||
              a.id === 1400 ||
              a.id === 1600 ||
              a.id === 1610 ||
              a.id === 1620
          );
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 1500 || stepId === 1630) {
          const currentToDo = todos.find((a) => a.id === 1500 || a.id === 1630);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        }
      }
    }
    return retVal;
  };

  checkStepCompletedForgive = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const forgivenessStep = permissions.forgivenessSteps.find((a) => a.id === stepMapping);

    let retVal = false;
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      retVal = forgivenessStep.stepComplete;
      if (!retVal) {
        if (stepId === 103 || stepId === 104 || stepId === 105) {
          const currentToDo = todos.find((a) => a.id === 103 || a.id === 104 || a.id === 105);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 106 || stepId === 107 || stepId === 108) {
          const currentToDo = todos.find((a) => a.id === 106 || a.id === 107 || a.id === 108);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        } else if (stepId === 111 || stepId === 112 || stepId === 113) {
          const currentToDo = todos.find((a) => a.id === 111 || a.id === 112 || a.id === 113);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        }
      }
    }
    return retVal;
  };

  getStepMapping = (id) => {
    let retVal = '';
    switch (id) {
      case 101:
        retVal = 'Welcome';
        break;
      case 102:
        retVal = 'ForbearanceP1';
        break;
      case 103:
      case 104:
      case 105:
        retVal = 'Consolidation';
        break;
      case 106:
      case 107:
      case 108:
        retVal = 'EmpCert';
        break;
      case 109:
        retVal = 'ForbearanceP2';
        break;
      case 110:
        retVal = 'Taxes';
        break;
      case 111:
      case 112:
      case 113:
        retVal = 'IDR';
        break;
      case 114:
      case 115:
        retVal = 'Contribution';
        break;
      case 150:
        retVal = 'BtsQuestionnaire';
        break;
      case 1100:
        retVal = 'WelcomeReducedIDR';
        break;
      case 1200:
      case 1300:
      case 1400:
      case 1600:
      case 1610:
      case 1620:
        retVal = 'IDRReducedIDR';
        break;
      case 1500:
      case 1630:
        retVal = 'CompleteReducedIDR';
        break;
      default:
        retVal = 'Welcome';
        break;
    }
    return retVal;
  };

  checkNotYetAtStep = () => {
    const { forReducedIDR } = this.props;
    if (forReducedIDR !== undefined && forReducedIDR !== null && forReducedIDR) {
      return this.checkNotYetAtStepReducedIDR();
    } else {
      return this.checkNotYetAtStepForgive();
    }
  };

  checkNotYetAtStepForgive = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const forgivenessStep = permissions.forgivenessSteps.find((a) => a.id === stepMapping);
    const currentStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);

    let retVal = false;
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      currentStep !== undefined &&
      currentStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (forgivenessStep.id === currentStep.id) {
        if (stepId === 103 || stepId === 104 || stepId === 105) {
          const currentToDo = todos.find((a) => a.id === 103 || a.id === 104 || a.id === 105);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 106 || stepId === 107 || stepId === 108) {
          const currentToDo = todos.find((a) => a.id === 106 || a.id === 107 || a.id === 108);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 111 || stepId === 112 || stepId === 113) {
          const currentToDo = todos.find((a) => a.id === 111 || a.id === 112 || a.id === 113);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 114 || stepId === 115) {
          const currentToDo = todos.find((a) => a.id === 114 || a.id === 115);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        }
      } else {
        retVal = !forgivenessStep.stepComplete && !forgivenessStep.stepCurrent;
      }
    }
    return retVal;
  };

  checkNotYetAtStepReducedIDR = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const reducedIDRStep = permissions.reducedIdrSteps.find((a) => a.id === stepMapping);
    const currentStep = permissions.reducedIdrSteps.find(
      (a) => a.stepCurrent && a.id.includes('IDR')
    );

    let retVal = false;
    if (
      reducedIDRStep !== undefined &&
      reducedIDRStep !== null &&
      currentStep !== undefined &&
      currentStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (reducedIDRStep.id === currentStep.id) {
        if (stepId === 1100) {
          const currentToDo = todos.find((a) => a.id === 1100);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (
          stepId === 1200 ||
          stepId === 1300 ||
          stepId === 1400 ||
          stepId === 1600 ||
          stepId === 1610 ||
          stepId === 1620
        ) {
          const currentToDo = todos.find(
            (a) =>
              a.id === 1200 ||
              a.id === 1300 ||
              a.id === 1400 ||
              a.id === 1600 ||
              a.id === 1610 ||
              a.id === 1620
          );
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (stepId === 1500 || stepId === 1630) {
          const currentToDo = todos.find((a) => a.id === 1500 || a.id === 1630);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        }
      } else {
        retVal = !reducedIDRStep.stepComplete && !reducedIDRStep.stepCurrent;
      }
    }
    return retVal;
  };

  checkLockedForLWO = (stepId) => {
    const { permissions, todos } = this.props;
    if (permissions.enrolledLwo && (stepId === 106 || stepId === 107)) {
      const lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

      if (lwoStep !== undefined && lwoStep !== null) {
        const currentToDo = todos.find((a) => a.id > 600 && a.id < 608);

        if (currentToDo !== undefined && currentToDo !== null) {
          return true;
        }
      }
    }
    return false;
  };

  goToCurrentStep = () => {
    const { forReducedIDR } = this.props;
    if (forReducedIDR !== undefined && forReducedIDR !== null && forReducedIDR) {
      return this.goToCurrentStepReducedIDR();
    } else {
      return this.goToCurrentStepForgive();
    }
  };

  goToCurrentStepForgive = () => {
    const { permissions, todos } = this.props;
    const forgivenessStep = permissions.forgivenessSteps.find((a) => a.stepCurrent);
    let retVal = '/benefits/forgiveness/overview';
    if (
      forgivenessStep !== undefined &&
      forgivenessStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (
        forgivenessStep.id === 'Consolidation' ||
        forgivenessStep.id === 'EmpCert' ||
        forgivenessStep.id === 'IDR' ||
        forgivenessStep.id === 'Contribution'
      ) {
        retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
        const currentToDo = todos.find(
          (a) =>
            (forgivenessStep.id === 'Consolidation' &&
              (a.id === 103 || a.id === 104 || a.id === 105)) ||
            (forgivenessStep.id === 'EmpCert' &&
              (a.id === 106 ||
                a.id === 107 ||
                a.id === 108 ||
                a.id === 252 ||
                a.id === 253 ||
                a.id === 254)) ||
            (forgivenessStep.id === 'IDR' &&
              (a.id === 111 ||
                a.id === 112 ||
                a.id === 113 ||
                a.id === 255 ||
                a.id === 256 ||
                a.id === 257)) ||
            (forgivenessStep.id === 'Contribution' && (a.id === 114 || a.id === 115))
        );
        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 103:
            case 106:
            case 111:
            case 114:
              retVal += '/step-1';
              break;
            case 104:
            case 107:
            case 112:
            case 115:
              retVal += '/step-2';
              break;
            case 105:
            case 108:
            case 113:
              retVal += '/step-3';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
      }
    }
    this.setState({ ...this.state, redirectURI: retVal });
  };

  goToCurrentStepReducedIDR = () => {
    const { permissions, todos } = this.props;
    const reducedIDRStep = permissions.reducedIdrSteps.find(
      (a) => a.stepCurrent && (a.id === 'EmpCertEmploymentInformation' || a.id.includes('IDR'))
    );
    let retVal = '/benefits/reducedidr/overview';
    if (
      reducedIDRStep !== undefined &&
      reducedIDRStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (reducedIDRStep.id === 'IDRReducedIDR') {
        retVal = '/benefits/reducedidr' + reducedIDRStep.stepPath;
        const currentToDo = todos.find(
          (a) =>
            reducedIDRStep.id === 'IDRReducedIDR' &&
            (a.id === 1200 ||
              a.id === 1300 ||
              a.id === 1400 ||
              a.id === 1600 ||
              a.id === 1610 ||
              a.id === 1620)
        );
        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 1200:
            case 1600:
              retVal += '/step-1';
              break;
            case 1300:
            case 1610:
              retVal += '/step-2';
              break;
            case 1400:
            case 1620:
              retVal += '/step-3';
              break;
            default:
              break;
          }
        }
      } else if (reducedIDRStep.id === 'EmpCertEmploymentInformation') {
        retVal = '/benefits/reducedidr/employmenthistory';
      } else {
        retVal = '/benefits/reducedidr' + reducedIDRStep.stepPath;
      }
    }
    this.setState({ ...this.state, redirectURI: retVal });
  };

  needsDocumentsGenerated = () => {
    const { stepId } = this.props;
    let retVal = false;
    if (stepId === 103 || stepId === 106 || stepId === 111 || stepId === 1200 || stepId === 1600) {
      retVal = true;
    }
    return retVal;
  };

  redirectForInSchoolQuestionnaire = () => {
    this.setState({ ...this.state, redirectURI: '/bts' });
  };

  redirectForDocumentGeneration = () => {
    const { stepId, permissions } = this.props;
    let retVal = '';
    if (this.props.stepId === 113 || this.props.stepId === 1500 || this.props.stepId === 1630) {
      this.props.idrConfirm(this.props.stepId).then((a) => {
        if (stepId === 103) {
          retVal = '/fulfillment/13';
        } else if (stepId === 106) {
          if (permissions.electronicEmpcertEnabled) {
            retVal = '/benefits/forgiveness/empcert-ss/step-2';
          } else {
            retVal = '/documents';
          }
        } else if (stepId === 111 || stepId === 1200 || stepId === 1600) {
          retVal = '/idr';
        }
        this.setState({ ...this.state, redirectURI: retVal });
        //window.location.href = retVal;
      });
    } else if (stepId === 114) {
      retVal = '/documents';
      this.setState({ ...this.state, redirectURI: retVal });
    } else {
      this.props.confirm(this.props.stepId).then((a) => {
        if (stepId === 103) {
          retVal = '/fulfillment/13';
        } else if (stepId === 106) {
          if (permissions.electronicEmpcertEnabled) {
            retVal = '/benefits/forgiveness/empcert/step-2';
          } else {
            retVal = '/documents';
          }
        } else if (stepId === 111 || stepId === 1200 || stepId === 1600) {
          retVal = '/idr';
        }
        this.setState({ ...this.state, redirectURI: retVal });
        //window.location.href = retVal;
      });
    }
  };

  redirectForLWO = () => {
    const { permissions, todos } = this.props;
    const lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);
    let retVal = '/benefits/limited-waiver-opportunity' + lwoStep.stepPath;

    if (lwoStep !== undefined && lwoStep !== null) {
      const currentToDo = todos.find((a) => a.id > 600 && a.id < 608);

      if (currentToDo !== undefined && currentToDo !== null) {
        switch (currentToDo.id) {
          case 606:
            retVal += '/step-1';
            break;
          case 607:
            retVal += '/step-2';
            break;
          default:
            break;
        }
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  handleChange = debounce((formState, id, attributes) => {
    this.setState({ ...this.state, showCheckbox: !this.state.showCheckbox });
  }, 250);

  render() {
    const { stepId, forReducedIDR } = this.props;

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      //alert(this.state.redirectURI);
      return <Redirect to={this.state.redirectURI} />;
    } else {
      if (this.checkNotYetAtStep()) {
        return (
          <AsyncLoader request={this.props.request}>
            <CardBody>
              <CardText>
                <div className="row col-12">
                  <div className="col-8">
                    <StyledHeader4>You haven't reached this step yet.</StyledHeader4>
                  </div>
                  <div className="col-4">
                    <InputWrapper>
                      <Button brand="info" onClick={this.goToCurrentStep}>
                        {'Go to current step'}
                      </Button>
                    </InputWrapper>
                  </div>
                </div>
              </CardText>
            </CardBody>
          </AsyncLoader>
        );
      } else if (this.checkStepCompleted()) {
        return (
          <AsyncLoader request={this.props.request}>
            <CardBody>
              <CardText>
                <div className="row col-12">
                  <div className="col-8">
                    <StyledHeader4>You've completed this step.</StyledHeader4>
                  </div>
                  <div className="col-4">
                    <InputWrapper>
                      <Button brand="info" onClick={this.goToCurrentStep}>
                        {'Go to current step'}
                      </Button>
                    </InputWrapper>
                  </div>
                </div>
              </CardText>
            </CardBody>
          </AsyncLoader>
        );
      } else if (this.needsDocumentsGenerated()) {
        if (this.checkLockedForLWO(stepId)) {
          return (
            <AsyncLoader request={this.props.request}>
              <CardBody>
                <CardText>
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        <Button brand="primary" onClick={this.redirectForLWO}>
                          Go to Assure&#8480;
                        </Button>
                      </InputWrapper>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </AsyncLoader>
          );
        } else {
          return (
            <AsyncLoader request={this.props.request}>
              <CardBody>
                <CardText>
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        <Button brand="primary" onClick={this.redirectForDocumentGeneration}>
                          {'Generate my document(s)'}
                        </Button>
                      </InputWrapper>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </AsyncLoader>
          );
        }
      } else {
        if (
          stepId === 104 ||
          stepId === 107 ||
          stepId === 112 ||
          stepId === 1300 ||
          stepId === 1610
        ) {
          if (this.checkLockedForLWO(stepId)) {
            return (
              <AsyncLoader request={this.props.request}>
                <CardBody>
                  <CardText>
                    <div className="row col-12">
                      <div className="col-8"></div>
                      <div className="col-4">
                        <InputWrapper>
                          <Button brand="primary" onClick={this.redirectForLWO}>
                            Go to Assure&#8480;
                          </Button>
                        </InputWrapper>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </AsyncLoader>
            );
          } else {
            return <StandardFax stepId={stepId} forReducedIDR={forReducedIDR} />;
          }
        } else if (stepId === 150) {
          return (
            <AsyncLoader request={this.props.request}>
              <CardBody>
                <CardText>
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        <Button brand="primary" onClick={this.redirectForInSchoolQuestionnaire}>
                          {'Go to Questionnaire'}
                        </Button>
                      </InputWrapper>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </AsyncLoader>
          );
        } else if (stepId === 114) {
          return (
            <AsyncLoader request={this.props.request}>
              <CardBody>
                <CardText>
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        <Button brand="primary" onClick={this.redirectForDocumentGeneration}>
                          {'Repayment Details Needed'}
                        </Button>
                      </InputWrapper>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </AsyncLoader>
          );
        } else {
          return (
            <AsyncLoader request={this.props.request}>
              <CardBody>
                <CardText>
                  <div className="row col-12">
                    <div className="col-12">
                      <InputWrapper2>
                        <InputCheckbox
                          name="allowConfirmation"
                          label="I am ready to confirm that I have followed all of the above steps and am ready to continue."
                          onChange={(e) =>
                            this.handleChange(
                              { allowConfirmation: e.target.value },
                              'allowConfirmation',
                              'allowConfirmation'
                            )
                          }
                        ></InputCheckbox>
                      </InputWrapper2>
                    </div>
                  </div>
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        {this.props.additionalInfoNeeded === false ||
                        this.props.additionalInfoNeeded === undefined ? (
                          <>
                            <Button
                              id={'confirm'}
                              brand={'primary'}
                              onClick={this.onConfirm}
                              disabled={!this.state.showCheckbox}
                            >
                              {'Confirm'}
                            </Button>
                          </>
                        ) : (
                          <Button brand={'primary'} id={'update'} onClick={this.goToInfo}>
                            Update Missing Step Info
                          </Button>
                        )}
                      </InputWrapper>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </AsyncLoader>
          );
        }
      }
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    await dispatch(todosUpdateResource(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  idrConfirm: async (id) => {
    await dispatch(idrSaveComplete(id));
    await dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(StandardConfirmation));
