import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AsyncLoader, Button, Header5, Header6, InputWrapper } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import { documentsLoadCollection } from '../../../documents';

class DocsNeededForRSUpload extends React.Component {
  static propTypes = {
    docsNeeded: t.object,
    loadDocs: t.func,
    documentButton: t.bool,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '' };
  }

  componentDidMount() {
    if (Object.entries(this.props.docsNeeded).length < 1) {
      this.props.loadDocs();
    }
  }

  componentDidUpdate() {}

  redirectToDocuments = () => {
    this.setState({ redirectURI: '/documents' });
  };

  render() {
    const { request, docsNeeded, documentButton, permissions } = this.props;
    let docArray = Object.entries(docsNeeded);
    let taxesNeeded = false;
    let spouseTaxesNeeded = false;
    let paycheckNeeded = false;
    let spousepaycheckNeeded = false;
    let EmploymentCertificationNeeded = false;
    let pretaxLetterNeeded = false;
    let wtwoNeeded = false;
    let clientNSLDSNeeded = false;
    let spouseNSLDSNeeded = false;
    let driversLicenseNeeded = false;
    let fedLoanAuthNeeded = false;

    let documentsNeeded = true;
    //let allowedDownload = false;

    let taxText = '';
    let paystubText = '';

    if (docArray.length > 0) {
      docArray.forEach(([key, value]) => {
        let docArray2 = Object.entries(value);

        docArray2.sort();
        //checks to see if the name key has a value. sends back a string name,[value]
        let name = docArray2[10];
        let allowedDownload = docArray2[0];
        if (name[1] === '' && allowedDownload[1] === false) {
          docArray2.forEach(([key2, value2]) => {
            if (key2 === 'fileTypeCd') {
              if (value2 === 'TXS') {
                taxesNeeded = true;
              } else if (value2.includes('SECF')) {
                if (permissions.electronicEmpcertEnabled) {
                  EmploymentCertificationNeeded = false;
                } else {
                  EmploymentCertificationNeeded = true;
                }
              } else if (value2 === 'PYCHK') {
                paycheckNeeded = true;
              } else if (value2 === 'LNDT') {
                clientNSLDSNeeded = true;
              } else if (value2 === 'STXS') {
                spouseTaxesNeeded = true;
              } else if (value2 === 'SPYCK') {
                spousepaycheckNeeded = true;
              } else if (value2 === 'PTLH') {
                pretaxLetterNeeded = true;
              } else if (value2 === 'W2') {
                wtwoNeeded = true;
              } else if (value2 === 'SNLDS') {
                spouseNSLDSNeeded = true;
              } else if (value2 === 'DL') {
                driversLicenseNeeded = true;
              } else if (value2 === 'FlA') {
                fedLoanAuthNeeded = true;
              }
            }
          });
        }
      });
    }

    if (
      !taxesNeeded &&
      !spouseTaxesNeeded &&
      !paycheckNeeded &&
      !spousepaycheckNeeded &&
      !EmploymentCertificationNeeded
    ) {
      documentsNeeded = false;
    }

    if (taxesNeeded && spouseTaxesNeeded) {
      taxText = "The first two pages of you and your spouse's most recent Federal 1040";
    } else if (taxesNeeded && !spouseTaxesNeeded) {
      taxText = 'The first two pages of your most recent Federal 1040';
    } else if (!taxesNeeded && spouseTaxesNeeded) {
      taxText = "The first two pages of your spouse's most recent Federal 1040";
    }

    if (paycheckNeeded && spousepaycheckNeeded) {
      paystubText = "You and your spouse's most recent paychecks";
    } else if (paycheckNeeded && !spousepaycheckNeeded) {
      paystubText = 'Your most recent paychecks';
    } else if (!paycheckNeeded && spousepaycheckNeeded) {
      paystubText = "Your spouse's most recent paychecks";
    }

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      return (
        <AsyncLoader request={request}>
          <Header5>
            {documentsNeeded && 'The following documentation is needed:'}
            <p />
            <ul>
              {taxText !== '' && <li>{taxText}</li>}
              {paystubText !== '' && <li>{paystubText}</li>}

              {EmploymentCertificationNeeded && (
                <li>
                  The Employment Certification
                  <Header6>
                    <ul>
                      <li>1st page signed by you</li>
                      <li>
                        2nd page completed in its entirety and signed by an authorized official at
                        your public service/non-profit organization
                      </li>
                    </ul>
                  </Header6>
                </li>
              )}

              {wtwoNeeded && <li>A copy of your most recently received W-2(s)</li>}

              {driversLicenseNeeded && <li>A copy of your driver's license</li>}

              {pretaxLetterNeeded && (
                <li>
                  A pre-tax deduction detter
                  <Header6>
                    <ul>
                      <li>A template of the letter can be found under the My Documents section.</li>
                      <li>
                        Please forward the template to a member of your HR or Payroll department to
                        complete.
                      </li>
                    </ul>
                  </Header6>
                </li>
              )}

              {clientNSLDSNeeded && (
                <li>
                  National Student Loan Data System (NSLDS) summary page
                  <Header6>
                    <ul>
                      <li>
                        Instructions for this document can be found at the bottom of the My
                        Documents section of your account
                      </li>
                    </ul>
                  </Header6>
                </li>
              )}

              {spouseNSLDSNeeded && (
                <li>
                  Your spouse's National Student Loan Data System (NSLDS) summary page
                  <Header6>
                    <ul>
                      <li>
                        Instructions for this document can be found at the bottom of the My
                        Documents section of your account
                      </li>
                    </ul>
                  </Header6>
                </li>
              )}

              {fedLoanAuthNeeded && <li>FedLoan Servicing Authorization Form</li>}
            </ul>
          </Header5>
          {documentButton && documentsNeeded && (
            <div className="row col-12">
              <div className="col-8"></div>
              <div className="col-4">
                <InputWrapper>
                  <Button id={'goToDocuments'} brand={'primary'} onClick={this.redirectToDocuments}>
                    {'Upload Documents'}
                  </Button>
                </InputWrapper>
              </div>
            </div>
          )}
        </AsyncLoader>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  docsNeeded: state.documents.cache,
  request: state.documents.requests.loadCollection,
});

const mapDispatchToProps = (dispatch) => ({
  loadDocs: () => {
    dispatch(documentsLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(DocsNeededForRSUpload));
