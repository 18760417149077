import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Form,
  Header2,
  InputError,
  InputGroup,
  InputLabel,
  InputNumeric,
  InputRadio,
  InputWrapper,
} from 'fiducius-ui';

import { Fade } from '../../../routing';
import { safeAccess, useScrollToTopOnMount } from '../../../utils';
import EmployerForm from './employer-form';

const getEmployers = (initialSize, size, employers = [], errors) => {
  let forms = [];
  const totalSize = initialSize + (isNaN(size) ? 0 : parseInt(size));
  for (let i = 0; i < totalSize; i++) {
    let data = {};
    let employerErrors = {};
    if (i < totalSize) {
      data = employers[i];
      employerErrors['employmentEmployerName'] = safeAccess(errors, 'employmentEmployerName' + i);
      employerErrors['employmentJobTitle'] = safeAccess(errors, 'employmentJobTitle' + i);
      employerErrors['employmentStartDate'] = safeAccess(errors, 'employmentStartDate' + i);
      employerErrors['employmentEndDate'] = safeAccess(errors, 'employmentEndDate' + i);
      employerErrors['employmentEin'] = safeAccess(errors, 'employmentEin' + i);
      employerErrors['employmentPayPeriods'] = safeAccess(errors, 'employmentPayPeriods' + i);
      employerErrors['employmentHours'] = safeAccess(errors, 'employmentHours' + i);
      employerErrors['employmentAddress1'] = safeAccess(errors, 'employmentAddress1' + i);
      employerErrors['employmentAddress2'] = safeAccess(errors, 'employmentAddress2' + i);
      employerErrors['employmentCity'] = safeAccess(errors, 'employmentCity' + i);
      employerErrors['employmentState'] = safeAccess(errors, 'employmentState' + i);
      employerErrors['employmentZip'] = safeAccess(errors, 'employmentZip' + i);
      employerErrors['employmentEmploymentClassification'] = safeAccess(
        errors,
        'employmentEmploymentClassification' + i
      );
      employerErrors['employmentEmploymentType'] = safeAccess(
        errors,
        'employmentEmploymentType' + i
      );
    }

    forms.push(
      <EmployerForm key={`employer-form-${i}`} data={data} id={i} errors={employerErrors} />
    );
  }
  return forms;
};

const WorkflowAlert = styled.div`
  & > .alert {
    margin-left: 0;
  }
`;

const TabEmployment = ({ data = {}, errors = {}, handleChange, id, addEmployer }) => {
  useScrollToTopOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your employment</Header2>
      <InputWrapper error={!!errors.employmentSizeCorrect}>
        <Fade id="employmentCorrectGate" show={data.initialEmployerSize > 0}>
          <InputLabel>
            Our records indicate that you are currently working for the {data.initialEmployerSize}{' '}
            employer(s) listed below. Is that correct?
          </InputLabel>
          <InputError>{safeAccess(errors, 'employmentSizeCorrect.detail')}</InputError>
          <InputRadio name="employmentSizeCorrect" value="Y" label="Yes" />
          <InputRadio name="employmentSizeCorrect" value="N" label="No" />
        </Fade>
      </InputWrapper>
      <InputWrapper>
        <Fade id="newEmploymentGate" show={data.employmentSizeCorrect === 'Y'}>
          <InputLabel>Please confirm the information for your employer(s) is accurate.</InputLabel>
        </Fade>
      </InputWrapper>
      <Fade
        id="newEmploymentGate"
        show={data.employmentSizeCorrect === 'N' || data.initialEmployerSize === 0}
      >
        <InputWrapper error={!!errors.employmentNewEmployerAmount}>
          <InputLabel htmlFor="employmentNewEmployerAmount">
            How many new employers did you work for in the last year?
          </InputLabel>
          <InputError>{safeAccess(errors, 'employmentNewEmployerAmount.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric
              name="employmentNewEmployerAmount"
              min={1}
              defaultValue={data.employmentNewEmployerAmount}
            />
          </InputGroup>
        </InputWrapper>
        {data.initialEmployerSize > 0 && (
          <WorkflowAlert>
            <Alert brand="warning">
              Please enter the end date(s) for the employer(s) you are no longer employed with.
            </Alert>
          </WorkflowAlert>
        )}
        <Fade id="newEmploymentGate" show={data.employmentNewEmployerAmount > 0}>
          <WorkflowAlert>
            <Alert brand="warning">
              Please enter the information for your new employer(s) below.
            </Alert>
          </WorkflowAlert>
        </Fade>
      </Fade>
      {getEmployers(
        data.initialEmployerSize,
        data.employmentNewEmployerAmount,
        data.employers,
        errors
      )}
    </Form>
  );
};

TabEmployment.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  addEmployer: t.func,
  id: t.string.isRequired,
};

export default TabEmployment;
