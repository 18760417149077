import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { convertIsoToSlash } from '../../utils';

import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Header4,
  Header5,
  ProgressBar,
} from 'fiducius-ui';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';
const PaddedIcon = styled(FontAwesomeIcon)`
  margin-left: 0.45em;
`;

const ToolTipSpan = styled.span`
  @media (pointer: coarse), (hover: none) {
    [title] {
      position: relative;
      display: inline-flex;
      justify-content: center;
    }
    [title]:focus::after {
      content: attr(title);
      position: absolute;
      top: 90%;
      color: #000;
      background-color: #fff;
      border: 1px solid;
      width: fit-content;
      padding: 3px;
    }
  }
`;

class TuitionInstitutionHistoryTermRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    termId: t.number.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getAllStepsDenied() {
    const { data, institutionId, termId } = this.props;
    const term = data[institutionId].terms[termId];
    let retVal = false;
    if (Object.keys(term.classes).length > 0) {
      retVal = true;
      Object.keys(term.classes).forEach((myClass) => {
        if (term.classes[myClass].currentStatusCode !== 'REIMDENY') {
          retVal = false;
        }
      });
    }
    return retVal;
  }

  getCompletedSteps() {
    const { data, institutionId, termId } = this.props;
    const term = data[institutionId].terms[termId];

    let completedSteps = 8;
    const termEndDate = new Date(term.endDate);
    const today = new Date();

    if (Object.keys(term.classes).length > 0) {
      Object.keys(term.classes).forEach((myClass) => {
        switch (term.classes[myClass].currentStatusCode) {
          case 'CREATED':
            completedSteps = Math.min(completedSteps, 0);
            break;
          case 'WAIT':
            completedSteps = Math.min(completedSteps, 1);
            break;
          case 'APPR':
          case 'PREPAYAPPR':
          case 'PREPAYSENT':
          case 'PREPAYCOMP':
            completedSteps = Math.min(completedSteps, 2);
            break;
          case 'WAITPRFRPT':
          case 'WAITPRF':
          case 'WAITRPT':
            completedSteps = Math.min(completedSteps, 3);
            break;
          case 'PRFRPTUPLD':
          case 'PRFRPTAPPR':
          case 'PRFRPTDENY':
          case 'WAITPSTAPR':
          case 'PSTAPPR':
          case 'PSTDENY':
            completedSteps = Math.min(completedSteps, 4);
            break;
          case 'REIMSENT':
          case 'REIMDENY':
            completedSteps = Math.min(completedSteps, 5);
            break;
          case 'COMPLETE':
            completedSteps = Math.min(completedSteps, 6);
            break;
          case 'REIMOVER':
            if (today < termEndDate) {
              completedSteps = Math.min(completedSteps, 2);
            } else {
              completedSteps = Math.min(completedSteps, 5);
            }
            break;
          default:
            break;
        }
      });
    }

    return completedSteps;
  }

  getCurrentStepText(completedSteps) {
    let stepText = '';
    switch (completedSteps) {
      case 0:
        stepText = 'Create Class';
        break;
      case 1:
        stepText = 'Manager Approval Needed';
        break;
      case 2:
        stepText = 'School Term in Progress';
        break;
      case 3:
        stepText = 'Proof of Payment and Transcript Required';
        break;
      case 4:
        stepText = 'Reimbursement Approval Pending';
        break;
      case 5:
        stepText = this.getAllStepsDenied() ? 'Reimbursement Denied' : 'Reimbursement Processing';
        break;
      case 6:
        stepText = 'Reimbursement Sent';
        break;
      default:
        break;
    }
    return stepText;
  }

  getClassRowInformation(myClass, term) {
    if (myClass.currentStatusCode === 'PREPAYSENT' || myClass.currentStatusCode === 'REIMSENT') {
      return [
        myClass.className,
        <>
          <ToolTipSpan
            title={
              'Your reimbursement will be processed on ' +
              convertIsoToSlash(term.expectedPayoutDate) +
              '.'
            }
          >
            {myClass.currentStatus}
            <PaddedIcon icon={faQuestionCircle} fixedWidth />
          </ToolTipSpan>
        </>,
      ];
    }
    return [myClass.className, myClass.currentStatus];
  }

  componentDidMount() {}

  render() {
    const { data, institutionId, termId } = this.props;
    const institution = data[institutionId];
    const term = data[institutionId].terms[termId];
    const classes = [];
    const classHeaders = ['Class', 'Current Status'];
    const completedSteps = this.getCompletedSteps();
    const stepText = this.getCurrentStepText(completedSteps);
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <div className="row">
              <div className="col-12 col-sm-6">
                <dl>
                  <dd>{institution.institutionName}</dd>
                  <dd>
                    <Header4>{term.termName}</Header4>
                  </dd>
                </dl>
              </div>
              <div className="col-12 col-sm-6">
                <dl>
                  <dd>
                    <Header4>Term Reimbursement Progress</Header4>
                  </dd>
                  <dd>
                    {completedSteps < 8 && (
                      <ProgressBar
                        categories={[
                          {
                            brand: 'success',
                            name: 'success',
                            title: 'Completed',
                            value: (100 * completedSteps) / 6,
                          },

                          {
                            brand: this.getAllStepsDenied() ? 'danger' : 'info',
                            name: 'info',
                            title: 'Current',
                            value: (100 * (completedSteps === 6 ? 0 : 1)) / 6,
                          },
                          {
                            brand: 'warning',
                            name: 'warning',
                            title: 'To Be Completed',
                            value: (100 * (completedSteps === 6 ? 0 : 5 - completedSteps)) / 6,
                          },
                        ]}
                      />
                    )}
                  </dd>
                  <dd>
                    <Header5>Stage: {stepText}</Header5>
                  </dd>
                </dl>
              </div>
            </div>
          </CardTitle>
          <CardText>
            <div className="col-12">
              {Object.keys(term.classes).length > 0 &&
                Object.keys(term.classes).forEach((myClass) => {
                  classes.push(this.getClassRowInformation(term.classes[myClass], term));
                })}
              {classes.length > 0 && <Table header={classHeaders} body={classes} />}
            </div>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (state) => {};

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionHistoryTermRow);
