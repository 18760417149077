import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import {
  Form,
  Header2,
  Header3,
  Header4,
  InputError,
  InputLabel,
  InputRadio,
  InputWrapper,
  AsyncLoader,
  Button,
  Lede,
} from 'fiducius-ui';

import { safeAccess, mergeRequestStatuses, debounce } from '../../../utils';

import { getTuitionInstitutionData } from '../../../benefits/tuition';

import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import {
  TuitionInstitutionTermForm,
  tuitionInstitutionTermClearForm,
  tuitionInstitutionTermHandleFormChange,
  termCreateResource,
} from '../../../tuition-institution-term';

import { withPermissions } from '../../../routing';

import { todosLoadCollection } from '../../../todos';

import { getToken, authLoadPermissions } from '../../../auth';

class TabTerm extends React.Component {
  static propTypes = {
    institutionData: t.object,
    data: t.object,
    errors: t.object,
    institutionErrors: t.object,
    request: t.object,
    getRequest: t.object,
    handleChange: t.func,
    loadInstitution: t.func.isRequired,
    clearTermForm: t.func.isRequired,
    handleTermForm: t.func.isRequired,
    createTerm: t.func.isRequired,
    id: t.string.isRequired,
    form: t.object,
    token: t.any,
  };

  constructor(props) {
    super(props);
    this.state = { showForm: false };
  }

  componentDidMount() {
    this.props.loadInstitution();
  }

  componentDidUpdate(prevProps) {
    const prevLoading = prevProps.request.isLoading;
    const current = this.props.request;

    if (prevLoading && !current.isLoading && !current.hasFailed) {
      //alert("I didn't fail");
      this.closeForm();
    }
  }

  addTerm = () => {
    this.setState({ showForm: true });
  };

  onChangeTerm = debounce((tuitionInstitutionTerm, id, attributes) => {
    this.props.handleTermForm({ ...this.props.form, ...tuitionInstitutionTerm }, [attributes]);
  }, 250);

  onCreateTerm = () => {
    let { form, data, errors } = this.props;
    form['institutionId'] = form['institutionId'] || data.institutionId;
    this.props.createTerm().then((addedId) => {
      if (addedId && addedId > 0) {
        data['termId'] = addedId;
        errors.termId = undefined;
        this.props.handleChange({ ...data }, 0, 'termId');
      }
    });
  };

  closeForm = () => {
    this.props.clearTermForm();
    this.setState({
      ...this.state,
      showForm: false,
    });
  };

  noTermsOnFile() {
    return (
      <Header4>
        You have no terms on file to select. Please use the Add New Term button below to add a new
        one.
      </Header4>
    );
  }

  noInstitutionSelected() {
    return (
      <Header4>
        You have not yet selected an institution. Please go back to the Institution section and
        select an existing institution or add a new institution.
      </Header4>
    );
  }

  termIdMatchCheck(termIdA, termIdB) {
    let retVal = false;
    if (termIdA !== undefined && termIdA !== null && termIdB !== undefined && termIdB !== null) {
      retVal = parseInt(termIdA) === parseInt(termIdB);
    }
    return retVal;
  }

  getInstitutionList = () => {
    const { institutionData } = this.props;
    let realInstitutions = Object.keys(institutionData).reduce(
      (obj, k) => ({
        ...obj,
        [k]: institutionData[k].institutionName,
      }),
      {}
    );
    let institutions = { ...realInstitutions };
    return institutions;
  };

  render() {
    const {
      data = {},
      form,
      institutionData,
      errors = {},
      getRequest,
      request,
      handleChange,
      id,
      institutionErrors,
    } = this.props;
    const { showForm } = this.state;
    const terms = [];
    let hasEditableTerms = false;

    return (
      <>
        <Header2>Term Information</Header2>

        <AsyncLoader request={getRequest} empty={this.noInstitutionSelected()}>
          {data.institutionId && (
            <>
              <Header3>Institution: {institutionData[data.institutionId].institutionName}</Header3>

              <Form handleChange={handleChange} id={id} defaults={data}>
                <div className="row">
                  <div className="col-12">
                    {institutionData[data.institutionId].terms.length > 0 && (
                      <InputWrapper error={!!safeAccess(errors, 'termId')}>
                        {hasEditableTerms && (
                          <div>
                            <InputLabel htmlFor="termId">Select a Term:</InputLabel>
                            <InputError>{safeAccess(errors, 'termId.detail')}</InputError>
                          </div>
                        )}

                        {Object.keys(institutionData[data.institutionId].terms).forEach((term) => {
                          if (institutionData[data.institutionId].terms[term].editable) {
                            hasEditableTerms = true;
                            terms.push(
                              <InputRadio
                                name="termId"
                                value={institutionData[data.institutionId].terms[term].id}
                                label={institutionData[data.institutionId].terms[term].termName}
                                defaultChecked={this.termIdMatchCheck(
                                  institutionData[data.institutionId].terms[term].id,
                                  data.termId
                                )}
                              />
                            );
                          }
                        })}
                        {data.institutionId && terms.length > 0 && <>{terms}</>}
                      </InputWrapper>
                    )}
                    {data.institutionId && terms.length === 0 && this.noTermsOnFile()}
                  </div>
                </div>
              </Form>
            </>
          )}
          {!data.institutionId && this.noInstitutionSelected()}
        </AsyncLoader>
        {data.institutionId && (
          <Lede>
            <Button brand="primary" onClick={() => this.addTerm()}>
              Add New Term
            </Button>
          </Lede>
        )}
        {showForm && (
          <TuitionInstitutionTermForm
            action={'Add'}
            closeForm={this.closeForm}
            data={form}
            errors={institutionErrors}
            isLoading={request.isLoading}
            getResource={getRequest}
            isOpen={showForm}
            onChange={this.onChangeTerm}
            onSubmit={this.onCreateTerm}
            institutionList={this.getInstitutionList()}
            defaultInstitutionId={this.props.data.institutionId}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  const termForm = safeAccess(state.tuitionInstitutionTerm, 'form');
  const termRequests = safeAccess(state.tuitionInstitutionTerm, 'requests');
  const termErrors = safeAccess(state.tuitionInstitutionTerm, 'errors');
  return {
    institutionData: getTuitionInstitutionData(state),
    token: getToken(state),
    getRequest: requests.loadCollection,
    institutionErrors: termErrors,
    request: mergeRequestStatuses([
      termRequests.createResource,
      termRequests.updateResource,
      termRequests.deleteResource,
    ]),
    form: termForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
  clearTermForm: () => dispatch(tuitionInstitutionTermClearForm()),
  createTerm: async () => {
    let addedId = await dispatch(termCreateResource());
    if (addedId && addedId > 0) {
      dispatch(authLoadPermissions());
      dispatch(tuitionInstitutionLoadCollection());
      dispatch(todosLoadCollection());
    }
    return addedId;
  },
  handleTermForm: (tuitionInstitutionTerm, attributes) =>
    dispatch(tuitionInstitutionTermHandleFormChange(tuitionInstitutionTerm, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabTerm));
