import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import {
  Alert,
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  DashboardSection,
  Header2,
  Modal,
  CardHeader,
  Link,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { debounce, isEqual, mergeRequestStatuses, safeAccess } from '../../../utils';

import QuotesTable from '../components/quotes-table';
import RefinanceForm from '../components/form';
import {
  refiDisableValidation,
  refiGetExistingQuotes,
  refiSendApply,
  refiSendForQuotes,
  refiUpdateOpeId,
  refiGetForm,
  refiMakeFormReady,
} from '../redux/operations';

import { AffiliateLink } from '../../affiliates';

export const LENDKEY_PROVIDER_ID = 3;
export const SPLASH_PROVIDER_ID = 4;
export const LAURELROAD_PROVIDER_ID = 5;
export const SOFI_PROVIDER_ID = 6;

const StyledLoader = styled.div`
  margin-bottom: 2rem;
`;

const TextLeft = styled(CardText)`
  text-align: left;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class MyRefinance extends React.Component {
  static propTypes = {
    applyRequest: t.object,
    getForm: t.func.isRequired,
    getFormRequest: t.object,
    getQuotes: t.func.isRequired,
    getQuotesRequest: t.object,
    data: t.object,
    id: t.string,
    quotes: t.array,
    sendApply: t.func.isRequired,
    updateForm: t.func.isRequired,
    alertText: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      disclaimerIsOpen: false,
      hasRequestedQuotes: false,
      initialLoad: false,
      institutionName: null,
      hasAddress: false,
      redirectURL: '',
      alertText: undefined,
    };
  }

  componentDidMount() {
    this.props.getForm(this.state);
  }

  componentDidUpdate(prevProps) {
    const { applyRequest: prevApply, getFormRequest: prevForm } = prevProps;
    const { applyRequest: currentApply, getFormRequest: currentForm, data } = this.props;
    const selectionUrl = safeAccess(data, 'selectionUrl', '');
    const opeId = safeAccess(data, 'opeId');

    if (prevForm.isLoading && currentForm.hasFinished && !currentForm.hasFailed && opeId) {
      this.setState({ ...this.state, hasRequestedQuotes: true }, () => this.props.getQuotes());
    }

    if (
      prevApply.isLoading &&
      currentApply.hasFinished &&
      !currentApply.hasFailed &&
      selectionUrl &&
      this.state.redirectURL === ''
    ) {
      this.setState({ ...this.state, redirectURL: selectionUrl });
      //window.location(selectionUrl);
    }

    const { applyRequest } = this.props;

    if (
      prevProps.applyRequest.isLoading &&
      applyRequest.hasFinished &&
      !applyRequest.hasFailed &&
      selectionUrl
    ) {
      //alert(selectionUrl);
      this.setState({ ...this.state, redirectURL: selectionUrl });
      //window.location(selectionUrl);
    }
  }

  getQuotes = (e) => {
    e.preventDefault();
    this.props.getQuotes();
    this.setState({ ...this.state, hasRequestedQuotes: true });
  };

  handleChange = debounce((formState) => {}, 200);

  updateForm = debounce((opeId) => {
    this.props.updateForm(opeId);
  });

  toggleDisclaimer = () =>
    this.setState({
      ...this.state,
      disclaimerIsOpen: !this.state.disclaimerIsOpen,
    });

  render() {
    const { data, id, getFormRequest, getQuotesRequest, quotes, sendApply } = this.props;
    if (
      this.state.redirectURL !== null &&
      this.state.redirectURL !== '' &&
      this.state.redirectURL.length > 0
    ) {
      return <Redirect to={this.state.redirectURL} />;
    } else {
      return (
        <>
          <DashboardSection title="My Refinancing">
            <Header2>
              Lower your rates and improve your payment schedule through private loan refinancing
            </Header2>

            <div className="row">
              <div className="col-12">
                <Alert brand="primary">
                  <p>
                    ***Unsure of what to do based on recent governmental announcements regarding
                    student loans?{' '}
                    <a href="https://getfiducius.com/news-insights/" target="_blank">
                      Click here
                    </a>{' '}
                    and view our News and Insights page to stay updated.
                  </p>
                </Alert>
                <AsyncLoader request={getFormRequest}>
                  {safeAccess(data, `[${id}].providerId`, false) === SPLASH_PROVIDER_ID && (
                    <Card brand="success">
                      <CardHeader>You're qualified</CardHeader>
                      <CardBody>
                        <CardText>
                          <p>
                            Lower your rates and improve your payment schedule through private loan
                            refinancing.
                          </p>
                          <p>
                            Click get quote to receive refinancing options through our trusted
                            partner. Checking your rate will not affect your credit score, and the
                            Fiducius team will always be here for support.
                          </p>
                          {/* TODO: Turn into link that looks like a button */}
                          <a
                            href="https://splashfinancial.com/partner/Fiducius"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Get Quotes
                          </a>
                        </CardText>
                      </CardBody>
                    </Card>
                  )}
                  {safeAccess(data, `[${id}].providerId`, false) === LAURELROAD_PROVIDER_ID && (
                    <>
                      <Card brand="success">
                        <CardHeader>Check Your Rate Options</CardHeader>
                        <CardBody>
                          <CardText>
                            <p>Fast, easy refinancing—without the kinks.</p>
                            <p>
                              We offer a simplified process with zero added costs or fees. Discover
                              your rate options online in less than 5 minutes – no hard credit pull
                              required.<sup>1.</sup>
                            </p>
                            <p>
                              Refinancing with Laurel Road loan could provide you with an
                              opportunity to do one or more of the following:
                            </p>
                            <ul>
                              <li>Lower interest rate</li>
                              <li>Lower monthly payment</li>
                              <li>Pay off loans faster</li>
                              <li>Choose a fixed or variable rate</li>
                              <li>Reduce number of loans</li>
                              <li>Save money</li>
                            </ul>
                            {/* TODO: Turn into link that looks like a button */}
                            <a
                              href="https://www.laurelroad.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Get Quotes
                            </a>
                          </CardText>
                        </CardBody>
                      </Card>
                      <p>
                        <sup>1.</sup>&nbsp;Checking your rate with Laurel Road only requires a soft
                        credit pull, which will not affect your credit score. To proceed with an
                        application, a hard credit pull will be required, which may affect your
                        credit score.
                      </p>
                    </>
                  )}
                  {safeAccess(data, `[${id}].providerId`, false) === SOFI_PROVIDER_ID && (
                    <>
                      <Card brand="success">
                        <CardHeader>Check Your Rate Options</CardHeader>
                        <CardBody>
                          <CardText>
                            <p>Fast, easy refinancing—without the kinks.</p>
                            <p>
                              Refinancing with SoFi could provide you with an opportunity to do one
                              or more of the following:
                            </p>
                            <ul>
                              <li>Lower interest rate</li>
                              <li>Lower monthly payment</li>
                              <li>Pay off loans faster</li>
                              <li>Choose a fixed or variable rate</li>
                              <li>Reduce number of loans</li>
                              <li>Save money</li>
                            </ul>
                            {/* TODO: Turn into link that looks like a button */}
                            <AffiliateLink
                              affiliateName="sofi"
                              linkText="Get Quotes"
                              targetText={'_blank'}
                              relText={'noopener noreferrer'}
                            />
                          </CardText>
                        </CardBody>
                      </Card>
                    </>
                  )}
                  {safeAccess(data, `[${id}].providerId`, false) === LENDKEY_PROVIDER_ID &&
                    this.state.initialLoad && (
                      <Card>
                        <CardBody>
                          <CardTitle>My Refinance Quotes</CardTitle>
                          {!this.state.hasAddress && (
                            <CardText>
                              We need you to fill out your address information on your{' '}
                              <Link to="/profile">Profile</Link> before you can continue.
                            </CardText>
                          )}
                          {this.state.hasAddress && !this.state.hasRequestedQuotes && (
                            <RefinanceForm
                              handleChange={this.handleChange}
                              onSubmit={this.getQuotes}
                              data={this.state.institutionName}
                              updateOpeId={this.updateForm}
                            />
                          )}
                        </CardBody>
                        {this.state.hasAddress && this.state.hasRequestedQuotes && (
                          <StyledLoader>
                            <AsyncLoader request={getQuotesRequest}>
                              {quotes && <QuotesTable quotes={quotes} sendApply={sendApply} />}
                            </AsyncLoader>
                          </StyledLoader>
                        )}
                      </Card>
                    )}
                </AsyncLoader>
              </div>
            </div>
            {!getFormRequest.isLoading &&
              safeAccess(data, `[${id}].providerId`, false) === LENDKEY_PROVIDER_ID && (
                <>
                  <FlexEnd>
                    <Button brand="tertiary" onClick={this.toggleDisclaimer}>
                      View disclaimer
                    </Button>
                  </FlexEnd>
                  <Modal isOpen={this.state.disclaimerIsOpen}>
                    <Card>
                      <CardHeader>LendKey Legal Disclaimer</CardHeader>
                      <CardBody>
                        <TextLeft>
                          <p>
                            <small>
                              The estimated loan option and terms displayed are based on information
                              that you provided on this website and are subject to change. LendKey
                              Technologies, Inc. ("LendKey") is not responsible for any inaccurate
                              information disclosed on this website. In the event that you would
                              like to move forward with the loan option displayed, you will be
                              required to complete an application with the lender on LendKey's
                              website, at which point we will conduct a credit report inquiry,
                              verify the information you provided, and collect additional
                              information from you (and your co-signer, if applicable) for the
                              lender to determine whether you qualify for the loan.
                            </small>
                          </p>
                          <p>
                            <small>
                              Terms and conditions apply. If approved for the loan, the interest
                              rate offered to you will depend on your (and your co-signer's, if
                              applicable) credit profile and application. Lowest advertised rates
                              are generally reserved for borrowers with excellent credit scores. All
                              applicants must (i) be either U.S. citizens or Permanent Residents in
                              an eligible state and graduated from an eligible school; (ii) meet the
                              age of majority requirements as determined by the applicant's state of
                              residence at the time of application; and (iii) meet the lender's
                              credit and income requirements. If the applicant wishes to accept a
                              loan offered from a credit union lender, the applicant must be a
                              member of the credit union, and, therefore, certain membership
                              requirements (including the opening of a share account and any
                              applicable association fees in connection with membership) may apply.
                              Applicants may complete a credit union membership application during
                              the loan application process on LendKey's website. Applying with a
                              creditworthy co-signer may result in a better chance of loan approval
                              and/or lower interest rate. APR = Annual Percentage Rate. For
                              variable-rate loans, the variable rates may increase after loan
                              consummation. Loans that were used for exam preparation classes,
                              including, but not limited to, loans for LSAT, MCAT, GMAT, and GRE
                              preparation are not eligible for refinancing with a lender via
                              LendKey.com. If you are approved for the loan, please refer to your
                              credit agreement Private Education Loan Disclosures for more
                              information about the loan and any applicable terms and borrower
                              responsibilities. Additionally, you may contact LendKey at
                              866-840-3502 if you have any questions. Lenders participating on
                              LendKey.com reserve the right to modify or discontinue the products,
                              terms, and benefits offered at any time without notice.
                            </small>
                          </p>
                          <p>
                            <small>
                              In some circumstances, the lender of the loan may offer a 0.25%
                              reduction if you choose to enroll in automatic monthly payments from a
                              personal checking or savings account to pay principal and interest
                              amounts that are due. The reduction may be removed and the rate will
                              be increased (leading to higher payments) upon any cancellation or
                              failed collection attempt of the automatic payment, and will be
                              suspended during any period of deferment or forbearance. Additionally,
                              in some circumstances, the lender of the loan may offer co-signer
                              release. In order to qualify for co-signer release, the borrower must
                              have made the required minimum number of on-time full principal and
                              interest payments immediately prior to the request to have the
                              co-signer released (Any period of forbearance will restart the clock);
                              the loan account cannot be in delinquent status; the borrower must
                              provide proof of income indicating he/she meets the income
                              requirements and pass a credit review demonstrating that he/she has a
                              satisfactory credit history and the ability to assume full
                              responsibility of loan repayment; no bankruptcies or foreclosures in
                              the last 60 months; and no loan defaults.
                            </small>
                          </p>
                          <p>
                            <small>
                              By selecting a conditional loan offer from LendKey on this website and
                              continuing to LendKey's website to complete a full application, you
                              authorize LendKey, any of its affiliates, agents, assignees, service
                              providers, and participating lenders who you apply for a loan with via
                              LendKey's website, to contact you at the phone number(s) (including
                              mobile device numbers) you provide or associated with your account
                              and/or loan, including, but not limited to calling or texting you via
                              an automated dialing device, leaving you a voice message (including a
                              prerecorded or artificial message sent via an automated dialing
                              device), and sending you an email or other electronic message for any
                              purpose related to your use of this website, an application you start
                              or complete on this website, or for research purposes (e.g., customer
                              surveys), even if you will be charged by your mobile service
                              providers.
                            </small>
                          </p>
                        </TextLeft>
                      </CardBody>
                      <CardFooter>
                        <Button brand="lowContrast" onClick={this.toggleDisclaimer}>
                          Close
                        </Button>
                      </CardFooter>
                    </Card>
                  </Modal>
                </>
              )}
          </DashboardSection>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { cache, requests } = state.refi;
  const quotes = cache && cache.quotes && !isEqual(cache.quotes, {}) ? cache.quotes : [];
  return {
    applyRequest: requests.updateResource,
    // TODO: This is bad because we're using cache for more than one model at different times
    // Super brittle. Needs a re-write ASAP.
    data: cache,
    id: getUserId(state),
    quotes: quotes,
    getFormRequest: requests.loadResource,
    getQuotesRequest: mergeRequestStatuses([requests.updateResource, requests.loadResource]),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getForm: async (state) => {
    await dispatch(refiDisableValidation());
    let data = await dispatch(refiGetExistingQuotes());
    state.hasRequestedQuotes = data && data.length > 0;
    dispatch(refiGetForm())
      .then(({ institutionName, hasAddress }) => {
        state.institutionName = institutionName;
        state.hasAddress = hasAddress;
      })
      .then(() => {
        state.initialLoad = true;
        dispatch(refiMakeFormReady());
      });
  },
  getQuotes: () => dispatch(refiSendForQuotes()),
  sendApply: (id) => dispatch(refiSendApply(id)),
  updateForm: (opeId) => dispatch(refiUpdateOpeId(opeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyRefinance);
