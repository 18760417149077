import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Header5,
  Header3,
  Table,
  Lede,
} from 'fiducius-ui';

import { safeAccess, formatAsMoney, objToArray } from '../../../utils';

import styled from 'styled-components';
import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import BtsBorrowingCompare from '../components/bts-borrowing-comparison';

import { questionnaireBtsLoadCollection } from '../../../workflows/questionnaire-bts/redux/operations';

const LeftContent = styled.div`
  margin-right: -1px;
  padding-right: 5px;
`;

const RightContent = styled.div`
  margin-left: -1px;
  padding-right: 5px;
`;

const StyledHeader5 = styled(Header5)`
  color: ${(p) => p.theme.textPrimary};
`;

const BottomContent = styled.div`
  margin-top: 1em;
`;

const VerticalLine = styled.div`
  @media (min-width: 768px) {
    border-left: 1px solid ${(p) => p.theme.textPrimary};
  }
`;

const ButtonRight = styled(Button)`
  float: right;
  margin-top: 10px;
  margin-right: 5px;
`;

const TabbedDD = styled.dd`
  margin-left: 15px;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class BtsReview extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    flatData: t.array,
    loadEducation: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = { buttonType: '', monthly: true, showHistory: false, redirectUrl: '' };
  }

  componentDidMount() {
    this.props.loadEducation(this.props.id);
  }

  componentDidUpdate() {
    /*const { data, flatData } = this.props;
    if (this.state.buttonType.length > 0) {
      this.setState({ buttonType: '', redirectUrl: '' });
    }*/
  }

  educationCreditTaxesInfo = () => {
    return (
      <div className="col">
        <CardTitle>
          <Header3>Education Credits and Taxes</Header3>
        </CardTitle>
        <p>
          An education credit is a type of tax credit available to students of a post-secondary
          educational institution, such as a college or university. If the credit reduces your tax
          to less than zero, you may receive a tax refund. You have two education credits available
          to utilize: American opportunity tax credit (AOTC) and the lifetime learning credit (LLC).
        </p>
        <p>
          <StyledHeader5>Basic Requirements:</StyledHeader5>
        </p>
        <ul>
          <li>
            You, your dependent, or a third party pays qualified education expenses for higher
            education
            <ul>
              <li>A third party is described as a relative or friend</li>
            </ul>
          </li>
          <li>To be eligible a student must be enrolled at an eligible educational institution</li>
          <li>
            You, your spouse, or your dependent listed on your tax return is the eligible student
          </li>
        </ul>
        <p>
          <StyledHeader5>More Information:</StyledHeader5>
        </p>
        <p>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.irs.gov/credits-deductions/individuals/education-credits-aotc-llc"
              >
                Education Credits
              </a>{' '}
              as defined by the IRS
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.eitc.irs.gov/other-refundable-credits-toolkit/compare-education-credits/compare-education-credits"
              >
                AOTC and LLC Comparison
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.irs.gov/help/ita/am-i-eligible-to-claim-an-education-credit"
              >
                IRS Interactive Tax Assistant
              </a>
            </li>
          </ul>
        </p>
      </div>
    );
  };

  fafsaFinancialAidInfo = () => {
    return (
      <div className="col">
        <CardTitle>
          <Header3>Financial Aid</Header3>
        </CardTitle>
        <p>
          Financial aid is classified as any funding that assists a student in paying for schooling
          through a post-secondary educational institution, such as a college or university. There
          are several forms of financial aid that you may be eligible for, those are the following:
        </p>
        <p>
          <StyledHeader5>Federal Student Loans:</StyledHeader5>
        </p>
        <p>
          Federal student loans are loans borrowed from federal loan servicers that must be paid
          back upon your graduation. The William D. Ford Federal Direct Loan Program is the current
          federal loan program and under this program any loan borrowed will be classified as a
          Direct loan. When borrowing, to meet our recommendations, the loans you will receive will
          be either a Direct Subsidized or Unsubsidized loan, but there are other types of federal
          student loans available, those include the following:
        </p>
        <p>
          <ul>
            <li>Direct Parent Plus loans</li>
            <li>
              Direct Graduate Plus loans
              <ul>
                <li>
                  To determine your eligibility for this type of loan, you will need to contact your
                  school's financial aid office
                </li>
              </ul>
            </li>
          </ul>
        </p>

        <p>
          <StyledHeader5>Grants:</StyledHeader5>
        </p>
        <p>
          A grant is a form of financial aid that does not have to be paid back as long as you
          maintain eligibility for the grant.
        </p>
        <p>
          <StyledHeader5>Scholarships:</StyledHeader5>
        </p>
        <p>
          Most colleges or universities offer scholarships to assist students in paying for tuition.
        </p>
        <p>
          <StyledHeader5>Work-study:</StyledHeader5>
        </p>
        <p>
          The Federal Work-Study Program allows you to work part-time and earn money to for your
          schooling.
        </p>
        <p>
          <StyledHeader5>Aid for Military Families:</StyledHeader5>
        </p>
        <p>
          Additional aid eligibility and special aid programs are available for serving in the
          military or for being the spouse or child of a veteran. Available aid includes the
          following:
        </p>

        <p>
          <ul>
            <li>Reserve Officers' Training Corps (ROTC) Scholarships</li>
            <li>Department of Veteran Affairs Education Benefits</li>
            <li>Iraq and Afghanistan Service Grant</li>
            <li>Federal Pell Grant</li>
          </ul>
        </p>
      </div>
    );
  };

  benefitsRetirementInfo = () => {
    return (
      <div className="col">
        <CardTitle>
          <Header3>Benefits and Retirement</Header3>
        </CardTitle>
        <p>
          As you get closer to your graduation date and the start of your Income-Driven Repayment,
          you should consider reviewing the benefits offered by your employer and your retirement
          options.
        </p>
        <p>
          Certain retirement plans and benefits are classified as pre-tax deductions which lower
          your adjusted gross income. A lower adjusted gross income will result in a lower
          Income-Driven Repayment and, in turn, can increase your projected loan forgiveness.{' '}
        </p>

        <p>
          If you are curious about seeing the impact of making changes to your benefits or
          retirement, you can utilize the Fiducius{' '}
          <Link to="/tools/pretax-tool">Pre-Tax Deduction</Link> and{' '}
          <Link to="/tools/retirement-tool">Retirement Comparison</Link> tools under Benefit Tools
          in your left-navigation.
        </p>
      </div>
    );
  };

  removeEmptyEntries = (array) => {
    if (array.length > 0) {
      var retArray = [];
      for (let i = 0; i <= array.length; i++) {
        if (array[i] !== undefined) {
          array[i].forEach((a) => {
            if (a !== undefined && a !== '') retArray.push(a);
          });
        }
      }
    }
    return retArray;
  };

  handleEducationClick = (buttonType) => {
    this.setState({ ...this.state, buttonType: buttonType });
  };

  handleChartHistory = () => {
    this.setState({ ...this.state, showHistory: !this.state.showHistory });
  };

  handleRedirect = () => {
    this.setState({ redirectUrl: '/contact' });
  };

  handleYearlyMonthly = () => {
    this.setState({ ...this.state, monthly: !this.state.monthly });
  };

  getPrevYearBal = (initial, previous) => {
    if (initial !== undefined && initial.length > 0) {
      var retArray = [];
      for (let i = 0; i <= initial.length; i++) {
        if (initial[i] !== undefined) {
          if (i === 0) {
            retArray.push(initial[i]);
          } else {
            retArray.push(previous[i - 1]);
          }
        }
      }
    }
    return retArray;
  };

  pivotDataTable = (headers, body) => {
    if (body !== undefined && body.length > 0) {
      var retArray = [];

      for (let i = 0; i < headers.length; i++) {
        var oneDimArray = [];
        for (let j = 0; j <= body.length; j++) {
          if (j === 0) {
            oneDimArray.push(headers[i]);
          } else {
            oneDimArray.push(body[j - 1][i]);
          }
        }
        retArray.push(oneDimArray);
      }
    }
    return retArray;
  };

  getPivotHeaders = (body) => {
    let retArray = [];
    if (body !== undefined && body.length > 0) {
      for (let i = 0; i <= body.length; i++) {
        if (i === 0) {
          retArray.push(' ');
        } else if (i === body.length) {
          retArray.push('Current Snapshot');
        } else {
          retArray.push('Year ' + i);
        }
      }
    }
    return retArray;
  };

  getTuitionLeft = (
    livingExp,
    recommended,
    newLoanBalance,
    initialLoanBalance,
    livingExpensePercent,
    tuitionPercent
  ) => {
    let negativeLivingExp = 0;
    let tuitionBorrowed =
      (newLoanBalance[newLoanBalance.length - 1] - initialLoanBalance[0]) * tuitionPercent;
    let tuitionLeftToBorrow = recommended[0] - tuitionBorrowed;
    let livingExpBorrowed =
      (newLoanBalance[newLoanBalance.length - 1] - initialLoanBalance[0]) * livingExpensePercent;
    let livingExpLeftToBorrow = livingExp[0] - livingExpBorrowed;

    if (livingExpLeftToBorrow < 0) {
      negativeLivingExp = livingExpLeftToBorrow;
    }

    if (tuitionLeftToBorrow < 0) {
      return 0;
    } else {
      return tuitionLeftToBorrow + negativeLivingExp;
    }
  };

  getLivingExpLeft = (
    livingExp,
    recommended,
    newLoanBalance,
    initialLoanBalance,
    livingExpensePercent,
    tuitionPercent
  ) => {
    let negativeTuition = 0;
    let tuitionBorrowed =
      (newLoanBalance[newLoanBalance.length - 1] - initialLoanBalance[0]) * tuitionPercent;
    let tuitionLeftToBorrow = recommended[0] - tuitionBorrowed;
    let livingExpBorrowed =
      (newLoanBalance[newLoanBalance.length - 1] - initialLoanBalance[0]) * livingExpensePercent;
    let livingExpLeftToBorrow = livingExp[0] - livingExpBorrowed;

    if (tuitionLeftToBorrow < 0) {
      negativeTuition = tuitionLeftToBorrow;
    }

    if (livingExpLeftToBorrow < 0) {
      return 0;
    } else {
      return livingExpLeftToBorrow + negativeTuition;
    }
  };

  render() {
    const { data, request } = this.props;

    let recommendedBorrowingRemain = 0;
    let recommendedBorrowingRemainAddInfo = 0;

    const headers = [
      'Starting Loan Balance',
      'New Loan Balance Borrowed',
      'Estimated New Loan Balance',
      'Expected Income',
      'Estimated Standard Payment',
      'Estimated IDR',
      'Estimated Savings',
    ];
    const tableVariables = [
      'initialLoanBalance',
      'newLoanAmtBorrowed',
      'estimatedLoanBalance',
      'graduationIncome',
      'monthlyStandardPayment',
      'estimatedIdr',
      'monthlySavings',
    ];
    const variables = [
      'id',
      'initialLoanBalance',
      'newLoanAmtBorrowed',
      'estimatedLoanBalance',
      'recommendedBorrowing',
      'recommendedBorrowingTuition',
      'recommendedBorrowingLivingExp',
      'estimatedIdr',
      'graduationIncome',
      'monthlyStandardPayment',
    ];

    let body = Object.values(data).map((a) =>
      tableVariables.map((h) => (h === 'id' ? a[h] : formatAsMoney(a[h])))
    );
    let totalBorrowRec = Object.values(data).map((a) =>
      variables.map((h) => (h === 'recommendedBorrowing' ? a[h] : undefined))
    );
    let newLoanBalance = Object.values(data).map((a) =>
      variables.map((h) => (h === 'estimatedLoanBalance' ? a[h] : undefined))
    );
    let initLoanBalance = Object.values(data).map((a) =>
      variables.map((h) => (h === 'initialLoanBalance' ? a[h] : undefined))
    );
    let recTuitionToBorrow = Object.values(data).map((a) =>
      variables.map((h) => (h === 'recommendedBorrowingTuition' ? a[h] : undefined))
    );
    let recLivingExpToBorrow = Object.values(data).map((a) =>
      variables.map((h) => (h === 'recommendedBorrowingLivingExp' ? a[h] : undefined))
    );
    let newYearlyBorrowedAmt = Object.values(data).map((a) =>
      variables.map((h) => (h === 'newLoanAmtBorrowed' ? a[h] : undefined))
    );
    let EstIDR = Object.values(data).map((a) =>
      variables.map((h) => (h === 'estimatedIdr' ? a[h] : undefined))
    );
    let ExpectedIncome = Object.values(data).map((a) =>
      variables.map((h) => (h === 'graduationIncome' ? a[h] : undefined))
    );
    let EstStandard = Object.values(data).map((a) =>
      variables.map((h) => (h === 'monthlyStandardPayment' ? a[h] : undefined))
    );
    let loanTicks = Object.values(data).map((a) =>
      variables.map((h) => (h === 'id' ? a[h] : undefined))
    );

    newLoanBalance = this.removeEmptyEntries(newLoanBalance);
    initLoanBalance = this.removeEmptyEntries(initLoanBalance);
    loanTicks = this.removeEmptyEntries(loanTicks);
    totalBorrowRec = this.removeEmptyEntries(totalBorrowRec);
    recTuitionToBorrow = this.removeEmptyEntries(recTuitionToBorrow);
    recLivingExpToBorrow = this.removeEmptyEntries(recLivingExpToBorrow);
    newYearlyBorrowedAmt = this.removeEmptyEntries(newYearlyBorrowedAmt);
    EstIDR = this.removeEmptyEntries(EstIDR);
    EstStandard = this.removeEmptyEntries(EstStandard);
    ExpectedIncome = this.removeEmptyEntries(ExpectedIncome);

    let loanBalance = [];
    if (loanTicks !== undefined && !loanTicks.includes(0)) {
      loanTicks.unshift(0);
    }

    let tableData = this.pivotDataTable(headers, body);
    let pivotTableHeaders = this.getPivotHeaders(body);
    let prevYearLoanBalance = this.getPrevYearBal(initLoanBalance, newLoanBalance);

    let tuitionLeftToBorrow = 0;
    let livingExpLeftToBorrow = 0;
    let tuitionPercent = 0;
    let livingExpensePercent = 0;

    if (recLivingExpToBorrow !== undefined && recTuitionToBorrow !== undefined) {
      tuitionPercent = recTuitionToBorrow[0] / totalBorrowRec[0];
      livingExpensePercent = recLivingExpToBorrow[0] / totalBorrowRec[0];

      tuitionLeftToBorrow = this.getTuitionLeft(
        recLivingExpToBorrow,
        recTuitionToBorrow,
        newLoanBalance,
        initLoanBalance,
        livingExpensePercent,
        tuitionPercent
      );
      livingExpLeftToBorrow = this.getLivingExpLeft(
        recLivingExpToBorrow,
        recTuitionToBorrow,
        newLoanBalance,
        initLoanBalance,
        livingExpensePercent,
        tuitionPercent
      );
    }

    if (totalBorrowRec !== undefined) {
      recommendedBorrowingRemain =
        totalBorrowRec[totalBorrowRec.length - 1] -
        (newLoanBalance[newLoanBalance.length - 1] - initLoanBalance[0]);
      if (recommendedBorrowingRemain < 0) {
        recommendedBorrowingRemainAddInfo = 0;
      } else {
        recommendedBorrowingRemainAddInfo = recommendedBorrowingRemain;
      }
    }

    if (this.state.redirectUrl && this.state.redirectUrl.length > 0) {
      return <Redirect to={this.state.redirectUrl} />;
    } else {
      return (
        <div>
          {/*<ForgivenessProgress />*/}
          <DashboardSection title="StudentFORGIVE Snapshot">
            <AsyncLoader request={request}>
              <CardBody>
                <div>
                  <CardText>
                    <div className="row">
                      <div className="col-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        {initLoanBalance !== undefined && (
                          <Card>
                            <CardBody>
                              <div className="row">
                                <LeftContent className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <CardTitle>
                                    <span>Presented Plan</span>
                                  </CardTitle>
                                  <dl>
                                    <dt>Starting Loan Balance</dt>
                                    <dd>{' ' + formatAsMoney(initLoanBalance[0]) || '—'}</dd>
                                    <dt>Recommended Borrowing</dt>
                                    <dd>{' ' + formatAsMoney(totalBorrowRec[0]) || '—'}</dd>
                                    <TabbedDD>
                                      <dl>
                                        <>
                                          <dt>Tuition To Borrow</dt>
                                          <dd>{formatAsMoney(recTuitionToBorrow[0]) || '-'}</dd>
                                        </>
                                        <>
                                          <dt>Living Expenses To Borrow</dt>
                                          <dd>{formatAsMoney(recLivingExpToBorrow[0]) || '-'}</dd>
                                        </>
                                      </dl>
                                    </TabbedDD>
                                    <dt>Loan Balance After School</dt>
                                    <dd>
                                      {' ' +
                                        (formatAsMoney(initLoanBalance[0] + totalBorrowRec[0]) ||
                                          '-')}
                                    </dd>
                                  </dl>
                                </LeftContent>
                                <VerticalLine />
                                <RightContent className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <CardTitle>
                                    <span>Current Snapshot</span>
                                  </CardTitle>
                                  <dl>
                                    {initLoanBalance.length === 1 && (
                                      <div>
                                        <dt>Starting Loan Balance</dt>{' '}
                                        <dd>{formatAsMoney(initLoanBalance[0])}</dd>
                                      </div>
                                    )}
                                    {initLoanBalance.length > 1 && (
                                      <div>
                                        <dt>Previous Snapshot's Loan Balance</dt>
                                        <dd>
                                          {formatAsMoney(
                                            prevYearLoanBalance[prevYearLoanBalance.length - 1]
                                          )}
                                        </dd>
                                      </div>
                                    )}
                                    <dt>New Borrowing</dt>
                                    <dd>
                                      {formatAsMoney(
                                        newYearlyBorrowedAmt[newYearlyBorrowedAmt.length - 1]
                                      )}
                                    </dd>
                                    <TabbedDD>
                                      <dl>
                                        <dt>Estimated Tuition Borrowed</dt>
                                        <dd>
                                          {formatAsMoney(
                                            newYearlyBorrowedAmt[newYearlyBorrowedAmt.length - 1] *
                                              tuitionPercent
                                          )}
                                        </dd>

                                        <dt>Estimated Living Expenses Borrowed</dt>
                                        <dd>
                                          {formatAsMoney(
                                            newYearlyBorrowedAmt[newYearlyBorrowedAmt.length - 1] *
                                              livingExpensePercent
                                          )}
                                        </dd>
                                      </dl>
                                    </TabbedDD>
                                    <dt>Estimated Loan Balance</dt>
                                    <dd>
                                      {formatAsMoney(newLoanBalance[newLoanBalance.length - 1])}
                                    </dd>
                                  </dl>
                                </RightContent>
                              </div>
                            </CardBody>
                          </Card>
                        )}
                      </div>
                      <div className="col-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
                        {/*Expected Income/Payments Section*/}
                        {ExpectedIncome !== undefined && (
                          <Card>
                            <CardBody>
                              <CardTitle>Estimated Payments</CardTitle>
                              <dl>
                                <dt>Expected Income</dt>
                                <dd>{formatAsMoney(ExpectedIncome[ExpectedIncome.length - 1])}</dd>
                                {this.state.monthly === true && (
                                  <div>
                                    <dt>Estimated Monthly Standard Payment</dt>
                                    <dd>
                                      {EstStandard[EstStandard.length - 1] < 0
                                        ? '-'
                                        : formatAsMoney(EstStandard[EstStandard.length - 1])}
                                    </dd>
                                    <dt>Estimated Monthly IDR</dt>
                                    <dd>
                                      {EstIDR[EstIDR.length - 1] < 0
                                        ? '-'
                                        : formatAsMoney(EstIDR[EstIDR.length - 1])}
                                    </dd>
                                    <dt>Estimated Monthly Savings</dt>
                                    <dd>
                                      {EstStandard[EstStandard.length - 1] -
                                        EstIDR[EstIDR.length - 1] <
                                      0
                                        ? '-'
                                        : formatAsMoney(
                                            EstStandard[EstStandard.length - 1] -
                                              EstIDR[EstIDR.length - 1]
                                          )}
                                    </dd>
                                  </div>
                                )}
                                {this.state.monthly === false && (
                                  <div>
                                    <dt>Estimated Yearly Standard Payment</dt>
                                    <dd>
                                      {EstStandard[EstStandard.length - 1] * 12 < 0
                                        ? '-'
                                        : formatAsMoney(EstStandard[EstStandard.length - 1] * 12)}
                                    </dd>
                                    <dt>Estimated Yearly IDR</dt>
                                    <dd>
                                      {EstIDR[EstIDR.length - 1] * 12 < 0
                                        ? 0
                                        : formatAsMoney(EstIDR[EstIDR.length - 1] * 12)}
                                    </dd>
                                    <dt>Estimated Yearly Savings</dt>
                                    <dd>
                                      {(EstStandard[EstStandard.length - 1] -
                                        EstIDR[EstIDR.length - 1]) *
                                        12 <
                                      0
                                        ? '-'
                                        : formatAsMoney(
                                            (EstStandard[EstStandard.length - 1] -
                                              EstIDR[EstIDR.length - 1]) *
                                              12
                                          )}
                                    </dd>
                                  </div>
                                )}
                                <dt>Total Estimated Forgiveness</dt>
                                <dd>
                                  {EstStandard[EstStandard.length - 1] * 120 -
                                    EstIDR[EstIDR.length - 1] * 120 <
                                  0
                                    ? '-'
                                    : formatAsMoney(
                                        EstStandard[EstStandard.length - 1] * 120 -
                                          EstIDR[EstIDR.length - 1] * 120
                                      )}
                                </dd>
                              </dl>

                              <Button brand={'primary'} onClick={() => this.handleYearlyMonthly()}>
                                {this.state.monthly === true && 'Click to See Yearly Estimates'}
                                {this.state.monthly === false && 'Click to See Monthly Estimates'}
                              </Button>
                            </CardBody>
                          </Card>
                        )}
                      </div>
                      <div className="col-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <Card>
                          <CardBody>
                            <CardTitle>
                              <span>Additional Information</span>
                            </CardTitle>
                            <dl>
                              <dt>Recommended Borrowing Remaining</dt>
                              <dd>{' ' + formatAsMoney(recommendedBorrowingRemainAddInfo)}</dd>
                              {recommendedBorrowingRemain >= 0 && (
                                <TabbedDD>
                                  <dl>
                                    <dt>Tuition</dt>
                                    <dd>{formatAsMoney(tuitionLeftToBorrow)}</dd>

                                    <dt>Living Expenses</dt>
                                    <dd>{formatAsMoney(livingExpLeftToBorrow)}</dd>
                                  </dl>
                                </TabbedDD>
                              )}
                              {recommendedBorrowingRemain < 0 && (
                                <div>
                                  <dt>Balance Borrowed Over Recommended</dt>
                                  <dd>{formatAsMoney(Math.abs(recommendedBorrowingRemain))}</dd>
                                  <dd>
                                    You have borrowed over what was recommended in your presented
                                    plan. The good news is that this will not impact your projected
                                    Income-Driven Repayment and any additional loans borrowed over
                                    the recommended will likely increase your projected loan
                                    forgiveness.
                                  </dd>
                                </div>
                              )}
                            </dl>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                    {/*END Expected Income/Payments Section*/}

                    {/*Start of Chart Section*/}
                    {loanBalance !== undefined && loanTicks !== undefined && (
                      <>
                        <div className="row">
                          <div className="col-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Card>
                              <CardBody>
                                {this.state.showHistory && (
                                  <>
                                    <CardTitle>
                                      <span>Snapshot History</span>
                                    </CardTitle>
                                    {tableData !== undefined &&
                                      tableData[2][1] !== '$0.00' &&
                                      tableData.length > 0 && (
                                        <Table header={pivotTableHeaders} body={tableData} />
                                      )}
                                  </>
                                )}

                                {!this.state.showHistory && (
                                  <BtsBorrowingCompare
                                    requestData={false}
                                    showPreviousYears={true}
                                  />
                                )}
                                <ButtonRight
                                  brand="primary"
                                  onClick={() => this.handleChartHistory()}
                                >
                                  {this.state.showHistory
                                    ? 'Show Borrowing Comparison'
                                    : 'Show Snapshot History'}
                                </ButtonRight>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-8 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Card>
                              <CardBody>
                                <CardTitle>
                                  <span>Other Helpful Insights</span>
                                </CardTitle>
                                <div className="row">
                                  <div className="col">
                                    <Lede>
                                      <StyledButton
                                        brand={'primary'}
                                        invert={this.state.buttonType === 'taxes'}
                                        onClick={() => this.handleEducationClick('taxes')}
                                      >
                                        Education Credits and Taxes
                                      </StyledButton>
                                    </Lede>
                                    <Lede>
                                      <StyledButton
                                        brand={'primary'}
                                        invert={this.state.buttonType === 'fafsa'}
                                        onClick={() => this.handleEducationClick('fafsa')}
                                      >
                                        Financial Aid
                                      </StyledButton>
                                    </Lede>
                                    <Lede>
                                      <StyledButton
                                        brand={'primary'}
                                        invert={this.state.buttonType === 'retirement'}
                                        onClick={() => this.handleEducationClick('retirement')}
                                      >
                                        Benefits and Retirement
                                      </StyledButton>
                                    </Lede>
                                  </div>
                                </div>
                                <BottomContent className="row">
                                  {this.state.buttonType === 'taxes' &&
                                    this.educationCreditTaxesInfo()}
                                  {this.state.buttonType === 'fafsa' &&
                                    this.fafsaFinancialAidInfo()}
                                  {this.state.buttonType === 'retirement' &&
                                    this.benefitsRetirementInfo()}
                                </BottomContent>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </>
                    )}
                  </CardText>
                </div>
              </CardBody>
            </AsyncLoader>
          </DashboardSection>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.questionnaireBts;
  const formData = safeAccess(state.questionnaireBts, 'cache');
  return {
    id: getUserId(state),
    data: formData,
    flatData: objToArray(formData),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadEducation: async (id) => {
    await dispatch(questionnaireBtsLoadCollection(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(BtsReview));
