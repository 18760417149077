import { FormValidator } from '../../utils';

export class EmploymentHistoryValidator extends FormValidator {
  employmentClassification(validator, state) {
    validator.isRequired();
  }

  employmentType(validator, state) {
    validator.isRequired();
  }

  employerName(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  jobTitle(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(50);
  }

  startDate(validator, state) {
    if (validator.value !== undefined && validator.value !== '') {
      validator.validDate();
    }
  }

  endDate(validator, state) {
    if (validator.value !== undefined && validator.value !== '') {
      validator.validDate();
      validator.isAfterDate('10/01/2007');
    }
  }

  ein(validator, state) {
    if (validator.value !== undefined && validator.value !== '') {
      validator.hasMaxLength(10);
      validator.matchesRegex(/(^$[0-9]{2}|-[0-9]{7}$)/g, 'must be formatted as ##-#######');
    }
  }

  payPeriods(validator, state) {
    if (validator.value !== undefined && validator.value !== '') {
      validator.validNumber();
    }
  }

  hours(validator, state) {
    validator.isRequired();
    validator.validNumber();
    validator.isGreaterThanOrEqualTo(1);
  }

  address1(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  address2(validator, state) {
    validator.hasMaxLength(30);
  }

  city(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  state(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(2);
  }

  zip(validator, state) {
    validator.isRequired();
    validator.hasMaxLength(10);
    validator.matchesRegex(
      /^[0-9]{5}($|-[0-9]{4}$)/g,
      'must be formatted as either ##### or #####-####'
    );
  }
}
