import actions from './actions';
import { resourceOperationsGenerator, patchApi, normalize } from '../../../utils';
import { getToken } from '../../../auth';
import { ContributionValidator } from './validators';

const contributionValidator = new ContributionValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/contribution',
  resourceType: 'contribution',
};

const operations = resourceOperationsGenerator(
  'contribution',
  actions,
  null,
  contributionValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  contributionLoadResource,
  contributionCreateResource,
  contributionClearForm,
  contributionLoadCollection,
  contributionCopyResourceToForm,
  contributionHandleFormChange,
  contributionUpdateResource,
} = operations;

const contributionAccountUpdateResource = (id) => async (dispatch, getState) => {
  const state = getState();
  let form = state.contribution.form;

  //need to manually make sub items kebab case kebab-case-is-fun
  let newForm = {
    contributionLoans: [
      {
        'contribution-id': form.contributionId,
        'loan-id': form.loanId,
        'contribution-type': form.contributionType,
        'loan-amount': form.loanAmount,
        'interest-rate': form.interestRate,
        'monthly-payment': form.monthlyPayment,
        'employer-contribution': form.employerContribution,
        'employee-contribution': form.employeeContribution,
        'loan-status': form.loanStatus,
        description: form.description,
      },
    ],
  };
  let retVal = false;

  try {
    const patchData = normalize('contribution', newForm, id);
    await patchApi(getToken(state), `/contribution/${id}`, patchData);
  } catch (error) {
    //alert('error: ' + error)
  }

  return retVal;
};

export {
  contributionAccountUpdateResource,
  contributionClearForm,
  contributionCreateResource,
  contributionLoadResource,
  contributionLoadCollection,
  contributionCopyResourceToForm,
  contributionHandleFormChange,
  contributionUpdateResource,
};

export default operations;
