import { resourceOperationsGenerator } from '../../../utils';

import actions from './actions';
import { TuitionValidator } from './validators';

const tuitionValidator = new TuitionValidator();

const endpointHandler = {
  endpoint: '/tuition-reimbursement-class',
  customResponseHandler: null,
  resourceType: 'tuition-reimbursement-class',
};

const operations = resourceOperationsGenerator(
  'tuitionInstitutionClass',
  actions,
  null,
  tuitionValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  tuitionInstitutionClassClearForm,
  tuitionInstitutionClassCopyResourceToForm,
  tuitionInstitutionClassHandleFormChange,
  tuitionInstitutionClassCreateResource,
  tuitionInstitutionClassValidateAttributes,
} = operations;

const classCreateResource = () => async (dispatch, getState) => {
  await dispatch(tuitionInstitutionClassCreateResource());
};

export {
  tuitionInstitutionClassClearForm,
  tuitionInstitutionClassCopyResourceToForm,
  tuitionInstitutionClassHandleFormChange,
  tuitionInstitutionClassValidateAttributes,
  classCreateResource,
};

export default operations;
