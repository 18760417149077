import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { isAuthenticated } from '../../auth';

const AuthGate = ({ children, isAuthenticated = false }) => (
  <React.Fragment>{isAuthenticated ? children : null}</React.Fragment>
);

AuthGate.propTypes = {
  children: t.node,
  isAuthenticated: t.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
});

export default connect(mapStateToProps)(AuthGate);
