import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../../todos';

const TodoText = () => (
  <>
    <strong>Step 1 – Sign consolidation application</strong>
    <ul>
      <li>Electronically sign the application.</li>
      <li>
        Please verify your Social Security Number is present on all pages up to the signature page.
      </li>
      <li>Please verify that your address on line #9 is not a P.O. box.</li>
      <li>
        Please verify that your references at the bottom of page one have different home addresses
        and phone numbers from both you and each other.
      </li>
    </ul>
    <strong>Step 2 – Access your loan servicer account(s)</strong>
    <ul>
      <li>Login to your loan servicer account(s).</li>
      <li>
        Verify the number of loans on your consolidation application matches the number of loans
        with an outstanding balance at your servicer(s).
      </li>
    </ul>
    <strong>Step 3 – Confirm the status of your loan(s)</strong>
    <ul>
      <li>Do not submit your application if your loans appear in the following statuses:</li>
      <ul>
        <li>In-school</li>
        <li>Deferment</li>
        <li>Default</li>
        <ul>
          <li>If your loans are currently in any of these statuses, please contact us.</li>
        </ul>
      </ul>
    </ul>
    <strong>Step 4 – Submit your application to MOHELA</strong>
    <ul>
      <li>
        Upload the signed agreement to{' '}
        <a href="https://www.mohela.com" target="_blank" rel="noopener noreferrer">
          MOHELA
        </a>{' '}
        if you have an account.
      </li>
    </ul>
    Or
    <ul>
      <li>
        Fax your completed application to MOHELA at 866.222.7060 if you do not have an account.
      </li>
      <li>
        <strong>Please note:</strong> You will be able to create an account with MOHELA
        approximately three weeks after the application is submitted.
      </li>
    </ul>
  </>
);

const ConsolidationComplete = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/documents')}
    text={<TodoText />}
    title="Submit Consolidation Application"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsolidationComplete);
