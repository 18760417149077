import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from '../../utils';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  Form,
  Header4,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Loader,
  Alert,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';
import styled from 'styled-components';

import PdfViewer from '../components/pdf-viewer';
import Signature from '../components/signature';
import {
  fulfillmentCopyResourceToForm,
  fulfillmentHandleFormChange,
  fulfillmentLoadResource,
  fulfillmentUpdateResource,
} from '../redux/operations';

export const AGREEMENT_DOC_ID = 8;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

class SignFulfillment extends React.Component {
  static propTypes = {
    data: t.object,
    id: t.number.isRequired,
    errors: t.object,
    form: t.object,
    loadHtml: t.func.isRequired,
    request: t.object,
    signContract: t.func.isRequired,
    signRequest: t.object,
    updateForm: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = { hasHtml: false, redirect: false };
  }

  componentDidMount() {
    this.setState({ hasHtml: true }, () => this.props.loadHtml());
    if (this.scrollRef.current) {
      this.scrollRef.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { signRequest } = this.props;

    if (prevProps.signRequest.isLoading && signRequest.hasFinished && !signRequest.hasFailed) {
      this.setState({
        redirect: true,
      });
    }
  }

  handleChange = debounce((formState, id, attribute) => {
    const { form, updateForm } = this.props;
    formState.docId = this.props.id;
    const newData = { ...form, ...formState };
    updateForm(newData, [attribute]);
  }, 250);

  handleClientSignature = (data) => {
    this.props.updateForm({ ...this.props.form, signaturePadClient: data }, ['signaturePadClient']);
  };

  handleSpouseSignature = (data) => {
    this.props.updateForm({ ...this.props.form, signaturePadSpouse: data }, ['signaturePadSpouse']);
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.signRequest.isLoading) {
      this.props.signContract();
    }
  };

  render() {
    const { data = {}, errors = {}, form = {}, request, signRequest } = this.props;

    if (this.state.redirect) {
      return <Redirect to="/documents" />;
    }
    //alert(request.isLoading);
    return (
      <AsyncLoader request={request}>
        <>
          <PdfViewer base64={safeAccess(data, 'fileData', '')} isFulfillment={true} />
          <Form id="signatureForm" handleChange={this.handleChange} defaults={form}>
            {safeAccess(errors, 'na') && (
              <Alert key={'invalid-info'} brand="danger">
                {errors.na.detail}
              </Alert>
            )}
            <Header4>
              For your security, please input the correct information below to sign the application.
            </Header4>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.firstName}>
                  <InputLabel2>First Name</InputLabel2>
                  <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
                  <InputGroup>
                    <InputText name="firstName" />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.lastName}>
                  <InputLabel2>Last Name</InputLabel2>
                  <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
                  <InputGroup>
                    <InputText name="lastName" />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.email}>
                  <InputLabel2>Email</InputLabel2>
                  <InputError>{safeAccess(errors, 'email.detail')}</InputError>
                  <InputGroup>
                    <InputText name="email" type="email" />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-12 col-md-6">
                <InputWrapper error={!!errors.password}>
                  <InputLabel2>Password</InputLabel2>
                  <InputError>{safeAccess(errors, 'password.detail')}</InputError>
                  <InputGroup>
                    <InputText name="password" type="password" />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
            <Signature handleChange={this.handleClientSignature} label="Your signature" />
            {data.needSpouseSignature && (
              <Signature handleChange={this.handleSpouseSignature} label="Spouse's Signature" />
            )}
          </Form>
          <Button brand="primary" onClick={(e) => this.submit(e)}>
            {signRequest.isLoading ? <Loader variant="push" size={1.5} /> : 'Sign contract'}
          </Button>
        </>
      </AsyncLoader>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  data: state.fulfillment.cache[ownProps.id],
  form: state.fulfillment.form,
  errors: state.fulfillment.errors,
  request: state.fulfillment.requests.loadResource,
  signRequest: state.fulfillment.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadHtml: async () => {
    await dispatch(fulfillmentLoadResource(ownProps.id));
    await dispatch(fulfillmentCopyResourceToForm(ownProps.id));
  },
  signContract: () => dispatch(fulfillmentUpdateResource(ownProps.id)),
  updateForm: (data, attributes) => dispatch(fulfillmentHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignFulfillment);
