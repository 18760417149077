// Auth cache
const AUTH_CLEAR = 'app/auth/CLEAR';
const AUTH_EXPIRING = 'app/auth/EXPIRING';
// const AUTH_EXPIRED = 'app/auth/AUTH_EXPIRED';
const AUTH_HANDLE_ERROR = 'app/auth/HANDLE_ERROR';
const AUTH_POPULATE = 'app/auth/POPULATE';
// const AUTH_SESSION_TERMINATED = 'app/auth/AUTH_SESSION_TERMINATED';
const AUTH_HANDLE_FORM_CHANGE = 'app/auth/HANDLE_FORM_CHANGE';
const AUTH_INVALIDATE_ATTRIBUTES = 'app/auth/INVALIDATE_ATTRIBUTES';
const AUTH_INVALIDATE_CACHE = 'app/auth/INVALIDATE_CACHE';
const AUTH_VALIDATE_ATTRIBUTES = 'app/auth/VALIDATE_ATTRIBUTES';
const AUTH_VALIDATE_CACHE = 'app/auth/VALIDATE_CACHE';

// Login
const AUTH_LOAD_PERMISSIONS_REQUEST = 'app/auth/LOAD_PERMISSIONS_REQUEST';
const AUTH_LOAD_PERMISSIONS_RESPONSE = 'app/auth/LOAD_PERMISSIONS_RESPONSE';
const AUTH_LOAD_PERMISSIONS_FAILURE = 'app/auth/LOAD_PERMISSIONS_FAILURE';
const AUTH_LOAD_PERMISSIONS_SUCCESS = 'app/auth/LOAD_PERMISSIONS_SUCCESS';

// Login
const AUTH_LOGIN_REQUEST = 'app/auth/LOGIN_REQUEST';
const AUTH_LOGIN_RESPONSE = 'app/auth/LOGIN_RESPONSE';
const AUTH_LOGIN_FAILURE = 'app/auth/LOGIN_FAILURE';
const AUTH_LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS';

// Logout
const AUTH_LOGOUT_REQUEST = 'app/auth/LOGOUT_REQUEST';
const AUTH_LOGOUT_RESPONSE = 'app/auth/LOGOUT_RESPONSE';
const AUTH_LOGOUT_FAILURE = 'app/auth/LOGOUT_FAILURE';
const AUTH_LOGOUT_SUCCESS = 'app/auth/LOGOUT_SUCCESS';

// Password reset
const AUTH_PASSWORD_RESET_REQUEST = 'app/auth/PASSWORD_RESET_REQUEST';
const AUTH_PASSWORD_RESET_RESPONSE = 'app/auth/PASSWORD_RESET_RESPONSE';
const AUTH_PASSWORD_RESET_FAILURE = 'app/auth/PASSWORD_RESET_FAILURE';
const AUTH_PASSWORD_RESET_SUCCESS = 'app/auth/PASSWORD_RESET_SUCCESS';

// Password update
const AUTH_PASSWORD_UPDATE_REQUEST = 'app/auth/PASSWORD_UPDATE_REQUEST';
const AUTH_PASSWORD_UPDATE_RESPONSE = 'app/auth/PASSWORD_UPDATE_RESPONSE';
const AUTH_PASSWORD_UPDATE_FAILURE = 'app/auth/PASSWORD_UPDATE_FAILURE';
const AUTH_PASSWORD_UPDATE_SUCCESS = 'app/auth/PASSWORD_UPDATE_SUCCESS';

// Signup
const AUTH_SIGNUP_REQUEST = 'app/auth/SIGNUP_REQUEST';
const AUTH_SIGNUP_RESPONSE = 'app/auth/SIGNUP_RESPONSE';
const AUTH_SIGNUP_FAILURE = 'app/auth/SIGNUP_FAILURE';
const AUTH_SIGNUP_SUCCESS = 'app/auth/SIGNUP_SUCCESS';

// Silent renew
const AUTH_SILENT_RENEW_REQUEST = 'app/auth/SILENT_RENEW_REQUEST';
const AUTH_SILENT_RENEW_RESPONSE = 'app/auth/SILENT_RENEW_RESPONSE';
const AUTH_SILENT_RENEW_FAILURE = 'app/auth/SILENT_RENEW_FAILURE';
const AUTH_SILENT_RENEW_SUCCESS = 'app/auth/SILENT_RENEW_SUCCESS';

// Get Partner Locations
const AUTH_PARTNER_LOCATION_REQUEST = 'app/auth/PARTNER_LOCATIONS_REQUEST';
const AUTH_PARTNER_LOCATION_RESPONSE = 'app/auth/PARTNER_LOCATIONS_RESPONSE';
const AUTH_PARTNER_LOCATION_FAILURE = 'app/auth/PARTNER_LOCATIONS_FAILURE';
const AUTH_PARTNER_LOCATION_SUCCESS = 'app/auth/PARTNER_LOCATIONS_SUCCESS';

// Theme
const AUTH_LOAD_THEME_REQUEST = 'app/auth/LOAD_THEME_REQUEST';
const AUTH_LOAD_THEME_RESPONSE = 'app/auth/LOAD_THEME_RESPONSE';
const AUTH_LOAD_THEME_FAILURE = 'app/auth/LOAD_THEME_FAILURE';
const AUTH_LOAD_THEME_SUCCESS = 'app/auth/LOAD_THEME_SUCCESS';

export default {
  AUTH_HANDLE_FORM_CHANGE,
  AUTH_INVALIDATE_ATTRIBUTES,
  AUTH_INVALIDATE_CACHE,
  AUTH_VALIDATE_ATTRIBUTES,
  AUTH_VALIDATE_CACHE,

  AUTH_LOAD_PERMISSIONS_REQUEST,
  AUTH_LOAD_PERMISSIONS_RESPONSE,
  AUTH_LOAD_PERMISSIONS_FAILURE,
  AUTH_LOAD_PERMISSIONS_SUCCESS,

  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_RESPONSE,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,

  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_RESPONSE,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_SUCCESS,

  AUTH_PASSWORD_RESET_REQUEST,
  AUTH_PASSWORD_RESET_RESPONSE,
  AUTH_PASSWORD_RESET_FAILURE,
  AUTH_PASSWORD_RESET_SUCCESS,

  AUTH_PASSWORD_UPDATE_REQUEST,
  AUTH_PASSWORD_UPDATE_RESPONSE,
  AUTH_PASSWORD_UPDATE_FAILURE,
  AUTH_PASSWORD_UPDATE_SUCCESS,

  AUTH_SIGNUP_REQUEST,
  AUTH_SIGNUP_RESPONSE,
  AUTH_SIGNUP_FAILURE,
  AUTH_SIGNUP_SUCCESS,

  AUTH_SILENT_RENEW_REQUEST,
  AUTH_SILENT_RENEW_RESPONSE,
  AUTH_SILENT_RENEW_FAILURE,
  AUTH_SILENT_RENEW_SUCCESS,

  AUTH_PARTNER_LOCATION_REQUEST,
  AUTH_PARTNER_LOCATION_RESPONSE,
  AUTH_PARTNER_LOCATION_FAILURE,
  AUTH_PARTNER_LOCATION_SUCCESS,

  AUTH_LOAD_THEME_REQUEST,
  AUTH_LOAD_THEME_RESPONSE,
  AUTH_LOAD_THEME_FAILURE,
  AUTH_LOAD_THEME_SUCCESS,

  AUTH_CLEAR,
  // AUTH_EXPIRED,
  AUTH_EXPIRING,
  AUTH_HANDLE_ERROR,
  AUTH_POPULATE,
  // AUTH_SESSION_TERMINATED,
  // AUTH_SILENT_RENEW_ERROR,
};
