import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  BrandColor,
  Header2,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
import { formatAsMoney } from '../../../utils';

import PaymentCompare from '../../forgiveness/components/payment-compare';
import ReducedIDRProgress from '../components/reduced-idr-progress';
import { forgivenessLoadResource } from '../../forgiveness/redux/operations';
import {
  getForgivenessData,
  getMonthlyPayment,
  getMonthlySavings,
  getNewPayment,
  getOldPayment,
  getTotalForgiveness,
  getTotalIdrRepayment,
  getTotalStandardRepayment,
  getRepaymentTerm,
  getShowTaxCompare,
  getStandardPayment,
} from '../../forgiveness/redux/selectors';

const ReducedIDROverview = withPermissions(
  ({
    data = {},
    id,
    load,
    monthlyPayment,
    monthlySavings,
    newPayment,
    oldPayment,
    request,
    standardPayment,
  }) => {
    useEffect(() => {
      load(id);
    }, []);

    return (
      <DashboardSection title="My Reduced IDR">
        <Header2>Lower your loan payment and see immediate financial relief.</Header2>

        <AsyncLoader request={request}>
          <ReducedIDRProgress />
          <div className="row">
            <div className="col-12 col-lg-4">
              <Card>
                <CardBody>
                  <CardTitle>Reduced IDR Details</CardTitle>
                  <CardText>
                    <dl>
                      <dt>Standard Monthly Payment</dt>
                      <dd>{standardPayment < 0 ? '—' : formatAsMoney(standardPayment)}</dd>
                      <dt>IDR Monthly Payment</dt>
                      <dd>
                        {monthlyPayment < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlyPayment)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Monthly Savings</dt>
                      <dd>
                        {monthlySavings < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlySavings)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                      <dt>Yearly Savings</dt>
                      <dd>
                        {monthlySavings < 0 ? (
                          <>—</>
                        ) : (
                          <BrandColor brand="secondary">
                            <strong>{formatAsMoney(monthlySavings * 12)}</strong>
                          </BrandColor>
                        )}
                      </dd>
                    </dl>
                  </CardText>
                </CardBody>
              </Card>
            </div>
            <div className="col-12 col-lg-6">
              <PaymentCompare
                oldPayment={oldPayment}
                newPayment={newPayment}
                year={data.year}
                bigTitle={true}
              />
            </div>
          </div>
        </AsyncLoader>
      </DashboardSection>
    );
  }
);

ReducedIDROverview.propTypes = {
  data: t.object,
  id: t.string.isRequired,
  load: t.func.isRequired,
  monthlyPayment: t.number,
  monthlySavings: t.number,
  newPayment: t.number,
  oldPayment: t.number,
  request: t.object.isRequired,
  totalForgiveness: t.number,
  totalIdrRepayment: t.number,
  totalStandardRepayment: t.number,
  repaymentTerm: t.number,
};

const mapStateToProps = (state) => ({
  data: getForgivenessData(state),
  id: getUserId(state),
  monthlyPayment: getMonthlyPayment(state),
  monthlySavings: getMonthlySavings(state),
  newPayment: getNewPayment(state),
  oldPayment: getOldPayment(state),
  request: state.forgiveness.requests.loadResource,
  totalForgiveness: getTotalForgiveness(state),
  totalIdrRepayment: getTotalIdrRepayment(state),
  totalStandardRepayment: getTotalStandardRepayment(state),
  standardPayment: getStandardPayment(state),
  repaymentTerm: getRepaymentTerm(state),
  showTaxCompare: getShowTaxCompare(state),
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(forgivenessLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReducedIDROverview);
