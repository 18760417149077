import actions from './actions';
import { IdrValidator } from './validators';
import { getToken, getUserId } from '../../../auth';
import {
  getApi,
  keyById,
  normalize,
  patchApi,
  resourceOperationsGenerator,
  safeAccess,
} from '../../../utils';

const idrValidator = new IdrValidator();

const fixData = (data) =>
  data.map((resource) => {
    const { question8Response } = resource;
    resource.question8Response =
      question8Response === null ? null : question8Response === true ? 'A' : 'B';
    return resource;
  });

const endpointHandler = {
  endpoint: '/idr-questions',
  customResponseHandler: fixData,
  resourceType: 'idr-questions',
};

const operations = resourceOperationsGenerator(
  'idr',
  actions,
  null,
  idrValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const idrFixUpdateData = () => async (dispatch, getState) => {
  const state = getState();
  let form = safeAccess(state, 'idr.form', {});
  form.question8Response = form.question8Response === null ? null : form.question8Response === 'A';
  await dispatch(actions.idrHandleFormChange(form));
};

const idrLoadComplete = (key = null) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  try {
    dispatch(actions.idrLoadResourceRequest(key));
    let data = await getApi(getToken(state), `/idr-complete/${getUserId(state)}`);

    dispatch(actions.idrLoadResourceResponse(key));

    const cache = {
      ...state.idr.cache,
      ...keyById(data),
    };

    await dispatch(operations.idrHandleCacheChange(cache));

    dispatch(actions.idrLoadResourceSuccess());
  } catch (error) {
    dispatch(operations.idrHandleError(error));
    dispatch(actions.idrLoadResourceFailure(error, key));
  }
};

const idrCopyCompleteToForm = () => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(actions.idrCopyResourceToFormRequest());
    const accessor = `idr.cache[${getUserId(state)}]`;
    const data = safeAccess(state, accessor, {});
    dispatch(actions.idrHandleFormChange(data));
    dispatch(actions.idrCopyResourceToFormResponse());

    dispatch(actions.idrCopyResourceToFormSuccess());
  } catch (error) {
    dispatch(operations.idrHandleError(error));
  }
};

const idrSaveComplete = (key = null) => async (dispatch, getState) => {
  const state = getState();
  let segment = state.idr;
  try {
    if (safeAccess(segment, 'errors.length') > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }

    if (segment.meta.isReadOnly) {
      throw new Error('The form is readonly. Any changes will not be saved.');
    }

    dispatch(actions.idrUpdateResourceRequest(key));
    const patchData = normalize('idr-complete', segment.form, getUserId(state));
    let data = await patchApi(getToken(state), `/idr-complete/${getUserId(state)}`, patchData);

    dispatch(actions.idrUpdateResourceResponse(key));

    const cache = { ...segment.cache, ...keyById(data) };
    await dispatch(operations.idrHandleCacheChange(cache));

    dispatch(actions.idrUpdateResourceSuccess(null, key));
  } catch (error) {
    dispatch(operations.idrHandleError(error));
    dispatch(actions.idrUpdateResourceFailure(error, key));
  }
};

const {
  idrClearForm,
  idrHandleCacheChange,
  idrCopyResourceToForm,
  idrHandleFormChange,
  idrInvalidateCache,
  idrLoadResource,
  idrUpdateResource,
  idrValidateAttributes,
  idrValidateState,
} = operations;

export {
  idrClearForm,
  idrHandleCacheChange,
  idrCopyCompleteToForm,
  idrCopyResourceToForm,
  idrFixUpdateData,
  idrHandleFormChange,
  idrInvalidateCache,
  idrLoadComplete,
  idrLoadResource,
  idrSaveComplete,
  idrUpdateResource,
  idrValidateAttributes,
  idrValidateState,
};

export default operations;
