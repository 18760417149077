import React from 'react';
import styled from 'styled-components';
import { DashboardSection } from 'fiducius-ui';

import { FulfillmentLibrary } from '../../fulfillment';

import DocumentLibrary from './document-library';

export const Spacer = styled.div`
  margin-bottom: 2rem;
`;

const MyDocuments = () => (
  <DashboardSection title="My Documents">
    <FulfillmentLibrary />
    <Spacer />
    <DocumentLibrary />
  </DashboardSection>
);

export default MyDocuments;
