import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  endpoint: '/implementation-information',
  customResponseHandler: null,
  resourceType: 'implementation-information',
};

const operations = resourceOperationsGenerator(
  'implementationResults',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null,
  null
);

const { implementationResultsLoadResource, implementationResultsUpdateResource } = operations;

export { implementationResultsLoadResource, implementationResultsUpdateResource };

export default operations;
