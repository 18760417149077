import React from 'react';

import { FullscreenContainer } from '../../../routing';

import PrescreeningForm from '../components/prescreening-form';

const Prescreening = () => (
  <FullscreenContainer className="container">
    <div className="row">
      <div className="col-12">
        <PrescreeningForm />
      </div>
    </div>
  </FullscreenContainer>
);

export default Prescreening;
