import React from 'react';
import styled from 'styled-components';
import { Table, Card, CardBody, Button, CardText } from 'fiducius-ui';

import { formatAsMoney, convertIsoToSlash, stringIsNullOrEmpty } from '../../utils';

const PaddedDiv = styled.div`
  padding-bottom: 1em;
`;

const StyledDivCentered = styled.div`
  text-align: center;
`;

const getTable = (props) => {
  if (props.data === null || props.data.length === 0) {
    return null;
  }
  let displayHeaders = [];
  const displayBody = [];

  displayHeaders = ['Loan Servicer', 'Required IDR Payment', 'Payment Amount', 'Paid On'];

  Object.keys(props.data)
    .sort((a, b) => {
      if (props.data[a].paymentDate > props.data[b].paymentDate) {
        return -1;
      }
      if (props.data[a].paymentDate < props.data[b].paymentDate) {
        return 1;
      }
      return 0;
    })
    .forEach((row) => {
      if (!stringIsNullOrEmpty(props.data[row].id)) {
        displayBody.push([
          props.data[row].servicerName,
          formatAsMoney(props.data[row].requiredIdrPaymentAmount),
          formatAsMoney(props.data[row].paymentAmount),
          convertIsoToSlash(props.data[row].paymentDate),
          <>
            {props.data[row].editable && (
              <Button
                brand="secondary"
                onClick={() => {
                  props.editRow(props.data[row].id);
                }}
              >
                Edit
              </Button>
            )}
          </>,
          <>
            {props.data[row].editable && (
              <Button
                brand="secondary"
                onClick={() => {
                  props.deleteRow(props.data[row].id);
                }}
              >
                Delete
              </Button>
            )}
          </>,
        ]);
      }
    });

  return (
    <>
      <Table
        meta={{
          pageSize: 10,
        }}
        body={displayBody}
        columns={[
          {},
          { renderer: formatAsMoney },
          { renderer: formatAsMoney },
          { sortable: false },
          {},
          {},
        ]}
        header={displayHeaders}
      />
      {displayBody.length == 0 && (
        <PaddedDiv>
          <StyledDivCentered>
            <CardText>No Payments Exist. Click 'Add Payment History' to add payments.</CardText>
          </StyledDivCentered>
        </PaddedDiv>
      )}
    </>
  );
};
const RepaymentHistoryTable = (props) => {
  let MaximumPaymentAmount = '$200.00';

  if (props.data !== undefined && props.data !== null && Object.keys(props.data).length > 0) {
    if (Object.values(props.data)[0].inBandSix) {
      MaximumPaymentAmount = '$400.00';
    }
  }

  return (
    <Card>
      <CardBody>
        {getTable(props)}
        <PaddedDiv className="row">
          <div className="col-12">
            <CardText>
              The Michigan 27k Student Loan Repayment program states you can receive aid up to{' '}
              {MaximumPaymentAmount} or your Income-Driven Repayment (IDR) amount, if it is less
              than {MaximumPaymentAmount}.<br />
              <br />
              Additionally, we recommend that you do not make a payment amount that is higher than
              your IDR amount.
            </CardText>
          </div>
        </PaddedDiv>
      </CardBody>
    </Card>
  );
};

export default RepaymentHistoryTable;
