/**
 * Takes an array of keys and filters an object through a curried function to
 * only include properties with keys in the input array.
 *
 * @param  {Array.<string>} picks - Array of string keys to pick from the object
 *
 * @returns {(original: Object) => Object} the object filtering function
 */
const pick = (...picks) => (original) =>
  picks.reduce(
    (filtered, key) => ({
      ...filtered,
      [key]: original[key],
    }),
    {}
  );

/**
 * Maps flat data to nested data based on an object with arrays of property keys
 *
 * @param {Object}         flatData - Flat data to be segmented into nested tabs
 * @param {Array.<string>} segments - Map of segments and keys to nest flatData
 *
 * @returns {Object.<string, Array>} An object with segmented arrays of keys
 */
export const nest = (flatData, segments) =>
  Object.keys(segments).reduce(
    (nested, segment) => ({
      ...nested,
      [segment]: pick(...segments[segment])(flatData),
    }),
    {}
  );

/**
 * Takes an object with a single level of nested data and flattens it
 *
 * @param {Object} nestedData - Nested tab data to be flattened into single object
 *
 * @returns {Object} An object with a level of nesting removed
 */
export const flatten = (nestedData) =>
  Object.keys(nestedData).reduce(
    (flatData, key) => ({
      ...flatData,
      ...nestedData[key],
    }),
    {}
  );

/**
 * Takes an object with a single level of nested data and flattens it
 *
 * @param {Object} nestedData - Nested tab data to be converted to an array of objects
 *
 * @returns {Object} An array with a level of nesting removed
 */
export const objToArray = (nestedData) => {
  var newArray = [];
  //var count = 0;

  if (nestedData !== undefined) {
    var final = [];

    for (let [_key, value] of Object.entries(nestedData)) {
      newArray = [];
      //alert(newArray.length)
      for (let [key1, value1] of Object.entries(value)) {
        //alert(`${key1}: ${value1}`);
        newArray.push(key1 + ':' + value1);
        //alert(newArray)
      }
      final.push({ newArray });
      //count++;
    }
  }
  return final;
};

export default {
  flatten,
  nest,
  objToArray,
};
