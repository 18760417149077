import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header2, AsyncLoader } from 'fiducius-ui';

import { todosLoadCollection } from '../../../todos';

import { withPermissions } from '../../../routing';

import { mergeRequestStatuses } from '../../../utils';

import { nsldsUploadData } from '../redux/operations';

import { authLoadPermissions } from '../../../auth';

const TextBlock = styled.div`
  color: ${(p) =>
    p.theme.themeName === 'greenlight' ? p.theme.textPrimary : p.theme.textTertiary};
`;

class TabReview extends React.Component {
  static propTypes = {
    uploadNslds: t.func.isRequired,
    request: t.object.isRequired,
    nsldsRequest: t.object.isRequired,
    uploadDifferentNslds: t.func.isRequired,
    inScreening: t.bool.isRequired,
    handleWorkflowComplete: t.func,
  };

  componentDidMount() {
    const { inScreening } = this.props;
    this.props.uploadNslds(inScreening);
  }

  failedUpload = () => {
    return (
      <>
        <Header2>Upload Failed</Header2>
        <TextBlock>
          <p>Your NSLDS upload has failed.</p>
        </TextBlock>
      </>
    );
  };

  successfulUpload = () => {
    return (
      <>
        <Header2>Complete</Header2>
        <TextBlock>
          <p>Your NSLDS upload has been uploaded successfully.</p>
        </TextBlock>
      </>
    );
  };

  workflowComplete = () => {
    const { nsldsRequest, handleWorkflowComplete } = this.props;
    if (!nsldsRequest.isLoading && nsldsRequest.hasFinished) {
      handleWorkflowComplete();
    }
  };

  render() {
    const { request, inScreening } = this.props;
    return (
      <AsyncLoader request={request} failed={this.failedUpload()}>
        {inScreening && this.workflowComplete()}
        {!inScreening && this.successfulUpload()}
      </AsyncLoader>
    );
  }
}

const mapStateToProps = (state) => {
  const { documents, nslds } = state;

  return {
    request: mergeRequestStatuses([
      documents.requests.createResource,
      nslds.requests.createResource,
    ]),
    nsldsRequest: nslds.requests.createResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uploadNslds: (inScreening) => {
    dispatch(nsldsUploadData(inScreening)).then(() => {
      if (!inScreening) {
        dispatch(authLoadPermissions());
        dispatch(todosLoadCollection());
      }
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabReview));
