import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AsyncLoader, Button, Card, CardBody, DashboardSection } from 'fiducius-ui';
import {
  loanRepaymentCopyResourceToForm,
  loanRepaymentCreateResource,
  loanRepaymentDeleteResource,
  loanRepaymentLoadCollection,
  loanRepaymentHandleFormChange,
  loanRepaymentUpdateResource,
} from '../redux/operations';
import { todosLoadCollection } from '../../todos/redux/operations';
import { authLoadPermissions } from '../../auth';
import { debounce, mergeRequestStatuses, safeAccess } from '../../utils';
import { withPermissions } from '../../routing';

import RepaymentHistoryTable from '../components/repayment-history-table';
import RepaymentHistoryForm from '../components/repayment-history-form';
import { loanRepaymentClearForm } from '../redux/operations';

const RepaymentOverview = ({
  request,
  data,
  createPayment,
  deletePayment,
  loadData,
  handleForm,
  hydrateForm,
  updatePayment,
  form,
  clearForm,
  errors,
}) => {
  const [redirectUri, setRedirectUri] = useState('');

  const [loadingToggle, setLoadingToggle] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [formId, setFormId] = useState(null);
  const [actionName, setActionName] = useState('');

  const openAddNewPaymentForm = () => {
    setFormId(null);
    setActionName('Add');
    setShowForm(true);
  };

  const closeForm = () => {
    setFormId(null);
    setActionName('');
    setShowForm(false);
    clearForm();
  };

  const onCreate = (paymentDifference, monthlyInvoiceAmount) => {
    handleForm(
      { ...form, paymentDifference: paymentDifference, monthlyInvoiceAmount: monthlyInvoiceAmount },
      ['paymentDifference']
    );
    createPayment(paymentDifference).then(() => {
      closeForm();
    });
  };

  const onUpdate = (paymentDifference) => {
    handleForm({ ...form, paymentDifference: paymentDifference }, ['paymentDifference']);
    updatePayment(formId, paymentDifference).then(() => {
      closeForm();
    });
  };

  const onDelete = () => {
    deletePayment(formId).then(() => {
      closeForm();
    });
  };

  const onChange = debounce((loanRepayment, id, attribute) => {
    let formData = form;
    handleForm({ ...formData, ...loanRepayment }, [attribute]);
  }, 250);

  const editRow = (id) => {
    setFormId(id);
    setActionName('Update');
    hydrateForm(id);
    setShowForm(true);
  };

  const deleteRow = (id) => {
    setFormId(id);
    setActionName('Delete');
    hydrateForm(id);
    setShowForm(true);
  };

  useEffect(() => {
    loadData().then(() => {
      setLoadingToggle(false);
    });
  }, []);

  useEffect(() => {
    if (!showForm) {
      loadData().then(() => {
        setLoadingToggle(false);
      });
    }
  }, [showForm]);

  let servicerNameList = [];
  let monthlyInvoiceAmount = 0;

  if (data !== undefined && data !== null && Object.keys(data).length > 0) {
    servicerNameList = Object.values(data)[0].servicerNameList;
    monthlyInvoiceAmount = Object.values(data)[0].monthlyInvoiceAmount;
  }

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <>
        <AsyncLoader request={request}>
          <DashboardSection title="Michigan 27k Student Loan Repayment">
            <Card>
              <CardBody>
                <AsyncLoader request={request} loading={<></>}>
                  <Button brand="primary" size="lg" onClick={openAddNewPaymentForm}>
                    Add Payment History
                  </Button>
                  {!loadingToggle && (
                    <RepaymentHistoryTable data={data} editRow={editRow} deleteRow={deleteRow} />
                  )}
                </AsyncLoader>
              </CardBody>
            </Card>
          </DashboardSection>
        </AsyncLoader>
        {showForm && (
          <RepaymentHistoryForm
            action={actionName}
            closeForm={closeForm}
            data={form}
            errors={errors}
            isLoading={request.isLoading}
            isOpen={showForm}
            onChange={onChange}
            onSubmit={
              actionName === 'Update' ? onUpdate : actionName === 'Add' ? onCreate : onDelete
            }
            servicerNameList={servicerNameList}
            monthlyInvoiceAmount={monthlyInvoiceAmount}
          />
        )}
      </>
    );
  }
};

RepaymentOverview.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    //state.todos.requests.updateResource,
    //state.todos.requests.loadCollection,
    state.loanRepayment.requests.updateResource,
    state.loanRepayment.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
  data: safeAccess(state, 'loanRepayment.cache', {}),
  form: state.loanRepayment.form,
  errors: state.loanRepayment.errors,
});

const mapDispatchToProps = (dispatch) => ({
  handleForm: (loanRepayment, attributes) =>
    dispatch(loanRepaymentHandleFormChange(loanRepayment, attributes)),
  loadData: async (id) => {
    await dispatch(loanRepaymentLoadCollection());
  },
  createPayment: async () => {
    await dispatch(loanRepaymentCreateResource());
    await dispatch(loanRepaymentLoadCollection());
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  updatePayment: async (id) => {
    await dispatch(loanRepaymentUpdateResource(id));
    dispatch(todosLoadCollection());
  },
  deletePayment: async (id) => {
    await dispatch(loanRepaymentDeleteResource(id));
    await dispatch(loanRepaymentLoadCollection());
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  hydrateForm: async (id) => await dispatch(loanRepaymentCopyResourceToForm(id)),
  clearForm: async () => dispatch(loanRepaymentClearForm()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(RepaymentOverview));
