import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';
import { AccountUnlockValidator } from './validators';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/account-unlock',
  resourceType: 'account-unlock',
};

const validator = new AccountUnlockValidator();

const operations = resourceOperationsGenerator(
  'accountUnlock',
  actions,
  null,
  validator,
  null,
  null,
  null,
  endpointHandler,
  null
);

const {
  accountUnlockClearForm,
  accountUnlockCopyResourceToForm,
  accountUnlockHandleFormChange,
  accountUnlockUpdateResource,
} = operations;

export {
  accountUnlockClearForm,
  accountUnlockCopyResourceToForm,
  accountUnlockHandleFormChange,
  accountUnlockUpdateResource,
};

export default operations;
