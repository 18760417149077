import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Header5,
  InputWrapper,
} from 'fiducius-ui';
import { formatRelative, parseISO } from 'date-fns';
import englishDefault from 'date-fns/locale/en-US/index';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { Scheduler } from '../../../meetings';

import ForgivenessProgress from '../components/forgiveness-progress';

import { renewalResultsLoadResource } from '../../../renewal-results';
import DocsNeededForRSUpload from '../components/docs-upload-needed';

import { todosLoadCollection } from '../../../todos/redux/operations';

class MeetingRSFS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.string,
    myMeetings: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { meetings: 0 };
  }

  componentDidMount() {
    this.props.loadData(this.props.id).then((response) => {
      let numMeetings = Object.entries(this.props.myMeetings).length;
      if (numMeetings > 0 && numMeetings !== undefined && numMeetings !== null) {
        this.setState({ meetings: numMeetings });
      }
    });
  }

  componentDidUpdate() {
    if (this.props.myMeetings !== undefined && this.state.meetings > 0) {
      if (Object.entries(this.props.myMeetings).length !== this.state.meetings) {
        this.props.loadData(this.props.id);
        this.setState({ meetings: Object.entries(this.props.myMeetings).length });
      }
    }
  }

  adjustedMeetingTime = (time) => {
    // TODO: fix API to return ISO 8601 with TZ.
    // TODO: THIS IS BROKEN FOR DAYLIGHT SAVINGS CHANGES
    // Everything in the database is EST (UTC-5)
    //return parseISO(time + '-0400');
    return parseISO(time);
  };

  render() {
    const { request, data } = this.props;
    const customRelativeLocale = {
      lastWeek: "'last' eeee 'at' p",
      yesterday: "'yesterday at' p",
      today: "'today at' p",
      tomorrow: "'tomorrow at' p",
      nextWeek: "eeee 'at' p",
      other: "MMMM do 'at' p",
    };

    const customLocale = {
      ...englishDefault,
      formatRelative: (token, date, baseDate, options) => customRelativeLocale[token],
    };

    let MeetingDateTime = null;
    if (data.meetingScheduledDate !== undefined && data.meetingScheduledDate !== null) {
      MeetingDateTime = formatRelative(
        this.adjustedMeetingTime(data.meetingScheduledDate),
        new Date(),
        {
          addSuffix: true,
          locale: customLocale,
        }
      );
    }

    return (
      <>
        <AsyncLoader request={request}>
          <ForgivenessProgress />
          {(data.slotId === undefined || data.slotId === null || data.slotId === 0) && (
            <DashboardSection>
              <Card brand="primary">
                <CardBody>
                  <CardTitle>Schedule Renewal Meeting</CardTitle>
                  <CardText>
                    <Header5>
                      The next step of the renewal process is to schedule a meeting to speak with a
                      Support Services Representative; times are available Monday through Friday
                      9:00AM to 5:00PM EST.
                    </Header5>
                    <p></p>
                    <Header5>
                      <DocsNeededForRSUpload documentButton={true} />
                    </Header5>
                  </CardText>
                </CardBody>
                <div className="row col-12">
                  <div className="col-8"></div>
                  <div className="col-4">
                    <InputWrapper>
                      <Scheduler />
                    </InputWrapper>
                  </div>
                </div>
              </Card>
            </DashboardSection>
          )}
          {data.slotId !== undefined && data.slotId !== null && data.slotId !== 0 && (
            <DashboardSection>
              <Card brand="primary">
                <CardBody>
                  <CardTitle>Renewal Meeting</CardTitle>
                  <CardText>
                    <Header5>
                      Your renewal meeting is scheduled for <strong>{MeetingDateTime}</strong>
                    </Header5>
                    <p></p>
                    <Header5>
                      <DocsNeededForRSUpload documentButton={true} />
                    </Header5>
                  </CardText>
                </CardBody>
              </Card>
            </DashboardSection>
          )}
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    myMeetings: state.meetings.cache.current,
    request: state.renewalResults.requests.loadResource,
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    dispatch(renewalResultsLoadResource(id));
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MeetingRSFS));
