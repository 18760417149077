import React from 'react';
import t from 'prop-types';
import { Table } from 'fiducius-ui';

import { formatAsMoney, startCase, convertIsoToSlash } from '../../utils';
import {
  convertMilestonePaymentCodeToDescription,
  convertMilestoneAccruementTypeCodeToDescription,
} from '../redux/selectors';

const RecentMilestonesTable = ({ data } = {}) => {
  if (Object.keys(data).length === 0) {
    return null;
  }

  const headers = [
    'dateAccrued',
    'milestoneAccruementType',
    'milestonePaymentType',
    'milestonePaymentAmount',
  ];

  let body = Object.values(data)
    .filter(
      (a) => a['dateAccrued'] !== undefined && a['dateAccrued'] !== null && a['dateAccrued'] !== ''
    )
    .filter((a, i) => i < 3)
    .map((a) => headers.map((h) => a[h]));

  headers[1] = 'accruementType';
  headers[2] = 'paymentType';
  headers[3] = 'amount';

  return (
    <Table
      body={body}
      columns={[
        { sortable: false, renderer: convertIsoToSlash },
        { sortable: false, renderer: convertMilestoneAccruementTypeCodeToDescription },
        { sortable: false, renderer: convertMilestonePaymentCodeToDescription },
        { sortable: false, renderer: formatAsMoney },
      ]}
      header={headers.map((h) => startCase(h))}
    />
  );
};

RecentMilestonesTable.propTypes = {
  data: t.object.isRequired,
};

export default RecentMilestonesTable;
