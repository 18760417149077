import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardHeader, CardText, Header5, Link } from 'fiducius-ui';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const EmpcertQualify = withPermissions(({ cache }) => (
  <Card brand="primary">
    <CardHeader>Emplify</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Verify your employment!</Header5>
        <Spaced>
          Your employer has hired Fiducius to manage the verification of your employment. Click{' '}
          <Link to="/benefits/empcert/generate">here</Link> to get started.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
));

EmpcertQualify.propTypes = {};

const mapStateToProps = (state) => {
  const id = getUserId(state);
  return {
    //cache: cache[id],
  };
};

export default connect(mapStateToProps)(withPermissions(EmpcertQualify));
