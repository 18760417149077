import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { CardBody, CardHeader, Loader } from 'fiducius-ui';
import { Redirect, withRouter } from 'react-router-dom';

import { ShadowAddCard } from '../../routing';

import SignInCRMForm from '../components/sign-in-crm';
import LoaderWrapper from '../styles/loader-wrapper';
import { isAuthenticated } from '../redux/selectors';
import { authCRMLogin, authLogout } from '../redux/operations';

class CRMLogin extends React.Component {
  static propTypes = {
    domain: t.string.isRequired,
    hasFailed: t.bool.isRequired,
    isAuthenticated: t.bool.isRequired,
    permissions: t.object,
    isLoading: t.bool.isRequired,
    location: t.object.isRequired,
    signIn: t.func.isRequired,
    clientStatus: t.string,
    auth: t.object,
    signOut: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      loaded: false,
      fromCRM: false,
    };
  }

  componentDidMount() {
    const { isAuthenticated, signOut, auth } = this.props;
    const referrer = document.referrer;
    let { loaded, fromCRM } = this.state;

    if (referrer.includes(process.env.REACT_APP_CRM_URL)) {
      fromCRM = true;
    }

    if (isAuthenticated) {
      signOut(auth.username).then((a) => {
        loaded = true;
        this.setState({ ...this.state, loaded: loaded, fromCRM: fromCRM });
      });
    } else {
      loaded = true;
      this.setState({ ...this.state, loaded: loaded, fromCRM: fromCRM });
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  getForm() {
    const { isLoading } = this.props;
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader variant="atom" size={3} />
        </LoaderWrapper>
      );
    } else {
      return <SignInCRMForm onSubmit={this.login} handleChange={this.handleChange} />;
    }
  }

  handleChange = (formState) => {
    this.setState({ ...this.state, ...formState });
  };

  login = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.signIn(username, password);
  };

  render() {
    // Just redirect if we're logged in
    if (this.state.loaded && this.props.isAuthenticated) {
      return <Redirect to={'/dashboard'} />;
    } else if (this.state.loaded && !this.state.fromCRM) {
      return <Redirect to={'/error/404'} />;
    }

    return (
      <>
        <ShadowAddCard brand={(this.props.hasFailed && 'danger') || 'secondary'}>
          {this.props.hasFailed && (
            <CardHeader>{this.props.hasFailed && <>Login Failed</>}</CardHeader>
          )}

          <CardBody>{this.getForm()}</CardBody>
        </ShadowAddCard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.auth;
  return {
    hasFailed: requests.login.hasFailed,
    isAuthenticated: isAuthenticated(state),
    isLoading: requests.login.isLoading || requests.loadPermissions.isLoading,
    auth: state.auth.cache,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password) => dispatch(authCRMLogin(username, password)),
  signOut: (username) => dispatch(authLogout(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CRMLogin));
