import React from 'react';
import t from 'prop-types';
import { Alert } from 'fiducius-ui';
import { connect } from 'react-redux';

class ContactResult extends React.Component {
  static propTypes = {
    getPaymentStatus: t.string,
    request: t.object,
    status: t.string,
  };

  componentDidMount() {}

  getMessage = () => {
    switch (this.props.status) {
      case 'success':
        return 'Your password has been successfully updated!';

      default:
        return 'There was an error updating your password.';
    }
  };

  render() {
    return (
      <Alert brand={this.props.status === 'success' ? 'success' : 'danger'}>
        {this.getMessage()}
      </Alert>
    );
  }
}

const mapStateToProps = (state) => ({
  //status: safeAccess(state, 'passwordauth.cache.contactStatus', ''),
  //request: safeAccess(state, 'contact.requests.updateResource'),
});

const mapDispatchToProps = (dispatch) => ({
  //getPaymentStatus: 'success',
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactResult);
