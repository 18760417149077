import { authLoadPermissions, getToken } from '../../../auth';
import {
  resourceOperationsGenerator,
  safeAccess,
  normalize,
  patchApi,
  keyById,
} from '../../../utils';

import actions from './actions';
import { ScreeningValidator } from './validators';
import { referrerHasExplanation } from '../components/tab-info';

const screeningValidator = new ScreeningValidator();

const fixData = (data) => {
  return data; /*
  return data.map((resource) => {
    const { birthDate, startDate } = resource;
    resource.birthDate = birthDate ? convertSlashToIso(birthDate) : '';
    resource.startDate = startDate ? convertSlashToIso(startDate) : '';
    return resource;
  });*/
};

const reloadPermissions = () => async (dispatch, getState) => {
  await dispatch(authLoadPermissions());
};

const endpointHandler = {
  endpoint: '/screening',
  customResponseHandler: fixData,
  resourceType: 'screening',
};

const operations = resourceOperationsGenerator(
  'screening',
  actions,
  null,
  screeningValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  screeningCopyResourceToForm,
  screeningHandleFormChange,
  screeningLoadResource,
  screeningUpdateResource,
  screeningValidateAttributes,
  screeningValidateState,
} = operations;

const screeningConcatenateReferralFields = () => (dispatch, getState) => {
  const form = safeAccess(getState(), 'screening.form', {});
  let referral = safeAccess(form, 'referrerName', '');
  if (referrerHasExplanation(referral)) {
    referral += ` - ${safeAccess(form, 'referrerExplanation', 'Not specified')}`;
  }
  const newForm = { ...form, referrerName: referral };
  dispatch(screeningHandleFormChange(newForm, []));
};

const screeningSkip = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(actions.screeningUpdateResourceRequest());
    const patchData = normalize('screening', { skipScreening: true }, id);
    let data = await patchApi(getToken(state), `/screening/${id}`, patchData);

    dispatch(actions.screeningUpdateResourceResponse());

    const cache = { ...state.screening.cache, ...keyById(data) };
    await dispatch(operations.screeningHandleCacheChange(cache));
  } catch (error) {
    dispatch(operations.screeningHandleError(error));
    dispatch(actions.screeningUpdateResourceFailure(error));
  }
};

export {
  screeningConcatenateReferralFields,
  screeningSkip,
  screeningCopyResourceToForm,
  screeningHandleFormChange,
  screeningLoadResource,
  screeningUpdateResource,
  screeningValidateAttributes,
  screeningValidateState,
  reloadPermissions,
};

export default operations;
