import actions from './actions';
import { SecurityQuestionsValidator } from './validators';
import { resourceOperationsGenerator, safeAccess, keyById, patchApi, normalize } from '../../utils';
import { getToken } from '../../auth';

const securityQuestionsValidator = new SecurityQuestionsValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-security-question',
  resourceType: 'customer-security-question',
};

const operations = resourceOperationsGenerator(
  'securityQuestions',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  securityQuestionsClearForm,
  securityQuestionsCopyResourceToForm,
  securityQuestionsHandleFormChange,
  securityQuestionsLoadResource,
} = operations;

const securityQuestionsUpdateResource = (id) => async (dispatch, getState) => {
  const state = getState();
  let segment = state.securityQuestions;

  try {
    if (safeAccess(segment, 'errors.length') > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }

    if (segment.meta.isReadOnly) {
      throw new Error('The form is readonly. Any changes will not be saved.');
    }

    dispatch(actions['securityQuestionsUpdateResourceRequest']());
    const patchData = normalize('customer-security-question', segment.form, id);
    let data = await patchApi(getToken(state), `/customer-security-question/${id}`, patchData);

    dispatch(actions['securityQuestionsUpdateResourceResponse']());

    const cache = { ...segment.cache, ...keyById(data) };
    await dispatch(operations['securityQuestionsHandleCacheChange'](cache));

    const auth = getState().auth;
    auth.cache.accessToken = data[0].accessToken;
    auth.cache.tokenExpiresIn = data[0].tokenExpiresIn;

    dispatch(actions['securityQuestionsUpdateResourceSuccess']());
  } catch (error) {
    dispatch(operations['securityQuestionsHandleError'](error));
    dispatch(actions['securityQuestionsUpdateResourceFailure'](error));
  }
};

export {
  securityQuestionsClearForm,
  securityQuestionsCopyResourceToForm,
  securityQuestionsHandleFormChange,
  securityQuestionsLoadResource,
  securityQuestionsUpdateResource,
};

export default operations;
