import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';

import { Button, Card, CardBody, CardTitle, CardText, CardFooter } from 'fiducius-ui';

import { getToken, authLoadPermissions } from '../../auth';
import { PaddedBadge } from '../../routing';
import { convertIsoToSlash, formatAsMoney } from '../../utils';

import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';

import { tuitionInstitutionLoadCollection } from '../../tuition-institution';

import { default as TuitionInstitutionTermForm } from './tuition-institution-term-form';

import {
  tuitionInstitutionTermClearForm,
  tuitionInstitutionTermCopyResourceToForm,
  tuitionInstitutionTermHandleFormChange,
  tuitionInstitutionTermLoadResource,
  termDeleteResource,
  termUpdateResource,
} from '../redux/operations';

import { todosLoadCollection } from '../../todos';

import { debounce, mergeRequestStatuses } from '../../utils';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const DivEnd = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 1em;
`;

class TuitionInstitutionTermRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    termId: t.number.isRequired,
    data: t.object,
    form: t.object,
    errors: t.object,
    getRequest: t.object,
    request: t.object,
    loadTerm: t.func.isRequired,
    clearTermForm: t.func.isRequired,
    handleTermForm: t.func.isRequired,
    updateTerm: t.func.isRequired,
    handleDeleteTerm: t.func.isRequired,
    hydrateTermForm: t.func.isRequired,
    token: t.any,
    hasMissingInformation: t.func,
  };

  constructor(props) {
    super(props);
    this.state = { showForm: false, isDeleting: false, formId: null };
  }

  componentDidMount() {}

  getInstitutionList = () => {
    const { data } = this.props;
    let realInstitutions = Object.keys(data).reduce(
      (obj, k) => ({
        ...obj,
        [k]: data[k].institutionName,
      }),
      {}
    );
    let institutions = { ...realInstitutions };
    return institutions;
  };

  editTerm = () => {
    const termId = this.props.data[this.props.institutionId].terms[this.props.termId].id;
    this.props.hydrateTermForm(termId);
    this.setState({ formId: termId, showForm: true, isDeleting: false });
  };
  deleteTerm = () => {
    const termId = this.props.data[this.props.institutionId].terms[this.props.termId].id;
    this.props.hydrateTermForm(termId);
    this.setState({ formId: termId, showForm: true, isDeleting: true });
  };

  onChangeTerm = debounce((tuitionInstitutionTerm, id, attributes) => {
    this.props.handleTermForm({ ...this.props.form, ...tuitionInstitutionTerm }, [attributes]);
  }, 250);

  onUpdateTerm = () => this.props.updateTerm(this.props.form.id);

  onDeleteTerm = () => this.props.handleDeleteTerm(this.props.form.id);

  closeForm = () => {
    this.props.clearTermForm();
    this.setState({
      ...this.state,
      showForm: false,
      formId: null,
    });
  };

  renderMissingInfoLengend = () => {
    this.props.hasMissingInformation(true);
  };

  render() {
    const { data = {}, institutionId, termId, request, errors, form = {}, getRequest } = this.props;
    const { showForm, formId, isDeleting } = this.state;

    const term = data[institutionId].terms[termId];
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row">
                <div className="col">{term.termName}</div>
                <DivEnd className="col-5">
                  <StyledButton
                    invert
                    brand="secondary"
                    onClick={() => this.editTerm()}
                    disabled={!term.editable}
                    title={
                      !term.editable
                        ? 'A term that is past the set organizational deadline cannot be edited. See the “Policy Quick Reference” on the Overview page for more information.'
                        : 'Edit Term'
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                  </StyledButton>
                  <StyledButton
                    invert
                    brand="danger"
                    onClick={() => this.deleteTerm()}
                    disabled={!term.deletable}
                    title={
                      !term.deletable
                        ? 'A class has been added for this term, so the term cannot be deleted.'
                        : 'Delete Term'
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} fixedWidth />
                  </StyledButton>
                </DivEnd>
              </div>
            </CardTitle>
            <CardText>
              <div className="row">
                <dl className="col-4">
                  <dt>Start Date</dt>
                  <dd>{convertIsoToSlash(term.startDate)}</dd>
                  <dt>
                    End Date
                    {term.editable &&
                      (term.endDate === null ||
                        term.endDate === undefined ||
                        term.endDate === '') && (
                        <PaddedBadge brand="primary">
                          {this.renderMissingInfoLengend()}
                          <FontAwesomeIcon icon={faSolidExclamation} />
                        </PaddedBadge>
                      )}
                  </dt>
                  <dd>{convertIsoToSlash(term.endDate)}</dd>
                </dl>
                <dl className="col-4">
                  <dt>
                    Total Cost of Term
                    {term.editable && isNaN(term.totalCost) && (
                      <PaddedBadge brand="primary">
                        {this.renderMissingInfoLengend()}
                        <FontAwesomeIcon icon={faSolidExclamation} />
                      </PaddedBadge>
                    )}
                  </dt>
                  <dd>{formatAsMoney(term.totalCost)}</dd>
                  <dt>Cost Per Credit Hour</dt>
                  <dd>{formatAsMoney(term.costPerCreditHour)}</dd>
                </dl>
                <dl className="col-4"></dl>
              </div>
            </CardText>
          </CardBody>
          {false && (
            <FlexFooter>
              <Button
                invert
                brand="secondary"
                onClick={() => this.editTerm()}
                disabled={!term.editable}
                title={
                  !term.editable
                    ? 'You are unable to edit a term that is past your organizational deadline. Please refer to the `Policy Quick Reference` on the overview page for more details.'
                    : ''
                }
              >
                Edit
              </Button>
              <Button
                invert
                brand="danger"
                onClick={() => this.deleteTerm()}
                disabled={!term.deletable}
                title={
                  !term.deletable
                    ? 'You cannot delete a term that has classes associated to it, or if it has documents associated with it.'
                    : ''
                }
              >
                Delete
              </Button>
            </FlexFooter>
          )}
        </Card>
        {showForm && (
          <TuitionInstitutionTermForm
            action={formId ? (isDeleting ? 'Delete' : 'Update') : 'Add'}
            closeForm={this.closeForm}
            data={form}
            errors={errors}
            isLoading={request.isLoading}
            getResource={getRequest}
            isOpen={showForm}
            onChange={this.onChangeTerm}
            onSubmit={
              formId ? (isDeleting ? this.onDeleteTerm : this.onUpdateTerm) : this.onCreateTerm
            }
            institutionList={this.getInstitutionList()}
            defaultInstitutionId={this.props.institutionId}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests, errors, form } = state.tuitionInstitutionTerm;
  return {
    data: getTuitionInstitutionData(state),
    token: getToken(state),
    getRequest: requests.loadResource,
    errors: errors,
    form: form,
    request: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearTermForm: () => dispatch(tuitionInstitutionTermClearForm()),
  handleTermForm: (tuitionInstitutionTerm, attributes) =>
    dispatch(tuitionInstitutionTermHandleFormChange(tuitionInstitutionTerm, attributes)),
  hydrateTermForm: async (id) => {
    await dispatch(tuitionInstitutionTermLoadResource(id));
    await dispatch(tuitionInstitutionTermCopyResourceToForm(id));
  },
  updateTerm: async (id) => {
    let shouldUpdate = await dispatch(termUpdateResource(id));
    if (shouldUpdate) {
      await dispatch(authLoadPermissions());
      await dispatch(tuitionInstitutionLoadCollection());
      dispatch(todosLoadCollection());
    }
  },
  handleDeleteTerm: async (id) => {
    await dispatch(termDeleteResource(id));
    await dispatch(tuitionInstitutionLoadCollection());
    dispatch(todosLoadCollection());
    dispatch(tuitionInstitutionTermClearForm());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionTermRow);
