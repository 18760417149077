import React from 'react';
import { connect } from 'react-redux';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Form,
  Header2,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputMask,
  InputNumeric,
  InputRadio,
  InputText,
  InputWrapper,
} from 'fiducius-ui';
import { idrValidateAttributes } from '../redux/operations';

import { convertIsoToSlash, safeAccess, stringIsNullOrEmpty } from '../../../utils';

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const InputHelp2 = styled(InputHelp)`
  color: ${(p) => p.theme.textPrimary};
`;

class TabSimple extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    handleChange: t.func,
    id: t.string.isRequired,
    permissions: t.object,
    validateSpecificAttributes: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { data } = this.props;
    let validateAttributes = [];
    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (!stringIsNullOrEmpty(value) && key !== 'agi') {
          validateAttributes.push(key);
        }
      }
    }

    this.props.validateSpecificAttributes(validateAttributes);
  }

  componentDidUpdate() {}

  render() {
    const { data = {}, errors = {}, handleChange, id } = this.props;

    return (
      <Form id={id} defaults={data} handleChange={handleChange}>
        <Header2>Tell us about your family</Header2>
        <InputWrapper error={!!errors.question5Response}>
          <InputLabel2>
            How many children, including unborn children, are in your family and receive more than
            half of their support from you?
          </InputLabel2>
          <InputError>{safeAccess(errors, 'question5Response.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric
              name="question5Response"
              min="0"
              defaultValue={data.question5Response}
              disabled={!stringIsNullOrEmpty(data.question5Response)}
            />
          </InputGroup>
          {!stringIsNullOrEmpty(data.question5Response) &&
            stringIsNullOrEmpty(data.question6Response) && (
              <InputHelp2>
                If the information for the above question is incorrect, please visit the Contact Us
                button on the left of your portal to reach out to us regarding.
                {/*please call us at{' '}
              <a href="tel:15136455400">+1 (513) 645-5400</a>.*/}
              </InputHelp2>
            )}
        </InputWrapper>
        <InputWrapper error={!!errors.question6Response}>
          <InputLabel2>
            How many other people, excluding your spouse and children, live with you and receive
            more than half of their support from you?
          </InputLabel2>
          <InputError>{safeAccess(errors, 'question6Response.detail')}</InputError>
          <InputGroup affordance="small">
            <InputNumeric
              name="question6Response"
              min="0"
              defaultValue={data.question6Response}
              disabled={!stringIsNullOrEmpty(data.question6Response)}
            />
          </InputGroup>
          {stringIsNullOrEmpty(data.question5Response) &&
            !stringIsNullOrEmpty(data.question6Response) && (
              <InputHelp2>
                If the information for the above question is incorrect, please visit the Contact Us
                button on the left of your portal to reach out to us regarding.
                {/*please call us at{' '}
              <a href="tel:15136455400">+1 (513) 645-5400</a>.*/}
              </InputHelp2>
            )}
        </InputWrapper>
        {!stringIsNullOrEmpty(data.question5Response) &&
          !stringIsNullOrEmpty(data.question6Response) && (
            <InputLabel2>
              If the information for either of the above questions is incorrect, please visit the
              Contact Us button on the left of your portal to reach out to us regarding.
              {/*please call us at{' '}
            <a href="tel:15136455400">+1 (513) 645-5400</a>.*/}
            </InputLabel2>
          )}
        {data.isMarried && (
          <>
            <InputWrapper error={!!errors.question8Response}>
              <InputLabel2>Does your spouse have federal student loans?</InputLabel2>
              <InputError>{safeAccess(errors, 'question8Response.detail')}</InputError>
              <InputRadio
                name="question8Response"
                value="A"
                label="Yes"
                defaultChecked={data.question8Response === 'A'}
              />
              <InputRadio
                name="question8Response"
                value="B"
                label="No"
                defaultChecked={data.question8Response === 'B'}
              />
            </InputWrapper>
            <InputWrapper error={!!errors.question9BResponse}>
              <InputLabel2>Spouse&#39;s Name</InputLabel2>
              <InputError>{safeAccess(errors, 'question9BResponse.detail')}</InputError>
              <InputGroup affordance="medium">
                <InputText name="question9BResponse" defaultValue={data.question9BResponse} />
              </InputGroup>
            </InputWrapper>
            <InputWrapper error={!!errors.question9CResponse}>
              <InputLabel2>Spouse&#39;s Date of Birth</InputLabel2>
              <InputError>{safeAccess(errors, 'question9CResponse.detail')}</InputError>
              <InputGroup affordance="medium">
                <InputMask
                  defaultValue={convertIsoToSlash(data.question9CResponse)}
                  mask="##/##/####"
                  name="question9CResponse"
                  placeholder="MM/DD/YYYY"
                />
              </InputGroup>
            </InputWrapper>
          </>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  validateSpecificAttributes: (attributes) => dispatch(idrValidateAttributes(attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabSimple);
