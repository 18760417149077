import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/benefit-modeler',
  resourceType: 'benefit-modeler',
};

const operations = resourceOperationsGenerator(
  'modeler',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  modelerLoadResource,
  modelerClearForm,
  modelerUpdateResource,
  modelerHandleFormChange,
  modelerCopyResourceToForm,
  modelerCreateResource,
} = operations;

export {
  modelerLoadResource,
  modelerClearForm,
  modelerUpdateResource,
  modelerHandleFormChange,
  modelerCreateResource,
  modelerCopyResourceToForm,
};

export default operations;
