import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  CardText,
  Form,
  InputGroup,
  InputLabel,
  InputWrapper,
  InputAutocomplete,
  Header5,
} from 'fiducius-ui';
import { getApi, useFocusOnMount } from '../../../utils';

const StyledButton = styled(Button)`
  align-self: flex-start;
`;

const OpeIdText = styled(CardText)`
  margin-bottom: 1.5rem;
`;

const InstitutionWrapper = styled.div`
  align-self: stretch;
  align-items: center;
  display: flex;
`;

const Highlight = styled.strong`
  background-color: ${(p) => p.theme.colorLevel(p.theme.warning, -5)};
`;

const renderInstitutionResult = (institution, value) => {
  const parts = institution.institutionName.split(value);
  return (
    <InstitutionWrapper>
      <Header5>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i + 1 !== parts.length && <Highlight>{value}</Highlight>}
          </React.Fragment>
        ))}
      </Header5>
    </InstitutionWrapper>
  );
};

const asyncFetcher = (value) => {
  if (value && value.length > 2) {
    return getApi(
      null,
      `/lend-key-accredited-institution?filter[institution-name]=like:${encodeURIComponent(value)}`
    ).then((data) => {
      if (data.length > 50) {
        return [
          {
            selected: false,
            render: () => (
              <div key="too-many-results">Too many results. Try narrowing it down.</div>
            ),
          },
        ];
      } else {
        return data.map((institution) => ({
          id: institution.id,
          name: institution.institutionName,
          render: () => renderInstitutionResult(institution, value),
        }));
      }
    });
  } else if (value) {
    return [
      {
        selected: false,
        render: () => (
          <div key="need-more">Too many results. Type more to help narrow it down.</div>
        ),
      },
    ];
  } else {
    return new Promise((resolve) => resolve([]));
  }
};

const RefinanceForm = ({ data, handleChange, onSubmit, updateOpeId, ...props }) => {
  const firstInput = useFocusOnMount();

  return (
    <Form id="refiForm" handleChange={handleChange} onSubmit={onSubmit} {...props}>
      <OpeIdText>
        Please enter the name of the institution where you completed your highest degree.
      </OpeIdText>
      <InputWrapper>
        <InputLabel>Institution Name</InputLabel>
        <InputGroup affordance="large">
          <InputAutocomplete
            inputRef={firstInput}
            fetcher={asyncFetcher}
            name="opeId"
            placeholder="Institution Name"
            onSelect={updateOpeId}
            defaultValue={data || ''}
          />
        </InputGroup>
      </InputWrapper>

      <StyledButton brand="primary" type="submit" onClick={onSubmit}>
        Get quotes
      </StyledButton>
    </Form>
  );
};

RefinanceForm.propTypes = {
  handleChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
  data: t.string,
  updateOpeId: t.func.isRequired,
};

export default RefinanceForm;
