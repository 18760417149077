import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActionIcon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 576px) {
    color: ${(p) =>
      p.theme.themeName === 'dark' || p.theme.themeName === 'greenlight'
        ? p.theme.white
        : p.theme.themeName === 'newlight'
        ? p.theme.textPrimary
        : p.theme.primary};

    &:hover {
      color: ${(p) =>
        p.theme.themeName === 'dark' || p.theme.themeName === 'newlight'
          ? p.theme.primaryB
          : p.theme.themeName === 'greenlight'
          ? p.theme.highContrast
          : p.theme.primary};
    }
  }
  @media (min-width: 577px) {
    color: ${(p) =>
      p.theme.themeName === 'newlight' || p.theme.themeName === 'greenlight'
        ? p.theme.textPrimary
        : p.theme.themeName === 'dark'
        ? p.theme.white
        : p.theme.primary};

    &:hover {
      color: ${(p) =>
        p.theme.themeName === 'dark' || p.theme.themeName === 'newlight'
          ? p.theme.primaryB
          : p.theme.themeName === 'greenlight'
          ? p.theme.primaryB
          : p.theme.primary};
    }
  }
  &:focus,
  &:active {
    color: ${(p) =>
      p.theme.themeName === 'dark' || p.theme.themeName === 'newlight'
        ? p.theme.primaryA
        : p.theme.themeName === 'greenlight'
        ? p.theme.highContrast
        : p.theme.primary};
    cursor: pointer;
  }
`;

export default ActionIcon;
