import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { Button, CardText } from 'fiducius-ui';

const SpacedButton = styled(Button)`
  margin: 1em;
`;

const SuccessfulUnlock = ({ goToLogin, goToForgotPassword }) => {
  return (
    <>
      <CardText>
        <p>You've successfully unlocked your account!</p>
        <p>
          Now you need to reset your password. Please click the button below to go to the forgot
          password workflow.
        </p>
      </CardText>
      <div className="row">
        <SpacedButton brand="primary" type="submit" onClick={goToForgotPassword}>
          Forgot Password
        </SpacedButton>
      </div>
    </>
  );
};

SuccessfulUnlock.propTypes = {
  goToLogin: t.func.isRequired,
  goToForgotPassword: t.func.isRequired,
};

export default SuccessfulUnlock;
