import React, { useContext } from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { CardTitle, Button } from 'fiducius-ui';
import { FocusButton } from './focus-button';
import Input from './input';
import { AppContext } from '../containers/plan-tool';

const StyledButton = styled(Button)`
  margin-right: 1em;
  width: inherit;
`;

export const PretaxCard = ({ inputs, page, modifiedInputs }) => {
  const { state, onChange, onChangeDeductionAction } = useContext(AppContext);
  const left = Object.entries(inputs);
  const right = Object.entries(modifiedInputs);
  return (
    <div className="polaris-input-card full" key="General">
      <CardTitle>{page}</CardTitle>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: 'calc(50% - 2rem)' }}>
          {left.map(([k, v]) => (
            <Input
              {...v}
              key={`General-${k}`}
              name={k}
              hidden={v && v.hide && !!v.hide(state)}
              onChange={onChange(page)}
              onClick={onChangeDeductionAction(page, 'Delete Deduction')}
            />
          ))}
          <div className="row">
            <div className="col-4">
              <StyledButton
                brand="primary"
                onClick={onChangeDeductionAction(page, 'Add Deduction')}
              >
                Add
              </StyledButton>
            </div>
          </div>
          <FocusButton focus={page} />
        </div>
        <div style={{ width: 'calc(50% - 2rem)' }}>
          {right.map(([k, v]) => (
            <Input
              {...v}
              key={`General-${k}`}
              name={k}
              hidden={v && v.hide && !!v.hide(state)}
              onChange={onChange('Modified Other Pre-Tax Costs / Contributions')}
              onClick={onChangeDeductionAction(
                'Modified Other Pre-Tax Costs / Contributions',
                'Delete Modified Deduction'
              )}
            />
          ))}
          <div className="row">
            <div className="col-4">
              <StyledButton
                brand="primary"
                onClick={onChangeDeductionAction(
                  'Modified Other Pre-Tax Costs / Contributions',
                  'Add Modified Deduction'
                )}
              >
                Add
              </StyledButton>
            </div>
          </div>
          <FocusButton focus={'Modified Other Pre-Tax Costs / Contributions'} />
        </div>
      </div>
    </div>
  );
};

PretaxCard.propTypes = {
  inputs: t.object.isRequired,
};
