import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';
import { PlanToolValidator } from './validators';

const planToolValidator = new PlanToolValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/plan-tool',
  resourceType: 'plan-tool',
};

const operations = resourceOperationsGenerator(
  'planTool',
  actions,
  null,
  planToolValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const loadPolarisData = (id) => async (dispatch, getState) => {
  await dispatch(planToolLoadResource(id));
  await dispatch(
    operations.planToolHandleCacheChange({
      ...getState().planTool.cache,
    })
  );
  dispatch(actions.planToolLoadResourceSuccess());
};

const {
  planToolLoadResource,
  planToolClearForm,
  planToolUpdateResource,
  planToolHandleFormChange,
  planToolHandleCacheChange,
  planToolCopyResourceToForm,
  planToolCreateResource,
} = operations;

export {
  planToolLoadResource,
  planToolClearForm,
  planToolUpdateResource,
  planToolHandleFormChange,
  planToolHandleCacheChange,
  planToolCreateResource,
  planToolCopyResourceToForm,
  loadPolarisData,
};

export default operations;
