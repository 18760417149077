import { FormValidator } from '../../../utils';

export class EducationalReengagementValidator extends FormValidator {
  // Institution Date validators
  expectedReturnToSchoolDate(validator) {
    validator.isRequired();
    validator.validDate();
    validator.isAfterDate(Date.now());
  }

  expectedGraduationDate(validator) {
    validator.isRequired();
    validator.validDate();
    validator.isAfterDate(Date.now());
  }
}
