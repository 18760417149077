import { getUserId } from '../../../auth';
import { safeAccess, formatAsMoney } from '../../../utils';

export const getContributionData = (state) =>
  safeAccess(state, `contribution.cache[${getUserId(state)}]`, {});

export const convertMilestonePaymentAmountToSentence = (paymentType, paymentAmount) => {
  let retVal = '';

  switch (paymentType.toUpperCase()) {
    case 'SETAMT':
      retVal = 'An employer increase of ' + formatAsMoney(paymentAmount) + ' each month';
      break;
    case 'MODAMT':
      retVal = paymentAmount + '% increase';
      break;
    case 'LUMP':
      retVal = 'One lump sum payment of ' + formatAsMoney(paymentAmount);
      break;
    default:
      retVal = 'Other';
  }
  return retVal;
};

export const convertMilestoneTypeAmountToSentence = (milestoneType, milestoneAmount) => {
  let retVal = '';

  switch (milestoneType.toUpperCase()) {
    case 'MONTHS':
      retVal = 'After every ' + milestoneAmount + ' month(s) you are eligible';
      break;
    case 'CALCPMTS':
      retVal =
        'After every ' + milestoneAmount + ' time(s) your payments are calculated you are eligible';
      break;
    case 'NUMPMTS':
      retVal = 'After every ' + milestoneAmount + ' payment(s) have been accrued you are eligible';
      break;
    case 'START':
      retVal =
        'At the start of every ' +
        milestoneAmount +
        ' enrollment period you will accrue this milestone.';
      break;
    default:
      retVal = 'Other';
  }
  return retVal;
};

export const convertMilestoneCodeToType = (milestoneCode) => {
  let retVal = '';
  switch (milestoneCode.toUpperCase()) {
    case 'SETAMT':
      retVal = 'Set Amount';
      break;
    case 'MODAMT':
      retVal = 'Percentage';
      break;
    case 'LUMP':
      retVal = 'Lump Sum';
      break;
    default:
      retVal = 'Other';
  }
  return retVal;
};

export const convertLoanCodeToType = (loanCode) => {
  let retVal = '';
  switch (loanCode.toUpperCase()) {
    case 'CON':
      retVal = 'FFEL Consolidated';
      break;
    case 'GB':
      retVal = 'Graduate Plus';
      break;
    case 'PLUS':
      retVal = 'Parent Plus';
      break;
    case 'PRIV':
      retVal = 'Private';
      break;
    case 'STAFF':
      retVal = 'Stafford';
      break;
    case 'SSTAF':
      retVal = 'FFEL Stafford Subsidized';
      break;
    case 'SDIR':
      retVal = 'Direct Stafford Subsidized';
      break;
    case 'DIR':
      retVal = 'Direct Consolidation';
      break;
    case 'USTAF':
      retVal = 'FFEL Stafford Unsubsidized';
      break;
    case 'DPL':
      retVal = 'Direct Plus Parent';
      break;
    case 'DPLG':
      retVal = 'Direct Plus Graduate';
      break;
    case 'FPL':
      retVal = 'FFEL Plus Parent';
      break;
    case 'FPLG':
      retVal = 'FFEL Grad Plus';
      break;
    case 'NDL':
      retVal = 'National Defense Loan';
      break;
    case 'NDSL':
      retVal = 'National Direct Student Loan';
      break;
    case 'SL':
      retVal = 'Supplemental Loan';
      break;
    case 'DPC':
      retVal = 'Direct PLUS Consolidated';
      break;
    case 'UDIR':
      retVal = 'Direct Stafford Unsubsidized';
      break;
    case 'SCON':
      retVal = 'Direct Subsidized Consolidated';
      break;
    case 'UCON':
      retVal = 'Direct Unsubsidized Consolidated';
      break;
    case 'TEACH':
      retVal = 'Direct Unsubsidized Teach';
      break;
    case 'PERK':
      retVal = 'Federal Perkins';
      break;
    case 'SLS':
      retVal = 'FFEL Supplemental Loan (SLS)';
      break;
    case 'RMBRS':
      retVal = 'Tuition Reimbursement Account';
      break;
    default:
      retVal = 'Other';
  }
  return retVal;
};
