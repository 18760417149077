import React from 'react';
import t from 'prop-types';
import { Button, Table } from 'fiducius-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';

import { formatAsMoney, formatAsPercent, startCase } from '../../utils';

const AccountTable = ({ accounts = {}, onEditAccount }) => {
  if (Object.keys(accounts).length === 0) {
    return null;
  }

  const headers = [
    'id',
    'loanDescription',
    'currentLoanBalance',
    'interestRate',
    'monthlyPayment',
    'edit',
  ];

  let body = Object.values(accounts).map((a) => headers.map((h) => a[h]));

  headers[1] = 'accountNickname';
  delete headers[0];
  body.forEach((row) => {
    const id = row[0];
    row[5] = (
      <Button invert brand="secondary" onClick={() => onEditAccount(id)}>
        <FontAwesomeIcon icon={faEdit} fixedWidth />
      </Button>
    );
    delete row[0];
  });

  return (
    <Table
      body={body}
      columns={[
        {},
        { sortable: true, width: '35%' },
        { sortable: true, renderer: formatAsMoney },
        { sortable: true, renderer: formatAsPercent },
        { sortable: true, renderer: formatAsMoney },
        { width: '10%' },
      ]}
      header={headers.map((h) => startCase(h))}
    />
  );
};

AccountTable.propTypes = {
  accounts: t.object,
  onEditAccount: t.func.isRequired,
};

export default AccountTable;
