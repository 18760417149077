import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';
import { CardBody, CardHeader, Loader, Header1 } from 'fiducius-ui';

import { ShadowAddCard } from '../../routing';
import { safeAccess } from '../../utils';

import PasswordUpdateForm from '../components/password-update-form';
import { authHandleFormChange, authPasswordUpdate } from '../redux/operations';
import { isAuthenticated, authGetRedirectPath } from '../redux/selectors';
import LoaderWrapper from '../styles/loader-wrapper';

const PasswordUpdate = ({
  errors,
  isAuthenticated,
  location,
  request,
  submitChange,
  validatePassword,
  getRedirectPathAuthenticated,
  getRedirectPathPublic,
}) => {
  const params = queryString.parse(location.search);
  const [formState, updateFormState] = useState({ token: params.token, email: '', password: '' });
  if (isAuthenticated) {
    return (
      <Redirect
        to={
          getRedirectPathAuthenticated === 'referrer' ? '/dashboard' : getRedirectPathAuthenticated
        }
      />
    );
  } else if (request.hasFinished && !request.hasFailed) {
    return (
      <Redirect to={getRedirectPathPublic === 'referrer' ? '/dashboard' : getRedirectPathPublic} />
    );
  } else {
    return (
      <>
        <Header1>Update Password</Header1>
        <ShadowAddCard brand={(request.hasFailed && 'danger') || 'primary'}>
          {request.hasFailed && <CardHeader>Password Update Failed</CardHeader>}
          <CardBody>
            {request.isLoading ? (
              <LoaderWrapper>
                <Loader variant="atom" size={3} />
              </LoaderWrapper>
            ) : (
              <PasswordUpdateForm
                data={formState}
                errors={errors}
                handleChange={(state) => {
                  updateFormState(state);
                  validatePassword(state.password, state.newPassword);
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  submitChange(formState);
                }}
              />
            )}
          </CardBody>
        </ShadowAddCard>
      </>
    );
  }
};

PasswordUpdate.propTypes = {
  errors: t.object,
  isAuthenticated: t.bool.isRequired,
  location: t.shape({
    search: t.string,
  }),
  request: t.object,
  submitChange: t.func.isRequired,
  validatePassword: t.func.isRequired,
  getRedirectPathAuthenticated: t.string,
  getRedirectPathPublic: t.string,
};

const mapStateToProps = (state) => ({
  errors: (state.auth && state.auth.errors) || {},
  isAuthenticated: isAuthenticated(state),
  request: safeAccess(state, 'auth.requests.passwordUpdate', {}),
  getRedirectPathAuthenticated: authGetRedirectPath(state, true),
  getRedirectPathPublic: authGetRedirectPath(state, false),
});

const mapDispatchToProps = (dispatch) => ({
  submitChange: ({ email, token, password, newPassword }) =>
    dispatch(authPasswordUpdate(token, email, password, newPassword)),
  validatePassword: (newPassword, password) =>
    dispatch(
      authHandleFormChange({ newPassword: newPassword, password: password }, [
        'newPassword',
        'password',
      ])
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordUpdate));
