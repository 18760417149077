import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, DashboardSection, Lede } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import { getTuitionInstitutionData } from '../redux/selectors';

import {
  tuitionInstitutionLoadCollection,
  TuitionInstitutionHistoryRow,
} from '../../../tuition-institution';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

class MyReimbursementHistory extends React.Component {
  static propTypes = {
    data: t.object,
    loadInstitution: t.func.isRequired,
    permissions: t.object,
    request: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadInstitution();
  }

  noReimbursementsOnFile() {
    return (
      <FlexRow>
        <Lede>You have not been reimbursed for any completed terms.</Lede>
      </FlexRow>
    );
  }

  render() {
    const { data = {}, request } = this.props;
    const institutions = [];
    return (
      <>
        <DashboardSection title={<>My Reimbursement History</>}>
          <AsyncLoader request={request} empty={this.noReimbursementsOnFile()}>
            {Object.keys(data).length > 0 &&
              Object.keys(data).forEach((institution) => {
                if (data[institution].previous) {
                  institutions.push(
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <TuitionInstitutionHistoryRow institutionId={institution} />
                    </div>
                  );
                }
              })}
            {institutions.length > 0 && <div className="row">{institutions}</div>}
            {institutions.length === 0 && this.noReimbursementsOnFile()}
          </AsyncLoader>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(MyReimbursementHistory));
