import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardHeader, CardText, Header5 } from 'fiducius-ui';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const OptimalSituationQualify = withPermissions(({ cache }) => (
  <Card brand="primary">
    <CardHeader>Optimal Situation</CardHeader>
    <CardBody>
      <CardText>
        <Header5>You're doing great!</Header5>
        <Spaced>
          You've optimized your current situation, but Fiducius could help you in the future.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
));

OptimalSituationQualify.propTypes = {};

const mapStateToProps = (state) => {
  const id = getUserId(state);
  return {
    //cache: cache[id],
  };
};

export default connect(mapStateToProps)(withPermissions(OptimalSituationQualify));
