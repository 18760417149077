import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Form,
  Header4,
  Header6,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputWrapper,
  Loader,
} from 'fiducius-ui';

import { mergeRequestStatuses, safeAccess } from '../../utils';

const LoaderWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const getYears = () => {
  const now = new Date();
  const thisYear = now.getFullYear();
  let years = [''];
  for (let i = 0; i < 10; i++) {
    years.push(thisYear + i);
  }
  return years;
};

class Pci extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    handleChange: t.func.isRequired,
    request: t.object,
  };

  render() {
    const { data, errors, handleChange, request } = this.props;

    if (request.isLoading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    } else {
      return (
        <>
          <Header4>Credit Card Information</Header4>
          <Header6>
            Please provide your credit card information to pay your deposit and recurring payments
            (if elected).
            <br />
            <br />
          </Header6>
          <Form id="pciForm" defaults={data} handleChange={handleChange}>
            <InputWrapper error={!!errors.cardNumber}>
              <InputLabel>Credit Card Number</InputLabel>
              <InputError>{safeAccess(errors, 'cardNumber.detail')}</InputError>
              <InputGroup affordance="medium">
                <InputMask
                  defaultValue={data.cardNumber}
                  mask="#### #### #### ####"
                  name="cardNumber"
                  placeholder="____ ____ ____ ____"
                />
              </InputGroup>
            </InputWrapper>
            <div className="row">
              <div className="col-4 col-md-2">
                <InputWrapper error={!!errors.month}>
                  <InputLabel>Month</InputLabel>
                  <InputError>{safeAccess(errors, 'month.detail')}</InputError>
                  <InputGroup>
                    <InputSelect
                      name="month"
                      options={['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                      defaultValue={data.month || ''}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-4 col-md-2">
                <InputWrapper error={!!errors.year}>
                  <InputLabel>Year</InputLabel>
                  <InputError>{safeAccess(errors, 'year.detail')}</InputError>
                  <InputGroup>
                    <InputSelect name="year" options={getYears()} defaultValue={data.year || ''} />
                  </InputGroup>
                </InputWrapper>
              </div>
              <div className="col-4 col-md-2">
                <InputWrapper error={!!errors.cvv}>
                  <InputLabel>CVV</InputLabel>
                  <InputError>{safeAccess(errors, 'cvv.detail')}</InputError>
                  <InputGroup>
                    <InputMask defaultValue={data.cvv} mask="####" name="cvv" placeholder="    " />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
          </Form>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.payment.requests.loadResource,
    state.payment.requests.updateResource,
    state.payment.requests.createResource,
  ]),
});

export default connect(mapStateToProps)(Pci);
