import actions from './actions';
import { SecurityQuestionsSetupValidator } from './validators';
import { resourceOperationsGenerator, safeAccess, keyById, postApi, normalize } from '../../utils';
import { getToken } from '../../auth';

const securityQuestionsSetupValidator = new SecurityQuestionsSetupValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-security-question-setup',
  resourceType: 'customer-security-question-setup',
};

const operations = resourceOperationsGenerator(
  'securityQuestionsSetup',
  actions,
  null,
  securityQuestionsSetupValidator,
  null,
  endpointHandler,
  endpointHandler,
  null,
  null
);

const {
  securityQuestionsSetupClearForm,
  securityQuestionsSetupCopyResourceToForm,
  securityQuestionsSetupHandleFormChange,
  securityQuestionsSetupLoadResource,
} = operations;

const securityQuestionsSetupCreateResource = () => async (dispatch, getState) => {
  const state = getState();
  let segment = state.securityQuestionsSetup;

  try {
    if (safeAccess(segment, 'errors.length') > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }

    if (segment.meta.isReadOnly) {
      throw new Error('The form is readonly. Any changes will not be saved.');
    }

    dispatch(actions['securityQuestionsSetupCreateResourceRequest']());
    const postData = normalize('customer-security-question-setup', segment.form);
    let data = await postApi(getToken(state), '/customer-security-question-setup', postData);
    dispatch(actions['securityQuestionsSetupCreateResourceResponse']());

    const addedId = safeAccess(data[0], 'id') || -1;
    const cache = { ...segment.cache, ...keyById(data) };

    const auth = getState().auth;

    auth.cache.accessToken = data[0].accessToken;
    auth.cache.tokenExpiresIn = data[0].tokenExpiresIn;

    await dispatch(operations['securityQuestionsSetupHandleCacheChange'](cache));

    dispatch(actions['securityQuestionsSetupCreateResourceSuccess']());
    return addedId;
  } catch (error) {
    dispatch(operations['securityQuestionsSetupHandleError'](error));
    dispatch(actions['securityQuestionsSetupCreateResourceFailure'](error));
  }
};

export {
  securityQuestionsSetupClearForm,
  securityQuestionsSetupCopyResourceToForm,
  securityQuestionsSetupHandleFormChange,
  securityQuestionsSetupLoadResource,
  securityQuestionsSetupCreateResource,
};

export default operations;
