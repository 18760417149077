import React from 'react';
import t from 'prop-types';
import { Button, Table } from 'fiducius-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';

import { convertIsoToSlash, startCase } from '../../../utils';

const ResultsTable = ({ loans = [], showEstimated = true }) => {
  if (loans.length === 0) {
    return null;
  }
  let columns = [];
  let displayHeaders = [];
  const displayBody = [];
  if (showEstimated) {
    displayHeaders = [
      'Loan Type',
      'Current Qualified Payments',
      'Estimated Additional Qualified Payments',
    ];
    columns = [{ sortable: true }, { sortable: true }, { sortable: true }];
    loans.forEach((row) => {
      displayBody.push([
        row.loan.loanTypeName,
        row.loan.loanPslfCumulativeMatchedMonths | 0,
        row.estimatedQps > 120 ? '120+' : row.estimatedQps || 0,
      ]);
    });
  } else {
    displayHeaders = ['Loan Type', 'Current Qualified Payments'];
    columns = [{ sortable: true }, { sortable: true }];
    loans.forEach((row) => {
      displayBody.push([row.loan.loanTypeName, row.loan.loanPslfCumulativeMatchedMonths | 0]);
    });
  }
  return (
    <Table
      meta={{
        pageSize: 5,
      }}
      body={displayBody}
      columns={columns}
      header={displayHeaders}
    />
  );
};

ResultsTable.propTypes = {
  loans: t.array,
  showEstimated: t.bool,
};

export default ResultsTable;
