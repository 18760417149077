import React from 'react';
import t from 'prop-types';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Link,
  Header3,
  Header4,
  Button,
  BrandColor,
  Lede,
  ProgressBar,
} from 'fiducius-ui';
import { formatAsMoney, safeAccess } from '../../../utils';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getUserId } from '../../../auth';
import { withPermissions, PaddedDD } from '../../../routing';

import { tuitionLoadResource } from '../redux/operations';
import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import { getTuitionData, getTuitionInstitutionData } from '../redux/selectors';
import b64toBlob from 'b64-to-blob';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

class TuitionSummary extends React.Component {
  static propTypes = {
    data: t.object,
    institutionData: t.object,
    id: t.string.isRequired,
    load: t.func.isRequired,
    request: t.object.isRequired,
    institutionRequest: t.object,
    getFAQ: t.func.isRequired,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id, load } = this.props;
    load(id);
  }

  needSpecialMessage = () => {
    return (
      this.props.permissions.strategicPartnerId === 161 ||
      this.props.permissions.strategicPartnerId === parseInt(process.env.REACT_APP_NFPTEST_ID)
    );
  };

  onDownloadFaq = () => {
    this.props.getFAQ().then((data) => {
      if (safeAccess(data, 'fileData')) {
        this.getFileFromData(data.fileData, 'FAQ.pdf');
      }
    });
  };

  getFileFromData = (data, name) => {
    const blob = b64toBlob(data);

    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      return navigator.msSaveBlob(blob, name);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      // Firefox and Edge require actual appended element for click()
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }
  };

  noReimbursementsOnFile = () => {
    return (
      <div className="col-12">
        <Lede>You have no active terms.</Lede>
      </div>
    );
  };

  getAllStepsDenied(institutionId, termId) {
    const { institutionData } = this.props;
    const term = institutionData[institutionId].terms[termId];
    let retVal = false;
    if (Object.keys(term.classes).length > 0) {
      retVal = true;
      Object.keys(term.classes).forEach((myClass) => {
        if (term.classes[myClass].currentStatusCode !== 'REIMDENY') {
          retVal = false;
        }
      });
    }
    return retVal;
  }

  getCurrentStepText(completedSteps, institutionId, termId) {
    let stepText = '';
    switch (completedSteps) {
      case 0:
        stepText = 'Create Class';
        break;
      case 1:
        stepText = 'Manager Approval Needed';
        break;
      case 2:
        stepText = 'School Term in Progress';
        break;
      case 3:
        stepText = 'Proof of Payment and Transcript Required';
        break;
      case 4:
        stepText = 'Reimbursement Approval Pending';
        break;
      case 5:
        stepText = this.getAllStepsDenied(institutionId, termId)
          ? 'Reimbursement Denied'
          : 'Reimbursement Processing';
        break;
      case 6:
        stepText = 'Reimbursement Sent';
        break;
      default:
        break;
    }
    return stepText;
  }

  getCompletedSteps(institutionId, termId) {
    const { institutionData } = this.props;
    const term = institutionData[institutionId].terms[termId];

    let completedSteps = 8;
    const termEndDate = new Date(term.endDate);
    const today = new Date();

    if (Object.keys(term.classes).length > 0) {
      Object.keys(term.classes).forEach((myClass) => {
        switch (term.classes[myClass].currentStatusCode) {
          case 'CREATED':
            completedSteps = Math.min(completedSteps, 0);
            break;
          case 'WAIT':
            completedSteps = Math.min(completedSteps, 1);
            break;
          case 'APPR':
          case 'PREPAYAPPR':
          case 'PREPAYSENT':
          case 'PREPAYCOMP':
            completedSteps = Math.min(completedSteps, 2);
            break;
          case 'WAITPRFRPT':
          case 'WAITPRF':
          case 'WAITRPT':
            completedSteps = Math.min(completedSteps, 3);
            break;
          case 'PRFRPTUPLD':
          case 'PRFRPTAPPR':
          case 'PRFRPTDENY':
          case 'WAITPSTAPR':
          case 'PSTAPPR':
          case 'PSTDENY':
            completedSteps = Math.min(completedSteps, 4);
            break;
          case 'REIMSENT':
          case 'REIMDENY':
            completedSteps = Math.min(completedSteps, 5);
            break;
          case 'COMPLETE':
            completedSteps = Math.min(completedSteps, 6);
            break;
          case 'REIMOVER':
            if (today < termEndDate) {
              completedSteps = Math.min(completedSteps, 2);
            } else {
              completedSteps = Math.min(completedSteps, 5);
            }
            break;
          default:
            break;
        }
      });
    }

    return completedSteps;
  }

  getTermInfo = (institution, term) => {
    const { institutionData } = this.props;
    const completedSteps = this.getCompletedSteps(institution, term);
    const stepText = this.getCurrentStepText(completedSteps, institution, term);

    return (
      <>
        {completedSteps < 8 && (
          <div className="row">
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <dl>
                <dd>{institutionData[institution].institutionName}</dd>
                <dd>{institutionData[institution].terms[term].termName}</dd>
              </dl>
            </div>
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <dl>
                <dd>
                  <ProgressBar
                    categories={[
                      {
                        brand: 'success',
                        name: 'success',
                        title: 'Completed',
                        value: (100 * completedSteps) / 6,
                      },

                      {
                        brand: this.getAllStepsDenied(institution, term) ? 'danger' : 'info',
                        name: 'info',
                        title: 'Current',
                        value: (100 * (completedSteps === 6 ? 0 : 1)) / 6,
                      },
                      {
                        brand: 'warning',
                        name: 'warning',
                        title: 'To Be Completed',
                        value: (100 * (completedSteps === 6 ? 0 : 5 - completedSteps)) / 6,
                      },
                    ]}
                  />
                </dd>
                <dd>Stage: {stepText}</dd>
              </dl>
            </div>
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      data = {},
      institutionData = {},
      request,
      institutionRequest,
      permissions,
    } = this.props;

    const terms = [];
    return (
      <Card brand="tertiary">
        <CardBody>
          <CardTitle>
            <div className="row">
              <div className="col-12 col-sm-8">Tuition Reimbursement</div>
              <div className="col-12 col-sm-4">
                <AsyncLoader request={request} loading={<></>}>
                  {data.faqUploaded && (
                    <Button brand="primary" onClick={this.onDownloadFaq}>
                      <PaddedIcon icon={faFileDownload} fixedWidth />
                      Download Policy Information
                    </Button>
                  )}
                </AsyncLoader>
              </div>
            </div>
          </CardTitle>
          <AsyncLoader request={request}>
            {data.alertMessage && (
              <>
                <div className="row">
                  <div className="col-12">
                    <Header3>{data.alertMessage}</Header3>
                  </div>
                </div>
              </>
            )}
            <CardText>
              <div className="row">
                <div className="col-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <dl>
                    <dt>Total Reimbursement</dt>
                    <PaddedDD>
                      <BrandColor brand="secondary">
                        {formatAsMoney(data.totalReimbursed)}
                      </BrandColor>
                    </PaddedDD>
                  </dl>
                </div>
                <div className="col-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <dl>
                    <dt>YTD Reimbursement</dt>
                    <PaddedDD>
                      <BrandColor brand="secondary">{formatAsMoney(data.ytdReimbursed)}</BrandColor>
                      {!this.needSpecialMessage() && <> /{formatAsMoney(data.maxYtdReimbursed)}</>}
                    </PaddedDD>
                  </dl>
                </div>
                <div className="col-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
                  <dl>
                    <dt>Pending Reimbursement</dt>
                    <PaddedDD>
                      <BrandColor brand="secondary">
                        {formatAsMoney(data.pendingReimbursement)}
                      </BrandColor>
                    </PaddedDD>
                  </dl>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Header4>Active Term(s)</Header4>
                </div>
              </div>
              <AsyncLoader
                request={institutionRequest}
                empty={this.noReimbursementsOnFile()}
                loading={<></>}
              >
                {Object.keys(institutionData).length > 0 &&
                  Object.keys(institutionData).forEach((institution) => {
                    if (institutionData[institution].current) {
                      Object.keys(institutionData[institution].terms).length > 0 &&
                        Object.keys(institutionData[institution].terms).forEach((term) => {
                          if (institutionData[institution].terms[term].current) {
                            terms.push(this.getTermInfo(institution, term));
                          }
                        });
                    }
                  })}
                {terms.length > 0 && terms}
                {terms.length === 0 && this.noReimbursementsOnFile()}
              </AsyncLoader>
            </CardText>
          </AsyncLoader>
        </CardBody>
        <FlexFooter>
          {!permissions.tuitionReadOnly && (
            <>
              <Link to="/benefits/tuition/overview">Tuition Reimbursement</Link>
              <Link to="/accounts">My Accounts</Link>
            </>
          )}
          {permissions.tuitionReadOnly && (
            <>
              <Link to="/benefits/tuition/history">Tuition Reimbursement History</Link>
            </>
          )}
        </FlexFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  data: getTuitionData(state),
  institutionData: getTuitionInstitutionData(state),
  id: getUserId(state),
  request: state.tuition.requests.loadResource,
  institutionRequest: state.tuitionInstitution.requests.loadCollection,
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => {
    dispatch(tuitionLoadResource(id));
    dispatch(tuitionInstitutionLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TuitionSummary));
