import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { Button, Card, CardBody, CardHeader, CardText, Header5 } from 'fiducius-ui';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

class LwoQualify extends React.Component {
  static propTypes = {
    showLwoButton: t.bool,
    redirectUri: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { showLwoButton, redirectUri } = this.props;
    return (
      <Card brand="primary">
        <CardHeader>Assure&#8480;</CardHeader>
        <CardBody>
          <CardText>
            <Header5>Am I eligible to get more qualifying payments?</Header5>
            <Spaced>
              With some PSLF requirements temporarily relaxed, you could receive more payments
              towards forgiveness.
            </Spaced>
            {showLwoButton && (
              <>
                <Button
                  brand="primary"
                  onClick={() =>
                    redirectUri('/benefits/limited-waiver-opportunity/loan-information')
                  }
                >
                  Go To Assure&#8480;
                </Button>
              </>
            )}
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

export default LwoQualify;
