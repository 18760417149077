import React from 'react';
import { Card, CardBody, CardText, CardTitle, Link } from 'fiducius-ui';

const InsuranceEnrolled = () => (
  <Card brand="success">
    <CardBody>
      <CardTitle>Disability Insurance</CardTitle>
      <CardText>
        You may be qualified for the{' '}
        <Link
          to="https://www.ameritas.com/wps/portal/corp/Home/home/!ut/p/z1/pVHLTsMwEPwWDj1adhrjOMegAlVEhSpo2viCHNuBhdhJWydQvh5TISSESoW6t33NzO5ggVdYODnAo_TQOtmEvBTsIZlHbJpSMiNZcUWySZreX_AoIrcRLrDAQjnf-SdcgtMwgO5lMyLdptW98tsR0bCVFTTgdwicaq35LDlpQaG6d1pa43ygCjidAo1Lys4VNZVBkhmKKJcccVpXKGX1WDMllaYKL48JE6FNDkRGwr7Yj3wjXE-SLCAs5uMiTwnh7GvgD4wyaEgOaqARXg5gXvHCtRsbDrz754nTowzxiQz5zxf8fmKwH57Xa5EFj1vnzZvHq5NM7qzl8Q691LPLmJb58H6TnX0AAPkStA!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
          external
          icon
        >
          Ameritas DInamic Fundamental®
        </Link>{' '}
        insurance plan. <Link to="/benefits/insurance">Learn more.</Link>
      </CardText>
    </CardBody>
  </Card>
);

export default InsuranceEnrolled;
