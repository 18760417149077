import actions from './actions';
import { CustomerContributionValidator } from './validators';
import { resourceOperationsGenerator } from '../../utils';

const validator = new CustomerContributionValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-contribution',
  resourceType: 'customer-contribution',
};

const operations = resourceOperationsGenerator(
  'customerContribution',
  actions,
  null,
  validator,
  endpointHandler,
  null,
  endpointHandler,
  endpointHandler,
  null
);

const {
  customerContributionClearForm,
  customerContributionCopyResourceToForm,
  customerContributionHandleFormChange,
  customerContributionLoadCollection,
  customerContributionCreateResource,
  customerContributionUpdateResource,
} = operations;

export {
  customerContributionClearForm,
  customerContributionCopyResourceToForm,
  customerContributionHandleFormChange,
  customerContributionLoadCollection,
  customerContributionCreateResource,
  customerContributionUpdateResource,
};

export default operations;
