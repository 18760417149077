import React, { useContext } from 'react';
import { Button } from 'fiducius-ui';

import { FocusContext } from '../containers/plan-tool';

export const FocusButton = ({ focus }) => {
  const { focusPage } = useContext(FocusContext);
  return (
    <Button brand="primary" onClick={() => focusPage(focus)}>
      {focus ? 'See more details' : 'Back to summary'}
    </Button>
  );
};

FocusButton.propTypes = {};
