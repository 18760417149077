import React from 'react';
import t from 'prop-types';
import b64toBlob from 'b64-to-blob';
import { connect } from 'react-redux';
import { FileUpload } from 'fiducius-ui';

import { todosLoadCollection } from '../../todos';
import { authLoadPermissions } from '../../auth';
import { safeAccess } from '../../utils';

import {
  documentsHandleFormChange,
  documentsLoadExampleFile,
  documentsLoadResource,
  documentsCreateResource,
  documentsRemoveFromCache,
  documentClearUploadedFile,
  documentsLoadCollection,
} from '../redux/operations';

import { fulfillmentLoadCollection } from '../../fulfillment/redux/operations';

import ApiFileWrapper from '../styles/api-file-wrapper';
import ExampleFileButton from '../styles/example-file-button';

const NSLDSFileTypeList = ['.txt'];
const OthersFileTypeList = ['.pdf', '.gif', '.bmp', '.jpg', '.jpeg', '.tif', '.tiff', '.png'];

class ApiFile extends React.Component {
  static propTypes = {
    file: t.object,
    exampleFile: t.object,
    getDocData: t.func.isRequired,
    getExampleDocData: t.func.isRequired,
    hasFailed: t.bool.isRequired,
    isDownloading: t.bool.isRequired,
    isUploading: t.bool.isRequired,
    reloadTodos: t.func.isRequired,
    removeFromCache: t.func.isRequired,
    requiredUpload: t.bool,
    uploadDoc: t.func.isRequired,
    uploadCompleted: t.func,
    clearUploadedFile: t.func,
    addError: t.func.isRequired,
    loadDocs: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldDownload: false,
      shouldDownloadExampleFile: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      exampleFile,
      file,
      hasFailed,
      isUploading,
      reloadTodos,
      removeFromCache,
      clearUploadedFile,
      isDownloading,
    } = this.props;
    const { shouldDownload, shouldDownloadExampleFile } = this.state;

    if (shouldDownload && file.fileData) {
      this.getFileFromData(file.fileData, file.name);
      this.setState({ shouldDownload: false });
    }

    if (shouldDownloadExampleFile && safeAccess(exampleFile, 'fileData')) {
      this.getFileFromData(exampleFile.fileData, `Example ${file.description}${exampleFile.name}`);
      this.setState({ shouldDownloadExampleFile: false });
    }

    if (prevProps.isDownloading && !isDownloading) {
      //this.props.loadDocs();
    }

    if (prevProps.isUploading && !isUploading) {
      reloadTodos();
      this.docUploaded(file, hasFailed);
      if (hasFailed) {
        clearUploadedFile(file.id);
      } else {
        removeFromCache(file.id);
      }
      this.setState({ ...this.state });
    }
  }

  docUploaded = (file, hasFailed) => {
    let trDoc = false;
    let refreshPage = false;
    if (!hasFailed) {
      for (let [key, value] of Object.entries(file)) {
        if (key === 'fileTypeCd' && (value === 'RPTCD' || value === 'PRFPY')) {
          trDoc = true;
        }
        if (key === 'fileTypeCd' && value === 'PACCT') {
          refreshPage = true;
        }
      }
    }
    if (!Object.keys(file).includes('signaturePadClient')) {
      //the if excludes fulfillment files.
      this.props.uploadCompleted(trDoc, refreshPage);
    }
  };

  onUpload = async (file) => {
    const data = await this.extractFileData(file);
    const rest = this.props.file;
    this.props.uploadDoc({ ...rest, ...data });
  };

  onUploadFailure = async (file) => {
    //const data = await this.extractFileData(file);
    const rest = this.props.file;

    if (rest.fileTypeCd === 'LNDT' || rest.fileTypeCd === 'SNLDS') {
      this.props.addError({
        title: 'Invalid file extension',
        detail: 'NSLDS files must be uploaded as a text file. (*.txt)',
      });
    } else {
      this.props.addError({
        title: 'Invalid file extension',
        detail:
          'File must be uploaded in one of the following file formats. (*.pdf, *.gif, *.bmp, *.jpg, *.jpeg, *.tif, *.tiff, *.png)',
      });
    }

    this.props.uploadCompleted(this.props.file, true);
  };

  extractFileData = async (file) => {
    const fileString = await this.fileToBase64(file);
    return {
      fileData: fileString,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      name: file.name,
      size: file.size,
      contentType: file.type,
    };
  };

  fileToBase64 = (file) => {
    let base64string;
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error('Error parsing input file'));
      };

      reader.onload = () => {
        const dataUrl = reader.result;
        base64string = dataUrl.split(',')[1];
        resolve(base64string);
      };

      try {
        reader.readAsDataURL(file);
      } catch (ex) {
        reader.abort();
      }
    });
  };

  onDownload = () => {
    const { id, fileData, name } = this.props.file;
    if (fileData) {
      this.getFileFromData(fileData, name);
    } else {
      this.setState({ shouldDownload: true }, () => this.props.getDocData(id));
    }
  };

  onDownloadExample = () => {
    const { exampleFile, file } = this.props;
    if (safeAccess(exampleFile, 'fileData', false)) {
      this.getFileFromData(exampleFile.fileData, `Example ${file.description}${exampleFile.name}`);
    } else {
      this.setState({ shouldDownloadExampleFile: true }, () =>
        this.props.getExampleDocData(file.fileTypeCd, file.id)
      );
    }
  };

  getExampleFileBadge = () => {
    const { hasExampleFile } = this.props.file;
    return (
      <ExampleFileButton brand="secondary" show={hasExampleFile} onClick={this.onDownloadExample}>
        Example
      </ExampleFileButton>
    );
  };

  getFileFromData = (data, name) => {
    const blob = b64toBlob(data);

    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      return navigator.msSaveBlob(blob, name);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      // Firefox and Edge require actual appended element for click()
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }
  };

  getUploaderProps() {
    const { allowedDownload, description, fileTypeCd, name, type } = this.props.file;
    let props = {
      accept: [],
      allowDelete: false,
      allowDownload: false,
      allowUpload: false,
      disabled: true,
      fileName: name,
      fileType: type,
      isDownloading: this.props.isDownloading,
      isUploading: this.props.isUploading,
      requiredUpload: this.props.requiredUpload,
      title: description,
    };

    // Some file types in the database should be validated for type
    if (fileTypeCd === 'LNDT' || fileTypeCd === 'SNLDS') {
      props.accept = NSLDSFileTypeList;
    } else {
      props.accept = OthersFileTypeList;
    }

    // only enable actions that should be enabled
    if (allowedDownload) {
      props.allowDownload = true;
      props.disabled = false;
      props.onDownloadFile = this.onDownload;
    } else if (!name) {
      props.allowUpload = true;
      props.disabled = false;
      props.onAddFile = this.onUpload;
      props.onAddFileFailure = this.onUploadFailure;
    }

    return props;
  }

  render() {
    return (
      <ApiFileWrapper>
        {this.getExampleFileBadge()}
        <FileUpload {...this.getUploaderProps()} />
      </ApiFileWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { createResource, loadResource } = state.documents.requests;
  let hasFailed = true;
  let isUploading = false;
  let isDownloading = false;
  if (createResource.key === ownProps.file.id || loadResource.key === ownProps.file.id) {
    hasFailed = createResource.hasFailed || loadResource.hasFailed;
    isUploading = createResource.isLoading;
    isDownloading = loadResource.isLoading;
  }

  return { hasFailed, isUploading, isDownloading };
};

const mapDispatchToProps = (dispatch) => ({
  getDocData: async (id) => {
    await dispatch(documentsLoadResource(id, id));
    await dispatch(documentsRemoveFromCache(id));
  },
  getExampleDocData: (code, hostFileId) => dispatch(documentsLoadExampleFile(code, hostFileId)),

  reloadTodos: () => dispatch(todosLoadCollection()),
  removeFromCache: (id) => dispatch(documentsRemoveFromCache(id)),
  uploadDoc: async (file) => {
    await dispatch(documentsHandleFormChange(file));
    await dispatch(documentsCreateResource(file.id));
    await dispatch(authLoadPermissions());
  },
  clearUploadedFile: (id) => {
    dispatch(documentClearUploadedFile(id));
  },
  loadDocs: async () => {
    await dispatch(authLoadPermissions());
    dispatch(documentsLoadCollection());
    dispatch(fulfillmentLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiFile);
