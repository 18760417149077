import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import b64toBlob from 'b64-to-blob';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';

import { withPermissions, PaddedDD } from '../../../routing';
import { FlexEnd } from '../../../root';
import {
  BrandColor,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Lede,
  Modal,
  Header3,
} from 'fiducius-ui';

import { formatAsMoney, safeAccess } from '../../../utils';

import { getTuitionData } from '../redux/selectors';

import { documentsLoadTrFaq } from '../../../documents';

import TuitionSetupSummary from './tuition-setup-summary';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const PolicyInformationDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 575px) {
    justify-content: flex-start;
    margin-top: 0.5em;
  }
`;

class OverviewSummary extends React.Component {
  static propTypes = {
    data: t.object,
    getFAQ: t.func.isRequired,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSetUp: false,
    }; // must be initialized for data handling

    this.handleSetupButton = this.handleSetupButton.bind(this);
  }

  handleSetupButton() {
    this.setState({ showSetUp: !this.state.showSetUp });
  }

  handleFilingStatus(event) {
    this.setState({ filingStatus: event.target.value });
  }

  componentDidMount() {}

  onDownloadFaq = () => {
    this.props.getFAQ().then((data) => {
      if (safeAccess(data, 'fileData')) {
        this.getFileFromData(data.fileData, 'FAQ.pdf');
      }
    });
  };

  getFileFromData = (data, name) => {
    const blob = b64toBlob(data);

    // IE10+ : (has Blob, but not a[download] or URL)
    if (navigator.msSaveBlob) {
      return navigator.msSaveBlob(blob, name);
    } else {
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      // Firefox and Edge require actual appended element for click()
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }
  };

  needSpecialMessage = () => {
    return (
      this.props.permissions.strategicPartnerId === 161 ||
      this.props.permissions.strategicPartnerId === parseInt(process.env.REACT_APP_NFPTEST_ID)
    );
  };

  render() {
    const { data = {} } = this.props;
    return (
      <>
        <Card>
          <CardBody>
            <CardTitle>
              <div className="row">
                <div className="col-12 col-sm-7">Summary Information</div>
                <PolicyInformationDiv className="col-12 col-sm-5">
                  {data.faqUploaded && (
                    <Button brand="primary" onClick={this.onDownloadFaq}>
                      <PaddedIcon icon={faFileDownload} fixedWidth />
                      Download Policy Information
                    </Button>
                  )}
                </PolicyInformationDiv>
              </div>
            </CardTitle>
            <CardText>
              {data.alertMessage && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <Header3>{data.alertMessage}</Header3>
                    </div>
                  </div>
                </>
              )}
              {this.needSpecialMessage() && (
                <>
                  <FlexRow>
                    {data.maxYtdReimbursed > 2625 && (
                      <Lede>
                        Your annual maximum is $3,000 unless you take classes at one of the
                        UnityPoint Health Colleges, then it is $5,250
                      </Lede>
                    )}
                    {data.maxYtdReimbursed < 2626 && (
                      <Lede>
                        Your annual maximum is $1,500 unless you take classes at one of the
                        UnityPoint Health Colleges, then it is $2,625
                      </Lede>
                    )}
                  </FlexRow>
                  <p />
                </>
              )}

              <div className="row">
                <div className="col-12 col-sm-4">
                  <Lede>
                    <dl>
                      <dt>
                        <Lede>Total Reimbursement</Lede>
                      </dt>
                      <PaddedDD>
                        <BrandColor brand="secondary">
                          {formatAsMoney(data.totalReimbursed)}
                        </BrandColor>
                      </PaddedDD>
                    </dl>
                  </Lede>
                </div>
                <div className="col-12 col-sm-4">
                  <Lede>
                    <dl>
                      <dt>
                        <Lede>YTD Reimbursement</Lede>
                      </dt>
                      <PaddedDD>
                        <BrandColor brand="secondary">
                          {formatAsMoney(data.ytdReimbursed)}
                        </BrandColor>
                        {!this.needSpecialMessage() && (
                          <> /{formatAsMoney(data.maxYtdReimbursed)}</>
                        )}
                      </PaddedDD>
                    </dl>
                  </Lede>
                </div>
                <div className="col-12 col-sm-4">
                  <Lede>
                    <dl>
                      <dt>
                        <Lede>Pending Reimbursement</Lede>
                      </dt>
                      <PaddedDD>
                        <BrandColor brand="secondary">
                          {formatAsMoney(data.pendingReimbursement)}
                        </BrandColor>
                      </PaddedDD>
                    </dl>
                  </Lede>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-4">
                  <Lede>
                    <Button brand="primary" onClick={this.handleSetupButton}>
                      <PaddedIcon icon={faQuestionCircle} fixedWidth />
                      Policy Quick Reference
                    </Button>
                  </Lede>
                </div>
              </div>
            </CardText>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.showSetUp} fullWidth>
          <TuitionSetupSummary data={data} closeModal={this.handleSetupButton} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: getTuitionData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFAQ: () => {
    return dispatch(documentsLoadTrFaq());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(OverviewSummary));
