import React, { useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Link,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { formatAsMoney, safeAccess } from '../../../utils';
import { AffiliateLink } from '../../affiliates';

import {
  SPLASH_PROVIDER_ID,
  LENDKEY_PROVIDER_ID,
  LAURELROAD_PROVIDER_ID,
  SOFI_PROVIDER_ID,
} from '../containers/my-refinance';
import { refiLoadResource } from '../redux/operations';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const LoaderWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const MyRefinanceSummary = ({ data = {}, id, load, request, history }) => {
  useEffect(() => {
    load(id);
  }, []);
  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <Card brand="primary">
          <CardBody>
            <CardTitle>Refinance</CardTitle>
            <CardText>
              <Spaced>
                You could save on student loans by lowering your interest rate or shortening your
                loan term.
              </Spaced>
              <AsyncLoader request={request}>
                <>
                  <div className="row">
                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                      <dl>
                        <dt>Current Loan Balance</dt>
                        <dd> {formatAsMoney(data.currentLoanBalance || '')} </dd>
                      </dl>
                    </div>
                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                      <dl>
                        <dt>Current Monthly Payment</dt>
                        <dd> {formatAsMoney(data.currentMonthlyPayment || '')} </dd>
                      </dl>
                    </div>
                  </div>
                  <LoaderWrapper>
                    {safeAccess(data, 'providerId', false) === SPLASH_PROVIDER_ID && (
                      <a
                        href="https://splashfinancial.com/partner/Fiducius"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Get Quotes
                      </a>
                    )}
                    {safeAccess(data, 'providerId', false) === LAURELROAD_PROVIDER_ID && (
                      <a
                        href="https://www.laurelroad.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Get Quotes
                      </a>
                    )}
                    {safeAccess(data, 'providerId', false) === SOFI_PROVIDER_ID && (
                      <AffiliateLink
                        affiliateName="sofi"
                        linkText="Get Quotes"
                        targetText="_blank"
                        relText={'noopener noreferrer'}
                      />
                    )}
                    {safeAccess(data, 'providerId', false) === LENDKEY_PROVIDER_ID && (
                      <Button brand="primary" onClick={() => history.push('/benefits/refinance')}>
                        Get quotes now
                      </Button>
                    )}
                  </LoaderWrapper>
                </>
              </AsyncLoader>
            </CardText>
          </CardBody>
          <FlexFooter>
            <Link to="/benefits/refinance">My Refinance</Link>
            <Link to="/accounts">My Accounts</Link>
          </FlexFooter>
        </Card>
      </div>
    </div>
  );
};

MyRefinanceSummary.propTypes = {
  data: t.object,
  id: t.string.isRequired,
  load: t.func.isRequired,
  request: t.object.isRequired,
  history: t.object,
};

const mapStateToProps = (state) => ({
  data: state.refi.cache[getUserId(state)],
  id: getUserId(state),
  request: state.refi.requests.loadResource,
});

const mapDispatchToProps = (dispatch) => ({
  load: (id) => dispatch(refiLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyRefinanceSummary));
