import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { renewalResultsLoadResource } from '../../../renewal-results';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';

class NSLDSUpload extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  componentDidUpdate() {}

  render() {
    const { request } = this.props;

    return (
      <>
        <AsyncLoader request={request}>
          <ForgivenessProgress />
          <DashboardSection>
            <Card brand="primary">
              <CardBody>
                <CardTitle>NSLDS</CardTitle>
                <CardText>
                  <p>Completing this step will allow us to re-evaluate your Forgiveness plan.</p>
                </CardText>
              </CardBody>
              <RenewalConfirmation stepId={351} />
            </Card>
          </DashboardSection>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: state.renewalResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    dispatch(renewalResultsLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(NSLDSUpload));
