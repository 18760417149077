import React from 'react';
import styled from 'styled-components';
import { Button } from 'fiducius-ui';

const ExampleFileButton = styled(({ show, ...props }) => <Button {...props} />)`
  border-radius: ${(p) => p.theme.borderRadiusBadge};
  left: -0.1rem;
  padding: 0 0.65rem;
  position: absolute;
  top: 0.2rem;
  visibility: ${(p) => (p.show ? 'visible' : 'hidden')};
  z-index: 1;
`;

export default ExampleFileButton;
