import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import ResultsTable from './results-table';
import { AsyncLoader, CardTitle } from 'fiducius-ui';

const StyledDiv = styled.div`
  margin-top: 1em;
`;

const Results = ({ loans = [], permissions = {}, request = {} }) => {
  const showResults = ['Questionnaire', 'LoanInformation', 'WaitPslf'].includes(
    permissions.lwoSteps.find((a) => a.stepCurrent).id
  )
    ? false
    : true;

  if (request.isLoading) {
    return <AsyncLoader request={request} />;
  } else {
    return (
      <>
        {showResults && (
          <>
            <StyledDiv>
              <CardTitle>Here Are Your Results</CardTitle>
            </StyledDiv>
            <StyledDiv className="row">
              <div className="col-12">
                {loans.length === 0 && (
                  <p>
                    We're sorry, but based on the information you've provided so far, it appears you
                    would not receive any additional qualifying payments by using the LWO. However,
                    Fiducius may be able to help with your student loans in other ways. Click the
                    "Go to current step" button and we'll cover your options.
                  </p>
                )}
                {loans.length > 0 && (
                  <>
                    <p>
                      Great news! Based on the information you've provided so far, it appears you
                      could receive additional qualifying payments by using the LWO. Your next step
                      is to enroll in Assure&#8480;, so Fiducius can guarantee you'll get all the
                      additional qualifying payments you deserve. Click the "Go to current step"
                      button and we'll get you started.
                    </p>
                    <ResultsTable loans={loans} />
                  </>
                )}
              </div>
            </StyledDiv>
          </>
        )}
      </>
    );
  }
};

Results.propTypes = {
  loans: t.array,
  permissions: t.object.isRequired,
  request: t.object,
};

export default Results;
