import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <strong>Step 1 - Complete your employment certification(s)</strong>
    <ul>
      <li>
        Complete the first page of the employment certification(s) with a hand-written signature.
      </li>
      <li>Ensure your employer(s) complete the second page of the employment certification(s).</li>
      <ul>
        <li>
          Questions 1 through 9 <strong>must</strong> be completed. Please read the answer to
          question 9 to determine if succeeding questions require an answer.
        </li>
      </ul>
      <li>
        Please verify your Social Security Number is present on the first two pages of your
        employment certification(s) prior to submitting it.
      </li>
    </ul>

    <strong>Step 2 – Submit your employment certification(s) to MOHELA</strong>
    <ul>
      <li>
        Upload the signed form to{' '}
        <a href="https://www.mohela.com" target="_blank" rel="noopener noreferrer">
          MOHELA
        </a>{' '}
        if you have an account.
        <ul>
          <li>Select "File Upload" on the top right</li>
          <li>Select "Public Service Loan Forgiveness"</li>
          <li>Select "Employment Certification Form (PSLF)"</li>
        </ul>
      </li>
      Or
      <li>Fax your application to MOHELA at 866.222.7060 if you do not have an account.</li>
    </ul>
  </>
);

const RenewalEmpCertComplete = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/dashboard')}
    text={<TodoText />}
    title="Complete and submit your Employment Certification(s)"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewalEmpCertComplete);
