import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';

import {
  AsyncLoader,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  CardFooter,
  Lede,
} from 'fiducius-ui';

import { debounce, mergeRequestStatuses, safeAccess } from '../../utils';
import { todosLoadCollection } from '../../todos';
import { withPermissions } from '../../routing';
import { getUserId } from '../../auth';

import EmploymentHistoryForm from '../components/employment-history-form';
import EmploymentHistoryTable from '../components/employment-history-table';
import {
  employmentHistoryClearForm,
  employmentHistoryCopyResourceToForm,
  employmentDeleteResource,
  employmentUpdateResource,
  employmentHistoryHandleFormChange,
  employmentCreateResource,
  employmentHistoryLoadCollection,
} from '../redux/operations';

const StyledButton = styled(Button)`
  margin-right: 1em;
  margin-top: 1em;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const PaddedBadge = styled(Badge)`
  margin-right: 0.45em;
  font-size: 0.75em;
  top: -0.75rem;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.5em;
`;

const StyledLede = styled(Lede)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textPrimary : 'inherit')};
`;

const AlignTextLeft = styled.div`
  text-align: left;
`;

class Employers extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    employers: t.object,
    actionRequest: t.object,
    requests: t.object,
    clearForm: t.func.isRequired,
    createEmployer: t.func.isRequired,
    checkQualification: t.func.isRequired,
    deleteEmployer: t.func.isRequired,
    errors: t.object,
    form: t.object,
    handleForm: t.func.isRequired,
    hydrateForm: t.func.isRequired,
    reload: t.func.isRequired,
    reloadWithTodos: t.func.isRequired,
    request: t.object,
    updateEmployer: t.func.isRequired,
    permissions: t.object,
    toDos: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      formId: null,
      showForm: false,
      action: '',
      openPartTimeEmpModal: false,
    };
    this.handlePartTimeEmpModal = this.handlePartTimeEmpModal.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const action = prevState.action;
    const createLoading = prevProps.requests.createResource.isLoading;
    const updateLoading = prevProps.requests.updateResource.isLoading;
    const deleteLoading = prevProps.requests.deleteResource.isLoading;

    const create = this.props.requests.createResource;
    const update = this.props.requests.updateResource;
    const deleteR = this.props.requests.deleteResource;

    let processCloseForm = false;
    switch (action) {
      case 'Add':
        processCloseForm = this.checkCloseForm(createLoading, create);

        break;
      case 'Update':
        processCloseForm = this.checkCloseForm(updateLoading, update);
        break;
      case 'Delete':
        processCloseForm = this.checkCloseForm(deleteLoading, deleteR);
        break;
      default:
        break;
    }

    if (processCloseForm) {
      this.setState({ ...this.state, showForm: false, action: '' }, () =>
        this.props.reloadWithTodos()
      );
    }
  }

  checkCloseForm = (prevRequestLoading, currentRequest) => {
    return prevRequestLoading && !currentRequest.isLoading && !currentRequest.hasFailed;
  };

  closeForm = () => {
    this.props.clearForm();
    this.setState({ ...this.state, showForm: false });
  };

  onAddEmployer = () => this.setState({ formId: null, showForm: true, action: 'Add' });

  onEditEmployer = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true, action: 'Update' });
  };

  onDeleteEmployer = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true, action: 'Delete' });
  };

  onChange = debounce((employer, id, attributes) => {
    if (attributes === 'endDate' && employer.endDate === '') {
      employer.endDate = null;
    }
    this.props.handleForm({ ...this.props.form, ...employer }, [attributes]);
  }, 250);

  onCreate = () => this.props.createEmployer();

  onUpdateOrDelete = () => {
    if (this.state.action === 'Update') {
      this.props.updateEmployer(this.props.form.id);
    } else if (this.state.action === 'Delete') {
      this.props.deleteEmployer(this.props.form.id);
    }
  };

  getIconForAdd() {
    const { toDos } = this.props;
    /*if (
      Object.keys(toDos).includes('403') ||
      Object.keys(toDos).includes('13') ||
      Object.keys(toDos).includes('502')
    ) {
      return (
        <PaddedBadge brand="primary">
          <FontAwesomeIcon icon={faSolidExclamation} />
        </PaddedBadge>
      );
    } else {*/
    return <PaddedIcon icon={faPlusCircle} fixedWidth />;
    //}
  }

  handlePartTimeEmpModal() {
    this.setState({ ...this.state, openPartTimeEmpModal: !this.state.openPartTimeEmpModal });
  }

  partTimeEmpModalContent() {
    return (
      <Modal isOpen={this.state.openPartTimeEmpModal} fullWidth={true}>
        <Card>
          <CardBody>
            <CardText>
              <CardTitle>Part-time Employment</CardTitle>
              <AlignTextLeft>
                <FlexRow>
                  <StyledLede>
                    You can provide part-time employment if you worked at more than one employer
                    during the same period and worked an average of at least 30 hours per week, per
                    PSLF requirements.
                  </StyledLede>
                </FlexRow>
              </AlignTextLeft>
            </CardText>
          </CardBody>
          <CardFooter>
            <Button brand="lowContrast" onClick={this.handlePartTimeEmpModal}>
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
    );
  }

  render() {
    const {
      employers,
      actionRequest,
      errors,
      form,
      request,
      checkQualification,
      id,
      permissions,
    } = this.props;
    const { formId, showForm } = this.state;
    const forReducedIDR =
      permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null;
    const showEdit =
      forReducedIDR ||
      ['Questionnaire', 'Agreement', 'NoQpComplete'].includes(
        permissions.lwoSteps.find((a) => a.stepCurrent).id
      )
        ? true
        : false;
    const showDelete =
      forReducedIDR ||
      ['Questionnaire', 'Agreement', 'NoQpComplete'].includes(
        permissions.lwoSteps.find((a) => a.stepCurrent).id
      )
        ? true
        : false;
    return (
      <>
        <div className="row">
          <div className="col">
            {showEdit && (
              <StyledButton brand="primary" onClick={this.onAddEmployer}>
                {this.getIconForAdd()}Add Employer
              </StyledButton>
            )}
            {!forReducedIDR && (
              <StyledButton brand="primary" onClick={this.handlePartTimeEmpModal}>
                <PaddedIcon icon={faQuestionCircle} fixedWidth />
                Part-time Employment
              </StyledButton>
            )}
          </div>
        </div>

        <AsyncLoader request={request} emptyMessage={'You have no Employers on file.'}>
          {employers && (
            <>
              <EmploymentHistoryTable
                employers={employers}
                onEditEmployer={this.onEditEmployer}
                onDeleteEmployer={this.onDeleteEmployer}
                showDelete={showDelete}
                showEdit={showEdit}
              />
              {Object.keys(employers).length == 0 && <p> There aren't any employers </p>}
              {showEdit && !forReducedIDR && (
                <div className="row">
                  <div className="col-12">
                    <StyledButton
                      brand="primary"
                      onClick={() => {
                        checkQualification(id);
                      }}
                    >
                      Calculate My Results
                    </StyledButton>
                  </div>
                </div>
              )}
            </>
          )}
        </AsyncLoader>
        {this.partTimeEmpModalContent()}
        {showForm && (
          <EmploymentHistoryForm
            action={formId ? this.state.action : 'Create'}
            closeForm={this.closeForm}
            data={form}
            errors={errors}
            isLoading={actionRequest.isLoading}
            isOpen={showForm}
            onChange={this.onChange}
            onSubmit={formId ? this.onUpdateOrDelete : this.onCreate}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.employmentHistory;

  return {
    id: getUserId(state),
    employers: cache,
    actionRequest: mergeRequestStatuses([
      requests.createResource,
      requests.updateResource,
      requests.deleteResource,
    ]),
    errors: errors,
    form: form,
    request: mergeRequestStatuses([requests.loadCollection, requests.updateResource]),
    requests: requests,
    toDos: safeAccess(state, 'todos.cache', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearForm: () => dispatch(employmentHistoryClearForm()),
  createEmployer: async () => {
    await dispatch(employmentCreateResource());
  },
  reload: () => dispatch(employmentHistoryLoadCollection()),
  reloadWithTodos: () => {
    dispatch(employmentHistoryClearForm());
    dispatch(employmentHistoryLoadCollection());
    dispatch(todosLoadCollection());
  },
  handleForm: (employer, attributes) =>
    dispatch(employmentHistoryHandleFormChange(employer, attributes)),
  hydrateForm: async (id) => await dispatch(employmentHistoryCopyResourceToForm(id)),
  updateEmployer: async (id) => {
    await dispatch(employmentUpdateResource(id));
  },
  deleteEmployer: async (id) => {
    await dispatch(employmentDeleteResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Employers));
