import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { AsyncLoader, Card, CardBody, CardText, CardFooter, Button, Loader } from 'fiducius-ui';
import { FullscreenContainer, ContrastDisplay, Fade } from '../../routing';
import { FlexBetween } from '../../root';
import { authGetRedirectPath, getToken, getUserId } from '../../auth';
import { safeAccess, debounce } from '../../utils';
import {
  securityQuestionsClearForm,
  securityQuestionsCopyResourceToForm,
  securityQuestionsHandleFormChange,
  securityQuestionsLoadResource,
  securityQuestionsUpdateResource,
} from '../redux/operations';
import SecurityQuestionForm from '../components/security-question-form';

class SecurityQuestionsCheck extends React.Component {
  static propTypes = {
    getRedirectPath: t.string,
    id: t.string.isRequired,
    token: t.any,
    data: t.object,
    form: t.object,
    errors: t.object,
    request: t.object,
    responseRequest: t.object,
    onLoad: t.func,
    clearForm: t.func,
    checkResponse: t.func,
    handleForm: t.func,
    hydrateForm: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      shouldLogout: false,
      shouldRedirect: false,
      securityQuestionAttemptCount: 0,
      securityQuestion: null,
    };
  }

  async componentDidMount() {
    this.props.clearForm();
    await this.props.onLoad(this.props.id);
    this.props.handleForm({ ...this.props.form, ...this.props.data }, ['securityChecksum']);
  }

  onChange = debounce((securityQuestions, id, attributes) => {
    this.props.handleForm({ ...this.props.form, ...securityQuestions }, [attributes]);
  }, 250);

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    return true;
  };

  checkNoErrors = () => {
    return true;
  };

  handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      this.submit(e);
    }
  };

  submit = async (e) => {
    e.preventDefault();
    const { shouldRedirect, securityQuestionAttemptCount, securityQuestion } = this.state;
    this.setState({
      ...this.state,
      securityQuestionAttemptCount: securityQuestionAttemptCount + 1,
      securityQuestion: this.props.data.securityQuestion,
    });

    if (!this.props.request.isLoading && !this.props.responseRequest.isLoading) {
      await this.props.checkResponse(this.props.id);
      if (
        this.props.responseRequest.hasFinished &&
        !this.props.responseRequest.hasFailed &&
        this.props.data.securityChecksum !== 'LOCKED'
      ) {
        this.props.clearForm();
        this.setState({ ...this.state, shouldRedirect: true });
      } else if (this.props.data.securityChecksum === 'LOCKED') {
        this.props.clearForm();
        this.setState({ ...this.state, shouldLogout: true });
      } else if (this.state.securityQuestionAttemptCount === 3) {
        do {
          await this.props.onLoad(this.props.id);
          this.props.handleForm({ ...this.props.form, ...this.props.data }, ['securityChecksum']);
        } while (securityQuestion === this.props.data.securityQuestion);
      }
    }
  };

  render() {
    const { request, data, form, errors, responseRequest, getRedirectPath } = this.props;

    if (this.state.shouldLogout) {
      return <Redirect to={'/logout'} />;
    } else if (this.state.shouldRedirect) {
      return <Redirect to={getRedirectPath === 'referrer' ? '/dashboard' : getRedirectPath} />;
    }

    return (
      <FullscreenContainer className="container">
        <div className="row no-gutters">
          <div className="col-12">
            <ContrastDisplay>Security Question Check</ContrastDisplay>
            <AsyncLoader request={request}>
              <Card>
                <CardBody>
                  <CardText>
                    <p>Please answer the following question to continue to your dashboard:</p>
                    <SecurityQuestionForm
                      data={data}
                      form={form}
                      errors={errors}
                      onChange={this.onChange}
                      handleKeyPress={this.handleKeyPress}
                    />
                  </CardText>
                </CardBody>
                <CardFooter>
                  <FlexBetween>
                    <Fade show={this.enableSubmit()}>
                      <Button brand="success" onClick={(e) => this.submit(e)}>
                        {responseRequest.isLoading ? (
                          <Loader variant="push" size={1.5} />
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </Fade>
                    <Fade show={!responseRequest.isLoading && !this.checkNoMissingData()}>
                      Please enter the missing information above.
                    </Fade>
                    <Fade
                      show={
                        !responseRequest.isLoading &&
                        this.checkNoMissingData() &&
                        !this.checkNoErrors()
                      }
                    >
                      Please fix the errors above.
                    </Fade>
                  </FlexBetween>
                </CardFooter>
              </Card>
            </AsyncLoader>
          </div>
        </div>
      </FullscreenContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.securityQuestions;
  let id = getUserId(state);
  return {
    id: id,
    token: getToken(state),
    getRedirectPath: authGetRedirectPath(state, true, true),
    request: requests.loadResource,
    responseRequest: requests.updateResource,
    form: form,
    errors: errors,
    data: safeAccess(cache, '[' + id + ']') || {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: async (id) => {
    await dispatch(securityQuestionsLoadResource(id));
  },
  clearForm: () => dispatch(securityQuestionsClearForm()),
  checkResponse: async (id) => {
    await dispatch(securityQuestionsUpdateResource(id));
  },
  handleForm: (securityQuestions, attributes) =>
    dispatch(securityQuestionsHandleFormChange(securityQuestions, attributes)),
  hydrateForm: async (id) => await dispatch(securityQuestionsCopyResourceToForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SecurityQuestionsCheck));
