import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { InputWrapper } from 'fiducius-ui';

const Styling = styled.div`
  padding-left: ${(p) => (p.first ? '0' : '0.5rem')};
  padding-right: ${(p) => (p.last ? '0' : '0.5rem')};
`;

const InputWrapperSpaced = ({ first, last, children, ...props }) => (
  <Styling first={first} last={last}>
    <InputWrapper {...props}>{children}</InputWrapper>
  </Styling>
);

InputWrapperSpaced.propTypes = {
  children: t.oneOfType([t.node, t.arrayOf(t.node)]),
};

export default InputWrapperSpaced;
