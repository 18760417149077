import React from 'react';
import t from 'prop-types';
import { Alert } from 'fiducius-ui';
import { connect } from 'react-redux';

import { safeAccess } from '../../utils';

class ContactResult extends React.Component {
  static propTypes = {
    getPaymentStatus: t.string,
    request: t.object,
    status: t.string,
  };

  componentDidMount() {}

  getMessage = () => {
    switch (this.props.status) {
      case 'success':
        return 'Thank you for your submission, due to increased seasonal correspondence your reply may be delayed. We appreciate your patience.';
      //If your question is urgent, please contact us at 1-513-645-5400. Our business hours are M-Th 8am-6pm and Friday 8am-5pm EST.';
      //return 'Thank you for your question. We will respond to you within 3-5 business days.';
      default:
        return 'There was an error submitting your question. Please email us directly at customer.service@GetFiducius.com.';
      //Please call us at 513-645-5400.';
    }
  };

  render() {
    return (
      <Alert brand={this.props.status === 'success' ? 'success' : 'danger'}>
        {this.getMessage()}
      </Alert>
    );
  }
}

const mapStateToProps = (state) => ({
  status: safeAccess(state, 'contact.cache.contactStatus', ''),
  request: safeAccess(state, 'contact.requests.updateResource'),
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentStatus: 'success',
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactResult);
