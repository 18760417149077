import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { Card, CardBody, CardTitle, CardText, Header4 } from 'fiducius-ui';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';

class TuitionInstitutionCurrentClassRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    termId: t.number.isRequired,
    classId: t.number.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  setGradeTextValue = (grade, degreeType) => {
    if (degreeType !== 'CERT') {
      if (grade === 'AC') {
        grade = 'Credit';
      } else if (grade === 'FW') {
        grade = 'Withdrawal';
      } else if (grade === 'FI') {
        grade = 'Incomplete';
      }
    } else {
      if (grade === 'A') {
        grade = 'Pass';
      } else if (grade === 'F') {
        grade = 'Fail';
      }
    }
    return grade;
  };

  render() {
    const { data, institutionId, termId, classId } = this.props;
    const institution = data[institutionId];
    const term = data[institutionId].terms[termId];
    const thisClass = data[institutionId].terms[termId].classes[classId];

    thisClass.grade = this.setGradeTextValue(thisClass.grade, institution.degreeType);

    return (
      <Card>
        <CardBody>
          <CardTitle>
            {thisClass.className}
            <br />
            <Header4 className="offset-1">
              {institution.institutionName + ' - ' + term.termName}
            </Header4>
          </CardTitle>
          <CardText>
            <dl>
              <dt>Grade</dt>
              <dd>{thisClass.grade}</dd>
              <dt>Hours</dt>
              <dd>{thisClass.hours}</dd>
              <dt>Current Status</dt>
              <dd>{thisClass.currentStatus}</dd>
            </dl>
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (state) => {};

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionCurrentClassRow);
