import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardText, CardTitle } from 'fiducius-ui';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const OptimalSituationSummary = withPermissions(({}) => (
  <div className="row">
    <div className="col-12 col-lg-12">
      <Card brand="primary">
        <CardBody>
          <CardTitle>Optimal Situation</CardTitle>
          <CardText>
            <Spaced>
              Based on the information you provided, you have managed your loan situation
              masterfully. At Fiducius, we are always looking to find new ways to assist our
              clients. Please visit your account frequently for new updates!
            </Spaced>
          </CardText>
        </CardBody>
      </Card>
    </div>
  </div>
));

OptimalSituationSummary.propTypes = {};

const mapStateToProps = (state) => {
  const id = getUserId(state);
  return {};
};

export default connect(mapStateToProps)(withPermissions(OptimalSituationSummary));
