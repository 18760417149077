import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';

import { PaddedBadge } from '../../../routing';
import { Redirect } from 'react-router-dom';

import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardTitle,
  DashboardSection,
  Header4,
  Lede,
  Modal,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import { getTuitionInstitutionData } from '../redux/selectors';

import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import { TuitionInstitutionClassRow } from '../../../tuition-institution-class';

import TuitionStatusInfo from '../components/tuition-status-info';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const StyledButton = styled(Button)`
  margin: 0.5em;
`;

class MyClasses extends React.Component {
  static propTypes = {
    data: t.object,
    loadInstitution: t.func.isRequired,
    permissions: t.object,
    request: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', openStatusModal: false, hasMissingInfo: false };
    this.handleStatusModal = this.handleStatusModal.bind(this);
  }

  componentDidMount() {
    this.props.loadInstitution();
  }

  handleStatusModal() {
    this.setState({ openStatusModal: !this.state.openStatusModal });
  }

  noClassesOnFile() {
    return (
      <FlexRow>
        <dl>
          <dd>
            <Lede>You have not added any classes for this term.</Lede>
          </dd>
          <dd>
            <Button
              brand="primary"
              onClick={() => {
                this.addClass();
              }}
            >
              <PaddedIcon icon={faPlusCircle} fixedWidth />
              Add New Class
            </Button>
          </dd>
        </dl>
      </FlexRow>
    );
  }

  noTermsOnFile() {
    return (
      <Card>
        <CardBody>
          <FlexRow>
            <dl>
              <dd>
                <Lede>A term must be added before a class can be added.</Lede>
              </dd>
              <dd>
                <Button
                  brand="primary"
                  onClick={() => {
                    this.addClass();
                  }}
                >
                  <PaddedIcon icon={faPlusCircle} fixedWidth />
                  Add New Term
                </Button>
              </dd>
            </dl>
          </FlexRow>
        </CardBody>
      </Card>
    );
  }

  noInstitutionsOnFile() {
    return (
      <Card>
        <CardBody>
          <FlexRow>
            <dl>
              <dd>
                <Lede>An institution and term must be added before a class can be added.</Lede>
              </dd>
              <dd>
                <Button
                  brand="primary"
                  onClick={() => {
                    this.addClass();
                  }}
                >
                  <PaddedIcon icon={faPlusCircle} fixedWidth />
                  Add New Institution
                </Button>
              </dd>
            </dl>
          </FlexRow>
        </CardBody>
      </Card>
    );
  }

  addClass() {
    this.setState({ ...this.state, redirectURI: '/tuition/new-class' });
  }

  missingInfo = (data) => {
    this.setState({ ...this.state, hasMissingInfo: data });
  };

  render() {
    const { data = {}, request } = this.props;
    const terms = [];
    let classes = [];
    let currentTerm = false;
    let currentInstitution = false;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }

    return (
      <>
        <DashboardSection
          title={
            <>
              <div className="row">
                <div className="col-12">My Classes</div>
              </div>
              <div className="row">
                <div className="col-12">
                  <StyledButton
                    brand="primary"
                    onClick={() => {
                      this.addClass();
                    }}
                  >
                    <PaddedIcon icon={faPlusCircle} fixedWidth />
                    Add New Class
                  </StyledButton>
                  <StyledButton brand="primary" onClick={this.handleStatusModal}>
                    <PaddedIcon icon={faQuestionCircle} fixedWidth />
                    Class Status Info
                  </StyledButton>
                </div>
              </div>
            </>
          }
        >
          <div className="row">
            <div className="col">
              {this.state.hasMissingInfo && (
                <Lede>
                  <PaddedBadge brand="primary">
                    <FontAwesomeIcon icon={faSolidExclamation} />
                  </PaddedBadge>{' '}
                  - Indicates Missing Information
                </Lede>
              )}
            </div>
          </div>
          <AsyncLoader request={request} empty={this.noInstitutionsOnFile()}>
            {Object.keys(data).length > 0 &&
              Object.keys(data).forEach((institution) => {
                if (data[institution].current) {
                  currentInstitution = true;
                  Object.keys(data[institution].terms).forEach((term) => {
                    if (data[institution].terms[term].current) {
                      currentTerm = true;
                      classes = [];
                      terms.push(
                        <Card>
                          <CardBody>
                            <CardTitle>
                              <dl>
                                <dd>{data[institution].institutionName}</dd>
                                <dd>
                                  <Header4>{data[institution].terms[term].termName}</Header4>
                                </dd>
                              </dl>
                            </CardTitle>
                            {Object.keys(data[institution].terms[term].classes).forEach(
                              (curClass) => {
                                classes.push(
                                  <div className="col-6 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <TuitionInstitutionClassRow
                                      institutionId={institution}
                                      termId={term}
                                      classId={curClass}
                                      hasMissingInformation={this.missingInfo}
                                    />
                                  </div>
                                );
                              }
                            )}
                            {classes.length > 0 && <div className="row">{classes}</div>}
                            {classes.length === 0 && this.noClassesOnFile()}
                          </CardBody>
                        </Card>
                      );
                    }
                  });
                }
              })}
            {terms.length > 0 && terms}
            {terms.length === 0 && currentInstitution && !currentTerm && this.noTermsOnFile()}
            {terms.length === 0 && !currentInstitution && this.noInstitutionsOnFile()}
          </AsyncLoader>
        </DashboardSection>
        <Modal isOpen={this.state.openStatusModal} fullWidth>
          <TuitionStatusInfo data={data} closeModal={this.handleStatusModal} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyClasses));
