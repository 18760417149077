import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import {
  Table,
  Modal,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardText,
  Header3,
  Header4,
  Button,
} from 'fiducius-ui';

const PaddedDiv = styled.div`
  padding-bottom: 1em;
`;

const TopDiv = styled.div`
  margin-bottom: 1em;
`;

const BorderedDiv = styled.div`
  margin-top: 1em;
  border-bottom: calc(2 * ${(p) => p.theme.borderWidth}) solid ${(p) => p.theme.textTertiary};
`;

const StyledHeader4 = styled(Header4)`
  margin-bottom: 0em;
`;

class MilestoneHelp extends React.Component {
  static propTypes = {
    data: t.object,
    closeForm: t.func.isRequired,
    isOpen: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.modalRef = React.createRef();
    if (this.modalRef.current !== null) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  getAccruementBody() {
    const { data } = this.props;
    const accruementBody = [];

    if (Object.values(data).filter((a) => a.milestoneAccruementType === 'START').length > 0) {
      accruementBody.push([
        'Starting Amount',
        'Starting monthly contribution amount set by your employer',
      ]);
    }
    if (Object.values(data).filter((a) => a.milestoneAccruementType === 'NUMPMTS').length > 0) {
      accruementBody.push([
        'Number of Payments',
        'Number of contribution payments needed to reach a milestone',
      ]);
    }
    if (Object.values(data).filter((a) => a.milestoneAccruementType === 'CALCPMTS').length > 0) {
      accruementBody.push([
        'Number of Calculated Payments',
        'Number of pre-calculated contribution payments needed to reach a milestone',
      ]);
    }
    if (Object.values(data).filter((a) => a.milestoneAccruementType === 'MONTHS').length > 0) {
      accruementBody.push([
        'Months',
        'Number of months participating in contribution needed to reach a milestone',
      ]);
    }

    return accruementBody;
  }

  getPaymentBody() {
    const { data } = this.props;
    const paymentBody = [];

    if (Object.values(data).filter((a) => a.milestonePaymentType === 'SETAMT').length > 0) {
      paymentBody.push(['Set Amount', 'Contribution payment amount set by your employer']);
    }
    if (Object.values(data).filter((a) => a.milestonePaymentType === 'MODAMT').length > 0) {
      paymentBody.push([
        'Modify Amount',
        'Additional contribution amount applied to your previous milestone',
      ]);
    }
    if (Object.values(data).filter((a) => a.milestonePaymentType === 'LUMP').length > 0) {
      paymentBody.push(['Lump Sum', 'Lump sum contribution payment amount set by your employer']);
    }

    return paymentBody;
  }

  render() {
    const { closeForm, isOpen, data } = this.props;

    let accruementHeaders = ['Accruement Type', 'Description'];
    let paymentHeaders = ['Payment Type', 'Description'];

    //This check has to be here because the table shows up empty otherwise
    //The table element is taking the first available version of the data and not updating normally
    if (Object.values(data).length === 0) {
      return <></>;
    } else {
      return (
        <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
          <Card>
            <CardHeader>
              <Header3>What are Milestones?</Header3>
            </CardHeader>
            <CardBody>
              <CardText>
                <TopDiv className="row">
                  <div className="col-12">
                    A milestone is accrued over time and impacts your monthly employer contribution.
                    <BorderedDiv></BorderedDiv>
                  </div>
                </TopDiv>
                <PaddedDiv className="row">
                  <div className="col-12">
                    <StyledHeader4>Milestone Accruement Types</StyledHeader4>
                    <Table
                      columns={[{ width: '25%' }, { width: '75%' }]}
                      header={accruementHeaders}
                      body={this.getAccruementBody()}
                    />
                  </div>
                </PaddedDiv>
                <PaddedDiv className="row">
                  <div className="col-12">
                    <StyledHeader4>Milestone Payment Types</StyledHeader4>
                    <Table
                      columns={[{ width: '25%' }, { width: '75%' }]}
                      header={paymentHeaders}
                      body={this.getPaymentBody()}
                    />
                  </div>
                </PaddedDiv>
              </CardText>
            </CardBody>
            <CardFooter>
              <Button brand="lowContrast" onClick={closeForm}>
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      );
    }
  }
}

export default MilestoneHelp;
