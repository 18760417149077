import React from 'react';
import t from 'prop-types';
import { Button, Table } from 'fiducius-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';

import { convertIsoToSlash, startCase } from '../../utils';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

const EmploymentHistoryTable = ({
  employers = {},
  onEditEmployer,
  onDeleteEmployer,
  showEdit,
  showDelete,
}) => {
  if (Object.keys(employers).length === 0) {
    return null;
  }

  const deleteButtonPos = showEdit ? 7 : 6;
  const headers = ['id', 'employerName', 'startDate', 'endDate', 'employmentType', 'hours'];
  const displayHeaders = ['Employer', 'Start Date', 'End Date', 'FT/PT', 'Hours'];
  const columns = [
    {},
    { sortable: true },
    { sortable: true, renderer: convertIsoToSlash },
    { sortable: true, renderer: convertIsoToSlash },
    { sortable: true },
    { sortable: true },
  ];
  if (showEdit) {
    headers.push('edit');
    displayHeaders.push('Edit');
    columns.push({ width: '10%' });
  }
  if (showDelete) {
    headers.push('delete');
    displayHeaders.push('Delete');
    columns.push({ width: '10%' });
  }

  let body = Object.values(employers).map((a) => headers.map((h) => a[h]));

  body.forEach((row) => {
    const id = row[0];
    row[4] = row[4] === 'FULL' ? 'FT' : row[4] === 'PART' ? 'PT' : '';
    if (showEdit) {
      row[6] = (
        <Button invert brand="secondary" onClick={() => onEditEmployer(id)}>
          <FontAwesomeIcon icon={faEdit} fixedWidth />
        </Button>
      );
    }

    if (showDelete) {
      row[deleteButtonPos] = (
        <Button
          invert
          brand="danger"
          onClick={() => onDeleteEmployer(id)}
          disabled={!employers[id].deletable}
          title={
            !employers[id].deletable
              ? 'You are unable to delete an employer that you previously submitted an Employment Certification for.'
              : ''
          }
        >
          <FontAwesomeIcon icon={faTrash} fixedWidth />
        </Button>
      );
    }

    delete row[0];
  });

  return <Table body={body} columns={columns} header={displayHeaders} />;
};

EmploymentHistoryTable.propTypes = {
  employers: t.object,
  onEditEmployer: t.func,
  onDeleteEmployer: t.func,
  showEdit: t.bool,
  showDelete: t.bool,
};

export default EmploymentHistoryTable;
