import { animated, useSpring } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import t from 'prop-types';

const duration = 100;
const CENTER = 'translateX(0%)';
const RIGHT = 'translateX(100%)';
const LEFT = 'translateX(-100%)';

export const Fade = ({ children, reverse = false, show, id }) => {
  // return <div>{children}</div>;
  const [skip, setSkip] = useState(true);
  const out = reverse ? RIGHT : LEFT;
  const [styles, spring] = useSpring(() => ({
    config: { duration },
    opacity: show ? 1 : 0,
    transform: show ? CENTER : out,
  }));

  useEffect(() => {
    setSkip(false);
  }, []);

  useEffect(() => {
    if (skip) return;
    if (show) {
      spring.stop();
      spring.set({ opacity: 0, transform: out });
      spring.update({ opacity: 1, transform: CENTER });
      spring.start();
    } else {
      spring.stop();
      spring.set({ opacity: 1, transform: CENTER });
      spring.update({ opacity: 0, transform: out });
      spring.start();
    }
    // Need this to skip the first render.
    // All deps except `skip` should be present
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, spring, out]);

  return (
    <animated.div className="polaris-fader" style={styles}>
      {children}
    </animated.div>
  );
};

Fade.propTypes = {
  children: t.node.isRequired,
  id: t.string.isRequired,
  reverse: t.bool,
  show: t.bool.isRequired,
};
