import React from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, DashboardSection } from 'fiducius-ui';

import StepDisplay from '../components/step-display';

import { withPermissions } from '../../../routing';

import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';

class ForbearancePhaseTwo extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection title="Request General Forbearance - Phase 2">
          <div className="row">
            <div className="col-12">
              <Card>
                <CardBody>
                  <StepDisplay title={'Step 1 – Login to your account at MOHELA.'}>
                    <ul>
                      <li>
                        Request general forbearance for <strong>twelve months</strong>.
                      </li>
                      <li>
                        <strong>Please note:</strong> You may not use the entire length of your
                        request. Your forbearance request will end once the correct income-driven
                        repayment is complete in a future step.
                      </li>
                    </ul>
                  </StepDisplay>
                  <StepDisplay title={'Step 2 – Confirm you have requested forbearance.'}>
                    <ul>
                      <li>
                        I confirm I have submitted a forbearance request at MOHELA to place my
                        current loan payments on hold.
                      </li>
                      <li>
                        Forbearance requests typically take up to two weeks to process and any
                        payment made while in forbearance may not qualify.
                      </li>
                      <li>
                        <strong>Please note:</strong> If you have used more than 36 months of
                        general forbearance, your request may be denied by the loan servicer.
                      </li>
                    </ul>
                  </StepDisplay>
                  <StandardConfirmation stepId={109} />
                </CardBody>
              </Card>
            </div>
          </div>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ForbearancePhaseTwo));
