import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Fade, withPermissions } from '../../../routing';
import { todosLoadCollection } from '../../../todos';

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Lede,
  Loader,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Modal,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { debounce, safeAccess, stringIsNullOrEmpty } from '../../../utils';

import { getTuitionData } from '../redux/selectors';
import { tuitionHandleFormChange, tuitionUpdateResource } from '../redux/operations';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TextLeft = styled.div`
  text-align: left;
`;

class ApproverEmail extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    permissions: t.object,
    updateFormState: t.func.isRequired,
    saveData: t.func.isRequired,
    id: t.string.isRequired,
    request: t.object.isRequired,
    updateRequest: t.object.isRequired,
    isOpen: t.bool,
    closeForm: t.func.isRequired,
    form: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.modalRef = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const prevLoading = prevProps.updateRequest.isLoading;
    const current = this.props.updateRequest;

    if (prevLoading && !current.isLoading && !current.hasFailed) {
      this.props.closeForm();
    }
  }

  handleChange = debounce((formState) => {
    const { data, updateFormState } = this.props;
    const newData = { ...data, ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 200);

  save = () => {
    this.props.saveData(this.props.id);
  };

  render() {
    const { data = {}, errors = {}, updateRequest, isOpen, closeForm, form = {} } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen}>
        <Card>
          <CardBody>
            <CardTitle>Company Information</CardTitle>
            <Form id="tuitionCompany" handleChange={this.handleChange}>
              <TextLeft>
                <InputWrapper error={!!errors.approverEmail}>
                  <InputLabel htmlFor="approverEmail" required>
                    Manager Email
                  </InputLabel>
                  <InputError>{safeAccess(errors, 'approverEmail.detail')}</InputError>
                  <InputGroup>
                    <InputText name="approverEmail" defaultValue={data.approverEmail} />
                  </InputGroup>
                </InputWrapper>
              </TextLeft>
            </Form>
            <FlexRow>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Lede>
                <Fade
                  show={
                    !stringIsNullOrEmpty(form.approverEmail) &&
                    (!errors.approverEmail || errors.approverEmail.detail === undefined)
                  }
                >
                  <Button brand="primary" onClick={this.save}>
                    {updateRequest.isLoading ? <Loader variant="push" size={1.5} /> : 'Save'}
                  </Button>
                </Fade>
                <Fade show={stringIsNullOrEmpty(form.approverEmail)}>
                  Please enter the missing information above.
                </Fade>
                <Fade show={!stringIsNullOrEmpty(form.approverEmail) && errors.approverEmail}>
                  Please fix the errors above.
                </Fade>
              </Lede>
            </FlexRow>
          </CardBody>
        </Card>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: { ...state.tuition.form, ...getTuitionData(state) },
    form: state.tuition.form,
    id: getUserId(state),
    errors: state.tuition.errors,
    request: state.tuition.requests.loadResource,
    updateRequest: state.tuition.requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFormState: (data, attributes) => dispatch(tuitionHandleFormChange(data, attributes)),
  saveData: async (id) => {
    await dispatch(tuitionUpdateResource(id));
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ApproverEmail));
