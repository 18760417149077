import { safeAccess } from '../../utils';

export const hasAnyTodos = (state) => {
  const todos = safeAccess(state, 'todos.cache');
  if (todos && Object.keys(todos).length > 0) {
    return true;
  }
  return false;
};

export const hasTodo = (state, ids) => {
  let exists = false;
  let todos = safeAccess(state, 'todos.cache');
  if (todos) {
    todos = Object.keys(todos);
  } else {
    return exists;
  }

  for (let i = 0; i < ids.length; i++) {
    if (todos.includes(ids[i].toString())) {
      exists = true;
      break;
    }
  }

  return exists;
};
