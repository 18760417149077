import React, { useState } from 'react';
import { connect } from 'react-redux';
import { InputWrapper, Button, Card, CardFooter, Modal } from 'fiducius-ui';

import { FlexEnd } from '../../root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as faXIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { MessageDiv } from '../../messages';
import { legalUpdateResource } from '../../legal/redux/operations';
import { loadMessage } from '../../messages/redux/operations';

const SUBSCRIPTION_VIEWED_KEY = 'SUBSC';

const SubscriptionHighlightModal = ({ markVideoWatched }) => {
  const [openSubscriptionModal, setOpenSubscriptionModel] = useState(false);
  const [subscriptionModalMessage, setSubscriptionModalMessage] = useState(true);

  const showModal = () => {
    setOpenSubscriptionModel(true);
  };

  const closeModal = () => {
    var avatarFrame = document.getElementById('avatarFrame_Subscription_Highlight');
    if (avatarFrame) {
      avatarFrame.setAttribute('src', '');
    }
    setOpenSubscriptionModel(false);
    setSubscriptionModalMessage(false);
    markVideoWatched();
  };

  return (
    <Modal isOpen={openSubscriptionModal} fullWidth theme={'position:fixed;'}>
      <Card>
        <FlexEnd>
          <InputWrapper>
            <Button brand="lowContrast" onClick={closeModal}>
              <FontAwesomeIcon icon={faXIcon} />
            </Button>
          </InputWrapper>
        </FlexEnd>

        <div id="messageSection">
          <MessageDiv
            messageType={'Subscription_Highlight'}
            showModal={showModal}
            modalMessage={subscriptionModalMessage}
          />
        </div>
        <CardFooter>
          <Button brand="lowContrast" onClick={closeModal}>
            Close
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

SubscriptionHighlightModal.propTypes = {};

const mapStateToProps = (state) => ({
  request: state.legal.requests.updateResource,
});

const mapDispatchToProps = (dispatch) => ({
  markVideoWatched: async () => {
    await dispatch(legalUpdateResource(SUBSCRIPTION_VIEWED_KEY));
    await dispatch(loadMessage('Subscription_Highlight'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionHighlightModal);
