import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Alert,
  AsyncLoader,
  Button,
  Card,
  CardFooter,
  Header4,
  Header5,
  Header6,
  InputRadio,
  InputWrapper,
  Modal,
} from 'fiducius-ui';

import { withPermissions } from '../../routing';
import { debounce, isEqual, mergeRequestStatuses, safeAccess, formatAsMoney } from '../../utils';

import BillingForm from './billing-form';
import Installment from './installment';
import {
  paymentCopyResourceToForm,
  paymentHandleFormChange,
  paymentLoadInfo,
  paymentTypes,
} from '../redux/operations';
import { getPaymentInfo } from '../redux/selectors';
import {
  getForgivenessData,
  getPastYearSavings,
  getCurrentYearSavings,
  getSAVEPlanSavings,
} from '../../benefits/forgiveness/redux/selectors';
import { forgivenessLoadResource } from '../../benefits/forgiveness/redux/operations';
import { getUserId } from '../../auth';

// TODO: after working with this class a lot, I think the real solution to all of the payment
// checking, moving, adding amount payments, renewal credits, etc. would be to just have all of the
// payments that need to be done or even shown (credits/everything) come back in the payments list
// from the API.

const InstallmentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 2rem;
  & > * {
    margin-right: 1rem;
  }
`;

const SubscriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  & > * {
    margin-right: 1rem;
  }
`;

const SubscriptionContentWrapper = styled.div`
  padding-bottom: 0.5rem;
`;

const StyledDivLeft = styled.div`
  text-align: left;
`;

const MessagePaddedDiv = styled.div`
  padding: 5px;
  margin: 5px;
  color: ${(p) => p.theme.textPrimary};
`;

class Billing extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    loadForgiveness: t.func.isRequired,
    pastYearSavings: t.object,
    currentYearSavings: t.object,
    saveProgramSavings: t.object,
    forgivenessData: t.object,
    data: t.object,
    errors: t.object,
    form: t.object,
    getInfo: t.func.isRequired,
    paymentType: t.string,
    permissions: t.object,
    request: t.object,
    updateForm: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      paymentOption: 'full',
      showSubscriptionModal: false,
    };
  }

  componentDidMount() {
    this.props.getInfo().then(() => {
      this.props.data
        ? this.setState({ paymentOption: this.props.data.paymentOption })
        : this.setState({ paymentOption: this.state.paymentOption });

      this.props.data
        ? this.props.data.paymentMode === 'both' || this.props.data.paymentMode === 'subscription'
          ? this.setState({ showSubscriptionModal: true })
          : this.setState({ showSubscriptionModal: false })
        : this.setState({ showSubscriptionModal: false });
      window.scrollTo(0, 0);
    });
    const { id, loadForgiveness, forgivenessData, permissions } = this.props;
    if (permissions.hasForgiveness && Object.keys(forgivenessData).length === 0) {
      loadForgiveness(id);
    }
  }

  handleChange = debounce((formState, id, attributes) => {
    this.props.updateForm({ ...this.props.form, ...formState }, [attributes]);
  }, 250);

  handleChangePaymentOption = (e) => {
    this.setState({ paymentOption: e.target.value });
    this.props.updateForm({ ...this.props.form, paymentOption: e.target.value }, ['paymentOption']);
  };

  nth = (n) => {
    return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
  };

  getCurrentDayNumber = () => {
    const d = new Date().getDate();
    return d + this.nth(d);
  };

  render() {
    const {
      data = {},
      form = {},
      errors = {},
      pastYearSavings = {},
      currentYearSavings = {},
      saveProgramSavings = {},
      forgivenessData = {},
      permissions,
      request,
      paymentType,
    } = this.props;
    const info = safeAccess(data, 'paymentInfo');
    const payments = safeAccess(info, 'payments', []);
    const payInFull = safeAccess(info, 'paymentTerm') === 'FULL';

    this.state.showSubscriptionModal
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = '');

    // Add amount field as today's payment if not PayNow
    if (!payInFull) {
      // Required because of multiple render passes
      if (!isEqual(payments, {}) && safeAccess(payments, '[0].index', null) !== -1) {
        let title = null;
        switch (paymentType) {
          case paymentTypes.DEPOSIT:
            title = 'Enrollment Fee';
            break;
          case paymentTypes.RENEWAL:
            title = 'Renewal Fee';
            break;
          case paymentTypes.LWO:
            title = <p>Assure&#8480; Fee</p>;
            break;
          default:
            title = null;
            break;
        }

        payments.unshift({
          amount: safeAccess(info, 'amount', 0),
          dueDate: new Date().toISOString(),
          index: -1,
          onlyPayment: false,
          title: title,
        });
      }
    }

    const showSavingsText = pastYearSavings > 0 && currentYearSavings > 0;
    const showSAVESavingsText = saveProgramSavings > 0;
    const showIncreaseText = forgivenessData.islsIdrPayment > 0;
    let savingsAmountText =
      'We anticipate you could save approximately ' +
      formatAsMoney(saveProgramSavings) +
      ' under the new plan.';

    return (
      <>
        {safeAccess(errors, 'generalErrors') && (
          <Alert brand="warning">{errors.generalErrors[0].detail}</Alert>
        )}
        <AsyncLoader request={request}>
          {paymentType === paymentTypes.RENEWAL &&
            permissions.hasForgiveness &&
            showSavingsText &&
            !showSAVESavingsText && (
              <Header5>
                <table>
                  <tr>
                    <td>Past Year Estimated Savings:</td>
                    <td>&nbsp;{formatAsMoney(pastYearSavings)}</td>
                  </tr>
                  <tr>
                    <td>Current Year Estimated Savings:</td>
                    <td>&nbsp;{formatAsMoney(currentYearSavings)}</td>
                  </tr>
                </table>
              </Header5>
            )}
          {paymentType === paymentTypes.RENEWAL &&
            permissions.hasForgiveness &&
            showSavingsText &&
            showSAVESavingsText && (
              <>
                <Header5>
                  <table>
                    <tr>
                      <td>Past Year Estimated Savings:</td>
                      <td>&nbsp;{formatAsMoney(pastYearSavings)}</td>
                    </tr>
                    <tr>
                      <td>Current Year Estimated Savings:</td>
                      <td>&nbsp;{formatAsMoney(currentYearSavings)}</td>
                    </tr>
                  </table>
                </Header5>
                <Header5>
                  <table>
                    <tr>
                      <td colspan="2">
                        With the recent announcement of the SAVE Repayment plan, your savings could
                        increase! {showIncreaseText && savingsAmountText}
                      </td>
                    </tr>
                  </table>
                </Header5>
              </>
            )}
          {paymentType === paymentTypes.DEPOSIT && <Header4>Installments</Header4>}
          <Header5>{safeAccess(info, 'paymentMessage', null)}</Header5>
          <Header6>
            <div dangerouslySetInnerHTML={{ __html: safeAccess(info, 'extraMessage', null) }}></div>
          </Header6>
          {data.paymentMode === 'both' &&
            paymentType === paymentTypes.RENEWAL &&
            payments &&
            payments.length > 1 && (
              <>
                <br />
                <Header4>Payment Option</Header4>
                <InputWrapper error={!!errors.paymentInstalls}>
                  <InputRadio
                    name="paymentOption"
                    value="full"
                    checked={this.state.paymentOption === 'full'}
                    onChange={this.handleChangePaymentOption}
                    label="Pay in Full"
                  />
                  <InputRadio
                    name="paymentOption"
                    value="subscription"
                    checked={this.state.paymentOption === 'subscription'}
                    onChange={this.handleChangePaymentOption}
                    label="Recurring Payment"
                  />
                </InputWrapper>
              </>
            )}
          {!isEqual(payments, {}) &&
            payments &&
            paymentType !== paymentTypes.NOW &&
            paymentType !== paymentTypes.RENEWAL && (
              <>
                <InstallmentWrapper>
                  {payments.map((p, i) => (
                    <Installment
                      {...p}
                      index={!payInFull ? i - 1 : i}
                      key={`installment-${i}`}
                      onlyPayment={payments.length === 1}
                    />
                  ))}
                </InstallmentWrapper>
              </>
            )}
          {!isEqual(payments, {}) &&
            payments &&
            paymentType === paymentTypes.RENEWAL &&
            this.state.paymentOption === 'subscription' && (
              <>
                <SubscriptionWrapper>
                  {payments.slice(1, 2).map((p, i) => (
                    <Installment {...p} index={i} key={`installment-${i}`} onlyPayment={true} />
                  ))}
                </SubscriptionWrapper>
                <SubscriptionContentWrapper>
                  <Header6>
                    This payment will be set to recurring on the {this.getCurrentDayNumber()} of the
                    month for the next 12 months.
                  </Header6>
                </SubscriptionContentWrapper>
              </>
            )}
          {!isEqual(payments, {}) &&
            payments &&
            paymentType === paymentTypes.RENEWAL &&
            !(this.state.paymentOption === 'subscription') && (
              <>
                <InstallmentWrapper>
                  {payments.slice(0, 1).map((p, i) => (
                    <Installment
                      {...p}
                      index={!payInFull ? i - 1 : i}
                      key={`installment-${i}`}
                      onlyPayment={payments.length === 1}
                    />
                  ))}
                </InstallmentWrapper>
              </>
            )}
          {paymentType === paymentTypes.NOW && (
            <>
              <Alert brand="info">
                Reminder: Payments made through this service are for Fiducius fees only. If you need
                to make a loan payment please follow the directions provided by your loan servicer.
              </Alert>
              {!isEqual(payments, {}) && payments && (
                <>
                  <InstallmentWrapper>
                    {payments.map((p, i) => (
                      <Installment
                        {...p}
                        index={!payInFull ? i - 1 : i}
                        key={`installment-${i}`}
                        onlyPayment={payments.length === 1}
                      />
                    ))}
                  </InstallmentWrapper>
                </>
              )}
            </>
          )}

          <Header4>Method of Payment</Header4>
          <InputRadio
            name="payMethod"
            value="credit"
            label={'Credit Card'}
            defaultChecked={true}
            disabled={true}
          />
          <br />
          <br />
          <Header4>Billing Information</Header4>
          <BillingForm data={form} errors={errors} handleChange={this.handleChange} />
        </AsyncLoader>
        <Modal isOpen={this.state.showSubscriptionModal} theme={'position:fixed;'} fullWidth={true}>
          <Card>
            <MessagePaddedDiv id="messageSection">
              <StyledDivLeft>
                <p>
                  Welcome to Fiducius PSLF Renewal software application We're thrilled to have you
                  on board. Please review the following subscription fee terms carefully:
                </p>
                <ol>
                  <li>
                    <b>Subscription Plan:</b> This software subscription is for one year, paid in 12
                    monthly installments. On the thirteenth month, this becomes a month-to-month
                    subscription.
                  </li>
                  <li>
                    <b>Billing Cycle:</b> Your subscription will be billed on a monthly basis.
                    Billing will occur when you accept the terms and will continue each month
                    thereafter for 12 months on the same day each month. Thereafter, it becomes a
                    month-to-month subscription.
                  </li>
                  <li>
                    <b>Payment Methods:</b> We accept credit card payment for your convenience.
                    Please ensure your payment method is valid and up to date to avoid any
                    disruptions in service.
                  </li>
                  <li>
                    <b>Price Changes:</b> We reserve the right to adjust subscription fees at any
                    time after your first twelve month installment. However, we will notify you in
                    advance of any changes to your subscription pricing. Your continued use of the
                    service after such notification constitutes acceptance of the new pricing terms.
                  </li>
                  <li>
                    <b>Trial Periods and Promotions:</b> From time to time, we may offer trial
                    periods or promotional discounts on subscription plans. These offers are subject
                    to specific terms and conditions, and may automatically convert to a paid
                    subscription at the end of the trial period unless cancelled.
                  </li>
                  <li>
                    <b>Renewals and Cancellations:</b> Your subscription will automatically renew at
                    the end of the twelve-month billing cycle unless cancelled after twelve months.
                    You may cancel your subscription at any time after twelve months, and
                    cancellation will be effective at the end of the then current billing cycle.
                    Refunds are not provided for partial subscription periods.
                  </li>
                  <li>
                    <b>Taxes:</b> Subscription fees are exclusive of any applicable taxes, which
                    will be added to your invoice as required by law in any state.
                  </li>
                  <li>
                    <b>Support and Assistance:</b> Our customer support team is here to assist you
                    with any questions or issues you may encounter regarding your subscription or
                    the software application itself. Please don't hesitate to reach out to us for
                    assistance.
                  </li>
                </ol>
                <p>
                  By subscribing to Fiducius PSLF Renewal software, you acknowledge that you have
                  read, understood, and agree to abide by these subscription fee terms. Thank you
                  for choosing us as your software solution provider. We look forward to serving
                  you!
                </p>
              </StyledDivLeft>
            </MessagePaddedDiv>
            <CardFooter>
              <Button
                brand="lowContrast"
                onClick={() => {
                  this.setState({ showSubscriptionModal: false });
                }}
              >
                Close
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  id: getUserId(state),
  forgivenessData: getForgivenessData(state),
  pastYearSavings: getPastYearSavings(state),
  currentYearSavings: getCurrentYearSavings(state),
  saveProgramSavings: getSAVEPlanSavings(state),
  data: getPaymentInfo(state),
  errors: state.payment.errors,
  form: state.payment.form,
  request: mergeRequestStatuses([
    state.payment.requests.loadResource,
    state.payment.requests.updateResource,
    state.payment.requests.createResource,
  ]),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadForgiveness: (id) => dispatch(forgivenessLoadResource(id)),
  updateForm: (data, attributes) => dispatch(paymentHandleFormChange(data, attributes)),
  getInfo: async () => {
    await dispatch(paymentLoadInfo());
    dispatch(paymentCopyResourceToForm(ownProps.paymentType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Billing));
