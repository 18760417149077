import styled from 'styled-components';

const DrawerItems = styled.div`
  color: ${(p) => p.theme.textPrimary};
  align-self: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default DrawerItems;
