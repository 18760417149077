import styled from 'styled-components';

const LoaderWrapper = styled.div`
  height: calc(${(p) => p.height}px - 2em);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5em 0;
`;

export default LoaderWrapper;
