import React from 'react';
import t from 'prop-types';
import {
  Button,
  Form,
  InputAddon,
  InputWrapper,
  InputHelp,
  InputLabel,
  InputGroup,
  InputText,
} from 'fiducius-ui';

class PasswordText extends React.Component {
  static propTypes = {
    auth: t.object,
    handleChange: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      resestToken: '',
      show: false,
    };
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow(value) {
    this.setState({ show: value });
  }

  render() {
    const { handleChange } = this.props;
    return (
      <Form handleChange={handleChange}>
        <InputWrapper>
          <InputLabel>Current Password</InputLabel>
          <InputGroup>
            <InputText
              name={'currentPassword'}
              type={this.state.show ? 'text' : 'password'}
              autoComplete="new-password"
              placeholder={'Password'}
            />
            <InputAddon>
              <Button
                brand="primary"
                onPointerDown={() => this.handleShow(true)}
                onPointerUp={() => this.handleShow(false)}
              >
                show
              </Button>
            </InputAddon>
          </InputGroup>
          <InputHelp>
            Please logout and request a new password at the login page if you forgot your current
            password.
          </InputHelp>
        </InputWrapper>
      </Form>
    );
  }
}

export default PasswordText;
