import React from 'react';
import t from 'prop-types';
import { usd } from './state';
import { CardTitle } from 'fiducius-ui';

export const PlanTableCompare = ({ years, yearsToCompare, type, compareRef }) => {
  return (
    <div className="polaris-plan-table">
      <div className="polaris-plan-table-window" ref={compareRef}>
        <CardTitle>Modified Contributions</CardTitle>
        <table>
          <thead>
            <tr>
              <td>Year</td>
              <td>
                New Monthly
                <br />
                Payment
              </td>
              <td>
                Modified Monthly
                <br />
                Payment
              </td>
              <td>
                Accumulated
                <br />
                Loan Savings
              </td>
              <td>
                Modified
                <br />
                Accumulated
                <br />
                Loan Savings
              </td>
              <td>
                Retirement
                <br />
                Account
                <br />
                Accumulation
              </td>
              <td>
                Modified
                <br />
                Retirement
                <br />
                Account
                <br />
                Accumulation
              </td>
            </tr>
          </thead>
          <tbody>
            {years.map((y) => (
              <tr key={y.id}>
                <td>{y.id}</td>
                <td>{usd(y.payment)}</td>
                <td>{usd(yearsToCompare[y.id - 1].payment)}</td>
                <td>{usd(y.accSavings)}</td>
                <td>{usd(yearsToCompare[y.id - 1].accSavings)}</td>
                <td>{usd(y.accRetirement)}</td>
                <td>{usd(yearsToCompare[y.id - 1].accRetirement)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PlanTableCompare.propTypes = {
  stdPayment: t.number.isRequired,
  years: t.arrayOf(t.object).isRequired,
};
