import React from 'react';
import styled from 'styled-components';

import t from 'prop-types';
import { connect } from 'react-redux';
import { Header3 } from 'fiducius-ui';

import { withPermissions } from '../../routing';
import { todosLoadCollection } from '../../todos';

import Scheduler from './scheduler';
import MeetingList from '../components/meeting-list';
import { meetingsCancelMeeting, meetingsLoadMeetings } from '../redux/operations';

const StyledHeader3 = styled(Header3)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textTertiary : 'inherit')};
`;

const Container = styled.div`
  padding: 1em;
`;

const MyMeetingContainer = styled.div`
  padding: 1em 0 0 0;
`;

class MyMeetings extends React.Component {
  static propTypes = {
    cancelMeeting: t.func.isRequired,
    deleteRequest: t.object.isRequired,
    loadMeetings: t.func.isRequired,
    myMeetings: t.object,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      schedulerIsOpen: false,
      shouldClose: false,
    };
  }

  componentDidMount() {
    this.props.loadMeetings();
  }

  render() {
    const { cancelMeeting, deleteRequest, myMeetings = {}, permissions } = this.props;
    return (
      <Container>
        {permissions.showScheduleMeeting && (
          <>
            <StyledHeader3>Schedule a meeting</StyledHeader3>
            <Scheduler />
          </>
        )}
        <MyMeetingContainer>
          <StyledHeader3>My Meetings</StyledHeader3>
          <MeetingList
            request={deleteRequest}
            cancelMeeting={cancelMeeting}
            meetings={Object.keys(myMeetings).reduce((m, k) => [...m, myMeetings[k]], [])}
          />
        </MyMeetingContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  myMeetings: state.meetings.cache.current,
  deleteRequest: state.meetings.requests.deleteResource,
});

const mapDispatchToProps = (dispatch) => ({
  cancelMeeting: async (id) => {
    await dispatch(meetingsCancelMeeting(id));
    dispatch(todosLoadCollection());
  },
  loadMeetings: () => dispatch(meetingsLoadMeetings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyMeetings));
