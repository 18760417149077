import React, { useContext } from 'react';
import t from 'prop-types';

import Input from './input';
import { CardTitle } from 'fiducius-ui';
import { AppContext } from '../containers/plan-tool';
import { FocusButton } from './focus-button';
import { GeneralCard } from './general-card';
import { PretaxCard } from './pretax-card';

export const InputCard = ({ page, inputs, modifiedInputs }) => {
  const { state, onChange, onChangeDeductionAction } = useContext(AppContext);
  if (page === 'General') return <GeneralCard inputs={inputs} page={page} />;
  else if (page === 'Monthly Retirement Contribution')
    return <GeneralCard inputs={inputs} page={page} />;
  else if (page === 'Other Pre-Tax Costs / Contributions')
    return <PretaxCard inputs={inputs} modifiedInputs={modifiedInputs} page={page} />;
  return (
    <div className="polaris-input-card" key={page}>
      <CardTitle>{page}</CardTitle>
      {Object.entries(inputs).map(([k, v]) => (
        <Input
          {...v}
          key={`${page}-${k}`}
          name={k}
          hidden={v && v.hide && !!v.hide(state)}
          onChange={onChange(page)}
          onClick={onChangeDeductionAction(page, 'Delete Deduction')}
        />
      ))}
      <FocusButton focus={page} />
    </div>
  );
};

InputCard.propTypes = {
  page: t.string.isRequired,
  inputs: t.object.isRequired,
};
