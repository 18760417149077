import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { getTuitionInstitutionData } from '../../benefits/tuition/redux/selectors';
import { TuitionInstitutionTermCurrentRow } from '../../tuition-institution-term';

class TuitionInstitutionHistoryRow extends React.Component {
  static propTypes = {
    institutionId: t.number.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  getDegreeText = (degreeType) => {
    let retVal = '';
    if (degreeType) {
      if (degreeType === 'CERT') {
        retVal = 'Certification';
      } else if (degreeType === 'ASSOC') {
        retVal = 'Associates';
      } else if (degreeType === 'BA') {
        retVal = 'Bachelors';
      } else if (degreeType === 'MA') {
        retVal = 'Masters';
      } else if (degreeType === 'PHD') {
        retVal = 'Doctorate';
      }
    }
    return retVal;
  };

  render() {
    const { data, institutionId } = this.props;
    const institution = data[institutionId];
    const terms = [];
    return (
      <>
        {Object.keys(institution.terms).length > 0 &&
          Object.keys(institution.terms).forEach((term) => {
            if (institution.terms[term].current) {
              terms.push(
                <TuitionInstitutionTermCurrentRow institutionId={institutionId} termId={term} />
              );
            }
          })}
        {terms.length > 0 && terms}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  return {
    data: getTuitionInstitutionData(state),
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (state) => {};

export default connect(mapStateToProps, mapDispatchToProps)(TuitionInstitutionHistoryRow);
