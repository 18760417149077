import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/customer-contribution-milestone',
  resourceType: 'customer-contribution-milestone',
};

const operations = resourceOperationsGenerator(
  'customerContributionMilestone',
  actions,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  endpointHandler,
  null
);

const {
  customerContributionMilestoneClearForm,
  customerContributionMilestoneCopyResourceToForm,
  customerContributionMilestoneHandleFormChange,
  customerContributionMilestoneLoadCollection,
  customerContributionMilestoneCreateResource,
  customerContributionMilestoneUpdateResource,
} = operations;

export {
  customerContributionMilestoneClearForm,
  customerContributionMilestoneCopyResourceToForm,
  customerContributionMilestoneHandleFormChange,
  customerContributionMilestoneLoadCollection,
  customerContributionMilestoneCreateResource,
  customerContributionMilestoneUpdateResource,
};

export default operations;
