import { FormValidator } from '../../utils';

export class AccountUnlockValidator extends FormValidator {
  // Register tab validators
  email(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidSingleEmail();
  }
}
