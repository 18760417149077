import React from 'react';
import LegalPage from './legal-page';
import { Card, CardBody, CardFooter, CardHeader, CardText, Link } from 'fiducius-ui';

const Privacy = () => (
  <LegalPage title="Privacy Policy">
    <Card>
      <CardHeader>Last updated October 7, 2021</CardHeader>
      <CardBody>
        <CardText>
          <p>
            Fiducius, LLC and its affiliates (collectively "Fiducius, LLC, we, our, or us") wants
            you to be clear how we are using information and the ways in which you can protect your
            privacy.
          </p>
          <p>This Privacy Notice ("Notice") explains:</p>
          <ul>
            <li>What information we collect and why we collect it.</li>
            <li>How we use that information.</li>
            <li>The choices we offer, including how to access and update information.</li>
          </ul>
          <p>
            This Notice discloses the current privacy practices for Fiducius, LLC, and your access
            to and use of our website ("site") are subject to this Notice. By accessing and using
            this site, you are acknowledging and agreeing to the terms of the Notice. If you do not
            agree to our Notice, please do not use our site.
          </p>
          <p>
            We have tried to keep this Notice as simple as possible, but if you are not familiar
            with terms like cookies, IP addresses, pixel tags and browsers, then read about these
            key terms first. Your privacy matters to Fiducius, LLC so whether you are new to
            Fiducius, LLC or a long-time user, please do take the time to get to know our practices.
            If you have any questions, consult this page.
          </p>

          <h4>1. Information We Collect </h4>
          <p>We collect information in two ways:</p>
          <ul>
            <li>
              Information you give us - For example, our services require you to sign up for a
              Fiducius, LLC Account. When you do, we'll ask for personal information, like your
              name, email address, telephone number, certain financial information and credit card
              information.
            </li>
            <li>
              Information we get from your use of our services - We may collect information about
              the services that you use and how you use them, like when you visit a website that
              uses our advertising services or you view and interact with our ads and content. This
              information includes:
              <ul>
                <li>
                  Device information - We may collect device-specific information (such as your
                  hardware model, operating system version, unique device identifiers, and mobile
                  network information including phone number). Fiducius, LLC may associate your
                  device identifiers or phone number with your Fiducius, LLC Account.
                </li>
                <li>
                  Log information - When you use our services or view content provided by Fiducius,
                  LLC, we may automatically collect and store certain information in server logs.
                  This may include:
                  <ul>
                    <li>Details of how you used our service, such as your search queries.</li>
                    <li>
                      Telephone log information like your phone number, calling-party number,
                      forwarding numbers, time and date of calls, duration of calls, SMS routing
                      information and types of calls.
                    </li>
                    <li>Internet protocol address.</li>
                    <li>
                      Device event information such as crashes, system activity, hardware settings,
                      browser type, browser language, the date and time of your request and referral
                      URL.
                    </li>
                    <li>
                      Cookies that may uniquely identify your browser or your Fiducius, LLC Account.
                    </li>
                  </ul>
                </li>
                <li>
                  Location information - When you use a location-enabled Fiducius, LLC service, we
                  may collect and process information about your actual location, like GPS signals
                  sent by a mobile device.
                </li>
                <li>
                  Unique application numbers - Certain services include a unique application number.
                  This number and information about your installation (for example, the operating
                  system type and application version number) may be sent to Fiducius, LLC when you
                  install or uninstall that service or when that service periodically contacts our
                  servers, such as for automatic updates.
                </li>
                <li>
                  Local storage - We may collect and store information (including personal
                  information) locally on your device using mechanisms such as browser web storage
                  (including HTML 5) and application data caches.
                </li>
                <li>
                  Cookies and anonymous identifiers - We and our partners use various technologies
                  to collect and store information when you visit a Fiducius, LLC service, and this
                  may include sending one or more cookies or anonymous identifiers to your device.
                  We also use cookies and anonymous identifiers when you interact with services we
                  offer to our partners, such as payment processing services.
                </li>
              </ul>
            </li>
          </ul>

          <h4>2. How We Use Information We Collect</h4>
          <p>
            We use the information we collect from all of our services to provide, maintain, protect
            and improve these services, to develop new ones, and to protect Fiducius, LLC and our
            partners, affiliates, service providers and users. We may also use this information to
            offer you tailored content.
          </p>
          <p>
            When you contact Fiducius, LLC, we may keep a record of your communication to help solve
            any issues you might be facing. We may use your email address to inform you about our
            services, such as letting you know about upcoming changes or improvements.{' '}
          </p>
          <p>
            We may use information collected from cookies and other technologies, like pixel tags,
            to improve your user experience and the overall quality of our services. We may combine
            personal information from one service with information, including personal information,
            from our partners, affiliates, service providers.{' '}
          </p>
          <p>
            We will ask for your express consent before using your personal information for a
            purpose other than those that are set out in Section 5 of this Notice.{' '}
          </p>

          <h4>3. Transparency and Choice</h4>
          <p>
            People have different privacy concerns. Our goal is to be clear about what information
            we collect, so that you can make meaningful choices about how it is used. For example,
            you can view and edit your information provided by you on your Fiducius, LLC account.{' '}
          </p>
          <p>
            You may also set your browser to block all cookies, including cookies associated with
            our services, or to indicate when a cookie is being set by us. However, it is important
            to remember that our services may not function properly if your cookies are disabled.{' '}
          </p>

          <h4>4. Accessing and Updating Your Personal Information</h4>
          <p>
            Whenever you use our services, we aim to provide you with access to your personal
            information. If that information is wrong, we strive to give you ways to update it
            quickly or to delete it – unless we have to keep that information for legitimate
            business or legal purposes. When updating your personal information, we may ask you to
            verify your identity before we can act on your request.{' '}
          </p>
          <p>
            We aim to maintain our services in a manner that protects information from accidental or
            malicious destruction. Because of this, after you delete information from our services,
            we may not immediately delete residual copies from our active servers and may not remove
            information from our backup systems.{' '}
          </p>

          <h4>5. Information We Share</h4>
          <p>
            We do not share personal information with nonaffiliated third parties unless one of the
            following circumstances applies:
          </p>
          <ul>
            <li>
              With your prior express consent: For example, when you establish an account with
              Fiducius, LLC, we will ask your permission to share your personal information with
              nonaffiliated third parties so that the third parties can market their services to you
              and you have the right to opt out of us sharing your information at any time by
              contacting us at{' '}
              <a href="mailto:customer.service@getfiducius.com">
                customer.service@getfiducius.com{' '}
              </a>{' '}
              or <a href="tel:5136455400">(513) 645-5400</a>.
            </li>
            <li>
              For external processing: We will share personal information with our partners,
              affiliates or service providers in the performance of services on our behalf. These
              entities are required to comply with agreed upon confidentiality and security
              measures.
            </li>
            <li>
              For legal reasons: We will share personal information with nonaffiliated third parties
              if we have a good-faith belief that access, use, preservation or disclosure of the
              information is reasonably necessary to:
              <ul>
                <li>
                  Meet any applicable law, regulation, legal process (e.g., subpoena) or enforceable
                  governmental request.
                </li>
                <li>
                  Enforce this Terms of Service, including investigation of potential violations.
                </li>
                <li>Detect, prevent, or otherwise address fraud, security or technical issues.</li>
                <li>
                  Protect against harm to the rights, property or safety of Fiducius, LLC, our
                  users, service providers, and the public as required or permitted by law.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            We may share aggregated, non-personally identifiable information publicly and with our
            partners, affiliates or service providers. For example, we may share information
            publicly to show trends about the general use of our services.{' '}
          </p>
          <p>
            If Fiducius, LLC is involved in a merger, acquisition or asset sale, we will continue to
            ensure the confidentiality of any personal information and give affected users notice
            before personal information is transferred or becomes subject to a different privacy
            policy.{' '}
          </p>

          <h4>6. Information Security</h4>
          <p>
            We work hard to protect Fiducius, LLC and our users from unauthorized access to or
            unauthorized alteration, disclosure or destruction of information we hold. In
            particular:
          </p>
          <ul>
            <li>We encrypt many of our services using SSL.</li>
            <li>
              We regularly review our information collection, storage and processing practices,
              including physical security measures, to guard against unauthorized access to systems.
            </li>
            <li>
              We restrict access to personal information to Fiducius, LLC employees, contractors and
              agents who need to know that information in order to process it for us, and who are
              subject to contractual confidentiality obligations and may be disciplined or
              terminated by Fiducius, LLC if they fail to meet these obligations.
            </li>
          </ul>
          <p>
            Fiducius, LLC does not guarantee or warrant the security of any information transmitted
            to us. You send us information at your own risk. Fiducius, LLC shall not be responsible
            for any harm that may result as a result of a breach of confidentiality in respect to
            any use of this site or any information transmitted to this site.
          </p>

          <h4>7. Preventing Identity Theft</h4>
          <p>
            In order to help us protect your personal information, it is important that you always
            keep your account information safe. Never share your username or password with anyone,
            under any circumstances.
          </p>
          <p>
            Fiducius, LLC will NEVER initiate a request via email for your sensitive information
            (e.g., Social Security number, username, or password). We will ask for documents to be
            uploaded or faxed when a meeting is scheduled. If you receive an email asking for your
            sensitive information, you should be suspicious of the request and promptly contact us
            at <a href="tel:5136455400">(513) 645-5400</a> to report the suspicious activity.
          </p>
          <p>
            Please be aware, however, that in certain telephone, in-person or online transactions we
            may ask for your full Social Security number, or other information before conducting the
            transactions you have requested. For example, we may ask for such information in order
            to begin our application process to get you enrolled with our services. Additional
            information regarding identity theft and the steps you can take to help protect yourself
            from fraud can be found on the Federal Trade Commission website.
          </p>

          <h4>8. Children's Privacy</h4>
          <p>
            Fiducius, LLC sites are not directed at children under the age of 13. We operate our
            sites in compliance with the Children's Online Privacy Protection Act and will not
            knowingly collect or use personally identifiable information from anyone under 13.
            However, we will accept and store data on minors as part of the application information
            required in order to provide our services.
          </p>

          <h4>9. When This Notice Applies</h4>
          <p>
            Our Notice applies to all of the services offered by Fiducius, LLC and its affiliates,
            but excludes services that have separate privacy policies that do not incorporate this
            Notice or information that we have received that was collected under another company's
            privacy policy.{' '}
          </p>
          <p>
            Our Notice does not apply to services offered by other companies or individuals,
            including products or sites that may be displayed to you in search results or other
            sites linked from our services. Our Notice does not cover the information practices of
            other companies and organizations who are linked to our services, and who may use
            cookies, pixel tags and other technologies to serve and offer relevant services.{' '}
          </p>

          <h4>10. Compliance and Cooperation with Regulatory Authorities</h4>
          <p>
            We regularly review our compliance with our Notice. When we receive formal written
            complaints, we will contact the person who made the complaint to follow up. We work with
            the appropriate regulatory authorities, including local data protection authorities, to
            resolve any complaints regarding the transfer of personal data that we cannot resolve
            with our users directly.{' '}
          </p>

          <h4>11. Changes</h4>
          <p>
            Our Notice may change from time to time. We will post any Notice changes on this page
            and, if the changes are significant, we will provide a more prominent notice (including,
            for certain services, email notification of Notice changes). We will also keep prior
            versions of this Notice in an archive for your review.{' '}
          </p>

          <h4>12. Contact Us</h4>
          <p>
            For questions regarding our privacy practices, contact us at{' '}
            <a href="mailto:customer.service@getfiducius.com">customer.service@getfiducius.com </a>{' '}
            or <a href="tel:5136455400">(513) 645-5400</a>.
          </p>
        </CardText>
      </CardBody>
      <CardFooter>
        <Link to="/dashboard">Return to dashboard</Link>
      </CardFooter>
    </Card>
  </LegalPage>
);

export default Privacy;
