import { FormValidator, stringIsNullOrEmpty } from '../../utils';

export class ServicerValidator extends FormValidator {
  priorityNumber(validator) {
    // validator.isRequired();
  }

  servicerName(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
  }

  username(validator, state) {
    if (state.isFullService && state.inForgiveness) {
      validator.isRequired();
    }
  }

  password(validator, state) {
    if (state.isFullService && state.inForgiveness) {
      validator.isRequired();
    }
  }

  routingNumber(validator, state) {
    if (validator.value && validator.value !== '') {
      validator.hasMaxLength(9);
      validator.matchesRegex(/^[0-9]{9}/g, 'must be 9 digits long');
    }
  }

  address1(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  address2(validator) {
    if (validator.value && validator.value !== '') {
      validator.hasMaxLength(30);
    }
  }

  city(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  state(validator) {
    validator.isRequired();
    validator.hasMaxLength(2);
  }

  zip(validator) {
    validator.isRequired();
    validator.hasMaxLength(10);
    validator.matchesRegex(
      /^[0-9]{5}($|-[0-9]{4}$)/g,
      'must be formatted as either ##### or #####-####'
    );
  }

  description(validator) {
    if (validator.value && validator.value !== '') {
      validator.hasMaxLength(50);
    }
  }

  phone(validator) {
    validator.isRequired();
    validator.isValidPhoneNumber();
  }

  phoneExtension(validator) {
    // validator.isRequired();
  }

  email(validator) {
    // validator.isRequired();
    validator.isValidEmail();
  }

  refServicerId(validator) {
    validator.isRequired();
  }

  needsUpdated(validator) {
    // validator.isRequired();
  }

  question1Text(validator, state) {
    if (!stringIsNullOrEmpty(state.question1Response)) {
      validator.isRequired();
    }
  }

  question1Response(validator, state) {
    if (!stringIsNullOrEmpty(state.question1Text)) {
      validator.isRequired();
    }
  }

  question2Text(validator, state) {
    if (!stringIsNullOrEmpty(state.question2Response)) {
      validator.isRequired();
    }
  }

  question2Response(validator, state) {
    if (!stringIsNullOrEmpty(state.question2Text)) {
      validator.isRequired();
    }
  }

  question3Text(validator, state) {
    if (!stringIsNullOrEmpty(state.question3Response)) {
      validator.isRequired();
    }
  }

  question3Response(validator, state) {
    if (!stringIsNullOrEmpty(state.question3Text)) {
      validator.isRequired();
    }
  }
}
