import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Button, Loader, Table } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { refiGetExistingQuotes } from '../redux/operations';

const LoaderWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

class SummaryQuotes extends React.Component {
  static propTypes = {
    history: t.object,
    load: t.func.isRequired,
    quotes: t.array,
    request: t.object,
  };

  componentDidMount() {
    this.props.load();
  }

  render() {
    const { history, quotes, request = {} } = this.props;
    if (request.notStarted || request.isLoading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    } else if (safeAccess(quotes, 'length', 0) > 0) {
      return (
        <Table
          columns={[
            { sortable: true },
            { sortable: true },
            { sortable: true },
            { sortable: true },
            { sortable: true },
            { sortable: true },
          ]}
          header={[
            'Rate',
            'Rate Type',
            'Loan Amount',
            'Loan Type',
            'Monthly Payment',
            'Term Length',
          ]}
          body={[
            ['5.19%', 'Variable', '$100,000.00', 'Federal', '$1895.84', '5 yr'],
            ['5.33%', 'Fixed', '$100,000.00', 'Federal', '$1077.35', '10 yr'],
            ['5.47%', 'Fixed', '$100,000.00', 'Federal', '$1083.78', '10 yr'],
            ['5.85%', 'Variable', '$100,000.00', 'Private', '$1102.69', '10 yr'],
          ]}
        />
      );
    } else {
      return (
        <LoaderWrapper>
          <Button brand="primary" onClick={() => history.push('/benefits/refinance')}>
            Get quotes now
          </Button>
        </LoaderWrapper>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  quotes: safeAccess(state, 'refi.cache.enrolledForgiveness', []),
  request: state.refi.loadResource,
});

const mapDispatchToProps = (dispatch) => ({
  load: async () => await dispatch(refiGetExistingQuotes()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SummaryQuotes));
