import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { withPermissions, Fade } from '../../../routing';

import {
  Alert,
  Animation,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  fade,
} from 'fiducius-ui';

import { FlexBetween } from '../../../root';
import { safeAccess, stringIsNullOrEmpty, convertIsoToSlash } from '../../../utils';
import { useEffect } from 'react';

const TextLeft = styled.div`
  text-align: left;
`;

const StyledFade = styled.div`
  ${fade};
`;

const EducationalReengagementForm = ({
  permissions,
  data = {},
  errors = {},
  onChange,
  onSubmit,
  action,
  closeForm,
  isLoading,
  isOpen,
}) => {
  const getShowSecondColumn = () => {
    return (
      (permissions.isFullService && permissions.inForgiveness) ||
      (!permissions.inTuition && permissions.inForgiveness)
    );
  };

  const getForm = () => {
    const id = data.id || 'create';

    let colVar = 'col-12 col-xl-6 col-lg-6 col-md-6';

    if (!getShowSecondColumn()) {
      colVar = 'col-12 col-xl-12 col-lg-12 col-md-12';
    }

    return (
      <Form defaults={data} handleChange={onChange} id={id} onSubmit={onSubmit}>
        <div className="row">
          <div className={colVar}>
            <CardTitle>Institution Dates</CardTitle>
            <StyledFade>
              <InputWrapper error={!!errors.expectedReturnToSchoolDate}>
                <InputLabel htmlFor="expectedReturnToSchoolDate" required>
                  Expected Return to School Date
                </InputLabel>
                <InputError>{safeAccess(errors, 'expectedReturnToSchoolDate.detail')}</InputError>
                <InputGroup>
                  <InputMask
                    defaultValue={convertIsoToSlash(data.expectedReturnToSchoolDate)}
                    mask="##/##/####"
                    name="expectedReturnToSchoolDate"
                    placeholder="MM/DD/YYYY"
                  />
                </InputGroup>
              </InputWrapper>
              <InputWrapper error={!!errors.expectedGraduationDate}>
                <InputLabel htmlFor="expectedGraduationDate" required>
                  Expected Graduation Date
                </InputLabel>
                <InputError>{safeAccess(errors, 'expectedGraduationDate.detail')}</InputError>
                <InputGroup>
                  <InputMask
                    defaultValue={convertIsoToSlash(data.expectedGraduationDate)}
                    mask="##/##/####"
                    name="expectedGraduationDate"
                    placeholder="MM/DD/YYYY"
                  />
                </InputGroup>
              </InputWrapper>
            </StyledFade>
          </div>
        </div>
      </Form>
    );
  };

  const checkNoMissingData = () => {
    return (
      !stringIsNullOrEmpty(data.expectedReturnToSchoolDate) &&
      !stringIsNullOrEmpty(data.expectedGraduationDate)
    );
  };

  const checkNoErrors = () => {
    return !errors.expectedReturnToSchoolDate && !errors.expectedGraduationDate;
  };

  const enableSubmit = () => {
    return checkNoMissingData() && checkNoErrors();
  };

  const submit = (e) => {
    e.preventDefault();
    if (!isLoading) {
      onSubmit();
    }
  };

  return (
    <Modal isOpen={isOpen} theme={'position:fixed;'}>
      <Card brand={errors.generalErrors ? 'danger' : undefined}>
        <CardHeader>Update Information</CardHeader>
        <CardBody>
          {errors.generalErrors && (
            <TextLeft>
              {errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))}
            </TextLeft>
          )}
          <TextLeft>{getForm()}</TextLeft>
        </CardBody>
        <CardFooter>
          <FlexBetween>
            <Button brand="lowContrast" onClick={closeForm}>
              Cancel
            </Button>
            <Fade show={enableSubmit()}>
              <Button brand="success" onClick={(e) => submit(e)}>
                {isLoading ? <Loader variant="push" size={1.5} /> : 'Save'}
              </Button>
            </Fade>
            <Fade show={!isLoading && !checkNoMissingData()}>
              Please enter the missing information above.
            </Fade>
            <Fade show={!isLoading && checkNoMissingData() && !checkNoErrors()}>
              Please fix the errors above.
            </Fade>
          </FlexBetween>
        </CardFooter>
      </Card>
    </Modal>
  );
};

export default withPermissions(EducationalReengagementForm);
