import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Button, InputWrapper, InputLabel, CardText } from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { safeAccess, debounce, mergeRequestStatuses } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { authLoadPermissions } from '../../../auth';
import { paymentSetPaymentType } from '../../../payment';

import { todosLoadCollection, todosUpdateResource } from '../../../todos/redux/operations';
import { legalUpdateResource } from '../../../legal/redux/operations';

import LwoFax from './lwo-fax';
import styled from 'styled-components';
import LwoOptOutModal from './lwo-opt-out';
const InputWrapper2 = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

class LwoConfirmation extends React.Component {
  static propTypes = {
    confirm: t.func.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    stepId: t.number.isRequired,
    todos: t.array,
    setPaymentType: t.func.isRequired,
    additionalInfoNeeded: t.bool,
    handleChange: t.func,
    zeroBalance: t.bool,
    id: t.string,
    optOutLwo: t.func.isRequired,
    data: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { showCheckbox: false, showOptOutModal: false, redirectURI: '' };
    this.onConfirm = this.onConfirm.bind(this);
    this.goToCurrentStep = this.goToCurrentStep.bind(this);
    this.goToInfo = this.goToInfo.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  openOptOutModal = () => {
    this.setState({ showOptOutModal: true });
  };

  handleOptOutModal = (action) => {
    if (action === 'CLOSE') {
      this.setState({ showOptOutModal: false });
    } else if (action === 'OPTOUT') {
      this.props.optOutLwo();
      this.setState({ showOptOutModal: false, redirectURI: '/dashboard' });
    }
  };

  onConfirm = async () => {
    await this.props.confirm(this.props.stepId);
    this.goToCurrentStep();
  };

  goToInfo(e) {
    this.props.handleChange(e.type);
  }

  checkStepCompleted = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const lwoStep = permissions.lwoSteps.find((a) => a.id === stepMapping);

    let retVal = false;
    if (lwoStep !== undefined && lwoStep !== null && todos !== undefined && todos !== null) {
      retVal = lwoStep.stepComplete;
      if (!retVal) {
        if (stepId === 606 || stepId === 607 || stepId === 608) {
          const currentToDo = todos.find((a) => a.id === 606 || a.id === 607 || a.id === 608);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
        }
      }
    }

    return retVal;
  };

  getStepMapping = (id) => {
    let retVal = '';
    switch (id) {
      case 602:
        retVal = 'LoanInformation';
        break;
      case 603:
        retVal = 'Questionnaire';
        break;
      case 604:
        retVal = 'Agreement';
        break;
      case 605:
        retVal = 'Fee';
        break;
      case 606:
      case 607:
      case 608:
        retVal = 'EmploymentCertification';
        break;
      case 609:
        retVal = 'QualifyingPaymentCheck';
        break;
      case 610:
        retVal = 'Complete';
        break;
      default:
        retVal = 'Overview';
        break;
    }
    return retVal;
  };

  checkNotYetAtStep = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    const lwoStep = permissions.lwoSteps.find((a) => a.id === stepMapping);
    const currentStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    let retVal = false;
    if (
      lwoStep !== undefined &&
      lwoStep !== null &&
      currentStep !== undefined &&
      currentStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (lwoStep.id === currentStep.id) {
        if (stepId === 606 || stepId === 607 || stepId === 608) {
          const currentToDo = todos.find((a) => a.id === 606 || a.id === 607 || a.id === 608);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        }
      } else {
        retVal = !lwoStep.stepComplete && !lwoStep.stepCurrent;
      }
    }
    return retVal;
  };

  goToCurrentStep = () => {
    let path = this.getNextStepPath();
    this.setState({ ...this.state, redirectURI: path });
  };

  getNextStepPath = () => {
    const { permissions, todos } = this.props;
    const lwoSteps = permissions.lwoSteps.filter((a) => a.stepCurrent);
    let lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    if (
      lwoSteps !== undefined &&
      lwoSteps !== null &&
      lwoSteps.length > 1 &&
      lwoSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      lwoStep = lwoSteps.find((a) => window.location.pathname.includes(a.stepPath));
    }

    let retVal = '/benefits/limited-waiver-opportunity/overview';
    if (lwoStep !== undefined && lwoStep !== null && todos !== undefined && todos !== null) {
      if (lwoStep.id === 'EmploymentCertification') {
        retVal = '/benefits/limited-waiver-opportunity' + lwoStep.stepPath;
        const currentToDo = todos.find(
          (a) =>
            lwoStep.id === 'EmploymentCertification' &&
            (a.id === 606 || a.id === 607 || a.id === 608)
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 606:
              retVal += '/step-1';
              break;
            case 607:
              retVal += '/step-2';
              break;
            case 608:
              retVal += '/step-3';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = '/benefits/limited-waiver-opportunity' + lwoStep.stepPath;
      }
    }
    return retVal;
  };

  checkStepCurrentAndComplete = () => {
    const { permissions } = this.props;
    const lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent && a.stepComplete);

    if (lwoStep !== undefined && lwoStep !== null && lwoStep.id === 'Complete') {
      return true;
    }
    return false;
  };

  showOptOut = () => {
    const { permissions, data } = this.props;
    if (permissions.inForgiveness && !data.companyPaid && !data.originationPaid) {
      return true;
    }
    return false;
  };
  needsDocumentsGenerated = () => {
    const { stepId } = this.props;
    let retVal = false;
    if (stepId === 606) {
      retVal = true;
    }
    return retVal;
  };

  redirectForDocumentGeneration = () => {
    if (this.needsDocumentsGenerated()) {
      this.props
        .confirm(this.props.stepId)
        .then((a) => this.setState({ ...this.state, redirectURI: '/documents' }));
    } else {
      this.setState({ ...this.state, redirectURI: '/documents' });
    }
  };

  redirectForDocumentUploadOrSign = () => {
    this.setState({ ...this.state, redirectURI: '/documents' });
  };

  redirectForPayment = () => {
    this.props.setPaymentType().then(() => {
      this.setState({ ...this.state, redirectURI: '/payment' });
    });
  };

  redirectToSignAgreement = () => {
    this.setState({ ...this.state, redirectURI: '/lwo-agreement' });
  };

  redirectToLearnMore = () => {
    this.setState({
      ...this.state,
      redirectURI: '/benefits/limited-waiver-opportunity/about-assure',
    });
  };

  redirectForNsldsUpload = () => {
    this.props
      .confirm(this.props.stepId)
      .then((a) => this.setState({ ...this.state, redirectURI: '/nslds' }));
  };

  getResources = (resourceType) => {
    return (
      <>
        <div className="row col-12">
          <div className="col-8">
            {resourceType === 'stepCompleted' && (
              <InputWrapper>
                <InputLabel2>You've completed this step.</InputLabel2>
              </InputWrapper>
            )}
            {resourceType === 'notAtStep' && (
              <InputWrapper>
                <InputLabel2>You haven't reached this step yet.</InputLabel2>
              </InputWrapper>
            )}
            {resourceType === 'documents' && (
              <InputWrapper2>
                <InputLabel2>You've completed this step.</InputLabel2>
              </InputWrapper2>
            )}
          </div>
          <div className="col-4">
            {(resourceType === 'stepCompleted' || resourceType === 'notAtStep') && (
              <InputWrapper>
                <Button brand="info" onClick={this.goToCurrentStep}>
                  {'Go to current step'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'generateDoc' && (
              <InputWrapper>
                <Button brand="success" onClick={this.redirectForDocumentGeneration}>
                  {'Generate my document(s)'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'signAgreement' && (
              <InputWrapper>
                <Button brand="success" onClick={this.redirectForDocumentUploadOrSign}>
                  {'Sign Agreement'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'documentUpload' && (
              <InputWrapper>
                <Button brand="success" onClick={this.redirectForDocumentUploadOrSign}>
                  {'Generate my document(s)'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'payment' && (
              <InputWrapper>
                <Button brand="primary" onClick={this.redirectForPayment}>
                  {'Pay Fee'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'documents' && (
              <InputWrapper>
                <Button brand="info" onClick={this.goToCurrentStep}>
                  {'Go to current step'}
                </Button>
              </InputWrapper>
            )}
          </div>
        </div>
      </>
    );
  };

  handleChange = debounce((formState, id, attributes) => {
    this.setState({ ...this.state, showCheckbox: !this.state.showCheckbox });
  }, 250);

  render() {
    const { stepId, permissions, data } = this.props;

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      if (this.checkNotYetAtStep()) {
        return (
          <AsyncLoader
            request={this.props.request}
            loading={stepId === 604 ? <></> : null}
            empty={this.props.request.isEmpty ? this.getResources('notAtStep') : null}
          >
            <div className="row col-12">
              <div className="col-lg-8 col-md-7 col-sm-12">
                <InputWrapper>
                  <InputLabel>
                    <CardText>
                      Looks like you still have some work to do to get your peace of mind from
                      Assure&#8480;. Click the "Go to Current Step" button and we'll get you started
                      again.
                    </CardText>
                  </InputLabel>
                </InputWrapper>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12">
                <InputWrapper>
                  <Button brand="info" onClick={this.goToCurrentStep}>
                    {'Go to current step'}
                  </Button>
                </InputWrapper>
              </div>
            </div>
          </AsyncLoader>
        );
      } else if (this.checkStepCompleted()) {
        if (this.checkStepCurrentAndComplete()) {
          return <></>;
        }
        return (
          <AsyncLoader
            request={this.props.request}
            loading={stepId === 604 ? <></> : null}
            empty={this.props.request.isEmpty ? this.getResources('stepCompleted') : null}
          >
            <div className="row col-12">
              <div className="col-lg-8 col-md-7 col-sm-12">
                <InputWrapper>
                  <InputLabel2>You've completed this step.</InputLabel2>
                </InputWrapper>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12">
                <InputWrapper>
                  <Button brand="info" onClick={this.goToCurrentStep}>
                    {'Go to current step'}
                  </Button>
                </InputWrapper>
              </div>
            </div>
          </AsyncLoader>
        );
      } else if (this.needsDocumentsGenerated()) {
        return (
          <AsyncLoader
            request={this.props.request}
            empty={this.props.request.isEmpty ? this.getResources('generateDoc') : null}
          >
            <div className="row col-12">
              <div className=" col-lg-8 col-md-7 col-sm-0"></div>
              <div className="col-lg-4 col-md-5 col-sm-12">
                <InputWrapper>
                  <Button brand="success" onClick={this.redirectForDocumentGeneration}>
                    {'Generate my document(s)'}
                  </Button>
                </InputWrapper>
              </div>
            </div>
          </AsyncLoader>
        );
      } else {
        if (stepId === 602 || stepId === 609) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('documentUpload') : null}
            >
              <div className="row col-12">
                <div className=" col-lg-8 col-md-7 col-sm-0"></div>
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <InputWrapper>
                    <Button brand="success" onClick={this.redirectForNsldsUpload}>
                      {stepId === 602 && 'Submit My NSLDS'}
                      {stepId === 609 && 'Submit My Updated NSLDS'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else if (stepId === 603) {
          return <></>;
        } else if (stepId === 604) {
          return (
            <AsyncLoader
              request={this.props.request}
              loading={<></>}
              empty={this.props.request.isEmpty ? this.getResources('documents') : null}
            >
              {data && (
                <>
                  {this.showOptOut() && (
                    <div className="row col-12">
                      <div className="col-lg-4 col-md-5 col-sm-12">
                        <InputWrapper>
                          <Button invert brand="danger" onClick={this.openOptOutModal}>
                            <>I Don't Want Assure&#8480;!</>
                          </Button>
                        </InputWrapper>
                      </div>
                      <div className=" col-lg-4 col-md-2 col-sm-0">
                        <InputWrapper>
                          <Button brand="primary" onClick={this.redirectToLearnMore}>
                            <>Learn More</>
                          </Button>
                        </InputWrapper>
                      </div>
                      <div className="col-lg-4 col-md-5 col-sm-12">
                        <InputWrapper>
                          <Button brand="success" onClick={this.redirectToSignAgreement}>
                            <>I Want Assure&#8480;!</>
                          </Button>
                        </InputWrapper>
                      </div>
                    </div>
                  )}
                  {!this.showOptOut() && (
                    <div className="row col-12">
                      <div className="col-lg-4 col-md-5 col-sm-12">
                        <InputWrapper>
                          <Button brand="primary" onClick={this.redirectToLearnMore}>
                            <>Learn More</>
                          </Button>
                        </InputWrapper>
                      </div>
                      <div className=" col-lg-4 col-md-2 col-sm-0"></div>
                      <div className="col-lg-4 col-md-5 col-sm-12">
                        <InputWrapper>
                          <Button brand="success" onClick={this.redirectToSignAgreement}>
                            <>I Want Assure&#8480;!</>
                          </Button>
                        </InputWrapper>
                      </div>
                    </div>
                  )}
                  {this.state.showOptOutModal && (
                    <LwoOptOutModal handleModalAction={this.handleOptOutModal} />
                  )}
                </>
              )}
            </AsyncLoader>
          );
        } else if (stepId === 605) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('payment') : null}
            >
              <div className="row col-12">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectToLearnMore}>
                      <>Learn More</>
                    </Button>
                  </InputWrapper>
                </div>
                <div className=" col-lg-4 col-md-2 col-sm-0"></div>
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectForPayment}>
                      <>Pay For Assure&#8480;</>
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else {
          if (stepId === 607) {
            return <LwoFax stepId={stepId} />;
          } else {
            return (
              <AsyncLoader request={this.props.request}>
                <div className="row col-12">
                  <div className=" col-lg-8 col-md-7 col-sm-0"></div>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <InputWrapper>
                      <Button id={'confirm'} brand={'primary'} onClick={this.onConfirm}>
                        {'Confirm'}
                      </Button>
                    </InputWrapper>
                  </div>
                </div>
              </AsyncLoader>
            );
          }
        }
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
      state.payment.requests.loadResource,
      state.limitedWaiverOpportunity.requests.loadResource,
      state.legal.requests.updateResource,
    ]),
    data: state.limitedWaiverOpportunity.cache[getUserId(state)],
    todos: Object.values(state.todos.cache),
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    await dispatch(todosUpdateResource(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  setPaymentType: async () => await dispatch(paymentSetPaymentType()),
  optOutLwo: async () => {
    await dispatch(legalUpdateResource('LWOOUT', 'LWO_DATA'));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoConfirmation));
