import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { renewalResultsLoadResource } from '../../../renewal-results';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';

class RenewalExtension extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  componentDidUpdate() {}

  render() {
    const { request } = this.props;

    return (
      <>
        <AsyncLoader request={request}>
          <ForgivenessProgress />
          <DashboardSection>
            <Card brand="primary">
              <CardBody>
                <CardTitle>Renewal Extension</CardTitle>
                <CardText>
                  <p>Thank you for trusting Fiducius to help with your renewal!</p>
                  <p>
                    Your team at Fiducius is committed to helping you get the best financial return
                    from using the Public Service Loan Forgiveness (PSLF) program. We constantly
                    monitor all policy changes or procedural updates regarding the program and
                    update our renewal tools accordingly. Thanks to our work, all you need to do is
                    follow the guidance we provide.
                  </p>

                  <p>
                    Temporary opportunities have been created in regard to the PSLF program as a
                    result of various changes across the student loan landscape. While some changes
                    apply to all borrowers, others should only be utilized by a select group based
                    on their situation. Don't worry. Fiducius will help you make the right decision!
                  </p>

                  <p>
                    Based on our analysis of the information you provided, we recommend that you
                    take advantage of a temporary program change. Maintaining your current repayment
                    plan for the next 12 months is in your best financial interest. If you submit a
                    new income-driven repayment application now, it will result in a higher monthly
                    payment.
                  </p>

                  <p>
                    This unique opportunity is available to you due to changes resulting from the
                    pandemic. Your "To-Do" list already reflects any actions necessary for your
                    situation, so please complete them as directed.
                  </p>

                  <p>
                    We will continue to monitor PSLF program changes and check in with you prior to
                    your next renewal period to again assess your options. In the meantime, be on
                    the lookout for our new tool, the Fiducius Benefit Modeler&trade;, which will
                    help you make employee benefit choices to lower your payment even further!
                  </p>

                  <p>
                    <b>
                      If you receive a notification from your loan servicer that your payment amount
                      is increasing due to your application not being submitted or that it is time
                      to recertify, please submit a Contact Us request using the "Repayment" subject
                      on your MyFiducius portal.
                    </b>
                  </p>
                </CardText>
              </CardBody>
              <RenewalConfirmation stepId={259} />
            </Card>
          </DashboardSection>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: state.renewalResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    dispatch(renewalResultsLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(RenewalExtension));
