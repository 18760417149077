import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
//import styled from 'styled-components';
/*import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Header4,
  Modal,
} from 'fiducius-ui';*/

import { todosLoadCollection } from '../../todos';

import { legalHandleFormChange, legalUpdateResource } from '../redux/operations';

/*const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 3rem 0;
`;

const TextLeftList = styled.ul`
  text-align: left;
`;

const TextLeft = styled.p`
  text-align: left;
`;*/

const AUTH_KEY = 'AUTH';

class AuthorizedUse extends React.Component {
  static propTypes = {
    consent: t.func.isRequired,
    isOpen: t.bool,
    request: t.object,
    toggleModal: t.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { request } = this.props;
    if (
      request.key === AUTH_KEY &&
      prevProps.request.isLoading &&
      !request.isLoading &&
      !request.hasFailed
    ) {
      this.props.toggleModal();
    }
  }

  render() {
    //const { isOpen, request, toggleModal } = this.props;
    return (
      <></>
      /*<Modal isOpen={isOpen}>
        <Card brand={request.hasFailed ? 'danger' : 'primary'}>
          {request.hasFailed && <CardHeader>Saving Authorized Use Failure</CardHeader>}
          <CardBody>
            <CardTitle>Authorized Use Consent</CardTitle>
            <CardText>
              <Header4>What am I authorizing?</Header4>
              <TextLeft>Your signature authorizes Fiducius to:</TextLeft>
              <TextLeftList>
                <li>
                  Properly align your student loans and establish the correct repayment plan with
                  The Department of Education for loan forgiveness.
                </li>
                <li>
                  Create and/or access your student loan service(s) account(s) to manage the loan
                  forgiveness program on your behalf.
                </li>
                <li>
                  Submit pre-filled consolidation application on your behalf at the loan servicer of
                  your choice.
                </li>
                <li>
                  Put your loan(s) in forbearance if that's part of your financial plan built by
                  your advisor.
                </li>
                <li>We will not utilize your FSA ID to access your account.</li>
              </TextLeftList>
            </CardText>
          </CardBody>
          <ButtonWrapper>
            <Button invert brand="textTertiary" size="lg" onClick={toggleModal}>
              Cancel
            </Button>
            <Button brand="primary" size="lg" onClick={this.props.consent}>
              I agree
            </Button>
          </ButtonWrapper>
        </Card>
      </Modal>*/
    );
  }
}

const mapStateToProps = (state) => ({
  request: state.legal.requests.updateResource,
});

const mapDispatchToProps = (dispatch) => ({
  consent: async () => {
    await dispatch(legalHandleFormChange({ id: AUTH_KEY, customerAuthorization: true }, []));
    await dispatch(legalUpdateResource(AUTH_KEY, AUTH_KEY));
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedUse);
