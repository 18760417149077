import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Header3,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
  fade,
} from 'fiducius-ui';

import { relationTypes } from '../../../references';
import { convertIsoToSlash, safeAccess } from '../../../utils';

const RowFade = styled.div`
  ${fade};
`;

const getRelations = () => {
  const keys = Object.keys(relationTypes).filter((k) => k !== 'ADV' && k !== 'REFER');
  return keys.reduce(
    (obj, k) => ({
      ...obj,
      [k]: relationTypes[k],
    }),
    {}
  );
};

const DependentForm = ({ data = {}, errors = {}, id }) => (
  <>
    <Header3>Household Member {id + 1}</Header3>
    <InputWrapper error={!!errors.dependentName}>
      <InputLabel htmlFor={'dependentName' + id}>First Name</InputLabel>
      <InputError>{safeAccess(errors, 'dependentName.detail')}</InputError>
      <InputGroup affordance="large">
        <InputText name={'dependentName' + id} defaultValue={data.dependentName} />
      </InputGroup>
    </InputWrapper>
    <InputWrapper error={!!errors.dependentLastName}>
      <InputLabel htmlFor={'dependentLastName' + id}>Last Name</InputLabel>
      <InputError>{safeAccess(errors, 'dependentLastName.detail')}</InputError>
      <InputGroup affordance="large">
        <InputText name={'dependentLastName' + id} defaultValue={data.dependentLastName} />
      </InputGroup>
    </InputWrapper>
    <RowFade className="row">
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.dependentRelationship}>
          <InputLabel htmlFor={'dependentRelationship' + id}>Relationship Type</InputLabel>
          <InputError>{safeAccess(errors, 'dependentRelationship.detail')}</InputError>
          <InputGroup>
            <InputSelect
              name={'dependentRelationship' + id}
              options={getRelations()}
              defaultValue={data.dependentRelationship}
            />
          </InputGroup>
        </InputWrapper>
      </div>
      <div className="col-12 col-md-6">
        <InputWrapper error={!!errors.dependentBirthdate}>
          <InputLabel htmlFor={'dependentBirthdate' + id}>Birth Date</InputLabel>
          <InputError>{safeAccess(errors, 'dependentBirthdate.detail')}</InputError>
          <InputGroup>
            <InputMask
              defaultValue={convertIsoToSlash(data.dependentBirthdate)}
              mask="##/##/####"
              name={'dependentBirthdate' + id}
              placeholder="MM/DD/YYYY"
            />
          </InputGroup>
        </InputWrapper>
      </div>
    </RowFade>
  </>
);

DependentForm.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.number.isRequired,
};

export default DependentForm;
