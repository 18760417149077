import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardText, CardTitle } from 'fiducius-ui';

import EmpCertProgress from '../components/emp-cert-progress';
import { mergeRequestStatuses } from '../../../utils';

const EmpCertSummary = ({ request }) => {
  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <Card brand="secondary">
          <CardBody>
            <CardText>
              <CardTitle>Emplify</CardTitle>
              <AsyncLoader request={request}>
                <EmpCertProgress removeContainer={true} />
              </AsyncLoader>
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

EmpCertSummary.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmpCertSummary);
