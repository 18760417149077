import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { getUserId } from '../../../auth';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
} from 'fiducius-ui';

import {
  EmploymentHistoryTable,
  employmentHistoryLoadCollection,
} from '../../../employment-history';
import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';
import { limitedWaiverOpportunityLoadResource } from '../redux/operations';

import QpPie from '../components/qualified-payment-pie';
import LwoOverviewModals from '../components/lwo-overview-modals';
import ResultsTable from '../components/results-table';
import LwoProgress from '../components/lwo-progress';

class LwoOverview extends React.Component {
  static propTypes = {
    data: t.object,
    employers: t.object,
    id: t.string.isRequired,
    load: t.func,
    loans: t.object,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalToOpen: null,
    };
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  openModal = (modalToOpen) => {
    this.setState({ modalToOpen: modalToOpen });
  };

  render() {
    const { data = {}, employers, permissions, request } = this.props;
    let currentLwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    return (
      <DashboardSection title="Overview">
        {currentLwoStep !== undefined && currentLwoStep.id !== 'Complete' && (
          <LwoProgress removeContainer={true} />
        )}
        <div className="row">
          <div className="col-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-12">
                    <CardTitle>My Summary</CardTitle>
                  </div>
                </div>
                <CardText>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        Using loan and employment information you provide as part of an initial
                        assessment, we calculate the estimated number of additional qualifying
                        payments you could expect to receive for each of your federal loans by
                        participating in the Limited Waiver Opportunity. If your estimate indicates
                        you could receive additional qualifying payments, then you'll want to use
                        our new Assure&#8480; solution. You can be confident you will recieve all
                        the additional qualifying payments and forgiveness you deserve.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <Button brand="primary" onClick={() => this.openModal('qpCalc')}>
                        How are the estimated qualifying payments calculated?
                      </Button>
                      <p />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <Button brand="primary" onClick={() => this.openModal('qpConfirm')}>
                        How will my additional qualifying payments be confirmed?
                      </Button>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </div>
          {currentLwoStep !== undefined &&
            ['WaitPslf', 'LoanInformation', 'Questionnaire'].includes(currentLwoStep.id) && (
              <div className="col-12 col-lg-6">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-12">
                        <CardTitle>Qualifying Payment Information</CardTitle>
                      </div>
                    </div>
                    <CardText>
                      <div className="row">
                        <div className="col-12">
                          <p>
                            You will see this information once you provide the required student loan
                            and employment details.
                          </p>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            )}

          {currentLwoStep !== undefined &&
            !['WaitPslf', 'LoanInformation', 'Questionnaire', 'Complete'].includes(
              currentLwoStep.id
            ) && (
              <div className="col-12 col-lg-6">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-12">
                        <CardTitle>Qualifying Payment Information</CardTitle>
                      </div>
                    </div>
                    <CardText>
                      <div className="row">
                        <div className="col-12">
                          <AsyncLoader request={request}>
                            <ResultsTable loans={data.eligibleLoans} />
                          </AsyncLoader>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            )}
          {currentLwoStep !== undefined && ['Complete'].includes(currentLwoStep.id) && (
            <>
              <div className="col-12 col-lg-6">
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-12">
                        <CardTitle>Estimated Qualifying Payment Information</CardTitle>
                      </div>
                    </div>
                    <CardText>
                      <div className="row">
                        <div className="col-12">
                          <AsyncLoader request={request}>
                            <ResultsTable loans={data.eligibleLoans} />
                          </AsyncLoader>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </>
          )}

          {permissions.inForgiveness && (
            <div className="col-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <Card>
                <CardBody>
                  <CardText>
                    <div className="row">
                      <div className="col-12">
                        <CardTitle>My Progress Towards Forgiveness</CardTitle>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p>
                          A total of 120 qualifying payments are required for you to receive loan
                          forgiveness. Since you're already working with Fiducius, we're able to
                          calculate how many more payments are needed before you reach 120
                          qualifying payments.
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {currentLwoStep !== undefined &&
                          !['WaitPslf', 'LoanInformation', 'Questionnaire'].includes(
                            currentLwoStep.id
                          ) && (
                            <AsyncLoader
                              request={request}
                              emptyMessage={'You have no Employers on file.'}
                            >
                              {/*TODO: Update data dynamically pay remaing = 120 - qp*/}
                              <EmploymentHistoryTable
                                employers={employers}
                                onEditEmployer={this.onEditEmployer}
                                onDeleteEmployer={this.onDeleteEmployer}
                                showDelete={false}
                                showEdit={false}
                              />
                              <QpPie
                                qualifiedPayments={data.qualifiedPayment / 1}
                                paymentsRemain={120 - data.qualifiedPayment / 1}
                                cutout={10}
                              />
                            </AsyncLoader>
                          )}
                        {currentLwoStep !== undefined &&
                          ['WaitPslf', 'LoanInformation', 'Questionnaire'].includes(
                            currentLwoStep.id
                          ) && (
                            <>
                              <p>
                                You will see this information once you provide the required student
                                loan and employment details.
                              </p>
                            </>
                          )}
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            </div>
          )}

          {currentLwoStep !== undefined && ['Complete'].includes(currentLwoStep.id) && (
            <div className="col-12 col-lg-6">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-12">
                      <CardTitle>Actual Qualifying Payment Information</CardTitle>
                    </div>
                  </div>
                  <CardText>
                    <div className="row">
                      <div className="col-12">
                        <AsyncLoader request={request}>
                          <ResultsTable loans={data.submittedLoans} showEstimated={false} />
                        </AsyncLoader>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            </div>
          )}
        </div>
        <LwoOverviewModals
          modalOption={this.state.modalToOpen}
          resetModalOption={() => this.openModal(null)}
        />
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const eHRequests = state.employmentHistory.requests;
  const lwoRequests = state.limitedWaiverOpportunity.requests;
  return {
    employers: state.employmentHistory.cache,
    id: getUserId(state),
    loans: state.limitedWaiverOpportunity.form.eligibleLoans,
    data: state.limitedWaiverOpportunity.cache[getUserId(state)],
    request: mergeRequestStatuses([eHRequests.loadCollection, lwoRequests.loadResource]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
    dispatch(employmentHistoryLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoOverview));
