import React, { useContext } from 'react';
import t from 'prop-types';
import { CardTitle } from 'fiducius-ui';
import { FocusButton } from './focus-button';
import Input from './input';
import { AppContext } from '../containers/plan-tool';

export const GeneralCard = ({ inputs, page }) => {
  const { state, onChange } = useContext(AppContext);
  const inputDict = Object.entries(inputs).filter((o) => o[1].width !== 'full');
  const fullDict = Object.entries(inputs).filter((o) => o[1].width === 'full');
  const half = Math.ceil(inputDict.length / 2);
  const left = inputDict.slice(0, half);
  const right = inputDict.slice(half);
  return (
    <div className="polaris-input-card full" key="General">
      <CardTitle>{page}</CardTitle>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%' }}>
          {fullDict.map(([k, v]) => (
            <Input
              {...v}
              key={`General-${k}`}
              name={k}
              hidden={v && v.hide && !!v.hide(state)}
              onChange={onChange(page)}
            />
          ))}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: 'calc(50% - 2rem)' }}>
          {left.map(([k, v]) => (
            <Input
              {...v}
              key={`General-${k}`}
              name={k}
              hidden={v && v.hide && !!v.hide(state)}
              onChange={onChange(page)}
            />
          ))}
        </div>
        <div style={{ width: 'calc(50% - 2rem)' }}>
          {right.map(([k, v]) => (
            <Input
              {...v}
              key={`General-${k}`}
              name={k}
              hidden={v && v.hide && !!v.hide(state)}
              onChange={onChange(page)}
            />
          ))}
        </div>
      </div>
      <FocusButton focus={page} />
    </div>
  );
};

GeneralCard.propTypes = {
  inputs: t.object.isRequired,
};
