import React from 'react';
import t from 'prop-types';
import { Card, CardBody, CardText, ChartBar } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils/format';

const TaxCompare = ({ jointly = 0, separately = 0, savings = 0 }) => (
  <Card>
    <CardBody>
      <CardText>
        <center>
          <strong>My Tax Analysis</strong>
          <br />
          <small>
            <i>(My Federal Income Tax + My Student Loan Payment)</i>
          </small>
          <br />
          Filing separately lowers my monthly payment <br /> and saves me {formatAsMoney(
            savings
          )}{' '}
          overall this year!
        </center>
        <ChartBar
          bars={[
            {
              title: '',
              data: [jointly, separately],
            },
          ]}
          config={{
            labels: ['Married, Filing Jointly', 'Married, Filing Separately'],
            legend: {
              display: false,
            },
            horizontal: true,
            formatter: formatAsMoney,
          }}
        />
      </CardText>
    </CardBody>
  </Card>
);

TaxCompare.propTypes = {
  jointly: t.number.isRequired,
  separately: t.number.isRequired,
  savings: t.number,
};

export default TaxCompare;
