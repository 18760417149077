import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Button,
  InputWrapper,
  InputCheckbox,
  InputLabel,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';
import { FlexBetween } from '../../../root';

import { safeAccess, debounce, mergeRequestStatuses } from '../../../utils';
import { renewalResultsLoadResource, renewalResultsUpdateResource } from '../../../renewal-results';
import DocsNeededForRSUpload from '../components/docs-upload-needed';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import { authLoadPermissions } from '../../../auth';
import { paymentSetTypeRenewal } from '../../../payment';

import {
  todosLoadCollection,
  todosUpdateResource,
  todosSendDenied,
  todosSendUpdate,
} from '../../../todos/redux/operations';

import { idrSaveComplete } from '../../../workflows/idr/redux/operations';
import StandardFax from './standard-fax';
import styled from 'styled-components';
const InputWrapper2 = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const TextLeft = styled.div`
  text-align: left;
`;

class RenewalConfirmation extends React.Component {
  static propTypes = {
    acknowledgeRsFeeCredits: t.func.isRequired,
    confirm: t.func.isRequired,
    idrConfirm: t.func.isRequired,
    confirmDenied: t.func.isRequired,
    confirmEmploymentHistory: t.func.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    stepId: t.number.isRequired,
    todos: t.array,
    setPaymentType: t.func.isRequired,
    additionalInfoNeeded: t.bool,
    handleChange: t.func,
    zeroBalance: t.bool,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = { showCheckbox: false, redirectURI: '', empCertDeniedModal: false };
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmDenied = this.onConfirmDenied.bind(this);
    this.goToCurrentStep = this.goToCurrentStep.bind(this);
    this.goToInfo = this.goToInfo.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  onConfirm = async () => {
    if (this.props.stepId === 257) {
      await this.props.idrConfirm(this.props.stepId);
      let path = this.getNextStepPath();
      window.location.href = path;
    } else if (this.props.stepId === 1606) {
      await this.props.confirmEmploymentHistory(this.props.stepId);
      this.goToCurrentStep();
    } else {
      await this.props.confirm(this.props.stepId);
      this.goToCurrentStep();
    }
  };

  onConfirmDenied = async () => {
    await this.props.confirmDenied(this.props.stepId);
    this.goToCurrentStep();
  };

  toggleEmpCertDeniedModal = () => {
    this.setState({ ...this.state, empCertDeniedModal: !this.state.empCertDeniedModal });
  };

  rsCreditProcess = async () => {
    await this.props.acknowledgeRsFeeCredits(this.props.id);
    this.goToCurrentStep();
  };

  goToInfo(e) {
    this.props.handleChange(e.type);
  }

  checkStepCompleted = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    let retVal = false;
    if (
      (permissions.forgivenessSteps !== undefined && permissions.forgivenessSteps !== null) ||
      (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null)
    ) {
      const forgivenessStep = permissions.forgivenessSteps
        ? permissions.forgivenessSteps.find((a) => a.id === stepMapping)
        : permissions.reducedIdrSteps.find((a) => a.id === stepMapping)
        ? permissions.reducedIdrSteps.find((a) => a.id === stepMapping)
        : permissions.reducedIdrSteps.find((a) => a.id === 'EmpCert');

      if (
        forgivenessStep !== undefined &&
        forgivenessStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        retVal = forgivenessStep.stepComplete;
        if (!retVal) {
          if (
            stepId === 252 ||
            stepId === 253 ||
            stepId === 254 ||
            stepId === 800 ||
            stepId === 810 ||
            stepId === 815 ||
            stepId === 820 ||
            stepId === 1601 ||
            stepId === 1605
          ) {
            const currentToDo = todos.find(
              (a) =>
                a.id === 252 ||
                a.id === 253 ||
                a.id === 254 ||
                a.id === 800 ||
                a.id === 810 ||
                a.id === 815 ||
                a.id === 820 ||
                a.id === 1601 ||
                a.id === 1605
            );
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
          } else if (stepId === 255 || stepId === 256 || stepId === 257) {
            const currentToDo = todos.find((a) => a.id === 255 || a.id === 256 || a.id === 257);
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
          } else if (stepId === 260 || stepId === 261) {
            const currentToDo = todos.find((a) => a.id === 260 || a.id === 261);
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
          }
        }
      }
    }

    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      const enterpriseEmpCertStep = permissions.enterpriseEmpcertSteps.find(
        (a) => a.id === stepMapping
      );
      if (
        enterpriseEmpCertStep !== undefined &&
        enterpriseEmpCertStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        retVal = enterpriseEmpCertStep.stepComplete;
        if (!retVal) {
          if (
            stepId === 800 ||
            stepId === 801 ||
            stepId === 805 ||
            stepId === 810 ||
            stepId === 815
          ) {
            const currentToDo = todos.find(
              (a) => a.id === 800 || a.id === 801 || a.id === 805 || a.id === 810 || a.id === 815
            );
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) > 0;
          }
        }
      }
    }

    return retVal;
  };

  getStepMapping = (id) => {
    let retVal = '';
    switch (id) {
      case 209:
        retVal = 'ProfileVerification';
        break;
      case 201:
        retVal = 'Questionnaire';
        break;
      case 202:
        retVal = 'DocsNeeded';
        break;
      case 203:
        retVal = 'Meeting';
        break;
      case 204:
      case 250:
        retVal = 'Fee';
        break;
      case 251:
        retVal = 'Forbearance';
        break;
      case 252:
      case 253:
      case 254:
        retVal = 'EmpCert';
        break;
      case 206:
      case 255:
      case 256:
      case 257:
        retVal = 'IDR';
        break;
      case 259:
        retVal = 'Extension';
        break;
      case 260:
      case 261:
        retVal = 'Contribution';
        break;
      case 351:
        retVal = 'NSLDSUploadNeeded';
        break;
      case 800:
        retVal = 'GenerateEnterpriseEmpcert';
        break;
      case 801:
      case 810:
        retVal = 'SubmitEnterpriseEmpcert';
        break;
      case 815:
        retVal = 'CheckEnterpriseEmpcert';
        break;
      case 820:
        retVal = 'CompleteEnterpriseEmpcert';
        break;
      case 1601:
        retVal = 'ProfileReducedIDR';
        break;
      case 1605:
        retVal = 'QuestionnaireReducedIDR';
        break;
      case 1606:
        retVal = 'EmpCertEmploymentInformation';
        break;
      default:
        retVal = 'Overview';
        break;
    }
    return retVal;
  };

  checkNotYetAtStep = () => {
    const { stepId, permissions, todos } = this.props;
    const stepMapping = this.getStepMapping(stepId);
    let retVal = false;

    if (
      (permissions.forgivenessSteps !== undefined && permissions.forgivenessSteps !== null) ||
      (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null)
    ) {
      //if StepID is 1601, or 1605, use step current, otherwise use 'EmpCert'
      const forgivenessStep = permissions.forgivenessSteps
        ? permissions.forgivenessSteps.find((a) => a.id === stepMapping)
        : permissions.reducedIdrSteps.find((a) => a.id === stepMapping)
        ? permissions.reducedIdrSteps.find((a) => a.id === stepMapping)
        : permissions.reducedIdrSteps.find((a) => a.id === 'EmpCert');
      const currentStep = permissions.forgivenessSteps
        ? permissions.forgivenessSteps.find((a) => a.stepCurrent)
        : permissions.reducedIdrSteps.find((a) => a.stepCurrent);

      if (
        forgivenessStep !== undefined &&
        forgivenessStep !== null &&
        currentStep !== undefined &&
        currentStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (forgivenessStep.id === currentStep.id) {
          if (stepId === 1601 || stepId === 1605) {
            const currentToDo = todos.find((a) => a.id === 1601 || a.id === 1605);
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
          } else if (
            stepId === 252 ||
            stepId === 253 ||
            stepId === 254 ||
            stepId === 800 ||
            stepId === 810 ||
            stepId === 815 ||
            stepId === 820
          ) {
            const currentToDo = todos.find(
              (a) =>
                a.id === 252 ||
                a.id === 253 ||
                a.id === 254 ||
                a.id === 800 ||
                a.id === 810 ||
                a.id === 815 ||
                a.id === 820
            );
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
          } else if (stepId === 255 || stepId === 256 || stepId === 257) {
            const currentToDo = todos.find((a) => a.id === 255 || a.id === 256 || a.id === 257);
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
          }
        } else if (forgivenessStep.id === 'IDR' && currentStep.id === 'EmpCert') {
          const currentToDo = todos.find(
            (a) =>
              a.id === 255 ||
              a.id === 256 ||
              a.id === 257 ||
              a.id === 800 ||
              a.id === 810 ||
              a.id === 815
          );
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else if (forgivenessStep.id === 'EmpCert' && currentStep.id === 'Fee') {
          const currentToDo = todos.find((a) => a.id === 252 || a.id === 253 || a.id === 254);
          retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
        } else {
          retVal = !forgivenessStep.stepComplete && !forgivenessStep.stepCurrent;
        }
      }
    }

    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      //enterpriseEmpcertSteps
      const enterpriseEmpcertStep = permissions.enterpriseEmpcertSteps.find(
        (a) => a.id === stepMapping
      );
      const currentStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);

      if (
        enterpriseEmpcertStep !== undefined &&
        enterpriseEmpcertStep !== null &&
        currentStep !== undefined &&
        currentStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (enterpriseEmpcertStep.id === currentStep.id) {
          if (
            stepId === 800 ||
            stepId === 805 ||
            stepId === 810 ||
            stepId == 815 ||
            stepId == 820
          ) {
            const currentToDo = todos.find(
              (a) => a.id === 800 || a.id === 805 || a.id === 810 || a.id === 815 || a.id === 820
            );
            retVal = parseInt(safeAccess(currentToDo, 'id', 0)) - parseInt(stepId) < 0;
          }
        } else {
          retVal = !enterpriseEmpcertStep.stepComplete && !enterpriseEmpcertStep.stepCurrent;
        }
      }
    }

    return retVal;
  };

  goToCurrentStep = () => {
    let path = this.getNextStepPath();
    this.setState({ ...this.state, redirectURI: path });
  };

  getNextStepPath = () => {
    const { permissions, todos } = this.props;
    let retVal = '/benefits/forgiveness/overview';
    if (
      (permissions.forgivenessSteps !== undefined && permissions.forgivenessSteps !== null) ||
      (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null)
    ) {
      const forgivenessSteps = permissions.forgivenessSteps
        ? permissions.forgivenessSteps.filter((a) => a.stepCurrent)
        : permissions.reducedIdrSteps.filter((a) => a.stepCurrent);
      let forgivenessStep = permissions.forgivenessSteps
        ? permissions.forgivenessSteps.find((a) => a.stepCurrent)
        : permissions.reducedIdrSteps.find((a) => a.stepCurrent);

      if (
        forgivenessSteps !== undefined &&
        forgivenessSteps !== null &&
        forgivenessSteps.length > 1 &&
        forgivenessSteps.find((a) => window.location.pathname.includes(a.stepPath))
      ) {
        forgivenessStep = forgivenessSteps.find((a) =>
          window.location.pathname.includes(a.stepPath)
        );
      }

      if (
        forgivenessStep !== undefined &&
        forgivenessStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (
          forgivenessStep.id === 'EmpCert' ||
          forgivenessStep.id === 'IDR' ||
          forgivenessStep.id === 'Extension'
        ) {
          if (permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null) {
            retVal = '/benefits/reducedidr' + forgivenessStep.stepPath;
          } else {
            retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
          }
          const currentToDo = todos.find(
            (a) =>
              (forgivenessStep.id === 'Extension' && a.id === 259) ||
              (forgivenessStep.id === 'EmpCert' &&
                (a.id === 106 ||
                  a.id === 107 ||
                  a.id === 108 ||
                  a.id === 252 ||
                  a.id === 253 ||
                  a.id === 254 ||
                  a.id === 254 ||
                  a.id === 800 ||
                  a.id === 805 ||
                  a.id === 810 ||
                  a.id == 815 ||
                  a.id == 820)) ||
              (forgivenessStep.id === 'IDR' &&
                (a.id === 111 ||
                  a.id === 112 ||
                  a.id === 113 ||
                  a.id === 255 ||
                  a.id === 256 ||
                  a.id === 257))
          );

          if (currentToDo !== undefined && currentToDo !== null) {
            switch (currentToDo.id) {
              case 252:
              case 255:
              case 800:
                retVal += '/step-1';
                break;
              case 253:
              case 256:
              case 810:
                retVal += '/step-2';
                break;
              case 254:
              case 257:
              case 815:
                retVal += '/step-3';
                break;
              default:
                break;
            }
          }
        } else if (
          forgivenessStep.id === 'QuestionnaireReducedIDR' ||
          forgivenessStep.id === 'ProfileReducedIDR' ||
          forgivenessStep.id === 'IDRReducedIDR' ||
          forgivenessStep.id === 'WelcomeReducedIDR'
        ) {
          retVal = '/benefits/reducedidr' + forgivenessStep.stepPath;
        } else if (forgivenessStep.id === 'EmpCertEmploymentInformation') {
          retVal = '/benefits/reducedidr/employmenthistory';
        } else {
          retVal = '/benefits/forgiveness' + forgivenessStep.stepPath;
        }
      }
    }

    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      let enterpriseEmpCertStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);
      if (
        enterpriseEmpCertStep !== undefined &&
        enterpriseEmpCertStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (enterpriseEmpCertStep.id == 'CompleteEnterpriseEmpcert') {
          retVal = '/benefits/empcert/complete';
        }

        const currentToDo = todos.find(
          (a) =>
            a.id === 800 ||
            a.id === 801 ||
            a.id === 805 ||
            a.id === 810 ||
            a.id === 815 ||
            a.id === 820
        );

        let isReducedIDR =
          permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null;

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 800:
              retVal = isReducedIDR
                ? '/benefits/reducedidr/empcert-ss/step-1'
                : '/benefits/empcert/generate';
              break;
            case 801:
            case 810:
              retVal = '/benefits/empcert/submit';
              break;
            case 805:
              retVal = '/benefits/empcert/correction';
              break;
            case 815:
              retVal = '/benefits/empcert/check';
              break;
            case 820:
              retVal = '/benefits/empcert/complete';
              break;
          }
        }
      }
    }
    return retVal;
  };

  checkLockedForLWO = (stepId) => {
    const { permissions, todos } = this.props;

    if (permissions.enrolledLwo && (stepId === 252 || stepId === 253)) {
      const lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

      if (lwoStep !== undefined && lwoStep !== null) {
        const currentToDo = todos.find((a) => a.id > 600 && a.id < 608);

        if (currentToDo !== undefined && currentToDo !== null) {
          return true;
        }
      }
    }
    return false;
  };

  needsDocumentsGenerated = () => {
    const { stepId } = this.props;
    let retVal = false;
    if (stepId === 252 || stepId === 255 || stepId === 800) {
      retVal = true;
    }
    return retVal;
  };

  redirectForDocumentGeneration = () => {
    const { stepId, permissions } = this.props;
    let retVal = '';
    let isReducedIDR =
      permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null;
    if (this.props.stepId === 113) {
      this.props.idrConfirm(this.props.stepId).then((a) => {
        if (stepId === 252) {
          if (permissions.electronicEmpcertEnabled) {
            retVal = '/benefits/forgiveness/empcert-ss/step-2';
          } else {
            retVal = '/documents';
          }
        } else if (stepId === 255) {
          retVal = '/idr';
        }
        this.setState({ ...this.state, redirectURI: retVal });
        //window.location.href = retVal;
      });
    } else if (stepId === 260) {
      retVal = '/documents';
      this.setState({ ...this.state, redirectURI: retVal });
    } else {
      this.props.confirm(this.props.stepId).then((a) => {
        if (stepId === 252) {
          if (permissions.electronicEmpcertEnabled) {
            retVal = '/benefits/forgiveness/empcert-ss/step-2';
          } else {
            retVal = '/documents';
          }
        } else if (stepId === 255) {
          retVal = '/idr';
        } else if (stepId === 800) {
          retVal = isReducedIDR
            ? '/benefits/reducedidr/empcert-ss/step-2'
            : '/benefits/empcert/generate';
        } else if (stepId === 815 && isReducedIDR) {
          retVal = '/benefits/reducedidr/empcert-ss';
        }
        this.setState({ ...this.state, redirectURI: retVal });
        //window.location.href = retVal;
      });
    }
  };

  redirectForDocumentUploadOrSign = () => {
    this.setState({ ...this.state, redirectURI: '/documents' });
  };

  redirectForProfileVerification = () => {
    this.setState({ ...this.state, redirectURI: '/profile' });
  };

  redirectForQuestionnaire = () => {
    this.setState({ ...this.state, redirectURI: '/renewal' });
  };

  redirectForRenewalPayment = () => {
    this.props.setPaymentType().then(() => {
      this.setState({ ...this.state, redirectURI: '/payment' });
    });
  };

  redirectForNSLDSUpload = () => {
    this.props.setPaymentType().then(() => {
      this.setState({ ...this.state, redirectURI: '/nslds' });
    });
  };

  redirectForLWO = () => {
    const { permissions, todos } = this.props;
    const lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);

    let retVal = '/benefits/limited-waiver-opportunity' + lwoStep.stepPath;

    if (lwoStep !== undefined && lwoStep !== null) {
      const currentToDo = todos.find((a) => a.id > 600 && a.id < 608);

      if (currentToDo !== undefined && currentToDo !== null) {
        switch (currentToDo.id) {
          case 606:
            retVal += '/step-1';
            break;
          case 607:
            retVal += '/step-2';
            break;
          default:
            break;
        }
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  getResources = (resourceType) => {
    return (
      <>
        <div className="row col-12">
          <div className="col-8">
            {resourceType === 'stepCompleted' && (
              <InputWrapper>
                <InputLabel2>You've completed this step.</InputLabel2>
              </InputWrapper>
            )}
            {resourceType === 'notAtStep' && (
              <InputWrapper>
                <InputLabel2>You haven't reached this step yet.</InputLabel2>
              </InputWrapper>
            )}
            {resourceType === 'documents' && (
              <InputWrapper2>
                <InputLabel2>You've completed this step.</InputLabel2>
              </InputWrapper2>
            )}
          </div>
          <div className="col-4">
            {(resourceType === 'stepCompleted' || resourceType === 'notAtStep') && (
              <InputWrapper>
                <Button brand="info" onClick={this.goToCurrentStep}>
                  {'Go to current step'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'generateDoc' && (
              <InputWrapper>
                <Button brand="success" onClick={this.redirectForDocumentGeneration}>
                  {'Generate my document(s)'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'documentUpload' && (
              <InputWrapper>
                <Button brand="success" onClick={this.redirectForDocumentUploadOrSign}>
                  {'Generate my document(s)'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'profileVerification' && (
              <InputWrapper>
                <Button brand="Primary" onClick={this.redirectForProfileVerification}>
                  {'Go to My Profile'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'questionnaire' && (
              <InputWrapper>
                <Button brand="primary" onClick={this.redirectForQuestionnaire}>
                  {'Go to Renewal Questionnaire'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'renewalPayment' && (
              <InputWrapper>
                <Button brand="primary" onClick={this.redirectForRenewalPayment}>
                  {'Pay Renewal Fee'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'renewalCredit' && (
              <InputWrapper>
                <Button brand="primary" onClick={this.rsCreditProcess}>
                  {'Acknowledge'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'documents' && (
              <InputWrapper>
                <Button brand="info" onClick={this.goToCurrentStep}>
                  {'Go to current step'}
                </Button>
              </InputWrapper>
            )}
            {resourceType === 'nsldsupload' && (
              <InputWrapper>
                <Button brand="info" onClick={this.redirectForNSLDSUpload}>
                  {'Go to current step'}
                </Button>
              </InputWrapper>
            )}
          </div>
        </div>
      </>
    );
  };

  handleChange = debounce((formState, id, attributes) => {
    this.setState({ ...this.state, showCheckbox: !this.state.showCheckbox });
  }, 250);

  render() {
    const { stepId } = this.props;

    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      if (this.checkNotYetAtStep()) {
        return (
          <AsyncLoader
            request={this.props.request}
            empty={this.props.request.isEmpty ? this.getResources('notAtStep') : null}
          >
            <div className="row col-12">
              <div className="col-8">
                <InputWrapper>
                  <InputLabel>You haven't reached this step yet.</InputLabel>
                </InputWrapper>
              </div>
              <div className="col-4">
                <InputWrapper>
                  <Button brand="info" onClick={this.goToCurrentStep}>
                    {'Go to current step'}
                  </Button>
                </InputWrapper>
              </div>
            </div>
          </AsyncLoader>
        );
      } else if (this.checkStepCompleted()) {
        return (
          <AsyncLoader
            request={this.props.request}
            empty={this.props.request.isEmpty ? this.getResources('stepCompleted') : null}
          >
            <div className="row col-12">
              <div className="col-8">
                <InputWrapper>
                  <InputLabel2>You've completed this step.</InputLabel2>
                </InputWrapper>
              </div>
              <div className="col-4">
                <InputWrapper>
                  <Button brand="info" onClick={this.goToCurrentStep}>
                    {'Go to current step'}
                  </Button>
                </InputWrapper>
              </div>
            </div>
          </AsyncLoader>
        );
      } else if (this.needsDocumentsGenerated()) {
        if (this.checkLockedForLWO(stepId)) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('generateDoc') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectForLWO}>
                      Go to Assure&#8480;
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('generateDoc') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="success" onClick={this.redirectForDocumentGeneration}>
                      {'Generate my document(s)'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        }
      } else {
        if (stepId === 209 || stepId === 1601) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('profileVerification') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectForProfileVerification}>
                      {'Go to My Profile'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else if (stepId === 202) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('documents') : null}
            >
              <DocsNeededForRSUpload />

              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="success" onClick={this.redirectForDocumentUploadOrSign}>
                      {'Upload Documents'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else if (stepId === 201 || stepId === 1605) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('questionnaire') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectForQuestionnaire}>
                      {'Go to Renewal Questionnaire'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else if ((stepId === 204 || stepId === 250) && this.props.zeroBalance === false) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('renewalPayment') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectForRenewalPayment}>
                      {'Pay Renewal Fee'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else if (stepId === 250 && this.props.zeroBalance === true) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('renewalCredit') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.rsCreditProcess}>
                      {'Acknowledge'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else if (stepId === 260) {
          return (
            <AsyncLoader
              request={this.props.request}
              empty={this.props.request.isEmpty ? this.getResources('documentUpload') : null}
            >
              <div className="row col-12">
                <div className="col-8"></div>
                <div className="col-4">
                  <InputWrapper>
                    <Button brand="primary" onClick={this.redirectForDocumentUploadOrSign}>
                      {'Repayment Details Needed'}
                    </Button>
                  </InputWrapper>
                </div>
              </div>
            </AsyncLoader>
          );
        } else {
          if (
            stepId === 253 ||
            stepId === 256 ||
            stepId === 801 ||
            stepId == 805 ||
            stepId === 810
          ) {
            if (this.checkLockedForLWO(stepId)) {
              return (
                <AsyncLoader
                  request={this.props.request}
                  empty={this.props.request.isEmpty ? this.getResources('generateDoc') : null}
                >
                  <div className="row col-12">
                    <div className="col-8"></div>
                    <div className="col-4">
                      <InputWrapper>
                        <Button brand="primary" onClick={this.redirectForLWO}>
                          Go to Assure&#8480;
                        </Button>
                      </InputWrapper>
                    </div>
                  </div>
                </AsyncLoader>
              );
            } else {
              return <StandardFax stepId={stepId} />;
            }
          } else if (stepId === 351) {
            return (
              <AsyncLoader
                request={this.props.request}
                empty={this.props.request.isEmpty ? this.getResources('nsldsupload') : null}
              >
                <div className="row col-12">
                  <div className="col-8"></div>
                  <div className="col-4">
                    <InputWrapper>
                      <Button brand="primary" onClick={this.redirectForNSLDSUpload}>
                        {'Upload NSLDS'}
                      </Button>
                    </InputWrapper>
                  </div>
                </div>
              </AsyncLoader>
            );
          } else {
            return (
              <>
                <Modal ref={this.modalRef} isOpen={this.state.empCertDeniedModal} fullWidth={false}>
                  <Card>
                    <CardHeader>Confirm Employment Certification Denied</CardHeader>
                    <CardBody>
                      <TextLeft>
                        <p>
                          By selecting Confirm, you are confirming your employment certification
                          form was denied and a member of your Fiducius team will be contact within
                          3 business days to address the issue.
                        </p>
                      </TextLeft>
                    </CardBody>
                    <CardFooter>
                      <FlexBetween>
                        <Button brand="lowContrast" onClick={this.toggleEmpCertDeniedModal}>
                          Cancel
                        </Button>
                        <Button brand="danger" onClick={this.onConfirmDenied}>
                          {'Confirm'}
                        </Button>
                      </FlexBetween>
                    </CardFooter>
                  </Card>
                </Modal>
                <AsyncLoader request={this.props.request}>
                  <div className="row col-12">
                    <div className="col-12">
                      <InputWrapper2>
                        <InputCheckbox
                          name="allowConfirmation"
                          label="I am ready to confirm that I have followed all of the above steps and am ready to continue."
                          onChange={(e) =>
                            this.handleChange(
                              { allowConfirmation: e.target.value },
                              'allowConfirmation',
                              'allowConfirmation'
                            )
                          }
                        ></InputCheckbox>
                      </InputWrapper2>
                    </div>
                  </div>
                  <div className="row col-12">
                    <div className="col-8">
                      {stepId === 815 && (
                        <Button brand="danger" onClick={this.toggleEmpCertDeniedModal}>
                          {'Employment Certification Denied'}
                        </Button>
                      )}
                    </div>
                    <div className="col-4">
                      <InputWrapper>
                        {this.props.additionalInfoNeeded === false ||
                        this.props.additionalInfoNeeded === undefined ? (
                          <Button
                            id={'confirm'}
                            brand={'primary'}
                            onClick={this.onConfirm}
                            disabled={!this.state.showCheckbox}
                          >
                            {'Confirm'}
                          </Button>
                        ) : (
                          <Button brand={'primary'} id={'update'} onClick={this.goToInfo}>
                            Update Missing Step Info
                          </Button>
                        )}
                      </InputWrapper>
                    </div>
                  </div>
                </AsyncLoader>
              </>
            );
          }
        }
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
      state.payment.requests.loadResource,
      state.renewalResults.requests.loadResource,
      state.renewalResults.requests.updateResource,
    ]),
    todos: Object.values(state.todos.cache),
  };
};

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    await dispatch(todosUpdateResource(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  idrConfirm: async (id) => {
    await dispatch(idrSaveComplete(id));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  acknowledgeRsFeeCredits: async (id) => {
    await dispatch(renewalResultsUpdateResource(id));
    dispatch(authLoadPermissions());
    dispatch(renewalResultsLoadResource(id));
    await dispatch(todosLoadCollection());
  },
  setPaymentType: async () => await dispatch(paymentSetTypeRenewal()),
  confirmDenied: async (id) => {
    await dispatch(todosSendDenied(816));
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
  confirmEmploymentHistory: async (id) => {
    await dispatch(todosSendUpdate(1606));
    await dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(RenewalConfirmation));
