import React from 'react';
import t from 'prop-types';
import { startCase } from '../../utils';
import { Button, Table, Card, CardBody, CardText, Header2 } from 'fiducius-ui';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { relationTypes } from '../redux/validators';
import styled from 'styled-components';

const Empty = styled.div`
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
`;

const EmptyReferences = () => (
  <Empty>
    <Header2>No saved references</Header2>
  </Empty>
);

const ReferenceTable = ({ references = {}, onEditReference }) => {
  if (Object.keys(references).length === 0) {
    return (
      <>
        <EmptyReferences />
        <Card>
          <CardBody>
            <CardText>
              <ul>
                <li>
                  You must provide two references with name, address, phone number and relationship.
                </li>
                <li>Your references cannot have the same address or phone number as you.</li>
                <li>Your references cannot have the same address or phone number as each other.</li>
              </ul>
            </CardText>
          </CardBody>
        </Card>
      </>
    );
  }

  const headers = ['id', 'firstName', 'lastName', 'relationType', 'edit'];

  let body = Object.values(references).map((r) => headers.map((h) => r[h]));

  headers[1] = 'name';
  delete headers[2];
  delete headers[0];

  body.forEach((row) => {
    const id = row[0];
    row[1] = row[1] + ' ' + row[2];
    row[3] = relationTypes[row[3]];
    row[4] = (
      <Button invert brand="secondary" onClick={() => onEditReference(id)}>
        <FontAwesomeIcon icon={faEdit} fixedWidth />
      </Button>
    );
    delete row[2];
    delete row[0];
  });

  return (
    <Card>
      <Table
        body={body}
        columns={[{}, { sortable: true }, {}, { sortable: true }, { width: '10%' }]}
        header={headers.map((h) => startCase(h))}
      />
      <CardBody>
        <CardText>
          <ul>
            <li>
              You must provide two references with name, address, phone number and relationship.
            </li>
            <li>Your references cannot have the same address or phone number as you.</li>
            <li>Your references cannot have the same address or phone number as each other.</li>
          </ul>
        </CardText>
      </CardBody>
    </Card>
  );
};

ReferenceTable.propTypes = {
  references: t.object,
  onEditReference: t.func.isRequired,
};

export default ReferenceTable;
