import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Header3 } from 'fiducius-ui';

import TodoList from '../components/todo-list';
import { todosDisableValidation, todosLoadCollection } from '../redux/operations';

const StyledHeader3 = styled(Header3)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textTertiary : 'inherit')};
`;

const Container = styled.div`
  padding: 1em;
`;

class MyTodos extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    loadTodos: t.func.isRequired,
    todos: t.array,
    toggleNavigation: t.func.isRequired,
  };

  componentDidMount() {
    this.props.loadTodos();
  }

  render() {
    return (
      <Container>
        <StyledHeader3>My To-do List</StyledHeader3>
        <TodoList {...this.props} />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  todos: Object.values(state.todos.cache),
  actionRequest: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch) => ({
  loadTodos: async () => {
    await dispatch(todosDisableValidation());
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTodos);
