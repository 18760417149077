import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import LoaderWrapper from '../../auth/styles/loader-wrapper';
import {
  Button,
  Card,
  CardBody,
  DashboardSection,
  InputHelp,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Loader,
} from 'fiducius-ui';
import { Fade } from '../../routing';

import { authLogout, isAuthenticated } from '../../auth';
import { getUserId } from '../../auth';
import { getPasswordData } from '../redux/selectors';

import { debounce, mergeRequestStatuses, flatten } from '../../utils';
import {
  passwordauthLoadResource,
  passwordauthHandleFormChange,
  passwordauthUpdateResource,
} from '../redux/operations';

import PasswordText from '../components/password-text';
import NewPasswordText from '../components/new-password-text';
import PasswordResult from '../components/password-result';

class PasswordAuth extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    auth: t.object,
    data: t.object,
    errors: t.object,
    form: t.object,
    id: t.string,
    loadData: t.func.isRequired,
    isAuthenticated: t.bool.isRequired,
    isProcessing: t.bool.isRequired,
    request: t.object.isRequired,
    reload: t.func.isRequired,
    savePasswordData: t.func.isRequired,
    signOut: t.func.isRequired,
    updateFormState: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: '',
      oldPassword: '',
      password: '',
      username: '',
      step: 1,
    };

    this.handleUsername = this.handleUsername.bind(this);
  }

  handleUsername(event) {
    this.setState({ username: event.target.value });
  }

  componentDidMount() {
    const { isAuthenticated, signOut, auth, id, loadData } = this.props;
    loadData(id);
    if (!isAuthenticated) {
      signOut(auth.username);
    }
  }

  handleChange = debounce((formState) => {
    //##Variables## currentPassword, newPassword, confirmNewPassword
    const { data, updateFormState } = this.props;

    if (formState.currentPassword !== undefined) {
      this.setState({
        username: this.state.username,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
        oldPassword: formState.currentPassword,
      });
    }

    if (formState.newPassword !== undefined) {
      this.setState({
        username: this.state.username,
        oldPassword: this.state.oldPassword,
        confirmPassword: this.state.confirmPassword,
        password: formState.newPassword,
      });
    }

    if (formState.confirmPassword !== undefined) {
      this.setState({
        username: this.state.username,
        oldPassword: this.state.oldPassword,
        confirmPassword: formState.confirmPassword,
        password: this.state.password,
      });
    }

    /*for(let [key, value] of Object.entries(this.state)) {
      alert(`${key}: ${value}`);
    }*/
    const newData = { ...flatten(data), ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 150);

  enableSubmit = () => {
    const { data = {}, errors } = this.props;
    let retval = false;

    if (data.username !== undefined && data.username !== null) {
      if (
        data.username.toUpperCase() === this.state.username.toUpperCase() &&
        this.state.password !== '' &&
        this.state.currentPassword !== '' &&
        this.state.username !== '' &&
        this.state.oldPassword !== '' &&
        this.state.password === this.state.confirmPassword &&
        this.state.password !== this.state.oldPassword &&
        (!errors.newPassword || errors.newPassword.detail === undefined)
      ) {
        retval = true;
      }
    }
    return retval;
  };

  save = () => {
    const { data, savePasswordData, id, updateFormState } = this.props;
    updateFormState(this.state, Object.keys(this.state));
    data.requestStatus = 'failed';
    this.setState({ step: 3 });
    savePasswordData(id).then((data) => {
      this.setState({ step: 2 });
    });
  };

  stepBack = () => {
    this.setState({
      step: 1,
      confirmPassword: '',
      oldPassword: '',
      password: '',
    });
  };

  render() {
    const { data, errors } = this.props;

    let MatchStatus = '';
    if (this.state.password !== this.state.confirmPassword) {
      MatchStatus = 'New passwords do not match';
    }
    /*for(let [key, value] of Object.entries(data)) {
      alert(`${key}: ${value}`);
    };*/
    return (
      <DashboardSection>
        <div className="col-12 col-lg-8 col-xl-6">
          <Card>
            <CardBody>
              {this.state.step === 1 && (
                <div>
                  <InputWrapper>
                    <InputLabel>Username</InputLabel>
                    <InputGroup>
                      <InputText
                        name={'username'}
                        type={'text'}
                        autoComplete="username"
                        placeholder={'username/email'}
                        onChange={this.handleUsername}
                      />
                    </InputGroup>
                  </InputWrapper>

                  <PasswordText handleChange={this.handleChange} />
                  <NewPasswordText handleChange={this.handleChange} errors={errors} />
                  <InputHelp>{MatchStatus}</InputHelp>
                </div>
              )}
              {this.state.step === 2 && <PasswordResult status={data.requestStatus} />}
              {this.state.step === 3 && (
                <LoaderWrapper>
                  <Loader variant="atom" size={5} />
                </LoaderWrapper>
              )}
            </CardBody>
            {this.state.step === 1 && (
              <div className="col-10 col-lg-10 col-xl-8">
                <Fade show={this.enableSubmit()}>
                  <InputWrapper>
                    <Button brand="primary" onClick={this.save}>
                      Save
                    </Button>
                  </InputWrapper>
                </Fade>
                <Fade show={!this.enableSubmit()}>
                  <InputWrapper>
                    <Button brand="primary">Save</Button>
                    <InputHelp>There is incorrect or missing information.</InputHelp>
                  </InputWrapper>
                </Fade>
              </div>
            )}

            {this.state.step === 2 && (
              <InputWrapper>
                <Button brand="primary" onClick={this.stepBack}>
                  Back
                </Button>
              </InputWrapper>
            )}
          </Card>
        </div>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors, requests, form } = state.passwordauth;
  return {
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    isAuthenticated: isAuthenticated(state),
    auth: state.auth.cache,
    data: getPasswordData(state),
    errors: errors,
    form: form,
    id: getUserId(state),
    request: state.passwordauth.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadData: async (id) => await dispatch(passwordauthLoadResource(id)),
  updateFormState: (data, attributes) => dispatch(passwordauthHandleFormChange(data, attributes)),
  savePasswordData: async (id) => await dispatch(passwordauthUpdateResource(id)),
  signOut: (username) => dispatch(authLogout(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordAuth);
