import React from 'react';
import t from 'prop-types';
import { Alert, AsyncLoader } from 'fiducius-ui';
import { connect } from 'react-redux';

import { safeAccess } from '../../utils';

import { paymentGetPaymentStatus, paymentResetStatus } from '../redux/operations';

class PaymentResult extends React.Component {
  static propTypes = {
    getPaymentStatus: t.func.isRequired,
    resetPaymentStatus: t.func.isRequired,
    request: t.object,
    status: t.string,
    token: t.string,
    serviceId: t.number,
  };

  componentDidMount() {
    if (this.props.serviceId && this.props.serviceId > 0) {
      this.props.getPaymentStatus(this.props.token, this.props.serviceId);
    } else {
      this.props.getPaymentStatus(this.props.token, null);
    }
    this.props.resetPaymentStatus();
  }

  getMessage = () => {
    switch (this.props.status) {
      case 'success':
        return 'Your payment has succeeded';
      default:
        return 'There was an error processing your payment';
    }
  };

  render() {
    return (
      <AsyncLoader request={this.props.request}>
        <Alert brand={this.props.status === 'success' ? 'success' : 'danger'}>
          {this.getMessage()}
        </Alert>
      </AsyncLoader>
    );
  }
}

const mapStateToProps = (state) => ({
  status: safeAccess(state, 'payment.cache.paymentStatus', ''),
  request: safeAccess(state, 'payment.requests.loadResource'),
  serviceId: safeAccess(state, 'payment.cache.paymentServiceId', 0),
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentStatus: (token, serviceId) => dispatch(paymentGetPaymentStatus(token, serviceId)),
  resetPaymentStatus: () => dispatch(paymentResetStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentResult);
