import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { Card, CardBody, CardText, CardTitle, ChartPie } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils/format';

const StyledRow = styled.div`
  margin-top: 1em;
`;

const RepaymentPie = ({ repay, forgive, cutout = 0, bigTitle = false }) => (
  <Card>
    <CardBody>
      {bigTitle && <CardTitle>My Repayment Breakdown</CardTitle>}
      <CardText>
        {!bigTitle && (
          <center>
            <strong>My Repayment Breakdown</strong>
          </center>
        )}
        <StyledRow className="row">
          <div className="col-12">
            <ChartPie
              pie={{ data: [forgive, repay] }}
              config={{
                labels: ['My Forgiveness', 'My IDR Repayment'],
                cutout: cutout,
                formatter: formatAsMoney,
              }}
            />
          </div>
        </StyledRow>
      </CardText>
    </CardBody>
  </Card>
);

RepaymentPie.propTypes = {
  repay: t.number,
  forgive: t.number,
  cutout: t.number,
  bigTitle: t.bool,
};

export default RepaymentPie;
