import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import {
  Table,
  Modal,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardText,
  Button,
} from 'fiducius-ui';

import { formatAsMoney, startCase, convertIsoToSlash } from '../../utils';
import {
  convertMilestonePaymentCodeToDescription,
  convertMilestoneAccruementTypeCodeToDescription,
} from '../redux/selectors';

const AlignLeft = styled.div`
  text-align: left;
  margin-top: 1em;
`;

class AvailableMilestonesTable extends React.Component {
  static propTypes = {
    data: t.object,
    closeForm: t.func.isRequired,
    isOpen: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.modalRef = React.createRef();
    if (this.modalRef.current !== null) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  getTable() {
    const { data } = this.props;
    if (Object.keys(data).length === 0) {
      return null;
    }

    const headers = [
      'dateAccrued',
      'milestoneAccruementType',
      'milestoneTypeAmount',
      'milestonePaymentType',
      'milestonePaymentAmount',
    ];

    let body = Object.values(data).map((a) => headers.map((h) => a[h]));

    headers[1] = 'accruementType';
    headers[2] = 'accruementAmount';
    headers[3] = 'paymentType';
    headers[4] = 'amount';

    return (
      <Table
        body={body}
        columns={[
          { sortable: false, renderer: convertIsoToSlash },
          { sortable: false, renderer: convertMilestoneAccruementTypeCodeToDescription },
          {},
          { sortable: false, renderer: convertMilestonePaymentCodeToDescription },
          { sortable: false, renderer: formatAsMoney },
        ]}
        header={headers.map((h) => startCase(h))}
      />
    );
  }

  render() {
    const { closeForm, isOpen } = this.props;
    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
        <Card>
          <CardHeader>Milestones</CardHeader>
          <CardBody>
            {this.getTable()}
            <div className="row">
              <AlignLeft className="col-12">
                <CardText>
                  Note: If a milestone does not have an accrued date, that milestone has not been
                  met.
                </CardText>
              </AlignLeft>
            </div>
          </CardBody>
          <CardFooter>
            <Button brand="lowContrast" onClick={closeForm}>
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default AvailableMilestonesTable;
