import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';

import { implementationResultsLoadResource } from '../../../implementation-results';

class IDRFS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    permissions: t.object.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  render() {
    const { data, request, permissions } = this.props;
    return (
      <div>
        <ForgivenessProgress />
        <AsyncLoader request={request}>
          {data.repaymentAppSigned === false && (
            <DashboardSection>
              <Card>
                <Card>
                  <CardBody>
                    <CardTitle>Step 1 - Sign Your Application</CardTitle>
                    <CardText>
                      <ul>
                        <li>
                          Click on “Income-Driven Repayment Application" in My Documents section of
                          your portal.
                        </li>
                        <li>Complete the short questionnaire.</li>
                        <li>Electronically sign the application.</li>
                        <li>
                          Verify your Social Security number is present on the first six pages of
                          your application prior to signing.
                        </li>
                      </ul>
                    </CardText>
                  </CardBody>
                </Card>
              </Card>
            </DashboardSection>
          )}
          {data.repaymentAppSigned === true && (
            <DashboardSection>
              <Card>
                <Card>
                  <CardBody>
                    <CardTitle>Step 2 – Income Driven Repayment Application Processing</CardTitle>
                    <CardText>
                      <ul>
                        <li>
                          We are evaluating the form that was provided and will submit it to the
                          loan servicer.
                        </li>
                        <li>
                          The loan servicer will process your documentation to obtain your lowered,
                          monthly student loan payment.
                        </li>
                        <li>
                          We will notify you once the correct amount is in place and ready to be
                          paid.
                        </li>
                        <li>Once we confirm the correct amount is implemented:</li>
                        <ul>
                          <li>Payments must be paid in the exact amount due.</li>
                          <li>
                            Payments must be made on time or up to 15 days after the due date.
                          </li>
                          {!permissions.showContribution && (
                            <li>
                              You may set-up automatic payments through your bank (recommended) or
                              your loan servicer.
                            </li>
                          )}
                          {permissions.showContribution && (
                            <li>
                              If your student loan payment is greater than your employer's
                              contribution, your portion of the payment will be automatically
                              updated as a payroll deduction to ensure that the full payment is met
                              each month.
                            </li>
                          )}
                        </ul>
                      </ul>
                    </CardText>
                  </CardBody>
                </Card>
              </Card>
            </DashboardSection>
          )}
        </AsyncLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `implementationResults.cache[${getUserId(state)}]`) || {},
    request: state.implementationResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(implementationResultsLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(IDRFS));
