import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { BrandColor, Card, CardBody, CardText, Header3, Header5, Header6 } from 'fiducius-ui';

import { formatAsMoney } from '../../utils';

const InstallmentCard = styled(Card)`
  box-shadow: ${(p) => p.theme.boxShadow};
`;

const Installment = ({ amount, title = null, dueDate, index, onlyPayment }) => (
  <InstallmentCard>
    <CardBody>
      <CardText>
        <Header5>{title ? <>{title}</> : <>Payment{onlyPayment ? '' : ` ${index + 1}`}</>}</Header5>
        <Header3>
          <BrandColor brand="success">{` ${formatAsMoney(amount)}`}</BrandColor>
        </Header3>
        <Header6>
          Due:{' '}
          {format(parseISO(dueDate), 'MM/dd/yyyy') === format(new Date(), 'MM/dd/yyyy') ? (
            <BrandColor brand="danger">Today</BrandColor>
          ) : (
            format(parseISO(dueDate), 'MM/dd/yyyy')
          )}
        </Header6>
      </CardText>
    </CardBody>
  </InstallmentCard>
);

Installment.propTypes = {
  amount: t.number.isRequired,
  dueDate: t.string.isRequired,
  index: t.number.isRequired,
  onlyPayment: t.bool.isRequired,
  title: t.string,
};

export default Installment;
