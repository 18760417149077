import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Animation,
  Form,
  Header2,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputRadio,
  InputSelect,
  InputText,
  InputWrapper,
  fade,
} from 'fiducius-ui';

import { useFocusOnMount, useScrollToTopOnMount, safeAccess } from '../../../utils';

const RowFade = styled.div`
  ${fade};
`;

const TabEducation = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  const firstInput = useFocusOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your education</Header2>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.highestCompletedDegree}>
            <InputLabel htmlFor="highestCompletedDegree">Highest Completed Degree</InputLabel>
            <InputError>{safeAccess(errors, 'highestCompletedDegree.detail')}</InputError>
            <InputGroup>
              <InputSelect
                inputRef={firstInput}
                name="highestCompletedDegree"
                defaultValue={data.highestCompletedDegree}
                options={{
                  '': '',
                  NONE: 'None',
                  ASSOC: 'Associates',
                  BA: 'Bachelors',
                  MA: 'Masters',
                  PHD: 'Doctorate',
                }}
              />
            </InputGroup>
          </InputWrapper>
          <Animation
            key="hasADoctorate"
            animation="fade"
            appear={false}
            in={data.degree === 'PHD'}
            unmountOnExit
          >
            <InputWrapper error={!!errors.doctorateType}>
              <InputLabel htmlFor="doctorateType">Doctorate Type</InputLabel>
              <InputError>{safeAccess(errors, 'doctorateType.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  name="doctorateType"
                  defaultValue={data.doctorateType}
                  options={{
                    '': '',
                    MD: 'MD',
                    DO: 'DO',
                    DDM: 'DDM',
                    DDS: 'DDS',
                    DVM: 'DVM',
                    VMD: 'VMD',
                    Other: 'Other',
                  }}
                />
              </InputGroup>
            </InputWrapper>
          </Animation>
        </div>
      </div>
      <InputWrapper error={!!errors.continuingEducation}>
        <InputLabel>
          Are you in school or planning on going back to school in the next year?
        </InputLabel>
        <InputHelp>
          Please note: This does not include Certifications or Certificates that are required for
          your current occupation.
        </InputHelp>
        <InputError>{safeAccess(errors, 'continuingEducation.detail')}</InputError>
        <InputRadio
          name="continuingEducation"
          value="Y"
          label="Yes"
          defaultChecked={data.continuingEducation === 'Y'}
        />
        <InputRadio
          name="continuingEducation"
          value="N"
          label="No"
          defaultChecked={data.continuingEducation === 'N'}
        />
      </InputWrapper>
      <Animation
        key="continuedEducationExtraDegree"
        animation="fade"
        appear={false}
        in={data.continuingEducation === 'Y'}
        unmountOnExit
      >
        <RowFade className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.degreeSought}>
              <InputLabel htmlFor="degreeSought">Degree Sought</InputLabel>
              <InputError>{safeAccess(errors, 'degreeSought.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  name="degreeSought"
                  defaultValue={data.degreeSought}
                  options={{
                    '': '',
                    ASSOC: 'Associates',
                    BA: 'Bachelors',
                    MA: 'Masters',
                    PHD: 'Doctorate',
                  }}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </RowFade>
      </Animation>
      <Animation
        key="continuedEducationExtraSchool"
        animation="fade"
        appear={false}
        in={data.continuingEducation === 'Y'}
        unmountOnExit
      >
        <RowFade className="row">
          <div className="col-12">
            <InputWrapper error={!!errors.school}>
              <InputLabel htmlFor="school">School</InputLabel>
              <InputError>{safeAccess(errors, 'school.detail')}</InputError>
              <InputGroup affordance="large">
                <InputText name="school" defaultValue={data.school} />
              </InputGroup>
            </InputWrapper>
          </div>
        </RowFade>
      </Animation>
    </Form>
  );
};

TabEducation.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabEducation;
