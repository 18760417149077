import React from 'react';
import { Card, CardBody, CardFooter, CardText, Header1, Link } from 'fiducius-ui';

import { FullscreenContainer } from '../../../routing';

import AgreementWorkflow from '../components/agreement-workflow';

const LwoAgreement = () => (
  <FullscreenContainer className="container">
    <div className="row">
      <div className="col-12">
        <Card>
          <CardBody>
            <Header1>Assure&#8480; Agreement</Header1>
            <CardText>
              <AgreementWorkflow />
            </CardText>
          </CardBody>
          <CardFooter>
            <Link to="/dashboard">Return home</Link>
          </CardFooter>
        </Card>
      </div>
    </div>
  </FullscreenContainer>
);

export default LwoAgreement;
