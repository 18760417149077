import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CardBody, CardHeader, Loader, Header1 } from 'fiducius-ui';
import { Link, Redirect, withRouter } from 'react-router-dom';

import { ShadowAddCard } from '../../routing';
import { persistRememberMe } from '../../persist';
import { safeAccess } from '../../utils';

import SignInForm from '../components/sign-in-form';
import FormSwitcher from '../styles/form-switcher';

import LoaderWrapper from '../styles/loader-wrapper';
import { isAuthenticated, passwordNeedsReset, authGetRedirectPath } from '../redux/selectors';
import { authClientLogin, authThemePreference } from '../redux/operations';

const FinePrint = styled.div`
  font-size: calc(1rem);
  && > a {
    font-weight: ${(p) => p.theme.fontWeightNormal};
    color: ${(p) => p.theme.textPrimary};
  }
`;

class Login extends React.Component {
  static propTypes = {
    domain: t.string.isRequired,
    hasFailed: t.bool.isRequired,
    isAuthenticated: t.bool.isRequired,
    permissions: t.object,
    isLoading: t.bool.isRequired,
    location: t.object.isRequired,
    rememberMe: t.bool.isRequired,
    signIn: t.func.isRequired,
    clientStatus: t.string,
    updateRememberMe: t.func.isRequired,
    passwordNeedsReset: t.bool,
    theme: t.object.isRequired,
    getThemePreference: t.func.isRequired,
    changeTheme: t.func.isRequired,
    getRedirectPath: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      referrer: this.wasReferred(props.location),
      rememberMe: props.rememberMe,
      username: null,
      password: null,
    };
  }

  componentWillMount() {}

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rememberMe !== prevState.rememberMe) {
      this.props.updateRememberMe(this.state.rememberMe);
    }
  }

  getForm() {
    const { isLoading, rememberMe } = this.props;
    if (isLoading) {
      return (
        <LoaderWrapper>
          <Loader variant="atom" size={3} />
        </LoaderWrapper>
      );
    } else {
      return (
        <SignInForm
          rememberMe={rememberMe}
          onSubmit={this.login}
          handleChange={this.handleChange}
        />
      );
    }
  }

  handleChange = (formState) => {
    this.setState({ ...this.state, ...formState });
  };

  login = (e) => {
    e.preventDefault();
    const { username, password, rememberMe } = this.state;
    this.props.signIn(username, password, rememberMe);
  };

  wasReferred = (location) => safeAccess(location, 'state.from.pathname', '');

  render() {
    // Just redirect if we're logged in
    if (this.props.isAuthenticated) {
      return (
        <Redirect
          to={
            this.props.getRedirectPath === 'referrer'
              ? this.state.referrer || '/dashboard'
              : this.props.getRedirectPath
          }
        />
      );
      /*if (this.props.passwordNeedsReset) {
        return <Redirect to={'/password-out-of-date'} />;
      } else {
        return <Redirect to={this.state.referrer || '/dashboard'} />;
      }*/
    }

    return (
      <>
        <Header1>Sign In</Header1>
        <ShadowAddCard brand={(this.props.hasFailed && 'danger') || 'secondary'}>
          {(this.props.hasFailed || this.props.domain.includes('trinityhealth')) && (
            <CardHeader>
              {this.props.hasFailed ? (
                <>Login Failed</>
              ) : this.props.domain.includes('trinityhealth') ? (
                <FinePrint>
                  NOTE: Our new website is optimized for modern browsers, including Chrome, Firefox
                  and Safari. We strongly encourage you to use one of these browsers for the best
                  experience and security. If you're using IE and have issues, contact your Fiducius
                  support team at{' '}
                  <a href="mailto:customer.service@getfiducius.com">
                    customer.service@getfiducius.com
                  </a>
                  .{/*or{' '}
                  <a href="tel:15136455400">+1 (513) 645-5400</a>.*/}
                </FinePrint>
              ) : (
                <></>
              )}
            </CardHeader>
          )}

          <CardBody>{this.getForm()}</CardBody>
          <FormSwitcher>
            New here? <Link to="/register">Create account</Link>
          </FormSwitcher>
        </ShadowAddCard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.auth;
  return {
    hasFailed: requests.login.hasFailed,
    isAuthenticated: isAuthenticated(state),
    isLoading: requests.login.isLoading || requests.loadPermissions.isLoading,
    rememberMe: state.persist.cache.rememberMe,
    passwordNeedsReset: passwordNeedsReset(state),
    theme: safeAccess(state, 'auth.cache.theme'),
    getRedirectPath: authGetRedirectPath(state, true),
  };
};

const mapDispatchToProps = (dispatch) => ({
  signIn: (username, password, remember) => dispatch(authClientLogin(username, password, remember)),
  updateRememberMe: (rememberMe) => dispatch(persistRememberMe(rememberMe)),
  getThemePreference: (domain) => dispatch(authThemePreference(domain)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
