import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Alert,
  AsyncLoader,
  Button,
  CardText,
  CardTitle,
  InputWrapper,
  InputError,
  InputCheckbox,
  InputGroup,
  Loader,
  Form,
  Header4,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';
import { withPermissions } from '../../routing';

import { FlexEnd } from '../../root';
import { todosLoadCollection } from '../../todos';
import { debounce, nest, flatten, stringIsNullOrEmpty } from '../../utils';
import { Fade } from '../../routing';
import { Redirect } from 'react-router-dom';

import AccountForm from '../components/account-form';
import ContactForm from '../components/contact-form';
import PersonalForm from '../components/personal-form';
import {
  profileCopyResourceToForm,
  profileHandleFormChange,
  profileSaveForm,
} from '../redux/operations';

import { authLoadPermissions } from '../../auth';

const StyledWrapper = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

class ProfileForm extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    getFormData: t.func.isRequired,
    id: t.string,
    copyRequest: t.object.isRequired,
    updateRequest: t.object.isRequired,
    saveData: t.func.isRequired,
    updateFormState: t.func.isRequired,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '' }; // must be initialized for data handling
  }

  componentDidMount() {
    const { id, getFormData } = this.props;
    getFormData(id);
  }

  handleChange = debounce((formState) => {
    const { data, updateFormState } = this.props;
    const newData = { ...flatten(data), ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 200);

  save = () => {
    this.props.saveData();
  };

  saveAndRedirect = () => {
    const { permissions, data } = this.props;
    this.props.saveData().then(() => {
      if (permissions.clientStatus === 'CRET' && data.verification.needsProfileVerification) {
        this.setState({ redirectURI: '/benefits/forgiveness/questionnaire' });
      } else if (permissions.clientStatus === 'NFPSS') {
        let retVal = permissions.forgivenessSteps.filter((a) => a.stepCurrent === true);
        if (retVal.find((b) => b.id === 'Welcome') !== undefined) {
          this.setState({ redirectURI: '/benefits/forgiveness/welcome' });
        }
      }
    });
  };

  render() {
    const { data = {}, errors = {}, copyRequest, updateRequest } = this.props;

    const request = Object.keys(copyRequest).reduce(
      (combined, key) => ({
        ...combined,
        [key]: copyRequest[key] || updateRequest[key],
      }),
      {}
    );
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      return (
        <>
          {errors.generalErrors
            ? errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))
            : null}
          {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
          <AsyncLoader request={request}>
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-5">
                <CardTitle>Personal Information</CardTitle>
                <CardText>
                  <PersonalForm
                    data={data.personal}
                    errors={errors}
                    handleChange={this.handleChange}
                  />
                </CardText>
                <CardTitle>Account Information</CardTitle>
                <CardText>
                  <AccountForm
                    data={data.account}
                    errors={errors}
                    handleChange={this.handleChange}
                  />
                </CardText>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                <CardTitle>Contact Information</CardTitle>
                <CardText>
                  <ContactForm
                    data={data.contact}
                    errors={errors}
                    handleChange={this.handleChange}
                  />
                </CardText>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
              <Fade Id="verifyGate" show={data.verification.needsProfileVerification || false}>
                <Form id="Verification" handleChange={this.handleChange}>
                  <StyledWrapper error={!!errors.verifyProfile}>
                    <InputGroup>
                      <InputCheckbox name="verifyProfile" label="My information is correct" />
                    </InputGroup>
                    <InputError>{safeAccess(errors, 'verifyProfile.detail')}</InputError>
                  </StyledWrapper>
                </Form>
              </Fade>
            </div>
            <FlexEnd>
              <Fade show={this.enableSubmit()}>
                {data.verification.needsProfileVerification && (
                  <Button brand="primary" onClick={this.saveAndRedirect}>
                    {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Save'}
                  </Button>
                )}

                {!data.verification.needsProfileVerification && (
                  <Button brand="primary" onClick={this.save}>
                    {request.isLoading ? <Loader variant="push" size={1.5} /> : 'Save'}
                  </Button>
                )}
              </Fade>
              <Fade show={!this.checkNoMissingData()}>
                <Header4>Please enter the missing information above.</Header4>
              </Fade>
              <Fade show={this.checkNoMissingData() && !this.checkNoErrors()}>
                <Header4>Please fix the errors above.</Header4>
              </Fade>
            </FlexEnd>
          </AsyncLoader>
        </>
      );
    }
  }

  checkNoMissingData = () => {
    const { data = {}, permissions } = this.props;
    let retVal =
      !stringIsNullOrEmpty(data.account.contactEmail) &&
      !stringIsNullOrEmpty(data.contact.cellPhone) &&
      !stringIsNullOrEmpty(data.contact.addressOne) &&
      !stringIsNullOrEmpty(data.contact.city) &&
      !stringIsNullOrEmpty(data.contact.usState) &&
      !stringIsNullOrEmpty(data.contact.zipCode) &&
      !stringIsNullOrEmpty(data.personal.dob) &&
      (!permissions.inForgiveness || !stringIsNullOrEmpty(data.personal.driverLicenseNumber)) &&
      (!permissions.inSimplifi || !stringIsNullOrEmpty(data.personal.driverLicenseNumber)) &&
      (!permissions.inForgiveness || !stringIsNullOrEmpty(data.personal.driverLicenseState)) &&
      (!permissions.inSimplifi || !stringIsNullOrEmpty(data.personal.driverLicenseState)) &&
      !stringIsNullOrEmpty(data.personal.firstName) &&
      !stringIsNullOrEmpty(data.personal.lastName) &&
      (!data.verification.needsProfileVerification ||
        (data.verification.needsProfileVerification && data.verification.verifyProfile));

    return retVal;
  };

  checkNoErrors = () => {
    const { errors = {}, permissions } = this.props;

    let retVal =
      (!errors.contactEmail || errors.contactEmail.detail === undefined) &&
      (!errors.cellPhone || errors.cellPhone.detail === undefined) &&
      (!errors.addressOne || errors.addressOne.detail === undefined) &&
      (!errors.addressTwo || errors.addressTwo.detail === undefined) &&
      (!errors.city || errors.city.detail === undefined) &&
      (!errors.usState || errors.usState.detail === undefined) &&
      (!errors.zipCode || errors.zipCode.detail === undefined) &&
      (!errors.dob || errors.dob.detail === undefined) &&
      (!permissions.inForgiveness ||
        !errors.driverLicenseNumber ||
        errors.driverLicenseNumber.detail === undefined) &&
      (!permissions.inSimplifi ||
        !errors.driverLicenseNumber ||
        errors.driverLicenseNumber.detail === undefined) &&
      (!permissions.inForgiveness ||
        !errors.driverLicenseState ||
        errors.driverLicenseState.detail === undefined) &&
      (!permissions.inSimplifi ||
        !errors.driverLicenseState ||
        errors.driverLicenseState.detail === undefined) &&
      (!errors.firstName || errors.firstName.detail === undefined) &&
      (!errors.lastName || errors.lastName.detail === undefined) &&
      (!errors.maidenName || errors.maidenName.detail === undefined) &&
      (!errors.middleInitial || errors.middleInitial.detail === undefined) &&
      (!errors.suffix || errors.suffix.detail === undefined) &&
      (!errors.verifyProfile || errors.verifyProfile.detail === undefined);
    return retVal;
  };

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };
}

const tabs = {
  account: ['contactEmail'],
  contact: [
    'addressOne',
    'addressTwo',
    'altPhone',
    'cellPhone',
    'city',
    'textNotifications',
    'usState',
    'zipCode',
  ],
  personal: [
    'dob',
    'driverLicenseNumber',
    'driverLicenseState',
    'firstName',
    'lastName',
    'maidenName',
    'middleInitial',
    'suffix',
    'pronounPreference',
  ],
  verification: ['needsProfileVerification', 'verifyProfile'],
};

const mapStateToProps = (state, ownProps) => {
  const { form, requests, errors } = state.profile;
  return {
    data: nest(form, tabs),
    errors: errors,
    copyRequest: requests.copyResourceToForm,
    updateRequest: requests.updateResource,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFormData: () => dispatch(profileCopyResourceToForm(ownProps.id)),
  saveData: async () => {
    await dispatch(profileSaveForm(ownProps.id));
    dispatch(authLoadPermissions());
    dispatch(todosLoadCollection());
  },
  updateFormState: (data, attributes) => dispatch(profileHandleFormChange(data, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ProfileForm));
