import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AsyncLoader, DashboardSection, Header5 } from 'fiducius-ui';

import { getUserId, getToken } from '../../../auth';
import { withPermissions } from '../../../routing';
import { contributionLoadResource } from '../redux/operations';

import { debounce, mergeRequestStatuses, safeAccess, getApi } from '../../../utils';
import { todosLoadCollection } from '../../../todos';

import {
  customerContributionClearForm,
  customerContributionCopyResourceToForm,
  customerContributionHandleFormChange,
  customerContributionLoadCollection,
  customerContributionUpdateResource,
  InactiveContribution,
  CustomerContributionForm,
} from '../../../customer-contribution';
import { contributionSetupLoadResource } from '../../../contribution-setup';
import { accountsLoadCollection } from '../../../accounts';

const StyledHeader5 = styled(Header5)`
  margin-left: 0em;
  margin-top: 1em;
  color: ${(p) => p.theme.textPrimary};
`;

const StyledDiv = styled.div`
  display: flex;
  align-content: center;
`;

class InactiveContributions extends React.Component {
  static propTypes = {
    id: t.string,
    token: t.string,
    data: t.object,
    load: t.func,
    permissions: t.object,
    request: t.object,
    actionRequest: t.object,
    clearForm: t.func.isRequired,
    errors: t.object,
    form: t.object,
    handleForm: t.func.isRequired,
    hydrateForm: t.func.isRequired,
    reload: t.func.isRequired,
    updateCustomerContribution: t.func.isRequired,
    toDos: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      redirectUri: '',
      formId: null,
      contributionEligibleAccounts: {},
      contributionAvailableAccounts: {},
    };
  }
  componentDidMount() {
    const { load, id } = this.props;
    this.getContributionEligibleAccounts();
    this.getContributionAvailableAccounts();
    load(id);
  }

  componentDidUpdate(prevProps) {
    const { load, id } = this.props;

    const wasLoading = prevProps.actionRequest.isLoading;
    const currently = this.props.actionRequest;
    if (wasLoading && !currently.isLoading && !currently.hasFailed) {
      this.setState({ ...this.state, showForm: false }, () => {
        this.getContributionEligibleAccounts();
        this.getContributionAvailableAccounts();
        load(id);
      });
    }
  }

  getContributionEligibleAccounts = () => {
    getApi(this.props.token, '/drop-down-list/ContributionEligibleLoan__A').then((data) => {
      let eligibleAccounts = {};
      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach((a) => {
          eligibleAccounts[data[a].id] = data[a].description;
        });
      }

      this.setState({
        ...this.state,
        contributionEligibleAccounts: eligibleAccounts,
      });
    });
  };

  getContributionAvailableAccounts = () => {
    getApi(this.props.token, '/drop-down-list/ContributionLoan__A').then((data) => {
      let eligibleAccounts = {};
      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach((a) => {
          eligibleAccounts[data[a].id] = data[a].description;
        });
      }
      eligibleAccounts[''] = '';

      this.setState({
        ...this.state,
        contributionAvailableAccounts: eligibleAccounts,
      });
    });
  };

  handleRedirect = (uri) => {
    this.setState({ redirectUri: uri });
  };

  closeForm = () => {
    this.props.clearForm();
    this.setState({ ...this.state, showForm: false });
  };

  onEditContribution = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true });
  };

  onChange = debounce((contribution, id, attributes) => {
    this.props.handleForm({ ...this.props.form, ...contribution }, [attributes]);
  }, 250);

  onUpdate = () => this.props.updateCustomerContribution(this.props.form.id);

  render() {
    const { data = {}, request, form, errors, actionRequest } = this.props;
    const {
      showForm,
      formId,
      contributionEligibleAccounts,
      contributionAvailableAccounts,
    } = this.state;

    let numInactive = Object.values(data).filter((a) => !a.active).length;

    if (this.state.redirectUri && this.state.redirectUri.length > 0) {
      return <Redirect to={this.state.redirectUri} />;
    }
    return (
      <>
        <DashboardSection title="Inactive Contributions">
          <AsyncLoader
            request={request}
            empty={
              <div className="col">
                <StyledHeader5>You have no inactive contributions on file.</StyledHeader5>
              </div>
            }
          >
            <StyledDiv className="row">
              {Object.values(data)
                .filter((a) => !a.active)
                .map((contribution, i) => {
                  return (
                    <div className="col-12 col-lg-6" key={i}>
                      <InactiveContribution
                        data={contribution}
                        key={i}
                        dataLevel={2}
                        onEditContribution={this.onEditContribution}
                      />
                    </div>
                  );
                })}
              {numInactive === 0 && (
                <div className="col">
                  <StyledHeader5>You have no inactive contributions on file.</StyledHeader5>
                </div>
              )}
            </StyledDiv>
          </AsyncLoader>
        </DashboardSection>
        {showForm && (
          <CustomerContributionForm
            action={formId ? 'Update' : 'Create'}
            contributionEligibleAccounts={
              formId ? contributionEligibleAccounts : contributionAvailableAccounts
            }
            closeForm={this.closeForm}
            data={form}
            errors={errors}
            isLoading={actionRequest.isLoading}
            isOpen={showForm}
            onChange={this.onChange}
            onSubmit={formId ? this.onUpdate : this.onCreate}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, requests, errors, form } = state.customerContribution;
  return {
    token: getToken(state),
    id: getUserId(state),
    data: cache,
    errors: errors,
    form: form,
    request: requests.loadCollection,
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    toDos: safeAccess(state, 'todos.cache', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: (id) => {
    dispatch(accountsLoadCollection());
    dispatch(contributionLoadResource(id));
    dispatch(contributionSetupLoadResource(id));
    dispatch(customerContributionLoadCollection());
  },
  clearForm: () => dispatch(customerContributionClearForm()),
  handleForm: (contribution, attributes) =>
    dispatch(customerContributionHandleFormChange(contribution, attributes)),
  hydrateForm: async (id) => await dispatch(customerContributionCopyResourceToForm(id)),
  updateCustomerContribution: async (id) => {
    await dispatch(customerContributionUpdateResource(id));
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(InactiveContributions));
