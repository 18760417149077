import React from 'react';
import t from 'prop-types';

import styled from 'styled-components';
import { getApiSingle } from '../../utils';
import OnboardingGraphic from '../components/onboarding-graphic';
import LoaderWrapper from '../styles/loader-wrapper';

import { Loader, Header4, Card, CardBody, CardText } from 'fiducius-ui';

const CardNoBorder = styled(Card)`
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
`;

class CustomWelcomeMessage extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    copyRequest: t.object.isRequired,
    data: t.object,
    auth: t.object,
    errors: t.object,
    form: t.object,
    hasData: t.bool,
    handleForm: t.func.isRequired,
    id: t.string,
    request: t.object,
    reload: t.func.isRequired,
    updateFormState: t.func.isRequired,
    CustomWelcomeMessage: t.object,
    extraOnboard: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: undefined,
      body: undefined,
      isLoading: true,
    }; // must be initialized for data handling
  }

  componentDidMount() {
    const pathName = window.location.pathname;
    let Header = null;
    let Body = null;
    getApiSingle(null, '/message/' + pathName).then((data) => {
      if (data.messages !== null) {
        const header = data.messages.find((a) => a.messageType === 'Login_Header');
        const message = data.messages.find((a) => a.messageType === 'Login_Message');
        if (header !== null) {
          Header = header.messageContent;
        }
        if (message !== null) {
          Body = message.messageContent;
        }
      }
      this.setState({
        title: Header,
        body: Body,
        isLoading: false,
      });
    });
  }

  render() {
    const { extraOnboard = false } = this.props;

    if (this.state.isLoading) {
      return (
        <LoaderWrapper>
          <Loader variant="atom" size={5} />
        </LoaderWrapper>
      );
    } else if (this.state.title !== null && this.state.body !== null) {
      return (
        <CardNoBorder>
          <CardBody>
            <OnboardingGraphic extraOnboard={extraOnboard} />
            <Header4>{this.state.title}</Header4>
            <CardText dangerouslySetInnerHTML={{ __html: this.state.body }} />
          </CardBody>
        </CardNoBorder>
      );
    } else {
      return (
        <CardNoBorder>
          <CardBody>
            <OnboardingGraphic extraOnboard={extraOnboard} />
          </CardBody>
        </CardNoBorder>
      );
    }
  }
}

export default CustomWelcomeMessage;
