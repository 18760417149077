import React, { useContext } from 'react';

import { PlanGraph } from './plan-graph';
import { PlanTable } from './plan-table';
import { AppContext } from '../containers/plan-tool';
import { forgiveness } from './state';
import { PlanTableCompare } from './plan-table-compare';

export const DataColumn = React.forwardRef((props, ref) => {
  const { state } = useContext(AppContext);
  const years = forgiveness(state, false);
  const projectedYears = forgiveness(state, true);
  return (
    <div className="polaris-data-column">
      <>
        <PlanTable
          stdPayment={state.General['Current Loan Payment'].value}
          years={years}
          chartRef={ref}
        />
        <PlanGraph years={years} />
        <PlanTableCompare
          stdPayment={state.General['Current Loan Payment'].value}
          years={years}
          yearsToCompare={projectedYears}
          compareRef={props.compareChartRef}
        />
      </>
    </div>
  );
});
