import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';

import { implementationResultsLoadResource } from '../../../implementation-results';

class OHGraduation extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  render() {
    const { data, request } = this.props;
    return (
      <div>
        <ForgivenessProgress />
        <AsyncLoader request={request}>
          <DashboardSection>
            <Card>
              <Card>
                <CardBody>
                  {data.clientGraduated === false && (
                    <div>
                      <CardTitle>Continuing Education</CardTitle>
                      <CardText>
                        <p>
                          Our records indicate you are currently in school or returning to school in
                          the near future. Please keep the following in mind while you complete your
                          education:
                        </p>
                        <ul>
                          <li>
                            Do not make payments on your loans. Notify us through Contact Us if you
                            receive a bill.
                          </li>
                          <li>
                            Inform us of any major life events that occur including, but not limited
                            to: marriage, change in household size, change in school start or end
                            date, etc.
                          </li>
                          <li>Submit a Contact Us request once you have graduated.</li>
                        </ul>
                      </CardText>
                    </div>
                  )}

                  {data.clientGraduated && !data.gradReadyForStrategy && (
                    <CardText>
                      <CardTitle>Congratulations on Completing Your Schooling!</CardTitle>

                      <p>There are a few documents required to move forward:</p>
                      <ul>
                        <li>
                          <strong>
                            Provide the National Student Loan Data System (NSLDS) Summary page
                          </strong>
                        </li>
                        <ul>
                          <li>
                            To access your National Student Loan Data System (NSLDS) Summary Text
                            document:{' '}
                          </li>
                          <ul>
                            <li>
                              Visit <a href="http://studentaid.gov/">studentaid.gov</a>{' '}
                            </li>
                            <li>
                              Click <strong>Log In</strong> if you have an FSA ID or{' '}
                              <strong>Create Account</strong> to create one
                            </li>
                            <li>
                              Select <strong>VIEW DETAILS</strong> in the My Aid section of your
                              dashboard in the middle of the page
                            </li>
                            <li>
                              On the Aid Summary page, click on{' '}
                              <strong>Download My Aid Data</strong> on the right side of page
                            </li>
                            <li>
                              Click on <strong>Continue</strong>
                            </li>
                            <li>
                              <strong>Save File</strong> as "Text Document" to your computer
                            </li>
                            <li>
                              Upload to your Fiducius Documents page under the .txt file option
                            </li>
                          </ul>
                        </ul>
                        <li>
                          <strong>Complete Exit Counseling</strong>
                        </li>
                        <ul>
                          <li>
                            Go to <a href="http://www.studentloans.gov">studentaid.gov</a> and login{' '}
                            <strong>using your FSA ID</strong>
                          </li>
                          <li>
                            Click on “Complete Loan Counseling (Entrance, Financial Awareness,
                            Exit)”
                          </li>
                          <li>Select “Exit Counseling”</li>
                          <li>
                            When you are completing the application, please select the “Standard
                            Repayment” option
                          </li>
                          <ul>
                            <li>
                              <strong>
                                Do not worry, you will not make any payments under this amount
                              </strong>
                            </li>
                            <li>
                              Once any deferment is removed, we will get the correct Income-Driven
                              Repayment amount established
                            </li>
                          </ul>
                        </ul>
                        <li>
                          <strong>Upload Documents to your MyFiducius portal</strong>
                        </li>
                        <ul>
                          <li>Your most recent 1040 tax forms</li>
                          <li>Spouse's most recent 1040 tax forms (if applicable)</li>
                          <li>Your most recent paystub</li>
                        </ul>
                      </ul>
                    </CardText>
                  )}
                  {data.gradReadyForStrategy && (
                    <CardText>
                      <CardTitle>Thank You for Providing Your Information!</CardTitle>
                      <p>
                        Your team of experts are working very hard ensuring your payments are on
                        hold and analyzing your account to provide next steps. They will reach out
                        with further direction once the analysis is complete.
                      </p>

                      <ul>
                        <li>
                          Your loan payments will be put on hold if you have forbearance time
                          available.
                        </li>
                        <li>
                          Cancel any automatic payments currently in place for these loans unless
                          otherwise directed.
                        </li>
                        <li>
                          If you receive a bill or any information regarding your loans, please
                          upload it to your Documents page.
                        </li>
                        <li>You can submit a Contact Us request if you have any questions.</li>
                      </ul>
                    </CardText>
                  )}
                </CardBody>
              </Card>
            </Card>
          </DashboardSection>
        </AsyncLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `implementationResults.cache[${getUserId(state)}]`) || {},
    request: state.implementationResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(implementationResultsLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(OHGraduation));
