import { camelCase, safeAccess } from './helpers';

// Our API errors need to adhere to the [json:api](https://jsonapi.org/) spec
export class JsonApiError extends Error {
  constructor(errors, res) {
    super(res.status + ' ' + res.statusText);
    this.errors = errors;
    this.name = 'JsonApiError';
    this.res = res;
  }
}

/**
 * Creates the error state for the current redux segment
 *
 * @param {JsonApiError|Error} error   - Error object
 * @param {Object} prevErrors          - Previous error state to merge errors into
 * @param {Object} validatedAttributes - Selected attributes to update state for
 *
 * @returns {Object} - Error object keyed to attributes with optional tab segments
 */
export const createErrorState = (error, prevErrors, validatedAttributes = null) => {
  const errorState = { ...prevErrors };
  const newErrors = getStateFromError(error);
  const newKeys = Object.keys(newErrors);
  const oldKeys = Object.keys(prevErrors);

  if (!validatedAttributes) {
    // If we got here from a non-validation action, these errors overwrite
    // the whole error state for the store segment
    return newErrors;
  }

  validatedAttributes.forEach((a) => {
    const isInNew = newKeys.includes(a);
    const isInOld = oldKeys.includes(a);

    if (isInNew) {
      // Validation failure: overwrite anything old
      errorState[a] = newErrors[a];
    } else if ((isInOld && !isInNew) || (!isInOld && !isInNew)) {
      // Re-validation succeeds OR first time validation succeeds
      errorState[a] = false;
    }
  });

  return errorState;
};

const getStateFromError = (error) => {
  const errorState = { generalErrors: [] };

  if (error instanceof JsonApiError && safeAccess(error, 'errors.length') !== 0) {
    error.errors.forEach((e) => {
      if (safeAccess(e, 'source.pointer')) {
        const pointerParts = e.source.pointer.split('/');
        const attribute = camelCase(pointerParts[pointerParts.length - 1]);
        errorState[attribute] = e;

        // TODO: consider allowing multiple errors per attribute?
        // if (!errorState[attribute]) {
        //     errorState[attribute] = [];
        // }
        // errorState[attribute].push(e);
      } else {
        errorState['generalErrors'].push(e);
      }
    });
  } else if (error instanceof Error) {
    errorState.generalErrors.push({
      title: error.name,
      detail: error.message,
    });
  } else {
    throw new Error('Unprocessable error.');
  }

  if (errorState.generalErrors.length < 1) {
    errorState.generalErrors = false;
  }

  return errorState;
};
