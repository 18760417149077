import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardHeader, CardText, Header5, Link } from 'fiducius-ui';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const EducationalReengagementQualify = withPermissions(({ cache }) => (
  <Card brand="primary">
    <CardHeader>Educational Reengagement</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Returning to school?</Header5>
        <Spaced>
          Going back to school takes a lot of preparation and planning and we will help make it
          easier.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
));

EducationalReengagementQualify.propTypes = {};

const mapStateToProps = (state) => {
  const id = getUserId(state);
  return {
    //cache: cache[id],
  };
};

export default connect(mapStateToProps)(withPermissions(EducationalReengagementQualify));
