// Polyfills for IE-11 must be at the top of the entry point
import 'react-app-polyfill/ie11'; // this has to be the first line
import 'core-js/es6/array';
import 'core-js/es7/array';
import 'core-js/es6/date';
import 'core-js/es6/object';
import 'core-js/es7/object';
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es7/set';
import 'core-js/es6/string';
import 'core-js/es7/string';

import React from 'react';
import ReactDOM from 'react-dom';
import 'fiducius-ui/dist/vendor/bootstrap-reboot.min.css';
import 'fiducius-ui/dist/vendor/bootstrap-grid.min.css';

import * as serviceWorker from './serviceWorker';
import App from './app';

ReactDOM.render(<App />, document.getElementById('fiducius-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
