import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';
import { EmpCertApprovalValidator } from './validators';

const empcertApprovalValidator = new EmpCertApprovalValidator();

const checkToken = (data) => {
  if (data[0].customerName === '') {
    throw new Error('no data found');
  }
  return data;
};

const endpointHandler = {
  endpoint: '/emp-cert-approval',
  customResponseHandler: checkToken,
  resourceType: 'emp-cert-approval',
};

const operations = resourceOperationsGenerator(
  'empcertApproval',
  actions,
  null,
  empcertApprovalValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null,
  null
);

const {
  empcertApprovalClearForm,
  empcertApprovalCopyResourceToForm,
  empcertApprovalHandleFormChange,
  empcertApprovalLoadResource,
  empcertApprovalUpdateResource,
} = operations;

const empcertApprovalSaveApprovalInfo = (id) => async (dispatch, getState) => {
  const state = getState();
  let data = state.empcertApproval.form;
  state.empcertApproval.form = data;
  await dispatch(empcertApprovalUpdateResource(id));
};

export {
  empcertApprovalClearForm,
  empcertApprovalCopyResourceToForm,
  empcertApprovalHandleFormChange,
  empcertApprovalLoadResource,
  empcertApprovalSaveApprovalInfo,
};

export default operations;
