// Common API resource operations
import { combineReducers } from 'redux';

import { persistSaveState } from '../persist';
import { getToken } from '../auth/redux/selectors';

import { deleteApi, getApi, normalize, patchApi, postApi } from './api';
import { createErrorState } from './error';
import { camelCase, isEqual, safeAccess, snakeCase, upperFirst } from './helpers';
import { requestTypesGenerator, requestActionsGenerator, requestReducerGenerator } from './request';

// TODO: could hook alerts into the request status

const resourceOperations = [
  'CLEAR_ERRORS',
  'CLEAR_FORM',
  'DISABLE_READONLY',
  'DISABLE_VALIDATION',
  'ENABLE_READONLY',
  'ENABLE_VALIDATION',
  'HANDLE_CACHE_CHANGE',
  'HANDLE_ERROR',
  'HANDLE_FORM_CHANGE',
  'INVALIDATE_ATTRIBUTES',
  'INVALIDATE_CACHE',
  'VALIDATE_ATTRIBUTES',
  'VALIDATE_CACHE',
];

const resourceRequestOperations = [
  'COPY_RESOURCE_TO_FORM',
  'CREATE_RESOURCE',
  'DELETE_RESOURCE',
  'LOAD_COLLECTION',
  'LOAD_RESOURCE',
  'UPDATE_RESOURCE',
];

/**
 * Generates common resource action types
 *
 * @param {string} feature     - Feature name.
 * @param {string} namespace   - (Optional) Namespace for types. Defaults to 'app'.
 *
 * @returns {Object} - Object of generated types
 */
export const resourceTypesGenerator = (feature, namespace = 'app') => {
  let types = {};
  const SSC_FEATURE = snakeCase(feature).toUpperCase();
  resourceOperations.forEach((op) => {
    types[`${SSC_FEATURE}_${op}`] = `${namespace}/${feature}/${op}`;
  });

  resourceRequestOperations.forEach((op) => {
    types = { ...types, ...requestTypesGenerator(feature, op, namespace) };
  });

  return types;
};

/**
 * Generates common resource action creators
 *
 * @param {string} feature - Feature to create actions for
 * @param {Object} types   - Object of types to generate action creators for.
 *
 * @returns {Object} - Object of generated actions
 */
export const resourceActionsGenerator = (feature, types) => {
  let actions = {};

  resourceOperations.forEach((op) => {
    const actionName = feature + upperFirst(camelCase(op));
    const SSC_ACTION_NAME = snakeCase(actionName).toUpperCase();

    // switch(true) lets us use comparators in the case because it
    // evaluates to true === (evaluated boolean from comparator)
    switch (true) {
      case SSC_ACTION_NAME.endsWith('HANDLE_CACHE_CHANGE'):
      case SSC_ACTION_NAME.endsWith('HANDLE_FORM_CHANGE'):
        actions[actionName] = (data) => ({
          type: types[SSC_ACTION_NAME],
          data,
        });
        break;
      case SSC_ACTION_NAME.endsWith('HANDLE_ERROR'):
        actions[actionName] = (errors) => ({
          type: types[SSC_ACTION_NAME],
          errors,
        });
        break;
      case SSC_ACTION_NAME.endsWith('INVALIDATE_ATTRIBUTES'):
        actions[actionName] = (attributes, errorState) => ({
          type: types[SSC_ACTION_NAME],
          attributes,
          errorState,
        });
        break;
      case SSC_ACTION_NAME.endsWith('VALIDATE_ATTRIBUTES'):
        actions[actionName] = (attributes, errorState) => ({
          type: types[SSC_ACTION_NAME],
          attributes,
          errorState,
        });
        break;
      default:
        actions[actionName] = () => ({
          type: types[SSC_ACTION_NAME],
        });
        break;
    }
  });

  resourceRequestOperations.forEach((op) => {
    actions = { ...actions, ...requestActionsGenerator(feature, op, types) };
  });

  return actions;
};

/**
 * Generates common resource reducers
 *
 * @param {string} feature - Feature name
 * @param {Object} types   - Resource redux action types
 * @param {string|undefined} namespace - Feature's namespace
 */
export const resourceReducerGenerator = (feature, types, namespace = '') => {
  const SSC_FEATURE = snakeCase(feature).toUpperCase();

  const cache = (state = {}, action) => {
    switch (action.type) {
      case types[`${SSC_FEATURE}_HANDLE_CACHE_CHANGE`]: {
        return { ...action.data };
      }
      default: {
        return state;
      }
    }
  };

  const errors = (state = {}, action) => {
    switch (action.type) {
      case types[`${SSC_FEATURE}_CLEAR_ERRORS`]: {
        return {};
      }
      case types[`${SSC_FEATURE}_HANDLE_ERROR`]: {
        return { ...action.errors };
      }
      case types[`${SSC_FEATURE}_VALIDATE_ATTRIBUTES`]: {
        let newErrors = validateAttributes(action.attributes, action.errorState).errors;
        return { ...state, ...newErrors };
      }
      case types[`${SSC_FEATURE}_INVALIDATE_ATTRIBUTES`]: {
        let newErrors = invalidateAttributes(action.attributes, action.errorState).errors;
        return { ...state, ...newErrors };
      }
      default:
        return state;
    }
  };

  const form = (state = {}, action) => {
    switch (action.type) {
      case types[`${SSC_FEATURE}_CLEAR_FORM`]: {
        return {};
      }
      case types[`${SSC_FEATURE}_HANDLE_FORM_CHANGE`]: {
        return { ...action.data };
      }
      default: {
        return state;
      }
    }
  };

  const meta = (
    state = {
      cacheIsCurrent: false,
      isNew: false,
      isReadOnly: false,
      isValid: false,
      hasValidated: false,
      shouldValidate: true,
    },
    action
  ) => {
    switch (action.type) {
      case types[`${SSC_FEATURE}_DISABLE_VALIDATION`]: {
        return { ...state, shouldValidate: false };
      }
      case types[`${SSC_FEATURE}_ENABLE_VALIDATION`]: {
        return { ...state, shouldValidate: true };
      }
      case types[`${SSC_FEATURE}_DISABLE_READONLY`]: {
        return { ...state, isReadOnly: false };
      }
      case types[`${SSC_FEATURE}_ENABLE_READONLY`]: {
        return { ...state, isReadOnly: true };
      }
      case types[`${SSC_FEATURE}_INVALIDATE_ATTRIBUTES`]: {
        let newMeta = invalidateAttributes(action.attributes, action.errorState).meta;
        return { ...state, ...newMeta };
      }
      case types[`${SSC_FEATURE}_INVALIDATE_CACHE`]: {
        return { ...state, cacheIsCurrent: false };
      }
      case types[`${SSC_FEATURE}_VALIDATE_CACHE`]: {
        return { ...state, cacheIsCurrent: true };
      }
      case types[`${SSC_FEATURE}_VALIDATE_ATTRIBUTES`]: {
        let newMeta = validateAttributes(action.attributes, action.errorState).meta;
        return { ...state, ...newMeta };
      }
      default: {
        return state;
      }
    }
  };

  const requestReducers = resourceRequestOperations.reduce((reducers, op) => {
    const requestName = camelCase(op);
    return {
      ...reducers,
      [requestName]: requestReducerGenerator(feature, requestName, types),
    };
  }, {});

  const requests = combineReducers(requestReducers);

  return combineReducers({
    cache,
    errors,
    form,
    meta,
    requests,
  });
};

export const invalidateAttributes = (attributes, errorState) => {
  let newErrorState = { ...errorState };
  const meta = {
    hasValidated: false,
    isValid: false,
  };

  attributes.forEach((a) => {
    newErrorState[a] = {};
  });

  return {
    errors: { ...newErrorState },
    meta,
  };
};

export const validateAttributes = (validatedAttributes, errorState) => {
  let newErrorState = { ...errorState }; // start with previous state of errors

  validatedAttributes.forEach((a) => {
    const exists = Object.keys(newErrorState).includes(a);
    const isEmpty = isEqual(newErrorState[a], {});
    if (exists && isEmpty) {
      newErrorState[a] = false;
    }
  });

  let isValid = true;
  const keys = Object.keys(newErrorState);
  for (let i = 0; i < keys.length; i++) {
    if (newErrorState[keys[i]] !== false) {
      isValid = false;
      break;
    }
  }

  const meta = {
    hasValidated: true,
    isValid: isValid,
  };

  return {
    errors: { ...newErrorState },
    meta,
  };
};

/**
 * Generates common redux operations for a feature
 *
 * @param {string} feature - Name of feature
 * @param {Object} actions - Redux actions for the feature
 * @param {string} namespace - Namespace of the feature
 * @param {FormValidator|undefined} validator - FormValidator if the resource should validate
 * @param {Object|undefined} loadCollection - Object describing the GET collection endpoint
 * @param {string} loadCollection.endpoint - Endpoint GET collection
 * @param {Function} loadCollection.customResponseHandler - Customize the response body of the GET collection ednpoint
 * @param {Object|undefined} loadResource - Object describing the GET id endpoint
 * @param {string} loadResource.endpoint - Endpoint GET id
 * @param {Function} loadResource.customResponseHandler - Customize the response body of the GET id ednpoint
 * @param {Object|undefined} createResource - Object describing the POST collection endpoint
 * @param {string} createResource.endpoint - Endpoint POST collection
 * @param {Function} createResource.customResponseHandler - Customize the response body of the POST collection ednpoint
 * @param {Function} createResource.resourceType - Resource type to include in POST body
 * @param {Object|undefined} updateResource - Object describing the PATCH id endpoint
 * @param {string} updateResource.endpoint - Endpoint PATCH id
 * @param {Function} updateResource.customResponseHandler - Customize the response body of the PATCH id ednpoint
 * @param {Function} updateResource.resourceType - Resource type to include in PATCH body
 * @param {Object|undefined} deleteResource - Object describing the DELETE id endpoint
 * @param {string} deleteResource.endpoint - Endpoint DELETE id
 * @param {Function} deleteResource.customResponseHandler - Customize the response body of the DELETE id ednpoint
 * @param {Function|undefined} emptyDecider - Function to decide if the cache is considered empty
 *
 * @returns {Object} - Object containing the resource operations
 */
export const resourceOperationsGenerator = (
  feature,
  actions,
  namespace = null,
  validator = null,
  loadCollection = null,
  loadResource = null,
  createResource = null,
  updateResource = null,
  deleteResource = null,
  emptyDecider = null
) => {
  // all the actions are put on the operation object by default, then we
  // override the ones that should be higher level.
  let operations = { ...actions };
  const accessor = namespace ? `${namespace}.${feature}` : feature;

  // Order of operations creation matters! We need to reference some created
  // operations in other generators
  operations[`${feature}HandleError`] = resourceErrorHandlerGenerator(feature, actions, accessor);

  if (validator) {
    operations[`${feature}ValidateState`] = resourceValidateStateGenerator(
      feature,
      actions,
      accessor,
      operations,
      validator
    );

    operations[`${feature}ValidateAttributes`] = resourceValidateAttributesGenerator(
      feature,
      actions,
      accessor,
      operations,
      validator
    );
  }

  operations[`${feature}HandleCacheChange`] = resourceCacheChangeHandlerGenerator(
    feature,
    actions,
    accessor,
    operations
  );

  operations[`${feature}HandleFormChange`] = resourceFormChangeHandlerGenerator(
    feature,
    actions,
    accessor,
    operations
  );

  operations[`${feature}CopyResourceToForm`] = resourceCopyResourceToFormGenerator(
    feature,
    actions,
    accessor,
    operations,
    emptyDecider
  );

  operations[`${feature}ClearForm`] = resourceClearFormGenerator(
    feature,
    actions,
    accessor,
    operations,
    emptyDecider
  );

  if (loadCollection) {
    operations[`${feature}LoadCollection`] = resourceLoadCollectionGenerator(
      feature,
      actions,
      accessor,
      operations,
      emptyDecider,
      loadCollection.endpoint,
      loadCollection.customResponseHandler
    );
  }

  if (loadResource) {
    operations[`${feature}LoadResource`] = resourceLoadResourceGenerator(
      feature,
      actions,
      accessor,
      operations,
      emptyDecider,
      loadResource.endpoint,
      loadResource.customResponseHandler
    );
  }

  if (createResource) {
    operations[`${feature}CreateResource`] = resourceCreateResourceGenerator(
      feature,
      actions,
      accessor,
      operations,
      emptyDecider,
      createResource.endpoint,
      createResource.customResponseHandler,
      createResource.resourceType
    );
  }

  if (updateResource) {
    operations[`${feature}UpdateResource`] = resourceUpdateResourceGenerator(
      feature,
      actions,
      accessor,
      operations,
      emptyDecider,
      updateResource.endpoint,
      updateResource.customResponseHandler,
      updateResource.resourceType
    );
  }

  if (deleteResource) {
    operations[`${feature}DeleteResource`] = resourceDeleteResourceGenerator(
      feature,
      actions,
      accessor,
      operations,
      emptyDecider,
      deleteResource.endpoint,
      deleteResource.customResponseHandler
    );
  }

  return operations;
};

const resolvePath = (obj, path) =>
  path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

export const checkForEmpty = (localState, callback = null) => {
  if (callback) {
    return callback(localState);
  } else {
    return !(safeAccess(localState, 'cache') && Object.keys(localState.cache).length > 0);
  }
};

const resourceErrorHandlerGenerator = (feature, actions, accessor) => (
  error,
  attributes = null
) => async (dispatch, getState) => {
  const prevErrors = resolvePath(getState(), accessor).errors;
  const errorState = createErrorState(error, prevErrors, attributes);
  await dispatch(actions[`${feature}HandleError`](errorState));
};

const resourceValidateStateGenerator = (
  feature,
  actions,
  accessor,
  operations,
  validator
) => () => (dispatch, getState) => {
  const formState = resolvePath(getState(), accessor).form;
  const formKeys = Object.keys(formState);
  let currentErrors = () => resolvePath(getState(), accessor).errors;
  try {
    dispatch(actions[`${feature}InvalidateAttributes`](formKeys, currentErrors()));
    validator.validateState(formState);
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error, formKeys));
  } finally {
    dispatch(actions[`${feature}ValidateAttributes`](formKeys, currentErrors()));
  }
};

const resourceValidateAttributesGenerator = (feature, actions, accessor, operations, validator) => (
  attributes
) => (dispatch, getState) => {
  const formState = resolvePath(getState(), accessor).form;
  let currentErrors = () => resolvePath(getState(), accessor).errors;
  try {
    dispatch(actions[`${feature}InvalidateAttributes`](attributes, currentErrors()));
    validator.validateAttributes(attributes, formState);
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error, attributes));
  } finally {
    dispatch(actions[`${feature}ValidateAttributes`](attributes, currentErrors()));
  }
};

const resourceFormChangeHandlerGenerator = (feature, actions, accessor, operations) => (
  data,
  attributes = null
) => async (dispatch, getState) => {
  const meta = resolvePath(getState(), accessor).meta;
  dispatch(actions[`${feature}HandleFormChange`](data));
  dispatch(actions[`${feature}InvalidateCache`]());

  if (meta.shouldValidate) {
    dispatch(operations[`${feature}ValidateAttributes`](attributes));
  }

  // don't really need persistence on forms
  // await dispatch(persistSaveState());
};

const resourceCacheChangeHandlerGenerator = (feature, actions, accessor, operations) => (
  data
) => async (dispatch, getState) => {
  dispatch(actions[`${feature}HandleCacheChange`](data));
  dispatch(actions[`${feature}ValidateCache`]());
  await dispatch(persistSaveState());
};

const resourceCopyResourceToFormGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider
) => (id) => async (dispatch, getState) => {
  try {
    dispatch(actions[`${feature}CopyResourceToFormRequest`]());
    const data = resolvePath(getState(), accessor).cache[id];
    dispatch(actions[`${feature}HandleFormChange`](data));
    dispatch(actions[`${feature}CopyResourceToFormResponse`]());

    const segment = resolvePath(getState(), accessor);
    dispatch(actions[`${feature}CopyResourceToFormSuccess`](checkForEmpty(segment, emptyDecider)));
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error));
  }
};

const resourceClearFormGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider
) => () => async (dispatch, getState) => {
  dispatch(actions[`${feature}ClearForm`]());
  dispatch(actions[`${feature}ClearErrors`]());
};

const resourceLoadCollectionGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider,
  endpoint,
  customResponseHandler
) => () => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  try {
    dispatch(actions[`${feature}LoadCollectionRequest`]());
    let data = await getApi(getToken(getState()), endpoint);

    dispatch(actions[`${feature}LoadCollectionResponse`]());
    if (customResponseHandler) {
      data = customResponseHandler(data);
    }

    data = keyById(data);
    await dispatch(operations[`${feature}HandleCacheChange`](data));

    const segment = resolvePath(getState(), accessor);
    dispatch(actions[`${feature}LoadCollectionSuccess`](checkForEmpty(segment, emptyDecider)));
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error));
    dispatch(actions[`${feature}LoadCollectionFailure`](error));
  }
};

export const keyById = (resultArray) =>
  resultArray.reduce(
    (keyed, item) => ({
      ...keyed,
      [item.id]: item,
    }),
    {}
  );

const resourceLoadResourceGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider,
  endpoint,
  customResponseHandler
) => (id, key = null) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  try {
    dispatch(actions[`${feature}LoadResourceRequest`](key));
    let data = await getApi(getToken(state), `${endpoint}/${id}`);

    dispatch(actions[`${feature}LoadResourceResponse`](key));
    if (customResponseHandler) {
      data = customResponseHandler(data);
    }

    const cache = {
      ...resolvePath(state, accessor).cache,
      ...keyById(data),
    };

    await dispatch(operations[`${feature}HandleCacheChange`](cache));

    const segment = resolvePath(getState(), accessor);
    dispatch(actions[`${feature}LoadResourceSuccess`](checkForEmpty(segment, emptyDecider), key));
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error));
    dispatch(actions[`${feature}LoadResourceFailure`](error, key));
  }
};

const resourceDeleteResourceGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider,
  endpoint,
  customResponseHandler
) => (id, key = null) => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  try {
    dispatch(actions[`${feature}DeleteResourceRequest`](key));
    await deleteApi(getToken(getState()), `${endpoint}/${id}`);

    dispatch(actions[`${feature}DeleteResourceResponse`](key));

    let cache = resolvePath(getState(), accessor).cache;
    delete cache[id];

    await dispatch(operations[`${feature}HandleCacheChange`](cache));

    const segment = resolvePath(getState(), accessor);
    dispatch(actions[`${feature}DeleteResourceSuccess`](checkForEmpty(segment, emptyDecider), key));
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error));
    dispatch(actions[`${feature}DeleteResourceFailure`](error, key));
  }
};

const resourceCreateResourceGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider,
  endpoint,
  customResponseHandler,
  resourceType
) => (key = null) => async (dispatch, getState) => {
  const state = getState();
  let segment = resolvePath(state, accessor);
  try {
    if (safeAccess(segment, 'errors.length') > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }

    if (segment.meta.isReadOnly) {
      throw new Error('The form is readonly. Any changes will not be saved.');
    }

    dispatch(actions[`${feature}CreateResourceRequest`](key));
    const postData = normalize(resourceType, segment.form);
    let data = await postApi(getToken(state), endpoint, postData);

    dispatch(actions[`${feature}CreateResourceResponse`](key));
    if (customResponseHandler) {
      data = customResponseHandler(data);
    }
    const addedId = safeAccess(data[0], 'id') || -1;
    const cache = { ...segment.cache, ...keyById(data) };

    await dispatch(operations[`${feature}HandleCacheChange`](cache));

    segment = resolvePath(getState(), accessor);
    dispatch(actions[`${feature}CreateResourceSuccess`](checkForEmpty(segment, emptyDecider), key));
    return addedId;
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error));
    dispatch(actions[`${feature}CreateResourceFailure`](error, key));
  }
};

const resourceUpdateResourceGenerator = (
  feature,
  actions,
  accessor,
  operations,
  emptyDecider,
  endpoint,
  customResponseHandler,
  resourceType
) => (id, key = null) => async (dispatch, getState) => {
  const state = getState();
  let segment = resolvePath(state, accessor);
  try {
    if (safeAccess(segment, 'errors.length') > 0) {
      throw new Error('The form contains errors and cannot be saved until they are fixed.');
    }

    if (segment.meta.isReadOnly) {
      throw new Error('The form is readonly. Any changes will not be saved.');
    }

    dispatch(actions[`${feature}UpdateResourceRequest`](key));
    const patchData = normalize(resourceType, segment.form, id);
    let data = await patchApi(getToken(state), `${endpoint}/${id}`, patchData);

    dispatch(actions[`${feature}UpdateResourceResponse`](key));
    if (customResponseHandler) {
      data = customResponseHandler(data);
    }

    const cache = { ...segment.cache, ...keyById(data) };
    await dispatch(operations[`${feature}HandleCacheChange`](cache));

    segment = resolvePath(getState(), accessor);
    dispatch(actions[`${feature}UpdateResourceSuccess`](checkForEmpty(segment, emptyDecider), key));
  } catch (error) {
    dispatch(operations[`${feature}HandleError`](error));
    dispatch(actions[`${feature}UpdateResourceFailure`](error, key));
  }
};
