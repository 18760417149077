import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { DashboardSection, Header2 } from 'fiducius-ui';

import { Servicers } from '../../servicers';
import { servicersLoadCollection } from '../../servicers/redux/operations';
import { refServicersLoadCollection } from '../../servicers-ref/redux/operations';

import Accounts from './accounts';
import { accountsLoadCollection } from '../redux/operations';
import { withPermissions } from '../../routing';
import { getApi } from '../../utils';
import { getToken } from '../../auth';

class MyAccounts extends React.Component {
  static propTypes = {
    loadMyAccountInfo: t.func.isRequired,
    reloadAccounts: t.func.isRequired,
    reloadServicers: t.func.isRequired,
    permissions: t.object,
    dropdownData: t.object,
    token: t.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      allowedAccountTypes: {},
      hasContributionAccountTypes: false,
      hasTRAccountTypes: false,
    };
  }

  componentDidMount() {
    this.getAccountTypeDropDownOptions();
    this.props.loadMyAccountInfo();
  }

  getAccountTypeDropDownOptions = () => {
    getApi(this.props.token, '/drop-down-list/AccountTypes__A').then((data) => {
      let accountTypes = {};
      let hasContributionAccountTypes = false,
        hasTRAccountTypes = false;
      Object.keys(data).forEach((a) => {
        accountTypes[data[a].id] = data[a].description;
        if (data[a].description.includes('+')) {
          hasTRAccountTypes = true;
        }
        if (data[a].description.includes('^')) {
          hasContributionAccountTypes = true;
        }
      });
      this.setState({
        ...this.state,
        allowedAccountTypes: accountTypes,
        hasContributionAccountTypes: hasContributionAccountTypes,
        hasTRAccountTypes: hasTRAccountTypes,
      });
    });
  };

  render() {
    return (
      <DashboardSection title="My Accounts">
        <Header2>Keep track of your accounts and account servicers / banks in one place.</Header2>
        <Servicers
          reload={this.props.reloadServicers}
          hasContributionAccountTypes={this.state.hasContributionAccountTypes}
        />

        <Accounts
          reload={this.props.reloadAccounts}
          allowedAccountTypes={this.state.allowedAccountTypes}
          hasTRAccountTypes={this.state.hasTRAccountTypes}
          hasContributionAccountTypes={this.state.hasContributionAccountTypes}
        />
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: getToken(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadMyAccountInfo: () => {
    dispatch(refServicersLoadCollection());
    dispatch(servicersLoadCollection());
    dispatch(accountsLoadCollection());
  },
  reloadAccounts: () => dispatch(accountsLoadCollection()),
  reloadServicers: () => dispatch(servicersLoadCollection()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(MyAccounts));
