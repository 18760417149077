import React from 'react';
import t from 'prop-types';
import { Card, CardBody, CardTitle, DashboardSection } from 'fiducius-ui';
import { withPermissions } from '../../routing';
import { PayNow } from '../../payment';

class Closed extends React.Component {
  static propTypes = {
    permissions: t.object,
  };

  render() {
    const { permissions } = this.props;

    return (
      <DashboardSection>
        <div className="col-12">
          <Card brand="primary">
            <CardBody>
              <CardTitle>This Account Is Inactive</CardTitle>
              Your account is no longer active. Please email us at{' '}
              <a href="mailto:customer.service@getfiducius.com">customer.service@getfiducius.com</a>
              .
              {/*{' '}
            or call <a href="tel:15136455400">+1 (513) 645-5400</a> to re-open your account.*/}
            </CardBody>
          </Card>
        </div>
        {permissions.clientStatus === 'OHPAY' && (
          <div className="col-12 col-lg-5 col-xl-4">
            <PayNow />
          </div>
        )}
      </DashboardSection>
    );
  }
}

export default withPermissions(Closed);
