import React from 'react';
import t from 'prop-types';
import { Button, Table } from 'fiducius-ui';
import { startCase } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit';

const ServicerTable = ({ servicers = {}, onEditServicer }) => {
  if (Object.keys(servicers).length === 0) {
    return null;
  }

  const headers = ['id', 'servicerName', 'description', 'edit'];

  let body = Object.values(servicers).map((a) => headers.map((h) => a[h]));

  delete headers[0];
  body.forEach((row) => {
    const id = row[0];
    row[3] = (
      <Button invert brand="secondary" onClick={() => onEditServicer(id)}>
        <FontAwesomeIcon icon={faEdit} fixedWidth />
      </Button>
    );
    delete row[0];
  });

  return (
    <Table
      body={body}
      columns={[
        {},
        { sortable: true, width: '50%' },
        { sortable: true, width: '30%' },
        { width: '10%' },
      ]}
      header={headers.map((h) => startCase(h))}
    />
  );
};

ServicerTable.propTypes = {
  servicers: t.object,
  onEditServicer: t.func.isRequired,
};

export default ServicerTable;
