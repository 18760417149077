import React from 'react';
import t from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { withPermissions } from '../components/permission-provider';

import LayoutDashboard from './layout-dashboard';
import LayoutFullscreen from './layout-fullscreen';
import LayoutAuth from './layout-auth';
import PrivateRoute from './private-route';

import { connect } from 'react-redux';
import { getApiSingle, patchApi, normalize } from '../../utils';
import { authGetRedirectPath, getCRMUserId, getSecurityId, getUserId } from '../../auth';
import { Maintenance } from '../../maintenance';

const LayoutFullscreenAlignStart = (props) => <LayoutFullscreen {...props} align="flex-start" />;
const LayoutDashboardWithProps = (props) => <LayoutDashboard {...props} />;

// Top level router handles delegating routes to different layouts which have
// their own individual routers
const checkMaintenance = () => {
  const hostName = window.location.hostname;
  const domain = hostName.split('.')[0];
  const pathName = window.location.pathname;

  getApiSingle(null, '/maintenance/' + domain).then((data) => {
    if (
      pathName === '/login' ||
      pathName === '/dashboard' ||
      pathName === '/crm' ||
      pathName === '/maintenance'
    ) {
      if (
        data.appVersionMajor > parseInt(process.env.REACT_APP_V_MAJOR) ||
        (data.appVersionMajor === parseInt(process.env.REACT_APP_V_MAJOR) &&
          data.appVersionMinor > parseInt(process.env.REACT_APP_V_MINOR)) ||
        (data.appVersionMajor === parseInt(process.env.REACT_APP_V_MAJOR) &&
          data.appVersionMinor === parseInt(process.env.REACT_APP_V_MINOR) &&
          data.appVersionPatch > parseInt(process.env.REACT_APP_V_PATCH))
      ) {
        document.location.reload(true);
      }
    }

    if (data.maintenanceEnabled) {
      if (pathName !== '/maintenance') {
        window.location.assign('/maintenance');
      }
    } else {
      if (pathName === '/maintenance') {
        window.location.assign('/login');
      }
    }
  });
};

const validateSecurityCheck = (customerId, securityId, redirectPath) => {
  if (customerId !== 'Error' && redirectPath !== 'referrer') {
    const pathName = window.location.pathname;

    if (
      securityId === '6333732' &&
      !(
        pathName === '/security-questions' ||
        pathName === '/security-question-check' ||
        pathName === '/password-out-of-date' ||
        pathName === '/login'
      )
    ) {
      window.location.assign(redirectPath);
    }
  }
};

//https://stackoverflow.com/questions/19491336/how-to-get-url-parameter-using-jquery-or-plain-javascript
var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return undefined;
};

const saveRefId = () => {
  var refId = getUrlParameter('ref');
  if (refId !== undefined) {
    const patchData = normalize(
      'url-identifier',
      {
        fullUrl: window.location.href,
      },
      refId
    );

    patchApi(null, '/url-identifier/' + refId, patchData);
  }
};

class RouterEntry extends React.Component {
  static propTypes = {
    getAuthRedirectPath: t.func.isRequired,
    getCRMUserId: t.func.isRequired,
    getSecurityId: t.func.isRequired,
    getUserId: t.func.isRequired,
    todos: t.object.isRequired,
  };

  constructor(props) {
    super(props);

    saveRefId();

    this.state = {
      isEmulation: false,
      checkedMaintenance: false,
      lastPath: window.location.pathname,
    };
  }

  componentDidMount() {
    this.fullCheck();
  }

  componentDidUpdate() {
    this.fullCheck();
  }

  fullCheck = () => {
    const { getAuthRedirectPath, getCRMUserId, getSecurityId, getUserId } = this.props;
    const crmId = getCRMUserId();

    let emulation = this.state.isEmulation;
    if (crmId !== 'Error' && emulation === false) {
      emulation = true;
    }
    let checkedMaintenance = this.state.checkedMaintenance;
    let pathName = this.state.lastPath;
    if (!checkedMaintenance || window.location.pathname !== pathName) {
      checkedMaintenance = true;
      checkMaintenance();
    }
    const customerId = getUserId();
    const securityId = getSecurityId();
    const redirectPath = getAuthRedirectPath();
    validateSecurityCheck(customerId, securityId, redirectPath);

    if (
      emulation !== this.state.isEmulation ||
      checkedMaintenance !== this.state.checkedMaintenance ||
      window.location.pathname !== pathName
    ) {
      this.setState({
        ...this.state,
        isEmulation: emulation,
        checkedMaintenance: checkedMaintenance,
        lastPath: window.location.pathname,
      });
    }
  };

  render() {
    const props = this.props;

    return (
      <Switch>
        <Route path="/maintenance" component={Maintenance} />

        {/* Errors */}
        <Route path="/error/:code" component={LayoutFullscreen} />

        {/* Public routes */}
        <Route exact path="/login" component={LayoutAuth} />
        <Route exact path="/account-unlock" component={LayoutAuth} />
        <Route exact path="/crm" component={LayoutAuth} />
        <Route exact path="/logout" component={LayoutAuth} />
        <Route exact path="/password-reset" component={LayoutAuth} />
        <Route exact path="/password-update" component={LayoutAuth} />
        <Route exact path="/pre-registered" component={LayoutAuth} />
        <PrivateRoute exact path="/password-out-of-date" component={LayoutAuth} />
        <Route exact path="/register" component={LayoutAuth} />
        <Route exact path="/quick-qual" component={LayoutAuth} />
        <Route exact path="/learn-more" component={LayoutAuth} />

        <Route exact path="/tuition-approval" component={LayoutFullscreen} />
        <Route exact path="/empcert-approval" component={LayoutFullscreen} />
        <Route exact path="/privacy" component={LayoutFullscreenAlignStart} />

        {props.permissions.showSharedData && (
          <Route exact path="/shared-data" component={LayoutFullscreenAlignStart} />
        )}
        <Route exact path="/terms-of-use" component={LayoutFullscreenAlignStart} />
        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/bts" component={LayoutFullscreenAlignStart} />
        )}
        {/* Private routes */}
        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/renewal" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/idr" component={LayoutFullscreenAlignStart} />
        )}
        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/screening" component={LayoutFullscreenAlignStart} />
        )}
        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/prescreening" component={LayoutFullscreenAlignStart} />
        )}
        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/agreement" component={LayoutFullscreenAlignStart} />
        )}
        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/contribution" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/payment" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/fulfillment/:fileId" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/tuition/new-class" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/nslds" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/lwo-agreement" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute exact path="/security-questions" component={LayoutFullscreenAlignStart} />
        )}

        {this.state.isEmulation === false && (
          <PrivateRoute
            exact
            path="/security-question-check"
            component={LayoutFullscreenAlignStart}
          />
        )}

        {/* All other routes */}
        <PrivateRoute path="/" {...props} component={LayoutDashboardWithProps} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getCRMUserId: () => getCRMUserId(state),
    getSecurityId: () => getSecurityId(state),
    getUserId: () => getUserId(state),
    getAuthRedirectPath: () => authGetRedirectPath(state, true, false),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(RouterEntry));
