import React from 'react';
import t from 'prop-types';
import { Chart as ChartJS, registerables } from 'chart.js-new';
import { Line } from 'react-chartjs-2';
import { usd } from '../components/state';

ChartJS.register(...registerables);
ChartJS.defaults.font.size = 16;

const chartData = (years) => ({
  labels: years.map((y) => y.id),
  datasets: [
    {
      label: 'Accumulated Savings',
      data: years.map((y) => y.accSavings),
      borderColor: 'rgb(24, 188, 112)',
      fill: true,
      backgroundColor: 'rgba(24, 188, 112, 0.25)',
    },
  ],
});

const options = {
  interaction: { intersect: false },
  responsive: false,
  plugins: {
    tooltip: {
      mode: 'index',
      callbacks: {
        title: (ts) => `Year ${ts[0].label}`,
        label: (ts) =>
          ts.dataset.label === 'Yearly Savings'
            ? `Year ${ts.label} Savings: ${usd(ts.parsed.y)}`
            : `${ts.dataset.label}: ${usd(ts.parsed.y)}`,
      },
    },
  },
  scales: {
    x: { title: { display: true, text: 'Years' } },
    y: {
      title: { display: true, text: 'Savings' },
      ticks: {
        callback: (v) => usd(v),
      },
    },
  },
};

export const PlanGraph = ({ years }) => (
  <div className="polaris-plan-graph">
    <Line id="planGraph" data={chartData(years)} options={options} width={775} height={400} />
  </div>
);

PlanGraph.propTypes = {
  years: t.arrayOf(t.object).isRequired,
};
