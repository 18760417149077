import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { withPermissions, Fade } from '../../routing';
import {
  Alert,
  Animation,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
  Loader,
  Modal,
  fade,
} from 'fiducius-ui';

import { FlexBetween } from '../../root';
import { safeAccess, stringIsNullOrEmpty } from '../../utils';

const TextLeft = styled.div`
  text-align: left;
`;

const StyledFade = styled.div`
  ${fade};
`;

class ServicerForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    permissions: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    refServicers: t.object,
    selectRefServicer: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }

    if (!prevProps.errors.generalErrors && this.props.errors.generalErrors) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  getShowSecondColumn() {
    const { permissions } = this.props;

    return (
      (permissions.isFullService && permissions.inForgiveness) ||
      (!permissions.inTuition && permissions.inForgiveness)
    );
    return false;
  }

  getForm() {
    const {
      data = {},
      errors = {},
      onChange,
      onSubmit,
      refServicers = {},
      selectRefServicer,
    } = this.props;

    const id = data.id || 'create';
    const isCreating = id === 'create';
    const refId = data.refServicerId || '';
    const exists = (isCreating && refId !== '') || !isCreating;
    let colVar = 'col-12 col-xl-6 col-lg-6 col-md-6';

    if (!this.getShowSecondColumn()) {
      colVar = 'col-12 col-xl-12 col-lg-12 col-md-12';
    }

    return (
      <Form defaults={data} handleChange={onChange} id={id} onSubmit={onSubmit}>
        <div className="row">
          <div className={colVar}>
            <CardTitle>Servicer / Bank Contact Information</CardTitle>
            {id === 'create' && (
              <InputWrapper error={!!errors.refServicerId}>
                <InputLabel htmlFor="refServicerId" required>
                  Template Servicer
                </InputLabel>
                <InputError>{safeAccess(errors, 'refServicerId.detail')}</InputError>
                <InputGroup affordance="large">
                  <InputSelect
                    name="refServicerId"
                    options={refServicers}
                    value={refId}
                    onChange={selectRefServicer}
                  />
                </InputGroup>
              </InputWrapper>
            )}
            <Animation key="servicerInfo" animation="fade" appear={false} in={exists} unmountOnExit>
              <StyledFade>
                <InputWrapper error={!!errors.servicerName}>
                  <InputLabel htmlFor="servicerName" required>
                    Servicer / Bank Name
                  </InputLabel>
                  <InputError>{safeAccess(errors, 'servicerName.detail')}</InputError>
                  <InputGroup affordance="large">
                    <InputText
                      inputRef={this.firstInput}
                      name="servicerName"
                      defaultValue={data.servicerName}
                    />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.description}>
                  <InputLabel htmlFor="description" required>
                    Description
                  </InputLabel>
                  <InputError>{safeAccess(errors, 'description.detail')}</InputError>
                  <InputGroup>
                    <InputText name="description" defaultValue={data.description} />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.routingNumber}>
                  <InputLabel htmlFor="routingNumber" required>
                    Routing Number
                  </InputLabel>
                  <InputError>{safeAccess(errors, 'routingNumber.detail')}</InputError>
                  <InputGroup affordance="medium">
                    <InputText name="routingNumber" defaultValue={data.routingNumber} />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.address1}>
                  <InputLabel htmlFor="address1" required>
                    Address Line 1
                  </InputLabel>
                  <InputError>{safeAccess(errors, 'address1.detail')}</InputError>
                  <InputGroup affordance="large">
                    <InputText name="address1" defaultValue={data.address1} />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.address2}>
                  <InputLabel htmlFor="address2">Address Line 2</InputLabel>
                  <InputError>{safeAccess(errors, 'address2.detail')}</InputError>
                  <InputGroup affordance="large">
                    <InputText name="address2" defaultValue={data.address2} />
                  </InputGroup>
                </InputWrapper>
                <InputWrapper error={!!errors.city}>
                  <InputLabel htmlFor="city" required>
                    City
                  </InputLabel>
                  <InputError>{safeAccess(errors, 'city.detail')}</InputError>
                  <InputGroup affordance="large">
                    <InputText name="city" defaultValue={data.city} />
                  </InputGroup>
                </InputWrapper>
                <div className="row">
                  <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <InputWrapper error={!!errors.state}>
                      <InputLabel htmlFor="state" required>
                        State
                      </InputLabel>
                      <InputError>{safeAccess(errors, 'state.detail')}</InputError>
                      <InputGroup>
                        <InputSelect
                          defaultValue={data.state}
                          name="state"
                          options={[
                            '',
                            'AL',
                            'AK',
                            'AR',
                            'AZ',
                            'CA',
                            'CO',
                            'CT',
                            'DC',
                            'DE',
                            'FL',
                            'GA',
                            'HI',
                            'IA',
                            'ID',
                            'IL',
                            'IN',
                            'KS',
                            'KY',
                            'LA',
                            'MA',
                            'MD',
                            'ME',
                            'MI',
                            'MN',
                            'MO',
                            'MS',
                            'MT',
                            'NC',
                            'NE',
                            'NH',
                            'NJ',
                            'NM',
                            'NV',
                            'NY',
                            'ND',
                            'OH',
                            'OK',
                            'OR',
                            'PA',
                            'PR',
                            'RI',
                            'SC',
                            'SD',
                            'TN',
                            'TX',
                            'UT',
                            'VT',
                            'VA',
                            'WA',
                            'WI',
                            'WV',
                            'WY',
                          ]}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                  <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <InputWrapper error={!!errors.zip}>
                      <InputLabel htmlFor="zip" required>
                        Zip Code
                      </InputLabel>
                      <InputError>{safeAccess(errors, 'zip.detail')}</InputError>
                      <InputGroup>
                        <InputText name="zip" defaultValue={data.zip} />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 col-xl-4 col-lg-4 col-md-5 col-sm-6 col-xs-12">
                    <InputWrapper error={!!errors.phone}>
                      <InputLabel htmlFor="phone" required>
                        Phone
                      </InputLabel>
                      <InputError>{safeAccess(errors, 'phone.detail')}</InputError>
                      <InputGroup>
                        <InputMask
                          defaultValue={data.phone}
                          mask="(###) ###-####"
                          name="phone"
                          placeholder="(___) ___-____"
                          type="tel"
                        />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                  <div className="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <InputWrapper error={!!errors.phoneExtension}>
                      <InputLabel htmlFor="phoneExtension">Ext.</InputLabel>
                      <InputError>{safeAccess(errors, 'phoneExtension.detail')}</InputError>
                      <InputGroup>
                        <InputText name="phoneExtension" defaultValue={data.phoneExtension} />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                </div>
                <InputWrapper error={!!errors.email}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <InputError>{safeAccess(errors, 'email.detail')}</InputError>
                  <InputGroup affordance="medium">
                    <InputText name="email" defaultValue={data.email} />
                  </InputGroup>
                </InputWrapper>
              </StyledFade>
            </Animation>
          </div>

          {this.getShowSecondColumn() && (
            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <CardTitle>Servicer / Bank Login Information</CardTitle>
              <div className="row">
                <div className="col-6 col-sm-12 col-xs-12">
                  <InputWrapper error={!!errors.username}>
                    <InputLabel htmlFor="username" required>
                      Username
                    </InputLabel>
                    <InputError>{safeAccess(errors, 'username.detail')}</InputError>
                    <InputGroup>
                      <InputText name="username" defaultValue={data.username} />
                    </InputGroup>
                  </InputWrapper>
                </div>
                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <InputWrapper error={!!errors.password}>
                    <InputLabel htmlFor="password" required>
                      Password
                    </InputLabel>
                    <InputError>{safeAccess(errors, 'password.detail')}</InputError>
                    <InputGroup>
                      <InputText name="password" defaultValue={data.password} />
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-sm-12 col-xs-12">
                  <InputWrapper error={!!errors.question1Text}>
                    <InputLabel htmlFor="question1Text">Challenge Question 1</InputLabel>
                    <InputError>{safeAccess(errors, 'question1Text.detail')}</InputError>
                    <InputGroup affordance="large">
                      <InputText name="question1Text" defaultValue={data.question1Text} />
                    </InputGroup>
                  </InputWrapper>
                  <InputWrapper error={!!errors.question1Response}>
                    <InputLabel htmlFor="question1Response">Challenge Question 1 Answer</InputLabel>
                    <InputError>{safeAccess(errors, 'question1Response.detail')}</InputError>
                    <InputGroup affordance="medium">
                      <InputText name="question1Response" defaultValue={data.question1Response} />
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 col-xs-12">
                  <InputWrapper error={!!errors.question2Text}>
                    <InputLabel htmlFor="question2Text">Challenge Question 2</InputLabel>
                    <InputError>{safeAccess(errors, 'question2Text.detail')}</InputError>
                    <InputGroup affordance="large">
                      <InputText name="question2Text" defaultValue={data.question2Text} />
                    </InputGroup>
                  </InputWrapper>
                  <InputWrapper error={!!errors.question2Response}>
                    <InputLabel htmlFor="question2Response">Challenge Question 2 Answer</InputLabel>
                    <InputError>{safeAccess(errors, 'question2Response.detail')}</InputError>
                    <InputGroup affordance="medium">
                      <InputText name="question2Response" defaultValue={data.question2Response} />
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-sm-12 col-xs-12">
                  <InputWrapper error={!!errors.question3Text}>
                    <InputLabel htmlFor="question3Text">Challenge Question 3</InputLabel>
                    <InputError>{safeAccess(errors, 'question3Text.detail')}</InputError>
                    <InputGroup affordance="large">
                      <InputText name="question3Text" defaultValue={data.question3Text} />
                    </InputGroup>
                  </InputWrapper>
                  <InputWrapper error={!!errors.question3Response}>
                    <InputLabel htmlFor="question3Response">Challenge Question 3 Answer</InputLabel>
                    <InputError>{safeAccess(errors, 'question3Response.detail')}</InputError>
                    <InputGroup affordance="medium">
                      <InputText name="question3Response" defaultValue={data.question3Response} />
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }

  checkSecondColumnNoMissingData = () => {
    const { data = {} } = this.props;

    if (data.inForgiveness && data.isFullService) {
      return (
        !stringIsNullOrEmpty(data.username) &&
        !stringIsNullOrEmpty(data.password) &&
        ((!stringIsNullOrEmpty(data.question1Text) &&
          !stringIsNullOrEmpty(data.question1Response)) ||
          (stringIsNullOrEmpty(data.question1Response) &&
            stringIsNullOrEmpty(data.question1Text))) &&
        ((!stringIsNullOrEmpty(data.question2Text) &&
          !stringIsNullOrEmpty(data.question2Response)) ||
          (stringIsNullOrEmpty(data.question2Response) &&
            stringIsNullOrEmpty(data.question2Text))) &&
        ((!stringIsNullOrEmpty(data.question3Text) &&
          !stringIsNullOrEmpty(data.question3Response)) ||
          (stringIsNullOrEmpty(data.question3Response) && stringIsNullOrEmpty(data.question3Text)))
      );
    } else {
      return true;
    }
  };

  checkSecondColumnNoErrors = () => {
    const { data = {}, errors = {} } = this.props;

    if (data.inForgiveness && data.isFullService) {
      return (
        !errors.question1Text &&
        !errors.question1Response &&
        !errors.question2Text &&
        !errors.question2Response &&
        !errors.question3Text &&
        !errors.question3Response &&
        !errors.username &&
        !errors.password
      );
    } else {
      return true;
    }
  };

  enableSubmit = () => {
    return this.checkNoMissingData() && this.checkNoErrors();
  };

  checkNoMissingData = () => {
    const { data = {} } = this.props;
    return (
      !stringIsNullOrEmpty(data.servicerName) &&
      !stringIsNullOrEmpty(data.address1) &&
      !stringIsNullOrEmpty(data.city) &&
      !stringIsNullOrEmpty(data.state) &&
      !stringIsNullOrEmpty(data.zip) &&
      !stringIsNullOrEmpty(data.phone) &&
      this.checkSecondColumnNoMissingData()
    );
  };

  checkNoErrors = () => {
    const { data = {}, errors = {} } = this.props;
    return (
      !errors.servicerName &&
      !errors.routingNumber &&
      !errors.address1 &&
      !errors.address2 &&
      !errors.city &&
      !errors.state &&
      !errors.zip &&
      !errors.description &&
      !errors.phone &&
      data.phone.match(/(\([0-9]{3}\) [0-9]{3}-[0-9]{4}|[0-9]{10})/g) &&
      !errors.email &&
      this.checkSecondColumnNoErrors()
    );
  };

  submit = (e) => {
    e.preventDefault();
    if (!this.props.isLoading) {
      this.props.onSubmit();
    }
  };

  render() {
    const { action, errors, closeForm, isLoading, isOpen, permissions } = this.props;
    /*for(let [key, value] of Object.entries(permissions)) {
      alert(`${key}: ${value}`);
    }*/
    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={this.getShowSecondColumn()}>
        <Card brand={errors.generalErrors ? 'danger' : undefined}>
          <CardHeader>{action} Account Servicer</CardHeader>
          <CardBody>
            {errors.generalErrors && (
              <TextLeft>
                {errors.generalErrors.map((e, i) => (
                  <Alert key={i} brand="danger">
                    {e.detail}
                  </Alert>
                ))}
              </TextLeft>
            )}
            <TextLeft>
              {this.getForm(
                permissions.isFullService,
                permissions.inTuition,
                permissions.inForgiveness
              )}
            </TextLeft>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Cancel
              </Button>
              <Fade show={this.enableSubmit()}>
                <Button brand="success" onClick={(e) => this.submit(e)}>
                  {isLoading ? <Loader variant="push" size={1.5} /> : action + ' Account Servicer'}
                </Button>
              </Fade>
              <Fade show={!isLoading && !this.checkNoMissingData()}>
                Please enter the missing information above.
              </Fade>
              <Fade show={!isLoading && this.checkNoMissingData() && !this.checkNoErrors()}>
                Please fix the errors above.
              </Fade>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default withPermissions(ServicerForm);
