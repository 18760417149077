import actions from './actions';
import { keyById, normalize, patchApi, resourceOperationsGenerator } from '../../utils';
import { getToken } from '../../auth';
import { EmploymentHistoryValidator } from './validators';

const employmentHistoryValidator = new EmploymentHistoryValidator();

const endpointHandler = {
  endpoint: '/employment-history',
  customResponseHandler: null,
  resourceType: 'employment-history',
};

const operations = resourceOperationsGenerator(
  'employmentHistory',
  actions,
  null,
  employmentHistoryValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  employmentHistoryClearForm,
  employmentHistoryCopyResourceToForm,
  employmentHistoryHandleFormChange,
  employmentHistoryLoadResource,
  employmentHistoryLoadCollection,
  employmentHistoryCreateResource,
  employmentHistoryDeleteResource,
} = operations;

const employmentCreateResource = () => async (dispatch, getState) => {
  const addedId = await dispatch(employmentHistoryCreateResource());
  //await dispatch(employmentHistoryLoadCollection());
  return addedId;
};

const employmentUpdateResource = (id) => async (dispatch, getState) => {
  const state = getState();
  try {
    dispatch(actions.employmentHistoryUpdateResourceRequest());

    const patchData = normalize('employment-history', state.employmentHistory.form, id);
    let data = await patchApi(getToken(state), `/employment-history/${id}`, patchData);

    dispatch(actions.employmentHistoryUpdateResourceResponse());

    let newData = {};
    newData[id] = data[0];

    const cache = { ...state.employmentHistory.cache, ...newData };
    await dispatch(operations.employmentHistoryHandleCacheChange(cache));

    dispatch(actions.employmentHistoryUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.employmentHistoryHandleError(error));
    dispatch(actions.employmentHistoryUpdateResourceFailure(error));
  }
};

const empcertSaveApproverInfo = (stepState) => async (dispatch, getState) => {
  const state = getState();
  let form = state.employmentHistory.form;
  let employmentHistoryCache = state.employmentHistory.cache;

  Object.keys(form).forEach((data) => {
    let id = data.substring(data.indexOf('_') + 1);
    let attribute = data.substring(0, data.indexOf('_'));

    let attributeObject = {};
    attributeObject['' + attribute] = form[data];

    if (attributeObject.empcertApproverEmail !== undefined) {
      employmentHistoryCache[id].empcertApproverEmail = attributeObject.empcertApproverEmail;
    }

    if (attributeObject.empcertApproverUnknown !== undefined) {
      employmentHistoryCache[id].empcertApproverUnknown = attributeObject.empcertApproverUnknown;
    }
  });

  // Send each needed employer update to the API
  Object.keys(employmentHistoryCache).forEach((e) => {
    if (employmentHistoryCache[e].empcertNeeded) {
      try {
        employmentHistoryCache[e].updatingApprover = true;
        dispatch(actions.employmentHistoryUpdateResourceRequest());

        const patchData = normalize('employment-history', employmentHistoryCache[e], e);
        let data = patchApi(getToken(state), `/employment-history/${e}`, patchData);

        dispatch(actions.employmentHistoryUpdateResourceResponse());

        let newData = {};
        newData[e] = data[0];

        const cache = { ...state.employmentHistory.cache, ...newData };
        dispatch(operations.employmentHistoryHandleCacheChange(cache));

        dispatch(actions.employmentHistoryUpdateResourceSuccess());
        stepState();
      } catch (error) {
        dispatch(operations.employmentHistoryHandleError(error));
        dispatch(actions.employmentHistoryUpdateResourceFailure(error));
      }
    }
  });
};

const employmentDeleteResource = (id) => async (dispatch) => {
  await dispatch(employmentHistoryDeleteResource(id));
  //await dispatch(employmentHistoryLoadCollection());
};

export {
  employmentHistoryClearForm,
  employmentHistoryCopyResourceToForm,
  employmentHistoryHandleFormChange,
  employmentHistoryLoadResource,
  employmentHistoryLoadCollection,
  employmentCreateResource,
  employmentDeleteResource,
  employmentUpdateResource,
  empcertSaveApproverInfo,
};

export default operations;
