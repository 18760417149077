import React from 'react';
import t from 'prop-types';
import { AsyncLoader, Card, CardBody, CardTitle } from 'fiducius-ui';
import { connect } from 'react-redux';
import { safeAccess } from '../../utils';

import FaqQuestion from './faq-question';
import { faqLoadQuestionAnswers } from '../redux/operations';

class QuestionAnswer extends React.Component {
  static propTypes = {
    faqs: t.object,
    loadQuestionAnswers: t.func.isRequired,
    request: t.object,
    subject: t.string,
    subjectId: t.number,
    selectedId: t.number,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { loadQuestionAnswers, subjectId } = this.props;

    loadQuestionAnswers(subjectId);
  }

  componentDidUpdate() {
    const { faqs, loadQuestionAnswers, subjectId, request } = this.props;

    if (
      faqs !== undefined &&
      faqs !== null &&
      faqs[subjectId] === undefined &&
      !request.isLoading
    ) {
      loadQuestionAnswers(subjectId);
    }
  }

  getQuestionAndAnswers = (faqs) => {
    let faqComponents = [];
    if (faqs !== undefined && faqs !== null && faqs[this.props.subjectId] !== undefined) {
      let faqItem = faqs[this.props.subjectId];

      Object.keys(faqItem.questionAnswer).forEach((key) => {
        faqComponents.push(
          <FaqQuestion question={faqItem.questionAnswer[key].question}>
            <div dangerouslySetInnerHTML={{ __html: faqItem.questionAnswer[key].answer }}></div>
          </FaqQuestion>
        );
      });
    }

    return faqComponents;
  };

  render() {
    const { faqs = {}, request } = this.props;
    return (
      <Card brand="primary">
        <CardBody>
          <CardTitle>{this.props.subject}</CardTitle>
          <AsyncLoader request={request}>{this.getQuestionAndAnswers(faqs)}</AsyncLoader>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    faqs: safeAccess(state, 'customerFaq.cache.questionAnswers'),
    request: state.customerFaq.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadQuestionAnswers: (id) => dispatch(faqLoadQuestionAnswers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAnswer);
