import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  Alert,
  AsyncLoader,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  InputError,
  InputWrapper,
  InputLabel,
  InputRadio,
} from 'fiducius-ui';

import { advisorInvalidateCache } from '../../../advisor';
import { getUserId, authLoadPermissions } from '../../../auth';
import { debounce, safeAccess } from '../../../utils';
import { withPermissions } from '../../../routing';
import { SharedDataConsent } from '../../../legal';
import { todosLoadCollection } from '../../../todos';

import {
  screeningCopyResourceToForm,
  screeningHandleFormChange,
  screeningLoadResource,
  screeningUpdateResource,
  screeningValidateAttributes,
  screeningSkip,
} from '../redux/operations';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0 3rem 0;
`;

const StyledInputError = styled(InputError)`
  display: flex;
  flex-direction: column;
`;

const PrescreeningForm = ({
  data,
  errors,
  request,
  permissions,
  updateForm,
  loadData,
  id,
  prescreeningSave,
}) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [skipScreening, setSkipScreening] = useState(false);

  useEffect(() => {
    loadData(id);
  }, []);

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     current: props.current || 0,
  //     modalOpen: false,
  //   };
  // }

  // componentDidMount() {
  //   this.props.loadData(this.props.id);
  // }

  // componentDidUpdate(prevProps) {
  //   const { request } = this.props;
  //   if (prevProps.request.isLoading && request.hasFinished && !request.hasFailed) {
  //     this.setState({ ...this.state, modalOpen: true });
  //   }
  // }

  const handleChange = debounce((formState, id, attribute) => {
    //const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };
    updateForm(newData, attribute);
  }, 250);

  // const allowedNavigate = () => {
  //   return true;
  // };

  // handleTabChange = (index) => {
  //   this.props.validateTab(this.state, Object.keys(this.state.tabs)[this.state.current]);
  //   this.setState({ current: index });
  // };

  const toggleModal = () => {
    setModalOpen(false);
  };

  const onContinue = () => {
    // hit the method that wil save the defaults
    if (skipScreening) {
      // Add the skip screening to cache here?

      prescreeningSave(id).then(() => {
        window.location.href = '/dashboard';
      });
    } else {
      window.location.href = '/screening';
    }
  };

  const showSharedDataConsent = (permissions) => {
    let retVal = false;
    if (permissions.hasConsentToShareData && permissions.consentFormCode === 'AREGI') {
      modalOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = '');
      retVal = true;
    }
    return retVal;
  };

  const getScreeningMessage = () => {
    if (permissions.skipScreeningMessage && permissions.skipScreeningMessage !== '') {
      return <p dangerouslySetInnerHTML={{ __html: permissions.skipScreeningMessage }}></p>;
    } else {
      return (
        <p>
          Fiducius works with non-affiliated third parties, including some health welfare financial
          service providers that may work with your employer (i.e. insurance broker, retirement
          provider). These parties have financial solutions that may further help you save on your
          student loan payment or otherwise help you with your financial wellness. By clicking on "I
          Accept" you are authorizing Fiducius to share your personal information you provide with
          these parties to help facilitate best possible outcomes for your individual situation. You
          may opt out at any time by contacting us at{' '}
          <a href="mailto:info@getfiducius.com" target="_top">
            info@getfiducius.com
          </a>{' '}
          or <a href="tel:5136455400">(513) 645-5400</a>.
        </p>
      );
    }
  };

  //const { data, errors, request, permissions } = this.props;
  return (
    <>
      {showSharedDataConsent(permissions) && (
        <SharedDataConsent hideIcon={true} isOpen={modalOpen} toggleModal={toggleModal} />
      )}
      <AsyncLoader request={request}>
        <>
          {safeAccess(errors, 'generalErrors')
            ? errors.generalErrors.map((e, i) => (
                <Alert key={i} brand="danger">
                  {e.detail}
                </Alert>
              ))
            : null}
          {/* TODO: Fix AsyncLoader so that it doesn't need this check for data */}
          {data && permissions.showSkipScreening && (
            <Card brand={request.hasFailed ? 'danger' : 'primary'}>
              {request.hasFailed && <CardHeader>Prescreen load Failure</CardHeader>}
              <CardBody>
                <CardTitle>Prescreening Option</CardTitle>
                <CardText>
                  {getScreeningMessage()}
                  <div className="row">
                    <div className="col-6 col-md-6">
                      <InputWrapper error={!!errors.skipScreening}>
                        {/* <InputLabel htmlfor="skipScreening" required>
                          Do you want to skip screening?
                        </InputLabel> */}
                        <StyledInputError
                          dangerouslySetInnerHTML={{
                            __html: safeAccess(errors, 'skipScreening.detail'),
                          }}
                        />
                        <InputRadio
                          name="skipScreening"
                          value="true"
                          checked={skipScreening === true}
                          onChange={() => {
                            setSkipScreening(!skipScreening);
                          }}
                          label="Yes"
                        />
                        <InputRadio
                          name="skipScreening"
                          value="false"
                          checked={skipScreening === false}
                          onChange={() => {
                            setSkipScreening(!skipScreening);
                          }}
                          label="No"
                        />
                      </InputWrapper>
                    </div>
                  </div>
                </CardText>
              </CardBody>
              <ButtonWrapper>
                <Button brand="primary" size="lg" onClick={onContinue}>
                  Continue
                </Button>
              </ButtonWrapper>
            </Card>
          )}
        </>
      </AsyncLoader>
    </>
  );
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.screening;
  return {
    id: getUserId(state),
    data: form,
    errors: errors,
    request: requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(screeningLoadResource(id));
    await dispatch(screeningCopyResourceToForm(id));
    await dispatch(todosLoadCollection());
  },
  updateForm: (data, attributes) => {
    dispatch(advisorInvalidateCache());
    dispatch(screeningHandleFormChange(data, attributes));
  },
  prescreeningSave: async (id) => {
    //await dispatch(screeningUpdateResource(id));
    await dispatch(screeningSkip(id));
    await dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },
});

PrescreeningForm.propTypes = {
  data: t.object,
  errors: t.object,
  id: t.string,
  loadData: t.func.isRequired,
  request: t.object.isRequired,
  updateForm: t.func.isRequired,
  permissions: t.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(PrescreeningForm));
