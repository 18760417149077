import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { BrandColor, Card, CardBody, CardText, CardTitle, Lede, CdnImage } from 'fiducius-ui';

import { safeAccess } from '../../utils';

const BannerCard = styled(Card)`
  margin-top: 2rem;
  box-shadow: ${(p) => p.theme.boxShadow};
  background-color: ${(p) => (p.theme.themeName === 'light' ? p.theme.quinary : p.theme.primaryB)};

  & .card-title > h2 {
    margin-bottom: 0;
  }
`;

const PartnershipWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem 1rem;
`;

const StyledPartnershipLogoDisplay = styled(CdnImage)`
  height: auto;
  max-height: calc(${(p) => p.theme.topBarHeight} - 0.5rem);
  max-width: 100%;
`;

const BannerGreeting = styled(CardText)`
  display: flex;
  justify-content: flex-start;
`;

const BannerRow = styled.div`
  align-items: baseline;
`;
const FullHeightCol = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

const parseSubdomain = () => {
  // subdomain.domain.tld <=> app.getfiducius.com
  // domains = [subdomain, domain, tld];
  const domains = window.location.hostname.split('.');
  // If there's more than the domain and top level domain,
  // return the first sub-domain
  return domains.length > 2 ? domains[domains.length - 3] : 'islsoffice';
};

const DashboardBanner = ({ company, greeting }) => {
  const partnerDomain = parseSubdomain();
  const leftClass =
    partnerDomain === process.env.REACT_APP_CDN_PARTNERSHIP
      ? 'col-12 col-md-3 col-lg-2'
      : 'col-12 col-md-4 col-lg-3';
  const rightClass =
    partnerDomain === process.env.REACT_APP_CDN_PARTNERSHIP
      ? 'col-12 col-md-9 col-lg-10'
      : 'col-12 col-md-8 col-lg-9';

  if (company || greeting) {
    return (
      <BannerCard invert>
        <CardBody>
          <BannerRow className="row">
            <FullHeightCol className={leftClass}>
              <CardTitle>
                {partnerDomain === process.env.REACT_APP_CDN_PARTNERSHIP && (
                  <PartnershipWrapper>
                    <StyledPartnershipLogoDisplay
                      cdn={process.env.REACT_APP_CDN_URL}
                      imagePath={'fiducius-logo.png'}
                    />
                  </PartnershipWrapper>
                )}
                {partnerDomain !== process.env.REACT_APP_CDN_PARTNERSHIP && (
                  <BrandColor brand="white">{company}</BrandColor>
                )}
              </CardTitle>
            </FullHeightCol>
            <FullHeightCol className={rightClass}>
              <BannerGreeting>
                <Lede>
                  <BrandColor brand="white">{greeting}</BrandColor>
                </Lede>
              </BannerGreeting>
            </FullHeightCol>
          </BannerRow>
        </CardBody>
      </BannerCard>
    );
  }

  return null;
};

DashboardBanner.propTypes = {
  company: t.string,
  greeting: t.string,
};

const mapStateToProps = (state) => ({
  company:
    safeAccess(state, 'auth.cache.permissions.partnerLine1') ||
    safeAccess(state, 'auth.cache.partnerLocations.partnerLine1'),
  greeting:
    safeAccess(state, 'auth.cache.permissions.partnerLine2') ||
    safeAccess(state, 'auth.cache.partnerLocations.partnerLine2'),
});
export default connect(mapStateToProps)(DashboardBanner);
