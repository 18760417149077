import React from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faExclamation as faSolidExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { Redirect } from 'react-router-dom';

import { AsyncLoader, DashboardSection, Button, Badge, Header5 } from 'fiducius-ui';

import { getUserId, getToken } from '../../../auth';
import { withPermissions } from '../../../routing';
import { contributionLoadResource } from '../redux/operations';
import { debounce, mergeRequestStatuses, safeAccess, getApi } from '../../../utils';
import { contributionSetupLoadResource } from '../../../contribution-setup';
import { todosLoadCollection } from '../../../todos';
import { getContributionData } from '../redux/selectors';

import {
  customerContributionClearForm,
  customerContributionCopyResourceToForm,
  customerContributionHandleFormChange,
  customerContributionLoadCollection,
  customerContributionCreateResource,
  customerContributionUpdateResource,
  ActiveContribution,
  CustomerContributionForm,
} from '../../../customer-contribution';

import { accountsLoadCollection } from '../../../accounts';

const StyledDiv = styled.div`
  display: flex;
  align-content: center;
`;
const StyledButton = styled(Button)`
  margin-right: 1em;
  margin-top: 1em;
`;

const StyledHeader5 = styled(Header5)`
  margin-top: 1em;
  margin-left: 0em;
  color: ${(p) => p.theme.textPrimary};
`;

const PaddedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.45em;
`;

const PaddedBadge = styled(Badge)`
  margin-right: 0.45em;
  font-size: 0.75em;
  top: -0.75rem;
`;

class ActiveContributions extends React.Component {
  static propTypes = {
    id: t.string,
    token: t.string,
    data: t.object,
    load: t.func,
    permissions: t.object,
    request: t.object,
    actionRequest: t.object,
    clearForm: t.func.isRequired,
    createCustomerContribution: t.func.isRequired,
    errors: t.object,
    form: t.object,
    handleForm: t.func.isRequired,
    hydrateForm: t.func.isRequired,
    reload: t.func.isRequired,
    updateCustomerContribution: t.func.isRequired,
    toDos: t.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      redirectUri: '',
      formId: null,
      contributionEligibleAccounts: {},
      contributionAvailableAccounts: {},
    };

    this.showAddButton = this.showAddButton.bind(this);
  }

  componentDidMount() {
    const { load, id } = this.props;
    this.getContributionEligibleAccounts();
    this.getContributionAvailableAccounts();
    load(id);
  }

  componentDidUpdate(prevProps) {
    const { load, id } = this.props;

    const wasLoading = prevProps.actionRequest.isLoading;
    const currently = this.props.actionRequest;
    if (wasLoading && !currently.isLoading && !currently.hasFailed) {
      this.setState({ ...this.state, showForm: false }, () => {
        this.getContributionEligibleAccounts();
        this.getContributionAvailableAccounts();
        load(id);
      });
    }
  }

  getContributionEligibleAccounts = () => {
    getApi(this.props.token, '/drop-down-list/ContributionEligibleLoan__A').then((data) => {
      let eligibleAccounts = {};
      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach((a) => {
          eligibleAccounts[data[a].id] = data[a].description;
        });
      }

      this.setState({
        ...this.state,
        contributionEligibleAccounts: eligibleAccounts,
      });
    });
  };

  getContributionAvailableAccounts = () => {
    getApi(this.props.token, '/drop-down-list/ContributionLoan__A').then((data) => {
      let eligibleAccounts = {};
      if (Object.keys(data).length > 0) {
        Object.keys(data).forEach((a) => {
          eligibleAccounts[data[a].id] = data[a].description;
        });
      }
      eligibleAccounts[''] = '';

      this.setState({
        ...this.state,
        contributionAvailableAccounts: eligibleAccounts,
      });
    });
  };

  handleRedirect = (uri) => {
    this.setState({ redirectUri: uri });
  };

  closeForm = () => {
    this.props.clearForm();
    this.setState({ ...this.state, showForm: false });
  };

  onAddContribution = () => this.setState({ formId: null, showForm: true });

  onEditContribution = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true });
  };

  onChange = debounce((contribution, id, attributes) => {
    this.props.handleForm({ ...this.props.form, ...contribution }, [attributes]);
  }, 250);

  onCreate = () => {
    this.props.createCustomerContribution();
    this.getContributionEligibleAccounts();
    this.getContributionAvailableAccounts();
  };

  onUpdate = () => this.props.updateCustomerContribution(this.props.form.id);

  getIconForAdd() {
    const { toDos } = this.props;
    if (Object.keys(toDos).includes('403') || Object.keys(toDos).includes('13')) {
      return (
        <PaddedBadge brand="primary">
          <FontAwesomeIcon icon={faSolidExclamation} />
        </PaddedBadge>
      );
    } else {
      return <PaddedIcon icon={faPlusCircle} fixedWidth />;
    }
  }

  showAddButton() {
    const { data = {}, permissions, contributionData } = this.props;

    const { contributionAvailableAccounts } = this.state;
    let numActive = Object.values(data).filter((a) => a.active).length;
    let showAddForForgive = true;
    if (
      permissions.showForgiveness &&
      (contributionData.idrRepayment === null || contributionData.doeRepayDate === null)
    ) {
      showAddForForgive = false;
    }
    return (
      Object.values(contributionAvailableAccounts).length > 0 && numActive < 2 && showAddForForgive
    );
  }

  render() {
    const { data = {}, request, form, errors, actionRequest, contributionData } = this.props;
    const {
      showForm,
      formId,
      contributionEligibleAccounts,
      contributionAvailableAccounts,
    } = this.state;
    let numActive = Object.values(data).filter((a) => a.active).length;

    if (this.state.redirectUri && this.state.redirectUri.length > 0) {
      return <Redirect to={this.state.redirectUri} />;
    }

    return (
      <>
        <DashboardSection title="Active Contributions">
          <AsyncLoader
            request={request}
            empty={
              <>
                {this.showAddButton() && (
                  <StyledDiv className="row">
                    <div className="col">
                      <StyledButton brand="primary" onClick={this.onAddContribution}>
                        {this.getIconForAdd()}Add Contribution
                      </StyledButton>
                    </div>
                  </StyledDiv>
                )}
                <div className="col-12">
                  <StyledHeader5>You have no active contributions on file.</StyledHeader5>
                </div>
              </>
            }
          >
            {this.showAddButton() && (
              <StyledDiv className="row">
                <div className="col">
                  <StyledButton brand="primary" onClick={this.onAddContribution}>
                    {this.getIconForAdd()}Add Contribution
                  </StyledButton>
                </div>
              </StyledDiv>
            )}
            <StyledDiv className="row">
              {Object.values(data)
                .filter((a) => a.active)
                .map((contribution, i) => (
                  <div className="col-12 col-lg-6" key={i}>
                    <ActiveContribution
                      data={contribution}
                      key={i}
                      dataLevel={2}
                      onEditContribution={this.onEditContribution}
                    />
                  </div>
                ))}
              {numActive === 0 && (
                <div className="col-12">
                  <StyledHeader5>You have no active contributions on file.</StyledHeader5>
                </div>
              )}
            </StyledDiv>
          </AsyncLoader>
        </DashboardSection>
        {showForm && (
          <CustomerContributionForm
            action={formId ? 'Update' : 'Create'}
            contributionEligibleAccounts={
              formId ? contributionEligibleAccounts : contributionAvailableAccounts
            }
            closeForm={this.closeForm}
            data={form}
            errors={errors}
            isLoading={actionRequest.isLoading}
            isOpen={showForm}
            onChange={this.onChange}
            onSubmit={formId ? this.onUpdate : this.onCreate}
            contribution={contributionData}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, requests, errors, form } = state.customerContribution;
  return {
    token: getToken(state),
    id: getUserId(state),
    data: cache,
    errors: errors,
    form: form,
    request: requests.loadCollection,
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    toDos: safeAccess(state, 'todos.cache', {}),
    contributionData: getContributionData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: (id) => {
    dispatch(accountsLoadCollection());
    dispatch(contributionLoadResource(id));
    dispatch(contributionSetupLoadResource(id));
    dispatch(customerContributionLoadCollection());
  },
  clearForm: () => dispatch(customerContributionClearForm()),
  createCustomerContribution: async () => {
    await dispatch(customerContributionCreateResource());
    dispatch(todosLoadCollection());
  },
  handleForm: (contribution, attributes) =>
    dispatch(customerContributionHandleFormChange(contribution, attributes)),
  hydrateForm: async (id) => await dispatch(customerContributionCopyResourceToForm(id)),
  updateCustomerContribution: async (id) => {
    await dispatch(customerContributionUpdateResource(id));
    dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ActiveContributions));
