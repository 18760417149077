import React from 'react';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import {
  Button,
  Form,
  InputAddon,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { useFocusOnMount } from '../../utils';

const SignInCRMForm = ({ handleChange, onSubmit, ...props }) => {
  const firstInput = useFocusOnMount();
  return (
    <Form id="signInForm" handleChange={handleChange} onSubmit={onSubmit} {...props}>
      <InputWrapper>
        <InputLabel>Username</InputLabel>
        <InputGroup>
          <InputAddon>
            <FontAwesomeIcon icon={faUser} fixedWidth />
          </InputAddon>
          <InputText
            inputRef={firstInput}
            name="username"
            autoComplete="username"
            placeholder="Username"
          />
        </InputGroup>
      </InputWrapper>
      <InputWrapper>
        <InputLabel>Password</InputLabel>
        <InputGroup>
          <InputAddon>
            <FontAwesomeIcon icon={faLock} fixedWidth />
          </InputAddon>
          <InputText
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
          />
        </InputGroup>
      </InputWrapper>
      <Button brand="primary" type="submit" onClick={onSubmit}>
        Login
      </Button>
    </Form>
  );
};

SignInCRMForm.propTypes = {
  handleChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
};

export default SignInCRMForm;
