import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { BrandColor, Card, CardBody, CardHeader, CardText, Header5 } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const ContributionQualify = ({ amount }) => (
  <Card brand="primary">
    <CardHeader>Contribution</CardHeader>
    <CardBody>
      <CardText>
        <Header5>How should I allocate my employer contribution?</Header5>
        <Spaced>
          Your employer has allocated
          <strong>
            <BrandColor brand="primary"> {formatAsMoney(amount)} </BrandColor>
          </strong>
          for you to use towards your student loans per month.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
);

ContributionQualify.propTypes = {
  amount: t.number.isRequired,
};

export default ContributionQualify;
