import { FormValidator } from '../../utils';

export class CustomerContributionValidator extends FormValidator {
  loanId(validator) {
    validator.isRequired();
  }

  contributionType(validator) {
    validator.isRequired();
  }

  employerContribution(validator) {
    validator.isRequired();
  }

  employeeContribution(validator) {
    validator.isRequired();
  }

  active(validator) {
    validator.isRequired();
  }
}
