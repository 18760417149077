import React from 'react';
import t from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputAddon,
  InputHelp,
  InputNumeric,
  InputText,
} from 'fiducius-ui';

import { formatAsMoney } from '../../../utils/format';
import { MessageDiv } from '../../../messages';

class RetirementForm extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    agi: t.number,
  };

  constructor(props) {
    super(props);
    this.retirementVal = React.createRef();

    this.state = {
      income: this.props.agi,
      famSize: '',
      retirementAmount: 0,
      retirementAmountOmega: 0,
      totalLoan: 0,
    };
    this.handleIncomeChange = this.handleIncomeChange.bind(this);
    this.handleFamSizeChange = this.handleFamSizeChange.bind(this);
    this.handleRetirementAmount = this.handleRetirementAmount.bind(this);
    this.handleRetirementAmountOmega = this.handleRetirementAmountOmega.bind(this);

    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleRetirementFormChange = this.handleRetirementFormChange.bind(this);
    this.handleTotalLoan = this.handleTotalLoan.bind(this);
  }

  handleIncomeChange(event) {
    this.setState({
      income: event.target.value,
      retirementAmount: (event.target.value * 0.05).toFixed(2),
      retirementAmountOmega: (event.target.value * 0.08).toFixed(2),
    });
  }
  handleFamSizeChange(event) {
    this.setState({ famSize: event.target.value });
  }
  handleRetirementFormChange(event) {
    this.handleRetirementChange(event.target.value);
  }

  handleRetirementChange = (value) => {
    this.setState({ ...this.state, retirementPercent: value });
  };

  handleSliderChange = (value) => {
    this.handleRetirementChange(value);
    this.retirementVal = value;
  };

  handleRetirementAmount(event) {
    this.setState({ retirementAmount: event.target.value });
  }

  handleRetirementAmountOmega(event) {
    this.setState({ retirementAmountOmega: event.target.value });
  }
  handleTotalLoan(event) {
    this.setState({ totalLoan: event.target.value });
  }

  render() {
    const { data = {}, onChange } = this.props;

    const agiIncome = this.state.income || data.agi;
    const retirePct = this.state.retirementPercent || 0;

    const agi = agiIncome - agiIncome * (retirePct / 100);
    const totalLoanBal = this.state.totalLoan || data.totalPrincipal;
    let povertyGuideline = data.povertyGuideline;

    let repaymentPlanValue;
    //assigns a value modifier depending on repayment plan time you qualify for.
    if (data.repaymentPlan === 'PAYE' || data.repaymentPlan === 'REPAYE') {
      repaymentPlanValue = 0.1;
    } else if (data.repaymentPlan === 'ICR') {
      repaymentPlanValue = 0.2;
    } else if (data.repaymentPlan === 'SAVE') {
      repaymentPlanValue = data.saveMultiplier;
      povertyGuideline = data.savePovertyGuideline;
    } else {
      repaymentPlanValue = 0.15;
    }

    let idr = ((agi - povertyGuideline) * repaymentPlanValue) / 12;

    if (idr < 0) {
      idr = 0;
    }

    //default values for retirement amounts
    let alpha = 0;
    let omega = 0;
    if (this.state.retirementAmount === 0) {
      alpha = 0.05 * agiIncome;
    } else {
      alpha = this.state.retirementAmount;
    }

    if (this.state.retirementAmountOmega === 0) {
      omega = 0.08 * agiIncome;
    } else {
      omega = this.state.retirementAmountOmega;
    }

    //forgiveness calculation with 2% increase to AGI
    let forgivenessAGIAlpha = agiIncome - alpha;
    let forgivenessAGIOmega = agiIncome - omega;
    let totalIDRPaymentsAlpha = 0;
    let totalIDRPaymentsOmega = 0;
    let i;
    for (i = 0; i < 10; i++) {
      if ((forgivenessAGIAlpha - povertyGuideline) * repaymentPlanValue > 0) {
        totalIDRPaymentsAlpha += (forgivenessAGIAlpha - povertyGuideline) * repaymentPlanValue;
        totalIDRPaymentsOmega += (forgivenessAGIOmega - povertyGuideline) * repaymentPlanValue;
        forgivenessAGIAlpha *= 1.02;
        forgivenessAGIOmega *= 1.02;
      } else {
        forgivenessAGIAlpha *= 1.02;
        forgivenessAGIOmega *= 1.02;
      }
    }

    //Retirement Nest Egg Calculation. Compounding interest, compounding once annually.
    let principalAlpha = alpha;
    let principalOmega = omega;

    for (i = 0; i < 9; i++) {
      principalAlpha = (principalAlpha * 1.06) / 1;
      principalOmega = (principalOmega * 1.06) / 1;

      principalAlpha = alpha / 1 + principalAlpha / 1;
      principalOmega = omega / 1 + principalOmega / 1;
    }

    // table calcs
    let alphaIDR = ((agiIncome - alpha - povertyGuideline) * repaymentPlanValue) / 12;
    let omegaIDR = ((agiIncome - omega - povertyGuideline) * repaymentPlanValue) / 12;

    if (alphaIDR < 0) {
      alphaIDR = 0;
    }
    if (omegaIDR < 0) {
      omegaIDR = 0;
    }

    let totalForgivenessAlpha = totalLoanBal - totalIDRPaymentsAlpha;
    let totalForgivenessOmega = totalLoanBal - totalIDRPaymentsOmega;

    if (totalForgivenessAlpha < 0) {
      totalForgivenessAlpha = 0;
    }
    if (totalForgivenessOmega < 0) {
      totalForgivenessOmega = 0;
    }
    //const id = data.id || 'create';

    let alphaPercent = 0;
    let omegaPercent = 0;

    if ((alpha / agiIncome) * 100 > 0) {
      alphaPercent = ((alpha / agiIncome) * 100).toFixed(1);
    }

    if ((omega / agiIncome) * 100 > 0) {
      omegaPercent = ((omega / agiIncome) * 100).toFixed(1);
    }

    return (
      <Form id="retirement" defaults={data} handleChange={onChange}>
        <CardTitle>Retirement Contribution Tool</CardTitle>
        <p>
          <MessageDiv messageType={'Retirement_Comparison'} />
        </p>
        <CardBody>
          <ui>
            <li>
              This tool is used to show the benefit of contributing more to a retirement fund
              annually.
            </li>
          </ui>
        </CardBody>
        <div className="row">
          <div className="col-6 col-lg-4">
            <InputWrapper>
              <InputLabel htmlFor="income">Annual Income</InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputText
                  id="income"
                  name="income"
                  defaultValue={agiIncome}
                  onChange={this.handleIncomeChange}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-6 col-md-4">
            <InputWrapper>
              <InputLabel htmlFor="Household size">Loan Balance</InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputText
                  id="famSize"
                  name="HouseHoldSize"
                  min="0"
                  defaultValue={totalLoanBal}
                  onChange={this.handleTotalLoan}
                />
              </InputGroup>
              {/*<InputHelp>This information is only used for the Income Driven Repaymnet estimation</InputHelp>*/}
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4">
            <InputWrapper>
              <InputLabel htmlFor="Household size">
                Yearly Retirement Contribution <br /> {alphaPercent}% of your Annual Income
              </InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric
                  id="famSize"
                  name="CurrentYearlyRetirement"
                  min="0"
                  value={this.state.retirementAmount || (alpha / 1).toFixed(2)}
                  onChange={this.handleRetirementAmount}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-6 col-md-4">
            <InputWrapper>
              <InputLabel htmlFor="Household size">
                Yearly Retirement Contribution <br /> {omegaPercent}% of your Annual Income
              </InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric
                  id="famSize"
                  name="NewYearlyRetirement"
                  min="0"
                  value={this.state.retirementAmountOmega || (omega / 1).toFixed(2)}
                  onChange={this.handleRetirementAmountOmega}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>

        <div className="col-9 col-md-9">
          <Card brand="primary">
            <CardBody>
              <div className="row">
                <div className="col-6 col-md-6">
                  <CardTitle>{alphaPercent} % Retirement Contribution</CardTitle>
                  <dl>
                    <dt>Income After Retirement Contribution</dt>
                    <dd>{formatAsMoney((agiIncome - alpha).toFixed(2)) || '—'}</dd>
                    <dt>Monthly Retirement Contribution</dt>
                    <dd>{formatAsMoney((alpha / 12).toFixed(2)) || '—'}</dd>
                    <dt>Estimated Loan Forgiveness</dt>
                    <dd>{formatAsMoney((totalForgivenessAlpha / 1).toFixed(2)) || '—'}</dd>
                    <InputHelp>This figures assumes a 2% raise in income each year</InputHelp>
                    <dt>Retirement Balance in 10 years</dt>
                    <dd>{formatAsMoney((principalAlpha / 1).toFixed(2)) || '—'}</dd>
                    <InputHelp>This figure assumes a 6% rate of growth</InputHelp>
                    <dt>Estimated Monthly {data.repaymentPlan} Payment </dt>
                    <dd>{formatAsMoney((alphaIDR / 1).toFixed(2)) || '—'}</dd>
                  </dl>
                </div>
                <div className="col-6 col-md-6">
                  <CardTitle>{omegaPercent} % Retirement Contribution</CardTitle>
                  <dl>
                    <dt>Income After Retirement Contribution</dt>
                    <dd>{formatAsMoney((agiIncome - omega).toFixed(2)) || '—'}</dd>
                    <dt>Monthly Retirement Contribution</dt>
                    <dd>{formatAsMoney((omega / 12).toFixed(2)) || '—'}</dd>
                    <dt>Estimated Loan Forgiveness</dt>
                    <dd>{formatAsMoney((totalForgivenessOmega / 1).toFixed(2)) || '—'}</dd>
                    <InputHelp>This figures assumes a 2% raise in income each year</InputHelp>
                    <dt>Retirement Balance in 10 years</dt>
                    <dd>{formatAsMoney((principalOmega / 1).toFixed(2)) || '—'}</dd>
                    <InputHelp>This figure assumes a 6% rate of growth</InputHelp>
                    <dt>Estimated Monthly {data.repaymentPlan} Payment </dt>
                    <dd>{formatAsMoney((omegaIDR / 1).toFixed(2)) || '—'}</dd>
                  </dl>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Form>
    );
  }
}

export default RetirementForm;
