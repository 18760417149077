import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <strong>
      Wait for Employment Certification approval and Qualifying Payment update letters from MOHELA.
    </strong>
    <ul>
      <li>
        The Employment Certification approval states your form was accepted, while the Qualifying
        Payment update letter states your number of qualifying payments towards the Public Service
        Loan Forgiveness program{' '}
        <strong> as of the date your employer signed your employment certification form.</strong>{' '}
      </li>
      <li>
        These typically arrive <strong>one to two months</strong> after the form is submitted.
      </li>
    </ul>
    <strong>
      Do not confirm this step is finished until you have received both the Employment Certification
      approval and Qualifying Payment update letters.
    </strong>
    <br />
    <br />
    Questions? Please visit the Contact Us button on the left of your portal to reach out to us.
    {/*Contact us at <a href="tel:+15136455400">(513) 645-5400</a> Option: 2*/}
  </>
);

const RenewalEmpCertCheck = withRouter((props) => (
  <TodoComplete {...props} text={<TodoText />} title="Check on your Employment Certification(s)" />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewalEmpCertCheck);
