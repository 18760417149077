import actions from './actions';
import { getApi, keyById, patchApi, normalize, resourceOperationsGenerator } from '../../utils';
import { getToken } from '../../auth';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/plaid',
  resourceType: 'plaid',
};

const operations = resourceOperationsGenerator(
  'plaid',
  actions,
  null,
  null,
  null,
  null,
  null,
  endpointHandler,
  null
);

const getActivePlaidConnections = () => async (dispatch, getState) => {
  let data = [];
  try {
    const state = getState();
    await dispatch(actions.plaidLoadResourceRequest());
    data = await getApi(getToken(state), `/plaid`);

    await dispatch(actions.plaidLoadResourceResponse());

    const cache = {
      ...getState().plaid.cache,
      ...keyById(data),
    };

    await dispatch(operations.plaidHandleCacheChange(cache));
    await dispatch(actions.plaidUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.plaidHandleError(error));
    dispatch(actions.plaidLoadResourceFailure(error));
  }
};

const getPlaidToken = (id) => async (dispatch, getState) => {
  let data = [];
  try {
    const state = getState();
    await dispatch(actions.plaidLoadResourceRequest());
    data = await getApi(getToken(state), `/plaid/${id}`);

    await dispatch(actions.plaidLoadResourceResponse());

    const cache = {
      ...getState().plaid.cache,
      ...keyById(data),
    };

    await dispatch(operations.plaidHandleCacheChange(cache));
    await dispatch(actions.plaidUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.plaidHandleError(error));
    dispatch(actions.plaidLoadResourceFailure(error));
  }
};

const setPlaidPublicToken = (id, token, metadata) => async (dispatch, getState) => {
  try {
    await dispatch(actions.plaidUpdateResourceRequest());
    let cache = getState().plaid.cache;
    cache = {
      cache,
      publicToken: token,
      linkToken: cache[id].linkToken,
      institutionId: metadata.institution.institution_id,
    };
    let patchData = normalize('plaid', cache);
    let data = await patchApi(getToken(getState()), `/plaid/${id}`, patchData);
    await dispatch(actions.plaidUpdateResourceResponse());
    cache = { ...getState().plaid.cache, ...keyById(data) };
    await dispatch(operations.plaidHandleCacheChange({ ...cache }));
    await dispatch(actions.plaidUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.plaidHandleError(error));
    dispatch(actions.plaidUpdateResourceFailure(error));
  }
};

const unlinkPlaidAccount = (id) => async (dispatch, getState) => {
  try {
    dispatch(actions.plaidUpdateResourceRequest());
    let cache = getState().plaid.cache;
    cache = { cache, action: 'unlink' };
    let patchData = normalize('plaid', cache);
    let data = await patchApi(getToken(getState()), `/plaid/${id}`, patchData);
    dispatch(actions.plaidUpdateResourceResponse());
    cache = { ...getState().plaid.cache, ...keyById(data) };
    await dispatch(operations.plaidHandleCacheChange({ ...cache }));
    dispatch(actions.plaidUpdateResourceSuccess());
  } catch (error) {
    dispatch(operations.plaidHandleError(error));
    dispatch(actions.plaidUpdateResourceFailure(error));
  }
};

const savePlaidLinkMetadata = (event, metadata) => async (dispatch, getState) => {
  try {
    let cache = getState().plaid.cache;
    cache = { cache, action: event, metadata: metadata };
    let patchData = normalize('plaid', cache);
    await patchApi(getToken(getState()), '/plaid/0', patchData);
  } catch (error) {
    dispatch(operations.plaidHandleError(error));
    dispatch(actions.plaidUpdateResourceFailure(error));
  }
};

const { plaidHandleFormChange, plaidUpdateResource } = operations;

export {
  plaidHandleFormChange,
  plaidUpdateResource,
  getPlaidToken,
  setPlaidPublicToken,
  getActivePlaidConnections,
  unlinkPlaidAccount,
  savePlaidLinkMetadata,
};

export default operations;
