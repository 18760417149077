import React, { useRef, useState } from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas';
import { Button, InputLabel, InputWrapper } from 'fiducius-ui';

import { safeAccess, useResize } from '../../utils';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
`;

const InputLabel2 = styled(InputLabel)`
  color: ${(p) => p.theme.textPrimary};
`;

const CanvasWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  border: ${(p) => p.theme.borderWidth} solid ${(p) => p.theme.level2};
  border-radius: ${(p) => p.theme.borderRadius};
`;

const Signature = ({ handleChange, label }) => {
  const wrapper = useRef(null);
  const canvas = useRef(null);
  const [width, setWidth] = useState(0);
  useResize(wrapper, setWidth);

  const clearSignature = () => {
    if (canvas && canvas.current) {
      canvas.current.clear();
    }
  };

  const handleMouseEvents = () => {
    const current = safeAccess(canvas, 'current');
    const curves = current ? current.toData() : [];
    const data =
      curves.length > 0 ? current.toDataURL().replace('data:image/png;base64,', '') : null;
    // console.log(current, curves, data);
    handleChange(data);
  };

  return (
    <>
      <InputWrapper
        onMouseUp={handleMouseEvents}
        onMouseLeave={handleMouseEvents}
        onTouchEnd={handleMouseEvents}
      >
        <InputLabel2>{label}</InputLabel2>
        <CanvasWrapper ref={wrapper}>
          <SignatureCanvas
            ref={canvas}
            canvasProps={{ width: width, height: '200px' }}
            maxWidth={5}
          />
        </CanvasWrapper>
      </InputWrapper>
      <ButtonWrapper>
        <Button invert brand="primary" onClick={clearSignature}>
          Clear signature
        </Button>
      </ButtonWrapper>
    </>
  );
};

Signature.propTypes = {
  handleChange: t.func.isRequired,
  label: t.string.isRequired,
};

export default Signature;
