import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';
import { PasswordAuthValidator } from './validators';

const passwordAuthValidator = new PasswordAuthValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/update-password',
  resourceType: 'update-password',
};

const operations = resourceOperationsGenerator(
  'passwordauth',
  actions,
  null,
  passwordAuthValidator,
  null,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  passwordauthLoadResource,
  passwordauthUpdateResource,
  passwordauthHandleFormChange,
  passwordauthCopyResourceToForm,
  passwordauthClearForm,
  passwordauthCreateResource,
} = operations;

export {
  passwordauthLoadResource,
  passwordauthUpdateResource,
  passwordauthHandleFormChange,
  passwordauthCopyResourceToForm,
  passwordauthClearForm,
  passwordauthCreateResource,
};

export default operations;
