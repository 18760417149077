import React from 'react';
import styled from 'styled-components';
import { Card, CardBody, CardHeader, CardText, Header5 } from 'fiducius-ui';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const SimpliFiQualify = () => (
  <Card brand="primary">
    <CardHeader>SimpliFi</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Is there a way to simplify all the loans in my portfolio?</Header5>
        <Spaced>
          It may make sense for you to consolidate your loans and potentially lower your payments.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
);

export default SimpliFiQualify;
