import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Header5,
} from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';
import DocsNeededForRSUpload from '../components/docs-upload-needed';

import { renewalResultsLoadResource } from '../../../renewal-results';

class EmpCertRSFS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  render() {
    const { data, request } = this.props;

    return (
      <div>
        <ForgivenessProgress />
        {data.empCertReceived && data.empCertApproved === false && (
          <DashboardSection>
            <Card>
              <AsyncLoader request={request}>
                <Card>
                  <CardBody>
                    <CardTitle>Employment Certification Tracking</CardTitle>
                    <CardText>
                      <Header5>
                        <p>
                          We are tracking your Employment Certification form(s) to ensure your
                          employment is verified and your loan payments, from this past year, are
                          certified for your loan forgiveness.
                        </p>
                      </Header5>
                    </CardText>
                  </CardBody>
                </Card>
              </AsyncLoader>
            </Card>
          </DashboardSection>
        )}
        {data.empCertReceived === false && (
          <DashboardSection>
            <Card>
              <AsyncLoader request={request}>
                <Card>
                  <CardBody>
                    <CardTitle>Employment Certification Tracking</CardTitle>
                    <CardText>
                      <Header5>
                        <p>
                          Your Employment Certification form has not been received. If the document
                          is not listed below, please contact us.{' '}
                        </p>
                        <p>
                          <DocsNeededForRSUpload documentButton={true} />
                        </p>
                      </Header5>
                    </CardText>
                  </CardBody>
                </Card>
              </AsyncLoader>
            </Card>
          </DashboardSection>
        )}
        {data.empCertReceived && data.empCertApproved === true && (
          <DashboardSection>
            <Card>
              <AsyncLoader request={request}>
                <Card>
                  <CardBody>
                    <CardTitle>Employment Certification Tracking</CardTitle>
                    <CardText>
                      <Header5>
                        <p>Your Employment Certification was successfully processed.</p>
                      </Header5>
                    </CardText>
                  </CardBody>
                </Card>
              </AsyncLoader>
            </Card>
          </DashboardSection>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `renewalResults.cache[${getUserId(state)}]`) || {},
    request: state.renewalResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(renewalResultsLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertRSFS));
