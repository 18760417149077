import actions from './actions';
import RegisterValidator from './validators';
import { persistClearState, persistSaveState } from '../../persist';
import { getRefreshToken, getToken, getUserId } from './selectors';
import { todosLoadCollection } from '../../todos';
import {
  baseFetch,
  createErrorState,
  getApiSingle,
  keyById,
  normalize,
  patchApi,
  postApi,
  resourceOperationsGenerator,
  safeAccess,
} from '../../utils';

export const anonAuthLogin = () => async (dispatch) => {
  const data = {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
    grant_type: 'client_credentials',
    scope: 'user.create',
  };

  const formData = Object.keys(data).reduce((form, k) => {
    form.append(k, data[k]);
    return form;
  }, new FormData());

  const opts = {
    body: formData,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    method: 'POST',
  };

  try {
    dispatch(actions.authLoginRequest());
    const data = await baseFetch(`${process.env.REACT_APP_AUTH_BASE_URL}/connect/token`, opts);
    // Normalize the token data to camelCase and to conform to the API
    if (data) {
      data['accessToken'] = data.access_token ? data.access_token : null;
      delete data.access_token;
      data['refreshToken'] = data.refresh_token ? data.refresh_token : null;
      delete data.refresh_token;
      data['tokenExpiresIn'] = data.expires_in ? data.expires_in : null;
      delete data.expires_in;
      data['tokenType'] = data.token_type ? data.token_type : null;
      delete data.token_type;
    }
    dispatch(actions.authLoginResponse());
    await Promise.all(dispatch(authPopulate(data)));
    dispatch(actions.authLoginSuccess());
  } catch (error) {
    dispatch(actions.authLoginFailure());
  }
};

export const authCRMLogin = (username, password) => async (dispatch) => {
  await dispatch(
    authLogin(
      username,
      password,
      process.env.REACT_APP_AUTH_CRM_CLIENT_ID,
      process.env.REACT_APP_AUTH_CRM_CLIENT_SECRET,
      'openid fiducius.read'
    )
  );
};

export const authClientLogin = (username, password) => async (dispatch) => {
  await dispatch(
    authLogin(
      username,
      password,
      process.env.REACT_APP_AUTH_CLIENT_ID,
      process.env.REACT_APP_AUTH_CLIENT_SECRET,
      'offline_access openid profile fiducius.read fiducius.write'
    )
  );
};

export const authLogin = (username, password, id, secret, scope) => async (dispatch) => {
  const data = {
    client_id: id,
    client_secret: secret,
    grant_type: 'password',
    scope: scope,
    password,
    username,
  };

  const formData = Object.keys(data).reduce((form, k) => {
    form.append(k, data[k]);
    return form;
  }, new FormData());

  const opts = {
    body: formData,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    method: 'POST',
  };
  try {
    dispatch(actions.authLoginRequest());
    const data = await baseFetch(`${process.env.REACT_APP_AUTH_BASE_URL}/connect/token`, opts);
    // Normalize the token data to camelCase and to conform to the API
    if (data) {
      data['accessToken'] = data.access_token ? data.access_token : null;
      delete data.access_token;
      data['refreshToken'] = data.refresh_token ? data.refresh_token : null;
      delete data.refresh_token;
      data['tokenExpiresIn'] = data.expires_in ? data.expires_in : null;
      delete data.expires_in;
      data['tokenType'] = data.token_type ? data.token_type : null;
      delete data.token_type;
    }
    dispatch(actions.authLoginResponse());
    await dispatch(authPopulate(data));
    await Promise.all([dispatch(authLoadPermissions()), dispatch(todosLoadCollection())]);
    dispatch(actions.authLoginSuccess());
  } catch (error) {
    dispatch(actions.authLoginFailure());
  }
};

export const authSilentRenew = () => async (dispatch, getState) => {
  const data = {
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
    grant_type: 'refresh_token',
    scope: 'offline_access openid profile fiducius.read fiducius.write',
    refresh_token: getRefreshToken(getState()),
  };

  const formData = Object.keys(data).reduce((form, k) => {
    form.append(k, data[k]);
    return form;
  }, new FormData());

  const opts = {
    method: 'POST',
    body: formData,
  };

  try {
    dispatch(actions.authSilentRenewRequest());
    const data = await baseFetch(`${process.env.REACT_APP_AUTH_BASE_URL}/connect/token`, opts);
    // Normalize the token data to camelCase and to conform to the API
    if (data) {
      data['accessToken'] = data.access_token ? data.access_token : null;
      delete data.access_token;
      data['refreshToken'] = data.refresh_token ? data.refresh_token : null;
      delete data.refresh_token;
      data['tokenExpiresIn'] = data.expires_in ? data.expires_in : null;
      delete data.expires_in;
      data['tokenType'] = data.token_type ? data.token_type : null;
      delete data.token_type;
    }
    dispatch(actions.authSilentRenewResponse());
    await dispatch(authPopulate(data));
    await Promise.all([dispatch(authLoadPermissions()), dispatch(todosLoadCollection())]);
    dispatch(actions.authSilentRenewSuccess());
  } catch (error) {
    dispatch(actions.authSilentRenewFailure());
  }
};

export const authLoadPermissions = () => async (dispatch, getState) => {
  // TODO: serve from cache and short-circuit
  const state = getState();
  try {
    dispatch(actions.authLoadPermissionsRequest());
    let data = await getApiSingle(getToken(state), `/customer-status/${getUserId(state)}`);

    dispatch(actions.authLoadPermissionsResponse());

    const cache = {
      ...getState().auth.cache,
      permissions: { ...data, benefits: { ...keyById(data.benefits) } },
    };

    await dispatch(authPopulate(cache));
    dispatch(actions.authLoadPermissionsSuccess());
  } catch (error) {
    await dispatch(actions.authHandleError(error));
    dispatch(actions.authLoadPermissionsFailure(error));
  }
};

export const authLogout = () => async (dispatch, getState) => {
  // TODO: Don't really need a request here...
  try {
    dispatch(actions.authLogoutRequest());
    dispatch(actions.authLogoutResponse());
    await dispatch(persistClearState());
    dispatch(actions.authLogoutSuccess());
  } catch (error) {
    dispatch(actions.authLogoutFailure());
  }
};

// Password reset
export const authPasswordReset = (email) => async (dispatch) => {
  try {
    dispatch(actions.authPasswordResetRequest());
    await getApiSingle(null, '/password-reset/' + email);
    dispatch(actions.authPasswordResetResponse());
    await dispatch(persistClearState());
    dispatch(actions.authPasswordResetSuccess());
  } catch (error) {
    dispatch(actions.authPasswordResetFailure());
  }
};

export const authThemePreference = (domain) => async (dispatch, getState) => {
  try {
    dispatch(actions.authLoadThemeRequest());
    const data = await getApiSingle(null, '/settings/' + domain);
    dispatch(actions.authLoadThemeResponse());
    dispatch(actions.authLoadThemeSuccess());

    return data;
  } catch (error) {
    dispatch(actions.authLoadThemeFailure());
  }
};

export const authPasswordOutOfDateReset = (
  token,
  email,
  oldPassword,
  newPassword,
  password
) => async (dispatch, getState) => {
  const state = getState();
  const patchData = normalize(
    'password-reset',
    {
      token: token,
      oldPassword: oldPassword,
      password: password,
      confirmPassword: newPassword,
    },
    email
  );

  try {
    dispatch(actions.authPasswordUpdateRequest());
    await patchApi(getToken(state), '/password-reset/' + email, patchData);
    dispatch(actions.authPasswordUpdateResponse());
    await dispatch(persistClearState());
    await dispatch(authClientLogin(email, password));
    dispatch(actions.authPasswordUpdateSuccess());
  } catch (error) {
    dispatch(authHandleError(error));
    dispatch(actions.authPasswordUpdateFailure());
  }
};

export const authPasswordUpdate = (token, email, password, confirmPassword) => async (
  dispatch,
  getState
) => {
  const patchData = normalize(
    'password-reset',
    {
      token: token,
      password: password,
      confirmPassword: confirmPassword,
    },
    email
  );

  try {
    dispatch(actions.authPasswordUpdateRequest());
    await patchApi(null, '/password-reset/' + email, patchData);
    dispatch(actions.authPasswordUpdateResponse());
    await dispatch(persistClearState());
    dispatch(actions.authPasswordUpdateSuccess());
  } catch (error) {
    dispatch(authHandleError(error));
    dispatch(actions.authPasswordUpdateFailure());
  }
};

export const authGetPartnerLocations = (domain) => async (dispatch, getState) => {
  try {
    dispatch(actions.authPartnerLocationRequest());
    const data = await getApiSingle(null, '/strategic-partner-ext/' + domain);
    const authData = { ...getState().auth.cache, partnerLocations: data };
    dispatch(actions.authPartnerLocationResponse());
    await dispatch(authPopulate(authData));
    dispatch(actions.authPartnerLocationSuccess());
  } catch (error) {
    dispatch(authHandleError(error));
    dispatch(actions.authPartnerLocationFailure(error));
  }
};

export const authPopulate = (data) => async (dispatch) => {
  dispatch(actions.authPopulate(data));
  await dispatch(persistSaveState());
};

export const authHandleError = (error, attributes = null) => async (dispatch, getState) => {
  const prevErrors = getState().auth.errors;
  const errorState = createErrorState(error, prevErrors, attributes);
  dispatch(actions.authHandleError(errorState));
};

const authHandleRegistrationError = (error, attributes = null) => async (dispatch, getState) => {
  const prevErrors = getState().auth.errors;
  const errorState = createErrorState(error, prevErrors, attributes);
  dispatch(actions.authHandleError(errorState));

  const domains = window.location.hostname.split('.');
  const domain = domains.length > 2 ? domains[domains.length - 3] : 'islsoffice';
  await dispatch(authGetPartnerLocations(domain));
};

const getUrlParams = () => {
  var match,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function(s) {
      return decodeURIComponent(s.replace(pl, ' '));
    },
    query = window.location.search.substring(1);
  let urlParams = [];
  while ((match = search.exec(query))) {
    const data = { 'field-name': decode(match[1]), 'field-value': decode(match[2]) };
    urlParams.push(data);
  }

  return urlParams;
};

export const authSignup = (formData) => async (dispatch, getState) => {
  try {
    await dispatch(anonAuthLogin());
    dispatch(actions.authSignupRequest());
    const state = getState();
    formData.partnerDefinedFields = getUrlParams();
    const data = await postApi(
      getToken(state),
      '/register-ext',
      normalize('register-ext', formData)
    );
    dispatch(actions.authSignupResponse());
    await dispatch(authPopulate(data[0]));
    await Promise.all([dispatch(authLoadPermissions()), dispatch(todosLoadCollection())]);
    dispatch(actions.authSignupSuccess());
  } catch (error) {
    dispatch(authHandleRegistrationError(error));
    dispatch(actions.authSignupFailure(error));
  }
};

const authValidateAttributes = (attributes) => async (dispatch, getState) => {
  const formState = getState().auth.form;
  try {
    dispatch(actions.authInvalidateAttributes(attributes, getState().auth.errors));
    const validator = new RegisterValidator();
    validator.validateAttributes(attributes, formState);
  } catch (error) {
    dispatch(authHandleError(error, attributes));
  } finally {
    dispatch(actions.authValidateAttributes(attributes, getState().auth.errors));
  }
};

export const authHandleFormChange = (data, attributes = null) => async (dispatch, getState) => {
  dispatch(actions.authHandleFormChange(data));
  dispatch(actions.authInvalidateCache());
  dispatch(authValidateAttributes(attributes));
};

const endpointHandler = {
  endpoint: '/login',
  customResponseHandler: null,
  resourceType: 'login',
};

const operations = resourceOperationsGenerator(
  'login',
  actions,
  null,
  null,
  endpointHandler,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  loginLoadResource,
  loginClearForm,
  loginUpdateResource,
  loginHandleFormChange,
  loginCopyResourceToForm,
  loginCreateResource,
} = operations;

export {
  loginCopyResourceToForm,
  loginLoadResource,
  loginHandleFormChange,
  loginClearForm,
  loginUpdateResource,
  loginCreateResource,
};
