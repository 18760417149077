import styled from 'styled-components';
import { Badge } from 'fiducius-ui';

const PaddedBadge = styled(Badge)`
  margin-left: 0.45em;
  font-size: 0.85em;
  top: -0.75rem;
`;

export default PaddedBadge;
