import React from 'react';
import t from 'prop-types';
import {
  Form,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';

const AccountForm = ({ data = {}, errors = {}, handleChange }) => (
  <Form id="account" handleChange={handleChange} defaults={data}>
    <InputWrapper error={!!errors.contactEmail}>
      <InputLabel htmlFor="contactEmail" required>
        Contact Email
      </InputLabel>
      <InputError>{safeAccess(errors, 'contactEmail.detail')}</InputError>
      <InputGroup>
        <InputText
          name="contactEmail"
          //type="email" - this does not accomidate multiple emails.
          placeholder="Email"
          autoComplete="email username"
          defaultValue={data.contactEmail}
        />
      </InputGroup>
      <InputHelp>Please note that this will not update the email you use to log in.</InputHelp>
    </InputWrapper>
  </Form>
);

AccountForm.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func.isRequired,
};

export default AccountForm;
