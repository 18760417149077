import React from 'react';
import t from 'prop-types';
import { Button, Card, CardBody, CardFooter, CardHeader, CardText, Modal } from 'fiducius-ui';

import { FlexBetween } from '../../root';

class UploadSuccess extends React.Component {
  static propTypes = {
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isOpen: t.bool,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    trFileUpload: t.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {}

  getMessage = () => {
    const { errors } = this.props;
    let message;

    if (errors && errors.detail) {
      message = errors.detail;
    } else {
      message = 'Your file has been successfully uploaded!';
    }

    return message;
  };

  render() {
    const { closeForm, errors, isOpen, trFileUpload } = this.props;

    //alert(errors.length)
    return (
      <Modal ref={this.modalRef} isOpen={isOpen}>
        <Card brand={errors === undefined ? 'success' : 'danger'}>
          <CardHeader>{errors === undefined ? 'Upload Confirmation' : 'Upload Error'}</CardHeader>
          <CardBody>
            <CardText>
              {this.getMessage()}
              <p></p>
              {trFileUpload && (
                <p>
                  <b>Please Note:</b> Transcripts and proofs of payment can be uploaded multiple
                  times. If an additional document of this type is needed, it will be requested.
                </p>
              )}
            </CardText>
          </CardBody>
          <CardFooter>
            <FlexBetween>
              <Button brand="lowContrast" onClick={closeForm}>
                Continue
              </Button>
            </FlexBetween>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default UploadSuccess;
