import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { AsyncLoader, Card, CardBody, DashboardSection } from 'fiducius-ui';

import FreshStartProgress from '../components/fresh-start-progress';
import { mergeRequestStatuses } from '../../../utils';
import { authLoadPermissions } from '../../../auth';
import { withPermissions } from '../../../routing';
import { todosLoadCollection, todosSendUpdate } from '../../../todos/redux/operations';

import styled from 'styled-components';

const EmbedContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border: 0;
`;

const EmbedContainerIFrame = styled.iframe`
    position: absolute; 
    top: 0; left: 0; bottom: 0; right 0;
    width: 100%; 
    height: 100%;
    border: 0; 
`;

const FreshStartOverview = ({ request }) => {
  const [redirectUri, setRedirectUri] = useState('');

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <div className="row">
        <div className="col-12 col-lg-12">
          <AsyncLoader request={request}>
            <FreshStartProgress />
            <DashboardSection title="Fresh Start">
              <Card>
                <CardBody>
                  <AsyncLoader request={request} loading={<></>}>
                    <div className="row">
                      <div className="col-12 col-lg-8">
                        <EmbedContainer>
                          <EmbedContainerIFrame
                            src="https://player.vimeo.com/video/880524480?h=eb840c318e"
                            allow="autoplay; fullscreen; picture-in-picture"
                            title="Fresh Start Program"
                            allowfullscreen
                          ></EmbedContainerIFrame>
                        </EmbedContainer>
                      </div>
                    </div>
                    <ul>
                      <li>
                        Fresh Start is a one-time temporary program from the U.S. Department of
                        Education that offers the removal of default from your federal student loans
                        and other special benefits.
                      </li>
                      <li>
                        Other benefits include, but are not limited to, restoring access to federal
                        student aid, stopping collections, and access to short-term relief
                        (forbearance and deferment).
                      </li>
                    </ul>
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </AsyncLoader>
        </div>
      </div>
    );
  }
};

FreshStartOverview.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FreshStartOverview));
