import React from 'react';
import t from 'prop-types';
import { Card, CardBody, CardTitle } from 'fiducius-ui';

import { Fade, FullscreenContainer } from '../../routing';
import { debounce, emailPattern } from '../../utils';

import runQuickQualifier from '../redux/operations';
import LearnMore from '../components/learn-more';
import QualQuestions from '../components/qual-questions';
import QuickQualResults from '../components/quick-qual-results';

class QuickQual extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    showLearnMore: t.bool.isRequired,
    showQualifier: t.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  onSubmitClick = (e) => {
    e.persist();
    const { data, errors } = this.state;

    if (
      data.salary &&
      !errors.salary &&
      data.loanBalance &&
      !errors.loanBalance &&
      data.ppLoan &&
      data.maritalStatus &&
      data.householdSize &&
      !errors.householdSize &&
      data.bts
    ) {
      data.resultText = runQuickQualifier(
        data.salary,
        data.loanBalance,
        data.ppLoan,
        data.maritalStatus,
        data.householdSize,
        2010,
        data.bts,
        120
      );

      const { lbf, perCt, ibRepay, message } = data.resultText;
      data.lbf = lbf;
      data.perCt = perCt;
      data.ibRepay = ibRepay;
      data.message = message;
    }

    this.setState({ data, errors });
  };

  onBackClick = (e) => {
    e.persist();
    const { data, errors } = this.state;
    data.resultText = null;
    data.lbf = -1;
    data.perCt = 0;
    data.ibRepay = 0;
    data.message = null;
    this.setState({ data, errors });
  };

  handleChange = debounce((formState, id, attribute) => {
    let { data, errors } = this.state;
    data = { ...data, ...formState };
    errors = {};

    if (data.email && !data.email.match(emailPattern)) {
      errors['email'] = 'Invalid email';
    }

    if (data.salary) {
      if (isNaN(data.salary) || data.salary <= 0) {
        errors['salary'] = 'Invalid salary';
      }
    }

    if (data.loanBalance) {
      if (isNaN(data.loanBalance) || data.loanBalance <= 0 || data.loanBalance > 1000000) {
        errors['loanBalance'] = 'Invalid loan balance';
      }
    }

    if (data.householdSize) {
      if (isNaN(data.householdSize) || data.householdSize < 1 || data.householdSize > 10) {
        errors['householdSize'] = 'Invalid household size';
      }
    }

    this.setState({ data, errors });
  }, 250);

  render() {
    const { data, errors } = this.state;
    return (
      <FullscreenContainer className="container">
        <div className="row">
          <div className="col-12">
            <Fade id="showQuestions" show={isNaN(data.lbf) || data.lbf === -1}>
              {' '}
              <Card>
                <CardBody>
                  <CardTitle>Fiducius Quick Qualifier</CardTitle>
                  <Fade
                    id="learnMoreGate"
                    show={this.props.showLearnMore && !this.props.showQualifier}
                  >
                    <LearnMore
                      id="learn-more"
                      handleChange={this.handleChange}
                      data={data}
                      errors={errors}
                    />
                  </Fade>
                  <Fade id="qualifierGate" show={this.props.showQualifier}>
                    <QualQuestions
                      id="quick-qual"
                      handleChange={this.handleChange}
                      data={data}
                      errors={errors}
                      onSubmitClick={this.onSubmitClick}
                    />
                  </Fade>
                </CardBody>
              </Card>
            </Fade>
            <Fade id="showResults" show={typeof data.lbf === 'number' && data.lbf >= 0}>
              <QuickQualResults data={data} onBackClick={this.onBackClick} />
            </Fade>
          </div>
        </div>
      </FullscreenContainer>
    );
  }
}

export default QuickQual;
