import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../../forgiveness/components/renewal-confirmation';
import EmpCertProgress from '../components/emp-cert-progress';
import StepDisplay from '../../forgiveness/components/step-display';

class EmpCertCheck extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentDidUpdate() {}

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  getStep3RestrictionDate = () => {
    let retVal = undefined;
    const { permissions } = this.props;
    const empCertStep = permissions.enterpriseEmpcertSteps.find(
      (a) => a.id === 'CheckEnterpriseEmpcert'
    );
    if (empCertStep.stepDateRestriction !== undefined) {
      let stepRestriction = new Date(empCertStep.stepDateRestriction);
      retVal =
        (stepRestriction.getMonth() + 1).toString() +
        '/' +
        stepRestriction.getDate() +
        '/' +
        stepRestriction.getFullYear();
    }
    return retVal;
  };

  render() {
    const { request, permissions } = this.props;
    const restrictionDate = this.getStep3RestrictionDate();
    const reducedIdrRedirect =
      permissions.reducedIdrSteps !== undefined && permissions.reducedIdrSteps !== null;

    return (
      <>
        {reducedIdrRedirect && (
          <>
            <Redirect to={'/benefits/reducedidr/empcert-ss/step-3'} />
          </>
        )}
        <AsyncLoader request={request}>
          <div id="step3Section">
            <EmpCertProgress />
            <DashboardSection title="Check on your Employment Certification">
              <Card
                overlayCard={restrictionDate !== undefined && !permissions.isEmpcertCheckPaused}
              >
                {!permissions.isEmpcertCheckPaused && (
                  <CardBody overlayCard hideOverlay={restrictionDate === undefined}>
                    {restrictionDate !== undefined && (
                      <>
                        <CardTitle overlayCard>Wait for PSLF Application Results letter</CardTitle>
                        <CardText overlayCard>
                          <p>
                            Based on the average processing time for this document, it will be
                            processed by your loan servicer on {restrictionDate} at the earliest*.
                            You will not be able to confirm completion of this step until that date.
                          </p>
                          <p>
                            *Please note: Your document is not guaranteed to be processed by this
                            date. It is important to follow your steps closely.
                          </p>
                        </CardText>
                      </>
                    )}
                  </CardBody>
                )}
                {permissions.isEmpcertCheckPaused && (
                  <>
                    <CardBody overlayCard>
                      <>
                        <CardTitle overlayCard>Application Pause</CardTitle>
                        <CardText overlayCard>
                          <p>
                            This step can not be completed at this time. Beginning{' '}
                            <b>May 1, 2024</b>, the Department of Education changed how it processes
                            the Public Service Loan Forgiveness Program documentation. As a result,
                            the processing of Employment Certification documentation is paused{' '}
                            <b>until July 2024</b>. Please return to your MyFiducius portal and
                            complete it once Fiducius notifies you that the Department of Education
                            has resumed processing, projected for <b>July 2024</b>.
                          </p>
                        </CardText>
                      </>
                    </CardBody>
                  </>
                )}
                <CardBody
                  disableChildren={
                    restrictionDate !== undefined || permissions.isEmpcertCheckPaused
                  }
                >
                  <AsyncLoader request={this.props.request} loading={<></>}>
                    <StepDisplay title={'Step 1 – Wait for PSLF Application Results from MOHELA.'}>
                      <ul>
                        <li>The approval letter states your form has been accepted.</li>
                        <li>
                          There may also be an additional update letter which provides your number
                          of qualifying payments towards the Public Service Loan Forgiveness program
                          up to the date your employer signed your Employment Certification form.
                        </li>
                        <li>
                          These typically arrive <strong>one to two months</strong> after the form
                          is submitted.{' '}
                        </li>
                        <li>
                          If your employment certification form is denied by your loan servicer,
                          please select <strong>Employment Certification Denied</strong> below. A
                          member of your Fiducius team will be contact within 3 business days to
                          address the issue.
                        </li>
                      </ul>
                      <strong>
                        Do not confirm this step is finished until you have received both the PSLF
                        Application Results and Qualifying Payment update letters.
                      </strong>
                    </StepDisplay>
                  </AsyncLoader>
                  {!permissions.isEmpcertCheckPaused && <RenewalConfirmation stepId={815} />}
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(EmpCertCheck));
