import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as faXIcon } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { getTuitionData } from '../redux/selectors';
import { withPermissions } from '../../../routing';

import {
  Button,
  Card,
  CardBody,
  CardText,
  Header2,
  Lede,
  CardFooter,
  ButtonCarousel,
  Table,
} from 'fiducius-ui';

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledLede = styled(Lede)`
  color: ${(p) => (p.theme.themeName === 'greenlight' ? p.theme.textPrimary : 'inherit')};
`;

const AlignTextLeft = styled.div`
  text-align: left;
`;

const AlignTextRight = styled.div`
  text-align: right;
`;

const StyledCardText = styled(CardText)`
  margin-top: -1.5em;
`;

class TuitionSetupSummary extends React.Component {
  static propTypes = {
    data: t.object.isRequired,
    closeModal: t.func.isRequired,
    trSetUp: t.object.isRequired,
    permissions: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {}; // must be initialized for data handling
  }

  getGeneralInformation(data) {
    //General info
    let qualifyingPrograms;
    if (data.enrollmentQualifyingPrograms === 'COMBO') {
      qualifyingPrograms =
        "You can enroll in any institution accredited by the Department of Education's Office of Post-secondary Education, and any institutions pre-approved by your employer.";
    } else if (data.enrollmentQualifyingPrograms === 'PREAPPR') {
      qualifyingPrograms =
        'You can enroll only in institutions that are pre-approved by your employer.';
    } else if (data.enrollmentQualifyingPrograms === 'DOE') {
      qualifyingPrograms =
        "You can enroll in any institution accredited by the Department of Education's Office of Post-secondary Education.";
    } else if (data.enrollmentQualifyingPrograms === 'MANUAL') {
      qualifyingPrograms = 'Qualifying institutions are manually approved.';
    }

    let certificationsAllowed;
    if (data.certificationsAllowed) {
      certificationsAllowed = 'Certifications are allowed';
      if (data.pslfQualification === 'CERTONLY') {
        certificationsAllowed +=
          ' even if you qualify for student loan forgiveness through Fiducius.';
      } else {
        certificationsAllowed += '.';
      }
    } else if (!data.certificationsAllowed) {
      certificationsAllowed = 'Certifications are not allowed.';
    }

    let benefitLimit;
    if (data.benefitEnrollmentEnd === 'CLOSED') {
      benefitLimit =
        "You can enroll at any point until your employer's budgetary limit for the benefit is reached.";
    } else if (data.benefitEnrollmentEnd === 'NONE') {
      certificationsAllowed = 'You can enroll at any point.';
    }

    let generalReimbTimeframe;
    if (data.reimbursementTime === 'BOTHHRLY') {
      generalReimbTimeframe =
        'You will be reimbursed around the start of your term/semester if your hourly wage is under $' +
        Math.round(data.reimbursementTimeMaxWage, 2) +
        '. If your hourly wage is over $' +
        Math.round(data.reimbursementTimeMaxWage, 2) +
        ', you will be reimbursed at the end of your term/semester.';
    } else if (data.reimbursementTime === 'BOTHSLRY') {
      generalReimbTimeframe =
        'You will be reimbursed around the start of your term/semester if your yearly salary is under $' +
        Math.round(data.reimbursementTimeMaxWage, 2) +
        '. If your salary is over $' +
        Math.round(data.reimbursementTimeMaxWage, 2) +
        ', that amount you will be reimbursed at the end of your term/semester.';
    } else if (data.reimbursementTime === 'BEFORE') {
      generalReimbTimeframe = 'You will be reimbursed around the start of your term/semester.';
    } else if (data.reimbursementTime === 'AFTER') {
      generalReimbTimeframe =
        'You will be reimbursed at the end of your term/semester only after we have received the necessary information to verify your enrollment and completion of all courses for the term.';
    }

    let maxReimbursementAmount =
      'Over the course of your enrollment in this benefit, you are allotted a lifetime max of $' +
      data.employeeLifetimeMax +
      ' in tuition reimbursement.';
    return (
      <>
        <Header2>
          <strong>General Information</strong>
        </Header2>
        <AlignTextLeft>
          <FlexRow>
            <StyledLede>
              <strong>What Institutions Can I Enroll In</strong>
              <br />
              {qualifyingPrograms}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong> Can I Enroll In Certifications</strong>
              <br />
              {certificationsAllowed}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong>When Can I Enroll In This Benefit</strong>
              <br /> {benefitLimit}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong>When Can I Generally Expect My Reimbursement</strong>
              <br /> {generalReimbTimeframe}
            </StyledLede>
          </FlexRow>
        </AlignTextLeft>
        {data.employeeLifetimeMax !== null && data.employeeLifetimeMax !== 0 && (
          <AlignTextLeft>
            <FlexRow>
              <StyledLede>
                <br />
                <strong>How Much Total Reimbursement Is Available</strong>
                <br /> {maxReimbursementAmount}
              </StyledLede>
            </FlexRow>
          </AlignTextLeft>
        )}
      </>
    );
  }

  getDegreeInformation(data) {
    let degreeTermSetupEndModifier;
    if (data.degreeTermSetupEndModifier < 0) {
      degreeTermSetupEndModifier = Math.abs(data.degreeTermSetupEndModifier) + ' days before';
    } else {
      degreeTermSetupEndModifier = data.degreeTermSetupEndModifier + ' days after';
    }

    let endOrStartDegree;
    if (data.degreeReimbursementRequestEnd === 'END') {
      endOrStartDegree = 'end';
    } else if (data.degreeReimbursementRequestEnd === 'START') {
      endOrStartDegree = 'start';
    }

    let documentTimeLimitDegree;
    if (data.certificationReimbursementRequestEndModifier < 0) {
      documentTimeLimitDegree =
        Math.abs(data.degreeReimbursementRequestEndModifier) + ' days before';
    } else {
      documentTimeLimitDegree = data.degreeReimbursementRequestEndModifier + ' days after';
    }

    let degreeTermPrePaySetupEndModifier;
    if (data.degreePrePayTermSetupEndModifier < 0) {
      degreeTermPrePaySetupEndModifier =
        Math.abs(data.degreePrePayTermSetupEndModifier) + ' days before';
    } else {
      degreeTermPrePaySetupEndModifier = data.degreePrePayTermSetupEndModifier + ' days after';
    }

    //Degree section
    let degreeRegistration;
    if (data.reimbursementTime === 'BOTHHRLY' || data.reimbursementTime === 'BOTHSLRY') {
      degreeRegistration =
        'You will have ' +
        degreeTermSetupEndModifier +
        " your term/semester's start date to send us the required information to sign up. If you qualify for reimbursement before your term/semester starts, you have " +
        degreeTermPrePaySetupEndModifier +
        " your term/semester's start date to send us the required information.";
    } else if (data.reimbursementTime === 'BEFORE') {
      degreeRegistration =
        'You will have ' +
        degreeTermSetupEndModifier +
        " your term/semester's start date to send us the required information to sign up.";
    } else if (data.reimbursementTime === 'AFTER') {
      degreeRegistration =
        'You will have ' +
        degreeTermSetupEndModifier +
        " your term/semester's start date to send us the required information to sign up.";
    }

    let degreeReimbursementTiming;
    if (data.reimbursementTime === 'BOTHHRLY' || data.reimbursementTime === 'BOTHSLRY') {
      degreeReimbursementTiming =
        'If you qualify for reimbursement before your term/semester starts, you will be reimbursed near your term/semester start date. All other requests will be reimbursed after the necessary information has been received and reviewed. Once approved you will receive your reimbursement shortly thereafter.';
    } else if (data.reimbursementTime === 'BEFORE') {
      degreeReimbursementTiming =
        "Once your request is verified, you will be reimbursed near your term/semester's start date.";
    } else if (data.reimbursementTime === 'AFTER') {
      degreeReimbursementTiming =
        'You will be reimbursed after the necessary information has been received and reviewed. Once approved you will receive your reimbursement shortly thereafter.';
    }

    let degreeReimbInfoTime;
    if (data.reimbursementTime === 'BOTHHRLY' || data.reimbursementTime === 'BOTHSLRY') {
      degreeReimbInfoTime =
        'If you were not approved for reimbursement before your term/semester starts, you will have ' +
        documentTimeLimitDegree +
        ' the ' +
        endOrStartDegree +
        ' of your term/semester to submit the necessary documents. Your account will prompt you for the required information.';
    } else if (data.reimbursementTime === 'AFTER' || data.reimbursementTime === 'BEFORE') {
      degreeReimbInfoTime =
        'You will have ' +
        documentTimeLimitDegree +
        ' the ' +
        endOrStartDegree +
        ' of your term/semester to submit the necessary documents. Your account will prompt you for the required information.';
    }

    return (
      <>
        <Header2>
          <strong>Degree Information</strong>
        </Header2>
        <AlignTextLeft>
          <FlexRow>
            <StyledLede>
              <strong>How Long Do I Have To Register For A Degree</strong>
              <br /> {degreeRegistration}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong>When Will I Get Reimbursed For My Degree</strong>
              <br /> {degreeReimbursementTiming}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong>
                How Long Do I Have To Get Fiducius The Required Information For Reimbursement{' '}
              </strong>
              <br /> {degreeReimbInfoTime}
            </StyledLede>
          </FlexRow>
        </AlignTextLeft>
      </>
    );
  }

  getCertificationInformation(data) {
    //data validation/modification
    let certTermSetupEndModifier;
    if (data.certificationTermSetupEndModifier < 0) {
      certTermSetupEndModifier = Math.abs(data.certificationTermSetupEndModifier) + ' days before';
    } else {
      certTermSetupEndModifier = data.certificationTermSetupEndModifier + ' days after';
    }

    let certTermPrePaySetupEndModifier;
    if (data.certificationPrePayTermSetupEndModifier < 0) {
      certTermPrePaySetupEndModifier =
        Math.abs(data.certificationPrePayTermSetupEndModifier) + ' days before';
    } else {
      certTermPrePaySetupEndModifier = data.certificationPrePayTermSetupEndModifier + ' days after';
    }

    let endOrStart;
    if (data.certificationReimbursementRequestEnd === 'END') {
      endOrStart = 'end';
    } else if (data.certificationReimbursementRequestEnd === 'START') {
      endOrStart = 'start';
    }

    let documentTimeLimit;
    if (data.certificationReimbursementRequestEndModifier < 0) {
      documentTimeLimit =
        Math.abs(data.certificationReimbursementRequestEndModifier) + ' days before';
    } else {
      documentTimeLimit = data.certificationReimbursementRequestEndModifier + ' days after';
    }

    //certification section

    let certRegistration;
    if (data.reimbursementTime === 'BOTHHRLY' || data.reimbursementTime === 'BOTHSLRY') {
      certRegistration =
        'You will have ' +
        certTermSetupEndModifier +
        " your term/semester's start date to send us the required information to sign up. If you qualify for reimbursement before your term/semester starts, you have " +
        certTermPrePaySetupEndModifier +
        " your term/semester's start date to send us the required information.";
    } else if (data.reimbursementTime === 'BEFORE') {
      certRegistration =
        'You will have ' +
        certTermPrePaySetupEndModifier +
        " your term/semester's start date to send us the required information to sign up.";
    } else if (data.reimbursementTime === 'AFTER') {
      certRegistration =
        'You will have ' +
        certTermSetupEndModifier +
        " your term/semester's start date to send us the required information to sign up.";
    }

    let certReimbursementTiming;
    if (data.reimbursementTime === 'BOTHHRLY' || data.reimbursementTime === 'BOTHSLRY') {
      certReimbursementTiming =
        'If you qualify for reimbursement before your term/semester starts, you will be reimbursed near your term/semester start date. All other requests will be reimbursed after the necessary information has been received and reviewed. Once approved you will receive your reimbursement shortly thereafter.';
    } else if (data.reimbursementTime === 'BEFORE') {
      certReimbursementTiming =
        "Once your request is verified you will be reimbursed near your term/semester's start date.";
    } else if (data.reimbursementTime === 'AFTER') {
      certReimbursementTiming =
        'You will be reimbursed after the necessary information has been received and reviewed. Once approved you will receive your reimbursement shortly thereafter.';
    }

    let certReimbInfoTime;
    if (data.reimbursementTime === 'BOTHHRLY' || data.reimbursementTime === 'BOTHSLRY') {
      certReimbInfoTime =
        'If you were not approved for reimbursement before your term/semester starts, you will have ' +
        documentTimeLimit +
        ' the ' +
        endOrStart +
        ' of your term/semester to submit the necessary documents. Your account will prompt you for the required information.';
    } else if (data.reimbursementTime === 'AFTER' || data.reimbursementTime === 'BEFORE') {
      certReimbInfoTime =
        'You will have ' +
        documentTimeLimit +
        ' the ' +
        endOrStart +
        ' of your term/semester to submit the necessary documents. Your account will prompt you for the required information.';
    }

    return (
      <>
        <Header2>
          <strong>Certification Information</strong>
        </Header2>
        <AlignTextLeft>
          <FlexRow>
            <StyledLede>
              <strong>How Long Do I Have To Register For A Certification</strong>
              <br /> {certRegistration}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong>When Will I Get Reimbursed For My Certification</strong>
              <br /> {certReimbursementTiming}
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <br />
              <strong>
                How Long Do I Have To Get Fiducius The Required Information For Reimbursement{' '}
              </strong>
              <br /> {certReimbInfoTime}
            </StyledLede>
          </FlexRow>
        </AlignTextLeft>
      </>
    );
  }

  getReimbursementInformation(data) {
    const { trSetUp, permissions } = this.props;

    let preApprovalStatus = false;
    if (trSetUp.reimbursementTime === 'BOTHHRLY' || trSetUp.reimbursementTime === 'BOTHSLRY') {
      preApprovalStatus = true;
    }

    const headers = ['Payment Remittance Date', 'Information'];
    const body = [];

    const isUPH =
      permissions.strategicPartnerId === 161 ||
      permissions.strategicPartnerId === parseInt(process.env.REACT_APP_NFPTEST_ID);

    let docReceivedDate = 'December 8th';
    let docRecommendedDate = 'November 25th';

    if (isUPH) {
      body.push([
        '7th of the Month',
        'If your documentation was approved between the 16th of the previous month and the 1st of the current month, your tuition reimbursement will be processed on the 7th of the month. Please allow up to 2 weeks before you will receive payment.',
      ]);
      body.push([
        '21st of the Month',
        'If your documentation was approved between the 2nd and the 15th of the current month, your tuition reimbursement will be processed on the 21st of the month. Please allow up to 2 weeks before you will receive payment.',
      ]);
      docReceivedDate = 'December 20th';
      docRecommendedDate = 'December 15th';
    } else {
      body.push([
        '15th of the Month',
        'If your documentation was approved between the 2nd of the previous month and the 1st of the current month, your tuition reimbursement will be processed on the 15th of the month. Please allow up to 2 weeks before you will receive payment.',
      ]);
    }

    return (
      <>
        <Header2>
          <strong>Reimbursement Information</strong>
        </Header2>
        <AlignTextLeft>
          <FlexRow>
            <StyledLede>
              <strong>How are Payments Scheduled</strong>
              <p>
                Scheduled tuition reimbursement payments are calculated upon approval of submitted
                documentation. Payments that are approved for reimbursement will appear in either a
                "Reimbursement Pending" {preApprovalStatus && 'or "Pre-Term Pay Pending"'} status.
              </p>
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <strong>Tuition Reimbursement Processing</strong>
              <div className="row">
                <Table header={headers} body={body} />
              </div>
              <p>
                Note: Documents are typically reviewed the business day after they are received.
                However, during high volume periods, processing times may be longer.
              </p>
            </StyledLede>
          </FlexRow>
          <FlexRow>
            <StyledLede>
              <strong>End of Year Documentation Deadline</strong>
              <p>
                In order to receive your tuition reimbursement in the current tax year,
                documentation needs to be received and approved no later than {docReceivedDate}. Due
                to high volumes and holidays, it is recommended that you return documentation no
                later than {docRecommendedDate} to ensure your documentation is approved before the
                deadline.
              </p>
            </StyledLede>
          </FlexRow>
        </AlignTextLeft>
      </>
    );
  }

  render() {
    const { data } = this.props;

    const components = [];
    const componentDisplayNames = [];

    componentDisplayNames.push('General');
    components.push(this.getGeneralInformation(data));
    componentDisplayNames.push('Degree');
    components.push(this.getDegreeInformation(data));
    if (data.certificationsAllowed) {
      componentDisplayNames.push('Certification');
      components.push(this.getCertificationInformation(data));
    }
    componentDisplayNames.push('Reimbursement');
    components.push(this.getReimbursementInformation(data));

    return (
      <Card>
        <AlignTextRight>
          <Button brand="lowContrast" onClick={this.props.closeModal}>
            <FontAwesomeIcon icon={faXIcon} />
          </Button>
        </AlignTextRight>

        <CardBody>
          <StyledCardText>
            <ButtonCarousel
              buttonLocation={1}
              components={components}
              componentDisplayNames={componentDisplayNames}
            />
          </StyledCardText>
        </CardBody>
        <CardFooter>
          <Button brand="lowContrast" onClick={this.props.closeModal}>
            Close
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  trSetUp: getTuitionData(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TuitionSetupSummary));
