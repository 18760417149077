import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import {
  AsyncLoader,
  Card,
  CardBody,
  CardTitle,
  CardText,
  DashboardSection,
  Lede,
  Button,
} from 'fiducius-ui';

import { safeAccess, mergeRequestStatuses, flatten } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import StandardConfirmation from '../components/standard-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
class ContributionSS extends React.Component {
  static propTypes = {
    data: t.object.isRequired,
    form: t.object.isRequired,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    onLoad: t.func.isRequired,
    action: t.func.isRequired,
    isMarried: t.bool.isRequired,
    errors: t.object,
    todos: t.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      showStep1: false,
      showStep2: false,
    };
  }

  componentDidMount() {
    //this.props.onLoad();
    const pathName = window.location.pathname;
    if (pathName.includes('/step')) {
      this.setState({
        ...this.state,
        showStep1: pathName.includes('/step-1'),
        showStep2: pathName.includes('/step-2'),
      });
    }
  }

  componentDidUpdate() {}

  handleStep1Click = () => {
    this.handleSectionClick('step1');
  };
  handleStep2Click = () => {
    this.handleSectionClick('step2');
  };

  handleSectionClick = (sectionName) => {
    if (sectionName === 'step1') {
      this.setState(
        {
          showStep1: true,
          showStep2: false,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    } else if (sectionName === 'step2') {
      this.setState(
        {
          showStep1: false,
          showStep2: true,
        },
        () => {
          this.scrollToStep(sectionName);
        }
      );
    }
  };

  getCurrentStep = () => {
    const { todos, permissions } = this.props;
    const forgivenessSteps = flatten(permissions.forgivenessSteps.filter((a) => a.stepCurrent));

    let CurrentToDo = todos.find(
      (a) => forgivenessSteps.id === 'Contribution' && (a.id === 114 || a.id === 115)
    );

    if (CurrentToDo === undefined || CurrentToDo === null) {
      CurrentToDo = 0;
    } else {
      CurrentToDo = CurrentToDo.id;
    }

    return CurrentToDo;
  };

  getStep1Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'Contribution');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 114);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          }
        }
      }
    }

    return retVal;
  };

  getStep2Brand = () => {
    let retVal = 'warning';
    const { permissions, todos } = this.props;
    const empCertStep = permissions.forgivenessSteps.find((a) => a.id === 'Contribution');
    if (empCertStep !== undefined && empCertStep !== null) {
      if (empCertStep.stepComplete) {
        retVal = 'success';
      } else {
        if (todos !== undefined && todos !== null) {
          const currentToDo = todos.find((a) => a.id === 115);
          if (currentToDo !== undefined && currentToDo !== null) {
            retVal = 'info';
          }
        }
      }
    }

    return retVal;
  };

  scrollToStep(section) {
    document.getElementById(section + 'Section').scrollIntoView({ behavior: 'smooth' });
  }

  disableButtons = () => {
    let retVal = false;
    const { todos } = this.props;

    if (todos.find((a) => a.id === 504)) {
      retVal = true;
    }
    return retVal;
  };

  render() {
    const { request } = this.props;

    return (
      <>
        <AsyncLoader request={request}>
          <ForgivenessProgress />
          <DashboardSection>
            <Card>
              <CardBody>
                <CardTitle>Contribution</CardTitle>
                <CardText>
                  <p>
                    If the two options below are locked, please review your To-do List and complete
                    the "Select Accounts for Contribution" step. All steps must be completed
                    properly to set-up your employer contribution for this year.
                  </p>
                  <div className="row col-12">
                    <div className="col-2" />
                    <div className="col-4">
                      <Lede>
                        <Button
                          brand={this.getStep1Brand()}
                          onClick={this.handleStep1Click}
                          disabled={this.disableButtons()}
                        >
                          Submit
                        </Button>
                      </Lede>
                    </div>
                    <div className="col-4">
                      <Lede>
                        <Button
                          brand={this.getStep1Brand()}
                          onClick={this.handleStep2Click}
                          disabled={this.disableButtons()}
                        >
                          Review
                        </Button>
                      </Lede>
                    </div>
                  </div>
                  <div className="col-2" />
                </CardText>
              </CardBody>
            </Card>
          </DashboardSection>
        </AsyncLoader>
        {this.state.showStep1 && (
          <div id="step1Section">
            <DashboardSection title="Submit Repayment Details">
              <Card>
                <CardBody>
                  <AsyncLoader request={request} loading={<></>}>
                    <Card>
                      <CardBody>
                        <CardText>
                          <ul>
                            <li>
                              The next step of the process is for you to provide your repayment
                              details for verification.
                              <ul>
                                <li>
                                  As a reminder, your repayment details can be found in your MOHELA
                                  Account Inbox.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </CardText>
                      </CardBody>
                    </Card>
                  </AsyncLoader>
                  <StandardConfirmation stepId={114} />
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
        {this.state.showStep2 && (
          <div id="step2Section">
            <DashboardSection title="Repayment Details Review">
              <Card>
                <CardBody>
                  <AsyncLoader request={request} loading={<></>}>
                    <Card>
                      <CardBody>
                        <CardText>
                          <ul>
                            <li>
                              We are validating your income-driven repayment and employer
                              contribution. The review can take up to 45 days to complete.
                            </li>
                          </ul>
                        </CardText>
                      </CardBody>
                    </Card>
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const userId = getUserId(state);
  let data = safeAccess(state, `idr.cache[${userId}]`, {});
  let form = safeAccess(state, 'idr.form', {});
  form.actualRepaymentType = data.projectedRepaymentType;
  form.projectedRepaymentType = data.projectedRepaymentType;

  return {
    data: data,
    form: form,
    request: mergeRequestStatuses([
      state.todos.requests.updateResource,
      state.todos.requests.loadCollection,
      state.idr.requests.updateResource,
    ]),
    isMarried: safeAccess(state, 'auth.cache.permissions.married', false),
    errors: safeAccess(state, 'idr.errors', {}),
    todos: Object.values(state.todos.cache),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ContributionSS));
