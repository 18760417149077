import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import {
  BrandColor,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Header3,
  Header5,
  Lede,
  Loader,
  Undraw,
  Alert,
  AsyncLoader,
} from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { mergeRequestStatuses } from '../../../utils';
import { advisorLoadResource } from '../../../advisor';
import { ContributionQualify } from '../../../benefits/contribution';
import { ForgivenessQualify } from '../../../benefits/forgiveness';
import { ForgivenessShortTermQualify } from '../../../benefits/forgiveness';
import { LwoQualify } from '../../../benefits/limited-waiver-opportunity';
import { RefinanceQualify } from '../../../benefits/refinance';
import { SimpliFiQualify } from '../../../benefits/simplifi';
import { TuitionQualify } from '../../../benefits/tuition';
import { EmpcertQualify } from '../../../benefits/empcert';
import { FreshStartQualify } from '../../../benefits/freshstart';
import { EducationalReengagementQualify } from '../../../benefits/educational-reengagement';
import { OptimalSituationQualify } from '../../../benefits/optimal-situation';
import { ReducedIDRQualify } from '../../../benefits/reducedidr';
import { Scheduler } from '../../../meetings';
import { withPermissions } from '../../../routing';

import {
  screeningConcatenateReferralFields,
  screeningUpdateResource,
  reloadPermissions,
} from '../redux/operations';

const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

const IEFix = styled.div`
  width: 100%;
`;

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

class TabResults extends React.Component {
  static propTypes = {
    cache: t.object,
    formsAreValid: t.bool.isRequired,
    getResults: t.func.isRequired,
    id: t.string,
    isLoading: t.bool.isRequired,
    meta: t.object,
    permissions: t.object,
    saveRequest: t.object,
    screeningSave: t.func.isRequired,
    reloadPermissions: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      redirectURI: undefined,
    };
  }

  async componentDidMount() {
    const { formsAreValid, id, meta } = this.props;
    window.scrollTo(0, 0);
    if (formsAreValid && !meta.cacheIsCurrent) {
      await this.props.screeningSave(id);
    }
  }

  async componentDidUpdate(prevProps) {
    const prev = prevProps.saveRequest;
    const current = this.props.saveRequest;
    if (prev.isLoading && current.hasFinished && !current.hasFailed) {
      await this.props.getResults(this.props.id);
      await this.props.reloadPermissions();
    }
  }

  setRedirectURI = (urlInput) => {
    this.setState({ redirectURI: urlInput });
  };

  render() {
    const { cache, meta, isLoading, permissions, request } = this.props;

    if (this.state.redirectURI !== undefined) {
      return <Redirect to={this.state.redirectURI} />;
    } else if (isLoading) {
      return (
        <Flex>
          <Loader variant="atom" size={3} />
        </Flex>
      );
    } else if (!meta.cacheIsCurrent) {
      return (
        <Flex>
          <Header5>Complete the screening questions to get your results.</Header5>
          <Undraw image="empty" />
        </Flex>
      );
    } else if (cache && !isLoading && meta.cacheIsCurrent && !cache.qualified) {
      return (
        <Flex className="row">
          <div className="col-12 col-lg-8">
            {!cache.slfpPartnership && (
              <Card brand="success">
                <CardHeader>Unqualified</CardHeader>
                <CardBody>
                  <CardText>
                    <Lede>
                      Unfortunately it doesn't look like you qualify for any of our products.
                    </Lede>
                    <Spaced>
                      The{' '}
                      <strong>
                        <BrandColor brand="primary">good news</BrandColor>
                      </strong>{' '}
                      is that it looks like your student loan situation is already in{' '}
                      <strong>
                        <BrandColor brand="primary">great shape</BrandColor>
                      </strong>
                      !
                    </Spaced>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {cache.slfpPartnership && (
              <Card brand="success">
                <CardHeader>Good News!</CardHeader>
                <CardBody>
                  <CardText>
                    <Lede>
                      Congratulations, your student loan situation appears to be in great shape.
                      Book your meeting with a student loan advisor so we can verify your details
                      and determine if we can improve your current payment or payoff.
                    </Lede>
                  </CardText>
                </CardBody>
              </Card>
            )}
          </div>

          {cache.slfpPartnership && (
            <>
              <IEFix className="row">
                <div className="col-12">
                  <Header3>Next Steps</Header3>
                  <p>
                    Schedule a short introductory phone call with an Advisor. The purpose of this
                    call is simply for the Advisor to learn more about your unique situation and
                    goals, so we can help you take full advantage of these benefits. Don't worry,
                    there is nothing you need to do to prepare for the call.
                  </p>
                  <Scheduler buttonText="Schedule a meeting" buttonBrand="secondary" />
                </div>
              </IEFix>
            </>
          )}
        </Flex>
      );
    } else {
      return (
        <AsyncLoader request={request}>
          <Flex>
            <IEFix className="row">
              {cache.qualifiedRefi && (
                <div className="col-12 col-lg-6">
                  <RefinanceQualify rate={cache.refiInterestRate} />
                </div>
              )}
              {cache.qualifiedForgiveness &&
                !!!(
                  cache.enterpriseBenefits && cache.enterpriseBenefits.qualifiedShortTermForgive
                ) && (
                  <div className="col-12 col-lg-6">
                    <ForgivenessQualify
                      newPayment={cache.forgivenessMonthlyPayment}
                      savings={cache.forgivenessAmountSaved}
                    />
                  </div>
                )}
              {cache.qualifiedForgiveness &&
                cache.enterpriseBenefits &&
                cache.enterpriseBenefits.qualifiedShortTermForgive && (
                  <div className="col-12 col-lg-6">
                    <ForgivenessShortTermQualify savings={cache.forgivenessAmountSaved} />
                  </div>
                )}
              {/* {cache.qualifiedLimitedWaiverOpportunity && (
                <div className="col-12 col-lg-6">
                  <LwoQualify
                    showLwoButton={!cache.qualifiedForgiveness}
                    redirectUri={this.setRedirectURI}
                  />
                </div>
              )} */}
              {cache.qualifiedSimplifi &&
                !cache.qualifiedForgiveness &&
                !(cache.enterpriseBenefits && cache.enterpriseBenefits.qualifiedFreshStart) && (
                  <div className="col-12 col-lg-6">
                    <SimpliFiQualify />
                  </div>
                )}
              {cache.qualifiedEmployerContribution && (
                <div className="col-12 col-lg-6">
                  <ContributionQualify amount={cache.employerContributionMonthlyAmount} />
                </div>
              )}
              {cache.qualifiedTuitionReimbursement && (
                <div className="col-12 col-lg-6">
                  <TuitionQualify tuitionAmount={cache.tuitionAmount} />
                </div>
              )}
              {cache.qualifiedEnterpriseEmpcert && !cache.qualifiedForgiveness && (
                <div className="col-12 col-lg-6">
                  <EmpcertQualify />
                </div>
              )}
              {cache.enterpriseBenefits && cache.enterpriseBenefits.qualifiedFreshStart && (
                <div className="col-12 col-lg-6">
                  <FreshStartQualify />
                </div>
              )}
              {cache.enterpriseBenefits && cache.enterpriseBenefits.qualifiedReducedIDR && (
                <div className="col-12 col-lg-6">
                  <ReducedIDRQualify />
                </div>
              )}
              {cache.enterpriseBenefits &&
                cache.enterpriseBenefits.qualifiedEducationalReengagement && (
                  <div className="col-12 col-lg-6">
                    <EducationalReengagementQualify />
                  </div>
                )}
              {cache.enterpriseBenefits && cache.enterpriseBenefits.qualifiedOptimalSituation && (
                <div className="col-12 col-lg-6">
                  <OptimalSituationQualify />
                </div>
              )}
            </IEFix>

            {cache.needsMeeting && (
              <>
                {permissions.covidFurlough > 0 && (
                  <IEFix className="row">
                    <div className="col-12">
                      <Alert brand="info">
                        <p>
                          Given that your were furloughed, you may be eligible for extra loan
                          forgiveness savings. You will learn more about this at your scheduled
                          meeting time with our Advisor Team.
                        </p>
                      </Alert>
                    </div>
                  </IEFix>
                )}
                <IEFix className="row">
                  <div className="col-12">
                    <Header3>Next Steps</Header3>
                    <p>
                      Schedule a short introductory phone call with an Advisor. The purpose of this
                      call is simply for the Advisor to learn more about your unique situation and
                      goals, so we can help you take full advantage of these benefits. Don't worry,
                      there is nothing you need to do to prepare for the call.
                    </p>
                    <Scheduler buttonText="Schedule a meeting" buttonBrand="secondary" />
                  </div>
                </IEFix>
              </>
            )}
          </Flex>
        </AsyncLoader>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { advisor, screening, auth } = state;
  const { cache, meta, requests } = advisor;
  const id = getUserId(state);
  return {
    id: id,
    cache: cache[id],
    meta: meta,
    formsAreValid: screening.meta.isValid,
    isLoading: requests.loadResource.isLoading || screening.requests.updateResource.isLoading,
    saveRequest: screening.requests.updateResource,
    request: mergeRequestStatuses([
      screening.requests.loadResource,
      screening.requests.updateResource,
      auth.requests.loadPermissions,
    ]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getResults: (id) => dispatch(advisorLoadResource(id)),
  screeningSave: (id) => {
    dispatch(screeningConcatenateReferralFields());
    dispatch(screeningUpdateResource(id));
  },
  reloadPermissions: () => dispatch(reloadPermissions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabResults));
