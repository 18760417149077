import React from 'react';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import { Card, CardBody, CardText, Header4 } from 'fiducius-ui';

class FaqQuestion extends React.Component {
  static propTypes = {
    question: t.string.isRequired,
    children: t.node.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      showAnswer: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.setState({
      showAnswer: !this.state.showAnswer,
    });
  };

  render() {
    return (
      <>
        <Card>
          <CardBody>
            <CardText>
              <Header4 onClick={this.handleClick}>
                <FontAwesomeIcon
                  icon={this.state.showAnswer ? faAngleDown : faAngleRight}
                  fixedWidth
                />
                {this.props.question}
              </Header4>
              {this.state.showAnswer && (
                <CardText>
                  <u>Answer:</u>
                  {this.props.children}
                </CardText>
              )}
            </CardText>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default FaqQuestion;
