import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import {
  Alert,
  Button,
  Form,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  InputError,
  Header2,
} from 'fiducius-ui';
import { safeAccess, useFocusOnMount } from '../../utils';

import NewPassword from './new-password';

const StyledInputError = styled(InputError)`
  display: flex;
  flex-direction: column;
`;

const FinePrint = styled.div`
  font-size: calc(1rem);
`;

const StyledLink = styled.a`
  color: rgb(24, 188, 112);
`;

const PasswordOutOfDateForm = ({ data, errors = {}, handleChange, onSubmit }) => {
  const firstInput = useFocusOnMount();
  return (
    <Form id="passwordUpdateForm" handleChange={handleChange} onSubmit={onSubmit} defaults={data}>
      <Header2>Your current password has expired. Please use the form below to update it.</Header2>
      {safeAccess(errors, 'errorMessage') && (
        <Alert>{safeAccess(errors, 'errorMessage.detail')}</Alert>
      )}
      <InputWrapper error={!!safeAccess(errors, 'email')}>
        <InputLabel>Email</InputLabel>
        <StyledInputError
          dangerouslySetInnerHTML={{ __html: safeAccess(errors, 'email.detail') }}
        />
        <InputGroup>
          <InputText inputRef={firstInput} name="email" defaultValue="" autoComplete="username" />
        </InputGroup>
      </InputWrapper>
      <NewPassword
        error={safeAccess(errors, 'oldPassword.detail')}
        label={'Current Password'}
        fieldName={'oldPassword'}
      />
      <NewPassword
        error={safeAccess(errors, 'password.detail')}
        label={'New Password'}
        fieldName={'password'}
      />
      <NewPassword
        error={safeAccess(errors, 'newPassword.detail')}
        label={'Confirm New Password'}
        fieldName={'newPassword'}
      />
      <InputWrapper>
        <Button brand="primary" type="submit" onClick={onSubmit}>
          Update password
        </Button>
      </InputWrapper>
      <InputWrapper>
        <FinePrint>Having trouble setting your password?</FinePrint>
        <FinePrint>
          Contact us at{' '}
          <StyledLink href="mailto:LoginAssistance@getfiducius.com">
            LoginAssistance@getfiducius.com
          </StyledLink>{' '}
          for assistance.
        </FinePrint>
      </InputWrapper>
    </Form>
  );
};

PasswordOutOfDateForm.propTypes = {
  data: t.object.isRequired,
  errors: t.object,
  handleChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
};

export default PasswordOutOfDateForm;
