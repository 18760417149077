import actions from './actions';
import { resourceOperationsGenerator } from '../../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/affiliate',
  resourceType: 'affiliate',
};

const operations = resourceOperationsGenerator(
  'affiliate',
  actions,
  null,
  null,
  endpointHandler,
  null,
  null,
  null,
  null
);

const getAffiliateLinkById = (id) => async (dispatch, getState) => {
  let state = getState();
  let cache = state.affiliate.cache;

  // If the affiliate link is already in cache, don't call the API to retrieve it
  if (cache && Object.keys(cache).length > 0 && id in cache) {
    let retVal = cache[id].affiliateLink;
    return retVal;
  }

  // Not in cache; Retrieve the afilliateLink with the API
  else {
    await dispatch(affiliateLoadCollection());
    state = getState();
    cache = state.affiliate.cache;
    if (cache && Object.keys(cache).length > 0 && id in cache) {
      let retVal = cache[id].affiliateLink;
      return retVal;
    } else {
      return '';
    }
  }
};

const getAffiliateLinkByName = (name) => async (dispatch, getState) => {
  let state = getState();
  let cache = state.affiliate.cache;

  // If the affiliate link is already in cache, don't call the API to retrieve it
  if (cache && Object.keys(cache).length > 0) {
    let result = '';
    Object.keys(cache).forEach((k) => {
      if (cache[k].cdnLocation.toLowerCase().includes(name)) {
        result = cache[k].affiliateLink;
      }
    });
    return result;
  }

  // Not in cache; Retrieve the afilliateLink with the API
  else {
    await dispatch(affiliateLoadCollection());
    state = getState();
    cache = state.affiliate.cache;
    if (cache && Object.keys(cache).length > 0) {
      let result = '';
      Object.keys(cache).forEach((k) => {
        if (cache[k].cdnLocation.toLowerCase().includes(name)) {
          result = cache[k].affiliateLink;
        }
      });
      return result;
    } else {
      return '';
    }
  }
};

const { affiliateLoadCollection } = operations;

export { affiliateLoadCollection, getAffiliateLinkById, getAffiliateLinkByName };

export default operations;
