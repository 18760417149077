import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Card, CardBody, Loader } from 'fiducius-ui';

import { safeAccess, debounce, mergeRequestStatuses } from '../../utils';

import LoaderWrapper from '../../auth/styles/loader-wrapper';
import TuitionApprovalForm from '../components/tuition-approval-form';
import TuitionApprovalSuccess from '../components/tuition-approval-success';
import TuitionApprovalInvalid from '../components/tuition-approval-invalid';

import {
  tuitionApprovalLoadResource,
  tuitionApprovalCopyResourceToForm,
  tuitionApprovalHandleFormChange,
  tuitionApprovalSaveApprovalInfo,
} from '../redux/operations';

import { ContrastDisplay, FullscreenContainer } from '../../routing';

class TuitionApproval extends React.Component {
  static propTypes = {
    errors: t.object,
    request: t.object,
    updateRequest: t.object,
    form: t.object,
    data: t.object,
    location: t.shape({
      search: t.string,
    }),
    load: t.func.isRequired,
    handleForm: t.func.isRequired,
    update: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { submitSuccessful: false, hasSubmitted: false };
  }

  componentDidMount() {
    const { load, location } = this.props;
    const params = queryString.parse(location.search);
    load(params.token);
  }

  componentDidUpdate(prevProps) {
    const { updateRequest } = this.props;
    if (updateRequest.hasFinished) {
      if (!this.state.submitSuccessful && this.state.hasSubmitted) {
        const { errors } = this.props;
        if (!updateRequest.hasFailed && !this.hasServerErrors(errors)) {
          this.setState({ ...this.state, submitSuccessful: true });
        } else {
          this.setState({ ...this.state, hasSubmitted: false });
        }
      }
    }
  }

  hasServerErrors(errors) {
    let retVal = false;
    for (let i = 0; i <= 10; i++) {
      if (safeAccess(errors, 'approvalStatus' + i)) {
        retVal = true;
      } else if (safeAccess(errors, 'classNote' + i)) {
        retVal = true;
      }
    }
    return retVal;
  }

  handleChange = debounce((formState, id, attribute) => {
    const { form, handleForm } = this.props;
    const newData = { ...form, ...formState };

    if (attribute.includes('approvalStatus') || attribute.includes('classNote')) {
      for (let i = 0; i < newData.classes.length; i++) {
        newData.classes[i].approvalStatus = newData['approvalStatus' + i];
        newData.classes[i].classNote = newData['classNote' + i];
      }
    }

    handleForm(newData, this.getDependentAttributes(attribute));
  }, 250);

  getDependentAttributes = (attribute) => {
    switch (attribute) {
      case 'approvalStatus0':
        return ['approvalStatus0', 'classNote0'];
      case 'approvalStatus1':
        return ['approvalStatus1', 'classNote1'];
      case 'approvalStatus2':
        return ['approvalStatus2', 'classNote2'];
      case 'approvalStatus3':
        return ['approvalStatus3', 'classNote3'];
      case 'approvalStatus4':
        return ['approvalStatus4', 'classNote4'];
      case 'approvalStatus5':
        return ['approvalStatus5', 'classNote5'];
      case 'approvalStatus6':
        return ['approvalStatus6', 'classNote6'];
      case 'approvalStatus7':
        return ['approvalStatus7', 'classNote7'];
      case 'approvalStatus8':
        return ['approvalStatus8', 'classNote8'];
      case 'approvalStatus9':
        return ['approvalStatus9', 'classNote9'];
      case 'approvalStatus10':
        return ['approvalStatus10', 'classNote10'];
      default:
        return [attribute];
    }
  };

  onSubmit = () => {
    this.props.update(this.props.form.id);
    this.setState({ ...this.state, hasSubmitted: true });
  };

  render() {
    const { errors, request, form, data } = this.props;
    const { submitSuccessful } = this.state;

    return (
      <FullscreenContainer className="container">
        <div className="row">
          <div className="col-12">
            <ContrastDisplay>Tuition Reimbursement Approval</ContrastDisplay>
            <Card brand={(request.hasFailed && 'danger') || 'primary'}>
              <CardBody>
                {request.isLoading ? (
                  <LoaderWrapper>
                    <Loader variant="atom" size={3} />
                  </LoaderWrapper>
                ) : !submitSuccessful ? (
                  form.classes !== undefined && form.classes.length > 0 ? (
                    <TuitionApprovalForm
                      data={form}
                      cache={data}
                      errors={errors}
                      onSubmit={this.onSubmit}
                      onChange={this.handleChange}
                    />
                  ) : (
                    <TuitionApprovalInvalid />
                  )
                ) : (
                  <TuitionApprovalSuccess />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </FullscreenContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: safeAccess(state, 'tuitionApproval.errors') || {},
  request: mergeRequestStatuses([
    safeAccess(state, 'tuitionApproval.requests.loadResource'),
    safeAccess(state, 'tuitionApproval.requests.updateResource'),
  ]),
  updateRequest: safeAccess(state, 'tuitionApproval.requests.updateResource'),
  data: state.tuitionApproval.cache,
  form: safeAccess(state, 'tuitionApproval.form'),
});

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(tuitionApprovalLoadResource(id));
    dispatch(tuitionApprovalCopyResourceToForm(id));
  },
  handleForm: (tuitionApproval, attributes) =>
    dispatch(tuitionApprovalHandleFormChange(tuitionApproval, attributes)),
  update: async (id) => {
    await dispatch(tuitionApprovalSaveApprovalInfo(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TuitionApproval);
