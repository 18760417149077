import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TodoComplete, todosCopyResourceToForm, todosUpdateResource } from '../../todos';

const TodoText = () => (
  <>
    <strong>Login to your current loan servicer(s) account(s)</strong>
    <ul>
      <li>Request general forbearance on any federal loan(s) that have a payment due.</li>
      <li>
        <strong>Please note:</strong> If you have any Income-Driven Repayments showing due for your
        previous year, you will want to make those payments, if qualifying, prior to submitting a
        forbearance request.
      </li>
    </ul>
    <strong>Step 2 – Confirm you have requested general forbearance</strong>
    <ul>
      <li>
        I confirm I have submitted a forbearance request at my current loan servicer(s) to place my
        current loan payment(s) on hold. We recommend you request general forbearance for{' '}
        <strong> at least one month </strong>to allow enough time to complete the remaining steps.
      </li>
      <li>
        Forbearance requests typically take up to two weeks to process and any payment made while in
        forbearance may not qualify.
      </li>
      <li>
        <strong>Please note:</strong> If you have used more than 36 months of general forbearance,
        your request may be denied by the loan servicer.
      </li>
    </ul>
    <br />
    <br />
    Questions? please visit the Contact Us button on the left of your portal to reach out to us.
    {/* Contact us at <a href="tel:+15136455400">(513) 645-5400</a> Option: 2 */}
  </>
);

const PdForbearance = withRouter((props) => (
  <TodoComplete
    {...props}
    onComplete={() => props.history.push('/dashboard')}
    text={<TodoText />}
    title="Request General Forbearance"
  />
));

const mapStateToProps = (state) => ({
  request: state.todos.requests.updateResource,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  action: async () => {
    await dispatch(todosCopyResourceToForm(ownProps.id));
    await dispatch(todosUpdateResource(ownProps.id, ownProps.id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PdForbearance);
