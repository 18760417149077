import React from 'react';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/pro-light-svg-icons/faSun';
import { faMoonStars } from '@fortawesome/pro-solid-svg-icons/faMoonStars';
import { Header3, InputCheckbox } from 'fiducius-ui';

class MySettings extends React.Component {
  render() {
    const { changeFluid, changeTheme, darkTheme, fluid } = this.props;
    return (
      <>
        <Header3>Settings</Header3>
        <InputCheckbox
          key="fluidTheme"
          onChange={changeFluid}
          name="fluid"
          label="Fluid"
          defaultChecked={fluid}
        />
        <InputCheckbox
          key="darkTheme"
          onChange={changeTheme}
          name="dark"
          label={<FontAwesomeIcon icon={darkTheme ? faMoonStars : faSun} size="lg" fixedWidth />}
          defaultChecked={darkTheme}
        />
      </>
    );
  }
}

MySettings.propTypes = {
  changeFluid: t.func.isRequired,
  changeTheme: t.func.isRequired,
  darkTheme: t.bool.isRequired,
  fluid: t.bool.isRequired,
};

export default MySettings;
