export class NSLDS {
  constructor() {
    this.totalOutstandingByLoanType = []; //new NSLDSTotalOutstanding();
    this.loans = []; //new NSLDSLoan();
    this.grants = []; //new NSLDSGrant();
    this.overpayments = []; // new NSLDSOverpayment();
    this.programs = []; //new NSLDSProgram();
    this.awards = []; //new NSLDSAward();
  }

  totalOutstandingByLoanType; //new NSLDSTotalOutstanding();
  loans; //new NSLDSLoan();
  grants; //new NSLDSGrant();
  overpayments; // new NSLDSOverpayment();
  programs; //new NSLDSProgram();
  awards; //new NSLDSAward();

  fileSource;
  fileRequestDate;
  studentFirstName;
  studentMiddleInitial;
  studentLastName;
  studentStreetAddress1;
  studentStreetAddress2;
  studentCity;
  studentStateCode;
  studentCountryCode;
  studentZipCode;
  studentEmailAddress;
  studentHomePhoneCountryCode;
  studentHomePhoneNumber;
  studentHomePhonePreferred;
  studentCellPhoneContryCode;
  studentCellPhoneNumber;
  studentCellPhonePreferred;
  studentWorkPhoneContryCode;
  studentWorkPhoneNumber;
  studentWorkPhonePreferred;
  studentSULAMaximumEligibilityPeriod;
  studentSULASubsidizedUsagePeriod;
  studentSULARemainingEligibilityPeriod;
  studentEnrollmentStatus;
  studentEnrollmentStatusEffectiveDate;
  studentTotalAllLoansOutstandingPrincipal;
  studentTotalAllLoansOutstandingInterest;
  studentPellLifetimeEligibilityUsed;
  studentIraqAndAfghanistanServiceLifetimeEligibilityUsed;
  studentTotalAllGrants;
  undergraduateAggregateSubsidizedTotal;
  undergraduateAggregateUnsubsidizedTotal;
  undergraduateAggregateCombinedTotal;
  graduateAggregateSubsidizedTotal;
  graduateAggregateUnsubsidizedTotal;
  graduateAggregateCombinedTotal;
  aggregateSubsidizedTotal;
  aggregateUnsubsidizedTotal;
  aggregateCombinedTotal;

  fileId;
  isSpouseNslds;
}

export class NSLDSTotalOutstanding {
  constructor() {
    this.loanType = null;
    this.principal = null;
    this.interest = null;
  }
  loanType;
  principal;
  interest;
}

export class NSLDSLoan {
  constructor() {
    this.loanStatuses = []; //NSLDSLoanStatus();
    this.loanDisbursements = []; //NSLDSLoanDisbursement();
    this.loanContacts = []; //NSLDSLoanContact();
    this.loanSpecialContacts = []; // NSLDSLoanSpecialContact();

    this.loanType = null;
    this.loanAwardId = null;
    this.loanAttendingSchoolName = null;
    this.loanAttendingSchoolOpeid = null;
    this.loanDate = null;
    this.loanRepaymentBeginDate = null;
    this.loanPeriodBeginDate = null;
    this.loanPeriodEndDate = null;
    this.loanAmount = null;
    this.loanDisbursedAmount = null;
    this.loanCanceledAmount = null;
    this.loanOutstandingPrincipalBalance = null;
    this.loanOutstandingPrincipalBalanceAsOfDate = null;
    this.loanOutstandingInterestBalance = null;
    this.loanOutstandingInterestBalanceAsOfDate = null;
    this.loanInterestRateType = null;
    this.loanInterestRate = null;
    this.loanRepaymentPlanType = null;
    this.loanRepaymentPlanBeginDate = null;
    this.loanRepaymentPlanScheduledAmount = null;
    this.loanRepaymentPlanIdrPlanAnniversaryDate = null;
    this.loanConfirmedSubsidyStatus = null;
    this.loanSubsidizedUsageInYears = null;
    this.loanReaffirmationDate = null;
    this.loanMostRecentPaymentEffectiveDate = null;
    this.loanNextPaymentDueDate = null;
    this.loanCumulativePaymentAmount = null;
    this.loanPslfCumulativeMatchedMonths = null;

    //Internal LoanId
    this.loanId = null;
    //Internal ServicerId
    this.servicerId = null;
  }

  loanStatuses; //NSLDSLoanStatus();
  loanDisbursements; //NSLDSLoanDisbursement();
  loanContacts; //NSLDSLoanContact();
  loanSpecialContacts; // NSLDSLoanSpecialContact();

  loanType;
  loanAwardId;
  loanAttendingSchoolName;
  loanAttendingSchoolOpeid;
  loanDate;
  loanRepaymentBeginDate;
  loanPeriodBeginDate;
  loanPeriodEndDate;
  loanAmount;
  loanDisbursedAmount;
  loanCanceledAmount;
  loanOutstandingPrincipalBalance;
  loanOutstandingPrincipalBalanceAsOfDate;
  loanOutstandingInterestBalance;
  loanOutstandingInterestBalanceAsOfDate;
  loanInterestRateType;
  loanInterestRate;
  loanRepaymentPlanType;
  loanRepaymentPlanBeginDate;
  loanRepaymentPlanScheduledAmount;
  loanRepaymentPlanIdrPlanAnniversaryDate;
  loanConfirmedSubsidyStatus;
  loanSubsidizedUsageInYears;
  loanReaffirmationDate;
  loanMostRecentPaymentEffectiveDate;
  loanNextPaymentDueDate;
  loanCumulativePaymentAmount;
  loanPslfCumulativeMatchedMonths;

  //Internal LoanId
  LoanId;
  //Internal ServicerId
  servicerId;
}

export class NSLDSLoanStatus {
  constructor() {
    this.loanStatus = null;
    this.loanStatusDescription = null;
    this.loanStatusEffectiveDate = null;
  }
  loanStatus;
  loanStatusDescription;
  loanStatusEffectiveDate;
}

export class NSLDSLoanDisbursement {
  constructor() {
    this.loanDisbursementDate = null;
    this.loanDisbursementAmount = null;
  }
  loanDisbursementDate = null;
  loanDisbursementAmount = null;
}

export class NSLDSLoanContact {
  constructor() {
    this.loanContactType = null;
    this.loanContactName = null;
    this.loanContactStreetAddress1 = null;
    this.loanContactStreetAddress2 = null;
    this.loanContactCity = null;
    this.loanContactStateCode = null;
    this.loanContactZipCode = null;
    this.loanContactPhoneNumber = null;
    this.loanContactPhoneExtension = null;
    this.loanContactEmailAddress = null;
    this.loanContactWebSiteAddress = null;
  }
  loanContactStreetAddress1 = null;
  loanContactStreetAddress2 = null;
}

export class NSLDSLoanSpecialContact {
  constructor() {
    this.loanSpecialContactReason = null;
    this.loanSpecialContact = null;
  }
}

export class NSLDSGrant {
  constructor() {
    this.grantContacts = []; //NSLDSGrantContact();
    this.grantType = null;
    this.grantAttendingSchoolName = null;
    this.grantAttendingSchoolOpeid = null;
    this.grantAwardYear = null;
    this.grantScheduledAmount = null;
    this.grantAwardAmount = null;
    this.grantDisbursedAmount = null;
    this.grantRemainingAmountToBePaid = null;
    this.grantFirstTime = null;
    this.grantAdditionalEligibility = null;
  }
}

export class NSLDSGrantContact {
  constructor() {
    this.grantContactType = null;
    this.grantContactName = null;
    this.grantContactStreetAddress1 = null;
    this.grantContactStreetAddress2 = null;
    this.grantContactCity = null;
    this.grantContactStateCode = null;
    this.grantContactZipCode = null;
  }
}

export class NSLDSOverpayment {
  constructor() {
    this.overpaymentContacts = []; //NSLDSOverpaymentContact();
    this.overpaymentSpecialContacts = []; // NSLDSOverpaymentSpecialContact;
    this.overpaymentAidType = null;
    this.overpaymentAttendingSchoolName = null;
    this.overpaymentAttendingSchoolOpeid = null;
    this.overpaymentDisbursementDate = null;
    this.overpaymentStatus = null;
    this.overpaymentRepaymentDate = null;
  }
}

export class NSLDSOverpaymentContact {
  constructor() {
    this.overpaymentContactType = null;
    this.overpaymentContactName = null;
    this.overpaymentContactStreetAddress1 = null;
    this.overpaymentContactStreetAddress2 = null;
    this.overpaymentContactCity = null;
    this.overpaymentContactStateCode = null;
    this.overpaymentContactZipCode = null;
    this.overpaymentContactPhoneNumber = null;
    this.overpaymentContactPhoneExtension = null;
    this.overpaymentContactEmailAddress = null;
    this.overpaymentContactWebSiteAddress = null;
  }
}

export class NSLDSOverpaymentSpecialContact {
  constructor() {
    this.overpaymentSpecialContactReason = null;
    this.overpaymentSpecialContact = null;
  }
}

export class NSLDSProgram {
  constructor() {
    this.programStatuses = []; //NSLDSProgramStatus();
    this.programSchoolName = null;
    this.programSchoolOpeid = null;
    this.programCIPTitle = null;
    this.programCredentialLevel = null;
    this.programBeginDate = null;
    this.programPublishedLengthInYears = null;
  }
}

export class NSLDSProgramStatus {
  constructor() {
    this.programStatusDescription = null;
    this.programStatusEffectiveDate = null;
  }
}

export class NSLDSAward {
  constructor() {
    this.awardSchoolName = null;
    this.awardSchoolOpeid = null;
    this.awardEarliestDisbursementDate = null;
    this.awardLoanPeriodBeginDate = null;
    this.awardLoanPeriodEndDate = null;
    this.awardSubsidizedUsage = null;
    this.awardSubsidyStatus = null;
  }
}
