import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import t from 'prop-types';
import { connect } from 'react-redux';

import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';

import { authLoadPermissions } from '../../../auth';

import RenewalConfirmation from '../../forgiveness/components/renewal-confirmation';
import ReducedIDRProgress from '../components/reduced-idr-progress';

import { todosLoadCollection } from '../../../todos/redux/operations';

import { Employers, employmentHistoryLoadCollection } from '../../../employment-history';

const ReducedIDREmploymentHistory = ({ request, load, employers }) => {
  const [redirect, setRedirect] = useState('');
  const employerCount =
    employers !== null && employers !== undefined ? Object.keys(employers).length : 0;

  useEffect(() => {
    load();
  }, []);

  if (redirect && redirect.length > 0) {
    return <Redirect to={redirect} />;
  } else {
    return (
      <>
        <div>
          <ReducedIDRProgress />
          <DashboardSection>
            <Card brand="primary">
              <AsyncLoader request={request}>
                <CardBody>
                  <CardTitle>Employment History</CardTitle>
                  <CardText>
                    <p>Here are your employers. There are currently {employerCount} of them.</p>
                    <Employers checkQualification={() => {}} />
                  </CardText>
                </CardBody>
              </AsyncLoader>
              {employerCount > 0 && <RenewalConfirmation stepId={1606} forReducedIDR={true} />}
              {/* {employerCount === 0 &&
                <CardFooter>
                  
                    Please Insert at least one employer
                  
                </CardFooter>
              } */}
            </Card>
          </DashboardSection>
        </div>
      </>
    );
  }
};

ReducedIDREmploymentHistory.propTypes = {
  request: t.object.isRequired,
  todos: t.array,
  reload: t.func.isRequired,
  load: t.func.isRequired,
  permissions: t.object,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.loadCollection,
    state.legal.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
  employers: state.employmentHistory.cache,
});

const mapDispatchToProps = (dispatch) => ({
  reload: async () => {
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },

  load: async () => {
    await dispatch(employmentHistoryLoadCollection());
    await dispatch(todosLoadCollection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(ReducedIDREmploymentHistory));
