import { FormValidator } from '../../../utils';

export class ScreeningValidator extends FormValidator {
  // Info tab validators
  hireDate(validator) {
    validator.isRequired();
    if (validator.value) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      validator.validDate();
      validator.value = new Date(validator.value);
      validator.isLessThanOrEqualTo(tomorrow);
    }
  }

  jobTitle(validator) {
    validator.isRequired();
    validator.hasMaxLength(50);
  }

  covidFurlough(validator) {
    validator.isRequired();
  }

  homeAddressOne(validator, state) {
    if (state.homeAddressTwo || state.homeCity || state.homeState || state.homeZipCode) {
      validator.isRequired();
    }
    validator.hasMaxLength(30);
  }

  homeAddressTwo(validator) {
    validator.hasMaxLength(30);
  }

  homeCity(validator, state) {
    if (state.homeAddressTwo || state.homeAddressOne || state.homeState || state.homeZipCode) {
      validator.isRequired();
    }
    validator.hasMaxLength(30);
  }

  referrerName(validator, state) {
    // TODO: validator.isRequired();
  }

  knowSomeoneWithLoans(validator) {
    validator.isRequired();
  }

  homeState(validator, state) {
    if (state.homeAddressOne || state.homeCity || state.homeAddressTwo || state.homeZipCode) {
      validator.isRequired();
    }
    validator.hasMaxLength(2);
  }

  homeZipCode(validator, state) {
    if (
      state.homeAddressOne ||
      state.homeCity ||
      state.homeState ||
      state.homeAddressTwo ||
      state.homeZipCode
    ) {
      validator.isRequired();
      validator.matchesRegex(
        /^[0-9]{5}($|-[0-9]{4}$)/g,
        'must be formatted as either ##### or #####-####'
      );
    }
    validator.hasMaxLength(10);
  }

  // Loans tab validators
  federalLoanBalance(validator) {
    validator.isRequired();
    validator.validNumber();
  }

  federalMonthlyPayment(validator) {
    validator.isRequired();
    validator.validNumber();
  }

  consolidated(validator) {
    validator.isRequired();
  }

  federalPrivateConsolidatedMix(validator, state) {
    if (state.consolidated === 'Y') {
      validator.isRequired();
    }
  }

  hasParentPlus(validator) {
    validator.isRequired();
  }

  privateBalance(validator, state) {
    if (state.hasPrivateLoans === 'Y') {
      validator.isRequired();
      validator.validNumber();
    }
  }

  hasPrivateLoans(validator) {
    validator.isRequired();
  }

  privatePayment(validator, state) {
    if (state.hasPrivateLoans === 'Y') {
      validator.isRequired();
      validator.validNumber();
    }
  }

  privateServicer(validator, state) {
    if (state.hasPrivateLoans === 'Y') {
      validator.isRequired();
      validator.hasMaxLength(100);
    }
  }

  privateInterestRate(validator, state) {
    if (state.hasPrivateLoans === 'Y') {
      validator.isRequired();
      validator.validNumber();
    }
  }

  // Finance tab validators
  income(validator) {
    validator.isRequired();
    validator.validNumber();
    validator.isLessThanOrEqualTo(999999);
  }

  creditScore(validator) {
    validator.isRequired();
  }

  taxFiling(validator) {
    validator.isRequired();
  }

  householdSize(validator) {
    validator.isRequired();
    validator.validNumber();
    validator.isGreaterThanOrEqualTo(1);
  }

  married(validator) {
    validator.isRequired();
  }

  pslfOnOwn(validator) {
    validator.isRequired();
  }

  spouseIncome(validator, state) {
    if (state.married === 'Y') {
      validator.isRequired();
      validator.validNumber();
    }
  }

  // Education tab validators
  highestCompletedDegree(validator) {
    validator.isRequired();
  }
  continuingEducation(validator) {
    validator.isRequired();
  }
  degreeSought(validator, state) {
    if (state.continuingEducation === 'Y') {
      validator.isRequired();
    }
  }
  school(validator, state) {
    if (state.continuingEducation === 'Y') {
      validator.isRequired();
      validator.hasMaxLength(100);
    }
  }
}

export default ScreeningValidator;
