import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardHeader, CardText, Header5, Link } from 'fiducius-ui';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const FreshStartQualify = withPermissions(({ cache }) => (
  <Card brand="primary">
    <CardHeader>Fresh Start</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Eliminate your default status!</Header5>
        <Spaced>
          Your employer has hired Fiducius to provide instruction to remove the default status from
          your student loans. Click <Link to="/benefits/freshstart/overview">here</Link> to get
          started.
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
));

FreshStartQualify.propTypes = {};

const mapStateToProps = (state) => {
  const id = getUserId(state);
  return {
    //cache: cache[id],
  };
};

export default connect(mapStateToProps)(withPermissions(FreshStartQualify));
