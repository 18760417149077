export const removeTimezone = (input) => input.split('T')[0];

export const convertIsoToSlash = (input) => {
  if (typeof input === 'string' || input instanceof String) {
    let slash = removeTimezone(input);
    slash = slash.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1');
    return slash;
  }
};

export const convertSlashToIso = (input) => {
  if (typeof input === 'string' || input instanceof String) {
    let iso = input.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');
    iso = removeTimezone(iso);
    return iso;
  }
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const rateFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatAsMoney = (number, formatter = moneyFormatter) => {
  return isNaN(number) ? number : formatter.format(number);
};

export const formatAsPercent = (number, formatter = rateFormatter) =>
  formatter.format(number / 100);
