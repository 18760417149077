import React, { useState, useEffect } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  Card,
  CardBody,
  CardText,
  DashboardSection,
  Modal,
} from 'fiducius-ui';

import FreshStartProgress from '../components/fresh-start-progress';
import { mergeRequestStatuses } from '../../../utils';
import { authLoadPermissions, getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';
import { todosLoadCollection, todosSendUpdate } from '../../../todos/redux/operations';

import { freshStartLoadResource } from '../redux/operations';

import NSLDSWorkflowScreening from '../../../workflows/nslds/components/nslds-workflow-screening';
import { documentsRemoveFromCache } from '../../../documents/redux/operations';

const FreshStartNSLDS = ({
  request,
  confirm,
  contact,
  permissions,
  id,
  load,
  data,
  removeFromCache,
  files,
  nsldsCreateResourceRequest,
}) => {
  useEffect(() => {
    load(id);
  }, []);

  const [redirectUri, setRedirectUri] = useState('');
  const [nsldsUploaded, setNsldsUploaded] = useState(false);
  const [inNSLDSWorkflow, setInNSLDSWorkflow] = useState(true);
  const [nsldsWorkflowComplete, setNsldsWorkflowComplete] = useState(false);

  const updateNsldsUploaded = (uploaded) => {
    setNsldsUploaded(uploaded);
  };

  const removeNSLDSFileFromCache = () => {
    const matches = Object.keys(files).filter(
      (k) => files[k].fileTypeCd === 'LNDT' && files[k].id > 0
    );
    if (matches.length > 0) {
      removeFromCache(matches[0]);
    }
    nsldsCreateResourceRequest.hasFinished = false;
  };

  const nsldsWorkflowIsComplete = () => {
    setInNSLDSWorkflow(false);
    setNsldsWorkflowComplete(true);
    funcSetOpenModal(false);
  };

  const nsldsWorkFlowStartOver = () => {
    setInNSLDSWorkflow(true);
    setNsldsWorkflowComplete(false);
  };

  const clearUploadedNslds = () => {
    updateNsldsUploaded(false);
    nsldsWorkFlowStartOver();
    removeNSLDSFileFromCache();
  };

  const hasCompletedStep = () => {
    const completedNSLDSUpload = permissions.freshStartSteps.filter(
      (a) => a.id === 'NSLDSUploadFreshStart' && a.stepComplete
    );

    if (completedNSLDSUpload.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const isCurrentStep = () => {
    const currentUploadNSLDS = permissions.freshStartSteps.filter(
      (a) => a.id === 'NSLDSUploadFreshStart' && a.stepCurrent
    );

    if (currentUploadNSLDS.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const confirmUpload = () => {
    confirm().then(() => {
      setRedirectUri('/benefits/freshstart/complete');
    });
  };

  const confirmContact = () => {
    contact().then(() => {
      setRedirectUri('/benefits/freshstart/contact');
    });
  };

  const [openAgenciesModal, setOpenAgenciesModal] = useState(false);

  useEffect(() => {
    if (!openAgenciesModal) {
      load(id);
    }
  }, [openAgenciesModal]);

  const funcSetOpenModal = (value) => {
    setOpenAgenciesModal(value);
  };

  const hasGuarantyAgency = () => {
    return (
      Object.values(data).length > 0 &&
      Object.values(data)[0].freshStartDefaultedLoans.filter((a) => a.heldByGuaranty).length > 0
    );
  };

  const hasDeptOfEdAgency = () => {
    return (
      Object.values(data).length > 0 &&
      Object.values(data)[0].freshStartDefaultedLoans.filter((a) => a.heldByDeptOfEd).length > 0
    );
  };

  const getMainText = () => {
    const retVal = [];
    retVal.push(
      <p>
        The final step of Fresh Start is to upload a new NSLDS text file that shows the default
        removed from your student loans.
      </p>
    );
    return retVal;
  };

  const showNSLDSUploadButton = () => {
    if (
      Object.values(data).length > 0 &&
      Object.values(data)[0].newNsldsUploaded &&
      !hasGuarantyAgency() &&
      !hasDeptOfEdAgency()
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showWaitRecontactContent = () => {
    if (
      Object.values(data).length > 0 &&
      Object.values(data)[0].newNsldsUploaded &&
      (hasGuarantyAgency() || hasDeptOfEdAgency())
    ) {
      return true;
    } else {
      return false;
    }
  };

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <>
        <div className="row">
          <div className="col-12 col-lg-12">
            <AsyncLoader request={request}>
              <FreshStartProgress />
              <DashboardSection title="Upload NSLDS">
                <Card>
                  <CardBody>
                    <AsyncLoader request={request} loading={<></>}>
                      {getMainText()}
                      {!hasCompletedStep() && !isCurrentStep() && (
                        <p>You haven't reached this step yet.</p>
                      )}
                      {!hasCompletedStep() && isCurrentStep() && showNSLDSUploadButton() && (
                        <>
                          <p>
                            <Button
                              brand="primary"
                              onClick={() => {
                                setInNSLDSWorkflow(true);
                                clearUploadedNslds();
                                funcSetOpenModal(true);
                              }}
                            >
                              {'NSLDS Upload'}
                            </Button>
                          </p>
                        </>
                      )}
                      {!hasCompletedStep() && showWaitRecontactContent() && (
                        <>
                          <p>
                            Your NSLDS text file is still showing a default status on your student
                            loans. We recommend that you check your NSLDS in a week to determine if
                            it has been updated, loan servicers tend to provide weekly updates.
                          </p>
                          <p>
                            If you need to contact your loan holder again, please select the button
                            below.
                          </p>
                          <p>
                            <Button brand="primary" onClick={confirmContact}>
                              {'Contact Loan Holder'}
                            </Button>
                          </p>
                        </>
                      )}
                      {!hasCompletedStep() && isCurrentStep() && !showNSLDSUploadButton() && (
                        <>
                          <p>
                            Your NSLDs text file shows the default status has been removed from your
                            student loans, please select "Confirm" below to move to the next step.
                          </p>
                          <Button brand="primary" onClick={confirmUpload}>
                            {'Confirm'}
                          </Button>
                        </>
                      )}
                      {hasCompletedStep() && <p>You've completed this step.</p>}
                    </AsyncLoader>
                  </CardBody>
                  {/* <CardFooter>                                
                                    </CardFooter>                         */}
                </Card>
              </DashboardSection>
            </AsyncLoader>
          </div>
        </div>
        <Modal isOpen={openAgenciesModal} fullWidth theme={'position:fixed;'}>
          <Card>
            <CardBody>
              <CardText>
                {!nsldsWorkflowComplete && (
                  <NSLDSWorkflowScreening
                    inNSLDSWorkflow={inNSLDSWorkflow}
                    handleWorkflowComplete={nsldsWorkflowIsComplete}
                    updateNsldsUploaded={updateNsldsUploaded}
                    nsldsUploaded={nsldsUploaded}
                  />
                )}
              </CardText>
            </CardBody>
          </Card>
        </Modal>
      </>
    );
  }
};

FreshStartNSLDS.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  id: getUserId(state),
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
    state.freshStart.requests.loadResource,
  ]),
  todos: Object.values(state.todos.cache),
  data: Object.values(state.freshStart.cache),
  files: state.documents.cache,
  nsldsCreateResourceRequest: state.nslds.requests.createResource,
});

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    dispatch(todosSendUpdate(920)).then(() => {
      dispatch(authLoadPermissions());
      dispatch(todosLoadCollection());
    });
  },
  contact: async (id) => {
    dispatch(todosSendUpdate(940)).then(() => {
      dispatch(authLoadPermissions());
      dispatch(todosLoadCollection());
    });
  },
  load: async (id) => {
    await dispatch(freshStartLoadResource(id));
  },
  removeFromCache: (id) => dispatch(documentsRemoveFromCache(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FreshStartNSLDS));
