import React from 'react';
import t from 'prop-types';

import { safeAccess } from '../../utils';
import styled from 'styled-components';

import {
  Button,
  Form,
  InputAddon,
  InputError,
  InputWrapper,
  InputLabel,
  InputGroup,
  InputText,
} from 'fiducius-ui';

const StyledInputError = styled(InputError)`
  display: flex;
  flex-direction: column;
`;

class NewPasswordText extends React.Component {
  static propTypes = {
    auth: t.object,
    errors: t.object,
    handleChange: t.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showMeToo: false,
    };
    this.handleShow = this.handleShow.bind(this);
  }

  handleShow(value) {
    this.setState({ show: value });
  }
  handleShowMeToo(value) {
    this.setState({ showMeToo: value });
  }

  render() {
    const { handleChange, errors = {} } = this.props;

    return (
      <Form handleChange={handleChange}>
        <InputWrapper error={!!errors.newPassword}>
          <InputLabel htmlFor="newPassword">New Password</InputLabel>
          <StyledInputError
            dangerouslySetInnerHTML={{ __html: safeAccess(errors, 'newPassword.detail') }}
          />
          <InputGroup>
            <InputText
              name="newPassword"
              type={this.state.show ? 'text' : 'password'}
              autoComplete="new-password"
              placeholder={'Password'}
            />
            <InputAddon>
              <Button
                brand="primary"
                onPointerDown={() => this.handleShow(true)}
                onPointerUp={() => this.handleShow(false)}
              >
                show
              </Button>
            </InputAddon>
          </InputGroup>
        </InputWrapper>

        <InputWrapper>
          <InputLabel>Confirm New Password</InputLabel>
          <InputGroup>
            <InputText
              name={'confirmPassword'}
              type={this.state.showMeToo ? 'text' : 'password'}
              autoComplete="new-password"
              placeholder={'Password'}
            />
            <InputAddon>
              <Button
                brand="primary"
                onPointerDown={() => this.handleShowMeToo(true)}
                onPointerUp={() => this.handleShowMeToo(false)}
              >
                show
              </Button>
            </InputAddon>
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  }
}

export default NewPasswordText;
