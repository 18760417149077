import {
  NSLDS,
  NSLDSTotalOutstanding,
  NSLDSLoan,
  NSLDSLoanStatus,
  NSLDSLoanDisbursement,
  NSLDSLoanContact,
  NSLDSLoanSpecialContact,
  NSLDSGrant,
  NSLDSGrantContact,
  NSLDSOverpayment,
  NSLDSOverpaymentContact,
  NSLDSOverpaymentSpecialContact,
  NSLDSProgram,
  NSLDSProgramStatus,
  NSLDSAward,
} from './nslds';

import nsldsFieldType from '../redux/field-type';

export class NSLDSImporter {
  constructor() {
    this.fileReader = new FileReader();

    //When processing these values should only be incremented, never reset.
    this.loanIndex = 0;
    this.grantIndex = 0;
    this.overpaymentIndex = 0;
    this.programIndex = 0;
    this.awardIndex = 0;

    //incremented and reset
    this.loanStatusIndex = 0;
    this.loanDisbursementIndex = 0;
    this.loanContactIndex = 0;
    this.loanSpecialContactIndex = 0;
    this.grantContactIndex = 0;
    this.overpaymentContactIndex = 0;
    this.overpaymentSpecialContactIndex = 0;
    this.programStatusIndex = 0;

    //main object
    this.nsldsObject = new NSLDS();

    //debug
    this.testCounter = 0;
  }

  handleFileChosen = (file) => {
    return new Promise((resolve, reject) => {
      this.fileReader.onerror = () => {
        this.fileReader.abort();
        reject(new Error('Error parsing input file'));
      };

      this.fileReader.onload = () => {
        const content = this.fileReader.result.split(/\r\n|\n/);
        resolve(this.handleFileRead(content));
      };

      try {
        this.fileReader.readAsText(file);
      } catch (ex) {
        this.fileReader.abort();
      }
    });
  };

  hasFieldInGroup = (fieldGroup, fieldName) => fieldGroup.includes(fieldName);

  handleFileRead = (content) => {
    //splits into line by line
    const nsldsFieldGroup = nsldsFieldType;
    content.forEach((line) => {
      let lineItem = line.split(':');
      if (lineItem[0].substring(0, 10).includes('File')) {
        this.parseNSLDSFile(lineItem);
      } else if (
        lineItem[0].substring(0, 10).includes('Student') ||
        this.hasFieldInGroup(nsldsFieldGroup.student, lineItem[0])
      ) {
        this.ParseNSLDSStudent(lineItem);
      } else if (lineItem[0].substring(0, 10).includes('Total')) {
        this.ParseNSLDSTotals(lineItem);
      } else if (lineItem[0].substring(0, 10).includes('Loan')) {
        //use a substring here because loan is a common word - ensures we are looking at the beginning of the line.
        this.ParseNSLDSLoans(lineItem);
      } else if (lineItem[0].substring(0, 10).includes('Grant')) {
        this.ParseNSLDSGrants(lineItem);
      } else if (lineItem[0].substring(0, 15).includes('Overpayment')) {
        this.ParseNSLDSOverpayments(lineItem);
      } else if (lineItem[0].substring(0, 15).includes('Program')) {
        this.ParseNSLDSPrograms(lineItem);
      } else if (lineItem[0].substring(0, 15).includes('Award')) {
        this.ParseNSLDSAwards(lineItem);
      }
    });
    return this.nsldsObject;
  };

  parseNSLDSFile = (lineItem) => {
    switch (lineItem[0].trim()) {
      case 'File Source':
        this.nsldsObject.fileSource = lineItem[1].trim();
        break;
      case 'File Request Date':
        if (lineItem[2] !== undefined) {
          //formatted as yyyy-MM-dd HH:mm:ss:fff from the text file.
          this.nsldsObject.fileRequestDate = this.getFileRequestDate(
            lineItem[1].trim() + '.' + lineItem[2].trim() + '.' + lineItem[3].trim()
          );
        } else {
          //formatted as yyyy-MM-dd-HH.mm.ss.fff from the text file.
          this.nsldsObject.fileRequestDate = this.getFileRequestDate(lineItem[1].trim());
        }
        break;
      default:
        break;
    }
  };

  getFileRequestDate(lineData) {
    if (lineData !== undefined && lineData !== null && lineData.toString().length > 0) {
      let year = lineData.substring(0, 4);
      let month = lineData.substring(5, 7);
      let day = lineData.substring(8, 10);
      let hour = lineData.substring(11, 13);
      hour = hour === '24' ? '00' : hour;
      let minute = lineData.substring(14, 16);
      let second = lineData.substring(17, 19);

      return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second;
    }
    return undefined;
  }

  ParseNSLDSStudent = (lineItem) => {
    switch (lineItem[0].replaceAll(' ', '')) {
      case 'StudentFirstName':
        this.nsldsObject.studentFirstName = lineItem[1].trim();
        break;
      case 'StudentMiddleInitial':
        this.nsldsObject.studentMiddleInitial = lineItem[1].trim();
        break;
      case 'StudentLastName':
        this.nsldsObject.studentLastName = lineItem[1].trim();
        break;
      case 'StudentStreetAddress1':
        this.nsldsObject.studentStreetAddress1 = lineItem[1].trim();
        break;
      case 'StudentStreetAddress2':
        this.nsldsObject.studentStreetAddress2 = lineItem[1].trim();
        break;
      case 'StudentCity':
        this.nsldsObject.studentCity = lineItem[1].trim();
        break;
      case 'StudentStateCode':
        this.nsldsObject.studentStateCode = lineItem[1].trim();
        break;
      case 'StudentCountryCode':
        this.nsldsObject.studentCountryCode = lineItem[1].trim();
        break;
      case 'StudentZipCode':
        this.nsldsObject.studentZipCode = lineItem[1]
          .trim()
          .replace('(', '')
          .replace(')', '');
        break;
      case 'StudentEmailAddress':
        this.nsldsObject.studentEmailAddress = lineItem[1].trim();
        break;
      case 'StudentHomePhoneCountryCode':
        this.nsldsObject.studentHomePhoneCountryCode = lineItem[1].trim();
        break;
      case 'StudentHomePhoneNumber':
        this.nsldsObject.studentHomePhoneNumber = lineItem[1].trim();
        break;
      case 'StudentHomePhonePreferred':
        this.nsldsObject.studentHomePhonePreferred = lineItem[1].trim();
        break;
      case 'StudentCellPhoneContryCode':
        this.nsldsObject.studentCellPhoneContryCode = lineItem[1].trim();
        break;
      case 'StudentCellPhoneNumber':
        this.nsldsObject.studentCellPhoneNumber = lineItem[1].trim();
        break;
      case 'StudentCellPhonePreferred':
        this.nsldsObject.studentCellPhonePreferred = lineItem[1].trim();
        break;
      case 'StudentWorkPhoneContryCode':
        this.nsldsObject.studentWorkPhoneContryCode = lineItem[1].trim();
        break;
      case 'StudentWorkPhoneNumber':
        this.nsldsObject.studentWorkPhoneNumber = lineItem[1].trim();
        break;
      case 'StudentWorkPhonePreferred':
        this.nsldsObject.studentWorkPhonePreferred = lineItem[1].trim();
        break;
      case 'StudentSULAMaximumEligibilityPeriod':
        this.nsldsObject.studentSULAMaximumEligibilityPeriod = lineItem[1].trim();
        break;
      case 'StudentSULASubsidizedUsagePeriod':
        this.nsldsObject.studentSULASubsidizedUsagePeriod = lineItem[1].trim();
        break;
      case 'StudentSULARemainingEligibilityPeriod':
        this.nsldsObject.studentSULARemainingEligibilityPeriod = lineItem[1].trim();
        break;
      case 'StudentEnrollmentStatus':
      case 'StudentEnrollmentStatusCodeDescription':
        this.nsldsObject.studentEnrollmentStatus = lineItem[1].trim();
        break;
      case 'StudentEnrollmentStatusEffectiveDate':
        this.nsldsObject.studentEnrollmentStatusEffectiveDate =
          lineItem[1].trim().length > 0 ? lineItem[1].trim() : null;
        break;
      case 'StudentTotalAllLoansOutstandingPrincipal':
        this.nsldsObject.studentTotalAllLoansOutstandingPrincipal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'StudentTotalAllLoansOutstandingInterest':
        this.nsldsObject.studentTotalAllLoansOutstandingInterest = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'StudentPellLifetimeEligibilityUsed':
        this.nsldsObject.studentPellLifetimeEligibilityUsed = lineItem[1]
          .trim()
          .replace('$', '')
          .replace('%', '')
          .replaceAll(',', '');
        break;
      case 'StudentIraqandAfghanistanServiceLifetimeEligibilityUsed':
        this.nsldsObject.studentIraqAndAfghanistanServiceLifetimeEligibilityUsed =
          lineItem[1].trim() === ''
            ? null
            : lineItem[1]
                .trim()
                .replace('$', '')
                .replaceAll(',', '')
                .replaceAll('%', '');
        break;
      case 'StudentTotalAllGrants':
        this.nsldsObject.studentTotalAllGrants = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'UndergraduateAggregateSubsidizedTotal':
        this.nsldsObject.undergraduateAggregateSubsidizedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'UndergraduateAggregateUnsubsidizedTotal':
        this.nsldsObject.undergraduateAggregateUnsubsidizedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'UndergraduateAggregateCombinedTotal':
        this.nsldsObject.undergraduateAggregateCombinedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'GraduateAggregateSubsidizedTotal':
        this.nsldsObject.graduateAggregateSubsidizedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'GraduateAggregateUnsubsidizedTotal':
        this.nsldsObject.graduateAggregateUnsubsidizedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'GraduateAggregateCombinedTotal':
        this.nsldsObject.graduateAggregateCombinedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'AggregateSubsidizedTotal(UndergraduateandGraduate)':
        this.nsldsObject.aggregateSubsidizedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'AggregateUnsubsidizedTotal(UndergraduateandGraduate)':
        this.nsldsObject.aggregateUnsubsidizedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      case 'AggregateCombinedTotal(UndergraduateandGraduate)':
        this.nsldsObject.aggregateCombinedTotal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      default:
        break;
    }
  };

  ParseNSLDSTotals = (lineItem) => {
    let currLoanType = lineItem[0]
      .replace('Total ', '')
      .replace(' Outstanding', '')
      .replace(' Principal', '')
      .replace(' Interest', '');

    let index = null;
    index = this.nsldsObject.totalOutstandingByLoanType.findIndex(
      (a) => a.loanType === currLoanType
    );
    if (index > -1) {
      if (lineItem[0].includes('Principal')) {
        this.nsldsObject.totalOutstandingByLoanType[index].principal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
      } else if (lineItem[0].includes('Interest')) {
        this.nsldsObject.totalOutstandingByLoanType[index].interest = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
      }
    } else {
      let newTotalLoanType = new NSLDSTotalOutstanding();
      newTotalLoanType.loanType = currLoanType;
      if (lineItem[0].includes('Principal')) {
        newTotalLoanType.principal = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
      } else if (lineItem[0].includes('Interest')) {
        newTotalLoanType.interest = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
      }
      //array of objects
      this.nsldsObject.totalOutstandingByLoanType.push(newTotalLoanType);
    }
  };

  ParseNSLDSGrants = (lineItem) => {
    if (
      this.nsldsObject.grants[this.grantIndex] === undefined ||
      this.nsldsObject.grants[this.grantIndex] === null
    ) {
      let newGrant = new NSLDSGrant();
      this.nsldsObject.grants.push(newGrant);
    }

    let itemName = lineItem[0].replaceAll(' ', '');
    if (itemName.substring(0, 15).includes('GrantContact')) {
      this.ParseNSLDSGrantContact(lineItem);
    } else {
      switch (lineItem[0].replaceAll(' ', '')) {
        case 'GrantType':
          //increment the loan every time we see a loan type
          // and current loan object has a loan type.
          // set all indexes to 0 - LoanType indicates start of a new loan.
          if (this.nsldsObject.grants[this.grantIndex].grantType !== null) {
            let newGrant = new NSLDSGrant();
            this.nsldsObject.grants.push(newGrant);
            this.grantIndex++;
            this.grantContactIndex = 0;
          }
          this.nsldsObject.grants[this.grantIndex].grantType = lineItem[1].trim();
          break;
        case 'GrantTypeCodeDescription':
          //increment the loan every time we see a loan type
          // and current loan object has a loan type.
          // set all indexes to 0 - LoanType indicates start of a new loan.
          if (this.nsldsObject.grants[this.grantIndex].grantTypeCodeDescription !== null) {
            let newGrant = new NSLDSGrant();
            this.nsldsObject.grants.push(newGrant);
            this.grantIndex++;
            this.grantContactIndex = 0;
          }
          this.nsldsObject.grants[this.grantIndex].grantType = lineItem[1].trim();
          break;
        case 'GrantAttendingSchoolName':
          this.nsldsObject.grants[this.grantIndex].grantAttendingSchoolName = lineItem[1].trim();
          break;
        case 'GrantAttendingSchoolOPEID':
          this.nsldsObject.grants[this.grantIndex].grantAttendingSchoolOpeid = lineItem[1].trim();
          break;
        case 'GrantAwardYear':
          this.nsldsObject.grants[this.grantIndex].grantAwardYear = lineItem[1].trim();
          break;
        case 'GrantScheduledAmount':
          this.nsldsObject.grants[this.grantIndex].grantScheduledAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'GrantAwardAmount':
          this.nsldsObject.grants[this.grantIndex].grantAwardAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'GrantDisbursedAmount':
          this.nsldsObject.grants[this.grantIndex].grantDisbursedAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'GrantRemainingAmounttobePaid':
          this.nsldsObject.grants[this.grantIndex].grantRemainingAmountToBePaid =
            lineItem[1].trim() === ''
              ? null
              : lineItem[1]
                  .trim()
                  .replace('$', '')
                  .replaceAll(',', '');
          break;
        case 'GrantFirstTime':
          this.nsldsObject.grants[this.grantIndex].grantFirstTime = lineItem[1].trim();
          break;
        case 'GrantAdditionalEligibility':
          this.nsldsObject.grants[this.grantIndex].grantAdditionalEligibility = lineItem[1].trim();
          break;
        default:
          break;
      }
    }
  };

  ParseNSLDSOverpayments = (lineItem) => {
    if (
      this.nsldsObject.overpayments[this.overpaymentIndex] === undefined ||
      this.nsldsObject.overpayments[this.overpaymentIndex] === null
    ) {
      let newOverpayment = new NSLDSOverpayment();
      this.nsldsObject.overpayments.push(newOverpayment);
    }

    let itemName = lineItem[0].replaceAll(' ', '');
    if (itemName.substring(0, 25).includes('OverpaymentContact')) {
      this.ParseNSLDSOverpaymentContact(lineItem);
    } else if (itemName.substring(0, 26).includes('OverpaymentSpecialContact')) {
      this.ParseNSLDSOverpaymentSpecialContact(lineItem);
    } else {
      switch (lineItem[0].replaceAll(' ', '')) {
        case 'OverpaymentAidType':
          //increment the loan every time we see a loan type
          // and current loan object has a loan type.
          // set all indexes to 0 - LoanType indicates start of a new loan.
          if (this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentAidType !== null) {
            let newOverpayment = new NSLDSOverpayment();
            this.nsldsObject.overpayments.push(newOverpayment);
            this.overpaymentIndex++;
            this.overpaymentContactIndex = 0;
            this.overpaymentSpecialContactIndex = 0;
          }

          this.nsldsObject.overpayments[
            this.overpaymentIndex
          ].overpaymentAidType = lineItem[1].trim();
          break;
        case 'OverpaymentAttendingSchoolName':
          this.nsldsObject.overpayments[
            this.overpaymentIndex
          ].overpaymentAttendingSchoolName = lineItem[1].trim();
          break;
        case 'OverpaymentAttendingSchoolOPEID':
          this.nsldsObject.overpayments[
            this.overpaymentIndex
          ].overpaymentAttendingSchoolOpeid = lineItem[1].trim();
          break;
        case 'OverpaymentDisbursementDate':
          this.nsldsObject.overpayments[
            this.overpaymentIndex
          ].overpaymentDisbursementDate = lineItem[1].trim();
          break;
        case 'OverpaymentStatus':
          this.nsldsObject.overpayments[
            this.overpaymentIndex
          ].overpaymentStatus = lineItem[1].trim();
          break;
        case 'OverpaymentRepaymentDate':
          this.nsldsObject.overpayments[
            this.overpaymentIndex
          ].overpaymentRepaymentDate = lineItem[1].trim();
          break;
        default:
          break;
      }
    }
  };

  GetLoanType = (nsldsLoanType) => {
    let loanType = 'OTH';

    switch (nsldsLoanType) {
      case 'DIRECT STAFFORD SUBSIDIZED':
      case 'DIRECT STAFFORD SUBSIDIZED (SULA ELIGIBLE)':
        loanType = 'SDIR';
        break;
      case 'DIRECT CONSOLIDATED SUBSIDIZED':
      case 'DIRECT CONSOLIDATED SUBSIDIZED (SULA ELIGIBLE)':
        loanType = 'SCON';
        break;
      case 'DIRECT CONSOLIDATED UNSUBSIDIZED':
        loanType = 'UCON';
        break;
      case 'DIRECT STAFFORD UNSUBSIDIZED':
        loanType = 'UDIR';
        break;
      case 'FFEL CONSOLIDATED':
        loanType = 'CON';
        break;
      case 'FEDERAL PERKINS':
      case 'NATIONAL DIRECT STUDENT LOAN (PERKINS)':
      case 'PERKINS EXPANDED LENDING':
      case 'NATIONAL DEFENSE LOAN (PERKINS)':
        loanType = 'PERK';
        break;
      case 'DIRECT PLUS GRADUATE':
        loanType = 'DPLG';
        break;
      case 'FFEL PLUS GRADUATE':
        loanType = 'FPLG';
        break;
      case 'DIRECT PLUS PARENT':
        loanType = 'DPL';
        break;
      case 'FFEL PLUS PARENT':
        loanType = 'FPL';
        break;
      case 'FFEL STAFFORD SUBSIDIZED':
        loanType = 'SSTAF';
        break;
      case 'FFEL STAFFORD UNSUBSIDIZED':
      case 'FFEL STAFFORD NON-SUBSIDIZED':
        loanType = 'USTAF';
        break;
      case 'DIRECT UNSUBSIDIZED (TEACH)':
        loanType = 'TEACH';
        break;
      case 'FFEL SUPPLEMENTAL LOAN (SLS)':
        loanType = 'SLS';
        break;
      case 'DIRECT PLUS CONSOLIDATED':
        loanType = 'DPC';
        break;
      default:
        break;
    }

    return loanType;
  };

  ParseNSLDSLoans = (lineItem) => {
    if (
      this.nsldsObject.loans[this.loanIndex] === undefined ||
      this.nsldsObject.loans[this.loanIndex] === null
    ) {
      let newLoan = new NSLDSLoan();
      this.nsldsObject.loans.push(newLoan);
    }

    let itemName = lineItem[0].replaceAll(' ', '');
    if (itemName.substring(0, 25).includes('LoanStatus')) {
      this.ParseNsldsLoanStatus(lineItem);
    } else if (itemName.substring(0, 25).includes('LoanDisbursement')) {
      this.ParseNsldsLoanDisbursement(lineItem);
    } else if (itemName.substring(0, 25).includes('LoanContact')) {
      this.ParseNsldsLoanContact(lineItem);
    } else if (itemName.substring(0, 25).includes('LoanSpecialContact')) {
      this.ParseNSLDSLoanSpecialContact(lineItem);
    } else {
      switch (lineItem[0].replaceAll(' ', '')) {
        case 'LoanTypeDescription':
        case 'LoanType':
          //increment the loan every time we see a loan type
          // and current loan object has a loan type.
          // set all indexes to 0 - LoanType indicates start of a new loan.
          if (this.nsldsObject.loans[this.loanIndex].loanType !== null) {
            let newLoan = new NSLDSLoan();
            this.nsldsObject.loans.push(newLoan);
            this.loanIndex++;
            this.loanStatusIndex = 0;
            this.loanDisbursementIndex = 0;
            this.loanContactIndex = 0;
            this.loanSpecialContactIndex = 0;
          }

          this.nsldsObject.loans[this.loanIndex].loanType = this.GetLoanType(lineItem[1].trim());
          break;
        case 'LoanAwardID':
          this.nsldsObject.loans[this.loanIndex].loanAwardId = lineItem[1].trim();
          break;
        case 'LoanAttendingSchoolName':
          this.nsldsObject.loans[this.loanIndex].loanAttendingSchoolName = lineItem[1].trim();
          break;
        case 'LoanAttendingSchoolOPEID':
          this.nsldsObject.loans[this.loanIndex].loanAttendingSchoolOpeid = lineItem[1].trim();
          break;
        case 'LoanDate':
          this.nsldsObject.loans[this.loanIndex].loanDate = lineItem[1].trim();
          break;
        case 'LoanRepaymentBeginDate':
          this.nsldsObject.loans[this.loanIndex].loanRepaymentBeginDate = lineItem[1].trim();
          break;
        case 'LoanPeriodBeginDate':
          this.nsldsObject.loans[this.loanIndex].loanPeriodBeginDate = lineItem[1].trim();
          break;
        case 'LoanPeriodEndDate':
          this.nsldsObject.loans[this.loanIndex].loanPeriodEndDate = lineItem[1].trim();
          break;
        case 'LoanAmount':
          this.nsldsObject.loans[this.loanIndex].loanAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'LoanDisbursedAmount':
          this.nsldsObject.loans[this.loanIndex].loanDisbursedAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'LoanCanceledAmount':
          this.nsldsObject.loans[this.loanIndex].loanCanceledAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'LoanOutstandingPrincipalBalance':
          this.nsldsObject.loans[this.loanIndex].loanOutstandingPrincipalBalance = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'LoanOutstandingPrincipalBalanceasofDate':
          this.nsldsObject.loans[this.loanIndex].loanOutstandingPrincipalBalanceAsOfDate =
            lineItem[1].trim() === '' ? null : lineItem[1].trim();
          break;
        case 'LoanOutstandingInterestBalance':
          this.nsldsObject.loans[this.loanIndex].loanOutstandingInterestBalance = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'LoanOutstandingInterestBalanceasofDate':
          this.nsldsObject.loans[this.loanIndex].loanOutstandingInterestBalanceAsOfDate =
            lineItem[1].trim() === '' ? null : lineItem[1].trim();
          break;
        case 'LoanInterestRateTypeDescription':
        case 'LoanInterestRateType':
          this.nsldsObject.loans[this.loanIndex].loanInterestRateType = lineItem[1].trim();
          break;
        case 'LoanInterestRate':
          this.nsldsObject.loans[this.loanIndex].loanInterestRate = lineItem[1]
            .trim()
            .replace('%', '');
          break;
        case 'LoanRepaymentPlanTypeCodeDescription':
        case 'LoanRepaymentPlanType':
          this.nsldsObject.loans[this.loanIndex].loanRepaymentPlanType = lineItem[1].trim();
          break;
        case 'LoanRepaymentPlanBeginDate':
          this.nsldsObject.loans[this.loanIndex].loanRepaymentPlanBeginDate = lineItem[1].trim();
          break;
        case 'LoanRepaymentPlanScheduledAmount':
          this.nsldsObject.loans[this.loanIndex].loanRepaymentPlanScheduledAmount =
            lineItem[1].trim() === ''
              ? null
              : lineItem[1]
                  .trim()
                  .replace('$', '')
                  .replaceAll(',', '');
          break;
        case 'LoanRepaymentPlanIDRPlanAnniversaryDate':
          this.nsldsObject.loans[this.loanIndex].loanRepaymentPlanIdrPlanAnniversaryDate =
            lineItem[1].trim() === '' ? null : lineItem[1].trim();
          break;
        case 'LoanConfirmedSubsidyStatus':
          this.nsldsObject.loans[this.loanIndex].loanConfirmedSubsidyStatus = lineItem[1].trim();
          break;
        case 'LoanSubsidizedUsageinYears':
          this.nsldsObject.loans[this.loanIndex].loanSubsidizedUsageInYears =
            lineItem[1].trim() === '' ? null : lineItem[1].trim();
          break;
        case 'LoanReaffirmationDate':
          this.nsldsObject.loans[this.loanIndex].loanReaffirmationDate = lineItem[1].trim();
          break;
        case 'LoanMostRecentPaymentEffectiveDate':
          this.nsldsObject.loans[
            this.loanIndex
          ].loanMostRecentPaymentEffectiveDate = lineItem[1].trim();
          break;
        case 'LoanNextPaymentDueDate':
          this.nsldsObject.loans[this.loanIndex].loanNextPaymentDueDate = lineItem[1].trim();
          break;
        case 'LoanCumulativePaymentAmount':
          this.nsldsObject.loans[this.loanIndex].loanCumulativePaymentAmount = lineItem[1]
            .trim()
            .replace('$', '')
            .replaceAll(',', '');
          break;
        case 'LoanPSLFCumulativeMatchedMonths':
          this.nsldsObject.loans[
            this.loanIndex
          ].loanPslfCumulativeMatchedMonths = lineItem[1].trim();
          break;
        default:
          break;
      }
    }
  };

  ParseNsldsLoanStatus = (lineItem) => {
    if (
      this.nsldsObject.loans[this.loanIndex].loanStatuses[this.loanStatusIndex] === undefined ||
      this.nsldsObject.loans[this.loanIndex].loanStatuses[this.loanStatusIndex] === null
    ) {
      let newLoanStatus = new NSLDSLoanStatus();
      this.nsldsObject.loans[this.loanIndex].loanStatuses.push(newLoanStatus);
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'LoanStatus':
        //increment the status index everytime we see a status where loan status exists
        if (
          this.nsldsObject.loans[this.loanIndex].loanStatuses[this.loanStatusIndex].loanStatus !==
          null
        ) {
          let newLoanStatus = new NSLDSLoanStatus();
          this.nsldsObject.loans[this.loanIndex].loanStatuses.push(newLoanStatus);
          this.loanStatusIndex++;
        }
        this.nsldsObject.loans[this.loanIndex].loanStatuses[
          this.loanStatusIndex
        ].loanStatus = lineItem[1].trim();
        break;
      case 'LoanStatusDescription':
        this.nsldsObject.loans[this.loanIndex].loanStatuses[
          this.loanStatusIndex
        ].loanStatusDescription = lineItem[1].trim();
        break;
      case 'LoanStatusEffectiveDate':
        this.nsldsObject.loans[this.loanIndex].loanStatuses[
          this.loanStatusIndex
        ].loanStatusEffectiveDate = lineItem[1].trim();
        break;
      default:
        break;
    }
  };

  ParseNsldsLoanDisbursement = (lineItem) => {
    if (
      this.nsldsObject.loans[this.loanIndex].loanDisbursements[this.loanDisbursementIndex] ===
        undefined ||
      this.nsldsObject.loans[this.loanIndex].loanDisbursements[this.loanDisbursementIndex] === null
    ) {
      let newLoanDisbursement = new NSLDSLoanDisbursement();
      this.nsldsObject.loans[this.loanIndex].loanDisbursements.push(newLoanDisbursement);
    }
    switch (lineItem[0].replaceAll(' ', '')) {
      case 'LoanDisbursementDate':
        //increment the disbursement index everytime we see a status where loan status exists
        if (
          this.nsldsObject.loans[this.loanIndex].loanDisbursements[this.loanDisbursementIndex]
            .loanDisbursementDate !== null
        ) {
          let newLoanDisbursement = new NSLDSLoanDisbursement();
          this.nsldsObject.loans[this.loanIndex].loanDisbursements.push(newLoanDisbursement);
          this.loanDisbursementIndex++;
        }
        this.nsldsObject.loans[this.loanIndex].loanDisbursements[
          this.loanDisbursementIndex
        ].loanDisbursementDate = lineItem[1].trim();
        break;
      case 'LoanDisbursementAmount':
        this.nsldsObject.loans[this.loanIndex].loanDisbursements[
          this.loanDisbursementIndex
        ].loanDisbursementAmount = lineItem[1]
          .trim()
          .replace('$', '')
          .replaceAll(',', '');
        break;
      default:
        break;
    }
  };

  ParseNsldsLoanContact = (lineItem) => {
    if (
      this.nsldsObject.loans[this.loanIndex].loanContacts[this.loanContactIndex] === undefined ||
      this.nsldsObject.loans[this.loanIndex].loanContacts[this.loanContactIndex] === null
    ) {
      let newLoanContact = new NSLDSLoanContact();
      this.nsldsObject.loans[this.loanIndex].loanContacts.push(newLoanContact);
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'LoanContactType':
        //increment the disbursement index everytime we see a status where loan status exists
        if (
          this.nsldsObject.loans[this.loanIndex].loanContacts[this.loanContactIndex]
            .loanContactType !== null
        ) {
          let newLoanDisbursement = new NSLDSLoanContact();
          this.nsldsObject.loans[this.loanIndex].loanContacts.push(newLoanDisbursement);
          this.loanContactIndex++;
        }
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactType = lineItem[1].trim();
        break;
      case 'LoanContactName':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactName = lineItem[1].trim();
        break;
      case 'LoanContactStreetAddress1':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactStreetAddress1 = lineItem[1].trim();
        break;
      case 'LoanContactStreetAddress2':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactStreetAddress2 = lineItem[1].trim();
        break;
      case 'LoanContactCity':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactCity = lineItem[1].trim();
        break;
      case 'LoanContactStateCode':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactStateCode = lineItem[1].trim();
        break;
      case 'LoanContactZipCode':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactZipCode = lineItem[1]
          .trim()
          .replace('(', '')
          .replace(')', '');
        break;
      case 'LoanContactPhoneNumber':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactPhoneNumber = lineItem[1].trim();
        break;
      case 'LoanContactPhoneExtension':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactPhoneExtension = lineItem[1].trim();
        break;
      case 'LoanContactEmailAddress':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactEmailAddress = lineItem[1].trim();
        break;
      case 'LoanContactWebSiteAddress':
        this.nsldsObject.loans[this.loanIndex].loanContacts[
          this.loanContactIndex
        ].loanContactWebSiteAddress = lineItem[1].trim();
        break;
      default:
        break;
    }
  };

  ParseNSLDSLoanSpecialContact = (lineItem) => {
    if (
      this.nsldsObject.loans[this.loanIndex].loanSpecialContacts[this.loanSpecialContactIndex] ===
        undefined ||
      this.nsldsObject.loans[this.loanIndex].loanSpecialContacts[this.loanSpecialContactIndex] ===
        null
    ) {
      let newLoanContact = new NSLDSLoanSpecialContact();
      this.nsldsObject.loans[this.loanIndex].loanSpecialContacts.push(newLoanContact);
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'LoanSpecialContactReason':
        //increment the disbursement index everytime we see a status where loan status exists
        if (
          this.nsldsObject.loans[this.loanIndex].loanSpecialContacts[this.loanSpecialContactIndex]
            .loanSpecialContactReason !== null
        ) {
          let newLoanContact = new NSLDSLoanSpecialContact();
          this.nsldsObject.loans[this.loanIndex].loanSpecialContacts.push(newLoanContact);
          this.loanSpecialContactIndex++;
        }
        this.nsldsObject.loans[this.loanIndex].loanSpecialContacts[
          this.loanSpecialContactIndex
        ].loanSpecialContactReason = lineItem[1].trim();
        break;
      case 'LoanSpecialContact':
        this.nsldsObject.loans[this.loanIndex].loanSpecialContacts[
          this.loanSpecialContactIndex
        ].loanSpecialContact = lineItem[1].trim();
        break;
      default:
        break;
    }
  };

  ParseNSLDSGrantContact = (lineItem) => {
    if (
      this.nsldsObject.grants[this.grantIndex].grantContacts[this.grantContactIndex] ===
        undefined ||
      this.nsldsObject.grants[this.grantIndex].grantContacts[this.grantContactIndex] === null
    ) {
      let newGrantContact = new NSLDSGrantContact();
      this.nsldsObject.grants[this.grantIndex].grantContacts.push(newGrantContact);
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'GrantContactType':
        //increment the grant index everytime we see a grant where contact type exists
        if (
          this.nsldsObject.grants[this.grantIndex].grantContacts[this.grantContactIndex]
            .grantContactType !== null
        ) {
          let newGrantContact = new NSLDSGrantContact();
          this.nsldsObject.grants[this.grantIndex].grantContacts.push(newGrantContact);
          this.grantContactIndex++;
        }
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactType = lineItem[1].trim();
        break;
      case 'GrantContactName':
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactName = lineItem[1].trim();
        break;
      case 'GrantContactStreetAddress1':
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactStreetAddress1 = lineItem[1].trim();
        break;
      case 'GrantContactStreetAddress2':
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactStreetAddress2 = lineItem[1].trim();
        break;
      case 'GrantContactCity':
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactCity = lineItem[1].trim();
        break;
      case 'GrantContactStateCode':
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactStateCode = lineItem[1].trim();
        break;
      case 'GrantContactZipCode':
        this.nsldsObject.grants[this.grantIndex].grantContacts[
          this.grantContactIndex
        ].grantContactZipCode = lineItem[1]
          .trim()
          .replace('(', '')
          .replace(')', '');
        break;
      default:
        break;
    }
  };

  ParseNSLDSOverpaymentContact = (lineItem) => {
    if (
      this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
        this.overpaymentContactIndex
      ] === undefined ||
      this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
        this.overpaymentContactIndex
      ] === null
    ) {
      let newOverpaymentContact = new NSLDSOverpaymentContact();
      this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts.push(
        newOverpaymentContact
      );
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'OverpaymentContactType':
        //increment the grant index everytime we see a grant where contact type exists
        if (
          this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
            this.overpaymentContactIndex
          ].overpaymentContactType !== null
        ) {
          let newOverpaymentContact = new NSLDSOverpaymentContact();
          this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts.push(
            newOverpaymentContact
          );
          this.overpaymentContactIndex++;
        }
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactType = lineItem[1].trim();
        break;
      case 'OverpaymentContactName':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactName = lineItem[1].trim();
        break;
      case 'OverpaymentContactStreetAddress1':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactStreetAddress1 = lineItem[1].trim();
        break;
      case 'OverpaymentContactStreetAddress2':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactStreetAddress2 = lineItem[1].trim();
        break;
      case 'OverpaymentContactCity':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactCity = lineItem[1].trim();
        break;
      case 'OverpaymentContactStateCode':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactStateCode = lineItem[1].trim();
        break;
      case 'OverpaymentContactZipCode':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactZipCode = lineItem[1].trim();
        break;
      case 'OverpaymentContactPhoneNumber':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactPhoneNumber = lineItem[1].trim();
        break;
      case 'OverpaymentContactPhoneExtension':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactPhoneExtension = lineItem[1].trim();
        break;
      case 'OverpaymentContactEmailAddress':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactEmailAddress = lineItem[1].trim();
        break;
      case 'OverpaymentContactWebSiteAddress':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentContacts[
          this.overpaymentContactIndex
        ].overpaymentContactWebSiteAddress = lineItem[1].trim();
        break;
      default:
        break;
    }
  };

  ParseNSLDSOverpaymentSpecialContact = (lineItem) => {
    if (
      this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts[
        this.overpaymentSpecialContactIndex
      ] === undefined ||
      this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts[
        this.overpaymentSpecialContactIndex
      ] === null
    ) {
      let newOverpaymentSpecialContact = new NSLDSOverpaymentSpecialContact();
      this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts.push(
        newOverpaymentSpecialContact
      );
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'OverpaymentSpecialContact':
        //increment the grant index everytime we see a grant where contact type exists
        if (
          this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts[
            this.overpaymentSpecialContactIndex
          ].overpaymentSpecialContact !== null
        ) {
          let newOverpaymentSpecialContact = new NSLDSOverpaymentSpecialContact();
          this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts.push(
            newOverpaymentSpecialContact
          );
          this.overpaymentSpecialContactIndex++;
        }
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts[
          this.overpaymentSpecialContactIndex
        ].overpaymentSpecialContact = lineItem[1].trim();
        break;
      case 'OverpaymentSpecialContactReason':
        this.nsldsObject.overpayments[this.overpaymentIndex].overpaymentSpecialContacts[
          this.overpaymentSpecialContactIndex
        ].overpaymentSpecialContactReason = lineItem[1].trim();
        break;
      default:
        break;
    }
  };

  ParseNSLDSPrograms = (lineItem) => {
    if (
      this.nsldsObject.programs[this.programIndex] === undefined ||
      this.nsldsObject.programs[this.programIndex] === null
    ) {
      let newProgram = new NSLDSProgram();
      this.nsldsObject.programs.push(newProgram);
    }

    let itemName = lineItem[0].replaceAll(' ', '');
    if (itemName.substring(0, 25).includes('ProgramStatus')) {
      this.ParseNSLDSProgramStatus(lineItem);
    } else {
      switch (lineItem[0].replaceAll(' ', '')) {
        case 'ProgramSchoolName':
          //increment the loan every time we see a loan type
          // and current loan object has a loan type.
          // set all indexes to 0 - LoanType indicates start of a new loan.
          if (this.nsldsObject.programs[this.programIndex].programSchoolName !== null) {
            let newProgram = new NSLDSProgram();
            this.nsldsObject.programs.push(newProgram);
            this.programIndex++;
            this.programStatusIndex = 0;
          }

          this.nsldsObject.programs[this.programIndex].programSchoolName = lineItem[1].trim();
          break;
        case 'ProgramSchoolOPEID':
          this.nsldsObject.programs[this.programIndex].programSchoolOpeid = lineItem[1].trim();
          break;
        case 'ProgramCIPTitle':
          this.nsldsObject.programs[this.programIndex].programCIPTitle = lineItem[1].trim();
          break;
        case 'ProgramCredentialLevelCodeDescription':
        case 'ProgramCredentialLevel':
          this.nsldsObject.programs[this.programIndex].programCredentialLevel = lineItem[1].trim();
          break;
        case 'ProgramBeginDate':
          this.nsldsObject.programs[this.programIndex].programBeginDate = lineItem[1].trim();
          break;
        case 'ProgramPublishedLengthInYears':
          this.nsldsObject.programs[
            this.programIndex
          ].programPublishedLengthInYears = lineItem[1].trim();
          break;
        default:
          break;
      }
    }
  };

  ParseNSLDSProgramStatus = (lineItem) => {
    if (
      this.nsldsObject.programs[this.programIndex].programStatuses[this.programStatusIndex] ===
        undefined ||
      this.nsldsObject.programs[this.programIndex].programStatuses[this.programStatusIndex] === null
    ) {
      let newProgramStatus = new NSLDSProgramStatus();
      this.nsldsObject.programs[this.programIndex].programStatuses.push(newProgramStatus);
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'ProgramStatusCodeDescription':
      case 'ProgramStatusDescription':
        //increment the grant index everytime we see a grant where contact type exists
        if (
          this.nsldsObject.programs[this.programIndex].programStatuses[this.programStatusIndex]
            .programStatusDescription !== null
        ) {
          let newProgramStatus = new NSLDSProgramStatus();
          this.nsldsObject.programs[this.programIndex].programStatuses.push(newProgramStatus);
          this.programStatusIndex++;
        }
        this.nsldsObject.programs[this.programIndex].programStatuses[
          this.programStatusIndex
        ].programStatusDescription = lineItem[1].trim();
        break;
      case 'ProgramStatusEffectiveDate':
        this.nsldsObject.programs[this.programIndex].programStatuses[
          this.programStatusIndex
        ].programStatusEffectiveDate = lineItem[1].trim();
        break;
      default:
        break;
    }
  };

  ParseNSLDSAwards = (lineItem) => {
    if (
      this.nsldsObject.awards[this.awardIndex] === undefined ||
      this.nsldsObject.awards[this.awardIndex] === null
    ) {
      let newAward = new NSLDSAward();
      this.nsldsObject.awards.push(newAward);
    }

    switch (lineItem[0].replaceAll(' ', '')) {
      case 'AwardSchoolName':
        //increment the loan every time we see a loan type
        // and current loan object has a loan type.
        // set all indexes to 0 - LoanType indicates start of a new loan.
        if (this.nsldsObject.awards[this.awardIndex].awardSchoolName !== null) {
          let newAward = new NSLDSAward();
          this.nsldsObject.awards.push(newAward);
          this.awardIndex++;
        }

        this.nsldsObject.awards[this.awardIndex].awardSchoolName = lineItem[1].trim();
        break;
      case 'AwardSchoolOPEID':
        this.nsldsObject.awards[this.awardIndex].awardSchoolOpeid = lineItem[1].trim();
        break;
      case 'AwardEarliestDisbursementDate':
        this.nsldsObject.awards[this.awardIndex].awardEarliestDisbursementDate = lineItem[1].trim();
        break;
      case 'AwardLoanPeriodBeginDate':
        this.nsldsObject.awards[this.awardIndex].awardLoanPeriodBeginDate = lineItem[1].trim();
        break;
      case 'AwardLoanPeriodEndDate':
        this.nsldsObject.awards[this.awardIndex].awardLoanPeriodEndDate = lineItem[1].trim();
        break;
      case 'AwardSubsidizedUsage':
        this.nsldsObject.awards[this.awardIndex].awardSubsidizedUsage = lineItem[1].trim();
        break;
      case 'AwardSubsidyStatus':
        this.nsldsObject.awards[this.awardIndex].awardSubsidyStatus = lineItem[1].trim();
        break;
      default:
        break;
    }
  };
}
