import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  AsyncLoader,
  Button,
  InputWrapper,
  InputCheckbox,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardFooter,
  DashboardSection,
} from 'fiducius-ui';

import FreshStartProgress from '../components/fresh-start-progress';
import { mergeRequestStatuses } from '../../../utils';
import { authLoadPermissions } from '../../../auth';
import { withPermissions } from '../../../routing';
import { todosLoadCollection, todosSendUpdate } from '../../../todos/redux/operations';
import styled from 'styled-components';

const InputWrapper2 = styled(InputWrapper)`
  color: ${(p) => p.theme.textPrimary};
`;

const FreshStartAwaiting = ({ request, confirm, permissions }) => {
  const [redirectUri, setRedirectUri] = useState('');
  const [showCheckbox, setShowCheckbox] = useState(false);

  const hasCompletedStep = () => {
    const completedContact = permissions.freshStartSteps.filter(
      (a) => a.id === 'AwaitingFreshStart' && a.stepComplete
    );

    if (completedContact.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const isCurrentStep = () => {
    const currentAwaiting = permissions.freshStartSteps.filter(
      (a) => a.id === 'AwaitingFreshStart' && a.stepCurrent
    );

    if (currentAwaiting.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const confirmContact = () => {
    confirm().then(() => {
      setRedirectUri('/benefits/freshstart/nslds');
    });
  };

  const handleCheckboxChange = () => {
    setShowCheckbox(!showCheckbox);
  };

  if (redirectUri && redirectUri.length > 0) {
    return <Redirect to={redirectUri} />;
  } else {
    return (
      <div className="row">
        <div className="col-12 col-lg-12">
          <AsyncLoader request={request}>
            <FreshStartProgress />
            <DashboardSection title="Awaiting Confirmation from Loan Servicer">
              <Card>
                <CardBody>
                  <AsyncLoader request={request} loading={<></>}>
                    <p>
                      The next step of Fresh Start is to wait for the following steps to be
                      completed:
                    </p>
                    <ul>
                      <li>
                        The Department of Education will transfer your defaulted loans from the
                        Default Resolution Group (or from a guaranty agency) to a loan servicer.
                      </li>
                      <li>
                        The Department of Education will return your defaulted loans to "in
                        repayment" status.
                      </li>
                      <li>
                        The Department of Education will remove the record of your default from your
                        credit report.
                      </li>
                    </ul>
                    {!hasCompletedStep() && isCurrentStep() && (
                      <>
                        <div className="row col-12">
                          <div className="col-12">
                            <InputWrapper2>
                              <InputCheckbox
                                name="allowConfirmation"
                                label="I'm ready to confirm that my loans are no longer in default."
                                onChange={() => handleCheckboxChange()}
                              ></InputCheckbox>
                            </InputWrapper2>
                          </div>
                        </div>
                        <div className="row col-12">
                          <div className="col-4">
                            <InputWrapper>
                              <Button
                                id={'confirm'}
                                brand={'primary'}
                                onClick={confirmContact}
                                disabled={!showCheckbox}
                              >
                                {'Confirm'}
                              </Button>
                            </InputWrapper>
                          </div>
                        </div>
                      </>
                    )}
                    {hasCompletedStep() && <p>You've completed this step.</p>}
                  </AsyncLoader>
                </CardBody>
              </Card>
            </DashboardSection>
          </AsyncLoader>
        </div>
      </div>
    );
  }
};

FreshStartAwaiting.propTypes = {
  request: t.object.isRequired,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  confirm: async (id) => {
    dispatch(todosSendUpdate(910)).then(() => {
      dispatch(authLoadPermissions());
      dispatch(todosLoadCollection());
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(FreshStartAwaiting));
