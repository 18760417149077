import actions from './actions';
import { LwoAgreementValidator } from './validators';
import { resourceOperationsGenerator } from '../../../utils';

const lwoAgreementValidator = new LwoAgreementValidator();

const endpointHandler = {
  endpoint: '/lwo-contract-questions',
  customResponseHandler: null,
  resourceType: 'lwo-contract-questions',
};

const operations = resourceOperationsGenerator(
  'lwoAgreement',
  actions,
  null,
  lwoAgreementValidator,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null
);

const {
  lwoAgreementCopyResourceToForm,
  lwoAgreementHandleFormChange,
  lwoAgreementInvalidateCache,
  lwoAgreementLoadResource,
  lwoAgreementUpdateResource,
  lwoAgreementValidateAttributes,
  lwoAgreementValidateState,
  lwoAgreementClearForm,
} = operations;

export {
  lwoAgreementCopyResourceToForm,
  lwoAgreementHandleFormChange,
  lwoAgreementInvalidateCache,
  lwoAgreementLoadResource,
  lwoAgreementUpdateResource,
  lwoAgreementValidateAttributes,
  lwoAgreementValidateState,
  lwoAgreementClearForm,
};

export default operations;
