import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { safeAccess } from '../../../utils';

import { getUserId } from '../../../auth';
import { withPermissions } from '../../../routing';

import ForgivenessProgress from '../components/forgiveness-progress';

import { implementationResultsLoadResource } from '../../../implementation-results';

class ConsolidationFS extends React.Component {
  static propTypes = {
    request: t.object.isRequired,
    data: t.object,
    loadData: t.func.isRequired,
    id: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.loadData(this.props.id);
  }

  render() {
    const { data, request } = this.props;
    return (
      <div>
        <ForgivenessProgress />
        <AsyncLoader request={request}>
          {data.consolidationAppSigned === false && (
            <DashboardSection>
              <Card>
                <Card>
                  <CardBody>
                    <CardTitle>Step 1 - Sign Consolidation Application</CardTitle>
                    <CardText>
                      <ul>
                        <li>Electronically sign the application.</li>
                        <li>
                          Please verify your Social Security Number is present on all pages up to
                          the signature page.
                        </li>
                        <li>Please verify your address on line #9 is not a P.O. box.</li>
                        <li>
                          Please verify your References at the bottom of page one contain different
                          home addresses and phone numbers from both you and each other.
                        </li>
                      </ul>
                    </CardText>
                  </CardBody>
                </Card>
              </Card>
            </DashboardSection>
          )}
          {data.consolidationAppSigned === true && (
            <DashboardSection>
              <Card>
                <Card>
                  <CardBody>
                    <CardTitle>Step 2 – Consolidation Application Processing</CardTitle>
                    <CardText>
                      <ul>
                        <li>
                          We are evaluating the form that was provided and will submit it to the
                          loan servicer.
                        </li>
                        <li>
                          The loan servicer will process the documentation to get your loans in
                          compliance with program regulations.
                        </li>
                        <li>
                          If you receive a bill or any information regarding your loans, please
                          upload it to your Documents page.
                        </li>
                        <li>This documentation can take several months to process.</li>
                        <li>You can submit a Contact Us request if you have any questions.</li>
                      </ul>
                    </CardText>
                  </CardBody>
                </Card>
              </Card>
            </DashboardSection>
          )}
        </AsyncLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: getUserId(state),
    data: safeAccess(state, `implementationResults.cache[${getUserId(state)}]`) || {},
    request: state.implementationResults.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(implementationResultsLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ConsolidationFS));
