import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import t from 'prop-types';
import { connect } from 'react-redux';

import { AsyncLoader, Card, CardBody, CardTitle, CardText, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';

import { authLoadPermissions } from '../../../auth';

import RenewalConfirmation from '../../forgiveness/components/renewal-confirmation';
import ReducedIDRProgress from '../components/reduced-idr-progress';

import { todosLoadCollection } from '../../../todos/redux/operations';

const ReducedIDRQuestionnaire = ({ request, load }) => {
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    load();
  }, []);

  if (redirect && redirect.length > 0) {
    return <Redirect to={redirect} />;
  } else {
    return (
      <>
        <div>
          <ReducedIDRProgress />
          <DashboardSection>
            <Card brand="primary">
              <AsyncLoader request={request}>
                <CardBody>
                  <CardTitle>Renewal Questionnaire</CardTitle>
                  <CardText>
                    <p>
                      In order to determine what steps are required to complete your renewal, please
                      fill out and submit the renewal questionnaire.
                    </p>
                  </CardText>
                </CardBody>
              </AsyncLoader>
              <RenewalConfirmation stepId={1605} forReducedIDR={true} />
            </Card>
          </DashboardSection>
        </div>
      </>
    );
  }
};

ReducedIDRQuestionnaire.propTypes = {
  request: t.object.isRequired,
  todos: t.array,
  reload: t.func.isRequired,
  load: t.func.isRequired,
  permissions: t.object,
};

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.loadCollection,
    state.legal.requests.updateResource,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({
  reload: async () => {
    dispatch(authLoadPermissions());
    await dispatch(todosLoadCollection());
  },

  load: async () => {
    await dispatch(todosLoadCollection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(ReducedIDRQuestionnaire));
