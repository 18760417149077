import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { BrandColor, Card, CardBody, CardHeader, CardText, Header5 } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const ForgivenessShortTermQualify = ({ savings }) => (
  <Card brand="primary">
    <CardHeader>Forgiveness</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Continue the progress you've made!</Header5>
        <Spaced>
          You've made progress toward forgiveness through a federal program. Your employer has hired
          Fiducius to provide management of this process and help you see receive potentially{' '}
          <strong>
            <BrandColor brand="primary"> {savings < 0 ? '—' : formatAsMoney(savings)} </BrandColor>
          </strong>{' '}
          in student loan forgiveness
        </Spaced>
      </CardText>
    </CardBody>
  </Card>
);

ForgivenessShortTermQualify.propTypes = {
  savings: t.number.isRequired,
};

export default ForgivenessShortTermQualify;
