import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { BrandColor, Card, CardBody, CardHeader, CardText, Header5, Button } from 'fiducius-ui';
import { formatAsMoney } from '../../../utils';
import { withPermissions, Fade } from '../../../routing';
import { getUserId } from '../../../auth';
const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const TuitionQualify = withPermissions(({ tuitionAmount, cache }) => (
  <Card brand="primary">
    <CardHeader>Tuition Reimbursement</CardHeader>
    <CardBody>
      <CardText>
        <Header5>Going back to, or already in school?</Header5>
        <Spaced>
          Your company has allocated up to{' '}
          <strong>
            <BrandColor brand="primary"> {formatAsMoney(tuitionAmount)} </BrandColor>
          </strong>{' '}
          to use towards tuition per calendar year.
        </Spaced>
      </CardText>
      <Fade show={cache.qualifiedForgiveness === false}>
        <Button
          brand="primary"
          onClick={() => {
            window.location.href = '/benefits/tuition/overview';
          }}
        >
          Setup My Tuition Reimbursement
        </Button>
      </Fade>
    </CardBody>
  </Card>
));

TuitionQualify.propTypes = {
  tuitionAmount: t.number.isRequired,
};

const mapStateToProps = (state) => {
  const { advisor } = state;
  const { cache } = advisor;
  const id = getUserId(state);
  return {
    cache: cache[id],
  };
};

export default connect(mapStateToProps)(withPermissions(TuitionQualify));
