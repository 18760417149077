import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Alert,
  Button,
  Card,
  CardTitle,
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  Header4,
} from 'fiducius-ui';

import { safeAccess } from '../../utils';
import ApprovalClass from './approval-class';

const StyledInputError = styled(InputError)`
  display: flex;
  flex-direction: column;
`;

class TuitionApprovalForm extends React.Component {
  static propTypes = {
    action: t.string,
    cache: t.object,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    getResource: t.object,
    onChange: t.func.isRequired,
    onSubmit: t.func.isRequired,
    institutionList: t.object.isRequired,
    defaultInstitutionId: t.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.modalRef = React.createRef();
    this.firstInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // if (!prevProps.isOpen && this.props.isOpen) {
    //     // TODO: this focuses correctly, but loses focus right afterwards for some reason
    //     this.firstInput.current.focus();
    // }
    /*if (!prevProps.errors.generalErrors && this.props.errors.generalErrors) {
      this.modalRef.current.scrollTo(0, 0);
    }*/
  }

  getClasses = (data, errors) => {
    const { cache } = this.props;
    let forms = [];
    const size = data.classLength;

    for (let i = 0; i < size; i++) {
      let newData = {};
      let formErrors = {};

      if (data.classes[i].className === undefined) {
        data.classes[i].className = cache[data.id].classes[i].className;
        data.classes[i].classStatus = cache[data.id].classes[i].classStatus;
        data.classes[i].classId = cache[data.id].classes[i].classId;
        data.classes[i].approvalStatus = cache[data.id].classes[i].approvalStatus;
        data.classes[i].classNote = cache[data.id].classes[i].classNote;
      }

      newData['approvalStatus'] = data.classes[i].approvalStatus;
      newData['classNote'] = data.classes[i].classNote;
      newData['className'] = data.classes[i].className;
      formErrors['approvalStatus'] = safeAccess(errors, 'approvalStatus' + i);
      formErrors['classNote'] = safeAccess(errors, 'classNote' + i);
      forms.push(
        <ApprovalClass data={newData} id={i} errors={formErrors} handleChange={this.handleChange} />
      );
    }
    return forms;
  };

  allowedToSubmit = () => {
    const { cache, data, errors } = this.props;
    let retVal = true;
    if (!data.email) {
      retVal = false;
    } else {
      for (let i = 0; i < data.classLength; i++) {
        if (
          !cache[data.id].classes[i].approvalStatus ||
          cache[data.id].classes[i].approvalStatus === ''
        ) {
          retVal = false;
        } else if (
          cache[data.id].classes[i].approvalStatus === 'N' &&
          (cache[data.id].classes[i].classNote === undefined ||
            cache[data.id].classes[i].classNote.trim() === '')
        ) {
          retVal = false;
        }
      }
    }

    if (safeAccess(errors, 'email')) {
      retVal = false;
    }

    for (let i = 0; i < data.classLength; i++) {
      if (safeAccess(errors, 'approvalStatus' + i)) {
        retVal = false;
      } else if (safeAccess(errors, 'classNote' + i)) {
        retVal = false;
      }
    }
    return retVal;
  };

  render() {
    const { data, errors = {}, onSubmit, onChange } = this.props;

    if (data && data.message && data.message.length > 0) {
      return <Card>{data.message}</Card>;
    } else {
      return (
        <>
          {errors.generalErrors &&
            errors.generalErrors.map((e, i) => (
              <Alert key={i} brand="danger">
                {e.detail}
              </Alert>
            ))}
          <CardTitle>Term/Semester Information for {data.customerName}</CardTitle>
          <Header4>Institution: {data.institutionName}</Header4>
          <Header4>Degree Type: {data.degreeType}</Header4>
          <Header4>Term/Semester: {data.termName}</Header4>
          <Form defaults={data} handleChange={onChange} id={'tuitionApproval'} onSubmit={onSubmit}>
            {this.getClasses(data, errors)}
            <InputWrapper error={!!errors.email}>
              <InputLabel>Approver's Email</InputLabel>
              <StyledInputError
                dangerouslySetInnerHTML={{ __html: safeAccess(errors, 'email.detail') }}
              />
              <InputGroup>
                <InputText
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  defaultValue={data.email}
                />
              </InputGroup>
            </InputWrapper>
            {this.allowedToSubmit() ? (
              <Button className="col-4" brand="primary" onClick={onSubmit}>
                Submit Response(s)
              </Button>
            ) : (
              <>To submit, please finish filling out the above information correctly.</>
            )}
          </Form>
        </>
      );
    }
  }
}

export default TuitionApprovalForm;
