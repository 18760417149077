// This is a simple wrapper for lodash library functions so that our app is encapsulated and
// shielded against changing which (or no) helper library we want to use. This also provides a
// consistent API that makes the most sense for our use cases.
import { default as lodashCamelCase } from 'lodash/camelCase';
import { default as lodashDebounce } from 'lodash/debounce';
import { default as lodashGet } from 'lodash/get';
import { default as lodashIsEqual } from 'lodash/isEqual';
import { default as lodashKebabCase } from 'lodash/kebabCase';
import { default as lodashPick } from 'lodash/pick';
import { default as lodashSnakeCase } from 'lodash/snakeCase';
import { default as lodashStartCase } from 'lodash/startCase';
import { default as lodashUpperFirst } from 'lodash/upperFirst';

// String manipulation

/**
 * Converts a string to camel case
 *
 * @param {string} [string=''] - The string to convert
 * @returns {string} Returns the converted string
 */
export const camelCase = (string = '') => lodashCamelCase(string);

/**
 * Converts a string to kebab case
 *
 * @param {string} [string=''] - The string to convert
 * @returns {string} Returns the converted string
 */
export const kebabCase = (string = '') => lodashKebabCase(string);

/**
 *
 * @param {*} string - The value to check
 * @returns {boolean} Returns string not null or empty
 */
export const stringIsNullOrEmpty = (string) => {
  return string === undefined || string === null || string === '';
};

/**
 *
 * @param {*} string - The value to check
 * @returns {boolean} Returns string not null or empty after trimming whitespace
 */
export const stringIsNullOrWhitespace = (string) => {
  return string === undefined || string === null || string.trim() === '';
};

/**
 * Converts a string to snake case
 *
 * @param {string} [string=''] - The string to convert
 * @returns {string} Returns the converted string
 */
export const snakeCase = (string = '') => lodashSnakeCase(string);

/**
 * Converts a string to start case
 *
 * @param {string} [string=''] - The string to convert
 * @returns {string} Returns the converted string
 */
export const startCase = (string = '') => lodashStartCase(string);

/**
 * Converts a string to upper first
 *
 * @param {string} [string=''] - The string to convert
 * @returns {string} Returns the converted string
 */
export const upperFirst = (string = '') => lodashUpperFirst(string);

// Object manipulation
/**
 * Gets the value at `path` of `object`. If the resolved value is
 * `undefined`, the `defaultValue` is returned in its place.
 *
 * @param {Object} object - The object to query.
 * @param {Array|string} path - The path of the property to get.
 * @param {*} [defaultValue] - The value returned for `undefined` resolved values.
 * @returns {*} Returns the resolved value.
 *
 * // const object = { a: [{ b: { c: 3 } }] };
 * // get(object, 'a[0].b.c'); // => 3
 * // get(object, ['a', '0', 'b', 'c']); // => 3
 * // get(object, 'a.b.c', 'default'); // => 'default'
 */
export const safeAccess = (object, path, defaultValue = null) =>
  lodashGet(object, path, defaultValue);

/**
 * Performs a deep comparison between two values to determine if they are
 * equivalent.
 *
 * **Note:** This method supports comparing arrays, array buffers, booleans,
 * date objects, error objects, maps, numbers, Object objects, regexes, sets,
 * strings, symbols, and typed arrays. Object objects are compared by their own,
 * not inherited, enumerable properties. Functions and DOM nodes are compared by
 * strict equality, i.e. ===.
 *
 * @param {*} value - The value to compare.
 * @param {*} other - The other value to compare.
 * @returns {boolean} Returns true if the values are equivalent, else false.
 */
export const isEqual = (value, other) => lodashIsEqual(value, other);

/**
 * Creates an object composed of the picked `object` properties.
 *
 * @param {Object} object - The source object.
 * @param {...(string|string[])} [paths] - The property paths to pick.
 * @returns {Object} Returns the new object.
 *
 * // const object = { a: 1, b: '2', c: 3 }
 * // pick(object, ['a', 'c']) // => { a: 1, c: 3 }
 */
export const pick = (object, paths = undefined) => lodashPick(object, paths);

// Function manipulation
/**
 * Creates a debounced function that delays invoking `func` until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked, or until the next browser frame is drawn. The debounced function
 * comes with a `cancel` method to cancel delayed `func` invocations and a
 * `flush` method to immediately invoke them. Provide `options` to indicate
 * whether `func` should be invoked on the leading and/or trailing edge of the
 * `wait` timeout. The `func` is invoked with the last arguments provided to the
 * debounced function. Subsequent calls to the debounced function return the
 * result of the last `func` invocation.
 *
 * @param {Function} func - The function to debounce.
 * @param {number} [wait=0] - The number of milliseconds to delay; if omitted, `requestAnimationFrame` is used (if available).
 * @param {Object} [options={}] - The options object.
 * @param {boolean} [options.leading=false] - Specify invoking on the leading edge of the timeout.
 * @param {number} [options.maxWait] - The maximum time `func` is allowed to be delayed before it's invoked.
 * @param {boolean} [options.trailing=true] - Specify invoking on the trailing edge of the timeout.
 * @returns {Function} Returns the new debounced function.
 */
export const debounce = (func, wait = 0, options = {}) => lodashDebounce(func, wait, options);
