// Storage persistence types
const PERSIST_INITIAL_LOAD = 'app/persist/INITIAL_LOAD';
const PERSIST_REMEMBER_ME = 'app/persist/REMEMBER_ME';
const PERSIST_HYDRATE_STATE = 'app/persist/HYDRATE_STATE';
const PERSIST_RESET_STATE = 'app/persist/RESET_STATE';

const PERSIST_CLEAR_REQUEST = 'app/persist/CLEAR_REQUEST';
const PERSIST_CLEAR_RESPONSE = 'app/persist/CLEAR_RESPONSE';
const PERSIST_CLEAR_FAILURE = 'app/persist/CLEAR_FAILURE';
const PERSIST_CLEAR_SUCCESS = 'app/persist/CLEAR_SUCCESS';

const PERSIST_LOAD_REQUEST = 'app/persist/LOAD_REQUEST';
const PERSIST_LOAD_RESPONSE = 'app/persist/LOAD_RESPONSE';
const PERSIST_LOAD_FAILURE = 'app/persist/LOAD_FAILURE';
const PERSIST_LOAD_SUCCESS = 'app/persist/LOAD_SUCCESS';

const PERSIST_SAVE_REQUEST = 'app/persist/SAVE_REQUEST';
const PERSIST_SAVE_RESPONSE = 'app/persist/SAVE_RESPONSE';
const PERSIST_SAVE_FAILURE = 'app/persist/SAVE_FAILURE';
const PERSIST_SAVE_SUCCESS = 'app/persist/SAVE_SUCCESS';

export default {
  PERSIST_INITIAL_LOAD,
  PERSIST_REMEMBER_ME,

  PERSIST_RESET_STATE,
  PERSIST_HYDRATE_STATE,

  PERSIST_CLEAR_REQUEST,
  PERSIST_CLEAR_RESPONSE,
  PERSIST_CLEAR_FAILURE,
  PERSIST_CLEAR_SUCCESS,

  PERSIST_LOAD_REQUEST,
  PERSIST_LOAD_RESPONSE,
  PERSIST_LOAD_FAILURE,
  PERSIST_LOAD_SUCCESS,

  PERSIST_SAVE_REQUEST,
  PERSIST_SAVE_RESPONSE,
  PERSIST_SAVE_FAILURE,
  PERSIST_SAVE_SUCCESS,
};
