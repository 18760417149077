import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  ChartLine,
  ChartBar,
  DashboardSection,
  BrandColor,
  Header4,
  Table,
} from 'fiducius-ui';

const RetirementConfig = {
  multiAxis: false,
  //label: 'Years',
  max: 130000,
  min: 0,
  labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
};

const RetirementLines = [
  {
    brand: 'secondary',
    dataType: 'currency',
    fill: true,
    title: 'IDR',
    data: [
      0.0,
      1887.15,
      3774.3,
      5661.45,
      7548.6,
      9435.75,
      11322.9,
      13210.04,
      15097.19,
      16984.34,
      18871.49,
    ],
    legend: {
      display: false,
    },
    // options:{
    scales: {
      xAxes: [
        {
          label: 'Years',
        },
      ],
    },
    // }
  },
  {
    brand: 'primary',
    dataType: 'currency',
    fill: true,
    title: 'STD',
    data: [
      0.0,
      14494.07,
      28988.15,
      43482.22,
      57976.29,
      72470.37,
      86964.44,
      101458.51,
      115952.58,
      130446.66,
      144940.73,
    ],
    legend: {
      display: false,
    },
    // options:{
    scales: {
      xAxes: [
        {
          label: 'Years',
        },
      ],
    },
    // }
  },
];

// const paymentConfig = {
//   labels: ['Standard', 'IDR'],
//   max: 1400,
//   min: 0,
//   //options: {
//   scales: {
//     xAxes: [
//       {
//         stacked: true,
//       },
//     ],
//     yAxes: [
//       {
//         stacked: true,
//       },
//     ],
//   },
//   //}
// };
//
// const paymentBars = [
//   {
//     //brand: 'secondary',
//     data: [
//       {
//         datasets: [
//           {
//             data: [
//               {
//                 values: [147.75, 1200.0],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

const taxConfig = {
  labels: ['Married, Filing Jointly', 'Married, Filing Separately'],
  max: 15000,
  min: 0,
  legend: {
    display: false,
  },
  horizontal: true,
};

// const taxBars = [
//   {
//     //brand: 'secondary',
//     data: [
//       {
//         datasets: [
//           {
//             title: 'IDR',
//             data: [
//               {
//                 values: [14496, 2958],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];

const MyRetirement = () => (
  <DashboardSection title="My Retirement">
    <Header4>
      With your current loan balance of <strong>$108,000</strong> and a retirement contribution rate
      of <strong>6%</strong>, your estimated forgiveness will be <strong>$126,152.57</strong>.
      <br />
      <br />
      Increasing your retirement contribution to a total of <strong>9%</strong>, will increase:
      <br />
      <ul>
        <li>
          Your forgiveness amount by <strong>$2,313</strong>
        </li>
        <li>
          Your retirement savings by at least <strong>$21,579</strong>
        </li>
      </ul>
      <br />
      Consult with your HR or Payroll Specialist to determine Pre-Tax benefit offerings.
      <br />
      <br />
      Common Pre-Tax Deductions include, but are not limited to:
      <br />
      <ul>
        <li>Medical/Dental/Vision Insurance</li>
        <li>FSA/HSA</li>
        <li>STD/LTD</li>
        <li>Retirement Contributions</li>
      </ul>
      We will assist you with your benefit decisions during your next open enrollment to determine
      the effect on your payment.
      <br />
    </Header4>
    <div className="row">
      <div className="col-12 col-lg-8">
        <Card>
          <CardBody>
            <CardTitle>Retirement Details</CardTitle>
            <div className="row">
              <div className="col-6">
                <CardText>
                  <dl>
                    <dt>Current Contribution Rate</dt>
                    <dt>(per pay)</dt>
                    <dd>6%</dd>
                    <dt>Est Retirement Balance</dt>
                    <dt>after 10 years</dt>
                    <dd>$43,158</dd>
                    <dt>Est Total Forgiveness</dt>
                    <dd>$126,152.57</dd>
                  </dl>
                </CardText>
              </div>
              <div className="col-6">
                <CardText>
                  <dl>
                    <dt>Increased Contribution Rate</dt>
                    <dt>(per pay)</dt>
                    <dd>9%</dd>
                    <dt>Est Retirement Balance</dt>
                    <dt>after 10 years</dt>
                    <dd>
                      <strong>
                        <BrandColor brand="secondary">$64,737</BrandColor>
                      </strong>
                    </dd>
                    <dt>Est Total Forgiveness</dt>
                    <dd>
                      <strong>
                        <BrandColor brand="secondary">$167,702</BrandColor>
                      </strong>
                    </dd>
                  </dl>
                </CardText>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-lg-5 col-xl-4">
        <Card brand="success">
          {/* <CardHeader>Total Projected Forgiveness <strong>$119.334.60</strong></CardHeader> */}
          <CardHeader>
            <center>
              Total Repayment - Standard vs IDR
              <br />
              <strong>$126,152.57 Est Forgiveness</strong>
            </center>
          </CardHeader>
          <CardBody>
            {/*<ChartLine lines={lines} config={config} />*/}
            <ChartLine lines={RetirementLines} config={RetirementConfig} />
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-lg-6">
        <Card brand="success">
          <CardHeader>Retirement Plan: Increasing Contribution</CardHeader>
          <CardBody>
            <ChartBar
              values={[
                {
                  label: '3% Growth',
                  data: [23000, 33892, 56489],
                  // backgroundColor: [
                  //   '#ff774d', // rgba(255,119,077,1) - Coral           WIP: https://coolors.co/f96302-fa8d47-ff774d-ff6a4d-d7654f
                  //   '#18bc70', // rgba(024,188,112,1) - Sea Green       WIP: https://coolors.co/709302-69995d-5a7f43-18bc70-00d081
                  //   '#6796eb', // rgba(084,097,110,1) - Teal            WIP: https://coolors.co/38a6ea-5296eb-6796eb-25a9a3-6bdbe4
                  // ],
                },
                {
                  label: '6% Growth',
                  data: [39275, 43158, 64737],
                  // backgroundColor: [
                  //   '#ff774d', // rgba(255,119,077,1) - Coral           WIP: https://coolors.co/f96302-fa8d47-ff774d-ff6a4d-d7654f
                  //   '#18bc70', // rgba(024,188,112,1) - Sea Green       WIP: https://coolors.co/709302-69995d-5a7f43-18bc70-00d081
                  //   '#6796eb', // rgba(084,097,110,1) - Teal            WIP: https://coolors.co/38a6ea-5296eb-6796eb-25a9a3-6bdbe4
                  // ],
                },
                {
                  label: '9% Growth',
                  data: [64458, 76394, 89779],
                  // backgroundColor: [
                  //   '#ff774d', // rgba(255,119,077,1) - Coral           WIP: https://coolors.co/f96302-fa8d47-ff774d-ff6a4d-d7654f
                  //   '#18bc70', // rgba(024,188,112,1) - Sea Green       WIP: https://coolors.co/709302-69995d-5a7f43-18bc70-00d081
                  //   '#6796eb', // rgba(084,097,110,1) - Teal            WIP: https://coolors.co/38a6ea-5296eb-6796eb-25a9a3-6bdbe4
                  // ],
                },
              ]}
              config={{
                min: 0,
                max: 80000,
                labels: ['6% Contribution', '9% Contribution'],
                options: {
                  scales: {
                    yAxes: [
                      {
                        stacked: true,
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                    xAxes: [
                      {
                        stacked: true,
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                },
              }}
            />
          </CardBody>
        </Card>
      </div>
      <div className="col-12 col-lg-6">
        <Card>
          <CardBody>
            <CardTitle>References</CardTitle>
          </CardBody>
          <Table
            columns={[{ sortable: true, width: '50%' }, { sortable: true }, { sortable: true }]}
            header={['Nam', 'Type', 'Status']}
            body={[
              ['John', 'Friend', 'Verified'],
              ['Joe', 'Family', 'Verified'],
              ['Jack', 'Friend', 'Verified'],
              ['Jill', 'Family', 'Verified'],
              ['Jane', 'Friend', 'Verified'],
            ]}
          />
        </Card>
      </div>
      <div className="col-12 col-lg-6">
        <Card brand="success">
          <CardHeader>
            <center>
              Tax Savings Filing Separately* <strong>$11,538</strong>
            </center>
          </CardHeader>
          <CardBody>
            <ChartBar
              values={[
                {
                  label: '',
                  data: [14496, 2958],
                  backgroundColor: [
                    '#ff774d', // rgba(255,119,077,1) - Coral           WIP: https://coolors.co/f96302-fa8d47-ff774d-ff6a4d-d7654f
                    '#18bc70', // rgba(024,188,112,1) - Sea Green       WIP: https://coolors.co/709302-69995d-5a7f43-18bc70-00d081
                    //'#6796eb', // rgba(084,097,110,1) - Teal            WIP: https://coolors.co/38a6ea-5296eb-6796eb-25a9a3-6bdbe4
                  ],
                },
              ]}
              //values={[14496, 2958]}
              config={taxConfig}
            />
            {/* <ChartLine lines={lines} config={config} /> */}
          </CardBody>
        </Card>
      </div>
    </div>
  </DashboardSection>
);

export default MyRetirement;
