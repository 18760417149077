import { FormValidator } from '../../utils';

export class ProfileValidator extends FormValidator {
  // Account tab validators
  contactEmail(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidEmail();
  }

  // Contact tab validators
  cellPhone(validator) {
    validator.isRequired();
    validator.isValidPhoneNumber();
  }

  addressOne(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  addressTwo(validator) {
    validator.hasMaxLength(30);
  }

  city(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  usState(validator) {
    validator.requiredSelection();
    validator.hasMaxLength(2);
  }

  zipCode(validator) {
    validator.isRequired();
    validator.hasMaxLength(10);
    validator.matchesRegex(
      /^[0-9]{5}($|-[0-9]{4}$)/g,
      'must be formatted as either ##### or #####-####'
    );
  }

  // Personal tab validators
  dob(validator) {
    validator.isRequired();
  }

  driverLicenseNumber(validator) {
    validator.isRequired();
    validator.hasMaxLength(20);
  }

  driverLicenseState(validator) {
    validator.requiredSelection();
    validator.hasMaxLength(2);
  }

  firstName(validator) {
    validator.isRequired();
    validator.hasMaxLength(20);
  }

  lastName(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  maidenName(validator) {
    validator.hasMaxLength(30);
  }

  middleInitial(validator) {
    validator.hasMaxLength(1);
  }

  suffix(validator) {
    validator.hasMaxLength(15);
  }

  //Correct info checkbox validator

  verifyProfile(validator, state) {
    if (state.needsProfileVerification) {
      validator.checkRequired();
    }
  }
}
