import actions from './actions';
import { LoanRepaymentValidator } from './validators';
import { resourceOperationsGenerator } from '../../utils';

const loanRepaymentValidator = new LoanRepaymentValidator();

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/loan-repayment',
  resourceType: 'loan-repayment',
};

const operations = resourceOperationsGenerator(
  'loanRepayment',
  actions,
  null,
  loanRepaymentValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler
);

const {
  loanRepaymentClearForm,
  loanRepaymentCopyResourceToForm,
  loanRepaymentHandleFormChange,
  loanRepaymentLoadResource,
  loanRepaymentLoadCollection,
  loanRepaymentCreateResource,
  loanRepaymentDeleteResource,
  loanRepaymentUpdateResource,
} = operations;

export {
  loanRepaymentClearForm,
  loanRepaymentCopyResourceToForm,
  loanRepaymentHandleFormChange,
  loanRepaymentLoadResource,
  loanRepaymentLoadCollection,
  loanRepaymentCreateResource,
  loanRepaymentDeleteResource,
  loanRepaymentUpdateResource,
};

export default operations;
