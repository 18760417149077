import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, DashboardSection, Header2 } from 'fiducius-ui';

import { getUserId } from '../../auth';

import ProfileForm from './profile-form';
import { profileLoadResource } from '../redux/operations';

class MyProfile extends React.Component {
  static propTypes = {
    hasData: t.bool,
    id: t.string,
    loadData: t.func.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {}; // must be initialized for data handling
  }

  componentDidMount() {
    const { id, loadData } = this.props;
    loadData(id);
  }

  render() {
    const { hasData, id, request } = this.props;
    return (
      <DashboardSection title="My Profile">
        <Header2>One simple place to keep all of your information up to date</Header2>
        <Card>
          <CardBody>
            <AsyncLoader request={request}>{hasData && <ProfileForm id={id} />}</AsyncLoader>
          </CardBody>
        </Card>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => ({
  hasData: state.profile.cache && Object.keys(state.profile.cache).length > 0,
  id: getUserId(state),
  request: state.profile.requests.loadResource,
});

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => dispatch(profileLoadResource(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
