import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { safeAccess } from '../../utils';

import {
  Button,
  Form,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  InputError,
  InputSelect,
  AsyncLoader,
  CardBody,
  CardFooter,
  Loader,
  CardText,
} from 'fiducius-ui';

class SecurityQuestionForm extends React.Component {
  static propTypes = {
    onChange: t.func.isRequired,
    data: t.object,
    form: t.object,
    errors: t.object,
    handleKeyPress: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data, form, errors, onChange, handleKeyPress } = this.props;
    return (
      <Form id="check" handleChange={onChange}>
        <InputWrapper error={!!errors.securityQuestionResponse}>
          <InputLabel>{data.securityQuestion}</InputLabel>
          <InputError>{safeAccess(errors, 'securityQuestionResponse.detail')}</InputError>
          <InputGroup>
            <InputText
              name="securityQuestionResponse"
              type="password"
              defaultValue={form.securityQuestionResponse}
              onKeyPress={handleKeyPress}
            />
          </InputGroup>
        </InputWrapper>
      </Form>
    );
  }
}

export default SecurityQuestionForm;
