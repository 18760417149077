import { FormValidator } from '../../utils';

export class FulfillmentValidator extends FormValidator {
  firstName(validator) {
    validator.isRequired();
    validator.hasMaxLength(20);
  }

  lastName(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  email(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidEmail();
  }

  password(validator) {
    validator.isRequired();
    validator.hasMinLength(8);
    validator.hasMaxLength(50);
  }

  signaturePadClient(validator) {
    validator.isRequired();
  }
}
