import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import { withPermissions } from '../../../routing';
import { AsyncLoader, DashboardSection } from 'fiducius-ui';

import { getContributionData } from '../redux/selectors';
import ContributionSummary from '../components/contribution-summary';
import ContributionRequiredInfo from '../components/contribution-required-info';

import { safeAccess } from '../../../utils';
import { contributionLoadResource } from '../redux/operations';
import { customerContributionLoadCollection, Overview } from '../../../customer-contribution';
import { customerContributionMilestoneLoadCollection } from '../../../customer-contribution-milestone';
import { contributionSetupLoadResource } from '../../../contribution-setup';
import { MyMilestones } from '../../../customer-contribution-milestone';
import { MessageTile } from '../../../messages';

import { getUserId } from '../../../auth';

class ContributionOverview extends React.Component {
  static propTypes = {
    id: t.string,
    data: t.object,
    request: t.object,
    milestoneRequest: t.object,
    getFAQ: t.func.isRequired,
    permissions: t.object,
    loadData: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSetUp: false,
    }; // must be initialized for data handling

    this.handleSetupButton = this.handleSetupButton.bind(this);
  }

  handleSetupButton() {
    this.setState({ showSetUp: !this.state.showSetUp });
  }

  handleFilingStatus(event) {
    this.setState({ filingStatus: event.target.value });
  }

  componentDidMount() {
    const { loadData, id } = this.props;
    loadData(id);
  }

  onDownloadFaq = () => {
    this.props.getFAQ().then((data) => {
      if (safeAccess(data, 'fileData')) {
        this.getFileFromData(data.fileData, 'FAQ.pdf');
      }
    });
  };

  render() {
    const { data, request, milestoneRequest } = this.props;
    return (
      <DashboardSection title="My Contribution">
        <div id="messageSection">
          <MessageTile messageType={'Contribution'} />
        </div>
        <AsyncLoader request={request}>
          <div className="row">
            <div className="col-12 col-lg-7">
              <ContributionSummary />
              <Overview />
            </div>
            <div className="col-12 col-lg-5">
              <ContributionRequiredInfo data={data} />
              <AsyncLoader request={milestoneRequest} loading={null} empty={null}>
                <MyMilestones />
              </AsyncLoader>
            </div>
          </div>
        </AsyncLoader>
      </DashboardSection>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.contribution;

  return {
    id: getUserId(state),
    data: getContributionData(state),
    request: requests.loadResource,
    milestoneRequest: state.customerContributionMilestone.requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: (id) => {
    dispatch(contributionLoadResource(id));
    dispatch(contributionSetupLoadResource(id));
    dispatch(customerContributionLoadCollection());
    dispatch(customerContributionMilestoneLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ContributionOverview));
