import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Workflow, Button } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { debounce, stringIsNullOrEmpty, safeAccess } from '../../../utils';
import { withPermissions } from '../../../routing';

import TabUpload from './tab-upload';
import TabValidate from './tab-validate';
import TabReview from './tab-review';
import {
  nsldsClearForm,
  nsldsHandleFormChange,
  nsldsValidateAttributes,
} from '../redux/operations';

import { documentsLoadCollection, documentsDisableValidation } from '../../../documents';
import { todosLoadCollection } from '../../../todos/redux/operations';

class NSLDSWorkflow extends React.Component {
  static propTypes = {
    current: t.number,
    data: t.object,
    errors: t.object,
    id: t.string,
    todos: t.array,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    updateForm: t.func.isRequired,
    validateTab: t.func.isRequired,
    clearForm: t.func.isRequired,
    loadDocs: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      current: props.current || 0,
      farthest: props.current || 0,
      redirectURI: '',
      nsldsUploaded: false,
      toDoItemForRedirect: 0,
    };
  }

  componentDidMount() {
    this.props.loadDocs();
  }

  componentDidUpdate() {
    if (
      this.props.permissions.showLwo &&
      this.state.toDoItemForRedirect === 0 &&
      this.props.todos.length > 0
    ) {
      let currentLwoToDo = this.props.todos.find(
        (a) => a.id === 602 || a.id === 603 || a.id === 609 || a.id === 610
      );
      if (currentLwoToDo !== undefined) {
        this.setState({
          toDoItemForRedirect: currentLwoToDo.id,
        });
      }
    }
  }

  handleChange = debounce((formState, id, attribute) => {
    const { data, updateForm } = this.props;
    const newData = { ...data, ...formState };
    updateForm(newData, this.getDependentAttributes(attribute));
  }, 250);

  getDependentAttributes = (attribute) => {
    switch (attribute) {
      default:
        return [attribute];
    }
  };

  handleTabChange = (index) => {
    this.props.validateTab(Object.keys(tabs)[this.state.current]);
    this.setState({
      current: index,
      farthest: index > this.state.farthest ? index : this.state.farthest,
    });
  };

  sectionValidationStatus(section, index) {
    let exists = false;
    if (index <= this.state.farthest) {
      if (index === 0) {
        if (index < this.state.farthest) {
          return !this.state.nsldsUploaded ? 'danger' : 'success';
        } else {
          exists = this.state.nsldsUploaded;
        }
      } else if (index === 1) {
        if (this.state.nsldsUploaded) {
          return this.validateNsldsInfo();
        }
      }
    }
    return exists ? 'success' : null;
  }

  validateNsldsInfo() {
    const { data } = this.props;
    let retVal = 'success';
    if (stringIsNullOrEmpty(data.fileRequestDate)) {
      retVal = 'danger';
    } else if (data.loans === undefined || data.loans.length === 0) {
      retVal = 'danger';
    } else if (
      stringIsNullOrEmpty(data.undergraduateAggregateSubsidizedTotal) ||
      stringIsNullOrEmpty(data.undergraduateAggregateUnsubsidizedTotal) ||
      stringIsNullOrEmpty(data.undergraduateAggregateCombinedTotal) ||
      stringIsNullOrEmpty(data.graduateAggregateSubsidizedTotal) ||
      stringIsNullOrEmpty(data.graduateAggregateUnsubsidizedTotal) ||
      stringIsNullOrEmpty(data.graduateAggregateCombinedTotal) ||
      stringIsNullOrEmpty(data.aggregateSubsidizedTotal) ||
      stringIsNullOrEmpty(data.aggregateUnsubsidizedTotal) ||
      stringIsNullOrEmpty(data.aggregateCombinedTotal)
    ) {
      retVal = 'danger';
    }
    return retVal;
  }

  reviewValidationStatus = (index) => {
    const upload = this.sectionValidationStatus('upload', 0);
    const validate = this.sectionValidationStatus('review', 1);

    if (this.state.farthest === 2 || index === 3) {
      if (upload === 'success' && validate === 'success') {
        return 'success';
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  allowedNavigate = (i) => {
    let retVal = true;
    return retVal;
  };

  updateNsldsUploaded = (uploaded) => {
    this.setState({ ...this.state, nsldsUploaded: uploaded });
  };

  uploadDifferentNslds = () => {
    this.setState({ ...this.state, redirectURI: '/nslds' });
  };

  getRedirectUrl = () => {
    const { permissions } = this.props;
    let retVal = '/documents';
    if (permissions.showLwo) {
      const currentToDo = this.state.toDoItemForRedirect;
      if (currentToDo > 0) {
        retVal = '/benefits/limited-waiver-opportunity';
        switch (currentToDo) {
          case 602:
            retVal += '/questionnaire';
            break;
          case 609:
            retVal += '/complete';
            break;
          default:
            break;
        }
      }
    }
    return retVal;
  };

  getRedirectButtonText = () => {
    const { permissions } = this.props;
    let retVal = 'Back to My Documents';
    if (permissions.showLwo) {
      const lwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);
      if (
        lwoStep.id === 'Questionnaire' ||
        lwoStep.id === 'Complete' ||
        lwoStep.id === 'LoanInformation' ||
        lwoStep.id === 'QualifyingPaymentCheck'
      ) {
        retVal = <>Back to Assure&#8480;</>;
      }
    }
    return retVal;
  };

  render() {
    const { data = {}, errors } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    }
    return (
      <>
        <>
          <Workflow
            tabs={[
              <TabUpload
                key="1"
                id="upload"
                data={data}
                errors={errors}
                brand={this.sectionValidationStatus('upload', 0)}
                name="Upload"
                handleChange={this.handleChange}
                updateNsldsUploaded={this.updateNsldsUploaded}
              />,
              <TabValidate
                key="2"
                id="review"
                data={data}
                errors={errors}
                brand={this.sectionValidationStatus('review', 1)}
                name="Review"
                handleChange={this.handleChange}
                nsldsUploaded={this.state.nsldsUploaded}
                updateNsldsUploaded={this.updateNsldsUploaded}
                uploadDifferentNslds={this.uploadDifferentNslds}
                inScreening={false}
              />,
              <TabReview
                key="3"
                id="complete"
                data={data}
                errors={errors}
                brand={this.reviewValidationStatus(2)}
                name="Complete"
                handleChange={this.handleChange}
                inScreening={false}
              />,
            ]}
            finalButton={
              <Button
                brand="primary"
                invert
                onClick={() => {
                  this.setState({ ...this.state, redirectURI: this.getRedirectUrl() });
                }}
              >
                {this.getRedirectButtonText()}
              </Button>
            }
            handleTabChange={this.handleTabChange}
            next
            prev
            finalPageLocksFlow
            finalPageCheck={() => this.reviewValidationStatus(3)}
            penUltimateButton={'Finish'}
            allowedNavigate={this.allowedNavigate}
          />
        </>
      </>
    );
  }
}

const tabs = {
  upload: [],
  validate: [],
  review: [],
};

const mapStateToProps = (state) => {
  const { errors, cache } = state.nslds;
  const id = getUserId(state);
  const data = cache;
  return {
    id: id,
    data: data,
    errors: errors,
    request: state.documents.requests.loadCollection,
    todos: Object.values(state.todos.cache),
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadDocs: () => {
    dispatch(documentsDisableValidation());
    dispatch(documentsLoadCollection());
  },
  updateForm: (data, attributes) => {
    dispatch(nsldsHandleFormChange(data, attributes));
  },
  clearForm: () => {
    dispatch(nsldsClearForm());
  },
  validateTab: async (tab) => {
    await dispatch(nsldsValidateAttributes(tabs[tab]));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(NSLDSWorkflow));
