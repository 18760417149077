import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, DashboardSection } from 'fiducius-ui';

import { withPermissions } from '../../../routing';

import RenewalConfirmation from '../components/renewal-confirmation';
import ForgivenessProgress from '../components/forgiveness-progress';
import StepDisplay from '../components/step-display';

class ForbearanceRS extends React.Component {
  static propTypes = { request: t.object.isRequired };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <>
        <ForgivenessProgress />
        <DashboardSection>
          <Card brand="primary">
            <CardBody>
              <CardTitle>Request Forbearance</CardTitle>

              <StepDisplay title={'Step 1 - Login to your current loan servicer(s) account(s)'}>
                <ul>
                  <li>
                    Request general forbearance on any federal loan(s) that have a payment due. We
                    recommend you request general forbearance for{' '}
                    <strong>at least one month</strong> to allow enough time to complete the
                    remaining steps.{' '}
                  </li>
                  <li>
                    <strong>Please note:</strong> If you have any Income-Driven Repayments showing
                    due for your previous year, you will want to make those payments, if qualifying,
                    prior to submitting a forbearance request.
                  </li>
                </ul>
              </StepDisplay>
              <StepDisplay title={'Step 2 – Confirm you have requested general forbearance'}>
                <ul>
                  <li>
                    I confirm I have submitted a forbearance request at my current loan servicer(s)
                    to place my current loan payment(s) on hold.{' '}
                  </li>
                  <li>
                    Forbearance requests typically take up to two weeks to process and any payment
                    made while in forbearance may not qualify.{' '}
                  </li>
                  <li>
                    <strong>Please note:</strong> If you have used more than 36 months of general
                    forbearance, your request may be denied by the loan servicer.
                  </li>
                </ul>
              </StepDisplay>
            </CardBody>
            <RenewalConfirmation stepId={251} />
          </Card>
        </DashboardSection>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  request: state.todos.requests.loadCollection,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(ForbearanceRS));
