import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';
import {
  Animation,
  Form,
  Header2,
  InputAddon,
  InputError,
  InputGroup,
  InputHelp,
  InputLabel,
  InputNumeric,
  InputRadio,
  InputSelect,
  InputWrapper,
  fade,
} from 'fiducius-ui';

import { useFocusOnMount, useScrollToTopOnMount, safeAccess } from '../../../utils';
import { Fade } from '../../../routing';

const RowFade = styled.div`
  ${fade};
`;

const TabFinance = ({ data = {}, errors = {}, handleChange, id }) => {
  useScrollToTopOnMount();
  const firstInput = useFocusOnMount();
  return (
    <Form handleChange={handleChange} id={id} defaults={data}>
      <Header2>Tell us about your financial situation</Header2>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.income}>
            <InputLabel htmlFor="income">
              Annual Income <br /> (If married, do not include your spouse's income)
            </InputLabel>
            <InputError>{safeAccess(errors, 'income.detail')}</InputError>
            <InputGroup>
              <InputAddon>$</InputAddon>
              <InputNumeric inputRef={firstInput} name="income" defaultValue={data.income} />
              <InputAddon>.00</InputAddon>
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.creditScore}>
            <InputLabel htmlFor="creditScore">Credit Score</InputLabel>
            <InputError>{safeAccess(errors, 'creditScore.detail')}</InputError>
            <InputGroup>
              <InputSelect
                name="creditScore"
                defaultValue={data.creditScore}
                options={{
                  '': '',
                  IDK: "I don't know",
                  UNDER: 'Under 620',
                  MID: '620 - 679',
                  HIGH: 'Above 679',
                }}
              />
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
      <Header2>Tell us about your household</Header2>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.householdSize}>
            <InputLabel htmlFor="householdSize">Household Size</InputLabel>
            <InputError>{safeAccess(errors, 'householdSize.detail')}</InputError>
            <InputGroup>
              <InputNumeric name="householdSize" min="1" defaultValue={data.householdSize} />
            </InputGroup>
            <InputHelp>Household size includes you and all of your dependents</InputHelp>
          </InputWrapper>
        </div>
      </div>
      <InputWrapper error={!!errors.married}>
        <InputLabel>Are you married?</InputLabel>
        <InputError>{safeAccess(errors, 'married.detail')}</InputError>
        <InputRadio name="married" value="Y" label="Yes" defaultChecked={data.married === 'Y'} />
        <InputRadio name="married" value="N" label="No" defaultChecked={data.married === 'N'} />
      </InputWrapper>
      <Animation
        key="marriedExtra"
        animation="fade"
        appear={false}
        in={data.married === 'Y'}
        unmountOnExit
      >
        <RowFade className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.spouseIncome}>
              <InputLabel htmlFor="spouseIncome">Spouse&#39;s Annual Income</InputLabel>
              <InputError>{safeAccess(errors, 'spouseIncome.detail')}</InputError>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric name="spouseIncome" defaultValue={data.spouseIncome} />
                <InputAddon>.00</InputAddon>
              </InputGroup>
            </InputWrapper>
          </div>

          <div className="col-12 col-md-12">
            <InputWrapper error={!!errors.spouseHasStudentLoans}>
              <InputLabel htmlFor="spouseHasStudentLoans">
                Does your spouse have federal student loans?
              </InputLabel>
              <InputError>{safeAccess(errors, 'spouseHasStudentLoans.detail')}</InputError>
              <InputRadio
                name="spouseHasStudentLoans"
                value="Y"
                label="Yes"
                defaultChecked={data.spouseHasStudentLoans === 'Y'}
              />
              <InputRadio
                name="spouseHasStudentLoans"
                value="N"
                label="No"
                defaultChecked={data.spouseHasStudentLoans === 'N'}
              />
              <InputWrapper>
                <InputHelp>
                  We are asking for your spouse's loan information because some joint repayment
                  plans may result in an overall lower monthly payment for your whole household. We
                  look at all options when determining your savings.
                </InputHelp>
              </InputWrapper>
            </InputWrapper>

            <Fade id="spouseHasLoansGate" show={data.spouseHasStudentLoans === 'Y'}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputWrapper error={!!errors.spouseLoanBalance}>
                    <InputLabel htmlFor="spouseLoanBalance">
                      Spouse&#39;s Loan Balance in Repayment Status
                    </InputLabel>
                    <InputError>{safeAccess(errors, 'spouseLoanBalance.detail')}</InputError>
                    <InputGroup>
                      <InputAddon>$</InputAddon>
                      <InputNumeric
                        name="spouseLoanBalance"
                        step="0.01"
                        defaultValue={data.spouseLoanBalance}
                      />
                      <InputAddon>.00</InputAddon>
                    </InputGroup>
                  </InputWrapper>
                </div>
              </div>
            </Fade>
          </div>
        </RowFade>
      </Animation>
      <div className="row">
        <div className="col-12 col-md-6">
          <InputWrapper error={!!errors.taxFiling}>
            <InputLabel htmlFor="taxFiling">Tax Filing Status</InputLabel>
            <InputError>{safeAccess(errors, 'taxFiling.detail')}</InputError>
            <InputGroup>
              <InputSelect
                name="taxFiling"
                defaultValue={data.taxFiling}
                options={{
                  '': '',
                  SING: 'Single',
                  SEP: 'Married - Filing Separately',
                  JOINT: 'Married - Filing Jointly',
                }}
              />
            </InputGroup>
          </InputWrapper>
        </div>
      </div>
    </Form>
  );
};

TabFinance.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func,
  id: t.string.isRequired,
};

export default TabFinance;
