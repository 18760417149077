import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { AsyncLoader, Button, Card, CardBody, CardTitle } from 'fiducius-ui';

import { todosLoadCollection } from '../../todos';
import { debounce, mergeRequestStatuses } from '../../utils';

import ReferenceForm from '../components/reference-form';
import ReferenceTable from '../components/reference-table';
import {
  referencesClearForm,
  referencesCopyResourceToForm,
  referencesCreateResource,
  referencesHandleFormChange,
  referencesLoadCollection,
  referencesUpdateResource,
} from '../redux/operations';

class MyReferences extends React.Component {
  static propTypes = {
    actionRequest: t.object,
    clearForm: t.func.isRequired,
    createReference: t.func.isRequired,
    errors: t.object,
    form: t.object,
    handleForm: t.func.isRequired,
    hydrateForm: t.func.isRequired,
    loadReferences: t.func.isRequired,
    references: t.object,
    request: t.object,
    servicers: t.object,
    updateReference: t.func.isRequired,
    showTitle: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      formId: null,
      showForm: false,
    };
  }

  componentDidMount() {
    this.props.loadReferences();
  }

  componentDidUpdate(prevProps) {
    const wasLoading = prevProps.actionRequest.isLoading;
    const currently = this.props.actionRequest;
    if (wasLoading && !currently.isLoading) {
      if (currently.hasFailed) {
        window.scrollTo(0, 0);
      } else {
        this.setState({ ...this.state, showForm: false }, () => {
          this.props.clearForm();
          this.props.loadReferences();
        });
      }
    }
  }

  closeForm = () => {
    this.props.clearForm();
    this.setState({ ...this.state, showForm: false });
  };

  onAddReference = () => this.setState({ formId: null, showForm: true });

  onEditReference = (id) => {
    this.props.hydrateForm(id);
    this.setState({ formId: id, showForm: true });
  };

  onChange = debounce((reference, id, attributes) => {
    this.props.handleForm({ ...this.props.form, ...reference }, [attributes]);
  }, 250);

  onCreate = () => this.props.createReference();

  onUpdate = () => this.props.updateReference(this.props.form.id);

  render() {
    const { references, actionRequest, errors, form, request, showTitle } = this.props;
    const { formId, showForm } = this.state;

    return (
      <Card>
        {showTitle && (
          <CardBody>
            <CardTitle>References</CardTitle>
          </CardBody>
        )}
        <AsyncLoader
          request={request}
          empty={
            <>
              <ReferenceTable references={references} onEditReference={this.onEditReference} />
              <CardBody>
                <Button brand="primary" onClick={this.onAddReference}>
                  <FontAwesomeIcon icon={faPlusCircle} fixedWidth /> Add reference
                </Button>
              </CardBody>
            </>
          }
        >
          <>
            {references && (
              <ReferenceTable references={references} onEditReference={this.onEditReference} />
            )}
            {Object.keys(references).length < 2 && (
              <CardBody>
                <Button brand="primary" onClick={this.onAddReference}>
                  <FontAwesomeIcon icon={faPlusCircle} fixedWidth /> Add reference
                </Button>
              </CardBody>
            )}
          </>
        </AsyncLoader>

        {showForm && (
          <ReferenceForm
            action={formId ? 'Update' : 'Create'}
            closeForm={this.closeForm}
            data={form}
            errors={errors}
            isLoading={actionRequest.isLoading}
            isOpen={showForm}
            onChange={this.onChange}
            onSubmit={formId ? this.onUpdate : this.onCreate}
          />
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const { cache, errors, form, requests } = state.references;
  const servicerCache = state.servicers.cache;
  let servicers = { '': '' };
  Object.keys(servicerCache).forEach((k) => {
    servicers[servicerCache[k].id] = servicerCache[k].servicerName;
  });

  return {
    references: cache,
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    servicers: servicers,
    errors: errors,
    form: form,
    request: requests.loadCollection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearForm: () => dispatch(referencesClearForm()),
  createReference: async () => {
    await dispatch(referencesCreateResource());
    await dispatch(todosLoadCollection());
  },
  handleForm: (reference, attributes) =>
    dispatch(referencesHandleFormChange(reference, attributes)),
  hydrateForm: async (id) => await dispatch(referencesCopyResourceToForm(id)),
  loadReferences: () => dispatch(referencesLoadCollection()),
  updateReference: async (id) => {
    await dispatch(referencesUpdateResource(id));
    await dispatch(todosLoadCollection());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyReferences);
