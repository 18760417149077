import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import {
  Table,
  Modal,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardText,
  Header3,
  Header4,
  Button,
} from 'fiducius-ui';

const PaddedDiv = styled.div`
  padding-bottom: 1em;
`;

const TopDiv = styled.div`
  margin-bottom: 1em;
`;

const BorderedDiv = styled.div`
  margin-top: 1em;
  border-bottom: calc(2 * ${(p) => p.theme.borderWidth}) solid ${(p) => p.theme.textTertiary};
`;

const StyledHeader4 = styled(Header4)`
  margin-bottom: 0em;
`;

class PaymentHelp extends React.Component {
  static propTypes = {
    data: t.object,
    closeForm: t.func.isRequired,
    isOpen: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.modalRef = React.createRef();
    if (this.modalRef.current !== null) {
      this.modalRef.current.scrollTo(0, 0);
    }
  }

  render() {
    const { closeForm, isOpen } = this.props;

    return (
      <Modal ref={this.modalRef} isOpen={isOpen} fullWidth={true}>
        <Card>
          <CardHeader>
            <Header3>How are Payments Scheduled?</Header3>
          </CardHeader>
          <CardBody>
            <CardText>
              <TopDiv className="row">
                <div className="col-12">
                  Scheduled contribution payments are calculated a month in advance of their due
                  date.
                  <BorderedDiv></BorderedDiv>
                </div>
              </TopDiv>
              <PaddedDiv className="row">
                <div className="col-12">
                  <StyledHeader4>Contribution Processing</StyledHeader4>
                  <Table
                    header={['Payment Remittance Date', 'Information']}
                    body={[
                      [
                        '1st of the Month',
                        'All employer contribution payments will be remitted on the 1st of the month, regardless of your loan payment due date.',
                      ],
                    ]}
                  />
                </div>
              </PaddedDiv>
              <PaddedDiv className="row">
                <div className="col-12">
                  Note: Contribution payments are submitted within 3-5 business days of the 1st of
                  the month. However, please note that the processing time may vary with different
                  loan servicers. Also, remittance dates falling on a holiday or weekend will be
                  processed the following business day.
                </div>
              </PaddedDiv>
            </CardText>
          </CardBody>
          <CardFooter>
            <Button brand="lowContrast" onClick={closeForm}>
              Close
            </Button>
          </CardFooter>
        </Card>
      </Modal>
    );
  }
}

export default PaymentHelp;
