import { createSelector } from 'reselect';
import { getUserId } from '../../../auth';
import { safeAccess } from '../../../utils';

export const getSimplifiData = (state) =>
  safeAccess(state, `simplifi.cache[${getUserId(state)}]`, {});

export const getMonthlyPayment = createSelector(
  [getSimplifiData],
  (data = {}) => data.standardPayment || 0
);

export const getMonthlySavings = createSelector(
  [getSimplifiData],
  (data = {}) => {
    const stdPayment = data.standardPayment || 0;
    const idrPayment = data.projectedIdr || 0;
    return stdPayment - idrPayment;
  }
);

export const getNewPayment = createSelector(
  [getSimplifiData],
  (data = {}) => data.projectedIdr || 0
);

export const getOldPayment = createSelector(
  [getSimplifiData],
  (data = {}) => data.standardPayment || 0
);

export const getStandardPayment = createSelector(
  [getSimplifiData],
  (data = {}) => data.standardPayment || 0
);
