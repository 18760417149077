import { FormValidator } from '../../utils';

export class RegisterValidator extends FormValidator {
  // Register tab validators
  email(validator) {
    validator.isRequired();
    validator.hasMaxLength(250);
    validator.isValidSingleEmail();
  }

  password(validator) {
    validator.isRequired();
    validator.hasMinLength(8);
    validator.isValidPassword();
  }

  oldPassword(validator) {
    validator.isRequired();
  }

  newPassword(validator, state) {
    validator.isRequired();
    if (validator.value !== state.password) {
      validator.handleFailure('does not match.');
    }
  }

  confirmPassword(validator, state) {
    validator.isRequired();
    if (validator.value !== state.password) {
      validator.handleFailure('does not match.');
    }
  }

  // Info tab validators
  daylightSavings(validator) {
    validator.isRequired();
  }

  firstName(validator) {
    validator.isRequired();
    validator.hasMaxLength(20);
  }

  lastName(validator) {
    validator.isRequired();
    validator.hasMaxLength(30);
  }

  middleInitial(validator) {
    validator.hasMaxLength(1);
  }

  phoneNumber(validator) {
    validator.isRequired();
    validator.isValidPhoneNumber();
  }

  partnerMemeberId(validator, state) {
    // validator.isRequired();
  }

  registrationCode(validator) {
    validator.isRequired();
  }

  suffix(validator) {
    validator.hasMaxLength(15);
  }

  textNotifications(validator) {
    validator.isRequired();
  }

  timeZone(validator) {
    validator.isRequired();
  }

  /*clientNonProfit(validator, state) {
    if (false) {
      validator.isRequired();
    }
  }*/
}

export default RegisterValidator;
