import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  customResponseHandler: null,
  endpoint: '/ref-customer-servicer',
  resourceType: 'ref-customer-servicer',
};

const operations = resourceOperationsGenerator(
  'refServicers',
  actions,
  null,
  null,
  endpointHandler,
  null,
  null,
  null,
  null
);

const { refServicersLoadCollection } = operations;
export { refServicersLoadCollection };
export default operations;
