import actions from './actions';
import { resourceOperationsGenerator } from '../../utils';

const endpointHandler = {
  endpoint: '/renewal-information',
  customResponseHandler: null,
  resourceType: 'renewal-information',
};

const operations = resourceOperationsGenerator(
  'renewalResults',
  actions,
  null,
  null,
  null,
  endpointHandler,
  null,
  endpointHandler,
  null,
  null
);

const { renewalResultsLoadResource, renewalResultsUpdateResource } = operations;

export { renewalResultsLoadResource, renewalResultsUpdateResource };

export default operations;
