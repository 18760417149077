import React, { useState } from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import {
  Button,
  Form,
  InputAddon,
  InputCheckbox,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
  InputError,
} from 'fiducius-ui';

import { safeAccess, useFocusOnMount } from '../../utils';
import NewPassword from '../components/new-password';
import RememberMe from '../styles/remember-me';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 1rem;
`;

const FORM_ID = 'signUpRegisterForm';

const canContinue = (errors, data, registrationCode) => {
  const hasData = !!(data.email && data.password && (data.registrationCode || registrationCode));
  const existingErrors = Object.keys(errors).filter(
    (k) =>
      ['email', 'password', 'confirmPassword', 'registrationCode'].includes(k) &&
      errors[k] !== false
  );
  //console.log(data, hasData, existingErrors);
  return hasData && existingErrors.length === 0;
};

const SignUpRegisterForm = ({
  data = {},
  errors = {},
  handleChange,
  onSubmit,
  registrationCode,
  rememberMe = false,
  toggleFormError,
}) => {
  const firstInput = useFocusOnMount();
  const [touchedCode, setTouchedCode] = useState(false);
  return (
    <Form
      handleChange={handleChange}
      id={FORM_ID}
      onSubmit={(e) => {
        !touchedCode && handleChange({ registrationCode }, FORM_ID, 'registrationCode');
        onSubmit(e);
      }}
    >
      <InputWrapper error={!!errors.email} required>
        <InputLabel required>Email</InputLabel>
        <InputError>{safeAccess(errors, 'email.detail')}</InputError>
        <InputGroup>
          <InputAddon>
            <FontAwesomeIcon icon={faUser} fixedWidth />
          </InputAddon>
          <InputText
            inputRef={firstInput}
            name="email"
            autoComplete="email"
            placeholder="Email"
            defaultValue={data.email}
          />
        </InputGroup>
      </InputWrapper>
      <NewPassword
        error={safeAccess(errors, 'password.detail')}
        required
        label={'Password'}
        fieldName={'password'}
        data={data.password}
      />
      <NewPassword
        error={safeAccess(errors, 'confirmPassword.detail')}
        required
        label={'Confirm Password'}
        fieldName={'confirmPassword'}
        data={data.confirmPassword}
      />
      <RememberMe>
        <InputCheckbox name="rememberMe" label="Remember me" defaultChecked={rememberMe} />
      </RememberMe>
      <InputWrapper error={!!errors.registrationCode} required>
        <InputLabel htmlFor="registrationCode" required>
          Registration Code
        </InputLabel>
        <InputError>{safeAccess(errors, 'registrationCode.detail')}</InputError>
        <InputGroup affordance="medium">
          <InputText
            name="registrationCode"
            defaultValue={registrationCode}
            onChange={() => setTouchedCode(true)}
          />
        </InputGroup>
      </InputWrapper>
      <ButtonWrapper>
        <Button
          brand="primary"
          type="submit"
          onClick={(e) => {
            if (canContinue(errors, data, registrationCode)) {
              !touchedCode &&
                handleChange(
                  { registrationCode: data.registrationCode || registrationCode },
                  FORM_ID,
                  'registrationCode'
                );
              onSubmit(e);
            } else {
              toggleFormError(e, true);
            }
          }}
        >
          Sign up
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

SignUpRegisterForm.propTypes = {
  data: t.object,
  errors: t.object,
  handleChange: t.func.isRequired,
  onSubmit: t.func.isRequired,
  registrationCode: t.string,
  rememberMe: t.bool,
  toggleFormError: t.func.isRequired,
};

export default SignUpRegisterForm;
