import React from 'react';
import t from 'prop-types';
import {
  Form,
  InputError,
  InputGroup,
  InputLabel,
  InputMask,
  InputSelect,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

import { InputWrapperSpaced } from '../../auth';
import { convertIsoToSlash, safeAccess } from '../../utils';
import { withPermissions } from '../../routing';

class PersonalForm extends React.Component {
  static propTypes = {
    data: t.object,
    errors: t.object,
    handleChange: t.func,
    permissions: t.object,
  };

  constructor(props) {
    super(props);
    this.firstInput = React.createRef();
  }

  componentDidMount() {
    this.firstInput.current.focus();
  }

  render() {
    const { data = {}, errors = {}, handleChange, permissions } = this.props;
    return (
      <Form id="personal" handleChange={handleChange} defaults={data}>
        <div className="row no-gutters">
          <div className="col-4">
            <InputWrapperSpaced error={!!errors.firstName} first>
              <InputLabel htmlFor="firstName" required>
                First Name
              </InputLabel>
              <InputError>{safeAccess(errors, 'firstName.detail')}</InputError>
              <InputGroup>
                <InputText
                  inputRef={this.firstInput}
                  name="firstName"
                  defaultValue={data.firstName}
                />
              </InputGroup>
            </InputWrapperSpaced>
          </div>
          <div className="col-2">
            <InputWrapperSpaced error={!!errors.middleInitial}>
              <InputLabel htmlFor="middleInitial">M.I.</InputLabel>
              <InputError>{safeAccess(errors, 'middleInitial.detail')}</InputError>
              <InputGroup>
                <InputText name="middleInitial" defaultValue={data.middleInitial} maxLength="1" />
              </InputGroup>
            </InputWrapperSpaced>
          </div>
          <div className="col-4">
            <InputWrapperSpaced error={!!errors.lastName}>
              <InputLabel htmlFor="lastName" required>
                Last Name
              </InputLabel>
              <InputError>{safeAccess(errors, 'lastName.detail')}</InputError>
              <InputGroup>
                <InputText name="lastName" defaultValue={data.lastName} />
              </InputGroup>
            </InputWrapperSpaced>
          </div>
          <div className="col-2">
            <InputWrapperSpaced error={!!errors.suffix} last>
              <InputLabel htmlFor="suffix">Suffix</InputLabel>
              <InputError>{safeAccess(errors, 'suffix.detail')}</InputError>
              <InputGroup>
                <InputText name="suffix" defaultValue={data.suffix} />
              </InputGroup>
            </InputWrapperSpaced>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-12 col-md-3">
            <InputWrapper error={!!errors.pronounPreference}>
              <InputLabel htmlFor="pronounPreference">Pronoun Preference</InputLabel>
              <InputError>{safeAccess(errors, 'pronounPreference.detail')}</InputError>
              <InputGroup>
                <InputSelect
                  defaultValue={data.pronounPreference}
                  name="pronounPreference"
                  options={['', 'He', 'She', 'They']}
                />
              </InputGroup>
            </InputWrapper>
          </div> */}
          <div className="col-12 col-md-9">
            <InputWrapper error={!!errors.maidenName}>
              <InputLabel htmlFor="maidenName">Last Name Pre-marriage</InputLabel>
              <InputError>{safeAccess(errors, 'maidenName.detail')}</InputError>
              <InputGroup affordance="medium">
                <InputText defaultValue={data.maidenName} name="maidenName" />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <InputWrapper error={!!errors.dob}>
              <InputLabel htmlFor="dob" required>
                Birth Date
              </InputLabel>
              <InputError>{safeAccess(errors, 'dob.detail')}</InputError>
              <InputGroup>
                <InputMask
                  defaultValue={convertIsoToSlash(data.dob)}
                  mask="##/##/####"
                  name="dob"
                  placeholder="MM/DD/YYYY"
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <InputWrapper
              error={
                (permissions.inForgiveness || permissions.inSimplifi) &&
                !!errors.driverLicenseNumber
              }
            >
              <InputLabel
                htmlFor="driverLicenseNumber"
                required={permissions.inForgiveness || permissions.inSimplifi}
              >
                Driver's License Number
              </InputLabel>
              {(permissions.inForgiveness || permissions.inSimplifi) && (
                <InputError>{safeAccess(errors, 'driverLicenseNumber.detail')}</InputError>
              )}
              <InputGroup>
                <InputText name="driverLicenseNumber" defaultValue={data.driverLicenseNumber} />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-4">
            <InputWrapper
              error={
                (permissions.inForgiveness || permissions.inSimplifi) && !!errors.driverLicenseState
              }
            >
              <InputLabel
                htmlFor="driverLicenseState"
                required={permissions.inForgiveness || permissions.inSimplifi}
              >
                License State
              </InputLabel>
              {(permissions.inForgiveness || permissions.inSimplifi) && (
                <InputError>{safeAccess(errors, 'driverLicenseState.detail')}</InputError>
              )}
              <InputGroup>
                <InputSelect
                  defaultValue={data.driverLicenseState}
                  name="driverLicenseState"
                  options={[
                    '',
                    'AL',
                    'AK',
                    'AR',
                    'AZ',
                    'CA',
                    'CO',
                    'CT',
                    'DC',
                    'DE',
                    'FL',
                    'GA',
                    'HI',
                    'IA',
                    'ID',
                    'IL',
                    'IN',
                    'KS',
                    'KY',
                    'LA',
                    'MA',
                    'MD',
                    'ME',
                    'MI',
                    'MN',
                    'MO',
                    'MS',
                    'MT',
                    'NC',
                    'NE',
                    'NH',
                    'NJ',
                    'NM',
                    'NV',
                    'NY',
                    'ND',
                    'OH',
                    'OK',
                    'OR',
                    'PA',
                    'PR',
                    'RI',
                    'SC',
                    'SD',
                    'TN',
                    'TX',
                    'UT',
                    'VT',
                    'VA',
                    'WA',
                    'WI',
                    'WV',
                    'WY',
                  ]}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>
      </Form>
    );
  }
}

export default withPermissions(PersonalForm);
