import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardText,
  DashboardSection,
  Button,
  Header2,
  Header4,
  InputWrapper,
  ProgressBar,
} from 'fiducius-ui';
import { Redirect } from 'react-router-dom';

import { safeAccess, mergeRequestStatuses } from '../../../utils';

import { withPermissions } from '../../../routing';

const StyleBar = styled(ProgressBar)`
  margin-bottom: 2rem;
`;

class EnterpriseEmpcertProgress extends React.Component {
  static propTypes = {
    permissions: t.object.isRequired,
    request: t.object.isRequired,
    todos: t.array,
    removeContainer: t.bool,
  };

  constructor(props) {
    super(props);
    this.state = { redirectURI: '', fullServiceSteps: 9, currentURI: '' };
  }

  componentDidMount() {
    this.setState({ ...this.state, currentURI: window.location.pathname });
  }

  componentDidUpdate() {}

  goToCurrentStep = () => {
    const { permissions, todos } = this.props;
    let retVal = '/dashboard';
    if (
      permissions.enterpriseEmpcertSteps !== undefined &&
      permissions.enterpriseEmpcertSteps !== null
    ) {
      let enterpriseEmpCertStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);
      if (
        enterpriseEmpCertStep !== undefined &&
        enterpriseEmpCertStep !== null &&
        todos !== undefined &&
        todos !== null
      ) {
        if (enterpriseEmpCertStep.id == 'CompleteEnterpriseEmpcert') {
          retVal = '/benefits/empcert/complete';
        }

        const currentToDo = todos.find(
          (a) =>
            a.id === 800 ||
            a.id === 801 ||
            a.id === 805 ||
            a.id === 810 ||
            a.id === 815 ||
            a.id === 820
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 800:
              retVal = '/benefits/empcert/generate';
              break;
            case 801:
            case 810:
              retVal = '/benefits/empcert/submit';
              break;
            case 805:
              retVal = '/benefits/empcert/correction';
              break;
            case 815:
              retVal = '/benefits/empcert/check';
              break;
            case 820:
              retVal = '/benefits/empcert/complete';
              break;
          }
        }
      }
    }

    this.setState({ ...this.state, redirectURI: retVal });
  };

  getCurrentStepText = () => {
    const { permissions, todos } = this.props;
    const enterpriseEmpcertSteps = permissions.enterpriseEmpcertSteps.filter((a) => a.stepCurrent);
    let enterpriseEmpcertStep = permissions.enterpriseEmpcertSteps.find((a) => a.stepCurrent);
    if (
      enterpriseEmpcertSteps !== undefined &&
      enterpriseEmpcertSteps !== null &&
      enterpriseEmpcertSteps.length > 1 &&
      enterpriseEmpcertSteps.find((a) => window.location.pathname.includes(a.stepPath))
    ) {
      enterpriseEmpcertStep = enterpriseEmpcertSteps.find((a) =>
        window.location.pathname.includes(a.stepPath)
      );
    }

    let retVal = '';
    if (
      enterpriseEmpcertStep !== undefined &&
      enterpriseEmpcertStep !== null &&
      todos !== undefined &&
      todos !== null
    ) {
      if (
        enterpriseEmpcertStep.id === 'GenerateEnterpriseEmpcert' ||
        enterpriseEmpcertStep.id === 'SubmitEnterpriseEmpcert' ||
        enterpriseEmpcertStep.id === 'CheckEnterpriseEmpcert' ||
        enterpriseEmpcertStep.id === 'CompleteEnterpriseEmpcert'
      ) {
        retVal = enterpriseEmpcertStep.stepText;
        const currentToDo = todos.find(
          (a) =>
            (enterpriseEmpcertStep.id === 'GenerateEnterpriseEmpcert' && a.id === 800) ||
            (enterpriseEmpcertStep.id === 'SubmitEnterpriseEmpcert' && a.id === 810) ||
            (enterpriseEmpcertStep.id === 'CheckEnterpriseEmpcert' && a.id === 815) ||
            (enterpriseEmpcertStep.id === 'CompleteEnterpriseEmpcert' && a.id === 820)
        );

        if (currentToDo !== undefined && currentToDo !== null) {
          switch (currentToDo.id) {
            case 800:
              retVal += '';
              break;
            case 810:
              retVal += '';
              break;
            case 815:
              retVal += '';
              break;
            case 820:
              retVal += '';
              break;
            default:
              break;
          }
        }
      } else {
        retVal = enterpriseEmpcertStep.stepText;
      }
    }

    return retVal;
  };

  getTotalSteps = () => {
    const { permissions } = this.props;
    const enterpriseEmpcertSteps = permissions.enterpriseEmpcertSteps.filter(
      (a) => a.id !== 'Overview'
    );
    return enterpriseEmpcertSteps.length;
  };

  getCompletedSteps = (normalized) => {
    const { permissions } = this.props;
    const enterpriseEmpcertSteps = permissions.enterpriseEmpcertSteps.filter(
      (a) => a.id !== 'Overview'
    );
    const completedSteps = enterpriseEmpcertSteps.filter((a) => a.stepComplete);
    if (normalized) {
      return 100.0 * (completedSteps.length / enterpriseEmpcertSteps.length);
    } else {
      return Math.min(completedSteps.length + 1, enterpriseEmpcertSteps.length);
    }
  };

  getCurrentStep = () => {
    const { permissions } = this.props;
    const enterpriseEmpcertSteps = permissions.enterpriseEmpcertSteps.filter(
      (a) => a.id !== 'Overview'
    );
    const currentSteps = permissions.enterpriseEmpcertSteps.filter(
      (a) => a.id !== 'Overview' && a.stepCurrent
    );
    const allStepsCompleted = permissions.enterpriseEmpcertSteps.filter(
      (a) => a.id === 'CompleteEnterpriseEmpcert' && a.stepComplete
    );

    if (allStepsCompleted.length > 0) {
      return 0;
    } else {
      return 100.0 * (currentSteps.length / enterpriseEmpcertSteps.length);
    }
  };

  getCurrentStepPath = () => {
    const { permissions } = this.props;

    const currentSteps = permissions.enterpriseEmpcertSteps.filter(
      (a) => a.id !== 'Overview' && a.stepCurrent
    );

    let stepPaths = [];
    currentSteps.forEach((step) => {
      stepPaths.push(step.stepPath);
    });
    //return an array since we can have multiple current steps.
    return stepPaths;
  };

  onCurrentStep = () => {
    let steps = this.getCurrentStepPath();
    var retVal = false;
    steps.forEach((step) => {
      if (retVal === false) {
        retVal = this.state.currentURI.includes(step);
      }
    });
    return retVal;
  };

  getLockedSteps = () => {
    return 100.0 - this.getCompletedSteps(true) - this.getCurrentStep();
  };

  render() {
    const { permissions, removeContainer } = this.props;
    if (this.state.redirectURI && this.state.redirectURI.length > 0) {
      return <Redirect to={this.state.redirectURI} />;
    } else {
      if (
        safeAccess(permissions, 'enterpriseEmpcertSteps') &&
        safeAccess(permissions, 'enterpriseEmpcertSteps').length > 1
      ) {
        return (
          <>
            {removeContainer && (
              <Card>
                <CardBody>
                  <CardText>
                    <Header2>
                      Your Employment Certification Progress : {this.getCompletedSteps(false)} /{' '}
                      {this.getTotalSteps()}
                    </Header2>

                    <StyleBar
                      categories={[
                        {
                          brand: 'success',
                          name: 'success',
                          title: 'Completed',
                          value: this.getCompletedSteps(true),
                        },

                        {
                          brand: 'info',
                          name: 'info',
                          title: 'Current',
                          value: this.getCurrentStep(),
                        },
                        {
                          brand: 'warning',
                          name: 'warning',
                          title: 'Locked',
                          value: this.getLockedSteps(),
                        },
                      ]}
                    />
                    <div className="row col-12">
                      <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                      </div>
                      <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <InputWrapper>
                          {!this.onCurrentStep() && (
                            <Button brand="primary" onClick={this.goToCurrentStep}>
                              {'Go to current step'}
                            </Button>
                          )}
                        </InputWrapper>
                      </div>
                    </div>
                  </CardText>
                </CardBody>
              </Card>
            )}
            {!removeContainer && (
              <DashboardSection>
                <Card>
                  <CardBody>
                    <CardText>
                      <Header2>
                        Your Employment Certification Progress : {this.getCompletedSteps(false)} /{' '}
                        {this.getTotalSteps()}
                      </Header2>
                      <StyleBar
                        categories={[
                          {
                            brand: 'success',
                            name: 'success',
                            title: 'Completed',
                            value: this.getCompletedSteps(true),
                          },

                          {
                            brand: 'info',
                            name: 'info',
                            title: 'Current',
                            value: this.getCurrentStep(),
                          },
                          {
                            brand: 'warning',
                            name: 'warning',
                            title: 'Locked',
                            value: this.getLockedSteps(),
                          },
                        ]}
                      />
                      <div className="row col-12">
                        <div className="col-8">
                          <Header4>Current Step: {this.getCurrentStepText()}</Header4>
                        </div>
                        <div className="col-4">
                          <InputWrapper>
                            {!this.onCurrentStep() && (
                              <Button brand="info" onClick={this.goToCurrentStep}>
                                {'Go to current step'}
                              </Button>
                            )}
                          </InputWrapper>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </DashboardSection>
            )}
          </>
        );
      } else {
        return <></>;
      }
    }
  }
}

const mapStateToProps = (state) => ({
  request: mergeRequestStatuses([
    state.todos.requests.updateResource,
    state.todos.requests.loadCollection,
  ]),
  todos: Object.values(state.todos.cache),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withPermissions(EnterpriseEmpcertProgress));
