import React from 'react';
import t from 'prop-types';
import {
  ChartBar,
  CardTitle,
  CardBody,
  Form,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputAddon,
  InputNumeric,
  InputSliderBar,
} from 'fiducius-ui';

import { formatAsMoney } from '../../../utils/format';

class RetirementTool extends React.Component {
  static propTypes = {
    action: t.string,
    agi: t.number,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.retirementVal = React.createRef();

    this.state = {
      income: this.props.agi,
      famSize: '',
      retirementPercent: 0,
    };

    this.handleIncomeChange = this.handleIncomeChange.bind(this);
    this.handleFamSizeChange = this.handleFamSizeChange.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleRetirementFormChange = this.handleRetirementFormChange.bind(this);
  }

  handleIncomeChange(event) {
    this.setState({ income: event.target.value });
  }
  handleFamSizeChange(event) {
    this.setState({ famSize: event.target.value });
  }
  handleRetirementFormChange(event) {
    this.handleRetirementChange(event.target.value);
  }

  handleRetirementChange = (value) => {
    this.setState({ ...this.state, retirementPercent: value });
  };

  handleSliderChange = (value) => {
    this.handleRetirementChange(value);
    this.retirementVal = value;
  };

  render() {
    const { data = {}, onChange } = this.props;

    const householdSize = this.state.famSize || data.householdSize || 1;
    const agiIncome = this.state.income || data.agi;
    const retirePct = this.state.retirementPercent || 0;

    const agi = agiIncome - agiIncome * (retirePct / 100);

    let repaymentPlanValue;
    //assigns a value modifier depending on repayment plan time you qualify for.
    if (data.repaymentPlan === 'PAYE' || data.repaymentPlan === 'REPAYE') {
      repaymentPlanValue = 0.1;
    } else if (data.repaymentPlan === 'ICR') {
      repaymentPlanValue = 0.2;
    } else if (data.repaymentPlan === 'SAVE') {
      repaymentPlanValue = data.saveMultiplier;
    } else {
      repaymentPlanValue = 0.15;
    }

    const povGuidCalc = (householdSize, povGuidelines) => {
      //modifies pov guidelines if a client lives in alaska or is ICR elegible.
      if (data.state === 'AK' && data.repaymentPlan === 'ICR') {
        return (povGuidelines = 15600 + 5530 * (householdSize - 1));
      } else if (data.state === 'AK') {
        return (povGuidelines = 23400 + 8295 * (householdSize - 1));
      } else if (data.repaymentPlan === 'ICR') {
        return (povGuidelines = 12490 + 4420 * (householdSize - 1));
      } else {
        return (povGuidelines = 18210 + 6480 * (householdSize - 1));
      }
    };

    let idr = ((agi - povGuidCalc(householdSize)) * repaymentPlanValue) / 12;

    if (data.repaymentPlan === 'SAVE') {
      idr = ((agi - data.savePovertyGuideline) * repaymentPlanValue) / 12;
    }

    if (idr < 0) {
      idr = 0;
    }

    return (
      <Form id="retirement" defaults={data} handleChange={onChange}>
        <CardTitle>Retirement Contribution Tool</CardTitle>
        <CardBody>
          <ui>
            <li>
              Please only input your pre-tax retirement contribution below to see how changes to
              your pre-tax retirement deductions will impact your Income-Driven Repayment (IDR).
            </li>
          </ui>
        </CardBody>
        <div className="row">
          <div className="col-6 col-lg-4">
            <InputWrapper>
              <InputLabel htmlFor="income">Annual Income</InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric
                  id="income"
                  name="income"
                  defaultValue={data.agi}
                  onChange={this.handleIncomeChange}
                />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-6 col-md-4">
            <InputWrapper>
              <InputLabel htmlFor="Household size">Household Size</InputLabel>
              <InputGroup>
                <InputNumeric
                  id="famSize"
                  name="HouseHoldSize"
                  min="0"
                  defaultValue={data.householdSize}
                  onChange={this.handleFamSizeChange}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-md-4" style={{ width: '10%' }}>
            <InputWrapper>
              <InputLabel htmlFor="Percent Contribution">
                Pre-Tax Retirement Contribution %
              </InputLabel>
              <InputGroup>
                <InputNumeric
                  name="retirementPercent"
                  id="retirementPercent"
                  min="0"
                  max="100"
                  defaultValue={0}
                  value={this.state.retirementPercent} //https://reactjs.org/docs/uncontrolled-components.html#default-values
                  onChange={this.handleRetirementFormChange}
                />
                <InputAddon>%</InputAddon>
              </InputGroup>
            </InputWrapper>

            <InputWrapper>
              <InputGroup>
                <InputSliderBar
                  id="sliderBar"
                  name="sliderBar"
                  minVal={0}
                  maxVal={100}
                  startingValue={0}
                  boxValue={true}
                  ticksOn={true}
                  tickNum={5}
                  theme={'sharp'}
                  onChange={(dummyVar) => {
                    this.handleSliderChange(dummyVar);
                  }}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>

        <div>
          <hr />
        </div>

        <div className="row">
          <div className="col-6 col-md-4">
            <InputWrapper>
              <InputLabel htmlFor="Percent Contribution">
                <strong>Adjusted Gross Income</strong>
              </InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric
                  name="something"
                  id="agiTxt"
                  defaultValue={this.state.agi}
                  value={agi.toFixed(2)}
                />
              </InputGroup>
            </InputWrapper>
          </div>
        </div>

        <div className="row">
          <div className="col-6 col-md-4">
            <InputWrapper>
              <InputLabel htmlFor="Percent Contribution">
                <strong>Estimated Monthly {data.repaymentPlan} Payment</strong>
              </InputLabel>
              <InputGroup>
                <InputAddon>$</InputAddon>
                <InputNumeric name="something" id="agiTxt" value={idr.toFixed(2)} />
              </InputGroup>
            </InputWrapper>
          </div>
          <div className="col-10 col-md-6">
            <ChartBar
              bars={[
                {
                  label: '',
                  dataType: 'currency',
                  data: [agi, agiIncome - agi],
                },
              ]}
              config={{
                labels: ['Taxable Income', 'Retirement Dollars'],
                formatter: formatAsMoney,
              }}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default RetirementTool;
