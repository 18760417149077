import React, { useState } from 'react';
import styled from 'styled-components';
import t from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/pro-light-svg-icons/faLockOpen';
import {
  Button,
  InputAddon,
  InputError,
  InputGroup,
  InputLabel,
  InputText,
  InputWrapper,
} from 'fiducius-ui';

const StyledInputError = styled(InputError)`
  display: flex;
  flex-direction: column;
`;

const NewPassword = ({ data, error, label, required, fieldName }) => {
  const [show, setShow] = useState(false);
  return (
    <InputWrapper error={!!error}>
      <InputLabel required={required}>{label || 'Password'}</InputLabel>
      <StyledInputError dangerouslySetInnerHTML={{ __html: error }} />
      <InputGroup>
        <InputAddon>
          {show ? (
            <FontAwesomeIcon icon={faLockOpen} fixedWidth />
          ) : (
            <FontAwesomeIcon icon={faLock} fixedWidth />
          )}
        </InputAddon>
        <InputText
          name={fieldName || 'password'}
          type={show ? 'text' : 'password'}
          autoComplete="new-password"
          placeholder={label || 'Password'}
          defaultValue={data}
        />
        <InputAddon button={true}>
          <Button
            brand="primary"
            onPointerDown={() => setShow(true)}
            onPointerUp={() => setShow(false)}
          >
            show
          </Button>
        </InputAddon>
      </InputGroup>
    </InputWrapper>
  );
};

NewPassword.propTypes = {
  data: t.object,
  error: t.object,
  label: t.string,
  required: t.bool,
  fieldName: t.string,
};

export default NewPassword;
