export const convertMilestonePaymentCodeToDescription = (paymentType) => {
  let retVal = '';

  switch (paymentType.toUpperCase()) {
    case 'SETAMT':
      retVal = 'Set Amount';
      break;
    case 'MODAMT':
      retVal = 'Modify Amount';
      break;
    case 'LUMP':
      retVal = 'Lump Sum';
      break;
    default:
      retVal = 'Other';
  }
  return retVal;
};

export const convertMilestoneAccruementTypeCodeToDescription = (milestoneType) => {
  let retVal = '';

  switch (milestoneType.toUpperCase()) {
    case 'MONTHS':
      retVal = 'Months in Program';
      break;
    case 'CALCPMTS':
      retVal = 'Number of Scheduled Payments';
      break;
    case 'NUMPMTS':
      retVal = 'Number of Payments';
      break;
    case 'START':
      retVal = 'Starting Amount';
      break;
    default:
      retVal = 'Other';
  }
  return retVal;
};
