import React from 'react';
import t from 'prop-types';
import styled from 'styled-components';

import { connect } from 'react-redux';

import { Card, CardBody, CardTitle, CardText } from 'fiducius-ui';
import { authLogout, isAuthenticated } from '../../auth';

import Fiducius from '../assets/Fiducius.jpg';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => p.align};
  min-height: 100%;
  position: absolute;
  width: 100%;
  background-image: radial-gradient(
    at left top,
    ${(p) =>
      p.theme.themeName === 'light'
        ? p.theme.colorLevel(p.theme.primary, -3)
        : p.theme.themeName === 'newlight'
        ? p.theme.colorLevel(p.theme.primaryA, -3)
        : p.theme.colorLevel(p.theme.level1, -3)},
    ${(p) =>
        p.theme.themeName === 'light'
          ? p.theme.colorLevel(p.theme.primary, 4)
          : p.theme.themeName === 'newlight'
          ? p.theme.colorLevel(p.theme.primaryA, 4)
          : p.theme.colorLevel(p.theme.level1, 4)}
      70%,
    ${(p) =>
      p.theme.themeName === 'light'
        ? p.theme.colorLevel(p.theme.primary, 5)
        : p.theme.themeName === 'newlight'
        ? p.theme.colorLevel(p.theme.primaryA, 5)
        : p.theme.colorLevel(p.theme.level1, 5)}
  );

  a {
    color: ${(p) => p.theme.secondary};
  }
`;

class Maintenance extends React.Component {
  static propTypes = {
    isAuthenticated: t.bool.isRequired,
    isLoading: t.bool.isRequired,
    signOut: t.func.isRequired,
    auth: t.object,
  };

  componentDidMount() {
    // On mount we log out the user
    const { isAuthenticated, signOut, auth } = this.props;
    if (isAuthenticated) {
      signOut(auth.username);
    }
  }

  render() {
    return (
      <Wrapper>
        <Card brand="primary">
          <CardBody>
            <img src={Fiducius} alt="Fiducius" width="400" />
            <br />
            <br />
            <CardTitle>We are temporarily down for scheduled maintenance</CardTitle>
            <CardText>
              We should be back online shortly.
              <br />
              Thank you for your patience!
            </CardText>
          </CardBody>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  isLoading: state.auth.requests.logout.isLoading,
  auth: state.auth.cache,
});

const mapDispatchToProps = (dispatch) => ({
  signOut: (username) => dispatch(authLogout(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
