import React from 'react';
import t from 'prop-types';
import { AnimationGroup } from 'fiducius-ui';
import { Route, Switch } from 'react-router-dom';

import {
  AuthPage,
  Login,
  Logout,
  PasswordReset,
  PasswordUpdate,
  PasswordOutOfDate,
  Register,
  CRMLogin,
  PreRegistered,
} from '../../auth';
import { Maintenance } from '../../maintenance';

import ErrorPage from './error-page';
import { QuickQualifier } from '../../quick-qual';
import { AccountUnlock } from '../../account-unlock';

const RouterAuth = (props) => {
  const domain = props.domain || 'islsoffice';
  return (
    <Route
      render={({ location }) => (
        <AnimationGroup key={location.key} animation="fade">
          <Switch location={location}>
            {/* Errors */}
            <Route path="/error/:code" component={ErrorPage} />

            {/* Public routes */}
            <Route
              path="/login"
              render={() => (
                <AuthPage onboarding domain={domain} {...props}>
                  <Login domain={domain} changeTheme={props.changeTheme} />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/crm"
              render={() => (
                <AuthPage domain={domain} {...props}>
                  <CRMLogin domain={domain} />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/logout"
              render={() => (
                <AuthPage {...props}>
                  <Logout />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/password-reset"
              render={() => (
                <AuthPage {...props}>
                  <PasswordReset />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/password-update"
              render={() => (
                <AuthPage {...props}>
                  <PasswordUpdate />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/account-unlock"
              render={() => (
                <AuthPage {...props}>
                  <AccountUnlock />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/pre-registered"
              render={() => (
                <AuthPage onboarding extraOnboard domain={domain} {...props}>
                  <PreRegistered />
                </AuthPage>
              )}
            />

            <Route
              exact
              path="/password-out-of-date"
              render={() => (
                <AuthPage {...props}>
                  <PasswordOutOfDate />
                </AuthPage>
              )}
            />
            <Route
              exact
              path="/register"
              render={() => (
                <AuthPage onboarding domain={domain} {...props}>
                  <Register domain={domain} />
                </AuthPage>
              )}
            />
            <Route exact path="/maintenance" render={() => <Maintenance />} />

            <Route
              exact
              path="/learn-more"
              render={() => <QuickQualifier {...props} showLearnMore />}
            />
            <Route
              exact
              path="/quick-qual"
              render={() => <QuickQualifier {...props} showLearnMore showQualifier />}
            />
          </Switch>
        </AnimationGroup>
      )}
    />
  );
};

RouterAuth.propTypes = {
  domain: t.string,
  changeTheme: t.func.isRequired,
};

export default RouterAuth;
