import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getUserId } from '../../../auth';
import {
  AsyncLoader,
  ButtonCarousel,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  DashboardSection,
  Link,
} from 'fiducius-ui';

import { withPermissions } from '../../../routing';
import { mergeRequestStatuses } from '../../../utils';
import { limitedWaiverOpportunityLoadResource } from '../redux/operations';

import QpPie from './qualified-payment-pie';
import ResultsTable from './results-table';
import LwoProgress from './lwo-progress';

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

class LwoSummary extends React.Component {
  static propTypes = {
    data: t.object,
    id: t.string.isRequired,
    load: t.func,
    loans: t.object,
    permissions: t.object.isRequired,
    request: t.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalToOpen: null,
    };
  }

  componentDidMount() {
    this.props.load(this.props.id);
  }

  componentDidUpdate() {}

  openModal = (modalToOpen) => {
    this.setState({ modalToOpen: modalToOpen });
  };

  getCardText = (currentLwoStep) => {
    if (['UnDef', 'WaitPslf', 'LoanInformation', 'Questionnaire'].includes(currentLwoStep.id)) {
      return (
        <p>
          We created Assure&#8480;, the only solution of its kind, to guarantee that borrowers like
          you receive all the additional qualifying payments and loan forgiveness you deserve from
          the LWO.
        </p>
      );
    } else if (
      ['Agreement', 'Fee', 'EmploymentCertification', 'QualifyingPaymentCheck'].includes(
        currentLwoStep.id
      )
    ) {
      return (
        <p>
          You're eligible to take advantage of the Limited Waiver Opportunity. Now, let's make sure
          you get all the additional qualifying payments you deserve.
        </p>
      );
    } else if (currentLwoStep.id === 'Complete') {
      return (
        <p>
          Congratulations, you have completed all of the steps to put Assure&#8480; to work for you
          student loan forgiveness.
        </p>
      );
    }
  };

  getLwoCarouselComponents = (loans) => {
    let retComponents = [];
    loans.forEach((a) => {
      retComponents.push(
        <QpPie qualifiedPayments={a.pslfQps / 1} paymentsRemain={120 - a.pslfQps / 1} cutout={10} />
      );
    });

    return retComponents;
  };

  getLwoCarouselButtons = (loans) => {
    let retComponents = [];
    loans.forEach((a) => {
      retComponents.push(a.loan.loanTypeName);
    });

    return retComponents;
  };

  getSummaryCharts = (data, currentLwoStep) => {
    return (
      <ResultsTable
        loans={currentLwoStep.id !== 'Complete' ? data.eligibleLoans : data.submittedLoans}
        showEstimated={currentLwoStep.id !== 'Complete'}
      />
    );
  };

  render() {
    const { data = {}, permissions, request } = this.props;
    let currentLwoStep = permissions.lwoSteps.find((a) => a.stepCurrent);
    if (currentLwoStep === undefined) {
      currentLwoStep = 'UnDef';
    }

    return (
      <Card>
        <CardBody>
          <div className="row">
            <div className="col-12">
              <CardTitle>Assure&#8480;</CardTitle>
            </div>
          </div>
          <AsyncLoader request={request}>
            <>
              <div className="row">
                <div className="col-12">
                  <CardText>
                    <div className="row">
                      <div className="col-12">{this.getCardText(currentLwoStep)}</div>
                    </div>

                    {!['WaitPslf', 'LoanInformation', 'Questionnaire'].includes(
                      currentLwoStep.id
                    ) &&
                      data.eligibleLoans !== undefined && (
                        <div className="row">
                          <div className="col-12">
                            {this.getSummaryCharts(data, currentLwoStep)}
                          </div>
                        </div>
                      )}
                  </CardText>
                </div>
              </div>
              {currentLwoStep !== undefined &&
                currentLwoStep.id !== 'Complete' &&
                !permissions.isLwoDeclined && <LwoProgress removeContainer={true} />}
            </>
          </AsyncLoader>
        </CardBody>
        <FlexFooter>
          <Link to="/benefits/limited-waiver-opportunity/overview">Overview</Link>

          <Link to="/benefits/limited-waiver-opportunity/about-assure">Learn More</Link>
        </FlexFooter>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  const eHRequests = state.employmentHistory.requests;
  const lwoRequests = state.limitedWaiverOpportunity.requests;
  return {
    id: getUserId(state),
    loans: state.limitedWaiverOpportunity.form.eligibleLoans,
    data: state.limitedWaiverOpportunity.cache[getUserId(state)],
    request: mergeRequestStatuses([eHRequests.loadCollection, lwoRequests.loadResource]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  load: async (id) => {
    await dispatch(limitedWaiverOpportunityLoadResource(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(LwoSummary));
