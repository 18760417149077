import actions from './actions';
import { AccountValidator } from './validators';
import { convertSlashToIso, resourceOperationsGenerator } from '../../utils';

const accountValidator = new AccountValidator();

const fixData = (data) => {
  return data.map((resource) => {
    const { dueDate, monthlyPayment } = resource;
    resource.dueDate = dueDate ? convertSlashToIso(dueDate) : null;
    resource.monthlyPayment = monthlyPayment === 0 ? null : monthlyPayment;
    return resource;
  });
};

const endpointHandler = {
  customResponseHandler: fixData,
  endpoint: '/loan',
  resourceType: 'loan',
};

const operations = resourceOperationsGenerator(
  'accounts',
  actions,
  null,
  accountValidator,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  endpointHandler,
  null
);

const {
  accountsClearForm,
  accountsCopyResourceToForm,
  accountsHandleFormChange,
  accountsLoadCollection,
  accountsLoadResource,
  accountsCreateResource,
  accountsUpdateResource,
} = operations;

export {
  accountsClearForm,
  accountsCopyResourceToForm,
  accountsHandleFormChange,
  accountsLoadCollection,
  accountsLoadResource,
  accountsCreateResource,
  accountsUpdateResource,
};

export default operations;
