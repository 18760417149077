import { formatAsMoney, formatAsPercent } from '../../utils';

export const getEmployerMonthlyAmountDisplay = (
  contributionType,
  employerContribution,
  monthlyEmployerContribution
) => {
  return contributionType === 'D'
    ? formatAsMoney(employerContribution)
    : formatAsPercent(employerContribution) +
        ' (' +
        formatAsMoney(employerContribution * monthlyEmployerContribution * 0.01) +
        ')';
};
