import React, { useEffect, useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { AsyncLoader, Card, CardBody, CardText, DashboardSection, Header2 } from 'fiducius-ui';

import { getUserId } from '../../../auth';
import { getBenefitData } from '../redux/selectors';

import { debounce, mergeRequestStatuses, flatten } from '../../../utils';

import {
  modelerLoadResource,
  modelerCopyResourceToForm,
  modelerHandleFormChange,
  getSingleMessage,
} from '../redux/operations';

import TaxEstimatorForm from '../components/tax-estimator-form';
import TaxEstimatorIntro from '../components/tax-estimator-intro';

const Modeler = ({ id, loadData, request, data, updateFormState, getSingleMessage }) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    loadData(id).then(() => setDataLoaded(true));
    getSingleMessage('Tax_Tool_Tab').then((data) => {
      if (data && data.messages !== null) {
        setMessageContent(data.messages[0].messageContent);
      }
    });
  }, []);

  const [showIntroPage, setShowIntroPage] = useState(true);
  const [messageContent, setMessageContent] = useState('');

  const handleIntroButtonClick = () => {
    setShowIntroPage(!showIntroPage);
    scrollToTop();
  };

  const scrollToTop = () => {
    document.getElementById('taxtooltop').scrollIntoView({ behavior: 'smooth' });
  };

  const onChange = debounce((modeler, attributes, formState) => {
    const newData = { ...flatten(data), ...formState };
    updateFormState(newData, Object.keys(newData));
  }, 250);

  return (
    <div id="taxtooltop">
      <style>
        {`@media print {
          @page {
            size: A3;
          }
          #taxtooltop{            
            position: fixed;
            left: 0;
            top: 0;          
          }
          #maincard{
            max-width:75%
          }          
          #launcher{display:none;}
          #informationbox{display:none;}
          #backbutton{display:none;}
          .nav-side{display:none;}
          .nav-top{display:none;}
      }`}
      </style>
      <DashboardSection title="Benefits for your financial well-being">
        {showIntroPage && (
          <Card brand="primary">
            <CardBody>
              <Header2>PaySmart Loan Navigator</Header2>
              <CardText>
                <AsyncLoader request={request}>
                  <TaxEstimatorIntro
                    isLoading={request.isLoading}
                    handleIntroButtonClick={handleIntroButtonClick}
                  />
                </AsyncLoader>
              </CardText>
            </CardBody>
          </Card>
        )}
        {!showIntroPage && (
          <div id="maincard">
            <Card brand="primary">
              <CardBody>
                <Header2>PaySmart Loan Navigator</Header2>
                <CardText>
                  <AsyncLoader request={request}>
                    <TaxEstimatorForm
                      data={data}
                      isLoading={request.isLoading}
                      onChange={onChange}
                      messageContent={messageContent}
                      dataLoaded={dataLoaded}
                    />
                  </AsyncLoader>
                </CardText>
              </CardBody>
            </Card>
          </div>
        )}
      </DashboardSection>
    </div>
  );
};

Modeler.propTypes = {
  actionRequest: t.object,
  data: t.object,
  errors: t.object,
  form: t.object,
  id: t.string,
  loadData: t.func.isRequired,
  request: t.object,
  modeler: t.object,
  updateFormState: t.func.isRequired,
};

const mapStateToProps = (state) => {
  const { errors, form, requests } = state.modeler;
  return {
    actionRequest: mergeRequestStatuses([requests.createResource, requests.updateResource]),
    data: getBenefitData(state),
    form: form,
    errors: errors,
    id: getUserId(state),
    request: state.modeler.requests.loadResource,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadData: async (id) => {
    await dispatch(modelerLoadResource(id));
    dispatch(modelerCopyResourceToForm(id));
  },
  updateFormState: (data, attributes) => dispatch(modelerHandleFormChange(data, attributes)),
  getSingleMessage: (id) => {
    return dispatch(getSingleMessage(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Modeler);
