import { FormValidator } from '../../utils';

export class SecurityQuestionsSetupValidator extends FormValidator {
  securityResponse1(validator) {
    validator.noBlankSpace();
  }

  securityResponse2(validator) {
    validator.noBlankSpace();
  }

  securityResponse3(validator) {
    validator.noBlankSpace();
  }

  securityQuestion1(validator) {
    validator.isValidSecurityQuestion();
  }

  securityQuestion2(validator) {
    validator.isValidSecurityQuestion();
  }

  securityQuestion3(validator) {
    validator.isValidSecurityQuestion();
  }
}
