import fetch from 'cross-fetch';
import camelcaseKeys from 'camelcase-keys';
import { kebabCase, safeAccess } from './helpers';
import { JsonApiError } from './error';

/* =========================== PUBLIC MODULE API ============================ */
/**
 *  Perform a GET request to the Resource API.
 *
 * @param {string} token    - API access token
 * @param {string} endpoint - The API endpoint
 * @param {Object} params   - Query params for the GET request
 *
 * @see apiFetch for fetch implementation
 */
export const getApi = (token, endpoint, params = null) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;
  return apiFetch(...createGetRequest(token, url, params));
};

/**
 *
 * @param {string} token    - API access token
 * @param {string} endpoint - The API endpoint
 *
 * @see apiFetch for fetch implementation
 */
export const deleteApi = (token, endpoint) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;
  return apiFetch(...createDeleteRequest(token, url));
};

/**
 * Wraps the getApi function to simplify getting single result endpoints
 *
 * @param {string} token    - API access token
 * @param {string} endpoint - The API endpoint
 * @param {Object} params   - Query params for the GET request
 *
 * @throws {Error} when there's an ambiguous result
 * @see getApi for wrapped function
 *
 * @returns {Object} single object result from the API
 */
export const getApiSingle = async (token, endpoint, params = null) => {
  let data = await getApi(token, endpoint, params);
  if (!data || data.length < 0) {
    data = null;
  } else if (data.length > 2) {
    throw new Error('Got unexpected ambiguous result');
  } else {
    data = data[0];
  }

  return data;
};

/**
 * Post data to the Resource API
 *
 * @param {string} token    - API access token
 * @param {string} endpoint - The API endpoint
 * @param {Object} postData - An object to be converted to JSON for the API post
 *
 * @see apiFetch for fetch implementation
 */
export const postApi = (token, endpoint, postData) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;
  return apiFetch(...createPostRequest(token, url, postData));
};

/**
 * Patch data to the Resource API
 *
 * @param {string} token    - API access token
 * @param {string} endpoint - The API endpoint
 * @param {Object} patchData - An object to be converted to JSON for the API patch
 *
 * @see apiFetch for fetch implementation
 */
export const patchApi = (token, endpoint, patchData) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}${endpoint}`;
  return apiFetch(...createPatchRequest(token, url, patchData));
};

/**
 * Fetches a URL with a set of options and parses the output.
 * Assumes the API is formatted according to
 * [json:api](https://jsonapi.org/) spec
 *
 * @param {string} url - The url to fetch
 * @param {Object} opts - Options for the url
 *
 * @see baseFetch for the base fetching implementation
 * @see denormalize for the json:api spec denormalization
 * @returns {Array} An array of documents fetched from the json:api endpoint
 */
export const apiFetch = async (url, opts) => {
  return denormalize(await baseFetch(url, opts));
};

/**
 * Fetches a URL with a set of options and parses the output as json
 *
 * @param {string} url - The url to fetch
 * @param {Object} opts - Options for the url
 *
 * @throws {Error|JsonApiError} on response status >= 400
 * @returns {Object} the json returned by a successful API post
 */
export const baseFetch = async (url, opts) => {
  const res = await fetch(url, opts);
  if (res.status >= 400) {
    await handleErrors(res);
  } else {
    if (res.status === 204) {
      return;
    } else {
      return res.json();
    }
  }
};

/**
 * Denormalizes a json-api formatted response object into an array of objects
 *
 * @param {Object} json - Json object to denormalize
 *
 * @throws {ReferenceError}
 * @returns {Array} Array of objects representing json api documents
 */
export const denormalize = (json = null) => {
  if (json === null) {
    return [];
  }

  // Get primary document data
  let data = json.hasOwnProperty('data') ? camelcaseKeys(json.data, { deep: true }) : [];
  data = Array.isArray(data) ? data : [data];

  // Get included resources
  let included = json.hasOwnProperty('included')
    ? camelcaseKeys(json.included, { deep: true })
    : [];
  included = Array.isArray(included) ? included : [included];

  return [
    ...data.map((doc) => {
      return {
        id: doc.id,
        type: doc.type,
        ...doc.attributes,
        included: included,
      };
    }),
  ];
};

/**
 * Normalizes an object into json api format
 *
 * @param {string} type - Type of resource
 * @param {Object} data - Data to be serialized
 * @param {string} id   - The resource's ID
 *
 * @returns {Object} normalized object according to the json api spec
 */
export const normalize = (type, data, id = null) => {
  let normalized = {
    data: {
      type: type,
      attributes: Object.keys(data).reduce(
        (kebabs, key) => ({
          ...kebabs,
          [kebabCase(key)]: data[key],
        }),
        {}
      ),
    },
  };

  // POSTs usually don't have an ID before creation
  if (id) {
    normalized.data['id'] = id;
  }

  return normalized;
};

/* ======================== PRIVATE MODULE HELPERS ========================== */
const createGetRequest = (token, url, params = null) => [
  url + (params ? '?' + createQuery(params) : ''),
  createGetOptions(token),
];

const createDeleteRequest = (token, url) => [url, createDeleteOptions(token)];

const createPostRequest = (token, url, postData = {}) => [url, createPostOptions(token, postData)];

const createPatchRequest = (token, url, patchData = {}) => [
  url,
  createPatchOptions(token, patchData),
];

const createQuery = (params) =>
  Object.keys(params)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');

const createGetOptions = (token) => ({
  method: 'GET',
  headers: getApiHeaders(token),
});

const createDeleteOptions = (token) => ({
  method: 'DELETE',
  headers: getApiHeaders(token),
});

const createPostOptions = (token, postData) => ({
  method: 'POST',
  body: JSON.stringify(postData),
  headers: getApiHeaders(token),
});

const createPatchOptions = (token, patchData) => ({
  method: 'PATCH',
  body: JSON.stringify(patchData),
  headers: getApiHeaders(token),
});

const getApiHeaders = (token = null) => {
  let headers = {
    Accept: 'application/vnd.api+json',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/vnd.api+json',
    Pragma: 'no-cache',
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  return headers;
};

const handleErrors = async (res) => {
  const json = res && (await res.json());
  if (safeAccess(json, 'errors')) {
    throw new JsonApiError(json.errors, res);
  } else {
    throw new Error(`${res.status} ${res.statusText}: ${res.body}`);
  }
};
