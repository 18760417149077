import React from 'react';
import t from 'prop-types';
import { Loader } from 'fiducius-ui';
import { connect } from 'react-redux';

import { persistLoadState } from '../../persist';
import LayoutFullscreen from './layout-fullscreen';
import FullscreenContainer from '../styles/fullscreen-container';

class RouterPersist extends React.Component {
  static propTypes = {
    children: t.node,
    hasFinished: t.bool.isRequired,
    initialLoad: t.bool.isRequired,
    loadState: t.func.isRequired,
  };

  componentDidMount() {
    if (!this.props.initialLoad) {
      this.props.loadState();
    }
  }

  render() {
    if (this.props.initialLoad) {
      return this.props.children;
    } else {
      return (
        <LayoutFullscreen>
          <FullscreenContainer className="container-fluid">
            <div className="row">
              <div className="col-12 col-sm-10 col-md-8">
                <Loader variant="atom" size={3} />
              </div>
            </div>
          </FullscreenContainer>
        </LayoutFullscreen>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  hasFinished: state.persist.requests.load.hasFinished,
  initialLoad: state.persist.cache.initialLoad || false,
});

const mapDispatchToProps = (dispatch) => ({
  loadState: () => dispatch(persistLoadState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouterPersist);
