import React from 'react';
import t from 'prop-types';
import {
  Card,
  ChartLine,
  CardTitle,
  CardBody,
  Form,
  InputLabel,
  InputWrapper,
  InputGroup,
  InputNumeric,
} from 'fiducius-ui';

import { formatAsMoney } from '../../../utils/format';

class SLAComparisonTool extends React.Component {
  static propTypes = {
    action: t.string,
    closeForm: t.func.isRequired,
    data: t.object,
    errors: t.object,
    isLoading: t.bool,
    isOpen: t.bool,
    onChange: t.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentBalance: 0,
      currentRepaymentTerm: 240,
      currentInterest: 0,

      contributionAmount: 100,

      currentPayment: 0,
      newPayment: 0,
    };

    this.handleCurrentBalanceChange = this.handleCurrentBalanceChange.bind(this);
    this.handleCurrentInterest = this.handleCurrentInterest.bind(this);
    this.handleCurrentTerm = this.handleCurrentTerm.bind(this);

    this.handleContribution = this.handleContribution.bind(this);
  }

  handleCurrentBalanceChange(event) {
    this.setState({ currentBalance: event.target.value });
  }
  handleCurrentInterest(event) {
    this.setState({ currentInterest: event.target.value });
  }
  handleCurrentTerm(event) {
    this.setState({ currentRepaymentTerm: event.target.value });
  }
  handleContribution(event) {
    this.setState({ contributionAmount: event.target.value });
  }

  render() {
    const { data = {}, onChange } = this.props;

    const currentBalance = this.state.currentBalance || data.totalPrincipal || 0;
    const currentRepaymentTerm = this.state.currentRepaymentTerm;
    const currentInterest = this.state.currentInterest || data.avgInterestRate || 0;
    const contributionAmnt = this.state.contributionAmount;

    const cInterest = currentInterest / 100;

    let monthlyPayment = (
      (currentBalance * (cInterest / 12.0)) /
      (1 - Math.pow(1.0 + cInterest / 12.0, -currentRepaymentTerm))
    ).toFixed(2);
    const monthlyContributionPayment = monthlyPayment / 1 + this.state.contributionAmount / 1;

    let totalInterest = monthlyPayment * currentRepaymentTerm - currentBalance;

    let PaymentSchedule = [];
    let ContributionSchedule = [];
    let ticks = [];
    let loopPrincipal = currentBalance;
    let contributionPrincipal = currentBalance;

    let interestMonthlyPayment = 0;
    let principalMonthlyPayment = 0;
    for (let i = currentRepaymentTerm - 1; i >= 0; i--) {
      interestMonthlyPayment = (cInterest / 12) * loopPrincipal;
      principalMonthlyPayment = monthlyPayment - interestMonthlyPayment;

      if ((i % 12 === 0 && i !== 0 && loopPrincipal > 0) || i === currentRepaymentTerm - 1) {
        if (i === currentRepaymentTerm - 1) {
          ticks.push('0');
          PaymentSchedule.push(currentBalance);
        } else {
          ticks.push((currentRepaymentTerm / 12 - i / 12).toFixed(2).toString());
          PaymentSchedule.push(loopPrincipal);
        }
      } else if (i % 12 === 0 && i !== 0 && loopPrincipal < 0) {
        ticks.push((currentRepaymentTerm / 12 - i / 12).toFixed(2).toString());
        PaymentSchedule.push(0);
      } else if (i === 0) {
        ticks.push((currentRepaymentTerm / 12 - i / 12).toFixed(2).toString());
        PaymentSchedule.push(0);
      }
      loopPrincipal = loopPrincipal - principalMonthlyPayment;
    }

    interestMonthlyPayment = 0;
    principalMonthlyPayment = 0;
    let flag = 0;
    let ContrPayoffTime;
    let totalContributionInterest;
    for (let i = currentRepaymentTerm - 1; i >= 0; i--) {
      interestMonthlyPayment = (cInterest / 12) * contributionPrincipal;
      principalMonthlyPayment = monthlyContributionPayment - interestMonthlyPayment;
      if (
        (i % 12 === 0 && i !== 0 && contributionPrincipal > 0) ||
        i === currentRepaymentTerm - 1
      ) {
        if (i === currentRepaymentTerm - 1) {
          ContributionSchedule.push(currentBalance);
        } else {
          ContributionSchedule.push(contributionPrincipal - monthlyContributionPayment);
        }
      } else if (i % 12 === 0 && i !== 0 && contributionPrincipal < 0) {
        ContributionSchedule.push(0);
      } else if (i === 0) {
        ContributionSchedule.push(0);
      }

      if (contributionPrincipal <= 0 && flag < 1) {
        ContrPayoffTime = currentRepaymentTerm - i - 1;
        //TotalContrPayments = ContrPayoffTime * monthlyContributionPayment;
        totalContributionInterest = monthlyContributionPayment * ContrPayoffTime - currentBalance;
        flag = 1;
      }

      contributionPrincipal = contributionPrincipal - principalMonthlyPayment;
    }

    let TimeReduced = currentRepaymentTerm - ContrPayoffTime;
    let TimeReducedYear = Math.floor(TimeReduced / 12);
    let TimeReducedMonth = TimeReduced % 12;

    return (
      <Form id="interestComparison" defaults={data} handleChange={onChange}>
        <CardTitle>Contribution Comparison Tool</CardTitle>
        <div className="row">
          <div className="col-12 col-md-4">
            <Card brand="primary">
              <CardBody>
                <CardTitle>Loan Details</CardTitle>
                <div className="row">
                  <div className="col-12 col-md-10">
                    <InputWrapper>
                      <InputLabel htmlFor="LoanBalance">Total Balance</InputLabel>
                      <InputGroup>
                        <InputNumeric
                          id="ClientTotalLoanBalance"
                          name="ClientTotalLoanBalance"
                          defaultValue={currentBalance}
                          onChange={this.handleCurrentBalanceChange}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-10">
                    <InputWrapper>
                      <InputLabel htmlFor="state">Interest Rate</InputLabel>
                      <InputGroup>
                        <InputNumeric
                          id="CurrentInterestRate"
                          name="CurrentInterestRate"
                          defaultValue={currentInterest}
                          onChange={this.handleCurrentInterest}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-10">
                    <InputWrapper>
                      <InputLabel htmlFor="state">Repayment Term (Months)</InputLabel>
                      <InputGroup>
                        <InputNumeric
                          id="CurrentTermMonths"
                          name="CurrentTermMonths"
                          defaultValue={currentRepaymentTerm}
                          onChange={this.handleCurrentTerm}
                        />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/*<Card brand="primary">
         <CardBody>  */}
            <CardTitle>Contribution</CardTitle>
            <div className="row">
              <div className="col-12 col-md-10">
                <InputWrapper>
                  <InputLabel htmlFor="LoanBalance">Monthly Contribution</InputLabel>
                  <InputGroup>
                    <InputNumeric
                      id="ClientTotalLoanBalance"
                      name="ClientTotalLoanBalance"
                      defaultValue={contributionAmnt}
                      onChange={this.handleContribution}
                    />
                  </InputGroup>
                </InputWrapper>
              </div>
            </div>
            {/*</CardBody>
          </Card>*/}
          </div>
          <div className="col-12 col-md-8">
            <Card brand="secondary">
              <CardBody>
                <CardTitle>
                  <span>Yearly Balance Comparison</span>
                </CardTitle>
                <ChartLine
                  lines={[
                    {
                      brand: 'secondary',
                      dataType: 'currency',
                      fill: true,
                      title: 'New Terms',
                      data: ContributionSchedule,
                      xDataType: 'category',
                      yDataType: 'linear',
                    },
                    {
                      brand: 'primary',
                      dataType: 'currency',
                      fill: true,
                      title: 'Current Terms',
                      data: PaymentSchedule,
                      xDataType: 'category',
                      yDataType: 'linear',
                    },
                  ]}
                  config={{
                    labels: ticks,
                    formatter: (value) => formatAsMoney(parseFloat(value)),
                    titleFormatter: (value) => `Year ${value}`,
                    yMin: 0,
                    multiAxis: false,
                  }}
                />
                <br />
                <br />
                <div className="row">
                  <div className="col-6 col-md-5">
                    <dl>
                      <dt>Time Reduced</dt>
                      <dd>
                        {TimeReducedYear + ' Year(s) ' + TimeReducedMonth + ' Month(s)' || '—'}
                      </dd>
                      <dt>Lifetime Savings</dt>
                      <dd>
                        {formatAsMoney(
                          ContrPayoffTime * contributionAmnt +
                            (totalInterest - totalContributionInterest)
                        )}
                      </dd>
                      <dt>Monthly Payment</dt>
                      <dd>{formatAsMoney(monthlyPayment) || '—'}</dd>
                      <dt>total Interest Paid</dt>
                      <dd>{formatAsMoney(totalInterest) || '—'}</dd>
                    </dl>
                  </div>
                  <div className="col-6 col-md-5">
                    <dt>Total Employer Contribution</dt>
                    <dd>{formatAsMoney(ContrPayoffTime * contributionAmnt) || '—'}</dd>
                    <dt>Interest Savings</dt>
                    <dd>{formatAsMoney(totalInterest - totalContributionInterest) || '—'}</dd>
                    <dt>Monthly Contribution Payment</dt>
                    <dd>{formatAsMoney(monthlyContributionPayment) || '—'}</dd>
                    <dt>Total Contribution Interest Paid</dt>
                    <dd>{formatAsMoney(totalContributionInterest) || '—'}</dd>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Form>
    );
  }
}

export default SLAComparisonTool;
