import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPermissions } from '../../../routing';
import { Card, CardBody, CardFooter, CardText, CardTitle, Link } from 'fiducius-ui';
import FreshStartProgress from '../components/fresh-start-progress';

import { getUserId } from '../../../auth';

const Spaced = styled.p`
  margin-top: 1.5rem;
`;

const FlexFooter = styled(CardFooter)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  & h5 {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
  }
`;

const FreshStartSummary = withPermissions(({}) => (
  <div className="row">
    <div className="col-12 col-lg-12">
      <Card brand="primary">
        <CardBody>
          <CardTitle>Fresh Start</CardTitle>
          <CardText>
            <Spaced>
              Fresh Start is a one-time temporary program from the U.S. Department of Education that
              offers the removal of default from your federal student loans and other special
              benefits.
            </Spaced>
          </CardText>
          <FreshStartProgress removeContainer={true} />
        </CardBody>
        <FlexFooter>
          <Link to="/benefits/freshstart/overview">My Fresh Start</Link>
        </FlexFooter>
      </Card>
    </div>
  </div>
));

FreshStartSummary.propTypes = {};

const mapStateToProps = (state) => {
  const id = getUserId(state);
  return {};
};

export default connect(mapStateToProps)(withPermissions(FreshStartSummary));
