import React from 'react';
import t from 'prop-types';

import { Button, InputGroup, InputLabel, InputText, InputWrapper } from 'fiducius-ui';
import { useFocusOnMount } from '../../utils';

const Email = ({ data, handleClick }) => {
  const firstInput = useFocusOnMount();

  return (
    <>
      <InputWrapper>
        <InputLabel>Email</InputLabel>
        <InputGroup>
          <InputText inputRef={firstInput} name="email" defaultValue={data.email} />
        </InputGroup>
      </InputWrapper>
      <InputWrapper>
        <Button brand="primary" type="submit" onClick={handleClick}>
          Submit
        </Button>
      </InputWrapper>
    </>
  );
};

Email.propTypes = {
  data: t.object.isRequired,
  handleClick: t.func.isRequired,
};

export default Email;
