import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { combineReducers, createStore, applyMiddleware, compose } from 'redux';

import { accountsReducer } from '../../accounts';
import { advisorReducer } from '../../advisor';
import { affiliateReducer } from '../../benefits/affiliates';
import { agreementReducer } from '../../workflows/agreement';
import { authReducer } from '../../auth';
import { contactReducer } from '../../contact';
import { contributionReducer } from '../../benefits/contribution';
import { customerContributionReducer } from '../../customer-contribution';
import { customerContributionMilestoneReducer } from '../../customer-contribution-milestone';
import { contributionSetupReducer } from '../../contribution-setup';
import { documentsReducer } from '../../documents';
import { customerFaqReducer } from '../../faq';
import { forgivenessReducer } from '../../benefits/forgiveness';
import { fulfillmentReducer } from '../../fulfillment';
import { idrReducer } from '../../workflows/idr';
import { legalReducer } from '../../legal';
import { meetingsReducer } from '../../meetings';
import { modelerReducer } from '../../tools/modeler';
import { passwordAuthReducer } from '../../password-reset-auth';
import { paymentReducer } from '../../payment';
import { persistReducer, persistTypes } from '../../persist';
import { profileReducer } from '../../profile';
import { questionnaireBtsReducer } from '../../workflows/questionnaire-bts';
import { referencesReducer } from '../../references';
import { refiReducer } from '../../benefits/refinance';
import { refServicersReducer } from '../../servicers-ref';
import { renewalReducer } from '../../workflows/renewal';
import { nsldsReducer } from '../../workflows/nslds';
import { renewalResultsReducer } from '../../renewal-results';
import { implementationResultsReducer } from '../../implementation-results';
import { screeningReducer } from '../../workflows/screening';
import { servicersReducer } from '../../servicers';
import { simplifiReducer } from '../../benefits/simplifi';
import { todosReducer } from '../../todos';
import { safeAccess } from '../../utils';
import { taxtoolReducer } from '../../tools/taxtool';
import { tuitionReducer } from '../../benefits/tuition';
import { tuitionInstitutionReducer } from '../../tuition-institution';
import { tuitionInstitutionTermReducer } from '../../tuition-institution-term';
import { tuitionInstitutionClassReducer } from '../../tuition-institution-class';
import { maintenanceReducer } from '../../maintenance';
import { tuitionApprovalReducer } from '../../tuition-approval';
import { selfServiceDocumentReducer } from '../../self-service-document';
import { employmentHistoryReducer } from '../../employment-history';
import { limitedWaiverOpportunityReducer } from '../../benefits/limited-waiver-opportunity';
import { lwoAgreementReducer } from '../../workflows/lwo-agreement';
import { securityQuestionsSetupReducer } from '../../security-questions-setup';
import { securityQuestionsReducer } from '../../security-questions';
import { accountUnlockReducer } from '../../account-unlock';
import { empcertApprovalReducer } from '../../empcert-approval';
import { planToolReducer } from '../../tools/plan-tool';
import { freshStartReducer } from '../../benefits/freshstart';
import { educationalReengagementReducer } from '../../benefits/educational-reengagement';
import { messagesReducer } from '../../messages';
import { plaidReducer } from '../../plaid';
import { loanRepaymentReducer } from '../../loan-repayment';

// Initialize middleware pipeline
const middleware = [thunkMiddleware];

// Logging middleware
if (process.env.REACT_APP_REDUX_LOGGING === 'true') {
  middleware.push(
    createLogger({
      collapsed: true,
      duration: true,
    })
  );
}

// Combine reducers to form rootReducer
const appReducer = combineReducers({
  accounts: accountsReducer,
  accountUnlock: accountUnlockReducer,
  advisor: advisorReducer,
  affiliate: affiliateReducer,
  agreement: agreementReducer,
  auth: authReducer,
  contact: contactReducer,
  contribution: contributionReducer,
  contributionSetup: contributionSetupReducer,
  customerContribution: customerContributionReducer,
  customerContributionMilestone: customerContributionMilestoneReducer,
  customerFaq: customerFaqReducer,

  documents: documentsReducer,
  employmentHistory: employmentHistoryReducer,
  forgiveness: forgivenessReducer,
  modeler: modelerReducer,
  fulfillment: fulfillmentReducer,
  idr: idrReducer,
  implementationResults: implementationResultsReducer,
  legal: legalReducer,
  limitedWaiverOpportunity: limitedWaiverOpportunityReducer,
  lwoAgreement: lwoAgreementReducer,
  meetings: meetingsReducer,
  nslds: nsldsReducer,
  passwordauth: passwordAuthReducer,
  payment: paymentReducer,
  persist: persistReducer,
  profile: profileReducer,
  questionnaireBts: questionnaireBtsReducer,
  references: referencesReducer,
  refi: refiReducer,
  refServicers: refServicersReducer,
  renewal: renewalReducer,
  renewalResults: renewalResultsReducer,
  securityQuestions: securityQuestionsReducer,
  securityQuestionsSetup: securityQuestionsSetupReducer,
  servicers: servicersReducer,
  screening: screeningReducer,
  simplifi: simplifiReducer,
  tools: taxtoolReducer,
  todos: todosReducer,
  tuition: tuitionReducer,
  tuitionInstitution: tuitionInstitutionReducer,
  tuitionInstitutionTerm: tuitionInstitutionTermReducer,
  tuitionInstitutionClass: tuitionInstitutionClassReducer,
  maintenance: maintenanceReducer,
  tuitionApproval: tuitionApprovalReducer,
  selfServiceDocument: selfServiceDocumentReducer,
  empcertApproval: empcertApprovalReducer,
  planTool: planToolReducer,
  freshStart: freshStartReducer,
  educationalReengagement: educationalReengagementReducer,
  messages: messagesReducer,
  plaid: plaidReducer,
  loanRepayment: loanRepaymentReducer,
});

// We need to handle two special state changes that should affect the whole
// redux store here. This allows us to load an entire persisted state tree and
// clear out the entire tree as part of logout teardown.
const rootReducer = (state, action) => {
  // Returning undefined will just instantiate the state tree as usual
  let resetState = undefined;

  // Don't reset the persistence reducer if it exists
  if (safeAccess(state, 'persist', false)) {
    resetState = { persist: state.persist };
  }

  // Handle special cases for the entire state tree.
  switch (action.type) {
    case persistTypes.PERSIST_RESET_STATE:
      // Reset the state tree minus persist
      state = resetState;
      break;
    case persistTypes.PERSIST_HYDRATE_STATE:
      // We use this to read and then hydrate the state from browser storage
      // Hydrate the entire state tree from passed in state
      state = action.data;
      break;
    default:
      // All other action types pass through here
      break;
  }

  return appReducer(state, action);
};

// Create the store with data from localStorage
const createStoreWithMiddleware = compose(applyMiddleware(...middleware))(createStore);
const store = createStoreWithMiddleware(rootReducer);

export default store;
