import React from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';

import {
  Form,
  Header2,
  Header3,
  Header4,
  Header5,
  InputError,
  InputGroup,
  InputLabel,
  InputSelect,
  InputText,
  InputWrapper,
  AsyncLoader,
} from 'fiducius-ui';

import { safeAccess, mergeRequestStatuses, getApi } from '../../../utils';

import { getTuitionInstitutionData } from '../../../benefits/tuition';

import { tuitionInstitutionLoadCollection } from '../../../tuition-institution';

import {
  tuitionInstitutionTermClearForm,
  tuitionInstitutionTermHandleFormChange,
  termCreateResource,
} from '../../../tuition-institution-term';

import { withPermissions } from '../../../routing';

import { todosLoadCollection } from '../../../todos';

import { getToken, authLoadPermissions } from '../../../auth';

class TabClass extends React.Component {
  static propTypes = {
    institutionData: t.object,
    data: t.object,
    errors: t.object,
    institutionErrors: t.object,
    request: t.object,
    getRequest: t.object,
    handleChange: t.func,
    loadInstitution: t.func.isRequired,
    clearTermForm: t.func.isRequired,
    handleTermForm: t.func.isRequired,
    createTerm: t.func.isRequired,
    id: t.string.isRequired,
    form: t.object,
    token: t.any,
  };

  constructor(props) {
    super(props);
    this.state = { allowedCertifications: {} };
  }

  componentDidMount() {
    this.props.loadInstitution();
    this.getCertificationDropDownOptions();
  }

  getCertificationDropDownOptions = () => {
    getApi(this.props.token, '/drop-down-list/Certification__A').then((data) => {
      let certifications = {};
      Object.keys(data).forEach((a) => {
        certifications[data[a].description] = data[a].description;
      });
      this.setState({ ...this.state, allowedCertifications: certifications });
    });
  };

  noTermsOnFile() {
    return (
      <Header4>
        You have no terms on file to select. Please use the Add New Term button below to add a new
        one.
      </Header4>
    );
  }

  noInstitutionSelected() {
    return (
      <Header4>
        You have not yet selected an institution. Please go back to the Institution section and
        select an existing institution or add a new institution.
      </Header4>
    );
  }

  noTermSelected() {
    return (
      <Header4>
        You have not yet selected a term. Please go back to the Term section and select an existing
        term or add a new term.
      </Header4>
    );
  }

  termIdMatchCheck(termIdA, termIdB) {
    let retVal = false;
    if (termIdA !== undefined && termIdA !== null && termIdB !== undefined && termIdB !== null) {
      retVal = parseInt(termIdA) === parseInt(termIdB);
      //alert((parseInt(termIdA) === parseInt(termIdB)) + ': ' + termIdA + ' === ' + termIdB);
    }
    return retVal;
  }

  getInstitutionList = () => {
    const { institutionData } = this.props;
    let realInstitutions = Object.keys(institutionData).reduce(
      (obj, k) => ({
        ...obj,
        [k]: institutionData[k].institutionName,
      }),
      {}
    );
    let institutions = { ...realInstitutions };
    return institutions;
  };

  getExistingInfo = () => {
    const { data = {}, institutionData } = this.props;

    const institution = institutionData[data.institutionId];
    const termId = Object.keys(institution.terms).find((termId) =>
      this.termIdMatchCheck(institution.terms[termId].id, data.termId)
    );
    const term = institution.terms[termId];
    const classes = [];
    if (term.classes.length > 0) {
      Object.keys(term.classes).forEach((classId) =>
        classes.push(<li>{term.classes[classId].className}</li>)
      );
    }
    return (
      <>
        <Header3>
          {institution.institutionName} - {term.termName}
        </Header3>
        <Header5>Existing Classes {classes.length === 0 && <> - No classes on file</>}</Header5>
        {classes.length > 0 && <ul>{classes}</ul>}
      </>
    );
  };

  isCertification() {
    const { data = {}, institutionData } = this.props;
    return institutionData[data.institutionId].degreeType === 'CERT';
  }

  render() {
    const { data = {}, errors = {}, getRequest, handleChange, id } = this.props;
    const { allowedCertifications } = this.state;
    return (
      <>
        <Header2>Class Information</Header2>

        <AsyncLoader request={getRequest} empty={this.noInstitutionSelected()}>
          {data.institutionId && data.termId && (
            <>
              {this.getExistingInfo()}

              <Form handleChange={handleChange} id={id} defaults={data}>
                <div className="row">
                  <div className="col-12">
                    <InputWrapper error={!!errors.className}>
                      <InputLabel htmlFor="className" required>
                        Class Name
                      </InputLabel>
                      <InputError>{safeAccess(errors, 'className.detail')}</InputError>
                      <InputGroup>
                        {this.isCertification() &&
                          Object.keys(allowedCertifications).length > 1 && (
                            <InputSelect
                              name="className"
                              value={data.className}
                              options={allowedCertifications}
                            />
                          )}
                        {(!this.isCertification() ||
                          Object.keys(allowedCertifications).length === 1) && (
                          <InputText name="className" defaultValue={data.className} />
                        )}
                      </InputGroup>
                    </InputWrapper>

                    <InputWrapper error={!!errors.hours}>
                      <InputLabel htmlFor="hours">Hours</InputLabel>
                      <InputError>{safeAccess(errors, 'hours.detail')}</InputError>
                      <InputGroup>
                        <InputText name="hours" defaultValue={data.hours} />
                      </InputGroup>
                    </InputWrapper>
                  </div>
                </div>
              </Form>
            </>
          )}
          {!data.institutionId && this.noInstitutionSelected()}
          {data.institutionId && !data.termId && this.noTermSelected()}
        </AsyncLoader>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { requests } = state.tuitionInstitution;
  const termForm = safeAccess(state.tuitionInstitutionTerm, 'form');
  const termRequests = safeAccess(state.tuitionInstitutionTerm, 'requests');
  const termErrors = safeAccess(state.tuitionInstitutionTerm, 'errors');
  return {
    institutionData: getTuitionInstitutionData(state),
    token: getToken(state),
    getRequest: requests.loadCollection,
    institutionErrors: termErrors,
    request: mergeRequestStatuses([
      termRequests.createResource,
      termRequests.updateResource,
      termRequests.deleteResource,
    ]),
    form: termForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadInstitution: () => dispatch(tuitionInstitutionLoadCollection()),
  clearTermForm: () => dispatch(tuitionInstitutionTermClearForm()),
  createTerm: async () => {
    let shouldUpdate = await dispatch(termCreateResource());
    if (shouldUpdate) {
      await dispatch(authLoadPermissions());
      await dispatch(tuitionInstitutionLoadCollection());
      dispatch(todosLoadCollection());
    }
  },
  handleTermForm: (tuitionInstitutionTerm, attributes) =>
    dispatch(tuitionInstitutionTermHandleFormChange(tuitionInstitutionTerm, attributes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(TabClass));
