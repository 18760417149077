import types from './types';
import { combineReducers } from 'redux';
import { invalidateAttributes, requestReducerGenerator, validateAttributes } from '../../utils';

const loadPermissions = requestReducerGenerator('auth', 'loadPermissions', types);
const login = requestReducerGenerator('auth', 'login', types);
const logout = requestReducerGenerator('auth', 'logout', types);
const partnerLocation = requestReducerGenerator('auth', 'partnerLocation', types);
const passwordReset = requestReducerGenerator('auth', 'passwordReset', types);
const passwordUpdate = requestReducerGenerator('auth', 'passwordUpdate', types);
const signup = requestReducerGenerator('auth', 'signup', types);
const silentRenew = requestReducerGenerator('auth', 'silentRenew', types);
const themePreference = requestReducerGenerator('auth', 'themePreference', types);

const cache = (state = null, action) => {
  switch (action.type) {
    case types.AUTH_HANDLE_ERROR:
      return { ...state, errors: action.errors };
    case types.AUTH_POPULATE:
      return { ...action.data };
    case types.AUTH_CLEAR:
    case types.AUTH_EXPIRED:
    case types.AUTH_SESSION_TERMINATED:
      return null;
    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case types.AUTH_CLEAR_ERRORS: {
      return {};
    }
    case types.AUTH_HANDLE_ERROR: {
      return { ...action.errors };
    }
    case types.AUTH_VALIDATE_ATTRIBUTES: {
      let newErrors = validateAttributes(action.attributes, action.errorState).errors;
      return { ...state, ...newErrors };
    }
    case types.AUTH_INVALIDATE_ATTRIBUTES: {
      let newErrors = invalidateAttributes(action.attributes, action.errorState).errors;
      return { ...state, ...newErrors };
    }
    default:
      return state;
  }
};

const form = (state = {}, action) => {
  switch (action.type) {
    case types.AUTH_CLEAR_FORM: {
      return {};
    }
    case types.AUTH_HANDLE_FORM_CHANGE: {
      return { ...action.data };
    }
    default: {
      return state;
    }
  }
};

const auth = combineReducers({
  cache,
  errors,
  form,
  requests: combineReducers({
    loadPermissions,
    login,
    logout,
    partnerLocation,
    passwordReset,
    passwordUpdate,
    signup,
    silentRenew,
    themePreference,
  }),
});

export default auth;
