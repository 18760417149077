import React, { useState } from 'react';
import t from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { CardBody, CardHeader, Loader, Header1 } from 'fiducius-ui';

import { ShadowAddCard } from '../../routing';
import { safeAccess } from '../../utils';

import PasswordOutOfDateForm from '../components/password-out-of-date-form';
import { authHandleFormChange, authPasswordOutOfDateReset } from '../redux/operations';
import {
  passwordNeedsReset,
  getPasswordResetTokenFromPermissions,
  authGetRedirectPath,
} from '../redux/selectors';
import LoaderWrapper from '../styles/loader-wrapper';

const PasswordOutOfDate = ({
  errors = {},
  getPasswordResetTokenFromPermissions,
  passwordNeedsReset,
  request,
  submitChange,
  validatePassword,
  getRedirectPath,
}) => {
  const token = getPasswordResetTokenFromPermissions;

  const [formState, updateFormState] = useState({
    token: token,
    email: '',
    oldPassword: '',
    newPassword: '',
    password: '',
  });
  if (!passwordNeedsReset) {
    return <Redirect to={getRedirectPath === 'referrer' ? '/dashboard' : getRedirectPath} />;
    //} else if (request.hasFinished && !request.hasFailed) {
    //  return <Redirect to="/dashboard" />;
  } else {
    return (
      <>
        <Header1>Password Expired</Header1>
        <ShadowAddCard brand={(request.hasFailed && 'danger') || 'primary'}>
          {request.hasFailed && <CardHeader>Password Change Failed</CardHeader>}
          <CardBody>
            {request.isLoading ? (
              <LoaderWrapper>
                <Loader variant="atom" size={3} />
              </LoaderWrapper>
            ) : (
              <>
                <PasswordOutOfDateForm
                  data={formState}
                  errors={errors}
                  handleChange={(state) => {
                    updateFormState(state);
                    validatePassword(state.oldPassword, state.newPassword, state.password);
                  }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitChange(formState);
                  }}
                />
              </>
            )}
          </CardBody>
        </ShadowAddCard>
      </>
    );
  }
};

PasswordOutOfDate.propTypes = {
  errors: t.object,
  passwordNeedsReset: t.bool.isRequired,
  getPasswordResetTokenFromPermissions: t.string.isRequired,
  request: t.object,
  submitChange: t.func.isRequired,
  validatePassword: t.func.isRequired,
  getRedirectPath: t.string,
};

const mapStateToProps = (state) => ({
  errors: (state.auth && state.auth.errors) || {},
  passwordNeedsReset: passwordNeedsReset(state),
  getPasswordResetTokenFromPermissions: getPasswordResetTokenFromPermissions(state),
  request: safeAccess(state, 'auth.requests.passwordUpdate', {}),
  getRedirectPath: authGetRedirectPath(state, true),
});

const mapDispatchToProps = (dispatch) => ({
  submitChange: ({ email, token, oldPassword, newPassword, password }) =>
    dispatch(authPasswordOutOfDateReset(token, email, oldPassword, newPassword, password)),
  validatePassword: (oldPassword, newPassword, password) =>
    dispatch(
      authHandleFormChange(
        { oldPassword: oldPassword, newPassword: newPassword, password: password },
        ['oldPassword', 'newPassword', 'password']
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PasswordOutOfDate));
